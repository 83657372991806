import {connect} from 'react-redux';
import TestCalls from './TestCalls';
import {getDialerTestCalls} from "../../actions/client/testCalls";

const mapState = ({client_test_calls}) => ({
    loading: client_test_calls.loading,
    test_calls_list: client_test_calls.test_calls_list,
    count: client_test_calls.count,
    page: client_test_calls.page,
    per_page: client_test_calls.per_page
});

export default connect(mapState, {getDialerTestCalls})(TestCalls);
