import {connect} from 'react-redux';
import {
    downloadAccesses,
    getAccessList,
    setLoadingData,
    allocateByAccess,
    setAccessLoadingAllocatedNumbers
} from '../../actions/client/accesses';

import {
    downloadNumbers,
    setTrunkNumberTransaction,
    setUploadingLoading,
    allocateGoogleOTPTrunkNumbers
} from '../../actions/client/numbers'

import Accesses from './Accesses';
import {getTrunkListDialer} from '../../actions/client/price';


const mapState = ({client_accesses, auth, auth_dialer, client_numbers, references, client_price}) => ({
    account: auth.authInfo,
    accountDialerState: auth_dialer.dialerInfo,

    accessesList: client_accesses.items,
    count: client_accesses.count,
    accessesLoading: client_accesses.loading,
    loadingAllocation: client_accesses.loadingAllocatedNumbers,
    page: client_accesses.page,
    per_page: client_accesses.per_page,
    trunkList: client_price.trunk_list,

    service_plan_list: references.service_plan_list,

    currency_list: references.currency_list,
    uploadLoading: references.uploadLoadingDialer,

    uploadLoadingSetting: client_numbers.uploadTrunkLoading,
    loadingGoogleOTPAllocation: client_numbers.loadingAllocatedNumbers,
    trunk_number_transaction: client_numbers.trunk_number_transaction,
    
    allocation_limit: {
        ...references.client_allocation_limit, 
        ...auth.allocation_limit
    }
});


export default connect(mapState, {
    getTrunkList: getTrunkListDialer,
    setAccessLoadingAllocatedNumbers,

    downloadNumbers,
    setTrunkNumberTransaction,
    setUploadingLoading,
    allocateGoogleOTPTrunkNumbers,

    getAccessList,
    downloadAccesses,
    allocateByAccess,
    setLoadingData,
})(Accesses);