import React from 'react';
import {formValid} from '../utils/validate';
import { deleteEmpty } from '../utils/helpers';


export default  (FilltersForm, model, timeout = 500) => {
    
    class FilltersFormHOC extends React.PureComponent{

        ref = React.createRef();

        formValue = null;
        timer = null;

        getValues = () => {
            // console.log("this.props.proxy", this.props.proxy, this.ref.current);
            if(this.props.proxy)
                return this.props.proxy(this.ref.current.getFormValue())
            return this.ref.current.getFormValue()
        };

        onChange = (formValue) => {
            clearTimeout(this.timer);

            this.timer = setTimeout( () => {
                if(!model || formValid(model.check(formValue))){
                    let _formValue = deleteEmpty({...formValue});

                    if(JSON.stringify(_formValue) !== JSON.stringify(this.formValue)){
                        this.formValue = _formValue;
                        this.props.onChange(
                            this.props.proxy ? this.props.proxy(_formValue) : _formValue
                        );
                    }
                }
            }, timeout)
            
        }

        render() {
            return (<FilltersForm {...this.props} onChange={this.onChange} ref={this.ref}/>)
        }
    }

    return FilltersFormHOC; 
}