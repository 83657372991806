import React from 'react';
import styled from 'styled-components'

export const RadioCSS = styled.div`
    margin-top: -24px;
    margin-bottom: 3px;
`;

const FooterActions_ = styled.div`
    width: 100%;
    position:relative;
`;
const FooterActions__ = styled.div`
    position:absolute;
    right:0;
    top:-68px;
`;
export const FooterActions = ({children}) => (<FooterActions_><FooterActions__>{children}</FooterActions__></FooterActions_>)


export const Header = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #8256C8;
    padding: 15px 0px;
     div {
        display: inline-block;
        width: 50%;
     }
     div:last-child{
         text-align: right
     }
`;

const HeaderTrunkActions_ = styled.div`
    width: 100%;
    position:relative;
`;
const HeaderTrunkActions__ = styled.div`
    position:absolute;
    right:0;
    top:-52px;
`;
export const HeaderTrunkActions = ({children}) => (<HeaderTrunkActions_>
    <HeaderTrunkActions__>
        {children}
    </HeaderTrunkActions__>
</HeaderTrunkActions_>)