import React, {useState} from 'react';
import Modal from '../../../components/Modal';
import {DatePicker, Form, Schema, SelectPicker} from 'rsuite';
import CustomField from '../../../components/Form/CustomField';
import {api} from '../../../api/loginRoutes';
import Checkbox from '../../../hoc/Checkbox';
import RateField from '../../../components/Form/RateField';
import ServicePlans from '../../../components/Filters/ServicePlans';
import {toStartDayUTCTime} from '../../../utils';
import {useWindowWidth} from "../../../hooks";
import {ButtonPrimary} from "../../../components/base/BaseButton";

const {NumberType} = Schema.Types;


const AddPrice = ({
    subdestination_list,
    account_id,
    updateItems
}) => {

    let formRef = null;
    const updateRandomFormRef = (ref) => formRef = ref;

    const [show, setShow] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);


    const onSubmit = async () => {

        if (!formRef.check())
            return;

        const data = {...formRef.getFormValue()};
        setDisabled(true);

        if (data.withDate) {

            data.start_date = data.start_date
                ? toStartDayUTCTime(new Date(data.start_date))
                : null;

        } else {
            delete data.start_date;
        }

        delete data.withDate;

        const result = await api('account_price:create', {
            target: {account_id},
            ...data,
            rate: +formRef.getFormValue().rate
        });

        setDisabled(false);

        if (result) {
            setShow(false);
            updateItems();
        }

    };

    return (
        <>
            <div style={{float: 'right'}}>
                <ButtonPrimary
                    onClick={() => setShow(true)}
                >
                    + Add new special price
                </ButtonPrimary>
            </div>
            <Modal
                show={show}
                title="Add new special rate"
                onClose={() => setShow(false)}
                footer={true}
                successText="Create"
                onSuccess={onSubmit}
            >

                <ModalForm
                    updateFormRef={updateRandomFormRef}
                    {...{
                        subdestination_list,
                        disabled
                    }}
                />

            </Modal>
        </>
    );
};

export default AddPrice;


const formModel = Schema.Model({
    sp_key: NumberType().isRequired('Required'),
    sde_key: NumberType().isRequired('Required'),
    rate: NumberType().isRequired('Required'),
});

const ModalForm = ({disabled = false, subdestination_list, updateFormRef}) => {
    const [withDate, setWithDate] = useState(false);
    const windowWidth = useWindowWidth();

    return (
        <Form
            layout="inline"
            model={formModel}
            formDefaultValue={{update_allocated_numbers: false}}
            ref={ref => updateFormRef(ref)}
            onChange={val => console.log("val", val)}
        >
            <ServicePlans disabled={disabled} classPrefix="minWidth"/>

            <CustomField
                disabled={disabled}
                accepter={SelectPicker}
                data={subdestination_list}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Subdestination"
                errorPlacement="topRight"
                name="sde_key"
                classPrefix="minWidth"
            />
            <br/>
            <RateField
                name="rate"
                label="Rate"
            />
            <br/>
            <Checkbox
                name={'withDate'}
                onChange={(checked) => {
                    setWithDate(checked);
                }}
            >
                From date
            </Checkbox>

            <CustomField
                accepter={DatePicker}
                placeholder="Enter Date"
                name="start_date"
                disabled={!withDate}
                ranges={[
                    {
                        label: 'Today',
                        value: new Date()
                    },
                    {
                        label: 'Tomorrow',
                        value: new Date().setDate(new Date().getDate() + 1)
                    }
                ]}

                // mobile
                oneTap={windowWidth < 480 && withDate}
                inline={windowWidth < 480 && withDate}
            />

            <Checkbox name="update_allocated_numbers">
                Update for all allocated number
            </Checkbox>
        </Form>
    );
};