import React from "react";
import {useIntl} from "react-intl";
import CommonTable from "../CommonTable";
import m from "../../../definedMessages";

export default ({
    data = [], 
    loading = false,
    currencyName,
}) => {

    const {formatMessage} = useIntl();

    const columns = [
        {
            id: 'amount',
            label: formatMessage(m.amount),
            value: ({sum}) => `${sum}${currencyName ? ' ' + currencyName : ''}`,
            flexGrow: 2,
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'date',
            label: formatMessage(m.date),
            dataKey: "date",
            flexGrow: 2,
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'paymentDetails',
            label: formatMessage(m.paymentDetails),
            dataKey: "bank_detail",
            flexGrow: 2,
            minWidth: 200,
            align: 'center'
        },
    ];

    return (
        <CommonTable
            data={data}
            loading={loading}
            columns={columns}
        />
    )
}