import {connect} from 'react-redux';
import PageIvrSounds from "./PageIvrSounds";
import {getIvrSoundList} from "../../../actions/ivr_sounds";

const mapState = ({ivr_sounds}) => ({
    data: ivr_sounds.list,
    loading: ivr_sounds.loading,
});

export default connect(mapState, {
    getIvrSoundList,
})(PageIvrSounds);