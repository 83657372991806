import React, {useState, useEffect, useContext} from 'react';
import {Icon, Nav, Navbar, Badge} from 'rsuite';
import {withRouter} from 'react-router';
import ModalEditProfile from '../../components/EditProfile/ModalEditProfile';
import {connect} from 'react-redux';
import '../../components/EditProfile/EditProfile.css'
import styled, {css} from 'styled-components';
import UserProfileMenu from "./UserProfileMenu";
import NavigationMenu from "./MainMenu/MainMenu";
import {defineMessages, useIntl} from "react-intl";
import AccountManagerInfo from "./ManagerInfo";
import BalanceMenu from "./BalanceMenu";
import {useWindowWidth} from "../../hooks";
import {APP_TYPE_ADMIN, APP_TYPE_CLIENT} from "../../const";
import {Link} from 'react-router-dom';
import SkypeInfo from './Contacts/SkypeInfo';
import ContactModal from './Contacts/ContactModal';
import {AppTypeContext} from "../../App";
import _ from 'lodash'
import {checkPermissionsFor} from '../../store/storeHelpers'
import {ACCOUNT_USER_GET_REALTIME_STATUS, REGISTRATION_GET_COUNT_API} from '../../const/apiMethods'


const localMessages = defineMessages({
    myNumbers: {id: 'menu.myNumbers', defaultMessage: "My Numbers", description: 'Menu: My Numbers'},
    testCalls: {id: 'menu.testCalls', defaultMessage: "Test calls", description: 'Menu: Test calls'},
    liveCalls: {id: 'menu.liveCalls', defaultMessage: "Live calls", description: 'Menu: Live calls'},
    report: {id: 'menu.report', defaultMessage: "Report", description: 'Menu: Report'},
    ratesAndNumbers: {id: 'menu.ratesAndNumbers', defaultMessage: "Ratecard", description: 'Menu: Ratecard'},
    accessList: {id: 'menu.accessList', defaultMessage: "Access List", description: 'Menu: Access List'},
    cliLookup: {id: 'menu.cliLookup', defaultMessage: "CLI Lookup", description: 'Menu: CLI Lookup'},
    editProfile: {id: 'menu.editProfile', defaultMessage: "Edit profile", description: 'Menu: Edit profile'},
    ivr: {id: 'menu.ivr', defaultMessage: "IVR", description: 'Menu: IVR'},
    logout: {id: 'menu.logout', defaultMessage: "Logout", description: 'Menu: Logout'},
    unlogin: {id: 'menu.unlogin', defaultMessage: "Log Out", description: 'Menu: Log Out'},
    menu: {id: 'menu.menu', defaultMessage: "Menu", description: 'common: Menu'},
    yourAccountManager: {id: 'menu.yourAccountManager', defaultMessage: "Your account manager", description: 'Menu: Your account manager'},
    payments: {id: 'menu.payments', defaultMessage: "Payments", description: 'Common: Payments'}
});


const Navigation = (
    {
        openAllocationModal,
        logoutUser,
        authInfo = {},
        dialerInfo,
        permissions,
        readyForPayment,

        modifyPasswordRequest,
        modifyAccountRequest,

        registrationCount,
        getRegistrationCount,
        realtimePaymentStatus,
        getRealtimePaymentStatus,

        supportContacts,
        contactText

    }
) => {

    const intl = useIntl();
    const windowWidth = useWindowWidth();
    const [showEditProfileModal, onChangeShowEditProfileModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const appType = useContext(AppTypeContext);

    const isTest = _.get(authInfo, 'session.account.is_test', false);

    useEffect(() => {
        if (!registrationCount && permissions.includes(REGISTRATION_GET_COUNT_API) && appType === APP_TYPE_ADMIN) {
            getRegistrationCount();
        }
        else if (!realtimePaymentStatus && permissions.includes(ACCOUNT_USER_GET_REALTIME_STATUS) && appType === APP_TYPE_CLIENT) {
            if (!isTest) {
                getRealtimePaymentStatus();
            }
        }
    }, [registrationCount, permissions, realtimePaymentStatus, appType]);

    return (
        <TopNavbar>
            <TopNavbar.Header>
                <UserProfileMenu
                    savedUser={authInfo}
                    localMessages={localMessages}
                    appType={appType}
                    onChangeShowEditProfileModal={() => onChangeShowEditProfileModal(true)}
                />
                <ModalEditProfile
                    show={showEditProfileModal}
                    onClose={() => onChangeShowEditProfileModal(false)}
                    {...{
                        authInfo,
                        modifyAccountRequest,
                        modifyPasswordRequest,
                    }}
                />
            </TopNavbar.Header>

            <TopNavbar.Body>
                <NavigationMenu
                    {...{
                        authInfo,
                        localMessages,
                        openAllocationModal,
                        logoutUser,
                        permissions,
                        registrationCount,
                    }}
                />

                <NavbarInfo>

                    {dialerInfo &&
                        <>
                            <BalanceMenu
                                isMobile={windowWidth < 1200}
                                readyForPayment={readyForPayment}
                                {...{
                                    realtimePaymentStatus,
                                    dialerInfo,
                                }}
                            />
                            <AccountManagerInfo
                                isMobile={windowWidth < 1200}
                                {...{
                                    authInfo,
                                    showContactModal,
                                    setShowContactModal,
                                    localMessages
                                }}
                            />
                            <SkypeInfo
                                isMobile={windowWidth < 1200}
                                {...{
                                    dialerInfo,
                                    supportContacts
                                }}
                            />
                        </>
                    }

                    {windowWidth >= 1200 &&
                        <Nav>
                            {checkPermissionsFor(REGISTRATION_GET_COUNT_API) && appType === APP_TYPE_ADMIN &&
                                (registrationCount > 0
                                    ? <StyledBadge content={registrationCount}>
                                        <NavLink
                                            icon={<Icon icon="group"/>}
                                            to="/registration-requests"
                                            eventKey="/registration-requests"
                                        />
                                    </StyledBadge>
                                    : <NavLink
                                        icon={<Icon icon="group"/>}
                                        to="/registration-requests"
                                        eventKey="/registration-requests"
                                    />)
                            }
                            <Nav.Item
                                icon={<Icon icon="sign-out"/>}
                                onClick={logoutUser}
                            >
                                {intl.formatMessage(localMessages.unlogin)}
                            </Nav.Item>
                        </Nav>
                    }
                </NavbarInfo>
            </TopNavbar.Body>
            <ContactModal
                {...{
                    contactText
                }}
                isMobile={windowWidth < 1200}
                show={showContactModal}
                onClose={setShowContactModal}
            />
        </TopNavbar>
    )
};


const mapState = ({auth, accounts, auth_dialer, registration, references})=> ({
    permissions: auth.permissions,
    authInfo: auth.authInfo,
    updatedInfo: auth.authInfo,
    registrationCount: registration.registration_count,
    dialerInfo: auth_dialer.dialerInfo,
    readyForPayment: auth_dialer.readyForPayment,
    supportContacts: references.support_contacts,
    contactText: references.contacts_text,
    realtimePaymentStatus: accounts.realtimePaymentStatus
});

export default withRouter(connect(mapState)(Navigation));

export const createExternalLink = (link, type = null) => {
    let linkString = link;

    if (type === "skype") {
        linkString = `${type}:${linkString}?chat`;
    } else if (type) {
        linkString = `${type}:${linkString}`;
    }
    return <StyledExternalLink href={linkString}>{link}</StyledExternalLink>
}


const NavLink = withRouter(props => (
    <Nav.Item componentClass={Link}
              active={props.location.pathname.indexOf(props.to) === 0}
              {...props}
    />)
);

const StyledExternalLink = styled.a`
    && {
        text-decoration: none;
        color: #FFFFFF;
    }
    &:hover {
        color: #dddddd;
    }
`

const TopNavbar = styled(Navbar).attrs(() => ({
    appearance: "inverse"
}))`
    align-items: stretch;
    display: flex;
    
    .rs-nav-waterline {
        border-top-color: transparent !important;
    }

    .rs-navbar-header {
        align-self: stretch;
        float: none;
        height: auto;
    }

    .rs-navbar-body {
        align-items: stretch;
        display: flex;
        flex-direction: row-reverse;
        flex-grow: 1;
        
        @media (min-width: 1200px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    
    .rs-nav-item-content::before {
        background-color: #ffffff !important;
    }
    
    &&&&& {
        .rs-dropdown-toggle,
        .rs-nav-item-content {
            background-color: transparent !important;
        }
        
        ${props => props.theme.appType === APP_TYPE_CLIENT && css `
            .rs-nav-item-active {
                .rs-dropdown-toggle,
                .rs-nav-item-content {
                    background-color: #16b07e !important;
                }
            }
        `}
        
        .rs-nav-item-content {
            padding: 18px 16px;
            height: 56px;
        }
    }

    &:before {
        display: none;
    }
    
    ${props => props.theme.appType === APP_TYPE_CLIENT && css`
        box-shadow: none !important;
    `}
`;

const NavbarInfo = styled.div`
    display: flex;
`;

const StyledBadge = styled(Badge)`
    & .rs-badge-content {
        margin: 15px;
    }
`;