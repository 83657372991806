import React, {useState, useEffect} from "react";
import {Form, FormControl, Icon, SelectPicker, Uploader, Schema, Divider} from "rsuite";
import * as S from "./styled";
import {useWindowWidth} from "../../../hooks";
import { parse } from 'object-flaser';
import { Spacer } from "../../../components/base/Spacer";
import fields, {
    
    // organization field groups
    customerFields,
    companyFields,

    // payment method field groups
    bankTransferFields, 
    paypalFields, 
    bitcoinFields, 
    hawalaFields, 
    otherTransferSystems
} from "./fields";
import { toBase64 } from "../../../utils/helpers";

const MAX_CHARACTERS = 255;

const methodFields = [
    bankTransferFields,
    paypalFields,
    otherTransferSystems,
    otherTransferSystems,
    hawalaFields,
    bitcoinFields
];

const {StringType, NumberType, ArrayType} = Schema.Types;

const maxFileSize = 5; // MB
const maxFiles = 5;

const defaultValue = {ot_key: 1, pm_key: 1};

export default ({
    show,
    onClose,
    maxWidth = 1000,
    modify = false,
    accountId,

    organizationTypeList,
    countryList,
    paymentMethodList,
    createPaymentDetails,
    modifyPaymentDetails,
    getPaymentDetailsList,

    value = false,
}) => {

    const formModel = Schema.Model({
        // type fields
        ot_key: NumberType().isRequired( "This field is required" ),
        pm_key: NumberType()
            .isRequired( "This field is required" )
            .addRule((value, data) => {
                if (data.ot_key === 2 && value === 5)
                    return false;
            }, 'This method is not allowed with the "company" option'),

        // customer fields
        customer_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        customer_last_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        tax_vat_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        customer_cntr_code: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_address.city': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_address.region': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'customer_address.address': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_address.postal_code': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'customer_phone': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        ext_files: ArrayType()
            .addRule( (value, data) => {
                return (data.ot_key === 2 || (value && value.length > 0)) ? true : false;
            }, "This field is required", true )
            .addRule( (value) => value.length > maxFiles ? false : true, `Maximum number of files is ${maxFiles}` )
            .addRule( (value) => {
                const names = [];
                for (const file of value) {
                    if (file.blobFile && file.blobFile.size > (1024 * 1024 * maxFileSize))
                        names.push(file.name);
                }
                return !names.length
            }, `File size should not exceed ${maxFileSize} MB`),

        // beneficiary fields
        beneficiary_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        beneficiary_last_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`)
            .addRule( (value, data) => {
                if ( !value ) {
                    if (data.ot_key === 2 && data.pm_key === 1)
                        return true;
    
                    return false
                }
            }, "This field is required", true ),
        beneficiary_cntr_code: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'beneficiary_address.region': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'beneficiary_address.city': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'beneficiary_address.address': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'beneficiary_address.postal_code': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),

        // bank transfer fields
        bank_name: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        bank_cntr_code: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'bank_address.region': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'bank_address.city': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        'bank_address.address': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        'bank_address.postal_code': StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`),
        account_iban_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        swift_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        intermediate_bank_details: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),

        // paypal fields
        paypal_id: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        
        // btc fields
        btc_wallet_number: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
        
        // hawala fields
        hawala_description: StringType().maxLength(MAX_CHARACTERS, `The maximum of this field is ${MAX_CHARACTERS}`).isRequired( "This field is required" ),
    });


    let formRef = null;

    const [formValue, setFormValue] = useState(value || defaultValue);
    const [activeFields, setActiveFields] = useState([]);
    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);


    // effect: change form data by defaults
    useEffect(() => {
        if (value) {
            setFormValue({...value});
        } else {
            setFormValue({...defaultValue});
        }
    }, [value]);


    // effect: set active fields
    useEffect( () => {
        const [personal, beneficiary] = setFormFields(formValue.ot_key, formValue.pm_key);
        setActiveFields([...personal, ...beneficiary]);
    }, [formValue.ot_key, formValue.pm_key] );


    // effect: prevent "hawala" && "company" option
    useEffect( () => {
        if (formValue.ot_key === 2 && formValue.pm_key === 5) {
            setFormValue({...formValue, pm_key: 1});
        }
    }, [formValue.ot_key, formValue.pm_key] );


    // submit data
    const handleFormSubmit = async () => {

        const fieldsValidity = [];
        for (const fieldName of activeFields) {
            fieldsValidity.push( formRef.checkForField(fieldName) );
        }
        if (fieldsValidity.includes(false))
            return;

        setLoading(true);
        const formValue = formRef.getFormValue();
        const data = {
            target: {
                account_id: accountId
            },
            ot_key: formValue.ot_key,
            pm_key: formValue.pm_key
        };
        if (modify && formValue.id) {
            delete data.ot_key;
            delete data.pm_key;
            data.target = {
                payment_detail_id: formValue.id
            }
        }

        for (const field of activeFields) {
            
            if (formValue[field] === undefined || formValue[field] === null)
                continue;
           
            if (field === "ext_files") {
                const files = [];
                for (const file of formValue[field]) {
                    const fileBase64 = file.blobFile 
                        ? await toBase64(file.blobFile)
                        : file.url;
                    const [type, content] = fileBase64.split(',');
    
                    files.push( {
                        file: content,
                        type: type.split(';')[0].split(':')[1],
                        fileKey: file.fileKey,
                        name: file.name,
                        status: file.status
                    } );
                }
                data[field] = files;
                continue;
            }

            data[field] = formValue[field];
        }

        const response = !modify
            ? await createPaymentDetails( parse(data) ) 
            : await modifyPaymentDetails( parse(data) );

        if (response) {
            getPaymentDetailsList({target: {account_id: accountId}});
            onClose(response);
        }
        
        setLoading(false);
    };


    const setFormFields = (organizationType, paymentMethod) => {
        // company + hawala
        if (organizationType === 2 && paymentMethod === 5) {
            return [[], []];
        }
        // private person + hawala
        if (organizationType === 1 && paymentMethod === 5) {
            return [[], ['hawala_description']];
        }
        // default
        const organizationFields = organizationType === 1 ? customerFields : companyFields;
        return [organizationFields, methodFields[paymentMethod - 1]]
    };


    const renderFields = (fieldNames = [], formValue) => (
        fieldNames.map((fieldName) => {
            const {type, name, label: defaultLabel, labelId, hideOn, ...props} = fields[fieldName];

            // hide on option
            if (hideOn && Array.isArray(hideOn)) {
                for (const conditionItem of hideOn) {
                    const keys = Object.keys(conditionItem || {});
                    const status = keys.map(key => {
                        return formValue[key] && formValue[key] === conditionItem[key];
                    });
                    
                    if (!status.includes(false))
                        return null;
                }
            }

            // get label
            const label = defaultLabel;

            switch (type) {
                case 'select_country':
                    return <FieldSelectCountry key={name} name={name} label={label} data={countryList} {...props} />;

                case 'file':
                    const label_ = formValue.ot_key === 1 
                        ? "Passport scans"
                        : "Certificate of Incorporation";
                    return <FieldFile key={name} name={name} defaultFileList={formValue[name]} label={label_} {...props} />;

                default:
                    return <FieldInput key={name} name={name} label={label} {...props} />
            }
        })
    );


    const clearForm = () => {
        setFormValue(defaultValue);
    };


    const cleanErrors = () => {
        formRef.cleanErrors();
    };

    return (
        <S.FormModal
            {...{show, onClose}}
            title={modify 
                ? "Modify payment details"
                : "Add new payment details"
            }
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            onSuccessClose={false}
            successButton="Save changes"
            loading={loading}
            keyboard={false}
            onSuccess={handleFormSubmit}
            onClose={() => {
                onClose(false);
            }}
            onExited={() => {
                clearForm();
            }}
        >

                <Form
                    ref={ref => formRef = ref}
                    model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >
                    <S.FlexBlock>
                        <S.FlexChild>
                            <S.Group>
                                <S.Label>Choose organization type</S.Label>
                                <FormControl
                                    name="ot_key"
                                    accepter={SelectPicker}
                                    data={organizationTypeList}
                                    labelKey="name"
                                    valueKey="ot_key"
                                    cleanable={false}
                                    searchable={false}
                                    disabled={modify}
                                    onChange={cleanErrors}
                                />
                            </S.Group>
                        </S.FlexChild>

                        <S.FlexChild>
                            <S.Group>
                                <S.Label>Payment method</S.Label>
                                <FormControl
                                    name="pm_key"
                                    accepter={SelectPicker}
                                    data={paymentMethodList}
                                    labelKey="name"
                                    valueKey="pm_key"
                                    cleanable={false}
                                    searchable={false}
                                    disabled={modify}
                                    disabledItemValues={formValue.ot_key === 2 ? [5] : []}
                                    onChange={cleanErrors}
                                />
                            </S.Group>
                        </S.FlexChild>

                    </S.FlexBlock>

                    <Spacer size={30}/>

                    <S.FormColumns>
                        {setFormFields(formValue.ot_key, formValue.pm_key)
                            .map((fieldsGroup, index, arr) => {
                                if (!fieldsGroup.length)
                                    return null;
                                
                                return (
                                    <>
                                        {index > 0 && arr[0].length > 0 && 
                                            <Divider style={{width: 0}} vertical />
                                        }
                                        <S.FormColumn colWidth="50%">
                                            {renderFields(fieldsGroup, formValue)}
                                        </S.FormColumn>
                                    </>
                                )
                            })
                        }
                    </S.FormColumns>
                </Form>
        </S.FormModal>
    )
};


const FieldInput = ({name, label, type, ...props}) => (
    <S.Group>
        <S.LabelWrapper>
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>
        <S.Field name={name} {...props}/>
    </S.Group>
);

const FieldSelectCountry = ({name, label, type, data, ...props}) => (
    <S.Group>
        <S.LabelWrapper>
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>
        <S.Field
            name={name}
            accepter={SelectPicker}
            cleanable={false}
            data={data}
            valueKey="cntr_code"
            labelKey="name"
            {...props}
        />
    </S.Group>
);

const FieldFile = ({name, label, type, defaultFileList, ...props}) => (
    <S.Group>
        <S.LabelWrapper 
            vAlign="flex-start" 
            top={18}
        >
            <S.Label>{label}</S.Label>
        </S.LabelWrapper>

        <S.FileUploader 
            name={name}
            defaultFileList={defaultFileList || []}
            accepter={Uploader}
            {...props}
        >
            <button><Icon icon='file-image-o' size="2x" /></button>
        </S.FileUploader>
    </S.Group>
)