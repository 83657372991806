import React from 'react';
import PageHeader from '../../components/base/PageHeader';
import PanelLayout from '../../components/base/PanelLayout';
import AccessListExceptionTable from './AccessListExceptionTable';
import AccessListExceptionFilter from './AccessListExceptionFilter';
import AccessListExceptionAdd from './AccessListExceptionAdd';
import {connect} from 'react-redux';
import {
    getAccessExclusionList, 
    onAddAccessExclusionItem, 
    onEditAccessExclusionItem, 
    onRemoveAccessExclusionItem
} from 'actions/access_list';
import {getReferencesForReports} from 'actions/references';
import ModalExceptionRemove from "./ModalExceptionRemove";
import './AccessListException.css';

class AccessListException extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: null,
            isLoading: false,
            showModalRemove: false,
            modalRemoveData: null,
        }
    }

    componentDidMount() {
        const {getAccessExclusionList, getReferencesForReports} = this.props;
        getAccessExclusionList();
        getReferencesForReports();
    }

    onChangeFilter = (filter) => {
        this.setState({
            filter: filter,
            isLoading: false,
        });
    };

    handleShowModalRemove = (data) => {
        this.setState({
            showModalRemove: true,
            modalRemoveData: data
        });
    }

    removeExceptionList = () => {
        const {modalRemoveData} = this.state;
        const {onRemoveAccessExclusionItem, getAccessExclusionList} = this.props;
        onRemoveAccessExclusionItem(modalRemoveData)
            .then(() => {
                getAccessExclusionList();
                this.setState({showModalRemove: false});
            })
    }

    render() {
        const {filter, isLoading, showModalRemove} = this.state;
        const {
            access_list_exclusion, onRemoveAccessExclusionItem, onEditAccessExclusionItem, worldzoneList, clientList,
            destinationList, subdestinationList, loading, onAddAccessExclusionItem, getAccessExclusionList
        } = this.props;

        return (
            <PanelLayout>
                <PageHeader title="Exceptions for access lists"/>

                <AccessListExceptionFilter
                    onChange={this.onChangeFilter}
                />
                <AccessListExceptionTable
                    data={access_list_exclusion}
                    onRemoveItem={this.handleShowModalRemove}
                    onEditItem={onEditAccessExclusionItem}
                    update={getAccessExclusionList}
                    {...{
                        filter,
                        loading,
                        isLoading,
                        clientList,
                        worldzoneList,
                        destinationList,
                        subdestinationList,
                    }}
                />
                <AccessListExceptionAdd
                    addItem={onAddAccessExclusionItem}
                    update={getAccessExclusionList}
                    {...{
                        clientList,
                        worldzoneList,
                        destinationList,
                        subdestinationList,
                    }}
                />
                <ModalExceptionRemove
                    show={showModalRemove}
                    handleClose={() => this.setState({showModalRemove: false})}
                    handleSuccess={this.removeExceptionList}
                />
            </PanelLayout>
        );
    }
}

const mapState = ({access_list, references}) => ({
    access_list_exclusion: access_list.access_list_exclusion,
    worldzoneList: references.worldzone_list,
    destinationList: references.destination_list,
    subdestinationList: references.subdestination_list,
    clientList: references.client_list,
});

export default connect(mapState,
    {
        getAccessExclusionList,
        onAddAccessExclusionItem,
        onEditAccessExclusionItem,
        onRemoveAccessExclusionItem,
        getReferencesForReports
    })(AccessListException);