import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {Whisper, Tooltip} from 'rsuite';
import m from "../../../definedMessages";

export default ({onClick, ...props}) => {

    // const computedStyle = getComputedStyle(document.documentElement);

    return (
        <Whisper
            placement="top"
            trigger="hover"
            speaker={
                <Tooltip>
                    <FormattedMessage 
                        {...m.clickToEnable}
                        values={{
                            colored: str => {
                                return (
                                    <span 
                                        key="colored" 
                                        style={{color: '#4ccc37'}}
                                    >
                                        {str}
                                    </span>
                                )
                            }
                        }}
                    />
                </Tooltip>
            }
        >

            {props.children || <Content onClick={onClick} />}
        </Whisper>
    )
}

const Content = styled.div`
    cursor: not-allowed;
    height: 100%;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;
    z-index: 10000;
    left: 0;
    top: 0;
`;