import {Component} from 'react';
import React from 'react';
import Form from 'rsuite/es/Form';

export class CustomizableForm extends Component {

    renderFields = (children) => {
        const {disabled} = this.props;

        return React.Children.map(children, child => {

            // return a child that is not a React element
            if (!React.isValidElement(child)) {
                return child
            }

            const props = {};

            // add status "disabled" to FormControl
            if (this.props.formDefaultValue) {
                if (Object.keys(this.props.formDefaultValue).includes(child.props.name)) {
                    props['disabled'] = disabled;
                }
            }

            // clone element children if it has
            if (child.props.children) {
                child = React.cloneElement(child, {
                    ...props,
                    children: this.renderFields(child.props.children)
                })
            } else {
                child = React.cloneElement(child, props)
            }

            // return cloned child
            return child
        })
    };

    render() {
        const {children} = this.props;

        return (
            <Form
                {...this.props}
            >
                {this.renderFields(children)}
            </Form>
        )
    }
}