import { connect } from 'react-redux';
import {createAccount} from '../actions/accounts';
import CreateAccount from '../pages/NewAccount';
import {getAccountManagersList} from "../actions/account_managers";

const mapState = ( {accounts, references, account_managers} )=> ({
    account_manager_list: account_managers.list,
    currency_list:references.currency_list,
    payment_terms_list: references.payment_terms_list,
    loadingReferences: references.loading,
    client_role_list: references.client_role_list,
    admin_role_list: references.admin_role_list,
    registeredAccount: accounts.registeredAccount
});

export default connect( mapState, {
    createAccount,
    getAccountManagersList
})(CreateAccount);
  