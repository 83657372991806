import React, {memo, useState, useMemo} from 'react';
import {Icon, Table} from 'rsuite';
import * as S from './styled';
import SettingsEditModal from 'pages/Settings/SettingsEditModal';
import {isObject} from 'utils/helpers';
import {SCREEN_MEDIA} from 'const';
import BaseTable from "../../components/base/BaseTable";

const {Column, HeaderCell, Cell} = Table;

const settingTypes = [
    {name: 'Number', type: 0},
    {name: 'Text', type: 1},
    {name: 'JSON Object', type: 2}
];

const getDataKey = (type) => {
    return {
        0: "s_as_integer",
        1: "s_as_string",
        2: "s_as_json"
    }[type]
};

export default memo(({
    dataList = [],
    onEditItem,
    loading,
    windowWidth,
    getList,
}) => {

    const isMobile = useMemo(() => windowWidth <= SCREEN_MEDIA.md.min, [windowWidth]);
    const [editModalData, onChangeEditData] = useState({});

    return (
        <>
            <BaseTable
                virtualized
                className="tableFilters"
                data={dataList}
                loading={loading}
                headerHeight={47}
                rowHeight={46}
                autoHeight
            >

                <Column flexGrow={2} minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Name</span>
                    </HeaderCell>
                    <S.CustomCell dataKey={"name"}>
                        {(rowData) => (
                            <span className="tableFilters__previewText">{rowData["name"]}</span>
                        )}
                    </S.CustomCell>
                </Column>

                <Column flexGrow={1} minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Type</span>
                    </HeaderCell>
                    <Cell dataKey={"type"}>
                        {(rowData) => {
                            let typeName = null;

                            if (!rowData["type"]) {
                                const comingValues = [0, 1, 2]
                                    .map(type => rowData[getDataKey(type)])
                                    .filter(value => !!value);

                                typeName = comingValues.length
                                    ? settingTypes.find(item => item.type === comingValues.indexOf(comingValues[0]))["name"]
                                    : null;

                            } else {
                                typeName = settingTypes.find(item => item.type === rowData["type"])["name"]
                            }
                            return (
                                <span className="tableFilters__previewText">{typeName}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={3} minWidth={130} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Description</span>
                    </HeaderCell>
                    <Cell dataKey={"description"}>
                        {({description}) => (
                            <span className="tableFilters__previewText">{description}</span>
                        )}
                    </Cell>
                </Column>

                <Column flexGrow={3} minWidth={130} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Value</span>
                    </HeaderCell>
                    <Cell dataKey={"sde_name"}>
                        {(rowData) => {
                            const key = getDataKey(rowData["type"]);
                            let value = rowData[key] ? rowData[key] : null;

                            if (!value && value !== 0) {

                                const comingValues = [0, 1, 2]
                                    .map(type => rowData[getDataKey(type)])
                                    .filter(value => !!value);

                                value = comingValues.length
                                    ? comingValues[0]
                                    : null;
                            }
                            return (!isObject(value)
                                ? <span className="tableFilters__previewText">{value}</span>
                                : <>It is a JSON Object</>)
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => {
                            const key = getDataKey(rowData["type"]);

                            return (<div className="tableFilters_buttons">
                                <S.StyledEditButton
                                    color="lightblue"
                                    disabled={rowData['type'] === null}
                                    onClick={() => {
                                        onChangeEditData({...rowData, key, typeStr: settingTypes.find(st => st.type === rowData['type']).name})
                                    }}>
                                    {isMobile ? '': 'Edit '}<Icon icon="edit2"/>
                                </S.StyledEditButton>
                            </div>)
                        }}
                    </Cell>
                </Column>

            </BaseTable>

            <SettingsEditModal
                show={editModalData && Object.keys(editModalData).length}
                dataObj={editModalData}
                valueKey={editModalData && editModalData['key']}
                isJson={isObject(editModalData) && editModalData['s_as_json']}
                onEditItem={onEditItem}
                isMobile={isMobile}
                windowWidth={windowWidth}
                getList={getList}
                onClose={() => onChangeEditData({})}
            />
        </>
    )
});