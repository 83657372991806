import React from 'react';
import JsSIP from 'jssip';

export default class Session extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ringing: false
        };

        this._localClonedStream = null;
    }

    componentDidMount() {
        const localVideo = this.refs.localVideo;
        const session = this.props.session;
        const peerconnection = session.connection;
        const localStream = peerconnection.getLocalStreams()[0];
        const remoteStream = peerconnection.getRemoteStreams()[0];

        // Handle local stream
        if (localStream) {
            this._localClonedStream = localStream.clone();
            localVideo.srcObject = this._localClonedStream;
        }

        // If incoming all we already have the remote stream
        if (remoteStream) {
            this._handleRemoteStream(remoteStream);
        }

        session.on('progress', () => {
            console.log("session progress");
            if (session.direction === 'outgoing') {
                this.setState({ringing: true});
            }
        });

        session.on('accepted', () => {
            console.log("session accepted");
            this.setState({ringing: false});
        });

        session.on('failed', () => {
            console.log("session failed");
            if (session.direction === 'outgoing')
                this.setState({ringing: false});
        });

        session.on('ended', () => {
            console.log("session ended");
            if (session.direction === 'outgoing') {
                this.setState({ringing: false});
            }
        });

        peerconnection.addEventListener('addstream', (event) => {
            this._handleRemoteStream(event.stream);
        });
    }


    componentWillUnmount() {
        JsSIP.Utils.closeMediaStream(this._localClonedStream);
    }


    _handleRemoteStream(stream) {
        const remoteVideo = this.refs.remoteVideo;
        // Display remote stream
        remoteVideo.srcObject = stream;
        stream.addEventListener('addtrack', () => {
            if (remoteVideo.srcObject !== stream)
                return;
            // Refresh remote video
            remoteVideo.srcObject = stream;
        });

        stream.addEventListener('removetrack', () => {
            if (remoteVideo.srcObject !== stream)
                return;
            // Refresh remote video
            remoteVideo.srcObject = stream;
        });
    }


    render() {
        return (
            <div style={{display: 'none'}}>
                <video ref='localVideo' autoPlay muted />
                <video ref='remoteVideo' autoPlay />
            </div>
        );
    }
}