import { connect } from 'react-redux';
import { 
    getAccounts, 
    setAccountsCurrentApiRequestStr 
} from '../actions/accounts';
import Accounts from '../pages/Accounts';
import {getAccountManagersList} from "../actions/account_managers";

const mapState = ( {accounts, account_managers, references} )=> ({
    loading: accounts.loading, 
    accounts: accounts.items,
    paymentTermsList: references.payment_terms_list,
    account_manager_list: account_managers.list,
});

export default connect( mapState, {
    getAccounts, 
    setAccountsCurrentApiRequestStr,
    getAccountManagersList,
})(Accounts);
  