import { connect } from 'react-redux';
import { getLiveCalls, stopCall } from '../../actions/live_calls';
import LiveCalls from './LiveCalls';

const mapState = ( {live_calls} )=> ({
    loading: live_calls.loading,
    live_calls_list: live_calls.live_calls_list,
    loadingItem: live_calls.loadingItem
});

export default connect( mapState, {getLiveCalls, stopCall})(LiveCalls);
