import React from 'react';
import {FormControl, Schema, SelectPicker} from 'rsuite'
import {Form, FormGroup} from 'rsuite';
import Checkbox from '../../hoc/Checkbox';
import CheckPicker from '../../hoc/CheckerPicker';
import FormHOC from '../../hoc/FilltersForm';
import * as S from "./styled";
import {Collapse} from "react-collapse";
import {Spacer} from "../../components/base/Spacer";

const {ArrayType} = Schema.Types;
const accFiltersModel = Schema.Model({
    account_manager: ArrayType()
});


export default FormHOC( ( {
    onChange, 
    account_manager_list,
    paymentTermsList,
    defaultValue,
    isMobile,
    show = false
} ) => {

    return (
        <>
            <Collapse isOpened={!isMobile || show}>
                <Form
                    onChange={onChange}
                    model={accFiltersModel}
                    formDefaultValue={defaultValue}
                    fluid
                >
                    {isMobile && <Spacer />}
                     <S.Grid
                         noWrap={!isMobile}
                         noGutters={isMobile}
                     >
                        <S.GridItem isFullWidth={isMobile}>
                            <FormGroup
                                style={{paddingBottom: isMobile ? '20px' : 0}}
                            >
                                <CheckPicker
                                    sticky
                                    data={account_manager_list.filter(item => item.is_managed)}
                                    labelKey="name"
                                    valueKey="id"
                                    style={{width: 224}}
                                    placeholder="All account manager"
                                    name="account_managger"
                                />
                            </FormGroup>
                        </S.GridItem>

                        <S.GridItem isFullWidth={isMobile}>
                            <FormGroup
                                style={{paddingBottom: isMobile ? '20px' : 0}}
                            >
                                <FormControl
                                    accepter={SelectPicker}
                                    data={paymentTermsList}
                                    labelKey="name"
                                    valueKey="pt_key"
                                    style={{width: 224}}
                                    placeholder="Payment term"
                                    name="payment_term"
                                    searchable={false}
                                />
                            </FormGroup>
                        </S.GridItem>

                        <S.GridItem isFullWidth={isMobile}>
                            <Checkbox
                                name="traffic_check"
                                defaultChecked={defaultValue.traffic_check}
                            >
                                Only with traffic
                            </Checkbox>
                        </S.GridItem>

                        <S.GridItem isFullWidth={isMobile}>
                            <Checkbox
                                name="allocated_check"
                                defaultChecked={defaultValue.allocated_check}
                            >
                                Only with Allocated numbers
                            </Checkbox>
                        </S.GridItem>

                        <S.GridItem isFullWidth={isMobile}>
                            <Checkbox
                                name="closed_check"
                                defaultChecked={defaultValue.closed_check}
                            >
                                Show closed accounts
                            </Checkbox>
                        </S.GridItem>

                    </S.Grid>
                </Form>
            </Collapse>
        </>
    );
}, accFiltersModel, 500);