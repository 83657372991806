import {connect} from "react-redux";
import {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    cancelPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
} from "../../../actions/payments";
import {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
} from "../../../actions/paymentDetails";
import TabPaymentManagement from "./TabPaymentManagement";
import {getDropdownAccounts} from "../../../actions/dropdown_accounts";

const mapStateToProps = ({payments, paymentDetails, accounts, dropdown_accounts, references}) => ({
    paymentItems: payments.paymentItems,
    paymentItemsLoading: payments.paymentItemsLoading,

    paymentRequestItems: payments.paymentRequestItems,
    paymentRequestLoading: payments.paymentRequestLoading,
    
    paymentTrafficReportsItems: payments.paymentTrafficReportsItems,
    paymentTrafficReportsLoading: payments.paymentTrafficReportsLoading,
    
    paymentDetailsItems: paymentDetails.items,
    paymentDetailsLoadinig: paymentDetails.loading,
    
    accountInfo: accounts.accountView,
    accounts: dropdown_accounts.items,
    
    organizationTypeList: references.organization_type_list,
    countryList: references.country_list,
    paymentMethodList: references.payment_method_list,
});

export default connect(mapStateToProps, {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    cancelPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
    
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails,

    getAccounts: getDropdownAccounts
})(TabPaymentManagement);