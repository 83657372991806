import React from 'react';
import PanelLayout from '../../components/base/PanelLayout';
import PageHeader from '../../components/base/PageHeader';
import {FlexboxGrid} from 'rsuite';
import {
    getBlockedDestinationsList,
    onAddBlockDestinationsItem,
    onEditBlockDestinationsItem,
    onRemoveBlockDestinationsItem,
    onEditBlockDestinationsItemList
} from 'actions/blocked_destinations';
import {connect} from 'react-redux';
import BlockedDestinationsFilters from 'pages/BlockedDestinations/BlockedDestinationsFilters';
import BlockedDestinationsTable from 'pages/BlockedDestinations/BlockedDestinationsTable';
import BlockedDestinationsAdd from 'pages/BlockedDestinations/BlockedDestinationsAdd';

class BlockedDestinationsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: {},
            isLoading: false
        }
    }

    componentDidMount () {
        const {filter} = this.state;
        const {getBlockedDestinationsList} = this.props;
        getBlockedDestinationsList(filter)
    }

    onChangeFilters = (filter) => {
        const {getBlockedDestinationsList} = this.props;
        getBlockedDestinationsList(filter);
        this.setState({filter: filter});
    };

    render () {
        const {filter} = this.state;
        const {loading, blockedDestinationsList, getBlockedDestinationsList, worldzoneList, destinationList,
            subdestinationList, servicePlanList, accountList, onAddBlockDestinationsItem, onEditBlockDestinationsItem,
            onRemoveBlockDestinationsItem, onEditBlockDestinationsItemList
        } = this.props;

        return (
            <PanelLayout>
                <PageHeader title="Blocked Destinations"/>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <BlockedDestinationsFilters onSubmit={this.onChangeFilters} defaultFilters={filter}/>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <BlockedDestinationsTable
                    data={blockedDestinationsList}
                    update={() => getBlockedDestinationsList(filter)}
                    onEditItem={onEditBlockDestinationsItem}
                    onEditItemList={onEditBlockDestinationsItemList}
                    onRemoveItem={onRemoveBlockDestinationsItem}
                    onAddItem={onAddBlockDestinationsItem}
                    {...{
                        filter,
                        loading,
                        accountList,
                        servicePlanList,
                        worldzoneList,
                        destinationList,
                        subdestinationList,
                    }}
                />
                <BlockedDestinationsAdd
                    addItem={onAddBlockDestinationsItem}
                    update={() => getBlockedDestinationsList(filter)}
                    {...{
                        accountList,
                        servicePlanList,
                        worldzoneList,
                        destinationList,
                        subdestinationList,
                    }}
                />
            </PanelLayout>
        )
    };
}

const mapStateToProps = ({blocked_destinations, references}) => ({
    blockedDestinationsList: blocked_destinations.list,
    worldzoneList: references.worldzone_list,
    destinationList: references.destination_list,
    subdestinationList: references.subdestination_list,
    servicePlanList: references.service_plan_list,
    accountList: references.dialer_list,
    loading: blocked_destinations.loading
});

const mapDispatchToProps = {
    getBlockedDestinationsList,
    onAddBlockDestinationsItem,
    onEditBlockDestinationsItem,
    onRemoveBlockDestinationsItem,
    onEditBlockDestinationsItemList
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedDestinationsPage);