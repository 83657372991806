import {
    API_PAGE_DIALER as PREFIX,
    LOCAL_STORAGE_API_KEY_DIALER,
} from '../../const';
import {
    API_PAGE_LIST_DIALER_API,
    API_GENERATE_NEW_DIALER_API
} from "../../const/apiMethods";
import {api} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {Alert} from 'rsuite';


const setKeyList = _setValue('SET_ITEMS_' + PREFIX);
const setGeneratedApiKey = _setValue(API_GENERATE_NEW_DIALER_API);
const setKeyListLoading = _setValue('SET_LOADING_' + PREFIX);

export const getKeyList = () => (dispatch) => {
    dispatch(setKeyListLoading(true));

    api(API_PAGE_LIST_DIALER_API, ).then((response) => {
        if (response !== undefined) {
            dispatch(setKeyList({
                keyList: response.account_apikey_list || []
            }));
        }
    });
};

export const generateNewApiKey = (api_key) => (dispatch) => {
    api(API_GENERATE_NEW_DIALER_API, {target: {api_key}}).then((response) => {
        if (response !== undefined) {
            const res_api_key = response.account_apikey.api_key;

            api(API_PAGE_LIST_DIALER_API).then((response) => {
                if (response !== undefined) {
                    dispatch(setGeneratedApiKey({
                        apiKey: res_api_key
                    }));
                    dispatch(setKeyList({
                        keyList: response.account_apikey_list || []
                    }));
                }
            });
            localStorage.setItem(LOCAL_STORAGE_API_KEY_DIALER, res_api_key);
            Alert.success(`New api key ${res_api_key}`);
        }
    });
};
