import React, {useLayoutEffect, useState} from 'react';
import NumberForm from './NumberForm';
import MassForm from './MassForm';
import ListForm from './ListForm';
import SequenceForm from './SequenceForm';
import TemplateListForm from './TemplateListForm';
import GoogleOTPForm from './GoogleOTPForm';
import ModalSendApi from '../../../../../components/Modal/ModalSendApi';
import {connect} from 'react-redux';
import Tabs from './Tabs';
import DialerTrunk from '../../../../../components/Filters/DialerTrunk';
import {getDialerTrunk} from '../../../../../actions/references';
import AllocationResult from '../../../../../components/Modal/ModalAllocationResult';
import AccountsGetForm from '../../../../../components/Filters/AccountsGetForm';
import styled from 'styled-components';
import {useWindowWidth} from "../../../../../hooks";
import { SP_OTP_TYPE } from 'const';
import DialerTrunkDropdown from "../../../../../components/Filters/DialerTrunkDropdown";
import {pageIdLogObject} from "../../../../../utils/pageIdLogger";

const AllocateModal = ({
    show,
    getDialerTrunk,
    disabledTrunk = false,
    activeTrunk: _activeTrunk,
    trunk_id: _trunk_id,
    sp_key: _sp_key,
    sp_auth: _sp_auth,
    spOtpType: _spOtpType,
    update,
    onClose,
    isDialerTrunkFiltered = false,
    client_allocation_limit,
    account_id: _account_id,
    dialerTrunkList,
    getTrunkNumberCsvById,
    pageId = null,
    match = {},
}) => {

    const windowWidth = useWindowWidth();

    const [activeTab, setActiveTab] = useState('numberForm');

    const [activeTrunk, setActiveTrunk] = useState(_activeTrunk);
    React.useEffect(() => setActiveTrunk(_activeTrunk), [_activeTrunk]);

    const [spAuth, setSpAuth] = useState(_sp_auth);
    React.useEffect(() => setSpAuth(_sp_auth), [_sp_auth]);

    const [allocateByPrefixesStatus, setAllocateByPrefixesStatus] = React.useState(spAuth);
    React.useEffect(() => setAllocateByPrefixesStatus(spAuth), [spAuth]);

    const [spOtpType, setSpOtpType] = useState(_spOtpType);
    React.useEffect(() => setSpOtpType(_spOtpType), [_spOtpType]);

    const [sp_key, setSpKey] = useState(_sp_key);
    React.useEffect(() => setSpKey(_sp_key), [_sp_key]);

    const [trunk_id, setTrunkId] = useState(_trunk_id);
    React.useEffect(() => setTrunkId(_trunk_id), [_trunk_id]);

    const [account_id, setAccountId] = useState(_account_id);
    React.useEffect(() => setAccountId(_account_id), [_account_id]);

    const [totalNumbersForced, setTotalNumbersForced] = useState(null)
    const [showResult, setShowResult] = useState(false);
    const [hideBufferButtons, setHideBufferButtons] = useState(false);
    const [filteredAccounts, setFilteredAccounts] = React.useState(null);
    const [filteredAccountsLoading, setFilteredAccountsLoading] = React.useState(false);
    const [csvContent, setCsvContent] = React.useState(null);
    const [summaryState, setSummaryState] = React.useState(null);
    const [transactionId, setTransactionId] = React.useState(null);

    const [notAllocatedNumbers, setNotAllocatedNumbers] = React.useState([]);

    useLayoutEffect(() => {
        if (!pageId)
            return;
        if (show || showResult) {
            pageIdLogObject.pageMatch = {...match, path: '/number-allocation'};
        } else {
            pageIdLogObject.pageMatch = {
                ...match,
                path: match.path + `${pageIdLogObject.lastTab 
                    ? "/" + pageIdLogObject.lastTab 
                    : ""
                }`
            };
        }
    }, [show, showResult]);

    React.useEffect(() => {
        if (show) {
            getDialerTrunk();
        }
    }, [show]);
    
    React.useEffect(() => {
        if (show && spOtpType !== SP_OTP_TYPE && activeTab === "googleOTP") {
            setActiveTab('numberForm');
        }
    }, [show, activeTab, spOtpType]);


    const onSelect = (activeKey) => setActiveTab(activeKey);


    const handleChangeDialerTrunk = (trunk = {}) => {
        trunk.trunk_id && setTrunkId(trunk.trunk_id);
        trunk.sp_key && setSpKey(trunk.sp_key);
        trunk.acc_uuid && setAccountId(trunk.acc_uuid);
        setSpOtpType(trunk.sp_otp_type);
        setSpAuth(trunk.sp_auth);
        setActiveTrunk({...trunk, name: trunk.tr_name});

        if ((trunk.sp_otp_type !== SP_OTP_TYPE) && activeTab === 'googleOTP') {
            setActiveTab('numberForm');
        }
    };


    const clearChosenTrunkInfo = () => {
        setTrunkId(null);
        setSpKey(1);
        setAccountId(null);
        setSpAuth(null);
        setActiveTrunk(null);
    };

    return (
        <>
            {show &&

            <ModalSendApi
                title="Allocate numbers"
                successText="Allocate"
                update={update}
                extraDisabled={!trunk_id}
                onClose={onClose}
                width={windowWidth < 800 ? windowWidth : 800}
                checkFirstAll
            >

                <>
                    {windowWidth < 800 &&
                        <Tabs
                            activeTab={activeTab}
                            onSelect={onSelect}
                            spKey={sp_key}
                            spAuth={spAuth}
                            isMobile={true}
                            spOtpType={spOtpType}
                        />
                    }
                </>

                <>
                    {isDialerTrunkFiltered &&
                    <AccountsGetFormWrapper>
                        <AccountsGetForm
                            setLoading={setFilteredAccountsLoading}
                            setFilteredAccounts={setFilteredAccounts}
                        />
                    </AccountsGetFormWrapper>
                    }
                </>

                {windowWidth >= 568
                    ? <DialerTrunk
                        trunkId={trunk_id}
                        onClear={clearChosenTrunkInfo}
                        onChange={handleChangeDialerTrunk}
                        disabledTrunk={disabledTrunk || filteredAccountsLoading}
                        accounts={filteredAccounts}
                        dialerTrunkList={dialerTrunkList}
                    />
                    : <DialerTrunkDropdown
                        trunkId={trunk_id}
                        onClear={clearChosenTrunkInfo}
                        onChange={handleChangeDialerTrunk}
                        disabledTrunk={disabledTrunk || filteredAccountsLoading}
                        accounts={filteredAccounts}
                        dialerTrunkList={dialerTrunkList}
                    />
                }

                <>
                    {windowWidth >= 800 &&
                        <Tabs
                            activeTab={activeTab}
                            onSelect={onSelect}
                            spKey={sp_key}
                            spAuth={spAuth}
                            isMobile={false}
                            spOtpType={spOtpType}
                        />
                    }
                </>

                {getForm({
                    activeTab,
                    onSelect,
                    activeTrunk,
                    target: {trunk_id},
                    sp_key,
                    spAuth: spAuth,
                    allocateByPrefixesStatus,
                    setAllocateByPrefixesStatus,
                    showResult: () => setShowResult(true),
                    setSummaryState,
                    setTotalNumbersForced,
                    getTrunkNumberCsvById,
                    setCsvContent,
                    setTransactionId,
                    account_id,
                    setHideBufferButtons,
                    setNotAllocatedNumbers,
                    allocationLimit: client_allocation_limit,
                    isMobile: windowWidth < 800,
                })}

            </ModalSendApi>
            }

            <AllocationResult
                show={showResult}
                accountId={account_id}
                summaryState={summaryState}
                totalNumbersForced={totalNumbersForced}
                allocatedByPrefix={allocateByPrefixesStatus}
                trunkId={trunk_id}
                showFullList={activeTab !== 'massForm'}
                hideBufferButtons={hideBufferButtons}
                csvContent={csvContent}
                transactionId={transactionId}
                notAllocatedNumbers={notAllocatedNumbers}
                pageId={pageId}
                isGoogleOtp={activeTab === "googleOTP"}
                
                allocatedByOTP={spOtpType || spOtpType === 0}
                onClose={() => {
                    setShowResult(false);

                    if (totalNumbersForced)
                        setTotalNumbersForced(null);

                    if (csvContent)
                        setCsvContent(null);
                }}
            />
        </>
    );
};


const getForm = (props) => {
    switch (props.activeTab) {
        case 'numberForm':
            return <NumberForm {...props} />;
        case 'massForm':
            return <MassForm {...props} />;
        case 'listForm':
            return <ListForm {...props} />;
        case 'templateListForm':
            return <TemplateListForm {...props} />;
        case 'sequenceForm':
            return <SequenceForm {...props} />;
        case 'googleOTP':
            return <GoogleOTPForm {...props} />;
        default:
            return null;
    }
};


const mapState = ({references}) => ({
    client_allocation_limit: references.client_allocation_limit,
    dialerTrunkList: references.dialerTrunkList,
});


export default connect(mapState, {getDialerTrunk})(AllocateModal);


const AccountsGetFormWrapper = styled.div`
    margin-bottom: 10px;
`;