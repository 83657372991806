import styled from 'styled-components';
import { Form, InputGroup, FormControl } from 'rsuite';

import {SCREEN_MEDIA} from "../../../const";

const {sm} = SCREEN_MEDIA;

export const PaginationContainer = styled.div.attrs(() => ({
    className: "pagination-container"
}))`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    padding-top: 20px;

    @media (min-width: ${sm.min}px) {
        justify-content: stretch;
    }
    
    && {
        .rs-pagination {
            border: 1px solid var(--color-grey);
            border-radius: 3px;
            
            a {
                color: #c4c4c4;
                border-right: 1px solid #c4c4c4;
                border-radius: 0 !important;
            }
            
            .rs-pagination-btn:last-child a {
                border-right: none;
            }
            
            & > li.rs-pagination-btn-active > a,
            & > li.rs-pagination-btn-active > a:hover,
            & > li.rs-pagination-btn-active > a:focus,
            & > li.rs-pagination-btn-active > a:active,
            & > li.rs-pagination-btn-active > a:active:hover,
            & > li.rs-pagination-btn-active > a:active:focus,
            & > li.rs-pagination-btn-active > a:active.focus,
            & > li.rs-pagination-btn-active > a.focus {
                color: #fff;
                background-color: var(--color-brand2);
            }
        }
    }
`;

export const PaginationForm = styled(Form).attrs(() => ({
    className: "pagination-form"
}))`
    flex-shrink: 1;
    margin-left: 5px;
    position: relative;

    @media (min-width: ${sm.min}px) {
        margin-left: 15px
    }

    .rtl & {
        margin-left: 0;
        margin-right: 5px;

        @media (min-width: ${sm.min}px) {
            margin-right: 15px
        }
    }
`;

export const PaginationFormGroup = styled(InputGroup).attrs(() => ({
    className: "pagination-group"
}))`
    
    &&& {
        border-color: var(--color-grey);
        border-radius: 3px;
        padding: 0px;
        width: auto;
    }
`;

export const PaginationFormGroupCount = styled(InputGroup.Addon).attrs(() => ({
    className: "pagination-group-count"
}))`
    &&& {
        background-color: var(--color-light);
        padding: 5px; 
        min-width: 20px; 
        padding-left: 2px;

        .rtl & {
            padding-left: 0;
            padding-right: 2px;
        }
    }
`;

export const PaginationFormField = styled(FormControl).attrs(() => ({
    className: "pagination-field"
}))`
    
    &&&& {
        min-width: 30px;
        height: 24px;
        border-radius: 3px;
        padding-left: 11px;
        padding-right: 2px;
        width: calc(100vw - 355px);
        
        &::placeholder {
            color: var(--color-grey);
            opacity: .75;
        }
    
        @media (min-width: ${sm.min}px) {
            width: 114px;
        }
    
        .rtl & {
            padding-left: 2px;
            padding-right: 11px;
        }
    }    
`;

export const PaginationTotal = styled.span.attrs(() => ({
    className: "pagination-total"
}))`
    align-self: flex-start;
    display: none;
    flex-shrink: 0;
    margin-left: auto;
    opacity: 0.6;

    @media (min-width: ${sm.min}px) {
        display: block;
    }

    .rtl & {
        margin-left: 0;
        margin-right: auto;
    }
`;