import React from 'react';
import { Icon  } from 'rsuite';
import styled from 'styled-components';

const Message = styled.div`
   color: ${props => props.color || '#1E90FF'};
    i {
        border: 2px solid;
        width: 22px;
        height: 22px;
        border-radius: 100%;
        text-align: center;
        line-height: 21px;
    }
    div {
        display:inline-block;
   }
   div:nth-child(1) {
       vertical-align: supper;
       padding-right: 10px;
   }
   div:nth-child(2) {
       width: calc(100% - 40px);
   }
`;



export const Info = ({children}) => {
    return (  
        <Message>
            <div><Icon icon='exclamation' rotate={180} /></div>
            <div>{children}</div>
        </Message>
    )
};

export const ErrorMessage = ({ showIcon = true, children}) => {
    return (
        <Message color="#f44336">
            {showIcon && <div><Icon icon='exclamation' rotate={180} /></div>}
            <div>{children}</div>
        </Message>
    )
}