import React, {useState} from 'react';
import Modal from "../../../../../components/Modal";
import {api} from '../../../../../api/loginRoutes';

const ModalAccessListFilterRemove = (props) => {

    let {account_id, data, handleClose, update} = props;

    const [disabled, setDisabled] = React.useState(false);

    const onSubmit = async () => {

        setDisabled(true);

        const result = await api('access_list_filter:remove',{
            target: {
                alf_key: data['alf_key']
            }
        })

        if(result && result.access_list_filter) {
            handleClose();
            update();
            setDisabled(false);
        }
    }

    return (
        <>
            <Modal
                show
                footer
                data={data}
                disabled = {disabled}
                onSuccess={onSubmit}
                onClose={handleClose}
                successText = "Delete"
                title={"Delete access list filter"}
            >
                <p>Are you sure about that?</p>
            </Modal>
        </>
    )
};

export default ModalAccessListFilterRemove;