import React, {useRef, useEffect} from "react";
import Table from "../../components/Table";


export default ({
    data = [],
    loading,
    perPage,
    page,
    count,
    getItems,
    toggleParamsModal,
    setSelected,
    filter,
    ...props
}) => {

    let tableRef = useRef(null);

    useEffect(() => {
        tableRef.current.clear();
    }, [perPage, filter]);

    const columns = [
        {
            id: 'prefix',
            label: "Prefix",
            dataKey: 'prefix',
            flexGrow: 2,
            minWidth: 200,
        },
        {
            id: 'sde_name',
            label: "Subdestination",
            dataKey: 'sde_name',
            flexGrow: 2,
            minWidth: 200,
        },
        {
            id: 'end_date',
            label: "End date",
            dataKey: 'end_date',
            width: 200,
            formatData: 'datetime_en',
        },
        {
            id: 'reason',
            label: "Reason",
            value: ({acc_key}) => (
                acc_key ? "Allocation" : "Traffic to numbers from range"
            ),
            flexGrow: 2,
            minWidth: 200,
        }
    ];

    const height = 40 + (30 * perPage);

    return (
        <Table
            ref={tableRef}
            virtualized
            isselected
            ispagination
            extraHeight={height > 700 ? 700 : height}
            getItems={getItems}
            per_page={perPage}
            isSelectedAll
            row_key={"prq_key"}
            {...{
                data,
                loading,
                columns,
                page,
                count,
                setSelected
            }}
            {...props}
        />
    )
};