import React from 'react';
import {Toggle} from 'rsuite';
import CDR from './CDR';
import TrafficStat from './TrafficStat';
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
import PanelLayout from "../../components/base/PanelLayout";
import m from "../../definedMessages";
import {Spacer} from "../../components/base/Spacer";
import {BaseToggle} from "../../components/base/BaseToggle";


class Traffic extends React.Component {

    constructor(props) {
        super(props);

        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);
        this.startTab = new URLSearchParams(this.props.location.search).get("start_tab");

        this.state = {
            typeTrafficCDR: this.startTab === "traffic_reports" || false
        };
    }

    changeTypeTrafficCDR = (value) => {
        this.setState({typeTrafficCDR: value});
    };

    render() {
        const {typeTrafficCDR} = this.state;

        return (
            <PanelLayout>
                <BaseToggle
                    checkedChildren={this.formatMessage(m.trafficStat)}
                    unCheckedChildren={this.formatMessage(m.cdr)}
                    defaultChecked={typeTrafficCDR}
                    onChange={value => this.changeTypeTrafficCDR(value)}
                />
                {typeTrafficCDR ? <TrafficStat/> : <CDR/>}
            </PanelLayout>
        );
    }
}

export default injectIntl( withRouter(Traffic) )