import React, {useState} from 'react';
import Modal from "../../components/Modal";
import {api} from '../../api/loginRoutes';

const ModalAccessListFilterRemove = (props) => {

    let {account_id, show = false, data, handleClose, handleSuccess} = props;

    const [disabled, setDisabled] = React.useState(false);

    return (
        <>
            <Modal
                show={show}
                footer
                data={data}
                disabled = {disabled}
                onSuccess={handleSuccess}
                onClose={handleClose}
                successText = "Remove"
                title={"Remove access list exception"}
            >
                <p>Are you sure about that?</p>
            </Modal>
        </>
    )
};

export default ModalAccessListFilterRemove;