import {
    GOOGLE_OTP_ALLOCATION_SET_LOADING,
    GOOGLE_OTP_ALLOCATION_SET_LIST,
} from '../const'

const initialState = {
    list: [],
    count: 0,
    page: 1,
    perPage: 10,
    loading: true
};

const handlers = {
    [GOOGLE_OTP_ALLOCATION_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [GOOGLE_OTP_ALLOCATION_SET_LIST]: (state, {payload: {list, count, page, perPage}}) => ({
        ...state,
        list: list,
        count: count,
        loading: false,
        page: page,
        perPage: perPage,
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}