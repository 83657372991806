import _ from 'lodash';
import {EOL} from 'const';
import {Table, Alert} from 'rsuite';
import React from 'react';
import {PDFDocument, rgb, StandardFonts} from 'pdf-lib';
import {jsPDF as JSPDF} from 'jspdf';
import autoTable from 'jspdf-autotable';
import {getFileResponse} from "../api/loginRoutes";
import {APP_TYPE_CLIENT, APP_TYPE_DEFAULT} from "../const";

const {Column, HeaderCell, Cell} = Table;

export const pipe = (data, filters, model) => {
    if (!Object.keys(filters).length) return data;

    return data.filter(x => {
        for (let f of Object.keys(filters)) {
            if (!filters[f]) continue;
            if (!model[f](x, filters[f], filters)) return false;
        }
        return true;
    });
};

export const deleteEmpty = obj => {

    if (typeof (obj) != 'object') return obj;

    for (let k of Object.keys(obj)) {
        if (!obj[k] && obj[k] !== false)
            delete obj[k];

        if (Array.isArray(obj[k]) && !obj[k].length)
            delete obj[k];
    }

    return obj;
};

export const sortData = (data, sortColumn, sortType) => {
    return data.sort((a, b) => {
        const aVal = _.isString(a[sortColumn]) ? a[sortColumn].toLowerCase() : a[sortColumn];
        const bVal = _.isString(b[sortColumn]) ? b[sortColumn].toLowerCase() : b[sortColumn];
        const comparisonResult = aVal >= bVal ? 1 : -1;

        return sortType === 'asc' ? comparisonResult : -comparisonResult;
    });
};

export const ID = () => '_' + Math.random().toString(36).substr(2, 9);
export const generateId = ID;

export const savePdfByTemplate = (data, headers, name, template, summary, account = {}) => {
    const doc = new JSPDF();
    const textToParse = template && template.text ? template.text.text : "";
    const objectForParseText = summary;

    const emptyKeysObject = {};
    objectForParseText && Object.keys(objectForParseText).map(key => {
        emptyKeysObject[`%${key}%`] = objectForParseText[key];
    });
    const parsedText = textToParse.replace(/%(\w+)%/g, (_, key) => {
        return emptyKeysObject[`%${key}%`];
    });
    try {
        doc.setFontSize(template.setFontSize.size);
        doc.addImage(template.addImage.imageData, 'base64', template.addImage.x, template.addImage.y, template.addImage.w, template.addImage.h);
        doc.text(parsedText, template.text.x, template.text.y, template.text.options);
        autoTable(doc, {
            ...template.autoTable.options,
            body: data,
            head: headers
        });
        doc.save(`${name}.pdf`);
    } catch (e) {
        Alert.error('No template for you')
    }

};


export const saveFileBlob = (response, name) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();

    // clear object after downloading
    setTimeout(function () {
        URL.revokeObjectURL(url);
    }, 0);
};




export const copyToClipboard = (text) => {
    const temp = document.createElement('textarea');
    document.body.appendChild(temp);
    temp.value = text;
    temp.select();
    document.execCommand('copy');
    temp.remove();
};

export const CRUDitems = (old_list, new_list) => {
    const jsonStringOldItems = old_list.map(
        item => JSON.stringify(item)
    );
    const jsonStringNewItems = new_list.map(
        item => JSON.stringify(item)
    );
    const newAndChangesItems = new_list.filter(item => !jsonStringOldItems.includes(JSON.stringify(item)));
    const newAndChangesItemsIds = newAndChangesItems.map(item => item.id);
    const deletedItems = jsonStringOldItems
        .filter(itemStringify => !jsonStringNewItems.includes(itemStringify))
        .map(itemStringify => JSON.parse(itemStringify))
        .filter(item => !newAndChangesItemsIds.includes(item.id));

    return [newAndChangesItems, deletedItems];
};

export const getFirstValueByName = (list, name, desiredKey, defaultValue = null) => {
    const found = _.find(list, (item) => item.name === name);

    return found ? found[desiredKey] : defaultValue;
};

export const toUTCDateTime = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
};

export const toStartDayUTCTime = (date) => {
    return new Date(Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0 ));
};

export const toStartDayUTCTimeNextDay = (date) => {
    return new Date(Date.UTC( date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0 ));
};

export const toEndDayUTCTime = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));
};

export const removeNilValues = (obj) => {
    const respObject = {...obj};

    Object.keys(respObject).forEach(key => {
        //Remove keys with Undefined or Null values
        if (_.isNil(respObject[key])) {
            delete respObject[key];
        }
    });

    return respObject;
};

export const responseErrorToString = (error) => {
    let errorString = '';

    if (_.isObject(error.data) && !_.isEmpty(error.data)) {
        _.each(error.data, (errMessage, fieldKey) => {
            errorString += `${fieldKey}: ${errMessage}${EOL}`;
        });
    } else {
        errorString = error.message;
    }

    return errorString;
};

export const renderColumn = ({label, dataKey, value = null, headerStyle = null, width = 200, id, ...props}) => (
    <Column key={id} width={width} {...props}>
        <HeaderCell style={headerStyle}>{label}</HeaderCell>
        <Cell dataKey={dataKey}>{value}</Cell>
    </Column>
);

export const hasError = (error) => {
    return error && Object.keys(error).map(i => error[i]).some(value => value === true);
};

export function canIMoveNumbersToAnotherTrunk(fromTrunk, toTrunk) {
    return fromTrunk.id !== toTrunk.id && !toTrunk.closed && fromTrunk.sp_key === toTrunk.sp_key;
}

export const replaceUndefinedOrNull = (key, value) => {
    if (value === null || value === undefined) {
        return undefined;
    }

    return value;
};

export const calculateFixedSignsLength = (value) => {
    const formSigns = value.toString().split('.');

    let calculatedFixedSigns = 1;
    if (value && formSigns.length > 1) {
        const fixedSign = formSigns.pop();
        calculatedFixedSigns = 1 / (10 ** fixedSign.length);
    }
    return calculatedFixedSigns;
};


export const getRangeByNumber = (number, numbers_count) => {
    return numbers_count > 1 ? `${number}${'0'.repeat(Math.log10(numbers_count))}-${number}${'9'.repeat(Math.log10(numbers_count))}` : number;
};
export const getRangeByNumberDigit = (number, digit) => {
    return digit > 0 ? `${number}${'0'.repeat(digit)}-${number}${'9'.repeat(digit)}` : number;
};
/* Mass allocation */
const createDownloadUrl = (string, type = '') => {
    return window.URL.createObjectURL(new Blob([string], {type: type}));
};

const downloadBlobByUrl = (url, name) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
        URL.revokeObjectURL(url);
    }, 0);
};

export const downloadNumbersRangeFile = (csvStringsArray, allocateByPrefixesStatus = false, name = 'numbers.csv') => {
    csvStringsArray.pop();
    const csvDataArray = csvStringsArray.map((item) => item.split(';'));
    const numbersFullArray = [];

    for (let rowIndex = 0; rowIndex < csvDataArray.length; rowIndex++) {
        const [prefix, ratio, ...rest] = csvDataArray[rowIndex];

        // get header
        if (rowIndex === 0) {
            numbersFullArray.push(['Numbers', ...rest].join(';'));
            continue;
        }

        const count = Math.pow(10, ratio);

        if (allocateByPrefixesStatus) {

            // push numbers
            for (let numberIndex = 0; numberIndex < count; numberIndex++) {
                const chunk = ratio > 1 ? (count + numberIndex + '').slice(1) : numberIndex;
                const number = prefix + chunk;
                numbersFullArray.push([number, ...rest].join(';'));
            }

        } else {
            numbersFullArray.push([prefix, ...rest].join(';'));
        }
    }

    const url = createDownloadUrl(numbersFullArray.join('\r\n'), 'text/csv;charset=utf-8;');
    downloadBlobByUrl(url, name);
};


export const downloadPrefixesRangeFile = (csvStringsArray, allocateByPrefixesStatus = false, name = 'numbers.csv') => {
    csvStringsArray.pop();
    const csvDataArray = csvStringsArray.map((item) => item.split(';'));
    const prefixesArray = [];

    for (let rowIndex = 0; rowIndex < csvDataArray.length; rowIndex++) {
        const [prefix, ratio, ...rest] = csvDataArray[rowIndex];

        // get header
        if (rowIndex === 0) {
            prefixesArray.push([prefix, ...rest].join(';'));
            continue;
        }

        const count = Math.pow(10, ratio);
        // push prefix
        const firstNumber = prefix + ('0'.repeat(ratio));
        const lastNumber = ratio > 0 ? prefix + (count - 1) : firstNumber;
        const prefixNumbers = `${firstNumber}-${lastNumber}`;
        prefixesArray.push([prefixNumbers, ...rest].join(';'));
    }

    const url = createDownloadUrl(prefixesArray.join('\r\n'), 'text/csv;charset=utf-8;');
    downloadBlobByUrl(url, name);
};


export const randomizeArray = (arr, chunkSize = 100) => {
    const chunksTotal = arr.length / chunkSize;
    const chunks = [];
    for (let i = 0; i < chunksTotal; i++) {
        const startItem = i * chunkSize;
        const endItem = startItem + chunkSize;
        const chunk = arr.slice(startItem, endItem);
        chunk.sort(() => Math.random() - 0.5);
        chunk.sort(() => Math.random() - 0.5);
        chunks.push(chunk);
    }

    return chunks.reduce((sum, current) => {
        return [...sum, ...current];
    }, []);
};


export const downloadNumbersFile = (csvStringsArray, allocateByPrefixesStatus = false, name = 'numbers.csv', isGoogleOtp = false) => {
    csvStringsArray.pop();
    const csvDataArray = csvStringsArray.map((item) => item.split(';'));

    const numbersOnlyArray = [];

    for (let rowIndex = 0; rowIndex < csvDataArray.length; rowIndex++) {
        const [prefix, ratio, ...rest] = csvDataArray[rowIndex];

        // get header
        if (rowIndex === 0) {
            continue;
        }

        const count = Math.pow(10, ratio);

        if (allocateByPrefixesStatus) {

            // push numbers
            for (let numberIndex = 0; numberIndex < count; numberIndex++) {
                const chunk = ratio > 1 ? (count + numberIndex + '').slice(1) : numberIndex;
                const number = prefix + chunk;
                numbersOnlyArray.push(number);
            }

            continue;

        } else {
            numbersOnlyArray.push(prefix);
        }
    }

    if (isGoogleOtp) {
        const numbersOnlyData = randomizeArray(numbersOnlyArray, 100);
        const url = createDownloadUrl(numbersOnlyData.join('\r\n'), 'text/csv;charset=utf-8;');
        downloadBlobByUrl(url, name);
        return;
    }

    const url = createDownloadUrl(numbersOnlyArray.join('\r\n'), 'text/csv;charset=utf-8;');
    downloadBlobByUrl(url, name);
};

export const compareObjects = (object1, object2) => {
    const keys1 = object1 ? Object.keys(object1) : {};
    const keys2 = object2 ? Object.keys(object2) : {};

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (Array.isArray(object1[key]) && Array.isArray(object2[key])) {
            return object1[key].length === object2[key].length && object1[key].every(function (value, index) {
                return value === object2[key][index];
            });
        } else {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
    }

    return true;
};

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const getFormattedTime = (date_) => {
    const date = new Date(date_);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
};

export const getISOTime = (date_, UTC = false) => {
    const date = !UTC
        ? new Date(date_.getTime() - (date_.getTimezoneOffset() * 60000)).toISOString()
        : date_.toISOString();
    return date;
};

export const removeTZFromDate = (date) => {
    const modifiedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return modifiedDate;
};

export const containsOnlyNumbers = (string) => {
    return Number.isInteger(string) || /^\d+$/.test(string);
};

export const someFieldHasError = (object) => {
    return Object.keys(object).map(key => Object.keys(object[key]).length).some(value => value > 0);
};
export const makeRangeFromPrefixesToCsv = (csvContent) => {
    const csvContentArray = csvContent ? csvContent.split('\n').filter((value) => !!value) : [];
    const csvHeaders = csvContentArray[0];
    const csvBodyPrefixes = csvContentArray.slice(1).map(value => {
        const prefixValue = value.split(';');

        return [getRangeByNumberDigit(prefixValue[0], prefixValue[1]), ...prefixValue.slice(1)].join(';')
    });
    return [csvHeaders, ...csvBodyPrefixes].join('\n');
};

export const makeNumbersFromPrefixesToCsv = (csvContent) => {
    const csvContentArray = csvContent ? csvContent.split('\n').filter((value) => !!value) : [];
    const csvHeaders = csvContentArray[0];
    const csvBodyPrefixes = csvContentArray.slice(1).map(value => {
        const prefixValue = value.split(';');

        return Array(10 ** prefixValue[1]).fill(prefixValue).map((value, index) => {
            const digit = prefixValue[1];
            const count = Math.pow(10, digit);
            const chunk = (count + index + '').slice(1);
            return [`${value[0]}${chunk}`, ...value.slice(1)].join(';')
        });
    });
    return [csvHeaders, ...csvBodyPrefixes.flat(1)].join('\n');
};
export const compareTwoArrays = (array1, array2) => {
    return array1.length === array2.length && array1.sort().every((value, index) => {
        const value1 = JSON.stringify(value);
        const value2 = JSON.stringify(array2.sort()[index]);

        return value1 === value2;
    })
};

export const generatePassword = (passwordLength=12) => {
    const numberChars = "0123456789";
    const specialChars = '#?!_@$%^&*';
    const upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerChars = "abcdefghijklmnopqrstuvwxyz";

    const allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return shuffleArray(randPasswordArray.map((x) => { return x[Math.floor(Math.random() * x.length)] })).join('');
};

export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
};

export const getBlobContent = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    console.log("file", file, typeof file);
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const isObject = (obj) => {
    return obj === Object(obj);
};

export const getDeepKeys = (object) => {
    let keys = [];
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            const keyValue = {key, value: key};
            keys.push(keyValue);

            if (typeof object[key] === "object") {
                const subkeys = getDeepKeys(object[key]);
                keys = keys.concat(subkeys.map((subkey) => {
                    return {key: key + "." + subkey.key, value: subkey.key}
                }));
            }
        }
    }
    return keys;
};


export function customDebounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms)
    };
};


export const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
  
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };

export const daysCount = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let dayCount = 0;

    while (end > start) {
        dayCount++;
        start.setDate(start.getDate() + 1);
    }

    return dayCount
}

export const fieldSorter = (fields) => {
    return ((a, b) => {
        return fields
            .map((key) => {
                let dir = 1;
                if (key[0] === '-') {
                    dir = -1;
                    key=key.substring(1);
                }
                if (a[key] > b[key]) return dir;
                if (a[key] < b[key]) return -(dir);
                return 0;
            })
            .reduce((item, data) => {
                return item ? item : data;
            }, 0);
    });
};

export const between = (value, min, max) => {
    return value >= min && value <= max;
};

export const getRangeByDigit = (number, digit) => {
    return digit > 0 ? `${number}${'0'.repeat(digit)}-${number}${'9'.repeat(digit)}` : number
};

export const arrayToObject = (array) => {
    return array.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        acc[key] = item[key];
        return acc
    }, {})
};
export const arrayToObjectSpecialKey = (array, key) => {
    return array.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc
    }, {})
};

export const renderCsvDataToTableData = (data, headers) => {
    const csvBody = data.length ? data.slice(1) : [];

    return (
        csvBody.map(line => {
            const items = line.split(';');
            const prefix = getRangeByDigit(items[0], items[1]);

            const body = items.map((item, index) => {
                if (between(index, 2, 5)) {
                    return {[headers[index]]: item}
                }
                return null;
            }).filter(value => value);

            return {
                [headers[0]]: prefix,
                ...(arrayToObject(body))
            }
        })
            .filter(obj => Object.keys(obj).some(key => obj[key]))
    );
};


export const objectIsEmpty = (obj) => {
    if (!obj) return true;

    for (const prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return true;
};


export const getAllowedRangesByIntervalMonth = (intervalMonth = 3) => {
    const startDate = new Date();
    const endDate = new Date();
    const dateRange =  [
        new Date( startDate.setMonth(startDate.getMonth() - intervalMonth) + (1000 * 3600 * 24) ),
        new Date( endDate.setMonth(endDate.getMonth() + 12) )
    ];
    return [
        `${dateRange[0].getFullYear()}${('0' + (dateRange[0].getMonth() + 1)).slice(-2)}01`,
        `${dateRange[1].getFullYear()}${('0' + (dateRange[1].getMonth() + 1)).slice(-2)}01`,
    ]
};

export const createUrl = (response, fileName = 'file.csv') => {
    if (response !== undefined) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
};

export const onDownloadCompleteOnlyNumbersCSV = async (trunkId, setUploadingLoading, filter = null) => {
    await getFileResponse('trunk_number__get_list', {
        target: {
            trunk_id: trunkId
        },
        filter: {...(filter ? filter : {})}
    })
        .then((response) => {
            const csvText = response.text();
            csvText.then((text) => {
                const csvContent = makeNumbersFromPrefixesToCsv(text);
                const csvDataList = csvContent.split('\n').slice(1).map(data => {
                    const line = data.split(';');
                    return line[0]
                });
                const csvData = csvDataList.join('\n');

                saveFileBlob(csvData, 'allocated_numbers.csv');
                setUploadingLoading(trunkId, false);
            });
        });
};


export const setBodyClassNames = (userInfo = {}) => {
    const appType = _.get(userInfo, 'session.site', APP_TYPE_DEFAULT);
    if (appType === APP_TYPE_CLIENT) {
        document.body.classList.remove("app-admin");
        document.body.classList.add("app-client");
    } else {
        document.body.classList.remove("app-client");
        document.body.classList.add("app-admin");
    }
};


export const getCsvDataWithHeaders = (csvData) => {
    const csvHeaderList = csvData && csvData.length ? csvData[0].split(';') : [];
    const csvBody = csvData && csvData.length ? csvData.slice(1) : [];
    const csvResult = csvBody && csvBody.length ? csvBody.filter(value => !!value).map( line => {
        const item = line.split(';');
        return `${item[0]};${item.slice(2).join(';')}`
    }) : [];
    const csvHeaders = csvHeaderList && csvHeaderList.length ? [csvHeaderList[0], ...csvHeaderList.slice(2)].join(";") : "";
    csvResult.unshift(csvHeaders);

    return csvResult;
};


export const onDownloadCompleteNumbersCSV = async (trunkId, setUploadingLoading, filter = null) => {
    await getFileResponse('trunk_number__get_list', {
        target: {
            trunk_id: trunkId
        },
        filter: {...(filter ? filter : {})}
    })
        .then((response) => {
            const csvText = response.text();
            csvText.then((text) => {
                const csvContent = makeNumbersFromPrefixesToCsv(text);
                const csvHeaderList = csvContent.split('\n')[0].split(';');
                const csvDataList = csvContent.split('\n').slice(1).map(line => {
                    const items = line.split(';');
                    return [items[0], ...items.slice(2)].join(';')
                });
                const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(2)].join(';');
                csvDataList.unshift(csvHeaders);
                const csvData = csvDataList.join('\n');

                saveFileBlob(csvData, 'allocated_numbers.csv');
                setUploadingLoading(trunkId, false);
            });
        });
};


export const onDownloadCompletePrefixesCSV = async (trunkId, setUploadingLoading, filter = null) => {
    await getFileResponse('trunk_number__get_list', {
        target: {
            trunk_id: trunkId
        },
        filter: {...(filter ? filter : {})}
    })
        .then((response) => {
            const csvText = response.text();
            csvText.then((text) => {
                const csvPreparedData = text ? makeRangeFromPrefixesToCsv(text).split('\n') : [];
                const csvContentList = getCsvDataWithHeaders(csvPreparedData);
                const csvHeaderList = csvContentList[0].split(';');
                const csvDataList = csvContentList.slice(1).map(line => {
                    const items = line.split(';');
                    return [items[0], ...items.slice(1)].join(';')
                });
                const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(1)].join(';');
                csvDataList.unshift(csvHeaders);
                const csvData = csvDataList.join('\n');

                saveFileBlob(csvData, 'allocated_numbers.csv');
                setUploadingLoading(trunkId, false);
            });
        });
};


export const countDecimals = (value) => {
    if ((value % 1) !== 0)
        return value.toString().split(".")[1].length;
    return 0;
};

