import React from 'react';
import CustomField from './CustomField';

import {Schema, Input} from 'rsuite';
const { StringType } = Schema.Types;

export const str40 = StringType()
    .isRequired('Required')
    .maxLength(40, 'The maximum is only 40 characters.');

const Text = (props) => (
    <CustomField
        accepter={Input}
        errorPlacement="topRight"
        {...props}
    />
)


export default Text;