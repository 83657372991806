import React, {useRef, useState} from "react";
import {Button, Form, FormControl, InputNumber, Schema, SelectPicker} from "rsuite";
import styled from "styled-components";
import Icon from "rsuite/es/Icon";
import ModalSettingSubmitApprove from "./ModalSettingSubmitApprove";

const {NumberType} = Schema.Types;

const formModel = Schema.Model({
    period_traffic: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0'),
    period_allocation: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0'),
    period_autorevoke: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0'),
});

export default ({
    filter,
    worldzoneList,
    destinationList,
    subdestinationList,
    onSubmit,
    getPriceRangeQuarantineSettingList,
}) => {

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [modalApproveData, setModalApproveData] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [modalApproveLoading, setModalApproveLoading] = useState(null);

    const handleSubmit = () => {
        if (!formRef.current.check())
            return;

        const formValue = formRef.current.getFormValue();
        formValue.period_traffic = +formValue.period_traffic;
        formValue.period_allocation = +formValue.period_allocation;
        formValue.period_autorevoke = +formValue.period_autorevoke;

        // Approve modal
        if (!formValue.wz_key && !formValue.de_key && !formValue.sde_key) {
            setShowModalApprove(true);
            setModalApproveData(formValue);
            return;
        }

        setLoading(true);
        onSubmit(formValue)
            .then(res => {
                setLoading(false);
                if (res) {
                    getPriceRangeQuarantineSettingList(filter)
                }
            });
    };

    const onApprovedSubmit = async () => {
        setModalApproveLoading(true);
        await onSubmit(modalApproveData);
        getPriceRangeQuarantineSettingList(filter);
        setModalApproveLoading(false);
        setShowModalApprove(false);
    };

    return (
        <>
            <StyledForm
                ref={formRef}
                model={formModel}
                onSubmit={handleSubmit}
            >
                <StyledFieldWrapper grow={2}>
                    <StyledField
                        accepter={SelectPicker}
                        name="wz_key"
                        className="tableFilters_field"
                        errorPlacement="topEnd"
                        data={worldzoneList}
                        placeholder="Zone"
                        valueKey="wz_key"
                        labelKey="name"
                        type="text"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper grow={2}>
                    <StyledField
                        accepter={SelectPicker}
                        name="de_key"
                        className="tableFilters_field"
                        errorPlacement="topEnd"
                        data={destinationList}
                        placeholder="Destination"
                        valueKey="de_key"
                        labelKey="name"
                        type="text"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper grow={2}>
                    <StyledField
                        name="sde_key"
                        className="tableFilters_field"
                        errorPlacement="topEnd"
                        accepter={SelectPicker}
                        data={subdestinationList}
                        placeholder="Subdestination"
                        valueKey="sde_key"
                        labelKey="name"
                        type="text"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper>
                    <StyledField
                        name="period_traffic"
                        min={0.01}
                        max={Infinity}
                        accepter={InputNumber}
                        placeholder="From last call"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper>
                    <StyledField
                        name="period_allocation"
                        min={0.01}
                        max={Infinity}
                        accepter={InputNumber}
                        placeholder="From allocation"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper>
                    <StyledField
                        name="period_autorevoke"
                        min={0.01}
                        max={Infinity}
                        accepter={InputNumber}
                        placeholder="Autorevoke period"
                    />
                </StyledFieldWrapper>

                <StyledSubmitWrapper>
                    <Button
                        className="tableFilters_submit"
                        color="green"
                        type="submit"
                        loading={loading}
                    >
                        <Icon icon="plus"/>
                    </Button>
                </StyledSubmitWrapper>
            </StyledForm>

            <ModalSettingSubmitApprove
                show={showModalApprove}
                onSubmit={onApprovedSubmit}
                onClose={() => setShowModalApprove(false)}
                disabled={modalApproveLoading}
            />
        </>
    )
};

const StyledField = styled(FormControl).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    placement: "autoVerticalStart",
    type: "text",
    cleanable: true,
    searchable: true,
}))`
`;

const StyledFieldWrapper = styled.div`
    flex-grow: ${props => props.grow || '0'};
    flex-shrink: 0;
    flex-basis: 200px;
    padding: 5px 10px;
    width: 174px;
`;
const StyledSubmitWrapper = styled.div`
    padding: 5px 10px;
    width: 174px;
`;

const StyledForm = styled(Form)`
    display: flex;
`;