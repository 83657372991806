import common from "./common";
import accesses from "./accesses";
import validation from "./validation";
import balanceInfo from "./balanceInfo";
import numbers from "./numbers";
import liveCalls from "./liveCalls";
import pagination from "./pagination";
import testCalls from "./testCalls";
import traffic from "./traffic";
import cdr from "./cdr";
import price from "./price";
import cli from "./cli";
import ivr from "./ivr";
import paymentDetails from "./paymentDetails";
import payments from "./payments";
import modalResponseHandler from "./modalResponseHandler";
import editProfile from "./editProfile";

export default {
    ...common,
    ...accesses,
    ...validation,
    ...balanceInfo,
    ...numbers,
    ...liveCalls,
    ...pagination,
    ...testCalls,
    ...traffic,
    ...cdr,
    ...price,
    ...cli,
    ...ivr,
    ...paymentDetails,
    ...payments,
    ...modalResponseHandler,
    ...editProfile
};