import React from 'react';
import Form from 'rsuite/es/Form';
import Text from '../../components/Form/Text';
import FormHOC from '../../hoc/FilltersForm';
import {Schema} from 'rsuite';
import {debounce} from 'lodash';
import styled, {css} from 'styled-components';

const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    number: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});


const AuthNumbersFilter = ({onChange, defaultFilters, textFieldStyles}) => {
    const handleChange = (formValues) => {
        onChange(formValues)
    };
    const {textFieldWidth, textFieldMarginBottom} = textFieldStyles;
    return (
        <>
            <Form
                model={filtersModel}
                layout="inline"
                onChange={debounce((value) => {
                    handleChange(value);
                }, 400)}
                formDefaultValue={defaultFilters}
            >
                <Text name="number" placeholder="Number/Service name" styleFormGroupExternal={{'margin-bottom': 0}} width={240}/>
            </Form>
        </>
    );
};
export default FormHOC (AuthNumbersFilter, filtersModel,300);