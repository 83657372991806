import React, {useState} from 'react';
import {Schema, SelectPicker} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import CustomField from '../../components/client/Form/CustomField/CustomField';
import {debounce} from 'lodash';
import {CustomizableForm} from '../../components/client/Form/CustomizableForm';
import {LOCAL_STORAGE_USER_INFO_DIALER} from '../../const';
import {useIntl} from "react-intl";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import m from "../../definedMessages";
import {BaseInputField, BaseSelectPicker} from "../../components/base/BaseForm";
import {useWindowWidth} from "../../hooks";

const {StringType} = Schema.Types;

const MAX_LENGTH = 40;

const filtersModel = Schema.Model({
        str: StringType().maxLength(MAX_LENGTH, `The maximum of this field is ${MAX_LENGTH} characters`),
    }
);


export default  FormHOC(({
    loading,
    onChangeFilter,
    prices = [],
    savedTableFilter,
    currency_list,
    spAuth
}) => {
    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();

    const filtersModelInner = Schema.Model({
            str: StringType().maxLength(MAX_LENGTH, formatMessage(m.maxLength, {count: MAX_LENGTH})),
        }
    );

    // temporary crutch for SP key
    const [spKey, setSpKey] = React.useState(1);
    React.useEffect(() => {
        
        const sp_key = (savedTableFilter && savedTableFilter.sp_key) || (prices && prices.length && prices[0].sp_key) || 1;
        setSpKey(sp_key);

    }, [savedTableFilter, prices]);

    const accountInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
    const parsedAccountInfo = accountInfo !== null ? JSON.parse(accountInfo).session.account : {};
    const accountInfoIsTest = parsedAccountInfo.hasOwnProperty('is_test') ? parsedAccountInfo.is_test : false;

    let filteredPriceList;
    let savedFilter;
    const priceList = prices.map(({sp_key: value, name: label}) => ({value, label}));
    const curList = currency_list.map(({cur_key: value, name: label}) => ({value, label}));

    if (priceList && priceList.length && savedTableFilter) {
        filteredPriceList = priceList.filter(price=>price.value===savedTableFilter.sp_key);
    }
    if (filteredPriceList && filteredPriceList.length) {
        savedFilter = {
            ...savedTableFilter,
            sp_key: filteredPriceList[0].value,
            cur_key: savedTableFilter && savedTableFilter.cur_key ? savedTableFilter.cur_key : 1
        }
    } else {
        savedFilter = {
            ...savedTableFilter,
            sp_key: (prices && prices.length && prices[0].sp_key) || (savedTableFilter && savedTableFilter.sp_key) || 1,
            cur_key: savedTableFilter && savedTableFilter.cur_key ? savedTableFilter.cur_key : 1
        }
    }

    const [changedSpKey, onChangeSpKey] = useState(savedFilter.sp_key);

    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);

        if (checker) {
            onChangeFilter({...data, sp_key: data.sp_key || savedTableFilter.sp_key || 1});
        }
        return false;
    };

    return (
        <CustomizableForm
            model={filtersModelInner}
            onChange={debounce((data) => {
                const checkedData = filtersModel.check(data);
                if (data.sp_key) onChangeSpKey(data.sp_key);
                onSubmit(data, checkedData);
            }, 1000)}
            formDefaultValue={savedFilter}
            fluid
            readOnly={loading}
        >
            <FlexGrid align="top">
                <FlexGridItem width={windowWidth > 767 ? "180px" : "100%"}>
                    <CustomField
                        data={priceList}
                        value={spKey}
                        onChange={(value) => {
                            setSpKey(value)
                        }}
                        searchable={false}
                        accepter={BaseSelectPicker}
                        name="sp_key"
                        cleanable={false}
                    />
                </FlexGridItem>
                { accountInfoIsTest &&
                    <FlexGridItem>
                        <CustomField
                            data={curList}
                            accepter={BaseSelectPicker}
                            name="cur_key"
                            width={windowWidth > 767 ? "320px" : "100%"}
                            cleanable={false}
                            searchable={false}
                        />
                    </FlexGridItem>
                }
                <FlexGridItem width={windowWidth > 767 ? "320px" : "100%"}>
                    <CustomField
                        name="str"
                        accepter={BaseInputField}
                        errorPlacement="topEnd"
                        placeholder={!spAuth ?
                            formatMessage(m.destinationFilter)
                            : formatMessage(m.destinationOrService)}
                    />
                </FlexGridItem>
            </FlexGrid>
        </CustomizableForm>
    );
}, filtersModel, 500);