import React from "react";
import {Redirect, Route, Switch} from "react-router";
import _ from "lodash";
import {DEFAULT_AUTH_PATH_CLIENT, DEFAULT_AUTH_PATH_CLIENT_TEST} from "../const";
import AuthRoute from "../components/Router/AuthRoute";
import LiveCalls from "../pagesClient/LiveCalls";
import TestCalls from "../pagesClient/TestCalls";
import TrafficStat from "../pagesClient/Traffic";
import Price from "../pagesClient/Price";
import Accesses from "../pagesClient/Accesses";
import CLI from "../pagesClient/CLI";
import IVR from "../pagesClient/IVR";
import ApiPage from "../pagesClient/ApiPage";
import PaymentDetails from "../pagesClient/PaymentDetails";
import Payments from "../pagesClient/Payments";
import Numbers from "../pagesClient/Numbers";
import PaymentDetailConfirm from "../pagesClient/PaymentDetails/PaymentDetailConfirm";


export default ({authInfo, auth}) => {

    const isTest = _.get(authInfo, 'session.account.is_test', false);

    return (
        <Switch>
            {!isTest && <AuthRoute exact path="/numbers" component={Numbers}/>}
            {!isTest && <AuthRoute exact path="/api" component={ApiPage}/>}
            {!isTest && <AuthRoute exact path="/payments" component={Payments}/>}
            {!isTest && <AuthRoute exact path="/traffic" component={TrafficStat}/>}
            {!isTest && <AuthRoute exact path="/cli" component={CLI}/>}
            {!isTest && <AuthRoute exact path="/payment-details" component={PaymentDetails}/>}
            {!isTest && <AuthRoute exact path="/payment-detail/:token" component={PaymentDetailConfirm}/>}

            <AuthRoute exact path="/test-calls" component={TestCalls}/>
            <AuthRoute exact path="/live-calls" component={LiveCalls}/>
            <AuthRoute exact path="/price" component={Price}/>
            <AuthRoute exact path="/accesses" component={Accesses}/>
            <AuthRoute exact path="/ivr" component={IVR}/>
            {isTest
                ? <Redirect to={DEFAULT_AUTH_PATH_CLIENT_TEST}/>
                : <Redirect to={DEFAULT_AUTH_PATH_CLIENT}/>
            }
        </Switch>
    )
}