import React, {useEffect} from "react";
import {Container, Content, FlexboxGrid, Header, Icon, Navbar, Panel} from "rsuite";
import {connect} from "react-redux";
import {ping} from "../../actions/auth";

const styleContainer = {
    minHeight: '100vh'
};

const stylePanel = {
    marginTop: '20vh',
    width: '100%',
    maxWidth: '500px',
    minWidth: '340px'
};


const Maintenance = ({ping, maintenanceMode}) => {

    const pingQuery = (func) => {
        setTimeout( async () => {
            const result = await func();
            if (!result) {
                pingQuery(func);
            }
        }, 15000 );
    };

    useEffect( () => {
        if (!maintenanceMode)
            return;

        pingQuery(ping);
    }, [maintenanceMode, ping, pingQuery]);

    return (
        <Container style={styleContainer}>
            <Header>
                <Navbar appearance="inverse">
                    <Navbar.Header></Navbar.Header>
                </Navbar>
            </Header>
            <Content>
                <FlexboxGrid justify="center" style={{width: '100%'}}>
                    <FlexboxGrid.Item style={stylePanel}>
                        <Panel
                            className="panel__noShadow"
                            header={<h3>The site is under maintenance</h3>}
                            style={{
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            <Icon
                                style={{
                                    fill: 'red',
                                }}
                                icon="gears2"
                                size="5x"
                            />
                            <p style={{paddingTop: '30px'}}>Premiumy is currently under maintenance. We should be back shortly. Thank you for your patience.</p>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    )
};

const mapState = ({auth}) => ({
    maintenanceMode: auth.maintenanceMode
});
export default connect(mapState, {ping})(Maintenance);