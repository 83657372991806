import {TEST_CALLS_DIALER as PREFIX} from '../../const'
import {api} from '../../api/loginRoutes'
import {_setValue} from './defaults'
import {TEST_CALLS_LIST_DIALER_API} from "../../const/apiMethods";

const setTestCalls = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);

export const getDialerTestCalls = (per_page = 12, filter = {}, page = 1) => (dispatch) => {
    dispatch(setLoading(true));
    api(TEST_CALLS_LIST_DIALER_API, {filter, page, per_page}).then((response) => {
        if (response !== undefined)
            dispatch(setTestCalls({
                cdr_full_test_list: response.cdr_full_test_list,
                cdr_full_test_count: response.cdr_full_test_count,
                page,
                per_page
            }))
    })
};
