import React from 'react';
import {Icon, InputGroup, Schema} from 'rsuite'
import {Form, FormControl} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import styled from 'styled-components';

const { StringType } = Schema.Types;
const MAX_LENGTH = 128;
const accFiltersModel = Schema.Model({
    str: StringType().maxLength(MAX_LENGTH, `The maximum is only ${MAX_LENGTH} characters.`),
});


export default FormHOC( ( {onChange, isMobile} ) => {
    return (
        <Form
            onChange={onChange}
            model={accFiltersModel}
            formDefaultValue={{str: ""}}
            fluid
        >
            <StyledInputGroup>
                <FormControl 
                    name="str" 
                    errorPlacement="topRight"
                    placeholder={!isMobile
                        ? "Account name/Email address"
                        : "Account name / email"
                    }
                />
                <InputGroup.Addon>
                    <Icon icon="search" />
                </InputGroup.Addon>
            </StyledInputGroup>
         
        </Form>
    );
}, accFiltersModel, 500);


const StyledInputGroup = styled(InputGroup)`
    && {
        overflow: visible;
        
        @media (min-width: 1200px) {
            margin-bottom: 10px;
        }
    }

    &&&& {
        .rs-input {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
    }
`;
