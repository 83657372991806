import React from 'react';
import { InputGroup, Input, Icon} from 'rsuite';


const InputSearch = ({onChange, placeholder,...props}) => (
    <InputGroup >
        <Input onChange={onChange} placeholder={placeholder} {...props}/>
        <InputGroup.Addon>
            <Icon icon="search" />
        </InputGroup.Addon>
    </InputGroup>
)


export default InputSearch;