import React, {useState, useEffect} from "react";
import {Button, CheckPicker, Icon, SelectPicker, Table, Input} from "rsuite";
import styled from "styled-components";
import ModalRemoveAccountManager from "./ModalRemoveAccountManager";
import BaseTable from "../../components/base/BaseTable";

const {Cell, Column, HeaderCell} = Table;


export default ({
    data = [],
    loading,
    teamLeadList,
    userList,
    billingList,
    handleRowEdit,
    modifyAccountManager,
    removeAccountManager,
    getAccountManagersList,
    ...props
}) => {

    const [forms, setForms] = useState(new Map());
    const [rowsLoading, setRowsLoading] = useState([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeData, setRemoveData] = useState(null);
    const [removeDataLoading, setRemoveDataLoading] = useState(null);

    const changeForm = (name, value, id) => {
        const formsCopy = new Map(forms);
        const currentForm = forms.get(id);
        formsCopy.set(id, {...currentForm, [name]: value});
        setForms(formsCopy);
    };

    const modifyRow = async (rowData) => {
        setRowsLoading([...rowsLoading, rowData.id]);
        const params = forms.get(rowData.id);
        if (!params.name) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData.id) );
            return;
        }
        if (!params.skype) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData.id) );
            return;
        }
        if (!params.email) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData.id) );
            return;
        }
        const res = await modifyAccountManager(params);
        if (!res) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData.id) );
            return;
        }
        setItemEditing(res.account_manager);
        getAccountManagersList();
        setRowsLoading( rowsLoading.filter(item => item !== rowData.id) );
    };

    const deleteRow = async () => {
        setRemoveDataLoading(true);
        const res = await removeAccountManager(removeData.id);
        if (res) {
            await getAccountManagersList();
            setItemEditing(res.account_manager);
            getAccountManagersList()
                .then(res => {
                    setRemoveDataLoading(false);
                    setShowRemoveModal(false);
                    setRemoveData(null);
                });
        }
    };

    const setItemEditing = (rowData) => {
        const formsCopy = new Map(forms);
        if ( forms.has(rowData.id) ) {
            formsCopy.delete(rowData.id);
        } else {
            formsCopy.set(rowData.id, {
                account_manager_id: rowData.id,
                email: rowData.email,
                name: rowData.name,
                account_user_teamlead_id: rowData.account_user_teamlead && rowData.account_user_teamlead.account_user_id,
                account_user_manager_id_list: rowData.account_user_manager_list
                    ? rowData.account_user_manager_list.map(item => item.account_user_id)
                    : null,
                skype: rowData.skype
            })
        }
        setForms(formsCopy);
    };

    return (
        <>
            <StyledTable
                className={'tableFilters'}
                shouldUpdateScroll={false}
                headerHeight={46}
                autoHeight
                wordWrap
                data={[...data]}
                {...{
                    loading
                }}
                {...props}
            >

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">AM</span>
                    </HeaderCell>
                    <Cell dataKey={"name"}>
                        {(rowData) => {
                            if ( forms.has(rowData.id) ) {
                                const editableRow = forms.get(rowData.id);
                                return <div className="rs-form-control-wrapper">
                                    <EditField
                                        as={Input}
                                        value={editableRow["name"]}
                                        name="name"
                                        placeholder={"Name"}
                                        onChange={(val) => changeForm("name", val, rowData.id)}
                                    />
                                    {!editableRow["name"] &&
                                        <EditFieldError text="Required" />
                                    }
                                </div>
                            }
                            return <span className="tableFilters__previewText">{rowData["name"]}</span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Email</span>
                    </HeaderCell>
                    <Cell dataKey={"email"}>
                        {(rowData) => {
                            if ( forms.has(rowData.id) ) {
                                const editableRow = forms.get(rowData.id);
                                return <div className="rs-form-control-wrapper">
                                    <EditField
                                        as={Input}
                                        value={editableRow["email"]}
                                        name="email"
                                        placeholder={"Email"}
                                        onChange={(val) => changeForm("email", val, rowData.id)}
                                    />
                                    {!editableRow["email"] &&
                                    <EditFieldError text="Required" />
                                    }
                                </div>
                            }
                            return <span className="tableFilters__previewText">{rowData["email"]}</span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Team Lead</span>
                    </HeaderCell>
                    <Cell dataKey={"teamlead_name"}>
                        {(rowData) => {
                            if ( forms.has(rowData.id) ) {
                                const editableRow = forms.get(rowData.id);
                                return <EditField
                                        cleanable={false}
                                        searchable={false}
                                        data={teamLeadList}
                                        value={editableRow["account_user_teamlead_id"]}
                                        valueKey="account_user_id"
                                        labelKey="account_user_name"
                                        name="account_user_teamlead_id"
                                        placeholder={"Team Lead"}
                                        onChange={(val) => changeForm("account_user_teamlead_id", val, rowData.id)}
                                    />
                            }
                            return <span className="tableFilters__previewText">
                                {rowData.account_user_teamlead
                                    ? rowData.account_user_teamlead.account_user_name
                                    : ''
                                }
                            </span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">AM from Billing</span>
                    </HeaderCell>
                    <Cell dataKey={"name"}>
                        {(rowData) => {
                            const billingItem = rowData["billing_acm_key"] && billingList.find(item => item.ACM_KEY === rowData["billing_acm_key"]);
                            return (
                                <span className="tableFilters__previewText">
                                    {rowData["billing_acm_key"]
                                        ? billingItem ? billingItem["ACM_NAME"] : ""
                                        : ""
                                    }
                                </span>
                            )
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Users</span>
                    </HeaderCell>
                    <Cell dataKey={"account_user_list"}>
                        {(rowData) => {
                            if ( forms.has(rowData.id) ) {
                                const editableRow = forms.get(rowData.id);
                                return <EditField
                                    cleanable={true}
                                    searchable={false}
                                    value={editableRow.account_user_manager_id_list}
                                    data={userList}
                                    valueKey="account_user_id"
                                    labelKey="account_user_name"
                                    name="account_user_manager_id_list"
                                    placeholder={"Users"}
                                    sticky
                                    as={CheckPicker}
                                    onChange={(val) => changeForm("account_user_manager_id_list", val, rowData.id)}
                                />
                            }
                            const users = rowData.account_user_manager_list && rowData.account_user_manager_list.map(item => item.account_user_name).join(", ");
                            return <span className="tableFilters__previewText">{users || ""}</span>
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={1} minWidth={130}>
                    <HeaderCell>
                        <span className="tableFilters__headerText">Skype</span>
                    </HeaderCell>
                    <Cell dataKey="skype">
                        {(rowData) => {
                            if ( forms.has(rowData.id) ) {
                                const editableRow = forms.get(rowData.id);
                                return <div className="rs-form-control-wrapper">
                                    <EditField
                                        as={Input}
                                        value={editableRow["skype"]}
                                        name="skype"
                                        placeholder="Skype"
                                        onChange={(val) => changeForm("skype", val, rowData.id)}
                                    />
                                    {!editableRow["skype"] &&
                                    <EditFieldError text="Required" />
                                    }
                                </div>
                            }
                            return <span className="tableFilters__previewText">{rowData["skype"]}</span>
                        }}
                    </Cell>
                </Column>

                <Column width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => {
                            const rowLoading = rowsLoading.includes(rowData.id);
                            return <div className="tableFilters_buttons">

                                {!forms.has(rowData.id)
                                    ? <Button
                                        size="sm"
                                        color="lightblue"
                                        disabled={rowLoading}
                                        onClick={() => setItemEditing(rowData)}
                                    >
                                        <Icon icon="edit2"/>
                                    </Button>
                                    : <>
                                        <Button
                                            size="sm"
                                            color="green"
                                            disabled={rowLoading}
                                            onClick={() => modifyRow(rowData)}
                                        >
                                            <Icon icon="check-circle"/>
                                        </Button>

                                        <Button
                                            size="sm"
                                            color="red"
                                            disabled={rowLoading}
                                            onClick={() => setItemEditing(rowData)}
                                        >
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                }

                                <Button
                                    size="sm"
                                    color="red"
                                    disabled={rowLoading}
                                    onClick={() => {
                                        setRemoveData(rowData);
                                        setShowRemoveModal(true);
                                    }}
                                >
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        }}
                    </Cell>
                </Column>

            </StyledTable>

            <ModalRemoveAccountManager
                show={showRemoveModal}
                onSubmit={deleteRow}
                onClose={() => setShowRemoveModal(false)}
                disabled={removeDataLoading}
            />

        </>
    )
}

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;


const EditFieldError = ({text}) => (
    <div className="rs-error-message-wrapper rs-form-control-message-wrapper rs-error-message-placement-top-end">
        <span className="rs-error-message rs-error-message-show">
            <span className="rs-error-message-arrow"></span>
            <span className="rs-error-message-inner">{text}</span>
        </span>
    </div>
);

const EditField = styled(SelectPicker).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
}))`
`;

