import React from 'react';
import {Table} from 'rsuite';
import _ from 'lodash';

const { Column, HeaderCell, Cell } = Table;

const dateOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
const timeOptions = {hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit'};

const dataFormatters = {
    'date': (date) => date ? new Date(date).toLocaleDateString() : null,
    'datetime': (date) => date ? new Date(date).toLocaleString("en-US") : null,
    'datetime_en': (date) => {
        const dateObj = new Date(date);
        return date
            ? dateObj.toLocaleDateString('en', dateOptions) + " " + dateObj.toLocaleTimeString('en', timeOptions)
            : null
    },
};

const convertDateToYesterday = (date) => {
    return date ? new Date(new Date(new Date(date).setSeconds(new Date(date).getSeconds() - 1)).setHours(23, 59, 59, 999)) : null
};

export default (columns, cutDate, isMobile) => columns.map(({
    label,
    flexGrow, 
    dataKey, 
    title, 
    formatData, 
    headerProps = {}, 
    value, 
    ...restProps
}, i)  => {
        const props = {...restProps};

        if (!props.width) {
            props.flexGrow = flexGrow || 1;
        }

        if (isMobile) {
            if (props.minWidthMobile)
                props.minWidth = props.minWidthMobile;
        }

        return (
            <Column {...props} key={i}>
                <HeaderCell {...headerProps}>{label}</HeaderCell>
                {!value ? <Cell dataKey={dataKey}>
                        {(row) => {
                            const dataFormatter = _.isFunction(formatData) ? formatData : dataFormatters[formatData];
                            const date = cutDate && cutDate.hasOwnProperty(dataKey) && cutDate[dataKey] ? convertDateToYesterday(row[dataKey]) : row[dataKey];
                            const data = dataFormatter ? dataFormatter(date, dataKey, row) : date;
                            return title
                                ? <span title={data}>{data}</span>
                                : <span>{data}</span>
                        }}

                    </Cell>
                    : <Cell dataKey={dataKey}>{value}</Cell>
                }
            </Column>
        )
    }
)

