import styled, { css } from 'styled-components';
import {FormControl} from 'rsuite';
import CustomField from "../../../components/client/Form/CustomField/CustomField";
import Modal from '../../../components/Modal/Modal';
import {CustomModalClient} from "../../../components/base";


export const FormModal = styled(CustomModalClient)`
    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;

export const FormColumns = styled.div`
    align-items: stretch;
    display: flex;
`;

export const FormColumn = styled.div`
    flex: 0 0 auto;
    position: relative;
    width: 100%;

    ${props => 
        props.flexGrow && css`
            flex-grow: ${props.flexGrow};
        `
    }

    @media (min-width: 768px) {
        padding: 0 15px;
        ${props => 
            props.width && css`
                width: ${props.width};
            `
        }
        
        .rs-form-control-wrapper {
            width: auto;
        }
    }
`;


export const Field = styled(FormControl)`

    &&& {
        min-width: 0;
        width: 100%;

        ${props => 
            props.componentClass === 'textarea' &&
            css`
                min-height: 200px;
                background: #fff;
                overflow-x: scroll;
            `
        }
    }

    ${props =>
        props.disabled &&
        css`
            opacity: var(--value-disabled-opacity)
        `
    }
`;


export const Fieldset = styled.fieldset`
    border: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
        width: auto;
    }
    
    .rs-form-control-wrapper {
        color: #888888;
        opacity: 0.6;

        input,
        a {
            cursor: not-allowed !important;
        }
    }
    
`;

export const NumbersField = styled(CustomField).attrs(() => ({
    className: 'test6678'
}))`
    &&&& {
        width: 100%;

        @media (min-width: 768px) {
            width: 100px;
        }
    }
`;