import {
    DEFAULT_SP_KEY,
    REFERENCES_DIALER_TRUNK, SET_UPLOAD_LOADING_DIALER
} from '../const/';
import {REFERENCES_ALL_SET_LISTS, REFERENCES_SET_LISTS, REFERENCES_SET_LOADING} from '../actions/actionTypes'
import _ from 'lodash';

const initialState = {
    loading: false,
    price_list: [],
    currency_list: [],
    country_list: [],
    payment_terms_list: [],
    account_manager_list: [],
    worldzone_list: [],
    destination_list: [],
    subdestination_list: [],
    protocol_list: [],
    service_plan_list: [],
    service_plan_list_filtered: [],
    client_list: [],
    dialer_list: [],
    supplier_list: [],
    dialerTrunkList: [],
    defaultSPKey: DEFAULT_SP_KEY,
    admin_allocation_limit: {},
    client_allocation_limit: {},
    client_role_list: [],
    uploadLoadingDialer: false,
    support_contacts: [],
    contacts_text: null
};

const handlers = {    
    [REFERENCES_SET_LOADING]: (state,{payload}) =>({...state, loading: payload}),
    [REFERENCES_SET_LISTS]: (state, {payload: references}) => ({
        ...state,
        ...references,
        defaultSPKey: _.get(references, 'service_plan_list[0].sp_key', DEFAULT_SP_KEY),
        client_allocation_limit: references.client_allocation_limit ? {...references.client_allocation_limit} : {},
        loading: false
    }),
    [REFERENCES_ALL_SET_LISTS]: (state, {payload}) => ({
        ...state,
        ...payload
    }),
    [REFERENCES_DIALER_TRUNK]: (state, {payload: dialerTrunkList}) => ({...state, dialerTrunkList}),
    [SET_UPLOAD_LOADING_DIALER]: (state, {payload}) => ({...state, uploadLoadingDialer: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
