import React, {useMemo, useContext} from "react";
import {Icon} from "rsuite";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import {checkPermissionsFor} from "../../../store/storeHelpers";
import {useWindowWidth} from "../../../hooks";
import {
    ACCESS_EXCLUSION_LIST, ACCOUNT_MANAGERS_REFERENCES_API, ACTION_LOGS_GET_LIST_API, AM_ACTIVITIES_LIST_API,
    BLOCKED_DESTINATIONS_GET_LIST_API, GOOGLE_OTP_ALLOCATION_GET_LIST_API, GOOGLE_OTP_QUARANTINED_GET_LIST_API,
    PAYMENT_REQUEST_LIST_API, PRICE_RANGE_QUARANTINE_SETTING_GET_LIST_API,
    SIM_TRAFFIC_RANGES_GET_LIST
} from '../../../const/apiMethods'
import {AppTypeContext} from "../../../App";
import {useIntl} from "react-intl";
import {APP_TYPE_ADMIN} from "../../../const";
import _ from "lodash";


export default ({
    permissions,
    openAllocationModal,
    registrationCount,
    authInfo,
    localMessages: m = {},
}) => {


    const appType = useContext(AppTypeContext);
    const windowWidth = useWindowWidth();
    const intl = useIntl();

    const menuDataAdmin = useMemo(() => {

        const isTest = _.get(authInfo, 'session.account.is_test', false);
        const filterMenuItems = (items) => (
            items.reduce((sum, item) => {
                const children = (item.children && item.children.length)
                    ? filterMenuItems(item.children)
                    : null;
                if (item.checkedMethods) {
                    for (const method of item.checkedMethods) {
                        if (!checkPermissionsFor(method))
                            return sum;
                    }
                }
                return [...sum, {...item, children: children}];
            }, [])
        );

        const clientMenu = [
            {key: '/live-calls', title: intl.formatMessage(m.liveCalls), icon: <Icon icon="phone-square"/>},
            {key: '/numbers', title: intl.formatMessage(m.myNumbers), hideOnTest: true},
            {key: '/test-calls', title: intl.formatMessage(m.testCalls)},
            {key: '/traffic', title: intl.formatMessage(m.report), hideOnTest: true},
            {key: '/price', title: intl.formatMessage(m.ratesAndNumbers)},
            {key: '/accesses', title: intl.formatMessage(m.accessList)},
            {key: '/cli', title: intl.formatMessage(m.cliLookup), hideOnTest: true},
            {key: '/ivr', title: intl.formatMessage(m.ivr)},
            {key: '/api', title: "API", hideOnTest: true},
            {key: '/payments', title: intl.formatMessage(m.payments), hideOnTest: true},
        ];

        const adminMenu = [
            {key: '/accounts', title: "Accounts"},
            {key: '/prices', title: "Prices"},
            {key: '/access-list', title: "Access list"},
            {key: '/cdr', title: "CDR"},
            {key: '/traffic-reports', title: "Traffic Reports"},
            {key: '/live-calls', title: "Live calls"},
            {title: "Number allocation", onClick: openAllocationModal},
            {key: '/ranges-numbers', title: "Ranges & numbers"},
            {key: '/auth-numbers', title: "Auth Numbers"},
            {key: '/payments', title: "Payment management", checkedMethods: [PAYMENT_REQUEST_LIST_API]},
            {key: '/ivrs', title: "IVRs"},
            {key: null, title: "Tools",
                children: [
                    {key: '/exceptions-accesses', title: "Exceptions for access lists", checkedMethods: [ACCESS_EXCLUSION_LIST]},
                    {key: '/sim-traffic-ranges', title: "SIM Traffic Ranges", checkedMethods: [SIM_TRAFFIC_RANGES_GET_LIST]},
                    {key: '/blocked-destinations', title: "Blocked Destinations", checkedMethods: [BLOCKED_DESTINATIONS_GET_LIST_API]},
                    {key: '/google-otp-quarantined-ranges', title: "Google OTP quarantined ranges", checkedMethods: [GOOGLE_OTP_QUARANTINED_GET_LIST_API]},
                    {key: '/google-otp-allocation-subdestination', title: "Google OTP allocation subdestination", checkedMethods: [GOOGLE_OTP_ALLOCATION_GET_LIST_API]},
                    {key: '/special-quarantine-terms', title: "Special quarantine terms", checkedMethods: [PRICE_RANGE_QUARANTINE_SETTING_GET_LIST_API]},
                    {key: '/action-logs', title: "Action Logs", checkedMethods: [ACTION_LOGS_GET_LIST_API]},
                    {key: '/am-activities', title: "AM activities", checkedMethods: [AM_ACTIVITIES_LIST_API]},
                    {key: '/account-managers', title: "Account Managers", checkedMethods: [ACCOUNT_MANAGERS_REFERENCES_API]},
                    {key: '/settings', title: "Settings", checkedMethods: ['global_setting:get_list']},

                ]
            },
        ];

        return appType === APP_TYPE_ADMIN
            ? filterMenuItems(adminMenu)
            : filterMenuItems(clientMenu.filter(item => !(isTest && item.hideOnTest)));

    }, [permissions, appType, intl, authInfo]);

    return (
        <>
            {windowWidth > 1200
                ? <MenuDesktop registrationCount={registrationCount} data={menuDataAdmin} />
                : <MenuMobile registrationCount={registrationCount} data={menuDataAdmin} localMessages={m} />
            }
        </>
    )
};