import React, {useCallback, useMemo} from "react";
import {
    getFormattedTime,
    getISOTime,
    getAllowedRangesByIntervalMonth
} from "../../../utils/helpers";
import {useIntl} from "react-intl";
import {LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER} from '../../../const';
import {Table, Tooltip, Whisper} from "rsuite";
import * as S from "../styled.js";
import CommonTable from "../CommonTable";
import m from "../../../definedMessages";

const {Column, HeaderCell, Cell} = Table;

export default ({
    data = [], 
    loading = false, 
    openModalRequestPayment,
    currencyName,
    globalSettings,

    intl
}) => {

    const {formatMessage} = useIntl();

    const columns = useMemo(() => {

        const statusLabels = [
            formatMessage(m.pending),
            formatMessage(m.readyForPayment),
            formatMessage(m.paid),
        ];

        return [
            {
                id: 'trafficPeriod',
                label: formatMessage(m.trafficPeriod),
                value: ({date_from, date_to}) => `${ getFormattedTime(date_from) } - ${ getFormattedTime(date_to)}`,
                flexGrow: 2,
                minWidth: 200,
                align: 'center'
            },
            {
                id: 'date',
                label: formatMessage(m.date),
                dataKey: "date2",
                flexGrow: 2,
                minWidth: 200,
                align: 'center'
            },
            {
                id: 'amount',
                label: formatMessage(m.amount),
                value: ({agr_send}) => `${agr_send}${currencyName ? ' ' + currencyName : ''}`,
                flexGrow: 2,
                minWidth: 200,
                align: 'center'
            },
            {
                id: 'status',
                label: formatMessage(m.status),
                value: ({status}) => statusLabels[status],
                flexGrow: 2,
                minWidth: 200,
                align: 'center'
            },
        ]

    }, [currencyName]);

    const showDetails = useCallback((rowData) => {
        const dateFrom = new Date(rowData.date_from);
        const dateTo = new Date(rowData.date_to);

        const filtersDefaultValue = {
            group: "range/a_number/b_number",
            checkbox: ["range", "a_number", "b_number"],
            start_date: getISOTime(dateFrom),
            end_date: getISOTime(dateTo),
            start_end_date: [dateFrom, dateTo]
        }
        localStorage.setItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER, JSON.stringify(filtersDefaultValue));
        window.open('/traffic/?start_tab=traffic_reports');
    }, []);


    const tableActions = (
        <Column width={210} key="table__actions">
            <HeaderCell> </HeaderCell>
            <Cell>
                {rowData => {

                    const dateFrom = new Date(rowData.date_from);
                    const allowedRanges = getAllowedRangesByIntervalMonth(globalSettings.cdr_full__interval_month);
                    const startDateYmd = `${dateFrom.getFullYear()}${('0' + (dateFrom.getMonth() + 1)).slice(-2)}${('0' + dateFrom.getDate()).slice(-2)}`;

                    return (
                        <S.StyledTableActions alignEnd>

                            {rowData.status === 1 &&
                                <S.StyledTableButton
                                    onClick={openModalRequestPayment}
                                >
                                    {formatMessage(m.requestPayment)}
                                </S.StyledTableButton>
                            }

                            {startDateYmd < allowedRanges[0]
                                ?
                                <Whisper
                                    preventOverflow
                                    trigger="hover"
                                    speaker={<Tooltip>{formatMessage(m.cdrHistoryPeriodIs, {count: globalSettings && globalSettings.cdr_full__interval_month})}</Tooltip>}
                                    placement="auto"
                                >
                                    <S.StyledTableButton
                                        className="rs-btn-disabled"
                                    >
                                        {formatMessage(m.details)}
                                    </S.StyledTableButton>
                                </Whisper>
                                : <S.StyledTableButton
                                    onClick={(e) => showDetails(rowData)}
                                >
                                    {formatMessage(m.details)}
                                </S.StyledTableButton>
                            }

                        </S.StyledTableActions>
                    );
                }}
            </Cell>
        </Column>
    );  

    return (
        <CommonTable
            data={data}
            loading={loading}
            columns={columns}
            tableActions={tableActions}
        />
    )
};