import React from 'react';
import PageHeader from '../../components/base/PageHeader';
import TestCallsTable from './TestCallsTable';
import TestCallsFilters from './TestCallsFilters';
import {objectIsEmpty, removeTZFromDate} from '../../utils/helpers';
import {
    LOCAL_STORAGE_TEST_CALLS_FILTERS_DIALER,
    LOCAL_STORAGE_TEST_CALLS_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from '../../const';
import {FormattedMessage} from "react-intl";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import {Spacer} from '../../components/base/Spacer';
import {Drawer, FlexboxGrid, Icon, IconButton} from "rsuite";
import styled from "styled-components";
import PanelLayout from "../../components/base/PanelLayout";


export default class extends React.Component {
    constructor(props) {
        super(props);

        this.filter = {};
        this.defaultFilter = {
            start_date: removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999)))
        };
        this.curPerPage = null;

        this.state = {
            searchLoading: false,
            savedTableFilter: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TEST_CALLS_FILTERS_DIALER)),
            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TEST_CALLS_PER_PAGE_DIALER)),
            resizedWidth: window.innerWidth,
            activeFiltersCount: 0,
            showFilters: false,
        };
    }

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    componentDidMount() {
        const {savedPerPage, savedTableFilter} = this.state;
        const {getDialerTestCalls, per_page} = this.props;

        this.setActiveFiltersCount(this.state.savedTableFilter);

        this.curPerPage = savedPerPage;
        let pickedFilter;

        if (!objectIsEmpty(savedTableFilter)) {
            pickedFilter = savedTableFilter;
        } else if (!objectIsEmpty(this.filter)) {
            pickedFilter = this.filter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        getDialerTestCalls(savedPerPage || per_page, pickedFilter, 1);

    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    onChangeFilters = (filtersObj) => {
        const {savedPerPage} = this.state;
        const {getDialerTestCalls, per_page} = this.props;

        this.setActiveFiltersCount(filtersObj);

        this.filter = filtersObj;

        localStorage.setItem(LOCAL_STORAGE_TEST_CALLS_FILTERS_DIALER, JSON.stringify(this.filter));

        this.setState({
            savedTableFilter: filtersObj
        });

        getDialerTestCalls(savedPerPage || this.curPerPage || per_page, this.filter, 1);
    };

    combineFilters = (filter, savedFilter) => {
        return {
            start_date: filter && filter.start_date ? filter.start_date :
                savedFilter && savedFilter.start_date ? savedFilter.start_date :
                    removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: filter && filter.end_date ? filter.end_date :
                savedFilter && savedFilter.end_date ? savedFilter.end_date :
                    removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999))),
            a_number: filter && (filter.a_number || filter.a_number === '') ? filter.a_number :
                savedFilter && (savedFilter.a_number || savedFilter.a_number === '') ? savedFilter.a_number : '',
            b_number: filter && (filter.b_number || filter.b_number === '') ? filter.b_number :
                savedFilter && (savedFilter.b_number || savedFilter.b_number === '') ? savedFilter.b_number : ''
        };
    };

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        const filters = Object.keys(value);
        const dateFilters = ['start_end_date', 'start_date', 'end_date'];
        const activeFilters = filters.filter(item => {
            if ( dateFilters.includes(item) )
                return false;

            if ( value[item] === this.defaultFilter[item] || (!value[item] && value[item] !== 0) ) {
                return false;
            }
            return true;
        });

        this.setState({
            activeFiltersCount: activeFilters.length + 1
        });
    };

    onChangePerPage = (perPage) => {
        const {savedTableFilter} = this.state;
        const {getDialerTestCalls} = this.props;

        if (perPage)
            this.setState({savedPerPage: null});
        else
            this.setState({savedPerPage: this.curPerPage});

        let pickedFilter;
        const per_page = Number(perPage) || this.curPerPage;
        const combinedFilter = this.combineFilters(this.filter, savedTableFilter);

        if (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        per_page <= 0 ? this.curPerPage = MIN_PER_PAGE_DIALER : per_page > MAX_PER_PAGE_DIALER ? this.curPerPage = MAX_PER_PAGE_DIALER : this.curPerPage = per_page;

        localStorage.setItem(LOCAL_STORAGE_TEST_CALLS_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        getDialerTestCalls(this.curPerPage, pickedFilter, 1);

    };

    onChangePage = (pageNumber) => {
        const {savedTableFilter, savedPerPage} = this.state;
        const {getDialerTestCalls, per_page} = this.props;

        let pickedFilter;
        const combinedFilter = this.combineFilters(this.filter, savedTableFilter);

        if (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        getDialerTestCalls(savedPerPage || this.curPerPage || per_page, pickedFilter, pageNumber);
    };

    render() {
        const {searchLoading, savedTableFilter, savedPerPage} = this.state;
        const {loading, test_calls_list, count, page, per_page} = this.props;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;

        return (
            <PanelLayout>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item>
                        <PageHeader title={<FormattedMessage id="testCalls.testCalls" defaultMessage="TEST CALLS" />}/>
                    </FlexboxGrid.Item>

                    {this.state.resizedWidth < 768 &&

                        <FlexboxGrid.Item>
                            <Toggler
                                icon={<Icon icon="filter"/>}
                                onClick={() => this.setState({showFilters: true})}
                            >
                                {this.state.activeFiltersCount}
                            </Toggler>
                            <Drawer
                                size="xs"
                                placement="bottom"
                                style={{height: '240px'}}
                                show={this.state.showFilters}
                                onHide={() => this.setState({showFilters: false})}
                            >
                                <Drawer.Header>
                                </Drawer.Header>
                                <Drawer.Body>
                                    <TestCallsFilters
                                        onChangeFilters={this.onChangeFilters}
                                        loading={loading || searchLoading}
                                        {...{savedTableFilter}}
                                    />
                                </Drawer.Body>
                            </Drawer>
                        </FlexboxGrid.Item>

                    }

                </FlexboxGrid>

                {this.state.resizedWidth >= 768 &&
                    <FlexGrid>
                        <FlexGridItem>
                            <TestCallsFilters
                                onChangeFilters={this.onChangeFilters}
                                loading={loading || searchLoading}
                                {...{savedTableFilter}}
                            />
                            <Spacer/>
                        </FlexGridItem>
                    </FlexGrid>
                }

                <TestCallsTable
                    data={test_calls_list}
                    loading={loading || searchLoading}
                    {...{
                        onChangePage,
                        onChangePerPage,
                        page,
                        count,
                        per_page,
                        savedPerPage
                    }}
                />
            </PanelLayout>
        );
    }
}

const Toggler = styled(IconButton)`
    && {
        color: #363434;
    }
`;