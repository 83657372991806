import React, { useEffect, useState } from 'react';
import {Alert, Button, Icon} from 'rsuite';
import {APP_VERSION} from "../index";
import {semverGreaterThan} from "../utils/helpers";
import {getAppVersion} from "../api/loginRoutes";
import {useWindowWidth} from "../hooks";
import BaseModal from "./base/BaseModal";

const NEW_VERSION_AVAILABLE = 'new_version_available';


export default () => {

    const [show, setShow] = useState(false);
    const resizedWidth = useWindowWidth();


    // check on mount
    useEffect( () => {
        checkVersion(true);
    }, [] );


    // interval check
    useEffect( () => {
        const interval = setInterval(() => {
            checkVersion();
        }, 120000);
        return () => clearInterval(interval);
    }, [] );


    const checkVersion = (showCacheNotification) => {
        const storageVersion = localStorage.getItem(NEW_VERSION_AVAILABLE);

        getAppVersion().then( (meta) => {
            if (!meta)
                return;

            const isMetaVersionGreater = semverGreaterThan(meta.version, APP_VERSION);

            if (!isMetaVersionGreater && storageVersion) {
                localStorage.removeItem(NEW_VERSION_AVAILABLE);
                return;
            }

            if (isMetaVersionGreater ) {

                if (storageVersion) {
                    if (storageVersion && storageVersion !== meta.version) {
                        localStorage.setItem(NEW_VERSION_AVAILABLE, meta.version);
                    }
                    if (showCacheNotification) {
                        Alert.error(<>
                            <p>New version <b>{meta.version}</b> is available.</p>
                            <p>Please, clear cache and reload the page</p>
                        </>, 300000000);
                    }
                    return;
                }

                setShow(true);
                localStorage.setItem(NEW_VERSION_AVAILABLE, meta.version);
            }
        } )
    };


    const handleClose = () => {
        setShow(false);
        window.location.reload();
    };


    return (
        <BaseModal
            show={show}
            onHide={handleClose}
            backdrop="static"
            width={resizedWidth > 600 ? 600 : resizedWidth}
        >
            <BaseModal.Header>
            </BaseModal.Header>
            <BaseModal.Body style={{maxHeight: '100%'}}>
                <Icon
                    icon="remind"
                    style={{
                        color: '#ffb300',
                        fontSize: 24
                    }}
                />
                &nbsp;&nbsp; The system has been updated, the page will be reloaded.
            </BaseModal.Body>
            <BaseModal.Footer>
                <Button onClick={handleClose} appearance="primary">
                  Ok
                </Button>
            </BaseModal.Footer>
        </BaseModal>
    );
};