import React, {Component, createRef} from 'react';
import {Schema, Table, Alert, Button, Form, Icon, FlexboxGrid, FormControl, SelectPicker, Input} from 'rsuite';
import Text from 'components/Form/Text';
import BaseTable from "../../components/base/BaseTable";

const {StringType, NumberType} = Schema.Types;
const {Column, HeaderCell, Cell} = Table;

export default class extends Component {
    constructor(props) {
        super(props);
        const {data} = this.props;

        this.columns = [
            {label: 'Number', dataKey: 'number', width: 400, type: 'text', flexGrow: 3},
            {label: 'Description', dataKey: 'description', width: 1150, type: 'text', flexGrow: 6},
            {label: 'Service plan', dataKey: 'sp_key', flexGrow: 1, type: 'select'}
        ];

        this.columnOptions = [
            {
                id: 'number',
                flexGrow: 3,
                width: 180
            },
            {
                id: 'description',
                flexGrow: 6,
                width: 440
            }
        ];
        this.formsNumbersRef = {};
        this.formEditRef = {number: createRef(), description: createRef()};
        this.refTextArea = createRef();

        this.formModel = Schema.Model({
            number: StringType()
                .pattern(/^[1-9][\d]*$/, 'The number must not begin with 0 and must contain only digits')
                .isRequired('This is required field')
                .minLength(6, 'The minimum of this field is 6 characters')
                .maxLength(15, 'The maximum  of this field is 15 characters'),
            description: StringType().isRequired('This is required field').maxLength(255, 'The maximum of this field is 255 characters'),
            sp_key: NumberType().isRequired('This is required field')
        });

        this.copy = null;
        this.defaultHeightTable = 500;

        this.state = {
            numberState: data || [],
            editState: null,
            formState: data || [],
            inputError: {},
            dataSetted: this.props.dataSetted,
            inputMessage: {},
            filledTextArea: '',
            autoHeightTable: this.defaultHeightTable,
            resizedWidth: window.innerWidth,
            editFormValue: {number: '', description: '', sp_key: null},
            editSpKeyValue: null
        };
    }

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate = (prevProps) => {
        const {data, getAuthNumbers} = this.props;

        if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {

            this.setState({numberState: data, formState: data});

            // The code below will check - does it compare fetched data || set from scratch (param: dataSetted)
            if (this.state.dataSetted === false) {
                this.setState({dataSetted: true})
            } else {
                getAuthNumbers();
            }

            data && data.length && this.setState({autoHeightTable : this.getAuthHeightTable(data)})
        }
    };

    updateFormRef = (refStorage, ref, id, dataKey) => {
        refStorage[id] = {...refStorage[id], [dataKey]: ref};
    };

    updateTableValues = (formValues, id) => {
        const {numberState} = this.state;

        this.setState({
            numberState: [
                ...numberState.map(
                    number => number.an_key === id ? {...number, ...formValues} : number
                )
            ]
        });
    };

    updateFormValues = (formValues, id) => {
        const {formState} = this.state;

        this.setState({
            formState: [
                ...formState.map(
                    number => number.an_key === id ? {...number, ...formValues} : number
                )
            ]
        });
    };

    getNumbers = () => {
        const {getAuthNumbers} = this.props;

        getAuthNumbers();
    };

    renderColumn = ({label, dataKey, value = null, width = 300, type = 'text', ...props}) => {
        const {service_plan_list} = this.props;
        const {inputError, inputMessage, formState, filledTextArea} = this.state;
        const servicePlanListOTP = service_plan_list.filter(servicePlan => !!servicePlan.auth);
        return (
            <Column width={width} {...props}>
                <HeaderCell>{label}</HeaderCell>
                <Cell dataKey={dataKey} rowIndex={dataKey}>
                    {
                        (rowData) => {
                            return <Form
                                onMouseOver={() => {
                                    this.copy = {[rowData.an_key]: dataKey};

                                    if (this.formsNumbersRef[rowData.an_key] && this.formsNumbersRef[rowData.an_key][dataKey]) {
                                        this.setState({filledTextArea: this.formsNumbersRef[rowData.an_key][dataKey].value});
                                    }
                                }}
                                onMouseOut={() => {
                                    this.copy =  null;
                                }}
                                classPrefix={'auth-number-form'}
                                model={this.formModel}
                                onError={error => {
                                    this.setState({
                                        inputError: {
                                            ...inputError,
                                            [rowData.an_key]: {...inputError[rowData.an_key], [dataKey]: error[dataKey]}
                                        }
                                    });
                                }}
                                key={rowData.an_key}
                                onChange={(formValues, event) => {
                                    const formCheck = this.formModel.check(formValues);
                                    const checker = Object.keys(formCheck).map(i => formCheck[i].hasError).some(value => value === false);

                                    this.setState({
                                        inputMessage: {
                                            ...inputMessage,
                                            [rowData.an_key]: {...inputMessage[rowData.an_key], [dataKey]: !checker}
                                        }
                                    });
                                    this.setState({
                                        formState: [
                                            ...formState.map(
                                                number => number.an_key === rowData.an_key ? {
                                                    ...number, ...formValues,
                                                    ...(event && event.target ? {event: {...number.event, [event.target.name]: event.target}} : {})
                                                } : number
                                            )
                                        ]
                                    });
                                }}
                                value={{[dataKey]: rowData[dataKey]}}
                                formDefaultValue={{[dataKey]: rowData[dataKey]}}
                            >
                                {type === 'text' && <Text
                                    inputRef={ref => this.updateFormRef(this.formsNumbersRef, ref, rowData.an_key, dataKey)}
                                    className="auth-number-input"
                                    readOnly={rowData.an_key ? !rowData.edit : false}
                                    name={dataKey}
                                    placeholder={label}
                                    message={inputError && inputError[rowData.an_key] && inputMessage[rowData.an_key] && inputMessage[rowData.an_key][dataKey] &&
                                    <span className="rs-table-auth-numbers-input-error">
                                        {inputError[rowData.an_key][dataKey]}
                                    </span>
                                    }
                                />}
                                {type === 'select' && <FormControl
                                    readOnly={rowData.an_key ? !rowData.edit : false}
                                    searchable={false}
                                    cleanable={false}
                                    errorPlacement="topEnd"
                                    className="tableFilters_field"
                                    name="sp_key"
                                    accepter={SelectPicker}
                                    data={servicePlanListOTP}
                                    placeholder="Service plan"
                                    valueKey="sp_key"
                                    labelKey="name"
                                    type="text"
                                    message={inputError && inputError[rowData.an_key] && inputMessage[rowData.an_key] && inputMessage[rowData.an_key][dataKey] &&
                                    <span className="rs-table-auth-numbers-input-error">
                                        {inputError[rowData.an_key][dataKey]}
                                    </span>
                                    }
                                />}
                                {
                                    !rowData.edit &&
                                    this.copy &&
                                    this.copy[rowData.an_key] &&
                                    this.copy[rowData.an_key] === dataKey &&
                                    type !== 'select' &&
                                <>
                                    <Button
                                        className="rs-table-auth-numbers-copy-button"
                                        onClick={() => {
                                            if (this.formsNumbersRef[rowData.an_key]) {
                                                this.refTextArea.current.select();
                                                document.execCommand('copy');
                                                const messages = {
                                                    [rowData.number]: `Number "${rowData.number}" was copied!`,
                                                    [rowData.description]: `Description "${rowData.description}" was copied!`
                                                };
                                                Alert.success(messages[this.refTextArea.current.value]);
                                            }
                                        }}
                                    >
                                        <Icon inverse icon="copy-o"/>
                                    </Button>
                                    <textarea
                                        ref={this.refTextArea}
                                        value={filledTextArea}
                                        className="rs-table-auth-numbers-textarea-hidden"
                                    />
                                </>}
                            </Form>;
                        }
                    }
                </Cell>
            </Column>
        );
    };

    getAuthHeightTable = (data) => {
        const length = (data.length);
        const height = data && length ? length * 46 + 40 : 40;

        return length <= 10 ? height : this.defaultHeightTable
    };


    render() {
        const {
            inputError, numberState, formState, inputMessage, editState, autoHeightTable, resizedWidth, editFormValue,
            editSpKeyValue
        } = this.state;
        const {loading, onAddAuthNumber, onEditAuthNumber, onPickDeleteNumber, permission, service_plan_list} = this.props;

        // const renderedColumns = this.columns.map(this.renderColumn);
        for (const column in this.columns) {
            const elem = this.columns[column];
            const columnOption = this.columnOptions.find(item => item.id === elem.dataKey);
            if (resizedWidth >= 800) {
                if (columnOption && !elem.flexGrow) {
                    elem.flexGrow = columnOption.flexGrow;
                    delete elem.width;
                }
            } else {
                if (columnOption) {
                    elem.width = columnOption.width;
                    delete elem.flexGrow;
                }
            }
        }
        const renderedColumns = this.columns.map(this.renderColumn);
        const servicePlanListOTP = service_plan_list.filter(servicePlan => !!servicePlan.auth);
        const formControlNames = [{name: "number", flexGrow: 3}, {name: "description", flexGrow: 6}, {name: "sp_key", flexGrow: 1}];
        const formControlItems = [
            <Text
                inputRef={ref => this.formEditRef['number'] = ref}
                className="auth-number-add-input"
                name="number"
                placeholder={"Number"}
                message={
                    inputError["new"] && inputMessage["new"] && inputMessage["new"]["number"] &&
                    <span className="rs-table-auth-numbers-input-error">{inputError["new"]["number"]}</span>
                }
            />,
            <Text
                inputRef={ref => this.formEditRef['description'] = ref}
                className="auth-number-add-input"
                name="description"
                placeholder={"Description"}
                message={
                    inputError && inputError["new"] && inputMessage["new"] && inputMessage["new"]["description"] &&
                    <span className="rs-table-auth-numbers-input-error">{inputError["new"]["description"]}</span>
                }
            />,
            <FormControl
                searchable={false}
                cleanable={false}
                errorPlacement="topEnd"
                className="tableFilters_field"
                name="sp_key"
                accepter={SelectPicker}
                data={servicePlanListOTP}
                placeholder="Service Plan"
                valueKey="sp_key"
                labelKey="name"
                onChange={(value) => {this.setState({editSpKeyValue: value})}}
                value={editSpKeyValue}
                type="text"
                message={
                    inputError && inputError["new"] && inputMessage["new"] && inputMessage["new"]["sp_key"] &&
                    <span className="rs-table-auth-numbers-input-error">{inputError["new"]["sp_key"]}</span>
                }
            />,
        ];

        const addColumns = formControlNames.map( key => {
            return {dataKey: key.name, label: ''}
        } );

        const tableColumnsElements = {};
        for (let i = 0; i < formControlNames.length; i++) {
            tableColumnsElements[formControlNames[i].name] = formControlItems[i];
        }

        const columnsElements = formControlNames.map( (item) => {
            return (
                <Column flexGrow={item.flexGrow}>
                    <HeaderCell></HeaderCell>
                    <Cell dataKey={item.name}/>
                </Column>
            )
        } );
        return (<>
            <BaseTable
                shouldUpdateScroll={false}
                virtualized
                height={autoHeightTable}
                data={numberState}
                loading={loading || !this.state.dataSetted}
                columns={this.columns}
            >
                {renderedColumns}
                <Column width={120}>
                    <HeaderCell>Action</HeaderCell>
                    <Cell>
                        {
                            rowData =>
                                {return permission ? <div style={{marginTop: '2px'}}>
                                    {! rowData.edit &&
                                    <Button    // Edit number
                                        color="green"
                                        onClick={() => {
                                            this.setState({editState: rowData});
                                            this.updateTableValues({edit: true}, rowData.an_key);
                                        }}
                                        className="rs-table-auth-numbers-buttons rs-table-auth-numbers-green-button"
                                    >
                                        <Icon icon="edit2"/>
                                    </Button>
                                    || <>
                                        <Button    // Confirm editing
                                            color="green"
                                            onClick={() => {
                                                const dataField = formState.find(number => number.an_key === rowData.an_key);
                                                this.updateTableValues({
                                                    ...dataField,
                                                    edit: false
                                                }, rowData.an_key);

                                                onEditAuthNumber(rowData.an_key, dataField);
                                            }}
                                            className="rs-table-auth-numbers-buttons rs-table-auth-numbers-green-button"
                                            disabled={
                                                formState && formState.length && Object.keys(this.formModel.check(formState.find(number => number.an_key === rowData.an_key)))
                                                    .map(i => this.formModel.check(formState.find(number => number.an_key === rowData.an_key))[i].hasError)
                                                    .some(value => value === true)
                                                    ? 'disabled' : ''
                                            }
                                        >
                                            <Icon icon="check-circle"/>
                                        </Button>
                                        <Button    // Cancel editing
                                            color="red"
                                            onClick={() => {
                                                this.getNumbers();
                                                this.setState({
                                                    editState: null,
                                                    inputMessage: {
                                                        ...inputMessage,
                                                        [rowData.an_key]: {...inputMessage[rowData.an_key], number: false, description: false}
                                                    }
                                                });
                                                this.updateTableValues({
                                                    ...rowData,
                                                    edit: false
                                                }, rowData.an_key);

                                                const formValue = formState.find(state => state.an_key === rowData.an_key);

                                                if (rowData.an_key === (editState && editState.an_key) && formValue.event) {
                                                    for (let name in formValue.event) {
                                                        if (formValue.event.hasOwnProperty(name)) {
                                                            formValue.event[name].value = editState[name];
                                                        }
                                                    }
                                                }
                                            }}
                                            className="rs-table-auth-numbers-buttons rs-table-auth-numbers-red-button"
                                        >
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                    }
                                    <Button    // Delete number
                                        color="red"
                                        onClick={() => {
                                            onPickDeleteNumber(true, rowData.an_key, () => {
                                                this.setState({
                                                    numberState:
                                                        [
                                                            ...numberState.filter(number => number.an_key !== rowData.an_key)
                                                        ]
                                                })
                                            });
                                        }}
                                        className="rs-table-auth-numbers-single-button rs-table-auth-numbers-red-button"
                                    >
                                        <Icon icon="trash2"/>
                                    </Button>
                                </div> : <></>}
                        }

                    </Cell>
                </Column>
            </BaseTable>
            {permission ? <Form
                layout="inline"
                model={this.formModel}
                classPrefix="auth-add-number-form"
                onChange={(formValues) => {
                    const formCheck = this.formModel.check(formValues);

                    this.setState({
                        inputMessage: {
                            ...inputMessage,
                            "new": {...inputMessage["new"], "number": formCheck["number"].hasError, "description": formCheck["description"].hasError, "sp_key": formCheck["sp_key"].hasError}
                        },
                        inputError: {
                            ...inputError,
                            "new": {...inputError["new"], "number": formCheck["number"].errorMessage, "description": formCheck["description"].errorMessage, "sp_key": formCheck["sp_key"].errorMessage}
                        },
                        editFormValue: {
                            ...formValues
                        }
                    });
                }}
                value={editFormValue}
                formDefaultValue={this.emptyField}
            >
                <BaseTable
                    data={[tableColumnsElements]}
                    columns={addColumns}
                    headerHeight={0}
                    autoHeight
                    rowHeight={46}
                    className="tableFilters tableFilters__form"
                >
                    {columnsElements}
                    <Column>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            <Button    // Add number
                                style={{width: '100%', marginTop: resizedWidth > 853 ? '0px' : '20px', height: 36}}
                                color="green"
                                onClick={() => {
                                    const numberValue = editFormValue;
                                    const duplicateNumber = numberState.find(value => value.number === numberValue.number);
                                    const duplicateDescription = numberState.find(value => value.description === numberValue.description);

                                    this.setState({
                                        editState: null,
                                        editFormValue: {number: '', description: '', sp_key: null},
                                        editSpKeyValue: null
                                    });
                                    if (!duplicateNumber || duplicateNumber && !duplicateDescription) {
                                        this.formEditRef['number'].value = '';
                                        this.formEditRef['description'].value = '';

                                        if (numberValue.number !== "" && numberValue.description !== "" && numberValue.sp_key !== "") onAddAuthNumber(numberValue);
                                        this.getNumbers()
                                    }
                                }}
                                className="rs-table-auth-numbers-single-button rs-table-auth-numbers-green-button"
                                disabled={
                                    !(inputMessage["new"] && (!inputMessage["new"]["description"] && !inputMessage["new"]["number"] && !inputMessage["new"]["sp_key"])) || !this.formEditRef['number'].value || !this.formEditRef['description'].value ? 'disabled' : ''
                                }
                            >
                                <Icon icon="plus"/>
                            </Button>
                        </Cell>
                    </Column>
                </BaseTable>
            </Form> : <></>}
        </>);
    };
}