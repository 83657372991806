import React from "react";
import Modal from '../../components/Modal';
import {useWindowWidth} from "../../hooks";
import ReactJson from 'react-json-view'

export default ({
    show,
    onClose,
    maxWidth = 600,
    title = "",
    onSuccess,
    loading,
}) => {

    const resizedWidth = useWindowWidth();

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => onClose(false)}
            title={title}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            successText="Confirm"
            disabled={loading}
            onSuccess={() => {
                onSuccess();
            }}
        >
            You're trying to clean some ranges for Google from quarantine. It may cause of allocation recently called numbers or neighbors of recently allocated numbers. Also pay your attention that recently called ranges will be restored automatically.
        </Modal>
    )
}