import {Nav} from "rsuite";
import styled from "styled-components";


export default styled(Nav)`
    &&&&&& {
        .rs-nav-waterline {
            border-bottom: 1.5px solid #8256C8;
            border-top: unset;
        }
        
        .rs-nav-item-active {
            .rs-nav-item-content {
                border: 1.5px solid #8256C8;
                border-bottom-color: #fff;
                color: #8256c8;
                padding: 7px 11px;
            }
        }
    }
`;