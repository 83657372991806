import React from 'react';
import Modal from '../Account/Trunks/AllocatedNumbers/AllocateNumbersModal/Modal';
import {connect} from "react-redux";
import {closeAllocationModal} from "../../actions";
import {withRouter} from "react-router";

const NumberAlocation = ({show, onClose, defaultSPKey, match}) => {

    return <Modal
            show={show}
            pageId={"/number-allocation"}
            match={match}
            disabledTrunk={false}
            sp_key={defaultSPKey}
            onClose={onClose}
            isDialerTrunkFiltered={true}
        />
    ;
};

const mapState = ({settings, references}) => ({
    show: settings.showAllocation,
    defaultSPKey: references.defaultSPKey,
});

export default connect(mapState, {
    onClose: closeAllocationModal
})( withRouter(NumberAlocation) );