import {connect} from 'react-redux';

import {getCLIList, loadingData} from '../../actions/client/cli';

import CLI from './CLI';

const mapState = ({auth, client_cli}) => ({
    account: auth.authInfo,
    
    CLIList: client_cli.items,
    count: client_cli.count,
    CLILoading: client_cli.loading,
    page: client_cli.page,
    per_page: client_cli.per_page,
});

export default connect(mapState, {
    getCLIList,
    loadingData
})(CLI);