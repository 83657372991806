import React, {useEffect, useState} from "react";
import PaymentsRequestSection from "./PaymentsRequestSection";
import PaymentsListSection from "./PaymentsListSection";
import PaymentsTrafficReportsSection from "./PaymentsTrafficReportsSection";
// import ModalAddPaymentDetail from "../PaymentDetails/ModalAddPaymentDetail";
import {Spacer} from "../../../components/base/Spacer";
import ModalRequestPayment from "../../Payments/ModalRequestPayment";
import ModalPaymentDetails from "../../Payments/ModalPaymentDetails";
import ModalAddPaymentDetail from "../../PaymentDetails/ModalAddPaymentDetail";
import FormPaymentSettings from "../../Payments/FormPaymentSettings";
import {Panel} from "rsuite";

export default ({
    accountId,
    paymentItems,
    paymentItemsLoading,

    paymentRequestItems,
    paymentRequestLoading,
    
    paymentTrafficReportsItems,
    paymentTrafficReportsLoading,

    paymentDetailsItems,

    accountInfo,
    accounts,

    organizationTypeList,
    countryList,
    paymentMethodList,

    // API methods
    getAccounts,
    getPaymentRequestList,
    requestPayment,
    cancelPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
    getPaymentList,
    getTrafficReportsList,
    getPaymentDetailsList,
    createPaymentDetails,
    modifyPaymentDetails,
}) => {

    const [showModalRequestPayment, setShowModalRequestPayment] = useState(false);
    const [requestPaymentFormValue, setRequestPaymentFormValue] = useState({amount: 0});
    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [showModalPaymentDetail, setShowModalPaymentDetail] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(true);
    const [amountLimitMin, setAmountLimitMin] = useState(0.01);

    useEffect(() => {
        getAccounts();
    }, []);

    // set payment detail amount min limit
    useEffect( () => {
        if (!requestPaymentFormValue.payment_detail_id || !paymentMethodList.length || !paymentDetailsItems.length) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentDetail = paymentDetailsItems.find(item => item.id === requestPaymentFormValue.payment_detail_id);
        if (!currentPaymentDetail) {
            setAmountLimitMin(0.01);
            return;
        }

        const currentPaymentMethod = paymentMethodList.find(item => item.pm_key === currentPaymentDetail.pm_key);
        const currentPaymentMethodLimit = currentPaymentMethod && currentPaymentMethod.limit
            ? currentPaymentMethod.limit
            : 0.01;
        setAmountLimitMin(currentPaymentMethodLimit);
    }, [requestPaymentFormValue, paymentMethodList, paymentDetailsItems] );

    // effect: get list on component mount
    useEffect( () => {
        if (!accountId)
            return;

        const data = {target: {account_id: accountId}};
        getPaymentDetailsList(data);
    }, [accountId] );

    const getPaymentAmountLimit = () => {
        const totalAmount = accountBalance || 0;
        const itemsAmount = paymentRequestItems.reduce( (sum, currentItem) => (
            ( currentItem.status !== undefined && [0, 2].includes(currentItem.status) ) 
                ? sum + currentItem.amount 
                : sum
        ), 0 );
        const amountDiff = totalAmount - itemsAmount;
        return amountDiff < 0 ? 0 : amountDiff.toFixed(2);
    };

    const openModalRequestPayment = () => {
        setShowModalRequestPayment(true);
    };

    const closeModalRequestPayment = () => {
        setShowModalRequestPayment(false);
    };

    const openModalPaymentDetail = () => {
        setShowModalPaymentDetail(true);
    }

    const handleShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
        setDropdownVisibility(false);
    };

    const closeModalAddPaymentDetail = (value) => {
        if (value) {
            setRequestPaymentFormValue({...requestPaymentFormValue, payment_detail_id: value.payment_detail.id});
        }
        setShowModalAddPaymentDetail(false);
        setDropdownVisibility(true);
    };

    const {currency_name: currencyName, BALANCE_INV: accountBalance, account_options, allow_hawala} = accountInfo || {};

    return (
        <>
            <Spacer />

            <FormPaymentSettings
                defaultValue={account_options || {}}
                default_allow_hawala={allow_hawala}
                {...{
                    accountId,
                    modifyPaymentSettings
                }}
            />

            <PaymentsRequestSection
                {...{
                    paymentRequestItems,
                    paymentRequestLoading,
                    cancelPaymentRequest,
                    approvePaymentRequest,
                    getPaymentRequestList,
                    openModalRequestPayment,
                    openModalPaymentDetail,
                    accountId,
                    currencyName
                }}
            />


            <Spacer size={30}/>
            <PaymentsListSection
                {...{
                    paymentItems,
                    paymentItemsLoading,
                    currencyName,
                    getPaymentList,
                    accountId,
                }}
            />

            <Spacer size={30}/>
            <PaymentsTrafficReportsSection
                {...{
                    paymentTrafficReportsItems,
                    paymentTrafficReportsLoading,
                    openModalRequestPayment,
                    currencyName,
                    getTrafficReportsList,
                    accountId,
                }}
            />

            <Spacer size={30}/>

            <ModalRequestPayment
                show={showModalRequestPayment}
                onClose={closeModalRequestPayment}
                formValue={requestPaymentFormValue}
                setFormValue={setRequestPaymentFormValue}
                amountLimit={getPaymentAmountLimit()}
                amountLimitMin={amountLimitMin}
                {...{
                    accountId,
                    currencyName,
                    accountBalance,
                    requestPayment,
                    paymentDetailsItems, 
                    getPaymentRequestList,
                    dropdownVisibility,
                    handleShowModalAddPaymentDetail,
                    paymentMethodList,
                    accounts
                }}
            />

            <ModalPaymentDetails
                show={showModalPaymentDetail}
                accountId={accountId}
                onClose={() => setShowModalPaymentDetail(false)}
            />
            
            
            <ModalAddPaymentDetail
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                {...{
                    accountId,
                    organizationTypeList,
                    countryList,
                    paymentMethodList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    getPaymentDetailsList
                }}
            />
           

        </>
    )
};