import React, {useEffect, useState} from 'react';
import FormHOC from 'hoc/FilltersForm';
import List from 'rsuite/es/List';
import Button from 'rsuite/es/Button';
import Icon from 'rsuite/es/Icon';
import {Alert, Schema, Tooltip} from 'rsuite'
import Whisper from 'rsuite/es/Whisper';
import Modal from 'components/Modal';
import Message from 'rsuite/es/Message';
import styled from "styled-components";
import Divider from 'rsuite/es/Divider';
import Checkbox from 'rsuite/es/Checkbox';
import {arrayToObjectSpecialKey, compareTwoArrays, copyToClipboard} from '../../../utils'
import Form from 'rsuite/es/Form';
import FormGroup from 'rsuite/es/FormGroup';
import FormControl from 'rsuite/es/FormControl';

const {StringType} = Schema.Types;

const rangeSchemaRanges = Schema.Model({
    ip_range_start: StringType().isRequired('This field is required')
        .addRule((value, data) => {
            if (Object.keys(data).length && data.ip_range_end && data.ip_range_end.length && value.length) {
                const ip_range_start_values = value.split('.');
                const ip_range_end_values = data.ip_range_end.split('.');
                let sumFirstIp = 0;
                let sumSecondIp = 0;
                for (const [idx, val] of ip_range_start_values.entries()) {
                    let firstIpValue = parseInt(val);
                    let secondIpValue = parseInt(ip_range_end_values[idx]);

                    if (idx === 0) {
                        firstIpValue = firstIpValue * 10000000;
                        secondIpValue = secondIpValue * 10000000;
                    } else if (idx === 1) {
                        firstIpValue = firstIpValue * 10000;
                        secondIpValue = secondIpValue * 10000;

                    } else if (idx === 2) {
                        firstIpValue = firstIpValue * 10;
                        secondIpValue = secondIpValue * 10;
                    }

                    sumFirstIp += sumFirstIp + firstIpValue;
                    sumSecondIp += sumSecondIp + secondIpValue;
                }

                return sumFirstIp <= sumSecondIp;
            }
            return true;
        }, 'Start range is over than end range'),
    ip_range_end: StringType().isRequired('This field is required')
});

const rangeSchema = Schema.Model({
    ip_range_start: StringType().isRequired('This field is required')
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address'),
    ip_range_end: StringType().isRequired('This field is required')
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address')
});

const rangeSchemaCreate = Schema.Model({
    ip_range_start: StringType()
        .addRule((value, data) => {
            if (Object.keys(data).length && data.ip_range_end && data.ip_range_end.length && value.length) {
                const ip_range_start_values = value.split('.');
                const ip_range_end_values = data.ip_range_end.split('.');
                let sumFirstIp = 0;
                let sumSecondIp = 0;
                for (const [idx, val] of ip_range_start_values.entries()) {
                    let firstIpValue = parseInt(val);
                    let secondIpValue = parseInt(ip_range_end_values[idx]);

                    if (idx === 0) {
                        firstIpValue = firstIpValue * 10000000;
                        secondIpValue = secondIpValue * 10000000;
                    } else if (idx === 1) {
                        firstIpValue = firstIpValue * 10000;
                        secondIpValue = secondIpValue * 10000;

                    } else if (idx === 2) {
                        firstIpValue = firstIpValue * 10;
                        secondIpValue = secondIpValue * 10;
                    }

                    sumFirstIp += sumFirstIp + firstIpValue;
                    sumSecondIp += sumSecondIp + secondIpValue;
                }

                return sumFirstIp <= sumSecondIp;
            }
            return true;
        }, 'Start range is over than end range')
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address'),
    ip_range_end: StringType()
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address')
});

const rangeSchemaUnited = Schema.Model({
    ip_range_start: StringType().isRequired('This field is required')
        .addRule((value, data) => {
            if (Object.keys(data).length && data.ip_range_end && data.ip_range_end.length && value.length) {
                const ip_range_start_values = value.split('.');
                const ip_range_end_values = data.ip_range_end.split('.');
                let sumFirstIp = 0;
                let sumSecondIp = 0;
                for (const [idx, val] of ip_range_start_values.entries()) {
                    let firstIpValue = parseInt(val);
                    let secondIpValue = parseInt(ip_range_end_values[idx]);

                    if (idx === 0) {
                        firstIpValue = firstIpValue * 10000000;
                        secondIpValue = secondIpValue * 10000000;
                    } else if (idx === 1) {
                        firstIpValue = firstIpValue * 10000;
                        secondIpValue = secondIpValue * 10000;

                    } else if (idx === 2) {
                        firstIpValue = firstIpValue * 10;
                        secondIpValue = secondIpValue * 10;
                    }

                    sumFirstIp += sumFirstIp + firstIpValue;
                    sumSecondIp += sumSecondIp + secondIpValue;
                }

                return sumFirstIp <= sumSecondIp;
            }
            return true;
        }, 'Start range is over than end range')
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address'),
    ip_range_end: StringType().isRequired('This field is required')
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address')
});

export default FormHOC(({
    keyList, modifyApiKey, deleteApiKey, account_id, addedNewApiKey, onAddedNewApiKey
}) => {
    const [apiKeyList, onChangeApiKeyList] = useState(keyList);
    const refTextAreaDict = {};
    const [formApiKey, onChangeFormApiKey] = useState({});
    const [activeDeleteModal, onChangeActiveDeleteModal] = useState(false);
    const [apiKeyDeleteModal, onChangeApiKeyDeleteModal] = useState(null);

    useEffect(() => {
        const newObject = arrayToObjectSpecialKey(keyList, 'api_key');
        onChangeFormApiKey(newObject);

        if (!compareTwoArrays(keyList, apiKeyList)) {
            onChangeApiKeyList(keyList);
        }
    }, [keyList]);

    return (
        <>
            <span className="api_page_keylist-header">Key List
                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>You can copy, modify or delete any api key</Tooltip>}>
                    <Icon className="api_page_keylist_header-icon" icon="info"/>
                </Whisper>
            </span>
            {apiKeyList && apiKeyList.length ? <>
                <StyledApiList
                    bordered
                >
                    {
                        apiKeyList.map(key => {
                            return <ApiListItem
                                item={key}
                                {...{
                                    account_id,
                                    formApiKey,
                                    refTextAreaDict,
                                    modifyApiKey,
                                    addedNewApiKey,
                                    onAddedNewApiKey,
                                    onChangeFormApiKey,
                                    onChangeActiveDeleteModal,
                                    onChangeApiKeyDeleteModal
                                }}
                            />
                        })
                    }
                </StyledApiList>
            </> :
                <Message
                    className="api_page_keylist_boxgrid_empty-message"
                    description="You don't have any api key. Create one please"
                />
            }
            {activeDeleteModal &&
                <Modal
                    footer
                    title="Warning"
                    show={activeDeleteModal}
                    onClose={() => onChangeActiveDeleteModal(false)}
                    onSuccess={() => {
                        deleteApiKey(apiKeyDeleteModal, account_id);
                        onChangeActiveDeleteModal(false);
                    }}
                >
                    This api key will be deleted
                </Modal>
            }
        </>
    )
});

const ApiListItem = ({
     item = {},
     account_id,
     formApiKey,
     modifyApiKey,
     addedNewApiKey,
     onAddedNewApiKey,
     refTextAreaDict,
     onChangeFormApiKey,
     onChangeActiveDeleteModal,
     onChangeApiKeyDeleteModal
}) => {
    const initItemData = JSON.parse(JSON.stringify(item));

    if (item && Object.keys(item).length) {
        return (<List.Item className="list-item">
            <>
                <span className="api_key">{item.api_key}</span>
                <ApiButtonsPanel
                    {...{
                        item,
                        refTextAreaDict,
                        onChangeActiveDeleteModal,
                        onChangeApiKeyDeleteModal
                    }}
                />
                <Divider className="api_key_divider" vertical/>
                <ApiKeyOptions
                    {...{
                        item,
                        initItemData,
                        formApiKey,
                        account_id,
                        modifyApiKey,
                        addedNewApiKey,
                        onAddedNewApiKey,
                        onChangeFormApiKey
                    }}
                />
            </>
        </List.Item>);
    } else {
        return <></>
    }
};

const ApiButtonsPanel = ({
    item,
    refTextAreaDict,
    onChangeActiveDeleteModal,
    onChangeApiKeyDeleteModal
}) => {
    refTextAreaDict[item.key] = {};

    const onCopy = (text) => {
        copyToClipboard(text);

        Alert.success(`Copied!`);
    };
    return <StyledButtonPanel>
        <Button
            className="api_page_keylist_boxgrid_wrapper_panel-button"
            appearance="primary"
            onClick={() => {
                onCopy(item.api_key)
            }}
        >
            Copy
            <Icon
                inverse
                className="api_page_keylist_boxgrid_wrapper_panel_button-icon copy"
                icon="copy-o"
            />
        </Button>
        <Button
            className="api_page_keylist_boxgrid_wrapper_panel-button"
            appearance="primary"
            onClick={() => {
                onChangeActiveDeleteModal(true);
                onChangeApiKeyDeleteModal(item.api_key)
            }}
        >
            Delete
            <Icon
                reverse
                className="api_page_keylist_boxgrid_wrapper_panel_button-icon delete"
                icon="exclamation-triangle"
            />
        </Button>
    </StyledButtonPanel>
};

const ApiKeyOptions = ({
    item, account_id, initItemData, modifyApiKey, addedNewApiKey, onAddedNewApiKey
}) => {
    const [rangeRows, onChangeRangeRows] = useState(item.access_list);
    const [createFormError, onChangeCreateFormError] = useState([]);
    const [rangeChecker, onCheckRange] = useState(item.access_list && item.access_list.length
        ? [...Array(item.access_list)].map((_) =>{ return {ip_range_start: "", ip_range_end: ""}}) : []);
    const [valueSuccess, onChangeValueSuccess] = useState(false);
    const [helpModal, onShowHelpModal] = useState(false);
    const [someHasError, setSomeHasError] = useState(false);
    const [newRangeValue, onChangeNewRange] = useState({ip_range_start: "", ip_range_end: ""});
    const [newRangeChecker, onCheckNewRange] = useState({});
    const [newActiveValue, onChangeNewActive] = useState(item.active);

    useEffect(() => {
        onChangeRangeRows(item.access_list);
    }, [item]);

    useEffect(() => {
        if (addedNewApiKey) {
            onCheckNewRange({});
            onChangeNewRange({ip_range_start: "", ip_range_end: ""});
            onAddedNewApiKey(false)
        }
    }, [addedNewApiKey]);

    useEffect(() => {
        const containError = rangeChecker.length && rangeChecker.some(item => {
            return !!(
                item.ip_range_start && (item.ip_range_start.hasOwnProperty('hasError') && item.ip_range_start.hasError) ||
                item.ip_range_end && (item.ip_range_end.hasOwnProperty('hasError')  && item.ip_range_end.hasError) ||
                (Object.keys(createFormError).length && Object.keys(createFormError).some(key => Object.keys(createFormError[key]).length && (createFormError[key].ip_range_start || createFormError[key].ip_range_end)))
            );
        });
        setSomeHasError(containError);
    }, [rangeChecker, createFormError]);

    const showCreateButton = !someHasError && (
        (newActiveValue !== (initItemData.hasOwnProperty("active") && initItemData.active)) ||
        !compareTwoArrays(
            rangeRows,
            (initItemData.hasOwnProperty("access_list") && initItemData.access_list)));

    return (<>
        <Checkbox
            name="active"
            className="form_status"
            onChange={(value, checked) => onChangeNewActive(checked)}
            defaultChecked={initItemData.hasOwnProperty("active") ? initItemData.active : false}
        >
            Active
        </Checkbox>
        <List className="api_optional">
            {rangeRows.map((range, idx) => {
                let hasError = null;
                if (rangeChecker[idx] && Object.keys(rangeChecker[idx]).length) {
                    hasError = rangeChecker[idx].ip_range_end && rangeChecker[idx].ip_range_end.hasError
                        || rangeChecker[idx].ip_range_start && rangeChecker[idx].ip_range_start.hasError;
                }
                return (<List.Item className={`api_optional_list_item ${hasError ? 'has_error' : ''}`}>
                    {<Modal
                        show={helpModal}
                        title="Insecure IP range"
                        onClose={() => {
                            onChangeValueSuccess(true);
                            onShowHelpModal(false)
                        }}
                        footer={true}
                        successText="Confirm"
                        onSuccess={() => {
                            onChangeValueSuccess(true);
                            onShowHelpModal(false)
                        }}
                    >
                        This IP range is insecure. Do you really want to open this?
                    </Modal>}
                    <Form
                        model={rangeSchema}
                        className="api_page_create_modal-form"
                        onCheck={(error) => {
                            const newCreateFormError = {...createFormError};
                            const formHasError = Object.keys(error).length;
                            if (formHasError) {
                                if (!Object.keys(newCreateFormError).includes(idx)) {
                                    const oldError = newCreateFormError[idx] ? newCreateFormError[idx] : {};
                                    newCreateFormError[idx] = Object.assign(oldError, error);
                                }
                            } else {
                                delete newCreateFormError[idx];
                            }
                            onChangeCreateFormError(newCreateFormError)
                        }}
                        onChange={(value) => {
                            if (value.ip_range_start && value.ip_range_end
                                && (value.ip_range_start === "0.0.0.0"
                                && value.ip_range_end === "255.255.255.255" && !valueSuccess)
                            ) {
                                onShowHelpModal(true)
                                onChangeValueSuccess(true)
                            } else {
                                const newRangeChecker = [...rangeChecker];

                                newRangeChecker[idx] = rangeSchemaRanges.check(value);
                                onCheckRange(newRangeChecker)
                                onChangeValueSuccess(false)
                            }

                        }}
                        formValue={{ip_range_start: range.ip_range_start, ip_range_end: range.ip_range_end}}
                        disabled={hasError}
                    >
                        <FormGroup className={"optional_form_group"}>
                            <span className="index">{idx + 1}</span>
                            <FormControl
                                name="ip_range_start"
                                placeholder="0.0.0.0"
                                className="api_page_item_input range_start"
                                onChange={(value) => {
                                    range.ip_range_start = value;
                                }}
                                errorMessage={createFormError[idx] && createFormError[idx]['ip_range_start']  ? createFormError[idx]['ip_range_start'] : null}
                            />
                            <span className="separator">to</span>
                            <FormControl
                                name="ip_range_end"
                                placeholder="255.255.255.255"
                                className="api_page_item_input range_end"
                                onChange={(value) => {
                                    range.ip_range_end = value;
                                }}
                                errorMessage={createFormError[idx] && createFormError[idx]['ip_range_end'] ? createFormError[idx]['ip_range_end'] : null}
                            />
                        </FormGroup>
                        {hasError && <Whisper placement="top" trigger="click" speaker={
                            <Tooltip>
                                {
                                    rangeChecker[idx] && rangeChecker[idx].ip_range_start && rangeChecker[idx].ip_range_start.hasError
                                        ? rangeChecker[idx].ip_range_start.errorMessage
                                        : 'Empty range is not available'
                                }
                            </Tooltip>}
                        >
                            <Icon className="range_exclamation" inverse icon="exclamation-triangle"/>
                        </Whisper>}
                    </Form>
                    {rangeRows.length > 1 && <Button
                        disabled={someHasError}
                        onClick={() => {
                            const newList = [...rangeRows];
                            const newCreateFormError = {...createFormError};
                            const newRangeChecker = {...rangeChecker};
                            newList.splice(idx, 1);
                            delete newRangeChecker[idx];
                            delete newCreateFormError[idx];

                            const createFormErrorList = Object.values(newCreateFormError).map((value, index) => {
                                return {[index]: value}
                            });
                            const rangeCheckerList = Object.values(newRangeChecker).map((value, index) => {
                                return {[index]: value}
                            });
                            const nextCreateFormError = Object.assign({}, ...createFormErrorList);
                            const nextRangeChecker = [...rangeCheckerList];

                            onChangeCreateFormError(nextCreateFormError);
                            onCheckRange(nextRangeChecker);
                            onChangeRangeRows(newList);
                        }}
                        className="range_delete"
                    >
                        <Icon icon="trash"/>
                    </Button>}
                </List.Item>);
            })}
            {(() => {
                let newRangeHasError = (!newRangeValue.ip_range_start || !newRangeValue.ip_range_end)
                    || Object.keys(newRangeChecker).some(key => newRangeChecker[key].hasError);

                return (<StyledNewRangeListItem
                    changed={showCreateButton}
                    className="api_optional_list_item"
                >
                    <Form
                        model={rangeSchemaCreate}
                        formValue={newRangeValue}
                        onChange={(formValue) => {
                            if (formValue.ip_range_start && formValue.ip_range_end
                                && (formValue.ip_range_start === "0.0.0.0"
                                    && formValue.ip_range_end === "255.255.255.255" && !valueSuccess)
                            ) {
                                onShowHelpModal(true);
                                onChangeValueSuccess(true);
                                onChangeNewRange(formValue);
                            } else {
                                const check = rangeSchemaCreate.check(formValue);
                                onCheckNewRange(check);
                                onChangeNewRange(formValue);
                                onChangeValueSuccess(false)
                            }
                        }}
                    >
                        <FormGroup className="optional_form_group">
                            <span className="index">{rangeRows.length + 1}</span>
                            <FormControl
                                name="ip_range_start"
                                placeholder="0.0.0.0"
                                errorMessage={newRangeChecker.hasOwnProperty("ip_range_start")
                                    && newRangeChecker.ip_range_start.hasError ? newRangeChecker.ip_range_start.errorMessage : ""}
                                value={newRangeValue.hasOwnProperty("ip_range_start") ? newRangeValue.ip_range_start : ""}
                                className="api_page_item_input range_start"
                            />
                            <span className="separator">to</span>
                            <FormControl
                                name="ip_range_end"
                                placeholder="255.255.255.255"
                                errorMessage={newRangeChecker.hasOwnProperty("ip_range_end")
                                    && newRangeChecker.ip_range_end.hasError ? newRangeChecker.ip_range_end.errorMessage : ""}
                                value={newRangeValue.hasOwnProperty("ip_range_end") ? newRangeValue.ip_range_end : ""}
                                className="api_page_item_input range_end"
                            />
                        </FormGroup>
                    </Form>
                    <Button
                        appearance="primary"
                        className="api_page_add_new_button"
                        onClick={() => {
                            const newList = [...rangeRows];
                            newList.push(newRangeValue);
                            onChangeRangeRows(newList);
                            onChangeNewRange({ip_range_start: "", ip_range_end: ""})
                        }}
                        disabled={newRangeHasError}
                    >
                        <Icon icon="plus"/>
                    </Button>
                </StyledNewRangeListItem>);
            })()}
            {!someHasError && (
                (newActiveValue !== (initItemData.hasOwnProperty("active") && initItemData.active)) ||
                !compareTwoArrays(rangeRows, (initItemData.hasOwnProperty("access_list") && initItemData.access_list)))
            && <List.Item className="api_optional_list_item save_button">
                <Button
                    appearance="primary"
                    className="api_page_add_new_button"
                    onClick={() => {
                        modifyApiKey({access_list: rangeRows, active: newActiveValue}, account_id, item.api_key)
                    }}
                >
                    Confirm changes
                </Button>
            </List.Item>}
        </List>
        <Icon className={`app_key_status ${newActiveValue ? "activated" : "deactivated"}`} icon={newActiveValue ? "play" : "pause"}/>
    </>)
};

const StyledApiList = styled(List)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .list-item {
        width: 1200px;
    }
    .api_key {
        width: 275px;
        display: inline-block; 
    }
    .api_key_divider {
        height: 24px;
    }
   .app_key_status {
        position: absolute;
        top: 5px;
        right: 5px;
   }
    .app_key_status.activated {
        font-size: 10px;
    }
    .app_key_status.deactivated {
        font-size: 9px;
    }
    .rs-list-item-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .form_status {
        width: 120px;
    }
    .optional_form_group .rs-form-control-wrapper {
        width: auto;
    }
    .rs-list-item.api_optional_list_item {
        padding-top: 0;
        width: 500px;
        box-shadow: none;
    }
    .rs-list.api_optional {
        width: 520px;
        box-shadow: none;
    }
    .rs-list.api_optional:last-child .api_optional_list_item {
        padding-bottom: 0;
    }
    .rs-list.api_optional:last-child .optional_form_group {
        margin-bottom: 0;
    }
    .rs-list.api_optional .api_optional_list_item.add_new_range {
        padding-bottom: 30px;
    }
    .api_optional_list_item .range_exclamation.rs-icon.rs-icon-exclamation-triangle.rs-icon-inverse {
        display: none;
    }
    .api_optional_list_item .index {
        background: white;
        color: gray;
        border: 1px solid #E5E5EA;
        border-right: 0;
        height: 36px;
        width: 34px;
        position: relative;
        display: inline-block;
        float: left;
        padding-top: 6px;
        padding-left: 13px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .api_optional_list_item .separator {
        background: white;
        color: gray;
        border: 1px solid #E5E5EA;
        border-right: 0;
        border-left: 0;
        height: 36px;
        width: 34px;
        position: relative;
        display: inline-block;
        padding-top: 6px;
        padding-left: 13px;
        top: -1px;
    }
    .api_optional_list_item.save_button {
        padding-bottom: 0 !important;
    }
    .optional_form_group {
        margin-bottom: 0 !important;
    }
    .api_page_add_new_button {

    }
    .api_page_item_input {
        border-radius: 0;
    }
    .api_page_item_input.range_end {
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
    }
    .api_optional_list_item.has_error {
        .api_page_item_input, span.index, span.separator {
            border-color: #ff7f7f;
        }
    }
    .rs-input.api_page_item_input {
        width: 190px;
    }
`;
const StyledNewRangeListItem = styled(List.Item)`
    padding-bottom: ${props => 
        !props.changed ? "27px !important" : "13px !important"
    }
`;

const StyledButtonPanel = styled.div`
    display: inline-block;
    width: 180px;
    
    .rs-btn {
        margin-left: 5px;
    }
`;
