import React, {useEffect} from "react";
import {Spacer} from "../../../components/base/Spacer";
import NotesForm from "./NotesForm";
import NotesList from "./NotesList";
import {commentTypes} from "../../../const";

export default ({
    notesList,
    notesLoading,
    isFetched,
    accountId,
    getAccountNoteList,
    removeAccountNote,
    createAccountNote,
}) => {

    useEffect( () => {
        if (accountId) {
            getAccountNoteList(accountId);
        }
    }, [accountId] );

    return (
        <>
            <Spacer />
            <NotesForm
                {...{
                    commentTypes,
                    notesList,
                    notesLoading,
                    accountId,
                    getAccountNoteList,
                    createAccountNote,
                }}
            />
            <Spacer size={40}/>
            <NotesList
                {...{
                    commentTypes,
                    notesLoading,
                    notesList,
                    accountId,
                    isFetched,
                    getAccountNoteList,
                    removeAccountNote
                }}
            />
            <Spacer size={40}/>
        </>
    )
};