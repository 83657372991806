import React from 'react';
import {Dropdown, FlexboxGrid, Icon, Nav} from 'rsuite';
import styled from "styled-components";
import { SP_OTP_TYPE } from 'const';
import BaseNav from "../../../../../components/base/BaseNav";


export default ({
    activeTab,
    onSelect,
    spAuth,
    spOtpType,
    isMobile = false
}) => {

    const tabItems = [
        {id: "numberForm", eventKey: "numberForm", title: "Number allocation"},
        {id: "massForm", eventKey: "massForm", title: "Mass allocation"},
        {id: "listForm", eventKey: "listForm", title: "List allocation"},
        {id: "templateListForm", eventKey: "templateListForm", title: "Template list allocation"},
        {id: "sequenceForm", eventKey: "sequenceForm", title: "Sequence allocation"},
        ...[spOtpType === SP_OTP_TYPE ?
            {id: "googleOTP", eventKey: "googleOTP", title: "Google OTP"}
            : null
        ],
    ].filter(item => !!item);

    const dropdownTitle = (tabItems.find(item => item.eventKey === activeTab) || {}).title;

    if (isMobile)
        return (
            <StyledHeader>
                <FlexboxGrid align={"middle"} justify={"space-between"}>
                    <FlexboxGrid.Item>
                        <Title>{dropdownTitle || "Number allocation"}</Title>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Dropdown
                            appearance="ghost"
                            icon={<Icon icon={"bars"}/>}
                            onSelect={onSelect}
                            placement={"bottomEnd"}
                            activeKey={activeTab}
                        >
                            {tabItems.map(item => (
                                <Dropdown.Item key={item.id} eventKey={item.eventKey}>{item.title}</Dropdown.Item>
                            ))}
                        </Dropdown>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </StyledHeader>
        );

    return (
        <BaseNav
            activeKey={activeTab}
            onSelect={onSelect}
            appearance="tabs"
            style={{marginBottom: 20}}
        >
            {tabItems.map(item => (
                <Nav.Item key={item.id} eventKey={item.eventKey}>{item.title}</Nav.Item>
            ))}
        </BaseNav>
    )
};

const StyledHeader = styled.div`
    position: relative;
    margin-bottom: 20px;
`;

export const Title = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #8256C8;
`;
