import React from 'react';
import {Alert, Loader, Placeholder} from 'rsuite';
import CreateForm from '../../NewAccount/CreateForm';
import {api} from '../../../api/loginRoutes';
import {CRUDitems, ID} from '../../../utils';
import {withRouter} from 'react-router-dom';
import {getAccountManagersList} from "../../../actions/account_managers";

const USER_NAME_ALREADY_USED_CODE = 4;

const {Paragraph} = Placeholder;

function AccountForm(account, currency, payments, users) {
    this.name = account.name;
    this.account_manager_id = account.account_manager && account.account_manager.id;
    this.call_duration_max = account.call_duration_max;
    const cur = currency.filter(cur => cur.name === account.currency);
    this.cur_key = cur[0] ? cur[0].cur_key : null;
    const paymentsTerms = payments.filter(pt => pt.name === account.payment_terms)[0] || {};
    this.pt_key = paymentsTerms.pt_key;
    this.contacts = (account.account_contact_list || []).map(
        contact => ({...contact, key: ID()})
    );
    this.users = users.map(
        user => ({
            ...user,
            login_disabled: true,
            email_disabled: true,
            key: ID()
        })
    );

    account.permission_list = Array.isArray(account.permission_list) ? account.permission_list : [];

    this.allocate_number = account.permission_list.includes('allocate_number');
    this.allocate_pattern = account.permission_list.includes('allocate_pattern');
    this.google_otp_allocation = account.permission_list.includes('google_otp_allocation');
    this.google_otp_autorevoke = account.permission_list.includes('google_otp_autorevoke');
    this.trunk_notificaton = account.trunk_notificaton;
}

const TabGeneral = ({
    disabled = false, currency_list = [], payment_terms_list = [], account_manager_list = [], account,
    accountUsers, account_user_modify_method, account_user_remove_method, getAccount, getAccountUsers,
    client_role_list, admin_role_list, getAccountManagersList, ...props
}) => {
    const [loading, setLoading] = React.useState(false);

    if (props.loading)
        return (
            <>
                <Loader backdrop content="loading..." vertical/>
                <Paragraph rows={5}/>
            </>
        );

    const onSubmit = async (data, account_manager_id, users, contacts) => {
        const _users = JSON.parse(JSON.stringify(users)).map(
            user => ({
                ...user,
                login_disabled: undefined,
                email_disabled: undefined,
                key: undefined
            })
        );
        const [newAndChangesUsers, deletedUsers] = CRUDitems(accountUsers, _users);

        const _contacts = JSON.parse(JSON.stringify(contacts)).map(
            contact => ({
                ...contact,
                key: undefined
            })
        );
        const [newAndChangesContacts, deletedContacts] = CRUDitems(account.account_contact_list || [], _contacts);

        try {
            setLoading(true);

            const result = await api('account:modify', {
                    ...data,
                    cur_key: undefined,
                    target: {
                        account_id: account.id,
                        account_manager_id
                    }
                },
                true);
            console.log(result);
            if (result && result.account) {

                const createMethod = account.is_test
                    ? 'account_user:create_in_test_account'
                    : 'account_user:create';

                const modifyMethod = account.is_test
                    ? 'account_user:modify_in_test_account'
                    : account_user_modify_method;

                const removeMethod = account.is_test
                    ? 'account_user:remove_in_test_account'
                    : account_user_remove_method;

                const results =
                    await Promise.all([
                            ...newAndChangesUsers.map(
                                (user) =>
                                    user.id
                                        ?
                                        api(modifyMethod, {
                                            name: user.name,
                                            password: user.password,
                                            target: {
                                                // account_id: account.id, Женя сказал убрать
                                                account_user_id: user.id
                                            },
                                            role_list: user.role_list
                                        }, true)
                                        :
                                        api(createMethod, {...user, target: {account_id: account.id}}) // not manual Error
                            ),
                            ...deletedUsers.map(
                                user => api(removeMethod, {
                                    target: {
                                        account_id: account.id,
                                        account_user_id: user.id
                                    }
                                }, true)
                            )],
                        ...newAndChangesContacts.map(
                            contact => contact.id
                                ?
                                api('account_contact:modify', {
                                    ...contact,
                                    target: {account_contact_id: contact.id}
                                }, true)
                                :
                                api('account_contact:create', {
                                    ...contact,
                                    target: {account_id: account.id}
                                }, true)
                        ),
                        ...deletedContacts.map(
                            contact => api('account_contact:remove', {target: {account_contact_id: contact.id}}, true)
                        )
                    );

                if (!results.map(r => JSON.stringify(r)).includes('{}')) {
                    Alert.success('Saved');
                    
                    getAccount(account.id, true, true);
                    getAccountUsers();
                    props.history.replace('/reload');
                    setTimeout((location, history) => {
                            history.replace({
                                ...location,
                                search: '?tab=general'
                            });
                        },
                        500,
                        props.location, props.history);

                }


                setLoading(false);
            } else {
                if (result.error.code === USER_NAME_ALREADY_USED_CODE) {
                    Alert.error('Account name already exists, please change to another');
                }
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const onCancel = () => {
        props.history.replace('/reload');
        setTimeout((location, history) => {
                history.replace({
                    ...location,
                    search: '?tab=trunks'
                });
            },
            0,
            props.location, props.history);
    };

    return (
        <CreateForm
            formDefaultValue={new AccountForm(account, currency_list, payment_terms_list, accountUsers)}
            disabledCurrency={true}
            onCancel={onCancel}
            account_id={account.id}
            isTestAccount={account.is_test}
            disabled={disabled || loading}
            active={account.active}
            {...{
                onSubmit,
                currency_list,
                payment_terms_list,
                account_manager_list,
                client_role_list,
                admin_role_list,
                getAccountManagersList
            }}
        />
    );
};

export default withRouter(TabGeneral);