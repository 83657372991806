import {connect} from 'react-redux';
import {
    allocateTrunkNumbersByTemplate,
    allocateTrunkNumbersByNumberList,
    allocateGoogleOTPTrunkNumbers,

    setAllocatedNumbersByTemplate,
    setAllocatedNumbersByNumberList,
    setAllocatedGoogleOTPNumbers,
    setLoadingAllocatedNumbers,
    setUploadingLoading,
    downloadNumbers,
    downloadFilteredNumbers,
    getAllAllocatedNumbers,
    getNumberTrunksList,
    getTrunkNumbers,
    setTrunkNumberTransaction,

} from '../../actions/client/numbers';
import Numbers from './Numbers';


const mapState = ({client_numbers, auth, auth_dialer, references}) => ({
    trunks: client_numbers.numberTrunks,
    numberTrunkLoading: client_numbers.numberTrunkLoading,
    allocatedNumbers: client_numbers.allocatedNumbers,
    allocatedNumbersLoading: client_numbers.allocatedNumbersLoading,
    loadingAllocation: client_numbers.loadingAllocatedNumbers,
    count: client_numbers.count,
    totalNumbersCount: client_numbers.totalNumbersCount,
    page: client_numbers.page,
    per_page: client_numbers.per_page,
    uploadLoading: client_numbers.uploadTrunkLoading,

    allocated_numbers_temp: client_numbers.allocated_numbers_by_template,
    allocated_numbers_num_list: client_numbers.allocated_numbers_by_number_list,
    allocated_numbers_google: client_numbers.allocated_google_otp_numbers,
    trunk_number_transaction: client_numbers.trunk_number_transaction,

    accountDialerState: auth_dialer.dialerInfo,
    account: auth.authInfo,
    permittedMethods: auth.permittedMethods,
    allocation_limit: auth_dialer.dialerInfo
        ? auth_dialer.dialerInfo.allocation_limit
        : references.client_allocation_limit
});

export default connect(mapState,
    {
        getNumberTrunksList,
        getTrunkNumbers,
        getAllAllocatedNumbers,
        downloadNumbers,
        downloadFilteredNumbers,
        allocateTrunkNumbersByNumberList,
        allocateTrunkNumbersByTemplate,
        allocateGoogleOTPTrunkNumbers,
        setAllocatedNumbersByNumberList,
        setAllocatedNumbersByTemplate,
        setAllocatedGoogleOTPNumbers,
        setLoadingAllocatedNumbers,
        setTrunkNumberTransaction,
        setUploadingLoading
    })(Numbers);