import React, {useContext} from 'react';
import {Icon, Table, Tooltip, Whisper} from 'rsuite';
import {useIntl} from "react-intl";
import {renderColumn} from "../../utils/helpers";
import CustomizableTable from '../../components/client/CustomizableTable/CustomizableTable';
import styled from 'styled-components';
import {ButtonRowClient, ButtonToolbar} from "../../components/base/BaseButton";
import m from "../../definedMessages";
import {RtlContext} from "../../App";

const {Column, HeaderCell, Cell} = Table;


export default ({
    data = [], 
    loading, 
    page, 
    count, 
    per_page, 
    savedPerPage, 
    onChangePerPage, 
    onChangePage, 
    onShowGetNumbersModal,
    setShowGoogleOTPModal,
    spAuth,
    isGoogleOtp,
    accountInfo,
    isTest,
    accountDialerState,
}) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    let actionShowFlag = null;

    if (accountInfo.hasOwnProperty('permission_list')) {
        if (accountInfo.permission_list !== null)
            actionShowFlag = accountInfo.permission_list.includes('allocate_number');
        if (accountInfo.permission_list !== null && isGoogleOtp)
            actionShowFlag = accountInfo.permission_list.includes('google_otp_allocation');
    }


    const nonTestColumns = [
        {
            id: 'payout',
            label: formatMessage(m.payout),
            value: (({payment_terms_rate_list}) => {
                if (!payment_terms_rate_list || !payment_terms_rate_list.length)
                    return "—";
                const obj = payment_terms_rate_list.find(value => {
                    return value.payment_terms_name === (accountInfo.hasOwnProperty('payment_terms') ? accountInfo.payment_terms : '');
                });
                if (obj) {
                    return `${Number(obj.rate).toFixed(4)} ${accountInfo.currency_name}`;
                } else {
                    return '—';
                }
            }),
            flexGrow: 2,
            minWidth: 120,
            align: 'center'
        }
    ];


    const columns = [
        {
            id: 'subdest',
            label: formatMessage(m.subdestination),
            value: ({subdestination_name, template}) => (
                    `${subdestination_name ? subdestination_name : ""}${template ? ` (${template})` : ""}`
            ),
            align: 'center',
            flexGrow: 3,
            minWidth: 180,
            spAuth: true
        },
        {
            id: 'prefix',
            label: formatMessage(m.prefix),
            dataKey: 'prefix',
            align: 'center',
            flexGrow: 2,
            minWidth: 120,
            spAuth: false
        },
        {
            id: 'range',
            label: formatMessage(m.rangeName),
            value: (({range_name}) => `${range_name}`),
            flexGrow: 4,
            minWidth: (actionShowFlag ? 210 : 200),
            align: 'center',
            spAuth: false
        },
        {
            id: 'test_num',
            label: formatMessage(m.testNumbers),
            value: (({test_trunk_number_list}) => {
                if (!test_trunk_number_list)
                    return;

                const firstElement = test_trunk_number_list[0];

                return (
                    firstElement
                        ? <>
                            <span>{firstElement}</span>
                            <Whisper
                                placement="auto"
                                speaker={
                                    <Tooltip>{formatMessage(m.allTheTrafficMustBeClearedByTheCarrier)}</Tooltip>
                                }
                            >
                                <StyledTooltipIcon icon='info'/>
                            </Whisper>
                        </>
                        :
                        <span>—</span>
                );
            }),
            flexGrow: 2,
            minWidth: 140,
            align: 'center',
            spAuth: false
        },
        {
            id: 'avail_num',
            label: formatMessage(m.availableNumbers),
            value: ( ({available_numbers}) => (available_numbers ? available_numbers : '')),
            flexGrow: 2,
            minWidth: 130,
            align: 'center',
            spAuth: false
        },
        {
            id: 'min_dur',
            label: formatMessage(m.minDuration),
            value: (() => <>
                <span>25 sec</span>
                <Whisper
                    placement="auto"
                    speaker={
                        <Tooltip>{formatMessage(m.allTheTrafficWillBePaidOnMonthlyBasis, {count: 25})}</Tooltip>
                    }
                >
                    <StyledTooltipIcon icon='info'/>
                </Whisper>
            </>),
            flexGrow: 2,
            minWidth: 120,
            align: 'center',
            spAuth: false
        }
    ].filter(value => {
        return value.spAuth === undefined || value.spAuth === spAuth
    });


    const testColumns = [];

    if (!isTest) {
        for (const column of nonTestColumns) {
            columns.splice(2, 0, column);
        }
    }


    const allTermList = [];

    for (const priceRange of data) {
        if (!priceRange.payment_terms_rate_list)
            continue;

        for (const term of priceRange.payment_terms_rate_list) {
            allTermList.push(term.payment_terms_name);
        }
    }

    const uniqueTermList = allTermList.filter((v, i, a) => a.indexOf(v) === i);
    for (const term of uniqueTermList) {
        testColumns.push(
            {
                id: 'payouts',
                label: `${formatMessage(m.payout)} ${term}`,
                value: (({payment_terms_rate_list}) => {
                    const obj = payment_terms_rate_list.find(value => value.payment_terms_name === term);
                    let currency = payment_terms_rate_list.find(value=> value.rate !== null);
                    if (currency) {
                        currency = currency.currency_name;
                    }
                    if (obj) {
                        if (accountInfo.hasOwnProperty('currency_name')) {
                            return `${Number(obj.rate).toFixed(4)} ${currency ? currency : ''}`;
                        }
                    } else {
                        return '—';
                    }
                }),
                flexGrow: 2,
                minWidth: 140,
                align: 'center'
            });
    }

    if (isTest) {
        for (const column of testColumns) {
            columns.splice(2, 0, column);
        }
    }

    const renderedColumns = columns.map(renderColumn);

    return (
        <>
            <CustomizableTable
                {...{
                    rtl,
                    data,
                    count,
                    page,
                    per_page,
                    loading,
                    savedPerPage,
                    onChangePage,
                    onChangePerPage,
                    renderedColumns
                }}
                actionColumn={() => {

                    if (spAuth && isGoogleOtp && (
                        !accountDialerState || 
                        !accountDialerState.permission_list ||
                        accountDialerState.permission_list && !accountDialerState.permission_list.includes('google_otp_allocation')
                    )) {
                        return null;
                    }

                    return actionShowFlag &&
                        <Column key="table_actions" width={(rtl ? 160 : 130)} align='center'>
                            <HeaderCell>{formatMessage(m.action)}</HeaderCell>
                            <Cell>
                                {
                                    rowData => {

                                        const data = {
                                            range_name: rowData.range_name,
                                            pr_key: rowData.pr_key,
                                            sp_key: rowData.sp_key,
                                            sde_key: rowData.sde_key,
                                            subdestination_name: rowData.subdestination_name,
                                            // available_numbers: (rowData.all_numbers - rowData.test_numbers - rowData.allocated_numbers),
                                            available_numbers: rowData.available_numbers,
                                            template: `${rowData.prefix}${'X'.repeat(rowData.digit)}`
                                        };

                                        return (
                                            <ButtonToolbar>
                                                <ButtonRowClient
                                                    size="sm"
                                                    onClick={() => {
                                                        if (isGoogleOtp) {
                                                            setShowGoogleOTPModal(rowData)
                                                        } else  {
                                                            onShowGetNumbersModal(data)
                                                        }
                                                    }}
                                                >
                                                    <b>{formatMessage(m.getNumbers)}</b>
                                                </ButtonRowClient>
                                            </ButtonToolbar>
                                        );
                                    }
                                }
                            </Cell>
                        </Column>;
                }}
                data={data}
                isChangedColumns={false}
                isPaginated={true}
            />
        </>
    );

};

const StyledTooltipIcon = styled(Icon)`
    margin-left: 5px;

    .rtl & {
        margin-left: 0;
        margin-right: 5px;
    }
`;