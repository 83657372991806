import React from "react";
import {Icon, IconButton, Loader, Tree} from "rsuite";
import styled from "styled-components";


export default ({
    list,
    activeIvr,
    loading,
    handleSelectIvr,
    handleSelectIvrCreate,
}) => {

    return (
        <>
            <StyledTreeWrapper>

                <StyledTree
                    virtualized
                    data={list}
                    valueKey="id"
                    labelKey="name"
                    height={300}
                    value={activeIvr && activeIvr.id}
                    onSelect={(item) => {
                        handleSelectIvr(item.id);
                    }}
                    defaultExpandAll
                />
                <StyledButtonCreateIvr
                    appearance="link"
                    onClick={handleSelectIvrCreate}
                    icon={<Icon icon="plus" />}
                >
                    Create new IVR
                </StyledButtonCreateIvr>
                {loading && <Loader backdrop size="sm" />}
            </StyledTreeWrapper>
        </>
    )
};

const StyledTreeWrapper = styled.div`
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
    
    .rs-picker-none {
        border-top: 2px solid transparent;
        height: 300px;
        width: 100%;
    }
`;

const StyledTree = styled(Tree)`
  
    .rs-tree-node {
        padding: 0 !important;
    }
    
    &&& .rs-tree-node-label-content {
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
    }
    
    .rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
        
        &,
        &:hover {
            font-weight: normal;
        }
    }
`;

const StyledButtonCreateIvr = styled(IconButton)`
`;