import React from "react";
import {Dropdown, Nav} from "rsuite";
import {Link, withRouter} from "react-router-dom";
import styled from "styled-components";
import {useLocation} from "react-router";

export default ({
    data = [],
}) => {

    return (
        <Nav appearance="subtle">
            {data.map(item => {

                const itemContent = <>{item.icon && item.icon}{item.title}</>

                if (item.children) {
                    if (item.children.length) {
                        return <MenuDropdown item={item} />
                    } else {
                        return <NavLink key={item.key}>{itemContent}</NavLink>;
                    }
                }

                return item.onClick
                    ? <NavLink key={item.key} onClick={item.onClick}>{itemContent}</NavLink>
                    : <NavLink key={item.key} to={item.key}>{itemContent}</NavLink>
            })}
        </Nav>
    )
};


const NavLink = withRouter( (props) => (
        <Nav.Item
            componentClass={Link}
            active={props.location.pathname.indexOf(props.to) === 0}
            {...props}
        />
    )
);


const MenuDropdown = ({item}) => {
    const location = useLocation();
    const isSubMenuActive = item.children.find(item => item.key === location.pathname);

    return (
        <StyledDropdown
            key={item.key}
            isActive={!!isSubMenuActive}
            activeKey={location.pathname}
            title={item.title}
        >
            {item.children.map(subItem => (
                <Dropdown.Item
                    active={location.pathname === subItem.key}
                    componentClass={Link}
                    to={subItem.key}
                >
                    {subItem.title}
                </Dropdown.Item>
            ))}
        </StyledDropdown>
    )
};


const StyledDropdown = styled(Dropdown).attrs(props => ({
    className: props.isActive ? 'active' : ''
}))`
    &&&&&& {

        .rs-dropdown-toggle-caret {
            left: 0;
            margin: 0 0 0 5px;
            padding: 0;
            position: inherit;left .3s ease-out,right .3s ease-out
        }

        .rs-dropdown-item-active {
            border-left: 3px solid var(--color-brand1);
        }
        
        .rs-dropdown-toggle {
            background-color: transparent !important;
            padding: 18px 16px;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 50%;
            height: 2px;
            background-color: #fff;
            transition: left .3s ease-out,right .3s ease-out;
        }

        &.active {
            background-color: transparent;
            
            &:before {
                left: 0;
                right: 0;
            }
        }
    }
`;