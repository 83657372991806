import {
    ACCOUNTS as PREFIX,
    VIEW_ACCOUNT,
    SET_VIEW_ACCOUNT,
    SET_TRUNK_ACCOUNT,
    SET_ALLOCATED_ACCOUNT,
    ADD_TRUNK_ACCOUNT,
    SET_TRUNK_ACCOUNT_LOADING,
    SET_ALLOCATED_ACCOUNT_LOADING,
    SET_VIEW_ACCOUNT_AFTER_DELETE,
    SET_USERS_ACCOUNT,
    SET_USERS_ACCOUNT_LOADING,
    SPECIAL_RATES,
    DEFAULT_PER_PAGE_SMALL,
    SET_ACCESS_LIST_FILTERS_LOADING,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING,
    SET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    MODIFY_ACCESS_LIST_NOTIFICATION_SETTINGS, SET_LIMIT_MODIFIED, SET_DEFAULT_FORM_CREATE_ACCOUNT,
    SET_REDIRECT_TAB
} from '../const/'
import store from '../store/index';
import {getAccount} from '../actions';
import {
    SET_ACCESS_LIST_FILTERS,
    SET_EDIT_ERROR,
    SET_EDIT_ERROR_MESSAGE,
    SET_CURRENT_TRUNK_ID, SET_CURRENT_RANGE_NUMBER
} from 'const';
import {
    SET_REALTIME_PAYMENT_STATUS
} from '../const/apiMethods'

const initialState = {
    loading: false,
    accessList: [],
    accessListLoading: false,
    accountView: {},
    accountTrunk:[],
    accountTrunkLoading:false,
    accountAllocated:[],
    accountAllocatedCount: 0,
    accountAllocatedNumbersTotalCount: 0,
    allocatedNumbersPage: 1,
    allocatedNumbersPerPage: +localStorage.getItem('allocatedNumbersPerPage') || DEFAULT_PER_PAGE_SMALL,
    accountAllocatedLoading:false,
    items: [],
    accountUsers: [],
    accountUsersLoading: false,
    allocation_limit: {},
    editError: false,
    editErrorMessage: null,
    ranges: [],
    rangesLoading: false,

    // Access List Filters data (NOT Access lists data! Be carefully)
    accessListFiltersList: [],
    accessListFiltersListLoading: true,
    accessListNotificationSettings: null,

    currentRangeNumber: null,
    currentTrunkId: null,
    modifiedLimit: null,

    currentApiRequestStr: "",
    registeredAccount: {},

    redirectTab: null,
    realtimePaymentStatus: 0
};

const viewAccount = (state, id) => {
    const accountView = state.items.find( acc => acc.id === id);
    if(!accountView)
        setTimeout(() => {
            store.dispatch(getAccount(id, true, true))
        }, 0);
    return {...state, accountView: accountView || {}};
};


const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload}) => {
        
        if (payload.filter && payload.filter.str !== state.currentApiRequestStr) {
            return state;
        }

        return {
            ...state, 
            items: payload.items, 
            loading: false
        }
    },
    ['SET_CURRENT_API_REQUEST_STR_' + PREFIX]: (state, {payload}) => ({...state, currentApiRequestStr: payload}),
    ['SET_LOADING_'+PREFIX]: (state,{payload}) =>({...state, loading: payload}),
    [VIEW_ACCOUNT]: (state, {payload:id}) => viewAccount(state, id),
    [SET_VIEW_ACCOUNT]: (state, {payload: accountView}) => ({
        ...state, accountView,
        allocation_limit: accountView.allocation_limit,
        loading: false
    }),
    [SET_TRUNK_ACCOUNT]: (state,{payload: accountTrunk}) => ({
        ...state, accountTrunk,
        accountTrunkLoading:false
    }),
    [SET_CURRENT_TRUNK_ID]: (state, {payload: id}) => ({
        ...state, currentTrunkId: id
    }),
    [SET_CURRENT_RANGE_NUMBER]: (state, {payload: number}) => ({
       ...state, currentRangeNumber: number
    }),
    [SET_TRUNK_ACCOUNT_LOADING]: (state,{payload: accountTrunkLoading}) => ({...state, accountTrunkLoading}),
    [SET_ALLOCATED_ACCOUNT]: (state, {payload:{items, count, trunk_number_count, page, per_page}}) => ({
        ...state,
        accountAllocated: items,
        accountAllocatedCount: count,
        accountAllocatedNumbersTotalCount: trunk_number_count,
        allocatedNumbersPage: page,
        allocatedNumbersPerPage: per_page || DEFAULT_PER_PAGE_SMALL,
        accountAllocatedLoading:false
    }),
    [SET_ALLOCATED_ACCOUNT_LOADING]: (state, {payload: accountAllocatedLoading}) => ({...state, accountAllocatedLoading}),
    [ADD_TRUNK_ACCOUNT]: (state,{payload: trunk}) => ({
        ...state,
        accountTrunk: [
            ...state.accountTrunk.filter( x => x.id !== trunk.id ),
            trunk
        ]
    }),
    [SET_VIEW_ACCOUNT_AFTER_DELETE]: (state, {payload: id}) => ({
        ...state,
        accountTrunk: state.accountTrunk.filter(trunkObj=>trunkObj.id !== id),
        accountTrunkLoading:false
    }),
    [SET_USERS_ACCOUNT]: (state, {payload: accountUsers}) => ({
        ...state,
        accountUsers,
        accountUsersLoading:false
    }),
    [SET_USERS_ACCOUNT_LOADING]: (state, {payload: accountUsersLoading}) => ({...state, accountUsersLoading}),
    [SPECIAL_RATES]: (state,{payload}) =>({...state,rates:payload,ratesLoading: false}),
    [SET_EDIT_ERROR]: (state, {payload}) => ({...state, editError: payload, loading: false}),
    [SET_EDIT_ERROR_MESSAGE]: (state, {payload}) => ({...state, editErrorMessage: payload, editError: true, loading: false}),
    [SET_ACCESS_LIST_FILTERS]: (state, {payload}) => ({...state, accessList: payload, accessListLoading: false}),
    [SET_ACCESS_LIST_FILTERS_LOADING]: (state, {payload}) => ({...state, accessListLoading: payload}),
    ['SET_LOADING_'+SPECIAL_RATES]: (state,{payload}) =>({...state,ratesLoading  : payload}),

    [SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST]: (state, {payload}) => ({
        ...state,
        accessListFiltersList: payload,
        accessListFiltersListLoading: false
    }),
    [SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING]: (state, {payload}) => ({...state, accessListFiltersListLoading: true}),
    [SET_ACCESS_LIST_NOTIFICATION_SETTINGS]: (state, {payload}) => {
        return(
            {
                ...state,
                accessListNotificationSettings: {...payload}
            }
        )
    },
    [UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS]: (state) => {
        return ({
            ...state,
            accessListNotificationSettings: null
        })
    },
    [SET_LIMIT_MODIFIED]: (state, {payload}) => ({...state, modifiedLimit: payload}),
    [SET_DEFAULT_FORM_CREATE_ACCOUNT]: (state, {payload}) => ({...state, registeredAccount: payload}),
    [SET_REDIRECT_TAB]: (state, {payload}) => ({...state, redirectTab: payload}),
    [SET_REALTIME_PAYMENT_STATUS]: (state, {payload}) => ({...state, realtimePaymentStatus: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};
