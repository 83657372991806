import React from 'react';
import {FormGroup, FormControl, ControlLabel, HelpBlock} from 'rsuite'

export default class  extends React.PureComponent {
    render() {

      const { name, message, label, widthLabel, accepter, error, ...props } = this.props;
      return (
            <FormGroup
                className={error ? 'has-error' : ''}
                style={{
                    marginBottom: 10,
                    marginRight:5,
                    width: '180px'
                }}
            >
                <FormControl
                    style={{width: '100%'}}
                    name={name}
                    accepter={accepter}
                    errorMessage={error}
                    errorPlacement="topRight"
                    {...props}
                />
                <HelpBlock>{error}</HelpBlock>
            </FormGroup>
      );
    }
  }