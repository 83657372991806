import React, {useState, useEffect} from 'react';
import Modal from 'components/Modal';
import Checkbox from 'rsuite/es/Checkbox';
import FlexboxGrid from 'rsuite/es/FlexboxGrid';
import List from 'rsuite/es/List';
import Form from 'rsuite/es/Form';
import FormGroup from 'rsuite/es/FormGroup';
import FormControl from 'rsuite/es/FormControl';
import Button from 'rsuite/es/Button';
import {Schema} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import Tooltip from 'rsuite/es/Tooltip';
import Whisper from 'rsuite/es/Whisper';
import Message from 'rsuite/es/Message';

const {StringType} = Schema.Types;

const rangeSchemaRanges = Schema.Model({
    ip_range_start: StringType()
        .addRule((value, data) => {
            if (Object.keys(data).length && data.ip_range_end && data.ip_range_end.length && value.length) {
                const ip_range_start_values = value.split('.');
                const ip_range_end_values = data.ip_range_end.split('.');
                let sumFirstIp = 0;
                let sumSecondIp = 0;
                for (const [idx, val] of ip_range_start_values.entries()) {
                    let firstIpValue = parseInt(val);
                    let secondIpValue = parseInt(ip_range_end_values[idx]);

                    if (idx === 0) {
                        firstIpValue = firstIpValue * 10000000;
                        secondIpValue = secondIpValue * 10000000;
                    } else if (idx === 1) {
                        firstIpValue = firstIpValue * 10000;
                        secondIpValue = secondIpValue * 10000;

                    } else if (idx === 2) {
                        firstIpValue = firstIpValue * 10;
                        secondIpValue = secondIpValue * 10;
                    }

                    sumFirstIp += sumFirstIp + firstIpValue;
                    sumSecondIp += sumSecondIp + secondIpValue;
                }

                return sumFirstIp <= sumSecondIp;
            }
            return true;
        }, 'Start range is over than end range'),
    ip_range_end: StringType()
});

const rangeSchema = Schema.Model({
    ip_range_start: StringType()
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address'),
    ip_range_end: StringType()
        .pattern(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Please enter correct IP Address')
});

export default ({show, onSuccessMethod, title, onClose, account_id, apiKeyData = {active: false, access_list: [{ip_range_start: "", ip_range_end: ""}], api_key: null} }) => {
    const [rangeRows, onChangeRangeRows] = useState(apiKeyData.access_list);
    const [createModal, onCreateModal] = useState(show);
    const [formActive, onChangeFormActive] = useState(apiKeyData.active);
    const [createFormError, onChangeCreateFormError] = useState({});
    const [rangeChecker, onCheckRange] = useState([...Array(apiKeyData.access_list.length)].map((_) =>{ return {ip_range_start: "", ip_range_end: ""}}));
    const [valueSuccess, onChangeValueSuccess] = useState(false);
    const [helpModal, onShowHelpModal] = useState(false);

    const createFormHasError = Object.keys(createFormError).length;

    console.log('RANGECHECHER', rangeChecker);
    return (
        <Modal
            width={500}
            show={createModal}
            title={title}
            onClose={() => {
                onClose && onClose(false);
                onCreateModal(false);
            }}
            onSuccess={() => {
                onSuccessMethod({active: formActive, access_list: rangeRows}, account_id, apiKeyData.api_key);
                onClose && onClose(false);
                onCreateModal(false);
            }}
            className="api_page_create_modal"
            successText={apiKeyData.api_key ? "Modify" : "Create"}
            extraDisabled={
                createFormHasError
                || rangeRows.find(range => Object.values(range).some(value => value === null || value === ""))
                || Object.values(rangeChecker).some(value => value.ip_range_start && value.ip_range_start.hasError || value.ip_range_end && value.ip_range_end.hasError)}
            footer
        >
            {apiKeyData.api_key && <>
                <span className="api_page_create_modal_form-header">API Key options:</span>
                <Checkbox
                    name="active"
                    className="api_page_create_modal_form-active"
                    onChange={(value, checked) => onChangeFormActive(checked)}
                    defaultChecked={formActive}
                >
                    Active
                </Checkbox>
            </>}
            <span className="api_page_create_modal_form-header access_list">Access List (IP Ranges)</span>
            <FlexboxGrid className="api_page_create_modal_form-range" align="center">
                <Message
                    className="api_page_create_modal_form_range-description"
                    description="IP ranges to access list for API key"
                    showIcon
                />
                <FlexboxGrid.Item className="api_page_create_modal_form_range-wrapper">
                    <List className="api_page_create_modal_form_range_wrapper-list" bordered>
                        {rangeRows.map((range, idx) => {
                            let hasError = null;

                            if (rangeChecker[idx] && Object.keys(rangeChecker[idx]).length) {
                                hasError = rangeChecker[idx].ip_range_end && rangeChecker[idx].ip_range_end.hasError || rangeChecker[idx].ip_range_start && rangeChecker[idx].ip_range_start.hasError;
                            }
                            return (<List.Item className={`api_page_create_modal_form_range_wrapper_list-item ${hasError ? 'has_error' : ''}`}>
                                {helpModal && <Modal
                                    show={show}
                                    title="Allocate numbers"
                                    onClose={() => {
                                        rangeRows[idx] = {ip_range_start: '', ip_range_end: ''};
                                        onShowHelpModal(false)
                                    }}
                                    footer={true}
                                    successText="Confirm"
                                    onSuccess={() => {
                                        onChangeValueSuccess(true);
                                        onShowHelpModal(false)
                                    }}
                                    >
                                    This IP range is insecure. Do you really want to open this?
                                </Modal>}
                                <Form
                                    model={rangeSchema}
                                    className="api_page_create_modal-form"
                                    onCheck={(error) => {
                                        const newCreateFormError = {...createFormError};
                                        const formHasError = Object.keys(error).length;
                                        if (formHasError) {
                                            if (!Object.keys(newCreateFormError).includes(idx)) {
                                                const oldError = newCreateFormError[idx] ? newCreateFormError[idx] : {};
                                                newCreateFormError[idx] = Object.assign(oldError, error);
                                            }
                                        } else {
                                            delete newCreateFormError[idx];
                                        }

                                        onChangeCreateFormError(newCreateFormError)
                                    }}
                                    onChange={(value) => {
                                        if (value.ip_range_start && value.ip_range_end
                                            && value.ip_range_start === "0.0.0.0"
                                            && value.ip_range_end === "255.255.255.255"
                                        ) {
                                            onShowHelpModal(true)
                                        } else {
                                            onChangeValueSuccess(true)
                                        }
                                        if (valueSuccess)  {
                                            const newRangeChecker = {...rangeChecker};

                                            newRangeChecker[idx] = rangeSchemaRanges.check(value);

                                            onCheckRange(newRangeChecker)
                                        }
                                    }}
                                    formValue={{ip_range_start: range.ip_range_start, ip_range_end: range.ip_range_end}}
                                >
                                    <FormGroup className="api_page_create_modal_form_range_wrapper_list_item-group">
                                        <span className="index">{idx + 1}</span>
                                        <FormControl
                                            name="ip_range_start"
                                            placeholder="0.0.0.0"
                                            className="api_page_create_modal_form_range_wrapper_list_item-input range_start"
                                            onChange={(value) => {
                                                range.ip_range_start = value;
                                            }}
                                            errorMessage={createFormError[idx] && createFormError[idx]['ip_range_start']  ? createFormError[idx]['ip_range_start'] : null}
                                        />
                                        <span className="separator">to</span>
                                        <FormControl
                                            name="ip_range_end"
                                            placeholder="255.255.255.255"
                                            className="api_page_create_modal_form_range_wrapper_list_item-input range_end"
                                            onChange={(value) => {
                                                range.ip_range_end = value;
                                            }}
                                            errorMessage={createFormError[idx] && createFormError[idx]['ip_range_end'] ? createFormError[idx]['ip_range_end'] : null}
                                        />
                                    </FormGroup>
                                    {idx > 0 &&
                                        <Icon
                                            onClick={() => {
                                                const newList = [...rangeRows];
                                                const newCreateFormError = {...createFormError};
                                                const newRangeChecker = {...rangeChecker};
                                                newList.splice(idx, 1);
                                                delete newRangeChecker[idx];
                                                delete newCreateFormError[idx];

                                                const createFormErrorList = Object.values(newCreateFormError).map((value, index) => {
                                                    return {[index]: value}// return {[index+1]: value}
                                                });
                                                const rangeCheckerList = Object.values(newRangeChecker).map((value, index) => {
                                                    return {[index]: value}// return {[index+1]: value}
                                                });
                                                const nextCreateFormError = Object.assign({}, ...createFormErrorList);
                                                const nextRangeChecker = Object.assign({}, ...rangeCheckerList);

                                                onChangeCreateFormError(nextCreateFormError);
                                                onCheckRange(nextRangeChecker);
                                                onChangeRangeRows(newList);
                                            }}
                                            className="range_delete"
                                            inverse={hasError}
                                            icon="trash"
                                        />}
                                    {hasError && <Whisper placement="top" trigger="click" speaker={
                                        <Tooltip>
                                            {
                                                rangeChecker[idx] && rangeChecker[idx].ip_range_start && rangeChecker[idx].ip_range_start.hasError
                                                ? rangeChecker[idx].ip_range_start.errorMessage
                                                : 'Empty range is not available'
                                            }
                                        </Tooltip>}
                                    >
                                        <Icon className="range_exclamation" inverse icon="exclamation-triangle"/>
                                    </Whisper>}
                                </Form>
                            </List.Item>);
                        })}
                        <List.Item clasName="api_page_create_modal_form_range_wrapper_list-add">
                            <Button
                                appearance="primary"
                                className="api_page_create_modal_form_range_wrapper_list_add-button"
                                onClick={() => {
                                    const newList = [...rangeRows];
                                    newList.push({ip_range_start: '', ip_range_end: ''});

                                    onChangeRangeRows(newList);
                                }}
                                disabled={createFormHasError}
                            >
                                +
                            </Button>
                        </List.Item>
                    </List>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Modal>
    );
};
