import {connect} from "react-redux";
import {
    getAccounts,
    setDefaultFormCreateAccount,
    onSetRedirectTab
} from '../../actions/accounts';
import {
    getRegistrationList,
    approveRegistration,
    rejectRegistration
} from "../../actions/registration";
import RegistrationRequestsPage from './RegistrationRequestsPage'

const mapState = ({accounts, registration, references}) => ({
    registrationList: registration.registration_list,
    currencyList: references.currency_list,
    countryList: references.country_list,
    accountList: accounts.items
});

export default connect(mapState, {
    getAccounts,
    getRegistrationList,
    approveRegistration,
    rejectRegistration,
    setDefaultFormCreateAccount,
    onSetRedirectTab
})(RegistrationRequestsPage);