const pythonExample = {
    options: {
        code: `
    import requests
    import json
    
    ADDRESS = '{your_url}'
    API_KEY = '{your_api_key}'
    URL = "{address}?api_key={api_key}".format(address=ADDRESS, api_key=API_KEY)
    CSV_URL = "{address}/csv?api_key={api_key}".format(address=ADDRESS, api_key=API_KEY)

    print(URL)
    print(CSV_URL)
        `,
        result: `
            {your_url}?api_key={your_api_key}\n
            {your_url}/csv?api_key={your_api_key}\n
        `
    },
    'live_call:get_list_by_account_user': {
        code: `
    #  Method - live_call:get_list_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
                'method': 'live_call:get_list_by_account_user',
                'params': {},
                'id': None
            })
        
    print(json.dumps(response))
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
            "live_call_list": []
        },
        "id": null,
        "hash": "5H4QAG1V"
    }
        `
    },
    'cdr_full:get_list': {
        code: `
    #  Method - cdr_full:get_list
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'cdr_full:get_list',
            'params': {
                'page': 1,
                'per_page': 1
            },
            'id': None
        })
    
    print(json.dumps(response.json()))
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "cdr_full_list": [
            {
                "ip": "207.180.220.127",
                "port": 5060,
                "range": "Eritrea - Mobile 2917671XXX",
                "a_number": "966570925692",
                "b_number": "2917671063",
                "datetime": "2020-03-26 07:41:15.568",
                "duration": "00:00:02",
                "a_sde_key": 3982,
                "b_sde_key": 2119,
                "sip_cause": 16,
                "cost_dialer": 0.00333,
                "rate_dialer": 0.1,
                "tprefix_dialer": "",
                "a_subdestination_name": "Saudi Arabia - Mobile - Virgin",
                "b_subdestination_name": "Eritrea - Mobile"
            }
        ],
            "cdr_full_count": 608637
    },
        "id": null,
        "hash": "XKAVAHOU"
    }
        `
    },
    'cdr_full:group_get_list': {
        code: `
    #  Method - cdr_full:group_get_list
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'cdr_full:group_get_list',
            'params': {
                'page': 1,
                'per_page': 10
            },
            'id': None
        })
    
    print(json.dumps(response.json()))
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "group_list": [
            {
                "group": null,
                "range": null,
                "b_number": null,
                "sum_duration": 629287.48,
                "sum_cost_dialer": 18554.0797,
                "count_cost_dialer": 573460
            }
        ],
            "group_count": 1
    },
        "id": null,
        "hash": "95FKJUTT"
    }
        `
    },
    'allocation:price_range': {
        code: `
    #  Method - allocation:price_range
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:price_range',
            'params': {
                'target': {
                    'trunk_id': 'PyxfnLdLSn2FXXXXXXXXX'
                },
                'pr_key': 208086,
                'numbers': 1
            },
            'id': None
        })
    
    print(json.dumps(response.json()))
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 5,
            "price_range_number_list": [
            {
                "rate": 0.1,
                "number": "79402214012",
                "status": 0,
                "prn_key": 14413116,
                "trn_name": "Abkhazia - Mobile 79402214XXX",
                "trunk_name": "Hi Class (ATX)/STD_NEW 0.10000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "0LX9H8D9"
    }
        `
    },
    'allocation:number_list_by_account_user': {
        code: `
    #  Method - allocation:number_list_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:number_list_by_account_user',
            'params': {
                'target': {
                    'trunk_id': '6VeukrKKRgXXXXXXXXX'
                },
                'number_list': [
                    '3978563432'
                ]
            },
            'id': None
        })
    
    print(json.dumps(response.json()))
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.1,
                "number": "79402214012",
                "status": 0,
                "prn_key": 14413116,
                "trn_name": "Abkhazia - Mobile 79402214XXX",
                "trunk_name": "Hi Class (ATX)/STD_NEW 0.10000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "0LX9H8D9"
    }
        `
    },
    'allocation:template_by_account_user': {
        code: `
    #  Method - allocation:template_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:template_by_account_user',
            'params': {
                'target': {
                    'trunk_id': 'PyxfnLdLSn2XXXXXXXXX'
                },
                'template': '39453453X',
                'numbers': 1
            },
            'id': None
        })
    
    print(json.dumps(response.json()))
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.001,
                "number": "394534259",
                "status": 0,
                "prn_key": 18024995,
                "trn_name": "Italy - Fixed 394534XXX",
                "trunk_name": "Hi Class (ATX)/STD_NEW 0.00100 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "NOEKBDZU"
    }
        `
    },
    'allocation:subdestination_by_account_user': {
        code: `
    #  Method - allocation:subdestination_by_account_user
    response = requests.post(
        url=URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:subdestination_by_account_user',
            'params': {
                'target': {
                    'trunk_id': 'pZlHKPtgRj2XXXXXXXX'
                },
                'sde_key': 2119,
                'numbers': 1,
            },
            'id': None
        })
    
    print(json.dumps(response.json()))
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.09,
                "number": "2917910050",
                "prn_key": 18025786,
                "trn_name": "Eritrea - Mobile 2917910XXX",
                "trunk_name": "Hi Class (ATX)/test 1 0.09000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "YZ25QAO7"
    }
        `
    },
    'allocation:google_otp_by_account_user': {
        code: `
    # Method - allocation:google_otp_by_account_user
    response = requests.post(
        url=CSV_URL,
        json={
            'jsonrpc': '2.0',
            'method': 'allocation:google_otp_by_account_user',
            'params': {
                'page': 1,
                'per_page': 10
            },
            'id': None
        })
    
    print(response.text)
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.09,
                "number": "2917910050",
                "prn_key": 18025786,
                "trn_name": "Eritrea - Mobile 2917910XXX",
                "trunk_name": "Hi Class (ATX)/test 1 0.09000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "YZ25QAO7"
    }
        `
    },
    'access_list__get_list:account_price': {
        code: `
    # CSV Method - access_list__get_list:account_price
    response = requests.post(
        url=CSV_URL,
        json={
            'jsonrpc': '2.0',
            'method': 'access_list__get_list:account_price',
            'params': {
                'page': 1,
                'per_page': 10
            },
            'id': None
        })
    
    print(response.text)
        `,
        result: `
    Service plan;Access origin;CLI;Access destination;Test number;Rate
    High price;United States - OnNet;1786915XXXX;Cuba - Mobile;5358181170;
    Standard;United States - OnNet;1786915XXXX;Cuba - Mobile;5356192974;
    High price;United States - OnNet;1925240XXXX;Cuba - Mobile;5358012567;
    Standard;United States - OnNet;1925240XXXX;Cuba - Mobile;5356192974;
        `
    }
};

const jsExample = {
    options: {
        code: `
    const address = '{your_url}';
    const apiKey = '{your_api_key}';
    const url = \`\${address}?api_key=\${apiKey}\`;
    const csvUrl = \`\${address}/csv?api_key=\${apiKey}\`;
    
    console.log(url);
    console.log(csvUrl);
        `,
        result: `
            {your_url}?api_key={your_api_key}\n
            {your_url}/csv?api_key={your_api_key}\n
        `
    },
    'live_call:get_list_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'live_call:get_list_by_account_user',
        params: {},
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
            "live_call_list": []
        },
        "id": null,
        "hash": "5H4QAG1V"
    }
        `
    },
    'cdr_full:get_list': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'cdr_full:get_list',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "cdr_full_list": [
            {
                "ip": "207.180.220.127",
                "port": 5060,
                "range": "Eritrea - Mobile 2917671XXX",
                "a_number": "966570925692",
                "b_number": "2917671063",
                "datetime": "2020-03-26 07:41:15.568",
                "duration": "00:00:02",
                "a_sde_key": 3982,
                "b_sde_key": 2119,
                "sip_cause": 16,
                "cost_dialer": 0.00333,
                "rate_dialer": 0.1,
                "tprefix_dialer": "",
                "a_subdestination_name": "Saudi Arabia - Mobile - Virgin",
                "b_subdestination_name": "Eritrea - Mobile"
            }
        ],
            "cdr_full_count": 608637
    },
        "id": null,
        "hash": "XKAVAHOU"
    }
        `
    },
    'cdr_full:group_get_list': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'cdr_full:group_get_list',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "group_list": [
            {
                "group": null,
                "range": null,
                "b_number": null,
                "sum_duration": 629287.48,
                "sum_cost_dialer": 18554.0797,
                "count_cost_dialer": 573460
            }
        ],
            "group_count": 1
    },
        "id": null,
        "hash": "95FKJUTT"
    }
        `
    },
    'allocation:price_range': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:price_range',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 5,
            "price_range_number_list": [
            {
                "rate": 0.1,
                "number": "79402214012",
                "status": 0,
                "prn_key": 14413116,
                "trn_name": "Abkhazia - Mobile 79402214XXX",
                "trunk_name": "Hi Class (ATX)/STD_NEW 0.10000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "0LX9H8D9"
    }
        `
    },
    'allocation:number_list_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:number_list_by_account_user',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.1,
                "number": "79402214012",
                "status": 0,
                "prn_key": 14413116,
                "trn_name": "Abkhazia - Mobile 79402214XXX",
                "trunk_name": "Hi Class (ATX)/STD_NEW 0.10000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "0LX9H8D9"
    }
        `
    },
    'allocation:template_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:template_by_account_user',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.001,
                "number": "394534259",
                "status": 0,
                "prn_key": 18024995,
                "trn_name": "Italy - Fixed 394534XXX",
                "trunk_name": "Hi Class (ATX)/STD_NEW 0.00100 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "NOEKBDZU"
    }
        `
    },
    'allocation:subdestination_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:subdestination_by_account_user',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.09,
                "number": "2917910050",
                "prn_key": 18025786,
                "trn_name": "Eritrea - Mobile 2917910XXX",
                "trunk_name": "Hi Class (ATX)/test 1 0.09000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "YZ25QAO7"
    }
        `
    },
    'allocation:google_otp_by_account_user': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'allocation:google_otp_by_account_user',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    {
        "jsonrpc": "2.0",
        "result": {
        "price_range_numbers": 1,
            "price_range_number_list": [
            {
                "rate": 0.09,
                "number": "2917910050",
                "prn_key": 18025786,
                "trn_name": "Eritrea - Mobile 2917910XXX",
                "trunk_name": "Hi Class (ATX)/test 1 0.09000 USD",
                "account_name": "Hi Class (ATX)",
                "currency_name": "USD",
                "numbers_count": 1
            }
        ]
    },
        "id": null,
        "hash": "YZ25QAO7"
    }
        `
    },
    'access_list__get_list:account_price': {
        code: `
    const xhr = new XMLHttpRequest();
    const params = {
        jsonrpc: '2.0',
        method: 'access_list__get_list:account_price',
        params: {
            page: 1,
            per_page: 1
        },
        id: null
    };
    
    xhr.open('POST', csvUrl, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(params));
    
    console.log(xhr.responseText);
        `,
        result: `
    Service plan;Access origin;CLI;Access destination;Test number;Rate
    High price;United States - OnNet;1786915XXXX;Cuba - Mobile;5358181170;
    Standard;United States - OnNet;1786915XXXX;Cuba - Mobile;5356192974;
    High price;United States - OnNet;1925240XXXX;Cuba - Mobile;5358012567;
    Standard;United States - OnNet;1925240XXXX;Cuba - Mobile;5356192974;
        `
    }
};
export {pythonExample, jsExample};