import React, {useEffect, useCallback, useMemo} from 'react';
import ModalSendApi from '../../components/Modal/ModalSendApi';
import ApiRequest  from '../../components/ApiRequest';
import {Tag, Tree} from 'rsuite';
import {api} from "api/loginRoutes";
import Loader from "components/Loader/Loader";
import ModalResult from "./ModalResult";
import {PRICE_RANGE_DELETE_API, PRICE_RANGE_DELETE_NOT_ALLOCATED_API} from "../../const/apiMethods";
import {ButtonClose as Delete} from "../../components/base/BaseButton";

export default ({
    getList,
    disabled,
    permissionMethods,
    update
}) => {

    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [rangesToDelete, setRangesToDelete] = React.useState([]);

    const [showModalFaultResult, setShowModalFaultResult] = React.useState(false);
    const [modalFaultResultData, setModalFaultResultData] = React.useState({});

    const apiMethod = useMemo(() => (
        permissionMethods.length && permissionMethods.includes(PRICE_RANGE_DELETE_API)
            ? PRICE_RANGE_DELETE_API
            : PRICE_RANGE_DELETE_NOT_ALLOCATED_API
    ), [permissionMethods]);

    useEffect(() => {
        if (show) {
            const keys = getList().list
                .map((range) => range.pr_key);

            setLoading(true);

            api('price_range__get_list_with_account_trunk_numbers_list', {pr_key_list: keys})
                .then(({price_range_list}) => {

                    const tree = price_range_list.map((range) => {

                        const trunks = range.dialer_allocated_numbers_list.map((dialer) => ({
                            value: dialer.trunk_name,
                            label: <div>
                                <div className="float-left">
                                    Dialer: {dialer.account_name}
                                </div>
                                <div className="float-right">
                                    <Tag color="green">{dialer.numbers} numbers</Tag>
                                </div>
                            </div>
                        }));

                        return {
                            children: trunks.length ? trunks : null,
                            value: range.id,
                            label: <div
                                data-range-id={range.id}
                                onClick={() => {
                                    changeExpandTree(range.id);
                                }}
                            >
                                <div className="float-left">Range: {range.name}</div>
                                <div className="float-right">
                                    <Tag color="orange">{range.dialer_allocated_numbers_list.length} dialers</Tag>
                                    <Tag color="green">{range.all_numbers} numbers</Tag>
                                </div>
                            </div>,
                        }

                    });

                    setRangesToDelete(tree);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [show]);

    const changeExpandTree = (rangeId) => {
        const node =  document.getElementsByClassName('rs-tree-node-children');
        if (node && node.length) {
            for (const treeNode of node) {
                const icon = treeNode.getElementsByClassName('rs-tree-node-expand-icon');
                const iconNode = icon && icon.length && icon[0];
                const nodeByRangeId = treeNode.querySelector(`[data-range-id='${rangeId}']`);
                if (treeNode && nodeByRangeId) {
                    if (treeNode.classList.contains('rs-tree-open')) {
                        treeNode.classList.remove('rs-tree-open');
                        iconNode.classList.remove('rs-tree-node-expanded');
                    } else {
                        treeNode.classList.add('rs-tree-open');
                        iconNode.classList.add('rs-tree-node-expanded');
                    }
                }
            }
        }
    };

    const onFetchResult = useCallback( (result) => {
        setShow(false);
        setModalFaultResultData(result);
        setShowModalFaultResult(true);
    }, [] );

    return (
        <>
            <Delete
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                - Delete ranges
            </Delete>

            {show &&

                <ModalSendApi
                    title="Delete Range"
                    successText="Confirm"
                    onClose={() => setShow(false)}
                    update={update}
                >

                    <ApiRequest
                        method={apiMethod}
                        checkResultKey = 'ranges'
                        onFaultResult={(result) => {
                            onFetchResult(result);
                            update();
                        }}
                        update={onFetchResult}
                        data={{pr_key_list: getList().list.map(x => x.pr_key)}}
                        // style={{maxHeight: '200px'}}
                    >
                        <Loader show={loading} isLocal/>
                        <h6 className="text-center"> Selected range allocated to following dialers</h6>
                        <Tree data={rangesToDelete} />
                        <h6 className="text-center">All numbers will be revoked.</h6>
                    </ApiRequest>

                </ModalSendApi>
            }

            <ModalResult
                show={showModalFaultResult}
                data={modalFaultResultData}
                onClose={() => setShowModalFaultResult(false)}
            />

        </>

    );
}