import {
    ACCESS_LIST_DIALER as PREFIX,
    SET_LOADING_ACCESS_ALLOCATED_NUMBERS_DIALER,
    SET_UPLOAD_LOADING_DIALER
} from '../../const';

import {
    ACCESS_LIST_ACCOUNT_PRICE_DIALER_API,
    ACCESS_LIST_DEFAULT_PRICE_DIALER_API,
    ACCESS_LIST_OTP_ACCOUNT_PRICE_DIALER_API,
    ACCESS_LIST_OTP_DEFAULT_PRICE_DIALER_API,
    ALLOCATION_SUBDESTINATION_DIALER_API,
} from "../../const/apiMethods";

import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {createUrl} from '../../utils/helpers';

const setAccessList = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);


export const setAccessLoadingAllocatedNumbers = _setValue(SET_LOADING_ACCESS_ALLOCATED_NUMBERS_DIALER);


export const setLoadingData = (load) => (dispatch) => {
    dispatch( setLoading(load) );
};


export const getAccessList = (per_page = 12, filter = {sp_key: 1}, page = 1, isAuth = false, cancelToken) => (dispatch) => {
    dispatch( setLoading(true) );
    
    const isTest = localStorage.getItem('userInfo') 
        ? JSON.parse(localStorage.getItem('userInfo')).session.account.is_test 
        : false;

    const request = isAuth 
        ? !isTest ? ACCESS_LIST_OTP_ACCOUNT_PRICE_DIALER_API : ACCESS_LIST_OTP_DEFAULT_PRICE_DIALER_API
        : !isTest ? ACCESS_LIST_ACCOUNT_PRICE_DIALER_API : ACCESS_LIST_DEFAULT_PRICE_DIALER_API;

    const params = {filter, page, per_page, cancelToken};
    if (isAuth) {
        params.sort1 = 'time';
        params.sort1_desc = true;
    }
        
    api(request, params).then((response) => {
        if (response !== undefined) {
            dispatch( setAccessList({
                count: response.access_list_count || 0,
                access_list_list: response.access_list_list || [],
                page,
                per_page
            }) );
        }
    });
};


export const downloadAccesses = (account_id = '', filter = {}, isAuth = false) => async (dispatch) => {
    
    const isTest = localStorage.getItem('userInfo') 
        ? JSON.parse(localStorage.getItem('userInfo')).session.account.is_test 
        : false;

        const request = isAuth 
            ? !isTest ? ACCESS_LIST_OTP_ACCOUNT_PRICE_DIALER_API : ACCESS_LIST_OTP_DEFAULT_PRICE_DIALER_API
            : !isTest ? ACCESS_LIST_ACCOUNT_PRICE_DIALER_API : ACCESS_LIST_DEFAULT_PRICE_DIALER_API;
    
    dispatch( setUploadLoading(true) );
    await getFileResponse(request, {
        filter,
        csv_result_key: 1
    })
        .then((response) => createUrl(response, 'accesses_list.csv'))
        .catch( e => e );
    dispatch( setUploadLoading(false) );
};


export const allocateByAccess = (trunk_id, sde_key, numbers, random_order) => async (dispatch) => {
    try {
        dispatch( setAccessLoadingAllocatedNumbers(true) );
        const response = await api(ALLOCATION_SUBDESTINATION_DIALER_API, {
            target: {trunk_id}, 
            random_number: random_order, 
            sde_key, numbers
        });

        dispatch( setAccessLoadingAllocatedNumbers(false) );

        if (response.response && response.response.data.error) {
            return response.response.data.error;
        }
        return response;
    } catch {
        dispatch( setAccessLoadingAllocatedNumbers(false) );
    }
};