import React, {useRef} from "react";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce} from "lodash";
import Checkbox from 'rsuite/es/Checkbox'



export default ({
    filter,
    onChangeFilter
}) => {
    return (
        <>
            <FlexGrid align="middle">
                <FlexGridItem>
                    <Checkbox
                        name="processed"
                        onChange={(_, checked) => {onChangeFilter({processed: checked})}}
                        defaultChecked={filter.processed}
                    >
                        Show processed
                    </Checkbox>
                </FlexGridItem>
            </FlexGrid>
        </>
    )
};