import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {BLOCKED_DESTINATIONS_SET_LIST, BLOCKED_DESTINATIONS_SET_LOADING} from './actionTypes';
import {
    BLOCKED_DESTINATIONS_GET_LIST_API, BLOCKED_DESTINATIONS_CREATE_API, BLOCKED_DESTINATIONS_MODIFY_API,
    BLOCKED_DESTINATIONS_REMOVE_API, BLOCKED_DESTINATIONS_MODIFY_LIST_API} from '../const/apiMethods';

const setLoading = createAction(BLOCKED_DESTINATIONS_SET_LOADING);
const setBlockedDestinationsList = createAction(BLOCKED_DESTINATIONS_SET_LIST);

export const getBlockedDestinationsList = (filter) => async (dispatch) => {
    dispatch(setLoading(true));

    return await api(BLOCKED_DESTINATIONS_GET_LIST_API, {filter})
        .then((response) => {
            dispatch(setBlockedDestinationsList({...response}));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const onAddBlockDestinationsItem = (data) => async (dispatch) => {
    return await api(BLOCKED_DESTINATIONS_CREATE_API, {...data})
};

export const onEditBlockDestinationsItem = (ab_key, data) => async (dispatch) => {
    return await api(BLOCKED_DESTINATIONS_MODIFY_API, {target: {ab_key}, ...data})
};
export const onEditBlockDestinationsItemList = (ab_key_list, data) => async (dispatch) => {
    return await api(BLOCKED_DESTINATIONS_MODIFY_LIST_API, {ab_key_list, ...data})
};
export const onRemoveBlockDestinationsItem = (ab_key) => async (dispatch) => {
    return await api(BLOCKED_DESTINATIONS_REMOVE_API, {target: {ab_key}})
};