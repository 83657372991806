import {
    ACCOUNT_MANAGERS_SET_LOADING,
    ACCOUNT_MANAGERS_LIST,
    ACCOUNT_MANAGERS_USER_LIST,
    ACCOUNT_MANAGERS_REFERENCES,
    ACCOUNT_MANAGERS_BIL,
} from '../const';

const initialState = {
    list: [],
    loading: true,
    userList: [],
    teamLeadList: [],
    billingList: [],
};

const handlers = {
    [ACCOUNT_MANAGERS_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [ACCOUNT_MANAGERS_LIST]: (state, {payload: {list}}) => ({
        ...state,
        list: list,
        loading: false,
    }),
    [ACCOUNT_MANAGERS_USER_LIST]: (state, {payload: {userList}}) => ({
        ...state,
        userList: userList,
    }),
    [ACCOUNT_MANAGERS_REFERENCES]: (state, {payload: {userList, teamLeadList}}) => ({
        ...state,
        userList: userList,
        teamLeadList: teamLeadList,
    }),
    [ACCOUNT_MANAGERS_BIL]: (state, {payload: {list}}) => ({
        ...state,
        billingList: list,
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}