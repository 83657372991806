import React from 'react';
import { Form, FormGroup,SelectPicker, Input, InputGroup, Icon, Schema} from 'rsuite';
import CustomField from '../../components/Form/CustomField';
import InputSearch from "../../components/Form/InputSearch";
import FormHOC from '../../hoc/FilltersForm';
import Checkbox from '../../hoc/Checkbox';
import ServicePlans from "../../components/Filters/ServicePlans";
import references from "../../reducers/references";
import {connect} from "react-redux";
const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});

const RangesFilters = ({onChange, worldzone_list, subdestination_list, destination_list, defaultValues={}, defaultSPKey}) =>  {
    const [sde_key, setSde] = React.useState(null);

    React.useEffect(() => {
        if (defaultValues.sde_key)
            setSde(defaultValues.sde_key)
    }, [defaultValues]);

    return (
            <>
                <Form layout="inline" 
                    style={{marginBottom: '-25px'}}
                    onChange={onChange}
                    formDefaultValue={defaultValues}
                    model={filtersModel}
                    >
                    <CustomField
                        name="str"
                        accepter={InputSearch}
                        errorPlacement="topRight"
                        placeholder="Range name/Numbers search"
                        style={{width:220}}
                    />
                    <CustomField
                        accepter={SelectPicker}
                        labelKey="name"
                        valueKey="wz_key"
                        data={worldzone_list}
                        placeholder="Worldzone"
                        name="wz_key"
                    />
                     <CustomField
                        accepter={SelectPicker}
                        data={destination_list}
                        labelKey="name"
                        valueKey="de_key"
                        placeholder="Destination"
                        name="de_key"
                    /> 
                    <CustomField
                        accepter={SelectPicker}
                        data={subdestination_list}
                        labelKey="name"
                        valueKey="sde_key"
                        onChange = { (sde_key) => setSde(sde_key) }
                        value = {sde_key}
                        placeholder="Subdestination"
                        name="sde_key"
                        classPrefix="allocated"
                    />
                    <Checkbox name="only_with_unallocated_numbers" defaultChecked>Show only available ranges</Checkbox>
                    <Checkbox name="only_without_test_numbers">Show private ranges</Checkbox>
                </Form>
            </>
        )
};

const mapStateToProps = ({references}) => ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapStateToProps)(FormHOC(RangesFilters, filtersModel,300));
