import {SET_UPLOAD_LOADING_DIALER, TRAFFIC_STAT_DIALER as PREFIX} from '../../const';
import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {createUrl} from '../../utils/helpers';
import {TRAFFIC_STAT_LIST_DIALER_API} from "../../const/apiMethods";

const setTestCalls = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);

export const getDialerTrafficStat = (per_page = 12, filterData = {}, page = 1, group = 'range/a_number/b_number') => (dispatch) => {
    dispatch(setLoading(true));
    const filter = {...filterData};
    if (filter.checkbox)
        delete filter.checkbox;
    if (filter.group)
        delete filter.group;

    api(TRAFFIC_STAT_LIST_DIALER_API, {group, filter, page, per_page})
        .then((response) => {
            if (response === undefined) {
                dispatch( setLoading(false) );
                return;
            }
            dispatch(setTestCalls({
                group_list: response.group_list || [],
                group_count: response.group_count || 0,
                group_summaries: response.group_summaries || {},
                page,
                per_page
            }));
        })
        .catch(error => {
            dispatch( setLoading(false) );
        });
};

export const exportDialerReport = (filterData = {}, group = 'range/a_number/b_number') => async (dispatch) => {
    dispatch(setUploadLoading(true));

    const filter = {...filterData};
    if (filter.checkbox)
        delete filter.checkbox;
    if (filter.group)
        delete filter.group;

    await getFileResponse(TRAFFIC_STAT_LIST_DIALER_API, {filter, group}).then((response) => createUrl(response, 'traffic_stats.csv'));
    dispatch(setUploadLoading(false));
};
