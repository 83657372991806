import {connect} from 'react-redux';
import CDR from './CDR';
import {exportDialerCDR, getDialerCDRList} from "../../../actions/client/cdr";

const mapState = ({client_cdr, references}) => ({
    loading: client_cdr.loading,
    page: client_cdr.page,
    per_page: client_cdr.per_page,
    count: client_cdr.count,
    cdr_list: client_cdr.cdr_list,
    summaries: client_cdr.summaries,
    uploadLoading: references.uploadLoadingDialer,
    globalSettings: references.global_setting,
});

export default connect(mapState, {
    getCDRList: getDialerCDRList,
    exportCDR: exportDialerCDR
})(CDR);
