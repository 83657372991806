import React from "react";
import {Table, Form, Schema, SelectPicker, FormControl, Input, InputNumber, Button, Icon} from "rsuite";
import BaseTable from "../../../../../components/base/BaseTable";

// Table components
const {Column, HeaderCell, Cell} = Table;

// Form validation schema model
const {StringType, NumberType} = Schema.Types;
const formModel = Schema.Model({
    a_wz_key: NumberType(),
    a_de_key: NumberType(),
    a_sde_key: NumberType(),
    a_prefix: StringType()
        .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
        .minLength(6, "Min length is 6 characters")
        .maxLength(15, "Max length is 15 characters"),
    b_wz_key: NumberType(),
    b_de_key: NumberType(),
    b_sde_key: NumberType(),
    rate_min: NumberType(),
});


const AccessListFiltersTable = React.memo( (props) => {

    // props
    let {loading} = props;

    // callbacks
    let {onRemoveItem, onEditItem} = props;

    // keeped form groups refs
    let formGroupsRefs = new Map();

    // hooks
    let [tableData, setTableData] = React.useState([]);
    let [currentEditedFilter, setCurrentEditedFilter] = React.useState(null);
    let [editedFiltersKeys, setEditedFiltersKeys] = React.useState([]);
    let [localLoading, setLocalLoading] = React.useState(false);

    const searchedData = ["a_worldzone_name", "a_destination_name", "a_subdestination_name", "a_prefix", "b_worldzone_name", "b_destination_name", "b_subdestination_name"];

    React.useEffect( () => {

        let tableData = props.data.filter( (item) => {

            // return item if filter is empty
            if (!props.filter || !props.filter.str)
                return true;

            let match = props.filter.str.toLowerCase();

            for (let keyName of searchedData) {
                if (!item[keyName])
                    continue;

                let string = item[keyName].toString().toLowerCase();

                if (keyName === "a_prefix" ) {
                    
                    // if "a_prefix" length less then match && match includes "a_prefix"
                    if (match.length > string.length) {

                        if ( match.indexOf(string) === 0 ) {
                            return true;
                        }

                    } else {

                        if (string.indexOf(match) === 0) {
                            return true;
                        }
                    }

                } else {

                    // if string has matches
                    if (string.includes(match)) {
                        return true;
                    }
                }
            }

            return false;
        } );

        // set filtered data
        setTableData(tableData);
        if (currentEditedFilter) {
            let editedFilters = new Set([...editedFiltersKeys, currentEditedFilter]);
            editedFilters.delete(currentEditedFilter);
            setEditedFiltersKeys(Array.from(editedFilters));
            setCurrentEditedFilter(null);
        }
    }, [props.data, props.filter] );


    // a_prefix form ref
    let aPrefixForm;


    // add new form group refs
    const createFormGroupRefs = (alf_key, ref) => {
        if ( !formGroupsRefs.has(alf_key) ) {
            formGroupsRefs.set(alf_key, new Set([ref]))
        } else {
            formGroupsRefs.get(alf_key).add(ref);
        }
    };


    // On Edit submit
    const handleSubmit = (alf_key) => {
        const formsGroup = formGroupsRefs.get(alf_key);

        let data = {};
        for (let form of formsGroup) {
            if (!form)
                continue;

            const formData = form.getFormValue();

            // check "a_prefix" field
            if (formData.a_prefix != undefined) {
                if ( !form.check() ) {
                    return;
                }
            }

            data = Object.assign(data, formData);
        }

        // prepare data for sending
        Object.keys(data).forEach( item => {
            if (item === "rate_min") {
                data[item] = +data[item];
            }
        } );

        if (!data.a_prefix && data.a_prefix !== undefined) {
            delete data.a_prefix
        }

        let promise = onEditItem(props.accountId, alf_key, data);
        promise.then(r => {
            if (!r)
                return;

            setCurrentEditedFilter(alf_key);
            props.update();
        })
    };


    return (
        <div>
            <BaseTable
                virtualized
                className="tableFilters"
                data={[...tableData]}
                loading={loading || localLoading}
                headerHeight={47}
                rowHeight={46}
                autoHeight
            >
                <Column flexGrow minWidth={130} className="tableFilters_column" align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Origin zone</span>
                    </HeaderCell>
                    <Cell dataKey={"a_worldzone_name"}>
                        {(rowData) => {
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{a_wz_key: rowData["a_wz_key"]}}>
                                        <FormControl name="a_wz_key"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={SelectPicker}
                                                     data={props.worldzoneList}
                                                     placeholder="Origin zone"
                                                     valueKey="wz_key"
                                                     labelKey="name"
                                                     type="text"/>
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["a_worldzone_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Origin country</span>
                    </HeaderCell>
                    <Cell dataKey={"a_destination_name"}>
                        {(rowData) => {
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{a_de_key: rowData["a_de_key"]}}>
                                        <FormControl name="a_de_key"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={SelectPicker}
                                                     data={props.destinationList}
                                                     placeholder="Origin country"
                                                     valueKey="de_key"
                                                     labelKey="name"
                                                     type="text" />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["a_destination_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Origin operator</span>
                    </HeaderCell>
                    <Cell dataKey={"a_subdestination_name"}>
                        {(rowData) => {
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{a_sde_key: rowData["a_sde_key"]}}>
                                        <FormControl name="a_sde_key"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={SelectPicker}
                                                     data={props.subdestinationList}
                                                     placeholder="Origin operator"
                                                     valueKey="sde_key"
                                                     labelKey="name"
                                                     type="text"/>
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["a_subdestination_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Origin Prefix</span>
                    </HeaderCell>
                    <Cell dataKey={"a_prefix"}>
                        {(rowData) => {
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{a_prefix: rowData["a_prefix"]}}>
                                        <FormControl name="a_prefix"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={Input}
                                                     placeholder="Origin Prefix"
                                                     type="text"/>
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["a_prefix"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Desctination zone</span>
                    </HeaderCell>
                    <Cell dataKey={"b_worldzone_name"}>
                        {(rowData) => {
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{b_wz_key: rowData["b_wz_key"]}}>
                                        <FormControl name="b_wz_key"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={SelectPicker}
                                                     data={props.worldzoneList}
                                                     placeholder="Desctination zone"
                                                     valueKey="wz_key"
                                                     labelKey="name"
                                                     type="text"/>
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["b_worldzone_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Destination country</span>
                    </HeaderCell>
                    <Cell dataKey={"b_destination_name"}>
                        {(rowData) => {
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{b_de_key: rowData["b_de_key"]}}>
                                        <FormControl name="b_de_key"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={SelectPicker}
                                                     data={props.destinationList}
                                                     placeholder="Destination country"
                                                     valueKey="de_key"
                                                     labelKey="name"/>
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["b_destination_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Destination operator</span>
                    </HeaderCell>
                    <Cell dataKey={"b_subdestination_name"}>
                        {(rowData) => {
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{b_sde_key: rowData["b_sde_key"]}}>
                                        <FormControl name="b_sde_key"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={SelectPicker}
                                                     data={props.subdestinationList}
                                                     placeholder="Destination operator"
                                                     valueKey="sde_key"
                                                     labelKey="name"
                                                     type="text"/>
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["b_subdestination_name"]}</span>
                            )
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Minimal payout</span>
                    </HeaderCell>
                    <Cell dataKey={"rate_min"}>
                        {(rowData) => {
                            let minRate = rowData["rate_min"];
                            if ( editedFiltersKeys.includes(rowData["alf_key"]) ) {
                                return (
                                    <Form model={formModel}
                                          ref={(ref) => createFormGroupRefs(rowData["alf_key"], ref)} formDefaultValue={{rate_min: rowData["rate_min"]}}>
                                        <FormControl name="rate_min"
                                                     className="tableFilters_field"
                                                     errorPlacement="topEnd"
                                                     accepter={InputNumber}
                                                     placeholder="Minimal payout"
                                                     step={0.1}
                                                     min={0}/>
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{minRate === 0 ? '0.0' : minRate}</span>
                            )
                        }}
                    </Cell>
                </Column>

                <Column width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="tableFilters_buttons">
                                {!editedFiltersKeys.includes(rowData["alf_key"])
                                    ? <Button size="sm" color="lightblue" onClick={() => {
                                        let editedFilters = new Set([...editedFiltersKeys, rowData["alf_key"]]);
                                        setEditedFiltersKeys(Array.from(editedFilters));
                                    }}>
                                        <Icon icon="edit2"/>
                                    </Button>
                                    : <>
                                        <Button size="sm" color="green" onClick={() => {
                                            handleSubmit(rowData["alf_key"]);
                                        }}>
                                            <Icon icon="check-circle"/>
                                        </Button>

                                        <Button size="sm" color="red" onClick={() => {
                                            let editedFilters = new Set([...editedFiltersKeys, rowData["alf_key"]]);
                                            editedFilters.delete(rowData["alf_key"]);
                                            setEditedFiltersKeys(Array.from(editedFilters));
                                        }}>
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                }
                                <Button size="sm" color="red" onClick={() => onRemoveItem(rowData)}>
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        )}
                    </Cell>
                </Column>

            </BaseTable>
        </div>
    )
} );

export default AccessListFiltersTable;