import React from 'react';
import {Button, Loader, ButtonToolbar} from 'rsuite';
import {BaseModal as Modal} from "../../components/base/BaseModal";

export default ({onConfirm, onCancel, loading, size, show}) => {
    return (
        <Modal size={size} show={show} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>Stop call</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <Loader size="md" />
                    </div>    
                ) : (
                    <p>Do you really want to stop this call?</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <ButtonToolbar>
                    <Button onClick={onConfirm} disabled={loading} appearance="primary">
                        Yes
                    </Button>
                    <Button onClick={onCancel} disabled={loading} appearance="subtle">
                        No
                    </Button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    )
}