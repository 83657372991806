import React, {useEffect, useState} from 'react';
import {
    ControlLabel,
    Divider,
    Form,
    FormGroup,
    Schema,
    Tooltip,
    Whisper,
} from 'rsuite';
import {useIntl} from "react-intl";
import {
    ALLOCATION_TEMPLATE_DIALER_API,
    ALLOCATION_NUMBER_LIST_DIALER_API
} from "../../../const/apiMethods";
import {useWindowWidth} from "../../../hooks";
import FormWhisper from './FormWhisper';
import '../colors.css';
import {Spacer} from '../../../components/base/Spacer';
import TrunksList from '../../../components/TrunksList/TrunksList';
import * as S from './styled';
import m from "../../../definedMessages";
import {BaseCheckboxField, BaseInputField, BaseInputNumberField} from "../../../components/base/BaseForm";

const {StringType, NumberType} = Schema.Types;
const MIN_CHARACTERS = 7;
const MAX_CHARACTERS = 15;


export default ({
    maxWidth = 600,
    show,
    onClose,
    trunks,
    onAllocateNumberList,
    onAllocateNumbersTemplate,
    selectedTrunkId,
    loading,

    leftFormValue = {},
    onChangeLeftFormValue,

    rightFormValue = {},
    onChangeRightFormValue,

    randomNumber = false,
    onChangeRandomNumber,
    allocationLimit,
    ...props
}) => {

    const [formDisableState, onChangeFocusForm] = useState('form_right');
    const [formResponse, onChangeFocusResponseForm] = useState('form_left');

    const [leftFormError, onChangeLeftFormError] = useState({});
    const [rightFormError, onChangeRightFormError] = useState({});
    const [trunkId, onChangeTrunkId] = useState(selectedTrunkId);
    const [allocatedMethodChecked, onChangeAllocatedMethod] = useState(true);
    const [isTableReady, setIsTableReady] = useState(false);

    const resizedWidth = useWindowWidth();

    const MIN_NUMBER = 1;

    
    const MAX_NUMBER = allocationLimit && allocationLimit.template 
        ? allocationLimit.template.once 
        : (allocationLimit && allocationLimit.other && allocationLimit.other.once) || 1000;
    
    const MAX_ROWS = allocationLimit && allocationLimit.number_list 
        ? allocationLimit.number_list.once 
        : (allocationLimit && allocationLimit.other && allocationLimit.other.once) || 1000;

    useEffect(() => {
        if (show && !isTableReady) {
            setTimeout(() => {setIsTableReady(true)}, 1000);
        } else if (!show && isTableReady) {
            setIsTableReady(false)
        }
    }, [show, isTableReady]);

    useEffect(() => {
        onChangeTrunkId(selectedTrunkId);
    }, [selectedTrunkId, show]);

    
    let form_left;
    let form_right;
    const {formatMessage} = useIntl();
    const rtl = false;
    
    
    const formLeftSchema = Schema.Model({
        template: StringType()
            .pattern(/^[1-9](?:\d*[xX]*(\[((\d)|(\d-\d))((,\d-\d)?(,\d)?)*\])?)*$/, formatMessage(m.pleaseEnterValidNumbers))
            .addRule((value) => {
                let replacedValue = value.replace( /\[[\d\,\-]+\]/g, 'X');
                if (replacedValue.length > MAX_CHARACTERS || replacedValue.length < MIN_CHARACTERS) {
                    return false
                }
            }, formatMessage(m.templateShouldBeSize, {minNumber: MIN_CHARACTERS, maxNumber: MAX_CHARACTERS}))
            .isRequired(formatMessage(m.thisFieldIsRequired)),
        numbers: NumberType(formatMessage(m.pleaseEnterValidNumber))
            .min(MIN_NUMBER, formatMessage(m.tooFewNumbers))
            .max(MAX_NUMBER, formatMessage(m.tooMuchNumbers))
            .isRequired(formatMessage(m.thisFieldIsRequired)),
    });

    const formRightSchema = Schema.Model({
        num_list: StringType()
            .addRule((value) => {
                const stringRowList = value.trim().split('\n');
                for (const row of stringRowList) {
                    if (row.length > MAX_CHARACTERS || row.length < MIN_CHARACTERS) {
                        return false;
                    }
                }
                return true;
            }, formatMessage(m.eachRowShouldBeSize, {minNumber: MIN_CHARACTERS, maxNumber: MAX_CHARACTERS}))
            .addRule((value) => {
                const stringRowList = value.trim().split('\n');
                return !(stringRowList.length > MAX_ROWS);
            }, formatMessage(m.numberOfPhoneNumbersExceeds, {number: MAX_ROWS}))
            .pattern(/^(?: *\d+ *(?:\n|$))+$/, formatMessage(m.pleaseEnterValidNumbers))
            .isRequired(formatMessage(m.thisFieldIsRequired))
    });

    const chooseRequest = (param) => {
        return {
            form_left: {
                func: onAllocateNumbersTemplate, 
                form: form_left, 
                model: formLeftSchema
            },
            form_right: {
                func: onAllocateNumberList,
                form: form_right, 
                model: formRightSchema
            }
        }[param];
    };

    const clearForm = () => {
        onChangeRightFormValue({
            num_list: null
        });
        
        onChangeLeftFormValue({
            template: null, numbers: 3
        });

        onChangeRandomNumber(false);
    };


    const checkForm = (form, formSchema, formData, formError) => {
        if (!form.check()) {
            return true;

        } else {
            
            const checker = Object.keys(formSchema.check(formData))
                    .map(i => formSchema.check(formData)[i].hasError)
                    .every(value => value === false) && ((formData.hasOwnProperty('num_list') || formData.hasOwnProperty('template')));

            if (checker) {
                onSubmit();
                return false;
            }
        }
        return true;
    };


    const onCheckFormError = (error, errorChanger) => {
        if (!trunkId) {
            onChangeTrunkId(selectedTrunkId);
        }
        errorChanger(error);
    };

    
    const onSubmit = async () => {

        const chosenRequest = chooseRequest(formResponse);

        const chosenResponseFunction = chosenRequest['func'];
        const chosenResponseDataRef = chosenRequest['form'].getFormValue();
        const randomNumberValue = formDisableState !== "form_left" 
            ? randomNumber 
            : undefined;

        const response = await chosenResponseFunction({
            ...chosenResponseDataRef, 
            random_order: randomNumberValue,
            trunk_id: trunkId, 
        });

        if (response === undefined) {
            return;
        }

        if (response.status === 0) {
            clearForm();
            return;
        }
                
        const trunk = trunks.filter( trunk => trunk.id === trunkId );

        props.setReasonModalProps({
            reasonCode: response.reason_code,
            reasonHash: response.reason_hash,
            isRandom: randomNumber,
            method: formResponse === "form_left"
                ? ALLOCATION_TEMPLATE_DIALER_API
                : ALLOCATION_NUMBER_LIST_DIALER_API,
            trunk: trunk[0],
            template: leftFormValue.template,
            numbersAmount: leftFormValue.numbers,
        });
    };

    const onSubmitFromValue = (ref, schema, formValue, formError) => {
        checkForm(ref, schema, formValue, formError);
    };

    const trunkSelectData = trunks.filter(trunk => !trunk.sp_otp_type && !trunk.closed);


    const TemplateForm = (
        <Form
            formValue={leftFormValue}
            ref={ref => form_left = ref}
            model={formLeftSchema}
            onChange={formValue => {
                onChangeLeftFormValue(formValue);
            }}
            onCheck={formError => {
                onCheckFormError(formError, onChangeLeftFormError);
            }}
        >

            {/* Description */}
            <S.FormTitle disabled={formDisableState === 'form_left'}>
                {formatMessage(m.allocatePerTemplate)}
            </S.FormTitle>


            {/* FormGroup: Template */}
            <FormGroup>

                <S.Label disabled={formDisableState === 'form_left'}>
                    {formatMessage(m.template)}
                </S.Label>

                <S.Field
                    accepter={BaseInputField}
                    name='template'
                    placeholder={'12356789XXXXXX'}
                    disabled={formDisableState === 'form_left'}
                />

            </FormGroup>


            {/* FormGroup: Amount */}
            <FormGroup>
                <S.Label
                    disabled={formDisableState === 'form_left'}
                >
                    {formatMessage(m.amount)}
                </S.Label>

                <S.Field
                    accepter={BaseInputNumberField}
                    name='numbers'
                    disabled={!leftFormValue.template || formDisableState === 'form_left'}
                    placeholder={'3'}
                />

            </FormGroup>

            {/* checkbox: Random Order */}
            <BaseCheckboxField
                disabled={!leftFormValue.template || formDisableState === 'form_left'}
                defaultChecked={randomNumber}
                onChange={(_, checked) => {
                    onChangeRandomNumber(checked)
                }}
            >
                {formatMessage(m.randomOrder)}
            </BaseCheckboxField>

        </Form>
    );


    const ListForm = (
        <Form
            ref={ref => form_right = ref}
            model={formRightSchema}
            onChange={formValue => {
                onChangeRightFormValue(formValue);
            }}
            onCheck={formError => {
                onCheckFormError(formError, onChangeRightFormError);
            }}
            formValue={rightFormValue}
        >
            <FormGroup>
                <S.FormTitle
                    as={ControlLabel}
                    disabled={formDisableState === 'form_right'}
                >
                    {formatMessage(m.putListOfOwnNumbers)}
                </S.FormTitle>
                <Whisper 
                    placement={resizedWidth > maxWidth ? (rtl ? "rightStart" : "leftStart") : 'auto'}
                    trigger="click"
                    speaker={<Tooltip>{formatMessage(m.eachPhoneNumberConditions, {
                            break: str => <br key="break"/>,
                            wrapt: str => <span key="wrapt">&lt;{str}&gt;</span>
                    })}</Tooltip>}
                >
                        
                    <S.Field
                        accepter={BaseInputField}
                        name="num_list"
                        componentClass="textarea"
                        wrap="off"
                        errorPlacement={rtl ? "topStart" : "topEnd"}
                        disabled={formDisableState === 'form_right'}
                    />

                </Whisper>
            </FormGroup>
        </Form>
    );

    return (
        <S.FormModal
            show={show}
            title={formatMessage(m.allocateNumbers)}
            width={maxWidth}
            showFooter={true}
            confirmButtonText={formatMessage(m.submit)}
            loading={loading}
            disabled={trunkId === null}
            onConfirm={() => {
                if (formDisableState === 'form_left') {
                    onSubmitFromValue(form_right, formRightSchema, rightFormValue, rightFormError);
                } else {
                    onSubmitFromValue(form_left, formLeftSchema, leftFormValue, leftFormError);
                }
            }}
            onClose={() => {
                clearForm();
                // onChangeTrunkId was able only when formDisableState === 'form_right'
                onChangeTrunkId(null);
                onClose(false);
            }}
        >

            {/* Select Trunk */}
            <S.FormColumns>
                <S.FormColumn>
                    <TrunksList
                        data={trunkSelectData}
                        defaultValue={selectedTrunkId}
                        cleanable={false}
                        onChange={(value) => {
                            onChangeTrunkId(value);
                        }}
                    />
                </S.FormColumn>
            </S.FormColumns>
            
            <Spacer size={30}/>

            {resizedWidth > maxWidth 
            
                ?

                    // Desktop Form
                    <S.FormColumns>
                        <S.FormColumn>

                            {formDisableState === 'form_left' &&
                                <FormWhisper
                                    onClick={() => {
                                        onChangeAllocatedMethod(true);
                                        onChangeFocusForm('form_right');
                                        onChangeFocusResponseForm('form_left');
                                    }}
                                />
                            }

                            {TemplateForm}

                        </S.FormColumn>
                        <S.FormColumn divider>
                            
                            <S.FormDivider>
                                <span className="divider__text">
                                    {formatMessage(m.or)}
                                </span>
                                <Divider className="divider" vertical/>
                            </S.FormDivider>

                        </S.FormColumn>
                        <S.FormColumn>

                            {formDisableState === 'form_right' &&
                                <FormWhisper
                                    onClick={() => {
                                        onChangeAllocatedMethod(false);
                                        onChangeFocusForm('form_left');
                                        onChangeFocusResponseForm('form_right');
                                    }}
                                />
                            }

                            {ListForm}

                        </S.FormColumn>
                    </S.FormColumns>
                :

                    // Mobile Form

                    <S.FormColumns>
                        <S.FormColumn>

                            <S.FormToggle
                                size="lg"
                                checkedChildren={formatMessage(m.template)}
                                unCheckedChildren={formatMessage(m.numberList)}
                                defaultChecked={allocatedMethodChecked}
                                onChange={value => {
                                    onChangeAllocatedMethod(value);

                                    if (value) {
                                        onChangeFocusForm('form_right');
                                        onChangeFocusResponseForm('form_left');
                                    } else {
                                        onChangeFocusForm('form_left');
                                        onChangeFocusResponseForm('form_right');
                                    }
                                }
                                }
                            />

                            {allocatedMethodChecked
                                ? TemplateForm
                                : ListForm
                            }

                        </S.FormColumn>
                    </S.FormColumns>
            }

        </S.FormModal>
    );
};