import React, {useState} from 'react'
import Modal from '../../../../components/Modal';
import { api } from '../../../../api/loginRoutes';
import TrunkForm from "./TrunkForm";
import {toStartDayUTCTimeNextDay, toStartDayUTCTime} from 'utils';
import {Alert} from "rsuite";
import {useWindowWidth} from "../../../../hooks";

export default ({
    onClose,
    trunksList,
    account_id,
    initialValues,
    update,
    account_name
}) => {

    let form;
    let dialerForm;
    const windowWidth = useWindowWidth();
    const [disabled, setDisabled ] = React.useState(false);

    const [is_default, onChangeDefault] = useState( initialValues.is_default_term_point !== undefined
        ? initialValues.is_default_term_point
        : true
    );

    const onSubmit = async() => {
        if(!form.check()) return;
        if(!is_default && !dialerForm.check()) return;

        setDisabled(true);

        const method = initialValues.id ? 'trunk:modify' : 'trunk:create';

        let _data = form.getFormValue();

        const todayDate = toStartDayUTCTime( new Date() );
        let startDate = _data.start_date 
            ? toStartDayUTCTime( new Date(_data.start_date) ) 
            : null;
        
        const isToday = startDate && (
            todayDate.getFullYear() === startDate.getFullYear() &&
            todayDate.getMonth() === startDate.getMonth() &&
            todayDate.getDate() === startDate.getDate()
        );

        const data = {
            name: _data.name,
            sp_key: _data.sp_key,
            is_default_term_point: _data.is_default_term_point,
            prt_key: _data.prt_key || null,

            start_date: !initialValues.id 
                ? (startDate && !isToday) ? startDate : null
                
                : isToday 
                    ?_data.start_date ? _data.start_date : null
                    : startDate,
            end_date: _data.end_date
                ? toStartDayUTCTimeNextDay( new Date(_data.end_date) ) 
                : null,
            
                active: !!_data.active,
        };

        if (_data.otp_type && _data.otp_type === "google_otp" && data.sp_key === 3) {
            data.option_google_otp = true;
        }

        if(!is_default){
            _data = dialerForm.getFormValue();
            data.prt_key = _data.prt_key;
            data.ip = _data.ip;
            data.port = +_data.port;
            data.tprefix = _data.tprefix;
        }

        if (data.tprefix) {
            for (let trunkData of trunksList) {

                if ( data.sp_key !== trunkData.sp_key) {
                    if ( (data.sp_key === 3 || trunkData.sp_key === 3) && data.tprefix === trunkData.tprefix ) {
                        setDisabled(false);
                        Alert.error(`Techprefix: ${data.tprefix} is not unique '${account_name}' trunk '${trunkData.name}'`, 5000);
                        return;
                    }
                }

            }
        }

        // debugger;

        try {
            await api(method,{
                target:{
                    account_id,
                    trunk_id: _data.id
                },
                ...data,
                sp_key: data.sp_key // _data.id ? undefined : data.sp_key - непонятный костыль
            });

            onClose();
            update();
        } catch (e) {
            console.log('Change trunk error');
        }

        setDisabled(false);
    };

    return (
        <Modal
            {...{onClose}}
            show={true}
            onSuccess={onSubmit}
            disabled ={disabled}
            title="Create/Edit Trunk"
            footer
            successText = "Save"
            width={windowWidth >= 620 ? 620 : windowWidth}
        >
              <TrunkForm
                  disabled = {disabled}
                  is_default = {is_default}
                  updateFormRef  = { ref => form = ref}
                  updateFormRef2 = { ref => dialerForm = ref}
                  onChangeDefault = {onChangeDefault}
                  initialValues ={initialValues}
                  isMobile={windowWidth < 620}
            />
        </Modal>
    )
};