import React from 'react';
import CustomField from '../Form/CustomField';
import InputSearch from '../Form/InputSearch';
import {Schema} from 'rsuite';
const { StringType } = Schema.Types;

export const str = StringType().maxLength(40, 'The maximum is only 40 characters.');

const Search = ({placeholder, ...props}) => (
    <CustomField
        name="str"
        accepter={InputSearch}
        errorPlacement="topRight"
        placeholder={placeholder}
        {...props}
    />
)


export default Search;