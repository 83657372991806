import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import {api, getFileResponse} from '../../../../api/loginRoutes';
import AllocationResult from '../../../../components/Modal/ModalAllocationResult';
import AccountsGetForm from 'components/Filters/AccountsGetForm';
import AllocateForm from "./AllocateForm";
import styled from 'styled-components';
import DialerTrunk from 'components/Filters/DialerTrunk';
import ModalResponseHandler from "components/Modal/ModalResponseHandler";
import {getBlobContent} from "../../../../utils";
import {ButtonPrimary} from "../../../../components/base/BaseButton";

export default ({
    disabled, 
    account_id, 
    sde_key, 
    pr_key, 
    update, 
    getList
}) => {

    const [show, setShow] = useState(false);
    const [_disabled, setDisabled] = useState(false);
    const [dialers, setDialers] = useState([]);
    const [formValue, setFormValue] = useState({});

    const [showResult, setShowResult] = useState(false);
    const [trunkId, setTrunkId] = useState(null);

    const [summaryState, setSummaryState] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [csvContent, setCsvContent] = useState(null);
    const [totalNumbersForced, setTotalNumbersForced] = useState(null);

    const [filteredAccounts, setFilteredAccounts] = useState(null);
    const [filteredAccountsLoading, setFilteredAccountsLoading] = useState(false);

    const [responseHandlerModalShow, setResponseHandlerModalShow] = useState(false);
    const [responseHandlerModalParams, setResponseHandlerModalParams] = useState({});

    const showResponseHandlerModal = (params = {}) => {
        setResponseHandlerModalShow(true);
        setResponseHandlerModalParams(params);
    };
    

    let formRef = null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     if (!_disabled && !rate) {
    //         setDisabled(true);
    //     } else {
    //         if (rate) {
    //             setDisabled(false);
    //         }
    //     }
    // }, [rate]);


    useEffect(() => {
        api('trunk_list_for_accounts').then(({trunk_list}) => {
            setDialers((trunk_list || []).map(x => ({
                ...x,
                trunk_id: x.tr_uuid,
                _name: `${x.acc_name}\\${x.tr_name}\\${x.sp_name}`
            })));
        });
    }, []);


    const onSubmit = async () => {

        if ( !formRef.check() )
            return;

        setDisabled(true);

        const selected = getList();
        const numbersKeysList = selected.list;
        const selectedAll = selected.all;

        const params = {
            target: {trunk_id: trunkId},
            rate: +formValue.custom_rate
        };

        if (selectedAll) {
            params.pr_key = pr_key;
        } else {
            params.prn_key_list = numbersKeysList;
        }

        const result = await api('allocation:price_range_number_list', params);

        if (result) {

            const activeTrunk = dialers.find(item => item.trunk_id === trunkId);

            if (result.reason_code && (!result.trunk_number_transaction || !result.trunk_number_transaction.numbers) ) {
                showResponseHandlerModal({
                    ...params,
                    method: 'allocation:price_range_number_list',
                    reasonCode: result.reason_code,
                    reasonHash: result.hash,
                    trunk: activeTrunk || {}
                });
                setDisabled(false);
                return;
            }

            const {trunk_number_transaction} = result;
            const summaryInfo = {...trunk_number_transaction};
            const transactionId = summaryInfo.id;

            getFileResponse("trunk_number:get_list", {target: {trunk_number_transaction_id: transactionId}}, true)
                .then((response) => {
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    })
                });

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);
            setShowResult(true);

            update();
            setShow(false);
        }
    };

    return (
        <>
            <ButtonPrimary
                disabled={disabled} 
                onClick={() => setShow(true)}
            >
                Allocate
            </ButtonPrimary>

            {show &&
                <Modal
                    show={show}
                    title="Allocate numbers"
                    onClose={() => setShow(false)}
                    footer={true}
                    successText="Confirm"
                    extraDisabled={_disabled || !formValue.custom_rate || !trunkId}
                    onSuccess={() => {
                        onSubmit();
                        setDisabled(false)
                    }}
                >

                    Choice dialer and trunk. Pay your attention - if they are allocated to any dialer they will be revoked
                    from them too.

                    <AccountsGetFormWrapper>
                        <AccountsGetForm
                            setLoading={setFilteredAccountsLoading}
                            setFilteredAccounts={setFilteredAccounts}
                        />
                    </AccountsGetFormWrapper>

                    <DialerTrunk
                        trunkId={trunkId}
                        disabledTrunk={disabled}
                        accounts={filteredAccounts}
                        dialerTrunkList={dialers}

                        onClear={() => setTrunkId(null)}
                        onChange={(trunk) => setTrunkId(trunk.trunk_id)}
                    />

                    <AllocateForm
                        trunkId={trunkId}
                        trunksList={dialers}
                        updateFormRef={ref => formRef = ref}
                        accountId={account_id}
                        sdeKey={sde_key}
                        disabled={disabled || filteredAccountsLoading}
                        accounts={filteredAccounts}
                        formValue={formValue}
                        setFormValue={setFormValue}
                    />

                </Modal>
            }

            <ModalResponseHandler
                show={responseHandlerModalShow}
                onClose={() => setResponseHandlerModalShow(false)}
                onSuccess={() => {}}
                changeRandom={() => {}}
                params={responseHandlerModalParams}
            />
            
            <AllocationResult
                show={showResult}
                accountId={account_id}
                summaryState={summaryState}
                totalNumbersForced={totalNumbersForced}
                allocatedByPrefix={false}
                trunkId={trunkId}
                showFullList={true}
                hideBufferButtons={false}
                csvContent={csvContent}
                transactionId={transactionId}
                onClose={() => {
                    setShowResult(false);

                    if (totalNumbersForced)
                        setTotalNumbersForced(null);

                    if (csvContent)
                        setCsvContent(null);

                }}
                // showFullList={true}
            />
        </>

    );
}


const AccountsGetFormWrapper = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;