import _ from 'lodash';
import {
    SET_AUTH,
    SET_LOADING_INFO,
    SET_USER_LOGIN_INFO,
    SET_MAINTENANCE_MODE,
    SET_ACCOUNT_DIALER_INFO,
    APP_TYPE_DEFAULT,
    SET_USER_LOCALIZATION
} from '../const';
import {
    NEED_SECOND_AUTH,
    SET_PERMISSION,
    SET_PERMISSION_ADD
} from "../actions/actionTypes";
import {SET_INFO_ACCOUNT} from '../const';


const isAuth = !!localStorage.getItem('api_key');
const savedAuthInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
const appTypeSaved = _.get(savedAuthInfo, 'session.site', APP_TYPE_DEFAULT);
const role = _.get(savedAuthInfo, 'session.account_user.role_list[0]', null);

const savedLang = localStorage.getItem('lang');
const savedRtl = localStorage.getItem('rtl') && JSON.parse( localStorage.getItem('rtl') ) ;

const initialState = {
    authInfo: savedAuthInfo, // completed info
    auth:  isAuth, // bool
    loading: false,
    permissions: [],
    permissions_add: [],
    role: role,
    dialerInfo: null,

    secondAuthType: null,
    secondAuthLink: null,
    secondAuthEmail: null,

    maintenanceMode: false,
    appType: appTypeSaved,
    lang: savedLang || 'en',
    rtl: savedRtl || false,
};

const handlers = {
    [SET_AUTH]: (state, {payload, appType}) => ({
        ...state,
        auth: payload,
        appType: appType || APP_TYPE_DEFAULT,
        loading: false,
        secondAuthType: null,
        secondAuthLink: null,
        secondAuthEmail: null,
        permissions: [],
        permissions_add: [],
    }),
    [SET_USER_LOGIN_INFO]: (state, {payload}) => ({...state, authInfo: payload, appType: payload.session.site}),
    [SET_USER_LOCALIZATION]: (state, {payload}) => ({...state, lang: payload.lang, rtl: payload.rtl}),
    [SET_INFO_ACCOUNT]: (state, {payload}) => ({...state, authInfo: payload, loading: false}),
    [SET_ACCOUNT_DIALER_INFO]: (state, {payload}) => ({
        ...state,
        dialerInfo: payload,
        readyForPayment: payload.available_for_payment || 0
    }),
    [SET_LOADING_INFO]: (state, {payload}) => ({...state, loading: payload}),
    [NEED_SECOND_AUTH]: (state, {payload}) => ({...state, ...payload}),
    [SET_PERMISSION]: (state, {payload}) => ({...state, permissions: payload}),
    [SET_PERMISSION_ADD]: (state, {payload}) => ({...state, permissions_add: payload}),
    [SET_MAINTENANCE_MODE]: (state, {payload}) => ({...state, maintenanceMode: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};



