import {
    ACCOUNTS as PREFIX,
    VIEW_ACCOUNT,
    SET_VIEW_ACCOUNT,
    ADD_TRUNK_ACCOUNT,
    SET_ALLOCATED_ACCOUNT,
    SET_TRUNK_ACCOUNT,
    SET_TRUNK_ACCOUNT_LOADING,
    SET_ALLOCATED_ACCOUNT_LOADING,
    SET_USERS_ACCOUNT,
    SET_USERS_ACCOUNT_LOADING,
    SET_VIEW_ACCOUNT_AFTER_DELETE,
    DESC_SORT,
    SET_INFO_ACCOUNT,
    SET_LOADING_INFO,
    SET_EDIT_ERROR,
    SET_EDIT_ERROR_MESSAGE,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST,
    SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING,
    SET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS,
    DEFAULT_AUTH_PATH_ADMIN,
    SET_CURRENT_TRUNK_ID,
    SET_LIMIT_MODIFIED,
    SET_REDIRECT_TAB
} from '../const/'
import {Alert} from 'rsuite';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import { formatDateApiWithoutTime } from '../utils/format';
import {
    ACCOUNT_USER_GET_REALTIME_STATUS,
    ACCOUNT_USER_MODIFY_BY_SELF,
    SET_REALTIME_PAYMENT_STATUS
} from '../const/apiMethods'
import {LOCAL_STORAGE_USER_INFO} from '../const/localStorageKeys';
import {ACCOUNT_CREATE_METHOD} from "const/apiMethods";
import {SET_ACCESS_LIST_FILTERS, SET_ACCESS_LIST_FILTERS_LOADING, SET_CURRENT_RANGE_NUMBER, SET_DEFAULT_FORM_CREATE_ACCOUNT} from 'const';
import history from 'config/history';
import store from "../store/index";



const setAccounts = createAction('SET_ITEMS_'+PREFIX);
const setCurrentApiRequestStr = createAction('SET_CURRENT_API_REQUEST_STR_' + PREFIX);

const setLoading = createAction('SET_LOADING_'+PREFIX);
const viewAccount = createAction(VIEW_ACCOUNT);
const setViewAccount = createAction(SET_VIEW_ACCOUNT);

const setEditError = createAction(SET_EDIT_ERROR);
const setEditErrorMessage = createAction(SET_EDIT_ERROR_MESSAGE);

const setViewAccountTrunk = createAction(SET_TRUNK_ACCOUNT);
const setViewAccountTrunkAfterDelete = createAction(SET_VIEW_ACCOUNT_AFTER_DELETE)

const setViewAccountTrunkLoading = createAction(SET_TRUNK_ACCOUNT_LOADING);
const setViewAccountAllocated = createAction(SET_ALLOCATED_ACCOUNT);
const setViewAccountAllocatedLoading = createAction(SET_ALLOCATED_ACCOUNT_LOADING);
const addViewAccountTrunk = createAction(ADD_TRUNK_ACCOUNT);

const setViewAccessListFiltersLoading = createAction(SET_ACCESS_LIST_FILTERS_LOADING);
const setViewAccessListFilters = createAction(SET_ACCESS_LIST_FILTERS);

const setViewAccountUsers = createAction(SET_USERS_ACCOUNT);
const setViewAccountUsersLoading = createAction(SET_USERS_ACCOUNT_LOADING);

const setInfoAccount = createAction(SET_INFO_ACCOUNT);
const setLoadingInfo = createAction(SET_LOADING_INFO);

const setAccountAccessListFiltersList = createAction(SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST);
const setAccountAccessListFiltersListLoading = createAction(SET_ACCOUNT_ACCESS_LIST_FILTERS_LIST_LOADING);

const setAccessListNotificationSettings = createAction(SET_ACCESS_LIST_NOTIFICATION_SETTINGS);
const unsetAccountAccessListNotificationSettings = createAction(UNSET_ACCESS_LIST_NOTIFICATION_SETTINGS);

const dispatchCurrentTrunkId = createAction(SET_CURRENT_TRUNK_ID);
const dispatchCurrentRangeNumber = createAction(SET_CURRENT_RANGE_NUMBER);

const setModifiedLimit = createAction(SET_LIMIT_MODIFIED);
const setDefaultFormCreateAccountAction = createAction(SET_DEFAULT_FORM_CREATE_ACCOUNT);

const setRedirectTab = createAction(SET_REDIRECT_TAB);

const setRealtimePaymentStatus = createAction(SET_REALTIME_PAYMENT_STATUS);

export const setAccountsCurrentApiRequestStr = (str) => (dispatch) => {
    dispatch( setCurrentApiRequestStr(str) );
}

export const unsetAccessListNotificationSettings = () => (dispatch) => {
    dispatch( unsetAccountAccessListNotificationSettings() );
};

export const getAccounts = (filters = null) => (dispatch) => {
    dispatch(setLoading(true));

    api('account:get_list', {
        filter: filters || {},
        add: {account_note_last: {}}
    }).then(({account_list = []}) => {
        dispatch(setAccounts({
            items: account_list.map(
                x => ({
                    ...x,
                    name: x.name || '',
                    account_manager: x.account_manager || {},
                    closed: !x.active || x.deleted,
                    balance: x.BALANCE || 0,
                    invoice_balance: x.BALANCE_INV || 0
                })
            ),
            filter: filters
        }));

    }).finally(() => dispatch(setLoading(false)));
};

export const getAccount = (id, getFromApi = true, redirectOnError = false) => (dispatch) => {
    dispatch(setLoading(true));

    if (!getFromApi) {
        dispatch(viewAccount(id));
    } else {
        api('account:get', {target: {account_id: id}})
            .then((response) => {
                // debugger;
               
                if (response === undefined || !response.account) {
                    dispatch(setLoading(false));
                    
                    if (redirectOnError)
                        history.push(DEFAULT_AUTH_PATH_ADMIN);
                    
                    return;
                }
                    
                const {account = {}} = response || {};
                dispatch(
                    setViewAccount({
                        ...account,
                        permission_list: account.permission_list || [],
                        trunk_notificaton: account.trunk_notificaton || []
                    }));
            })
            .finally(() => dispatch(setLoading(false)));
    }
};

export const getAccessListFilter = (id, filter, page = 1, per_page, sort = {}) => (dispatch) => {
    dispatch(setViewAccessListFiltersLoading(true));

    api('access:get_list', {target: {account_id: id}, filter, page, per_page, sort})
        .then(({access_list}) => dispatch(setViewAccessListFilters(access_list)))
};

export const getAccountTrunk = (id, callback) => (dispatch) => {
    dispatch(setViewAccountTrunkLoading(true));

    dispatch(setViewAccountAllocated({
        items: [],
        count: 0,
        page: 1
    }));

    api('trunk:get_list',{target:  {account_id: id }})
        .then( (r) => {
            if (r === undefined) {
                return;
            }
            dispatch( setViewAccountTrunk(
                (r.trunk_list || []).map( trunk => ({
                    ...trunk,
                    type: trunk.is_default_term_point
                        ? 'Default IVR'
                        : `${trunk.protocol_name}/${trunk.ip}:${trunk.port} ${trunk.tprefix}`
                }))
            ));
             callback && callback(r.trunk_list || [])
            }
        )
        .finally(() => dispatch(setViewAccountTrunkLoading(false)));
};

export const createAccountTrunk = (id, data) => (dispatch) => {
    api('trunk:create', {
        target: {account_id: id},
        sp_key: data.sp_key,
        prt_key: data.prt_key,
        ...data
    }).then(({trunk}) => trunk && dispatch(addViewAccountTrunk({...trunk, ...data})))
};

export const editAccountTrunk = (id, trunk_id, data) => (dispatch) => {
    api('trunk:modify', {
        target: {account_id: id, trunk_id: trunk_id},
        sp_key: data.sp_key,
        prt_key: data.prt_key,
        ...data
    }).then(({trunk}) => trunk && dispatch(addViewAccountTrunk({...trunk, ...data})))
};

export const removeAccountTrunk = (id, trunk_id, trunk1_id) => (dispatch) => {
    dispatch(setViewAccountTrunkLoading(true));

    api('trunk:get_list',{target:  {account_id: id }})
        .then( ({ trunk_list = [] }) => {
            if (trunk1_id) {
                api('trunk:remove', {target: {account_id: id, trunk_id, trunk1_id}}).then(({trunk}) => {
                    dispatch(setViewAccountTrunkAfterDelete(trunk.id))
                })
            } else {
                api('trunk:remove', {target: {account_id: id, trunk_id}}).then(({trunk}) => {
                    dispatch(setViewAccountTrunkAfterDelete(trunk.id))
                });
            }
        })
        .finally(() => dispatch(setViewAccountTrunkLoading(false)));

};

export const getAccountAllocatedNumbers = (account_id, trunk_id, group, filter, page = 1, per_page, sort = {}) => (dispatch) => {
    dispatch(setViewAccountAllocatedLoading(true));

    let sort1, sort1_desc;

    if (sort.column) {
        sort1 = sort.column === 'name' ? 'price_range_name' : sort.column;
        sort1_desc = sort.type && sort.type === DESC_SORT;
    }

    localStorage.setItem('allocatedNumbersPerPage', per_page);

    api('trunk_number__get_list',{target:{trunk_id} , group, filter, page, per_page, sort1, sort1_desc}).then(
        ({
             trunk_number_list,
             group_price_range_list,
             group_subdestination_list,
             row_count,
             group_price_range_count,
             group_subdestination_count,
             trunk_number_count,
             group_price_range_number_count,
             group_subdestination_number_count
         }) => dispatch(setViewAccountAllocated(
            {
                items: (trunk_number_list || group_price_range_list || group_subdestination_list || []).map(
                    x => {
                        if(group_price_range_list) {
                            x.pr_key = x.price_range.pr_key;
                            x.sde_key = x.price_range.sde_key;
                            x.sp_key = x.price_range.sp_key;
                            x.subdestination_name = x.price_range.subdestination_name;
                        }

                        if(!x.subdestination_name){
                            x.subdestination_name = x.sde_name;
                        }

                        if(x.start_date && x.end_date)
                            x.date = formatDateApiWithoutTime(x.start_date) + '-' + formatDateApiWithoutTime(x.end_date);
                        if(x.price_range)
                            x.name = x.price_range.name;

                        if(x.price_ranges && x.trunk_numbers)
                            x.allocated_numbers_and_ranges = x.trunk_numbers +'/'+ x.price_ranges;

                        return x;
                    }
                ),
                count: row_count || group_price_range_count || group_subdestination_count || 0,
                trunk_number_count: trunk_number_count || group_price_range_number_count || group_subdestination_number_count,
                page,
                per_page
            }
            )))
        .finally(() => dispatch(setViewAccountAllocatedLoading(false)));
};

export const getAccountUsers = (account_id) => (dispatch) => {
    dispatch(setViewAccountUsersLoading(true));
    console.log(account_id);
    api('account_user:get_list', {target: {account_id}} )
        .then( (r) => {
            if (r === undefined)
                return;
            dispatch(setViewAccountUsers(r.account_user_list || []))
        })
        .finally(() => dispatch(setViewAccountUsersLoading(false)));

};

export const createAccount = (params) => dispatch => {
    api(ACCOUNT_CREATE_METHOD, params).then( data => {
        // console.log(data)
    } )
    // api('trunk_number__get_list',{target:  {account_id: id , trunk_id: trunk_id }}).then( ({ trunk_list = [] }) => dispatch(setViewAccountAllocated(trunk_list || [])))
    // api('trunk_number__get_list',{target:  {account_id: id , trunk_id: trunk_id }}).then( responce => console.log(responce))
};

export const modifyAccountRequest = ({name, surname, email, phone, password, old_password}) => (dispatch) => {
    const user = localStorage.getItem(LOCAL_STORAGE_USER_INFO);
    const userInfo = user && JSON.parse(user);

    const params = {
        ...(name ? {name: name} : {}),
        ...(surname ? {surname: surname} : {}),
        ...(phone ? {phone: phone} : {}),
        ...(email ? {email: email} : {}),
    };

    const dataToSave = {...userInfo, ...params};

    api(ACCOUNT_USER_MODIFY_BY_SELF, {
        ...params,
        ...(password ? {password: password} : {}),
        old_password
    }, true)
        .then(response => {
            if (response) {
                dispatch(setEditError(false));
                if (response && response.account_user) {
                    const lang = store.getState().auth.lang;

                    Alert.success(lang === "en" 
                        ? 'Profile data has successfully changed!' 
                        : "تم تغيير بيانات الملف الشخصي بنجاح!", 15000);
                    
                    dispatch(setInfoAccount({
                        name: userInfo.name,
                        surname: userInfo.surname,
                        phone: userInfo.phone,
                        email: userInfo.email,
                        ...params
                    }));
                    localStorage.setItem(LOCAL_STORAGE_USER_INFO, JSON.stringify(dataToSave))
                }
            }
        })
        .catch(error => {
            if (error.response && error.response.data.error.data.old_password) {
                Alert.error('Wrong old password', 15000);

                dispatch(setEditErrorMessage("Wrong old password"))
            }
        })
};

export const getRealtimePaymentStatus = (id) => (dispatch) => {
    api(ACCOUNT_USER_GET_REALTIME_STATUS,{
        target: {
            account_user_id: id
        }
    }).then(response => {
        dispatch(setRealtimePaymentStatus(
            response.realtime_status
            && response.realtime_status.hasOwnProperty('payment_not_viewed_count')
            && response.realtime_status.payment_not_viewed_count) )
    });
};

/* Account:AccessListFilters */

// thunk "Access List Filters" get items
export const getAccessListFiltersItems = (id) => (dispatch) => {
    dispatch( setAccountAccessListFiltersListLoading() );
    api('access_list_filter:get_list',
        {target: {account_id: id}}
    ).then( ({access_list_filter_list}) => {
        let payload = access_list_filter_list;
        dispatch( setAccountAccessListFiltersList(payload) );
    } )
};

// thunk "Access List Filters" create new item
export const createNewAccessListFiltersItem = (id, data) => (dispatch) => {
    dispatch( setAccountAccessListFiltersListLoading() );

    api(
        'access_list_filter:create',
        {
            target: {
                account_id: id
            },
            ...data,
        }
    ).then( response => {
        dispatch( setAccountAccessListFiltersListLoading() );
        dispatch( getAccessListFiltersItems(id) );
    } );
};


// thunk "Access List Filters" modify item
export const modifyAccessListFiltersItem = (accountId, alf_key, data) => async (dispatch) => {

    let promise = await api(
        'access_list_filter:modify',
        {
            target: {
                alf_key: alf_key
            },
            ...data,
        }
    )

    return promise;
};



// thunk "Access List Filters" remove item
export const removeAccessListFiltersItem = (id, data) => (dispatch) => {
    api('access_list_filter:remove',{
        target: {
            alf_key: data['alf_key']
        }
    });
};

// thunk "Notifications" get settings
export const getAccessListNotificationSettings = (id) => (dispatch) => {
    api('access_list_notification:get',{
        target: {
            account_id: id
        }
    }).then(response => {
        dispatch( setAccessListNotificationSettings(response.access_list_notification) )
    });
};


// thunk "Notifications" modify settings
export const modifyAccessListNotificationSettings = (id, data) => async (dispatch) => {
    let response = await api('access_list_notification:modify',{
        target: {
            account_id: id,
        },
        ...data
    });

    dispatch( getAccessListNotificationSettings(id) )

    return response;
};

export const setCurrentTrunkId = (id) => (dispatch) => {
    dispatch(dispatchCurrentTrunkId(id))
};

export const setCurrentRangeNumber = (number) => (dispatch) => {
    dispatch(dispatchCurrentRangeNumber(number))
};

export const setAllocationRestrictions = (id, data, key) => (dispatch) => {
    api('account_setting:modify', {
        target: {
            account_id: id
        },
        ...data
    }).then(
        (response) => {
            if (response) {
                dispatch(setModifiedLimit(key));
            }
            if (Object.keys(response).length && key) {
                Alert.success('Allocation Restrictions has been modified!')
            }
            if (Object.keys(response).length && !key) {
                Alert.warning('Allocation limit has been changed to default!')
            }
        }
    )
};

export const setModifiedLimitId = (key) => (dispatch) => {
    dispatch(setModifiedLimit(key))
};

export const setDefaultFormCreateAccount = (data) => (dispatch) => {
    dispatch(setDefaultFormCreateAccountAction(data))
};

export const onSetRedirectTab = (tab) => (dispatch) => {
    dispatch(setRedirectTab(tab))
};