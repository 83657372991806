import React, {useEffect, useRef, useState} from 'react';
import {CheckPicker, Icon} from "rsuite";
import css from "components/Table/Table.module.css";
import _ from 'lodash';

const ShowColumnPicker = ({columns, onChange, value}) => {
    const [isOpen, setOpen] = useState(false);
    const ref = useRef(null);
    const pickerData = columns.map(({dataKey, label, fullLabel}) => ({
        dataKey,
        label: fullLabel || label
    }));



    const handleClickOutside = (event) => {
        const menuBodyEl = _.get(ref, 'current.menuContainerRef.current.menuBodyContainerRef.current');

        if ((!menuBodyEl || !menuBodyEl.contains(event.target))) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);

        return () => document.removeEventListener('click', handleClickOutside, false);
    });

    return (
        <div className="js-column-selector-wrapper">
            <Icon
                className={`position-absolute z-index-dropdown columns-picker-icon-show`}
                icon={isOpen ? "indent" : "align-justify"}
                onClick={() => setOpen(!isOpen)}
            />
            <CheckPicker
                ref={ref}
                className="position-absolute z-index-dropdown invisible"
                open={isOpen}
                valueKey="dataKey"
                value={value}
                data={pickerData}
                // menuAutoWidth={false}
                searchable={false}
                onChange={onChange}
                style={{width: 190}}
            />
        </div>
    );
};

export default ShowColumnPicker;