import React, {useEffect, useState} from "react";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import PriceRangeQuarantineSettingTable from "./PriceRangeQuarantineSettingTable";
import PriceRangeQuarantineSettingCreateForm from "./PriceRangeQuarantineSettingCreateForm";
import {FlexboxGrid} from "rsuite";
import PriceRangeQuarantineSettingFilter from "./PriceRangeQuarantineSettingFilter";

export default ({
    data,
    loading,
    worldzoneList,
    destinationList,
    subdestinationList,
    globalSettings = {},
    getPriceRangeQuarantineSettingList,
    createPriceRangeQuarantineSetting,
    modifyPriceRangeQuarantineSetting,
    removePriceRangeQuarantineSetting,
}) => {

    const [filter, onChangeFilter] = useState({});

    useEffect(() => {
        getPriceRangeQuarantineSettingList(filter);
    }, [filter]);

    useEffect(() => {
        getPriceRangeQuarantineSettingList();
    }, []);


    return (
        <PanelLayout>
            <PageHeader title="Special Quarantine Terms"/>

            <p>
                Default values for quarantine are <b>{globalSettings.google_quarantine_period_allocation}</b> hour after allocation
                and <b>{globalSettings.google_quarantine_period_traffic}</b> hours after last call to 10k group.
            </p>

            <FlexboxGrid>
                <FlexboxGrid.Item style={{marginTop: '30px'}}>
                    <PriceRangeQuarantineSettingFilter
                        {...{
                            filter,
                            onChangeFilter,
                            worldzoneList,
                            destinationList,
                            subdestinationList,
                        }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <PriceRangeQuarantineSettingTable
                {...{
                    data,
                    loading,
                    worldzoneList,
                    destinationList,
                    subdestinationList,
                    filter,

                    getPriceRangeQuarantineSettingList,
                    modifyPriceRangeQuarantineSetting,
                    removePriceRangeQuarantineSetting,
                }}
            />

            <PriceRangeQuarantineSettingCreateForm
                onSubmit={createPriceRangeQuarantineSetting}
                {...{
                    filter,
                    worldzoneList,
                    destinationList,
                    subdestinationList,
                    getPriceRangeQuarantineSettingList,
                }}
            />
        </PanelLayout>
    )
};