import {
    REFERENCES_ALLOCATION_LIMIT_LIST,
    SET_ACCOUNT_DIALER_INFO, SET_ACCOUNT_READY_FOR_PAYMENT
} from '../const';

const initialState = {
    dialerInfo: null,
    readyForPayment: 0,
    allocation_limit: {}
};

const handlers = {
    [SET_ACCOUNT_DIALER_INFO]: (state, {payload}) => ({
        ...state,
        dialerInfo: payload,
        readyForPayment: payload.available_for_payment || 0
    }),
    [REFERENCES_ALLOCATION_LIMIT_LIST]: (state, {payload: allocation_limit}) => ({...state, allocation_limit}),
    [SET_ACCOUNT_READY_FOR_PAYMENT]: (state, {payload}) => ({...state, readyForPayment: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};



