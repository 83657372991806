import {
    ACTION_LOGS_LIST,
    ACTION_LOGS_SET_LOADING,
    ACTION_LOGS_REFERENCES
} from '../const';

const initialState = {
    list: [],
    count: 0,
    page: 1,
    perPage: 100,
    loading: true,
    
    referencesFetched: false,
    managerList: [],
    actionList: [],
    userList: [],
};

const handlers = {
    [ACTION_LOGS_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [ACTION_LOGS_LIST]: (state, {payload: {list, count, page, perPage}}) => ({
        ...state,
        list: list,
        count: count,
        loading: false,
        page: page,
        perPage: perPage,
    }),
    [ACTION_LOGS_REFERENCES]: (state, {payload: {account_user_manager_list, action_list, account_user_list}}) => ({
        ...state,
        managerList: account_user_manager_list,
        actionList: action_list,
        userList: account_user_list,
        referencesFetched: true,
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}