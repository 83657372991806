import React, {useContext} from 'react';
import {renderColumn} from '../../utils/helpers';
import {useIntl} from "react-intl";
import CustomizableTable from '../../components/client/CustomizableTable/CustomizableTable';
import {useWindowWidth} from "../../hooks";
import m from "../../definedMessages";
import {RtlContext} from "../../App";

export default ({data = [], loading, page, count, per_page, savedPerPage, onChangePerPage, onChangePage}) => {

    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();
    const rtl = useContext(RtlContext);

    const columns = [
        {
            id: 'datetime', 
            label: formatMessage(m.calltime),
            dataKey: 'datetime', 
            align: 'center',
            flexGrow: 2,
            minWidth: 120,
            value: ({datetime}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{datetime}</div>
                    </div>
                )
            }
        },
        {
            id: 'a_number', 
            label: formatMessage(m.aNumber), 
            dataKey: 'a_number',
            flexGrow: 2,
            minWidth: 120, 
            align: 'center',
            value: ({a_number, a_subdestination_name}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{a_number}</div>
                        <div className="table-two-staged__cell-second">{a_subdestination_name}</div>
                    </div>
                )
            }
        },
        {
            id: 'b_number', 
            label: formatMessage(m.bNumber), 
            dataKey: 'b_number',
            flexGrow: 2,
            minWidth: 120, 
            align: 'center',
            value: ({b_number, b_subdestination_name}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{b_number}</div>
                        <div className="table-two-staged__cell-second">{b_subdestination_name}</div>
                    </div>
                )
            }
        },
        {
            id: 'range', 
            label: formatMessage(m.range), 
            dataKey: 'range', 
            flexGrow: 4,
            minWidth: 200, 
            align: 'center',
            value: ({range}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{range}</div>
                    </div>
                )
            }
        },
        {
            id: 'duration', 
            label: formatMessage(m.duration), 
            dataKey: 'duration', 
            flexGrow: 2,
            minWidth: 120, 
            align: 'center',
            value: ({duration}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-single">{duration}</div>
                    </div>
                )
            }
        }
    ];

    const renderedColumns = columns.map(renderColumn);

    return (
        <>
            <CustomizableTable
                {...{
                    rtl,
                    data,
                    count,
                    page,
                    per_page,
                    loading,
                    savedPerPage,
                    onChangePage,
                    onChangePerPage,
                    renderedColumns
                }}
                data={data}
                isMobile={windowWidth < 768}
                rowHeight={46}
                twoStaged={true}
                isChangedColumns={false}
                isPaginated={true}
            />
        </>
    );
}