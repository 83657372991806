import React, {useState} from 'react';
import {Alert, Checkbox, DatePicker, SelectPicker} from 'rsuite';
import ModalSendApi from '../../../components/Modal/ModalSendApi'
import ApiRequest from '../../../components/ApiRequest'
import {toEndDayUTCTime, toStartDayUTCTime} from 'utils';
import CustomDatePicker from "../../../components/Form/CustomDatePicker";
import {useWindowWidth} from "../../../hooks";


export default ({initialValues, target, getCount, entity, trunks, ...props}) => {
    const [trunkToModeId, setTrunkToMoveId] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [withDate, setWithDate] = useState(false);

    const windowWidth = useWindowWidth();

    const numbersCount = getCount();

    return (
        <ModalSendApi
            {...props}
            title="Move Number"
            successText="Yes"
            extraDisabled={!trunkToModeId || withDate && (!endDate || !trunkToModeId)}
        >
            <ApiRequest
                method="trunk_number__filter:move_to_trunk"
                checkResultKey = 'trunk_numbers'
                target = {{...target, trunk1_id: trunkToModeId }}
                update = { ({trunk_numbers}) => {
                    Alert.success(`Moved ${trunk_numbers} numbers`)
                }}
                data={{
                    ...(endDate ? {end_date: endDate} : {}),
                    ...initialValues
                }}
            >
                <div>
                    <p>Selected <strong>{numbersCount}</strong> {numbersCount === 1 ? "number" : "numbers"} from this trunk will be moved. Do you really want to do it?</p>
                    <div
                        style={{textAlign: windowWidth >= 600 ? 'center' : 'left'}}
                        className="mt-5"
                    >
                        <span className="mr-4">Move to trunk</span>
                        <SelectPicker
                            cleanable={false}
                            data={trunks}
                            placeholder={'Select trunk to move'}
                            valueKey="id"
                            labelKey="name"
                            onChange={(trunkId) => setTrunkToMoveId(trunkId || null)}
                            style={{
                                width: 250
                            }}
                        />
                    </div>
                    <div
                        style={{textAlign: windowWidth >= 600 ? 'center' : 'left'}}
                        className="mt-4"
                    >
                        <Checkbox
                            inline
                            className="mr-4"
                            onChange={(v, checked) => {
                                setWithDate(checked);
                                if (!checked) {
                                    setEndDate(null);
                                }
                            }}
                        >
                            From date
                        </Checkbox>
                        <CustomDatePicker
                            placeholder="Enter Date"
                            disabled={!withDate}
                            onChange={(date) => {
                                date && setEndDate(toStartDayUTCTime(date));
                            }}
                            style={{width: 150}}
                            ranges={[
                                {
                                    label: 'Today',
                                    value: new Date()
                                },
                                {
                                    label: 'Tomorrow',
                                    value: new Date( new Date().setDate(new Date().getDate() + 1) )
                                }
                            ]}

                            // mobile
                            inline={windowWidth < 600 && withDate}
                            oneTap={windowWidth < 600 && withDate}
                        />
                    </div>
                </div>
            </ApiRequest>
        </ModalSendApi>
    );
};