import React, {useEffect, useState} from "react";
import {Spacer} from "../../../components/base/Spacer";
import BlockIvrSoundsFilter from "./BlockIvrSoundsFilter";
import {BaseButton, ButtonPrimary} from "../../../components/base/BaseButton";
import {Button, List, Icon, Loader, IconButton} from "rsuite";
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import styled from "styled-components";
import ModalAddSound from "./ModalAddSound";
import ModalCancel from "./ModalCancel";


export default (
    {
        data,
        loading,
        getIvrSoundList,
        activeIvr
    }
) => {

    const [modalNewSoundShow, setModalNewSoundShow] = useState(false);
    const [modalCancelShow, setModalCancelShow] = useState(false);
    const [activeSoundId, setActiveSoundId] = useState(null);

    useEffect(() => {
        getIvrSoundList();
    }, []);

    return (
        <>
            <Spacer size={30}/>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <BlockIvrSoundsFilter
                    style={{flexShrink: 0}}
                    activeIvr={activeIvr}
                />
                <ButtonPrimary onClick={() => setModalNewSoundShow(true)}>Add New</ButtonPrimary>
            </div>
            
            <Spacer size={30}/>

            <List>
                {data.map(item => (
                    <List.Item key={item.id}>
                        <IvrAudio className="ivr-audio">
                            <div className="ivr-audio__title">{item.filename}</div>

                            <StyledAudioPlayer
                                className="ivr-audio__content"
                                src={item.url_source}
                                autoPlay={false}
                                layout="horizontal-reverse"
                                showJumpControls={false}
                                customProgressBarSection={[
                                    RHAP_UI.CURRENT_TIME,
                                    RHAP_UI.PROGRESS_BAR,
                                    RHAP_UI.DURATION,
                                ]}
                                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                            />

                            <div className="ivr-audio__actions">
                                <IconButton
                                    as={BaseButton}
                                    download={item.filename}
                                    href={item.url_source}
                                    buttonStyle="primary"
                                    icon={<Icon icon="download"/>}
                                />
                                <BaseButton
                                    onClick={() => {
                                        setActiveSoundId(item.id);
                                        setModalNewSoundShow(true);
                                    }}
                                    buttonStyle="secondary"
                                >
                                    Replace by sound
                                </BaseButton>
                                <Button
                                    onClick={() => {
                                        setModalCancelShow(true);
                                        setActiveSoundId(item.id);
                                    }}
                                    color="red"
                                >
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        </IvrAudio>
                    </List.Item>
                ))}
            </List>

            <ModalAddSound
                show={modalNewSoundShow}
                activeSoundId={activeSoundId}
                getIvrSoundList={getIvrSoundList}
                onClose={() => {
                    setModalNewSoundShow(false);
                    setActiveSoundId(null);
                }}
            />

            <ModalCancel
                show={modalCancelShow}
                activeSoundId={activeSoundId}
                getIvrSoundList={getIvrSoundList}
                onClose={() => {
                    setModalCancelShow(false);
                    setActiveSoundId(null);
                }}
            />

            {loading && <Loader backdrop />}
        </>
    )
};


const StyledAudioPlayer = styled(AudioPlayer)`
    .rhap_controls-section {
        flex-grow: 0;
    }
`;

const IvrAudio = styled.div`
    display: flex;
    align-items: center;
    
    .ivr-audio__content {
        padding: 2px 15px;
        max-width: 800px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }
    
    .rhap_time {
        font-size: 14px;
    }
    
    .ivr-audio__actions {
        flex-shrink: 0;
        margin-left: auto;
        
        .rs-btn {
            margin-left: 15px;
        }
    }
    
    .rhap_play-pause-button {
        font-size: 35px;
        width: 35px;
        height: 35px;
        margin-left: 0;
        
        svg {
            vertical-align: top;
        }
    }
    
    .ivr-audio__title {
        width: 270px;
        word-break: break-all
    }
`;