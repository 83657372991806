import {PAGE_LOGGER} from '../const';

const initialState = {
    pageId: null
};

const handlers = {
    [PAGE_LOGGER]: (state, {payload}) => ({
        ...state,
        pageId: payload
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}