import React from 'react';
import { Form, FormGroup,SelectPicker, Input, InputGroup, Icon, Schema} from 'rsuite';
import CustomField from '../../components/Form/CustomField';
import FormHOC from '../../hoc/FilltersForm';
import Checkbox from '../../hoc/Checkbox';
import ServicePlans from "../../components/Filters/ServicePlans";
import {connect} from "react-redux";
import {USD_DEFAULT_CURRENCY_KEY} from "../../const";
const { StringType } = Schema.Types;

const numbersFiltersModel = Schema.Model({
    str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});


const PriceFilters =   ({onChange, currency_list, worldzone_list, subdestination_list, destination_list, defaultSPKey, price_list}) =>  {
    return (
            <>
                <Form layout="inline" 
                    onChange={onChange}
                    formDefaultValue={{
                        cur_key: USD_DEFAULT_CURRENCY_KEY,
                        p_key: price_list.length ? price_list[0].p_key : defaultSPKey
                    }}
                    model={numbersFiltersModel}
                    >
                    <CustomField
                        accepter={SelectPicker}
                        labelKey="name"
                        valueKey="p_key"
                        name="p_key"
                        data={price_list}
                        placeholder="Prices"
                        searchable={false}
                        cleanable={false}
                        classPrefix="allocated"
                    />
                    <CustomField
                        accepter={SelectPicker}
                        labelKey="name"
                        valueKey="wz_key"
                        data={worldzone_list}
                        placeholder="Worldzone"
                        name="wz_key"
                        classPrefix="allocated"
                    />
                     <CustomField
                        accepter={SelectPicker}
                        data={destination_list}
                        labelKey="name"
                        valueKey="de_key"
                        placeholder="Destination"
                        name="de_key"
                        classPrefix="allocated"
                    /> 
                    <CustomField
                        accepter={SelectPicker}
                        data={subdestination_list}
                        labelKey="name"
                        valueKey="sde_key"
                        placeholder="Subdestination"
                        name="sde_key"
                        classPrefix="allocated"
                    />  
                    <CustomField
                        accepter={SelectPicker}
                        data={currency_list}
                        cleanable={false}
                        searchable={false}
                        labelKey="name"
                        valueKey="cur_key"
                        name="cur_key"
                        classPrefix="allocated"
                    />
                </Form>
            </>
        )
};

const mapStateToProps = ({references}) => ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapStateToProps)(FormHOC(PriceFilters, numbersFiltersModel,300));