import React, {useEffect, useState} from 'react';
import {Alert, Dropdown, List as RsuiteList, Modal} from 'rsuite';

import {getFileResponse} from '../../../api/loginRoutes';
import Icon from 'rsuite/es/Icon';
import './ModalAllocationResult.css';
import * as S from './styled';
import {
    copyToClipboard,
    downloadNumbersFile,
    downloadNumbersRangeFile,
    downloadPrefixesRangeFile,
    getRangeByNumberDigit,
    makeNumbersFromPrefixesToCsv,
    saveFileBlob
} from 'utils/helpers';
import {makeRangeFromPrefixesToCsv} from 'utils';
import {getCsvResponse} from 'api/loginRoutes';
import {getBlobContent, randomizeArray} from "../../../utils";
import {useWindowWidth} from "../../../hooks";

import { EOL } from 'const';
import ResultNotAllocatedList from "./ListNotAllocatedNumbers";
import BaseModal from "../../base/BaseModal";


const AllocationResult = ({
    show,
    onClose, 
    totalNumbersForced,
    allocatedByPrefix,
    trunkId,
    hideBufferButtons,
    showFullList,
    csvContent,
    transactionId,
    isGoogleOtp = false,
    allocatedByOTP,
    notAllocatedNumbers = [],
}) => {

    const [loading, setLoading] = useState(false);
    const [loaderDownloadButton, onLoadDownloadButton] = useState(false);

    const windowWidth = useWindowWidth();

    const downloadNumberByTransaction = (downloadFunction, prefixStatus, convertPrefixToNumbers, name) => {
        onLoadDownloadButton(true);
        getFileResponse("trunk_number:get_list", {target: {trunk_number_transaction_id: transactionId}}, true)
            .then((response) => {
                const csvText =  getBlobContent(response);
                csvText.then((text) => {
                    const csvBody = prefixStatus && convertPrefixToNumbers ? makeNumbersFromPrefixesToCsv(text) : text;
                    const csvStringsArray = csvBody ? csvBody.split('\n') : [];
                    downloadFunction(csvStringsArray, prefixStatus, name, isGoogleOtp);
                    onLoadDownloadButton(false)
                })
            });
    };

    const onCopy = () => {
        const csvData = allocatedByPrefix ? makeNumbersFromPrefixesToCsv(csvContent) : csvContent;
        const csvDataList = csvData && csvData.length ? csvData.split('\n') : [];
        copyToClipboard(csvDataList && csvDataList.length && csvDataList.slice(1)
            .map( line => {
                const item = line.split(';');
                return line ? `${item[0]}, ${item.slice(2).join(', ')}` : ''
            })
            .join(EOL)
        );

        Alert.success(`Copied!`);
    };

    const onCopyByPrefix = () => {
        copyToClipboard(csvData && csvData.length && csvData.slice(1)
            .map( line => {
                const item = line.split(';');
                return `${getRangeByNumberDigit(item[0], item[1])}, ${item.slice(2).join(', ')}`
            })
            .join(EOL)
        );

        Alert.success(`Copied!`);
    };


    const onCompleteDownload = async() => {
        setLoading(true);
        const file = await getCsvResponse('trunk_number__get_list', {
            target: {
                trunk_id: trunkId
            },
        });

        if (file) {
            const csvContent = makeNumbersFromPrefixesToCsv(file);
            const csvHeaderList = csvContent.split('\n')[0].split(';');
            const csvDataList = csvContent.split('\n').slice(1).map(line => {
                const items = line.split(';');
                return [items[0], ...items.slice(2)].join(';');
            });
            const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(2)].join(';');
            csvDataList.unshift(csvHeaders);
            const csvData = csvDataList.join('\n');

            saveFileBlob(csvData, 'allocated_numbers.csv');

        }

        setLoading(false);
    };

    const onCompleteDownloadPrefixes = async () => {
        setLoading(true);
        const file = await getCsvResponse('trunk_number__get_list', {
            target: {
                trunk_id: trunkId
            },
        });

        if (file) {
            const csvContent = makeRangeFromPrefixesToCsv(file);
            const csvHeaderList = csvContent.split('\n')[0].split(';');
            const csvDataList = csvContent.split('\n').slice(1).map(line => {
                const items = line.split(';');
                return [items[0], ...items.slice(2)].join(';');
            });
            const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(2)].join(';');
            csvDataList.unshift(csvHeaders);
            const csvData = csvDataList.join('\n');

            saveFileBlob(csvData, 'allocated_numbers.csv');

        }

        setLoading(false);
    };


    const onCopyOnlyNumbers = () => {
        const csvData = allocatedByPrefix ? makeNumbersFromPrefixesToCsv(csvContent) : csvContent;
        const csvDataList = csvData && csvData.length ? csvData.split('\n') : [];

        const onlyNumbersData = csvDataList && csvDataList.length && csvDataList.slice(1)
            .map( line => {
                const item = line.split(';');
                return item.length && item[0];
            });

        if (isGoogleOtp) {
            const data = randomizeArray(onlyNumbersData, 100);
            copyToClipboard( data.join(EOL) );
            Alert.success(`Copied!`);
            return;
        }

        copyToClipboard( onlyNumbersData.join(EOL) );
        Alert.success(`Copied!`);
    };

    const csvData = csvContent 
        ? csvContent.split('\n').filter(value => value)
        : [];

    const numberCsvListItems = csvData
        .slice(1)
        .map( (line, index) => {
            const items = line.split(';');
            return <RsuiteList.Item key={index}>
                {getRangeByNumberDigit(items[0], items[1])}, {items.slice(2).join(', ')}
            </RsuiteList.Item>
        }
    );

    const isMobile = windowWidth < 820;

    return (
            <BaseModal
                show={show}
                onHide={onClose}
                // width={windowWidth < 820 ? windowWidth : 820}
                style={{width: windowWidth < 820 ? windowWidth : 820}}
            >
                <Modal.Header>
                    <Modal.Title>New Allocated numbers</Modal.Title>
                </Modal.Header>
                
                <Modal.Body className="modal-body">
                    
                    <div className="allocation_result_warning-message">
                        <Icon icon="exclamation-triangle"/>&nbsp;
                        Pay your attention that allocated numbers will be activated during 10 minutes after allocation. Warn dialer about it.
                    </div>

                    {notAllocatedNumbers && !!notAllocatedNumbers.length &&
                        <ResultNotAllocatedList
                            count={notAllocatedNumbers.length}
                            numbers={notAllocatedNumbers}
                        />
                    }

                    <div className="text-center">
                        <strong>{totalNumbersForced || 0}</strong> numbers were allocated
                    </div>
                    
                    {showFullList &&
                        <S.List 
                            size='sm' 
                            style={{height: csvData.length * 36, maxHeight: 288}}
                        >
                            {numberCsvListItems}
                        </S.List>
                    }

                </Modal.Body>

                <Modal.Footer className="modal-footer">
                    { totalNumbersForced 
                        
                        ? <>

                            <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>
                                {!hideBufferButtons &&
                                    <S.Dropdown
                                        placement={isMobile ? 'topStart' : 'bottomStart'}
                                        title={<>{loading && <S.Loader/>} Copy List</>} 
                                        disabled={loading}
                                    >
                                        <Dropdown.Item onClick={onCopyOnlyNumbers}>Copy just numbers</Dropdown.Item>
                                            
                                        {allocatedByPrefix && 
                                            <Dropdown.Item onClick={onCopyByPrefix}>Copy list allocated by prefix</Dropdown.Item>
                                        }

                                        <Dropdown.Item onClick={onCopy}>Copy List</Dropdown.Item>

                                    </S.Dropdown>
                                }
                            </S.ButtonToolbar>

                            <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>

                                <S.Dropdown
                                    placement={isMobile ? 'topStart' : 'bottomStart'}
                                    title={<>{(loading || loaderDownloadButton) && <S.Loader/>} Download List</>}
                                    disabled={loading}
                                >
                                    <Dropdown.Item onClick={onCompleteDownload}>
                                        Download complete list allocated numbers as csv
                                    </Dropdown.Item>

                                    {allocatedByOTP && <Dropdown.Item onClick={onCompleteDownloadPrefixes}>
                                        Download complete list of allocated numbers as ranges
                                    </Dropdown.Item>}

                                    <Dropdown.Item
                                        onClick={() => downloadNumberByTransaction(downloadNumbersRangeFile, allocatedByPrefix, false)}
                                    >
                                        Download new allocated numbers
                                    </Dropdown.Item>

                                    {allocatedByPrefix &&
                                    <Dropdown.Item
                                        onClick={() => downloadNumberByTransaction(downloadPrefixesRangeFile, allocatedByPrefix, false)}
                                    >
                                        Download new allocated numbers as ranges
                                    </Dropdown.Item>
                                    }

                                    <Dropdown.Item 
                                        onClick={() => downloadNumberByTransaction(downloadNumbersFile, allocatedByPrefix, false, 'numbers.txt')}
                                    >
                                        Download new allocated numbers (only numbers; txt version)
                                    </Dropdown.Item>

                                </S.Dropdown>

                            </S.ButtonToolbar>

                            <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>

                                <S.CancelButton 
                                    width="100%" 
                                    onClick={onClose}
                                >
                                    Close
                                </S.CancelButton>

                            </S.ButtonToolbar>
                        </>
                        
                        : <></>
                    }

                </Modal.Footer>
            </BaseModal>
    );
};

export default AllocationResult;