import {
    PRICE_DIALER as PREFIX,
    SET_TRUNK_NUMBER_DIALER,
    SET_LOADING_PRICE_ALLOCATED_NUMBERS_DIALER,
    SET_UPLOAD_LOADING_DIALER
} from '../../const';

import {
    RATECARD_LIST_OTP_DEFAULT_DIALER,
    RATECARD_LIST_DEFAULT_DIALER,
    RATECARD_LIST_OTP_ACCOUNT_DIALER,
    RATECARD_LIST_ACCOUNT_DIALER,
    ALLOCATION_PRICE_RANGE_DIALER_API,
} from '../../const/apiMethods';

import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {createUrl} from '../../utils/helpers';

const setPriceList = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);
const setTrunkList = _setValue(SET_TRUNK_NUMBER_DIALER);

export const setLoadingAllocatedNumbersDialer = _setValue(SET_LOADING_PRICE_ALLOCATED_NUMBERS_DIALER);

export const setUploadingPriceLoadingDialer = (state) => (dispatch) => {
    dispatch(setUploadLoading(state));
};

export const getPriceListDialer = (per_page = 12, filter = {}, page = 1, isAuth = false) => (dispatch) => {
    dispatch(setLoading(true));

    const isTest = localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo')).session.account.is_test 
        : false;
    
    const request = isAuth 
        ? !isTest ? RATECARD_LIST_OTP_ACCOUNT_DIALER : RATECARD_LIST_OTP_DEFAULT_DIALER
        : !isTest ? RATECARD_LIST_ACCOUNT_DIALER : RATECARD_LIST_DEFAULT_DIALER;

    api(request, {
        filter, 
        page, 
        per_page, 
        add: {}
    }).then((response) => {
        if (response !== undefined) {
            dispatch(setPriceList({
            ...(isAuth
                ? {
                    ratecard_list: response.ratecard_otp_list || [],
                    ratecard_count: response.ratecard_otp_count || 0
                }
                : {
                    ratecard_list: response.ratecard_standart_list || [],
                    ratecard_count: response.ratecard_standart_count || 0
                }),
                page,
                per_page
            }));
        }
    });
};

export const setLoadingDataDialer = (load) => (dispatch) => {
    dispatch(setLoading(load));
};

export const exportPriceDialer = (filter = {}, isAuth = false) => async (dispatch) => {
    const isTest = localStorage.getItem('userInfo') 
        ? JSON.parse(localStorage.getItem('userInfo')).session.account.is_test 
        : false;
    
    const request = isAuth
        ? !isTest ? RATECARD_LIST_OTP_ACCOUNT_DIALER : RATECARD_LIST_OTP_DEFAULT_DIALER
        : !isTest ? RATECARD_LIST_ACCOUNT_DIALER : RATECARD_LIST_DEFAULT_DIALER;
    
    dispatch(setUploadLoading(true));
    await getFileResponse(request, {filter})
        .then((response) => createUrl(response, 'prices.csv'))
        .catch( e => e );
    dispatch(setUploadLoading(false));
};

export const getTrunkListDialer = () => (dispatch) => {
    api('trunk:get_list', {}).then((response) => {
        if (response !== undefined)
            dispatch(setTrunkList(response.trunk_list || []));
    });
};

export const allocateByPriceRangeDialer = (trunk_id, pr_key, numbers, random_order) => async (dispatch) => {

    try {
        dispatch(setLoadingAllocatedNumbersDialer(true));
        const response = await api(ALLOCATION_PRICE_RANGE_DIALER_API, {target: {trunk_id}, pr_key, numbers, random_number: random_order});
        dispatch(setLoadingAllocatedNumbersDialer(false));
        if (response.response && response.response.data.error) {
            return response.response.data.error;
        }
        return response;
    } catch {
        dispatch(setLoadingAllocatedNumbersDialer(false));
    }
};