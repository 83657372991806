import {TRAFFIC_STAT_DIALER as PREFIX} from '../../const';
import {ID} from '../../utils/helpers';
import {DEFAULT_PER_PAGE_DIALER} from '../../const';

const initialState = {
    loading: false,
    trafficStat: [],
    count: 0,
    page: 1,
    per_page: DEFAULT_PER_PAGE_DIALER,
    summaries: {}
};


const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {group_list, group_count, page, per_page, group_summaries}}) => ({
        ...state, 
        trafficStat: [
            ...group_list.map(x => {
                x.id = ID();
                return x;
            })
        ], 
        loading: false, 
        count: group_count,
        summaries: {
            ...group_summaries
        },
        page, 
        per_page
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
