import {connect} from 'react-redux';

import {getKeyList, generateNewApiKey} from '../../actions/client/apiPage';

import ApiPage from './ApiPage';
import {LOCAL_STORAGE_USER_INFO_DIALER} from '../../const';

const mapState = ({client_api_page, auth}) => ({
    keyList: client_api_page.items,
    count: client_api_page.count,
    newItem: client_api_page.newItem,
    loading: client_api_page.loading,
    account_id: localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER)).session.account.id :
        auth.info ? auth.info.session.account.id : auth.accountInfo ? auth.accountInfo.session.account.id : null
});

export default connect(mapState,
    {
        getKeyList,
        generateNewApiKey
    })(ApiPage);