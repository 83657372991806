import React from 'react';
import {getFileResponse} from '../../api/loginRoutes';
import {getBlobContent, saveFileBlob, savePdfByTemplate} from '../../utils/helpers';
import {USD_DEFAULT_CURRENCY_KEY} from "../../const";
import Dropdown from 'rsuite/es/Dropdown';
import styled, {css} from 'styled-components';
import {ButtonToolbar, Loader} from 'rsuite';
import Icon from 'rsuite/es/Icon';


export default ({
    cur_key = USD_DEFAULT_CURRENCY_KEY,
    str = '',
    sp_key,
    otpType,
    ...props
}) => {

    const [loading, setLoading] = React.useState(false);

    const exportFile = async () => {
        setLoading(true);

        const method = otpType === null
            ? 'ratecard_standart:get_default_price'
            : 'ratecard_otp:get_default_price';

        const result = await getFileResponse(method, {
            filter:{ str, cur_key, sp_key },
        });

        if(result)
            saveFileBlob(result,'prices.csv');
        setLoading(false)

    };

    const onDownloadPricesPdf = async () => {
        const {account, getTemplate} = props;

        const method = otpType === null
            ? 'ratecard_standart:get_default_price'
            : 'ratecard_otp:get_default_price';

        setLoading(true);
        getTemplate(account.id, 0, 'allocated_numbers').then(({template = {}}) => {
            getFileResponse(method, {filter: {str, cur_key, sp_key},}, true)
                .then((response) => {
                    const csvText = getBlobContent(response);
                    csvText.then((csvContent) => {
                        const csvStringsArray = csvContent ? csvContent.split('\n') : [];
                        const csvDataArrayData = csvStringsArray.filter(value => !!value);
                        const csvHeaders = csvDataArrayData.length && csvDataArrayData[0].split(';');
                        const csvBody = csvDataArrayData.length && csvDataArrayData.slice(1, csvDataArrayData.length).map(line => line.split(';'));

                        savePdfByTemplate(
                            csvBody,
                            [csvHeaders],
                            'prices',
                            template,
                            {},
                            account
                        );
                        setLoading(false)
                    });
                })
        })
    };
    return (
        <StyledButtonToolbar>
            <StyledDropdown
                size="sm"
                title={<>{loading ? <StyledLoader/> : <><Icon icon="upload2"/>Export price</>}</>}
                appearance="default"
                disabled={loading}
                placement="bottomEnd"
            >
                <Dropdown.Item
                    onClick={() => exportFile()}>
                    Export price list
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onDownloadPricesPdf()}>
                    Export price list as pdf
                </Dropdown.Item>
            </StyledDropdown>
        </StyledButtonToolbar>
    )
};
const StyledButtonToolbar = styled(ButtonToolbar)`
    display: inline-block; 
    ${props =>
    props.width && css`
        width: ${props.width};
    `}
`;

const StyledDropdown = styled(Dropdown)`
    height: 30px;
    & .rs-btn {
        padding-left: 10px !important;
    }
    & .rs-loader-spin {
        margin-top: 0 !important;
    }
    & > a {
        padding-top: 5px !important;
        top: 0;
        height: 30px;
        width: 100%;
    }
    & .rs-dropdown-toggle-caret {
        top: 5px;
        margin-top: 0 !important;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;
    }
`;

const StyledLoader = styled(Loader)`
  & span.rs-loader-spin {
    margin-top: 3px;
    margin-right: 5px;
  }
`;