import React, {useEffect, useRef, useState} from "react";
import {Form, FormControl, Message, SelectPicker} from "rsuite";
import {BaseButton} from "../../../components/base/BaseButton";
import { Spacer } from "../../../components/base/Spacer";
import styled from "styled-components";


export default (
    {
        ivrDefault,
        ivrList,
        getIvrList,
        getDefaultIvr,
        changeDefaultIvr
    }
) => {

    const formRef = useRef(null);
    const [value, setValue] = useState({});

    
    useEffect(() => {
        if (!ivrDefault || !ivrDefault.id) {
            setValue({});
            return;    
        }
        
        setValue({ivr_id: ivrDefault.id});
    }, [ivrDefault]);


    useEffect(() => {
        getIvrList();
        getDefaultIvr();
    }, []);


    const handleSubmit = () => {
        if (!value || !value.ivr_id) {
            return;
        }

        changeDefaultIvr({target: {ivr_id: value.ivr_id}});
    }

    return (
        <>
            <Spacer size={30}/>

            <Message
                showIcon
                type="warning"
                description="This IVR will be played if no other is not applicable. Changing this IVR will affect a lot of traffic."
            />

            <Spacer size={20}/>

            <StyledForm
                ref={formRef}
                formValue={value}
                onChange={setValue}
            >
                <FormControl
                    accepter={SelectPicker}
                    name={"ivr_id"}
                    data={ivrList}
                    valueKey="id"
                    labelKey="name"
                    cleanable={false}
                />
                <BaseButton
                    buttonStyle="primary"
                    onClick={handleSubmit}
                >
                    Save
                </BaseButton>
            </StyledForm>
        </>
    )
};


const StyledForm = styled(Form)`
    display: flex;

    .rs-picker-toggle-wrapper {
        width: 100%;
    }

    .rs-form-control-wrapper {
        max-width: 300px;
        margin-right: 20px;
    }
    
    .rs-btn {
        flex-shrink: 0;
        padding-left: 34px;
        padding-right: 34px;
    }
`;