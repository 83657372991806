import React, {useMemo, useEffect, useState} from 'react';
import {
    CheckboxGroup,
    DateRangePicker,
    Form,
    FormGroup,
    SelectPicker,
    FormControl,
    ControlLabel,
    Input
} from "rsuite";
import CustomField from "../../components/Form/CustomField";
import {connect} from "react-redux";
import {getReferencesForReports} from "../../actions/references";
import FormHOC from "../../hoc/FilltersForm";
import {removeNilValues} from "../../utils";
import _ from 'lodash';
import CheckboxBadge from "../../components/Form/CheckboxBadge/CheckboxBadge";
import {ButtonPrimary} from "../../components/base/BaseButton";

const {allowedRange} = DateRangePicker;


const TrafficReportsFilters = ({onApplyFilters, defaultFilters, loading, globalSettings = {}, ...props}) => {
    const [filters, setFilters] = useState(defaultFilters);
    const [grouping, setGrouping] = useState(defaultFilters.group_checkbox);

    const allowedRanges = useMemo(() => {
        const startDate = new Date();
        const endDate = new Date();
        const intervalMonth = globalSettings.cdr_full__interval_month || 0;
        const dateRange =  [
            new Date( startDate.setMonth(startDate.getMonth() - intervalMonth) + (1000 * 3600 * 24) ),
            new Date( endDate.setMonth(endDate.getMonth() + 12) )
        ];
        return [
            `${dateRange[0].getFullYear()}-${dateRange[0].getMonth() + 1}-${dateRange[0].getDate()}`,
            `${dateRange[1].getFullYear()}-${dateRange[1].getMonth() + 1}-${dateRange[1].getDate()}`,
        ]
    }, [globalSettings]);


    const handleChange = (formValues) => {
        let groupCheckboxes = formValues.group_checkbox;
        const radioCheckboxes = ['dt_day', 'dt_month', 'dt_year'];
        const changedCheckbox = _.difference(groupCheckboxes, grouping)[0];
        const valuesWithoutRadio = groupCheckboxes.filter((el) => !radioCheckboxes.includes(el));

        if (changedCheckbox && radioCheckboxes.includes(changedCheckbox)) {
            groupCheckboxes = [...valuesWithoutRadio, changedCheckbox];
        }

        setGrouping(groupCheckboxes);
        setFilters({...formValues, group_checkbox: groupCheckboxes});
    };

    const handleApply = () => {
        onApplyFilters( removeNilValues(filters) );
    };

    useEffect(() => {
        props.getReferencesForReports();
    }, []);

    return (
        <>
            <Form
                layout="inline"
                onChange={handleChange}
                formDefaultValue={defaultFilters}
                formValue={filters}
            >
                <ControlLabel className={'d-block mb-2'}>Filters</ControlLabel>
                <CustomField
                    accepter={DateRangePicker}
                    cleanable={false}
                    placeholder="Select Date Range"
                    disabledDate={allowedRange(...allowedRanges)}
                    name="range"
                    width={250}
                />
                <CustomField
                    accepter={SelectPicker}
                    labelKey="name"
                    valueKey="acc_key"
                    data={props.originatorList}
                    placeholder="Originator"
                    name="originator_key"
                    width={200}
                />
                <CustomField
                    accepter={SelectPicker}
                    data={props.dialerList}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Dialer"
                    name="dialer_id"
                    width={200}
                />
                {/*<CustomField*/}
                {/*    accepter={SelectPicker}*/}
                {/*    data={props.supplierList}*/}
                {/*    labelKey="name"*/}
                {/*    valueKey="acc_key"*/}
                {/*    placeholder="Supplier"*/}
                {/*    name="supplier_key"*/}
                {/*    width={200}*/}
                {/*    disabled={true}*/}
                {/*/>*/}
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    placeholder="Area Origin"
                    name="a_sde_key"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={200}
                    data={props.subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    placeholder="Area Dest"
                    name="b_sde_key"
                />
                <CustomField
                    accepter={Input}
                    width={150}
                    placeholder="A-Number"
                    name="a_number"
                />
                <FormGroup>
                    <ButtonPrimary
                        style={{
                            padding: '8px 34px',
                            marginLeft: 12
                        }}
                        onClick={handleApply}
                        disabled={loading}
                    >
                        Show
                    </ButtonPrimary>
                </FormGroup>
                <br/>
                <FormGroup>
                    <ControlLabel className={'d-block'}>Group by</ControlLabel>
                    <FormControl
                        name="group_checkbox"
                        accepter={CheckboxGroup}
                        inline
                        value={grouping}
                    >
                        <CheckboxBadge value="a_number">A-Number</CheckboxBadge>
                        <CheckboxBadge value="b_number">B-Number</CheckboxBadge>
                        <CheckboxBadge value="range_name">Range</CheckboxBadge>
                        <CheckboxBadge value="originator_key">Originator</CheckboxBadge>
                        <CheckboxBadge value="dialer_key">Dialer</CheckboxBadge>
                        <CheckboxBadge value="supplier_key" wrapperClassName={'mr-4'}>Supplier</CheckboxBadge>
                        <CheckboxBadge value="dt_day">Day</CheckboxBadge>
                        <CheckboxBadge value="dt_month">Month</CheckboxBadge>
                        <CheckboxBadge value="dt_year">Year</CheckboxBadge>
                        <br/>
                        <CheckboxBadge value="a_wz_key">Zone (origin)</CheckboxBadge>
                        <CheckboxBadge value="b_wz_key">Zone (dest)</CheckboxBadge>
                        <CheckboxBadge value="a_de_key">Dest (origin)</CheckboxBadge>
                        <CheckboxBadge value="b_de_key">Dest (dest)</CheckboxBadge>
                        <CheckboxBadge value="a_sde_key">Area (origin)</CheckboxBadge>
                        <CheckboxBadge value="b_sde_key" >Area (dest)</CheckboxBadge>
                        <CheckboxBadge value="margin" >Margin</CheckboxBadge>
                    </FormControl>
                </FormGroup>
            </Form>
        </>
    );
};

const mapStateToProps = ({references, traffic_reports}) => ({
    loading: traffic_reports.loading,
    originatorList: references.client_list,
    dialerList: references.dialer_list,
    supplierList: references.supplier_list,
    subdestinationList: references.subdestination_list,
});

const mapDispatchToProps = {
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHOC(TrafficReportsFilters));