import React from "react";
import {useIntl} from "react-intl";
import {useWindowWidth} from "../../hooks";
import {Form} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import FieldDataRange from "../../components/Form/FieldDataRange";
import m from "../../definedMessages";
import {BaseDateRangePicker} from "../../components/base/BaseForm";


export default ({filters, onChangeFilters}) => {

    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();

    return (
        <Form
            formDefaultValue={filters}
            onChange={(value) => {
                onChangeFilters(value);
            }}
        >
            <FlexGrid align="middle">
                {(resizedWidth > 1005) && 
                    <FlexGridItem>{formatMessage(m.dateFrom)}</FlexGridItem>
                }

                <FlexGridItem>
                    <FieldDataRange
                        name="start_end_date"
                        accepter={BaseDateRangePicker}
                    />
                </FlexGridItem>
            </FlexGrid>
        </Form>
    )
}