import React, {useEffect, useState} from "react";
import PaymentsRequestSection from "./PaymentsRequestSection";
import PaymentsListSection from "./PaymentsListSection";
import PaymentsTrafficReportsSection from "./PaymentsTrafficReportsSection";
import {Spacer} from "../../components/base/Spacer";
import {LOCAL_STORAGE_USER_INFO_DIALER} from "../../const";
import ModalRequestPayment from "./ModalRequestPayment";
import ModalAddPayment from "../PaymentDetails/ModalAddPaymentDetail";
import PanelLayout from "../../components/base/PanelLayout";
import { PAYMENT_DETAIL_LIST_API } from "const/apiMethods";
import { checkPermissionsFor } from "store/storeHelpers";

export default ({
    permissions,

    paymentItems,
    paymentItemsLoading,

    paymentRequestItems,
    paymentRequestLoading,
    
    paymentTrafficReportsItems,
    paymentTrafficReportsLoading,

    paymentDetailsItems,
    accountInfo,
    readyForPayment,

    organizationTypeList,
    countryList,
    paymentMethodList,
    globalSettings,

    // API methods
    getPaymentRequestList,
    requestPayment,
    cancelPaymentRequest,
    getPaymentList,
    getTrafficReportsList,
    getPaymentDetailsList,
    createPaymentDetails,
    modifyPaymentDetails,
    setReadyForPayment,
}) => {

    // why do we store an account_id information only in the LocalStorage??
    const accountInfoStorage = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
    const parsedAccountInfo = accountInfoStorage !== null ? JSON.parse(accountInfoStorage) : {
        session: false
    };
    const accountId = parsedAccountInfo && parsedAccountInfo.session 
        ? parsedAccountInfo.session.account.id 
        : null;

    const [showModalRequestPayment, setShowModalRequestPayment] = useState(false);
    const [requestPaymentFormValue, setRequestPaymentFormValue] = useState({amount: 0});
    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(true);
    const [amountLimitMin, setAmountLimitMin] = useState(0.01);


    // effect: get list on component mount
    useEffect( () => {
        if (checkPermissionsFor(PAYMENT_DETAIL_LIST_API)) {
            const data = {target: {account_id: accountId}};
            getPaymentDetailsList(data);
        }
    }, [permissions] );


    // set payment detail amount min limit
    useEffect( () => {
        if (!requestPaymentFormValue.payment_detail_id || !paymentMethodList.length || !paymentDetailsItems.length) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentDetail = paymentDetailsItems.find(item => item.id === requestPaymentFormValue.payment_detail_id);
        if (!currentPaymentDetail) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentMethod = paymentMethodList.find(item => item.pm_key === currentPaymentDetail.pm_key);
        const currentPaymentMethodLimit = currentPaymentMethod && currentPaymentMethod.limit
            ? currentPaymentMethod.limit
            : 0.01;
        setAmountLimitMin(currentPaymentMethodLimit);
    }, [requestPaymentFormValue, paymentMethodList, paymentDetailsItems] );

    const openModalRequestPayment = () => {
        setShowModalRequestPayment(true);
    };

    const closeModalRequestPayment = () => {
        setShowModalRequestPayment(false);
    };

    const handleShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
        setDropdownVisibility(false);
    };

    const closeModalAddPaymentDetail = (value) => {
        if (value) {
            setRequestPaymentFormValue({
                ...requestPaymentFormValue,
                payment_detail_id: value.payment_detail.id
            });
        }
        setShowModalAddPaymentDetail(false);
        setDropdownVisibility(true);
    };

    const changeReadyForPayment = (amount) => {
        setReadyForPayment(+readyForPayment - amount);
    };

    const {currency_name: currencyName} = accountInfo || {};

    return (
        <PanelLayout>
            <PaymentsRequestSection
                {...{
                    permissions,
                    paymentRequestItems, 
                    paymentRequestLoading, 
                    cancelPaymentRequest,
                    getPaymentRequestList,
                    openModalRequestPayment,
                    accountId,
                    currencyName,
                    readyForPayment,
                    setReadyForPayment
                }}
            />
            
            <Spacer size={30}/>
            <PaymentsListSection
                {...{
                    permissions,
                    paymentItems,
                    paymentItemsLoading,
                    currencyName,
                    getPaymentList,
                    accountId,
                }}
            />

            <Spacer size={30}/>
            <PaymentsTrafficReportsSection
                {...{
                    permissions,
                    paymentTrafficReportsItems, 
                    paymentTrafficReportsLoading,
                    openModalRequestPayment,
                    currencyName,
                    globalSettings,
                    getTrafficReportsList,
                    accountId,
                }}
            />


            <ModalRequestPayment
                show={showModalRequestPayment}
                onClose={closeModalRequestPayment}
                formValue={requestPaymentFormValue}
                setFormValue={setRequestPaymentFormValue}
                amountLimit={readyForPayment}
                amountLimitMin={amountLimitMin}
                onSuccess={changeReadyForPayment}
                {...{
                    accountId,
                    currencyName,
                    readyForPayment,
                    requestPayment,
                    paymentDetailsItems, 
                    getPaymentRequestList,
                    dropdownVisibility,
                    handleShowModalAddPaymentDetail
                }}
            />

            <ModalAddPayment
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                {...{
                    organizationTypeList,
                    countryList,
                    paymentMethodList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    getPaymentDetailsList
                }}
            />

        </PanelLayout>
    )
};