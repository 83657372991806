import { apiFormData } from '../../../api/loginRoutes';
import {IVR_SOUND_CREATE_API, IVR_SOUND_MODIFY_API} from 'const/apiMethods';
import React, {useRef, useState} from 'react';
import {Form, FormControl, Input, Uploader, ControlLabel} from 'rsuite';
import Modal from "../../../components/Modal";
import styled from "styled-components";

export default ({
    show,
    data,
    onClose,
    disabled,
    activeSoundId,
    getIvrSoundList,
}) => {

    const formRef = useRef();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        setLoading(true);

        const dt = new DataTransfer();
        dt.items.add(files[0].blobFile);

        const formValue = formRef.current.getFormValue();
        const params = {...formValue, files: dt.files};

        console.log(params);

        let method = activeSoundId 
            ? IVR_SOUND_MODIFY_API
            : IVR_SOUND_CREATE_API;

        if (activeSoundId) {
            params.target = {
                ivr_sound_id: activeSoundId
            }
        }

        apiFormData(method, params)
            .then(r => {
                if (!r)
                    return;

                getIvrSoundList();
                handleClose();
            })
            .finally(() => {
                setLoading(false);
            });
        
    };

    const handleClose = () => {
        setFiles([]);
        onClose();
    };

    return (
        <>
            <Modal
                show={show}
                footer
                data={data}
                disabled = {disabled}
                onSuccess={handleSubmit}
                onClose={handleClose}
                successText={"Add"}
                title={"Add new sound"}
                loading={loading}
            >
                <Form
                    ref={formRef}
                >
                    <div>
                        <ControlLabel>Choose File</ControlLabel>
                        <FormControl
                            accepter={StyledUploader}
                            fileList={files}
                            autoUpload={false}
                            multiple={false}
                            removable={false}
                            accept="audio/mpeg,audio/wave,audio/wav,audio/x-wav,audio/x-pn-wav"
                            onChange={(files) => {
                                setFiles([files[files.length - 1]]);
                            }}
                        />
                    </div>

                    <div>
                        <ControlLabel>File Name</ControlLabel>
                        <FormControl
                            accepter={Input}
                            name="name"
                        />
                    </div>
                </Form>
            </Modal>
        </>
    )
};


const StyledUploader = styled(Uploader)`
    .rs-uploader-file-item.rs-uploader-file-item-text {
        display: block !important;
    }
`;