import {
    ACCOUNT_NOTE_LOADING,
    ACCOUNT_NOTE_LIST,
} from "../const";

import {
    ACCOUNT_NOTE_GET_LIST_API,
    ACCOUNT_NOTE_DEACTIVATE_API,
    ACCOUNT_NOTE_CREATE_API,
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setAccountNoteList = createAction(ACCOUNT_NOTE_LIST);
const setAccountNoteLoading = createAction(ACCOUNT_NOTE_LOADING);


// get list
export const getAccountNoteList = (accountId) => async (dispatch) => {
    dispatch( setAccountNoteLoading(true) );

    await api(ACCOUNT_NOTE_GET_LIST_API, {
        target: {account_id: accountId}
    })
        .then((response) => {
            if (!response)
                return;

            dispatch( setAccountNoteList({
                list: [...response.account_note_list] || [],
                count: response.account_note_count
            }) );
        })
        .finally(() => {
            dispatch( setAccountNoteLoading(false) );
        })
};

export const removeAccountNote = (accountId, accountNoteId) => async () => {
    return await api(ACCOUNT_NOTE_DEACTIVATE_API, {
        account_note_key: accountNoteId,
        target: {account_id: accountId}
    });
};

export const createAccountNote = (accountId, data) => async () => {
    return await api(ACCOUNT_NOTE_CREATE_API, {
        ...data,
        target: {account_id: accountId}
    });
};

