import styled from 'styled-components';
import {Table} from 'rsuite';

const {Cell} = Table;

export const CustomCell = styled(Cell)`
  .duplicates_badge {
    position: absolute;
    top: 14px;
    left: 5px;
  }
`;