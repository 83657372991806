import React, {useState, useEffect, createRef, useRef} from 'react'
import {useWindowWidth} from "../../hooks";
import { connect } from "react-redux";
import {
    registerCreate
} from "../../actions/registration";
import {getAllReferences} from '../../actions/references';
import {parse} from 'object-flaser';
import {Form,  Schema, Divider, Alert} from "rsuite";
import Modal from "../../components/Modal";
import * as S from "./styled";
import Recaptcha from 'react-google-invisible-recaptcha';

import {
    FieldInput, FieldNumber,
    FieldSelect,
    FieldSelectComment
} from './RegistrationField'

import fields, {
    selectableFields,
    registrationFormFields
} from "./RegistrationFormFields";
import {CustomModalClient} from "../../components/base";

const {StringType, NumberType} = Schema.Types;


const RegistrationModal = ( ({
    show=false,
    onClose,
    maxWidth = 1000,
    defaultFormValue = null,
    countryList,
    currencyList,
    registerCreate,
    getAllReferences,
}) => {

    // const recaptchaState = ReCAPTCHA.
    const formModel = Schema.Model({
        // type fields
        name: StringType().maxLength(256, 'Max length exceeded')
            .isRequired( 'This field is required'),
        skype: StringType().maxLength(256, 'Max length exceeded')
            .isRequired( 'This field is required'),
        email: StringType().isEmail().maxLength(1024, 'Max length exceeded')
            .isRequired( 'This field is required'),
        cntr_code: StringType().isRequired( 'This field is required'),
        comment:  StringType().maxLength(40, 'Max length exceeded'),
        month_traffic_minute: NumberType().min(1, 'Min amount exceeded').max(99999, 'Max amount exceeded'),
        cur_key: NumberType(),
        traffic_type: NumberType()
    });

    const formData = useRef(null);
    let formRef = null;
    let recaptchaRef = useRef(null);

    const [formState, setFormValue] = useState(defaultFormValue ? defaultFormValue : {});
    const [trafficState, onChangeTraffic] = useState(0);
    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);
    const [verifyToken, setVerifyCaptcha] = useState(null);
    const [registerBody, setRegisteredBody] = useState(null);

    // effect: change form data by defaults
    useEffect(() => {
        getAllReferences()
    }, []);

    useEffect(() => {
        if (defaultFormValue) {
            setFormValue({...defaultFormValue});
        }
    }, [defaultFormValue]);

    useEffect(() => {
        if (verifyToken && registerBody) {


        }
        setLoading(false);
    }, [verifyToken, registerBody])

    const handleFormSubmit = () => {
        const formValues = formRef.getFormValue();

        if (formValues && Object.keys(formValues).length) {

            const fieldsValidity = [];
            for (const fieldName of registrationFormFields) {
                fieldsValidity.push(formRef.checkForField(fieldName));
            }
            if (fieldsValidity.includes(false)) {
                if (recaptchaRef !== null)
                    recaptchaRef.current.reset()
                return;
            }

            setLoading(true);

            const formValue = {...formValues, month_traffic_minute: trafficState || 0};
            formData.current = formValue;
            setRegisteredBody(formValue);

            if (recaptchaRef !== null) {
                recaptchaRef.current.execute();
            }
        }
    };


    const renderFields = (fieldNames = [], formValue) => (

        fieldNames.map((fieldName) => {
            const {type, name, label, labelId, hideOn, ...props} = fields[fieldName];

            // hide on option
            if (hideOn && Array.isArray(hideOn)) {
                for (const conditionItem of hideOn) {
                    const keys = Object.keys(conditionItem || {});
                    const status = keys.map(key => {
                        return formValue[key] && formValue[key] === conditionItem[key];
                    });

                    if (!status.includes(false))
                        return null;
                }
            }

            // get label
            switch (type) {
                case 'select_cntr_code':
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        data={countryList}
                        labelKey="name"
                        valueKey="cntr_code"
                    />;
                case 'select_cur_key':
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        searchable={false}
                        data={currencyList}
                        labelKey="name"
                        valueKey="cur_key"
                        {...props}
                    />;
                case 'select_comment':
                    return <FieldSelectComment
                        key={name}
                        name={name}
                        label={label}
                        searchable={false}
                        data={selectableFields[type]}
                        labelKey="name"
                        valueKey="id"
                        {...props}
                    />;
                case 'select_traffic_type':
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        searchable={false}
                        data={selectableFields[type]}
                        labelKey="name"
                        valueKey="id"
                        {...props}
                    />;
                case 'integer':
                    return (<S.GroupNumber>
                        <FieldNumber
                            key={name}
                            name={name}
                            label={label}
                            onChange={(value) => {onChangeTraffic(parseInt(value))}}
                            {...props}
                        />
                    </S.GroupNumber>);
                default:
                    return <FieldInput key={name} name={name} label={label} {...props} />
            }
        })
    );

    const clearForm = () => {
        setFormValue({});
    };

    const cleanErrors = () => {
        if (formRef) {
            formRef.cleanErrors();
        }
    };

    const onResolveCaptcha = (token) => {
        setVerifyCaptcha(token)
        return new Promise((resolve, reject) => {
            if (token) {
                resolve()
            } else {
                reject()
            }
        })
    }

    return ( <CustomModalClient
            {...{show, onClose}}
            title="Registration form"
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={false}
            confirmButtonText="Success"
            loading={loading}
            keyboard={false}
            onClose={() => {
                cleanErrors()
                clearForm();
                if (recaptchaRef)
                    recaptchaRef.current.reset()
                onClose(false);
            }}
        >
            <S.FormModalWrapper>
                <Form
                    ref={ref => formRef = ref}
                    model={formModel}
                    formValue={formState}
                    onChange={setFormValue}
                >
                    <S.FormColumns>
                        <>
                            <S.FormColumn colWidth="50%">
                                {renderFields(registrationFormFields, formState)}
                            </S.FormColumn>
                            <Divider style={{width: 0}} vertical />
                        </>
                    </S.FormColumns>
                    <S.RegistrationButtonWrapper>
                        <S.RegistrationSubmitButton onClick={handleFormSubmit} appearance="primary" type="submit">Submit</S.RegistrationSubmitButton>
                        {resizedWidth < 1200 ? <S.RegistrationCancelButton onClick={() => {onClose()}}>Close</S.RegistrationCancelButton> : null}
                    </S.RegistrationButtonWrapper>
                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onResolved={ (token) => {
                            const promise = onResolveCaptcha(token);
                            promise.then(() => {
                                const params = formData.current || {};
                                registerCreate(parse({...params, token})).then((response) => {
                                    if (response) {
                                        if (response.code && response.code === 1) {
                                            Alert.error("Bot verification failed")
                                        } else if (response.code && response.code > 1) {
                                            Alert.error("No permissions");
                                        } else if ( response.reg_key) {
                                            Alert.success("Registration request has been sent")
                                        }
                                        cleanErrors();
                                        clearForm();
                                        onClose(false);
                                    }
                                    if (recaptchaRef)
                                        recaptchaRef.current.reset()
                                });
                            }, () => {
                                Alert.error("Bot verification failed")
                                if (recaptchaRef)
                                    recaptchaRef.current.reset()
                            })
                        } }
                    />
                </Form>
            </S.FormModalWrapper>
        </CustomModalClient>
    )
} );

const mapState = ({references}) => ({
    countryList: references.country_list,
    currencyList: references.currency_list
});

export default connect(mapState, {
    registerCreate,
    getAllReferences
})(RegistrationModal);