export const formatDateApi = (datestr) => {
    return  datestr.
            replace(/T/, ' ').
            replace(/-/g, '/').
            replace(/\..+/, '')
            // .
            // slice(0,-3);
}


export const  formatDateApiWithoutTime  = (datestr) => {
    return  datestr.
            replace(/T/, ' ').
            replace(/-/g, '.').
            // replace(/\..+/, '')
            slice(0,-9);
};

export const splitList = (str) => {
    const replaseStr = str.replace( /,/g,' ')
        .replace( /\r/g,' ')
        .replace( /\n/g,' ');
    return replaseStr.split(' ').filter( s => s !== "");
};

export const convertDataInColumn = (data, key, filter) => {
    return data.map(column => {
        if (column.hasOwnProperty(key)) {
            return filter(column[key], column, key)
        } else {
            return column
        }
    })
};