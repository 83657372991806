import React from 'react';
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import {Alert} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonClose as Revoke} from "../../../components/base/BaseButton";


export default ({getList, disabled, update}) => {
    const [show, setShow] = React.useState(false);
    const [_disabled, setDisabled] = React.useState(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps

    const onSubmit = async () => {
        setDisabled(true);
        const numbersKeysList = getList().list;

        const result = await api('price_range_number:set_block_allocation_list', {
            prn_key_list: numbersKeysList
        });

        if (result && result.price_range_numbers) {
            Alert.success(`${result.price_range_numbers} number were blocked`);
            setShow(false);
            update();
        }
        setDisabled(false);

    };
    return (
        <>
            <Revoke
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                <Icon icon="lock"/> Block
            </Revoke>
                {show && <Modal
                    show={show}
                    title="Allocate numbers"
                    onClose={() => setShow(false)}
                    footer={true}
                    successText="Confirm"
                    disabled={_disabled}
                    onSuccess={onSubmit}
                >
                    Selected numbers will be set as blocked. It means you can't allocate these numbers to any dialer or set test
                </Modal>
            }
        </>

    );
}
