import { combineReducers } from 'redux';
import accounts from './accounts';
import settings from './setting';
import auth from './auth';
import references from './references';
import access_list from './access_list'
import live_calls from "./live_calls";
import prices from "./prices";
import ranges from "./ranges";
import numbers from "./numbers";
import roles from './roles';
import cdr from './cdr';
import traffic_reports from "reducers/traffic_reports";
import auth_numbers from './auth_numbers';
import apiPage from './apiPage';
import payments from './payments';
import paymentDetails from './paymentDetails';
import sim_traffic_ranges from './sim_traffic_ranges';
import blocked_destinations from './blocked_destinations';
import global_setting from './global_setting';
import page_logger from "./page_logger";
import action_logs from "./action_logs";
import google_otp_quarantined from "./google_otp_quarantined";
import google_otp_allocation from "./google_otp_allocation";
import am_activities from "./am_activities";
import account_managers from "./account_managers";
import special_limit_terms from "./special_limit_terms";
import account_note from "./account_note";
import registration from "./registration";
import ivr from "./ivr";
import ivr_cli from "./ivr_cli";
import ivr_sounds from "./ivr_sounds";
import ivr_default from "./ivr_default";

// client
import auth_dialer from "./auth_dialer";
import client_numbers from "./client/numbers";
import client_test_calls from "./client/test_calls";
import client_live_calls from "./client/live_calls";
import client_cdr from "./client/cdr";
import client_traffic_stat from "./client/traffic_stat";
import client_price from "./client/price";
import client_accesses from "./client/accesses";
import client_cli from "./client/cli";
import client_api_page from "./client/api_page";


// simple
import dropdown_accounts from "./dropdown_accounts";


export default combineReducers({
    apiPage,
    accounts,
    settings,
    auth,
    references,   
    access_list,
    live_calls,
    prices,
    ranges,
    numbers,
    roles,
    cdr,
    global_setting,
    blocked_destinations,
    traffic_reports,
    auth_numbers,
    payments,
    paymentDetails,
    page_logger,
    sim_traffic_ranges,
    action_logs,
    google_otp_quarantined,
    google_otp_allocation,
    am_activities,
    account_managers,
    special_limit_terms,
    account_note,
    registration,
    ivr,
    ivr_cli,
    ivr_sounds,
    ivr_default,

    // client
    auth_dialer,
    client_numbers,
    client_test_calls,
    client_live_calls,
    client_cdr,
    client_traffic_stat,
    client_price,
    client_accesses,
    client_cli,
    client_api_page,

    // simple
    dropdown_accounts
});