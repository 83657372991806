export function  AccountCreate (form){
    this.active = true;
    this.description = 'Description is required? Realy??? You dont read task!';
    this.is_dailer = true;
    this.is_supplier = false;
    this.permission_list = [];

    form.allocate_number && this.permission_list.push('allocate_number');
    form.allocate_pattern && this.permission_list.push('allocate_pattern');
    form.google_otp_allocation && this.permission_list.push('google_otp_allocation');
    form.google_otp_autorevoke && this.permission_list.push('google_otp_autorevoke');
    this.trunk_notificaton = form.trunk_notificaton;

    this.name  = form.name;
    this.cur_key = form.cur_key;
    this.pt_key = form.pt_key;
    this.call_duration_max = form.call_duration_max ? Number.parseInt(form.call_duration_max) : 3600;
    // this.account_manager_id = form.account_manager_id;
    // this.CONTACT_LIST  = form.contacts;
}
