import {
    NUMBERS_DIALER as PREFIX,
    SET_ALL_ALLOCATED_NUMBER_DIALER,
    SET_ALLOCATED_NUMBER_DIALER,
    SET_TRUNK_NUMBER_DIALER,
    SET_TRUNK_NUMBER_ALLOCATED_LOADING_DIALER,
    SET_TRUNK_NUMBER_LOADING_DIALER,
    SET_LOADING_NUMBERS_ALLOCATED_NUMBERS_DIALER,
    SET_UPLOAD_TRUNK_LOADING_DIALER
} from '../../const';

import {
    TEMPLATE_GET,
    ALLOCATION_NUMBER_LIST_DIALER_API,
    ALLOCATION_TEMPLATE_DIALER_API,
    ALLOCATION_GOOGLE_OTP_DIALER_API
} from "../../const/apiMethods";


import {api, getFileResponse, getCsvResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {createUrl, getBlobContent, saveFileBlob} from '../../utils/helpers';
import {SET_TRUNK_NUMBER_TRANSACTION_DIALER} from '../../const';

const setViewNumberTrunk = _setValue(SET_TRUNK_NUMBER_DIALER);
const setNumberTrunkLoading = _setValue(SET_TRUNK_NUMBER_LOADING_DIALER);
const setViewNumberAllocatedLoading = _setValue(SET_TRUNK_NUMBER_ALLOCATED_LOADING_DIALER);
const setViewNumberAllocated = _setValue(SET_ALLOCATED_NUMBER_DIALER);
const setAllNumberAllocated = _setValue(SET_ALL_ALLOCATED_NUMBER_DIALER);
export const setLoadingAllocatedNumbers = _setValue(SET_LOADING_NUMBERS_ALLOCATED_NUMBERS_DIALER);
const uploadLoading = _setValue(SET_UPLOAD_TRUNK_LOADING_DIALER);

export const setAllocatedNumbersByNumberList = _setValue(ALLOCATION_NUMBER_LIST_DIALER_API);
export const setAllocatedNumbersByTemplate = _setValue(ALLOCATION_TEMPLATE_DIALER_API);
export const setAllocatedGoogleOTPNumbers = _setValue(ALLOCATION_GOOGLE_OTP_DIALER_API);

const trunkNumberTransaction = _setValue(SET_TRUNK_NUMBER_TRANSACTION_DIALER);

export const setTrunkNumberTransaction = (transactionObject) => (dispatch) => {
    dispatch(trunkNumberTransaction(transactionObject))
};

export const setUploadingLoading = (trunk_id, state) => (dispatch) => {
    dispatch(uploadLoading({[trunk_id]: state}));
};

export const getNumberTrunksList = () => (dispatch) => {

    dispatch(setNumberTrunkLoading(true));
    api('trunk:get_list', {}).then((response) => {
        if (response !== undefined)
            dispatch(setViewNumberTrunk(response.trunk_list || []));
    });

};

export const getTrunkNumbers = (trunk_id, per_page = 12, filter = {}, page = 1, group = "trunk_number") => (dispatch) => {
    dispatch(setViewNumberAllocatedLoading(true));
    api('trunk_number__get_list', {
        target: {
            trunk_id: trunk_id
        },
        // add: {},
        page,
        filter,
        group,
        per_page
    })
        .then((response) => {
            if (response !== undefined) {
                const {
                    trunk_number_list,
                    group_price_range_list,
                    group_subdestination_list,
                    trunk_number_count,
                    group_price_range_number_count,
                    group_subdestination_number_count,
                    row_count,
                    group_price_range_count,
                    group_subdestination_count
                } = response;

                dispatch(setViewNumberAllocated({
                    items: (trunk_number_list || group_price_range_list || group_subdestination_list || [])
                        .map(x => {
                            if(group_price_range_list) {
                                x.pr_key = x.price_range.pr_key;
                                x.sde_key = x.price_range.sde_key;
                                x.sp_key = x.price_range.sp_key;
                                x.subdestination_name = x.price_range.subdestination_name;
                            }

                            return x;
                        }),
                    trunk_number_count: trunk_number_count || group_price_range_number_count || group_subdestination_number_count || 0,
                    row_count: row_count || group_price_range_count || group_subdestination_count || 0,
                    page,
                    per_page
                }));
            }
        });
};

export const getAllAllocatedNumbers = (trunk_id) => (dispatch) => {
    api('trunk_number__get_list', {
        target: {trunk_id: trunk_id},
        // add: {price_range: {}},
        page: 1,
        filter: {},
        per_page: 1000
    }).then(
        (response) => {
            if (response !== undefined)
                dispatch(setAllNumberAllocated({trunk_number_list: response.trunk_number_list || []}));
        });
};


export const downloadNumbers = (trunk_id) => async (dispatch) => {
    dispatch(uploadLoading({[trunk_id]: true}));

    await getFileResponse('trunk_number:get_list', {
        target: {
            trunk_id: trunk_id
        }
    })
        .then((response) => {
            const csvText = getBlobContent(response);

            csvText.then(csvBody => {
                console.log("csv22", csvBody);
                const csvHeaderList = csvBody.split('\n')[0].split(';');
                const csvDataList = csvBody.split('\n').slice(1).map(line => {
                    const items = line.split(';');
                    return [items[0], ...items.slice(2)].join(';')
                });
                const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(2)].join(';');
                csvDataList.unshift(csvHeaders);
                const csvData = csvDataList.join('\n');

                saveFileBlob(csvData, 'allocated_numbers.csv')
            });
        });
    dispatch(uploadLoading({[trunk_id]: false}));
};

export const downloadFilteredNumbers = (trunkId, filters) => async (dispatch) => {
    dispatch(uploadLoading({[trunkId]: true}));
    await getFileResponse('trunk_number__get_list', {
        target: {
            trunk_id: trunkId
        },
        filter: {...filters}
    })
        .then((response) => {
            createUrl(response, 'allocated_numbers.csv')
        });

    dispatch(uploadLoading({[trunkId]: false}));
};


// thunk: Allocate by template
export const allocateTrunkNumbersByTemplate = (trunk_id, template, numbers, random_order) => async (dispatch) => {
    try {
        dispatch( setLoadingAllocatedNumbers(true) );
        const response = await api(ALLOCATION_TEMPLATE_DIALER_API, {target: {trunk_id}, template, numbers, random_number: random_order});

        dispatch( setLoadingAllocatedNumbers(false) );
        if (response && response.response && response.response.data.error) {
            return response.response.data.error;
        }

        return response;
    } catch (e) {
        dispatch( setLoadingAllocatedNumbers(false) );
    }
};


// thunk: Allocate by number list
export const allocateTrunkNumbersByNumberList = (trunk_id, number_list) => async (dispatch) => {
    try {
        dispatch(setLoadingAllocatedNumbers(true));
        const response = await api(ALLOCATION_NUMBER_LIST_DIALER_API, {target: {trunk_id}, number_list});

        // if (response !== undefined && response.reason_code) {
        // dispatch(setLoadingAllocatedNumbers(false));
        // }

        dispatch( setLoadingAllocatedNumbers(false) );
        if (response && response.response && response.response.data.error) {
            return response.response.data.error;
        }

        return response;
    } catch {
        dispatch(setLoadingAllocatedNumbers(false));
    }
};


// trunk: Allocate Google OTP
export const allocateGoogleOTPTrunkNumbers = ({trunk_id, numbers, ...data}) => async (dispatch) => {

    try {
        dispatch( setLoadingAllocatedNumbers(true) );
        const params = {
            ...data,
            target: {trunk_id},
            numbers: +numbers
        };

        if (params.template) {
            params.template_list = [params.template];
            delete params.template;
        }
        const response = await api(ALLOCATION_GOOGLE_OTP_DIALER_API, params);

        dispatch( setLoadingAllocatedNumbers(false) );
        if (response && response.response && response.response.data.error) {
            return response.response.data.error;
        }

        return response;
    } catch (e) {
        dispatch( setLoadingAllocatedNumbers(false) );
    }
};

export const getTemplate = (account_id, type, table_name) => async () => {
    return await api(TEMPLATE_GET, {target: {account_id}, type, table_name});
};