import React, {useRef} from "react";
import {Form, SelectPicker} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce} from "lodash";
import CustomField from "../../components/Form/CustomField";

export default ({
        defaultFilter = {},
        subdestinationList = [],
        onChangeFilter,
    }) => {

    const formRef = useRef(null);

    const handleChange = debounce( (value) => {
        if ( !formRef.current.check() )
            return;
        onChangeFilter(value);
    }, 500);

    return (
        <>
            <Form
                ref={formRef}
                defaultFormValue={defaultFilter}
                onChange={handleChange}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        <CustomField
                            accepter={SelectPicker}
                            width={300}
                            data={subdestinationList}
                            labelKey="name"
                            valueKey="sde_key"
                            placeholder="Subdestination"
                            name="sde_key"
                        />
                    </FlexGridItem>
                </FlexGrid>
            </Form>
        </>
    )
};