import React from 'react';
import {Alert, Loader, Placeholder} from 'rsuite';
import CreateForm from './CreateForm';
import PageHeader from '../../components/base/PageHeader';
import PanelLayout from '../../components/base/PanelLayout';
import {api} from '../../api/loginRoutes';
import {withRouter} from 'react-router-dom';
import {ACCOUNT_CREATE_METHOD} from '../../const/apiMethods';
import {checkPermissionsFor} from '../../store/storeHelpers';
import {getAccountManagersList} from "../../actions/account_managers";

const {Paragraph} = Placeholder;
const USER_NAME_ALREADY_USED_CODE = 4;

class NewAccount extends React.Component {
    formDefaultValue = {
        account_manager_id: null,
        cur_key: null,
        pt_key: null,
        trunk_notificaton: 'all_numbers_trunk'
    };

    state = {
        disabled: false,
    };

    constructor(props) {
        super(props);

        this.setUpFormDefaultValues(props);
    }

    setUpFormDefaultValues(props) {
        const {currency_list, payment_terms_list, account_manager_list, registeredAccount} = props;

        if (payment_terms_list.length)
            this.formDefaultValue.pt_key = payment_terms_list[0].pt_key;

        if (account_manager_list.length)
            this.formDefaultValue.account_manager_id = account_manager_list[0].id;

        if (Object.keys(registeredAccount)) {
            if (registeredAccount.cur_key)
                this.formDefaultValue.cur_key = registeredAccount.cur_key;

            if (registeredAccount.name && registeredAccount.email && registeredAccount.name) {
                const person = registeredAccount.name;
                const email = registeredAccount.email;
                const messenger = registeredAccount.skype;
                this.formDefaultValue.users = [{name: person, email}];
                this.formDefaultValue.contacts = [{person, email, messenger}];
            }
        } else {
            if (currency_list.length)
                this.formDefaultValue.cur_key = currency_list[0].cur_key;
        }
        console.log(this.formDefaultValue);
    }

    checkData = () => {
        return !!Object.keys(this.formDefaultValue).filter(
            x => this.formDefaultValue[x] === null
        ).length;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.setUpFormDefaultValues(nextProps);

        return true;
    }

    onCancel = () => {
        this.props.history.goBack();
    };

    onSubmit = async (data, account_manager_id, users, contacts = [], reg_key) => {
        this.setState({disabled: true});

        try {
            const result = await api(ACCOUNT_CREATE_METHOD, {
                ...data,
                target: {account_manager_id}
            }, true);
            this.setState({disabled: false});

            if (result && result.account) {

                if (!checkPermissionsFor("account_user:create") || !checkPermissionsFor("account_contact:create")) {
                    this.props.history.replace('/reload');
                    this.props.history.push({
                        pathname: `/accounts/view/${result.account.id}`,
                        search: '?tab=general'
                    });
                } else {
                    await Promise.all([
                        ...users
                            .map(
                                (user) => api('account_user:create', {
                                    ...user,
                                    ...(reg_key ? {reg_key} : {}),
                                    target: {
                                        account_id: result.account.id
                                    }
                                })
                            ),
                        ...contacts
                            .map(
                                (contact) => api('account_contact:create', {
                                    ...contact,
                                    target: {account_id: result.account.id}
                                }, true)
                            )
                        ])
                        .finally(() => {
                            this.props.history.replace('/reload');
                            this.props.history.push({
                                pathname: `/accounts/view/${result.account.id}`,
                                search: '?tab=general'
                            });
                        });
                    }
            } else {
                if (result.error.code === USER_NAME_ALREADY_USED_CODE) {
                    Alert.error('Account name already exists, please change to another');
                }
                this.setState({disabled: false});
            }
        } catch (e) {
            this.setState({disabled: false});
        }
    };

    render() {
        const {disabled} = this.state;
        const {
            currency_list, payment_terms_list, account_manager_list, loadingReferences,
            client_role_list, admin_role_list, getAccountManagersList, registeredAccount
        } = this.props;

        return (
            <PanelLayout>
                <PageHeader title="Create Account"/>
                {loadingReferences && <Loader backdrop content="loading..." vertical/>}

                {
                    loadingReferences
                        ?
                        <Paragraph rows={5}/>
                        :
                        <CreateForm
                            onSubmit={this.onSubmit}
                            disabled={loadingReferences || disabled}
                            formDefaultValue={this.formDefaultValue}
                            onCancel={this.onCancel}
                            isSeparatedPage={true}
                            registration_key={registeredAccount.reg_key}
                            {...{
                                currency_list,
                                payment_terms_list,
                                account_manager_list,
                                client_role_list,
                                admin_role_list,
                                getAccountManagersList
                            }}
                        />
                }
            </PanelLayout>
        );
    }
}

export default withRouter(NewAccount);