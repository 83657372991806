import React, {useContext} from 'react';
import {Table} from 'rsuite';
import {renderColumn} from "../../utils/helpers";
import {DEFAULT_PER_PAGE_DIALER} from '../../const';
import {useIntl} from "react-intl";
import m from "../../definedMessages";
import BaseTable from "../../components/base/BaseTable";
import {RtlContext} from "../../App";

export default ({
    data = [], 
    loading, 
    count,
    spAuth = false,
}) => {
    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    window.testData = data;

    const columns = [
        {
            id: 'otp_service',
            label: formatMessage(m.service),
            value: ({a_service, a_number}) => (
                a_service && a_number
                    ? `${a_service} (${a_number})`
                    : ""
            ),
            minWidth: 120,
            flexGrow: 4,
            align: 'center',
            spAuth: true
        },
        {
            id: 'otp_subdest',
            label: formatMessage(m.subdestination),
            value: ({b_subdestination_name, b_template}) => (
                b_subdestination_name && b_template
                    ? `${b_subdestination_name} (${b_template})`
                    : ""
            ),
            minWidth: 120,
            flexGrow: 4,
            align: 'center',
            spAuth: true
        },
        {
            id: 'a_cntr_name',
            label: formatMessage(m.origin),
            dataKey: 'a_cntr_name',
            minWidth: 120,
            flexGrow: 4,
            align: 'center',
            spAuth: false
        },
        {
            id: 'a_number',
            label: formatMessage(m.cli),
            dataKey: 'a_number',
            minWidth: 120,
            flexGrow: 2,
            align: 'center',
            spAuth: false
        },
        {
            id: 'b_cntr_name',
            label: formatMessage(m.destination),
            dataKey: 'b_cntr_name',
            minWidth: 120,
            flexGrow: 4,
            align: 'center',
            spAuth: false
        },
        {
            id: 'b_number',
            label: formatMessage(m.phone),
            dataKey: 'b_number',
            minWidth: 120,
            flexGrow: 2,
            align: 'center',
            spAuth: false
        },
        {
            id: 'date',
            label: formatMessage(m.date),
            dataKey: 'date',
            minWidth: 120,
            flexGrow: 2,
            align: 'center',
            spAuth: false
        }
    ].filter(value => value.spAuth === undefined || value.spAuth === spAuth);;

    const renderedColumns = columns.map(renderColumn);
    // reverse columns for RTL. Haven't found good solutions for CSS yet
    if (rtl) renderedColumns.reverse();

    const clearData = (dataList) => {
        return dataList.map(number => {

            const newNumber = {...number};

            Object.keys(newNumber).forEach(key => {
                if (newNumber[key] === null)
                    newNumber[key] = '—';
            });

            return newNumber;
        });
    };

    const clearedData = clearData(data);

    const calculatedTableHeight = window.innerHeight - 365;
    const calculatedTableRows = Math.round(calculatedTableHeight / 35);
    const defaultTableRowsHeight = DEFAULT_PER_PAGE_DIALER * 35 + 35;

    return (
        <BaseTable
            height={calculatedTableHeight < defaultTableRowsHeight ? defaultTableRowsHeight : calculatedTableHeight}
            autoHeight={data.length === calculatedTableRows}
            data={clearedData}
            loading={loading}
            rowHeight={35}
            headerHeight={35}
            style={{overflowY: 'hidden'}}

        >
            {renderedColumns}
        </BaseTable>
    );
}