import React, {useRef} from "react";
import {Form, SelectPicker, Schema, FormControl} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce} from "lodash";
import Text from "../../components/Form/Text";
import CustomField from "../../components/Form/CustomField";

const {StringType} = Schema.Types;

const formModel = Schema.Model({
    str: StringType()
        .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
        .minLength(6, 'The minimum is 6 characters.')
        .maxLength(40, "The maximum is 15 characters"),
});


export default ({
    filter = {},
    onChangeFilter,
    worldzoneList,
    destinationList,
    subdestinationList,
}) => {

    const formRef = useRef(null);

    const handleChange = debounce( (value) => {
        if ( !formRef.current.check() )
            return;
        onChangeFilter(value);
    }, 500);

    return (
        <>
            <Form
                ref={formRef}
                model={formModel}
                defaultFormValue={filter}
                onChange={handleChange}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                            <CustomField
                                accepter={SelectPicker}
                                width={200}
                                name="wz_key"
                                className="tableFilters_field"
                                errorPlacement="topEnd"
                                data={worldzoneList}
                                placeholder="Zone"
                                valueKey="wz_key"
                                labelKey="name"
                                type="text"
                            />
                    </FlexGridItem>
                    <FlexGridItem>
                            <CustomField
                                accepter={SelectPicker}
                                width={200}
                                name="de_key"
                                className="tableFilters_field"
                                errorPlacement="topEnd"
                                data={destinationList}
                                placeholder="Destination"
                                valueKey="de_key"
                                labelKey="name"
                                type="text"
                            />
                    </FlexGridItem>
                    <FlexGridItem>
                            <CustomField
                                width={200}
                                name="sde_key"
                                className="tableFilters_field"
                                errorPlacement="topEnd"
                                accepter={SelectPicker}
                                data={subdestinationList}
                                placeholder="Subdestination"
                                valueKey="sde_key"
                                labelKey="name"
                                type="text"
                            />
                    </FlexGridItem>
                </FlexGrid>
            </Form>
        </>
    )
};