import React, {useContext, useState} from 'react';
import {renderColumn} from '../../../utils/helpers';
import CustomizableTable from '../../../components/client/CustomizableTable/CustomizableTable';
import FormHOC from '../../../hoc/FilltersForm';
import { useWindowWidth } from '../../../hooks';
import {RtlContext} from "../../../App";

let renderColumns = [];

export default FormHOC(({
                     data,
                     page,
                     count,
                     per_page,
                     loading,
                     pickedColumns,
                     savedTableColumns,
                     savedPerPage,
                     onChangePerPage,
                     onChangePage,
                     onSaveColumnsTable,
                     extraRows
                 }) => {


    const rtl = useContext(RtlContext);
    const [columns, onChangeColumns] = useState( savedTableColumns && savedTableColumns.length 
            ? pickedColumns.filter(elem=>savedTableColumns.includes(elem.id)) 
            : pickedColumns
    );

    const dataObj = data[0];

    const windowWidth = useWindowWidth();

    if (dataObj) {
        renderColumns = [];
        const dataLabels = [];
        for (let column of columns) {
            if (dataObj[column.dataKey] || dataObj[column.dataKey] === 0) {
                dataLabels.push(column.label);
            }
        }

        for (let column of columns) {
            if (dataObj[column.dataKey] || dataObj[column.dataKey] === 0) {
                renderColumns.push(column);
            }
        }
    }

    let selectedColumns = null;
    if (renderColumns.length > 0) {
        selectedColumns = renderColumns;
    } else {
        selectedColumns = columns;
    }    

    const renderedColumns = selectedColumns.map(renderColumn);

    return (
        <CustomizableTable
            {...{
                rtl,
                data,
                count,
                page,
                per_page,
                loading,
                pickedColumns,
                savedPerPage,
                onChangePage,
                onChangePerPage,
                renderedColumns,
                onChangeColumns,
                onSaveColumnsTable,
                savedTableColumns,
                extraRows
            }}
            isChangedColumns={true}
            isPaginated={true}
            isMobile={windowWidth < 768 ? true : false}
            rowClassName={rowData => {
                if (rowData && rowData.id === "summaries") {
                    return "summaries";
                }

                return "";
            }}
        />
    );
});
