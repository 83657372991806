import {DESC_SORT, PRICE_NUMBERS, SET_NUMBERS, SET_NUMBERS_CSV} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {DEFAULT_PER_PAGE_TINY} from "../const";
import {TEMPLATE_GET} from '../const/apiMethods';


const setLoading = createAction('SET_LOADING_'+PRICE_NUMBERS);
const setNumbers = createAction(SET_NUMBERS);
const setNumbersCsv = createAction(SET_NUMBERS_CSV);

export const getNumbers = (pr_key, filter = {}, page = 1, per_page = DEFAULT_PER_PAGE_TINY, sort = {}) => (dispatch) => {
    dispatch(setLoading(true));

    let sort1, sort1_desc;

    if (sort.column) {
        sort1 = sort.column;
        sort1_desc = sort.type && sort.type === DESC_SORT;
    }

    if (pr_key) {
    api('price_range_number:get_list', {pr_key,
        filter: {
            show_allocated_numbers:false,
            show_unallocated_numbers:false,
            show_test_numbers:false,
            show_block_allocation_numbers: false,
            show_only_block_allocation_numbers: false,
            ...(filter ? filter : {})
        },
        page,
        per_page,
        sort1,
        sort1_desc
    })
        .then( (response) => {
            if (response) {
                dispatch(setNumbers({
                    items: response.price_range_number_list || [],
                    count: response.row_count || 0,
                    allocatedNumbersCount: response.price_range_number_allocated_count,
                    page,
                    per_page,
                }));
            }
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
        dispatch(setNumbers({
            items: [],
            count: 0,
            page,
            per_page
        }));

    }
};

export const getTrunkNumberCsvById = (transaction_id) => (dispatch) => {
    api('trunk_number:get_list', {target: {transaction_id}})
        .then(({price_range_number_list = []}) => {
            dispatch(setNumbersCsv(price_range_number_list));
        })
};

export const getTemplate = (account_id, type, table_name) => async () => {
    return await api('template:get', {target: {account_id}, type, table_name});
};