import React from "react";
import Modal from '../../components/Modal';
import {useWindowWidth} from "../../hooks";
import ReactJson from 'react-json-view'

export default ({
    show,
    onClose,
    maxWidth = 600,
    title = "",
    currentLogItem,
}) => {

    const resizedWidth = useWindowWidth();

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => onClose(false)}
            title={title}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={false}
        >
            {currentLogItem && currentLogItem.params
                ? <ReactJson src={currentLogItem.params} name={false} sortKeys={true}/>
                : null
            }
        </Modal>
    )
}