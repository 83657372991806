import React from "react";
import {Form, FormControl, Input, InputGroup} from "rsuite";
import styled from "styled-components";

export default ({
    onChangeFilter
}) => {

    return (
        <StyledField>
            <InputGroup>
                <Form
                    onChange={onChangeFilter}
                >
                    <FormControl
                        name="str"
                        accepter={Input}
                        placeholder={'Sound filename, IVR, CLI or phone'}
                    />
                </Form>
            </InputGroup>
        </StyledField>
    )
}

const StyledField = styled.div`
    max-width: 300px;
`;