import React from 'react';

import {FlexboxGrid, Form} from 'rsuite';
import CustomField from 'pages/NewAccount/CustomField';
import styled from 'styled-components';
import Button from 'rsuite/es/Button';
import 'pages/Account/TabSelfAllocation/selfAllocation.css';
import List from 'rsuite/es/List';


export default ({defaultLimitKeys, defaultAllocationLimits, ...props}) => {
    return (
        defaultLimitKeys && defaultLimitKeys.length ?
            <>
                <Header>Default limitations for self allocation is</Header>
                <LimitList bordered autoScroll={false}>
                    {defaultLimitKeys.map(limitKey => {
                        const limits = defaultAllocationLimits;
                        const limitLabels = `${limitKey.charAt(0).toUpperCase()}${limitKey.replace('_', ' ').slice(1)}`;
                        return <LimitListItem>
                            {limitKey === 'other' ? <Option>
                                    <Value><Number>{limits[limitKey]['once']}</Number> for one-time
                                        and <Number>{limits[limitKey]['daily']}</Number> a day for
                                        rest types</Value>
                                </Option>
                                : <Option>
                                    <Name>{limitLabels}:</Name><Value>
                                    <Number>{limits[limitKey]['once']}</Number> numbers
                                    for one-time allocation, <Number>{limits[limitKey]['daily_sde']}</Number> per
                                    destination a day and <Number>{limits[limitKey]['daily']}</Number> summary</Value>
                                </Option>}
                        </LimitListItem>
                    })}
                </LimitList>
                <Header>You can setup special limitations (lower or higher) for this account below</Header>
            </>
            : <Header>Sorry! No limitations for you</Header>
    );
};
const StyledFLexBoxItem = styled(FlexboxGrid.Item)`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    >div.rs-form-group[role="group"] {
      display: inline-block;
      margin-bottom: 0;
      width: 280px !important;
    }
    >div.rs-form-group[role="group"] .rs-checkbox {
      width: 100% !important;
    }
    >div.rs-form-group[role="group"] label {
      width: 300px;
    }
`;
const Header = styled.div`
    margin-bottom: 15px;
    margin-left: 5px;
`;
const Name = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #8256C8;
    margin-right: 3px;
`;
const Value = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #363434;
    margin-right: 15px;
`;
const Number = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #2a2828;
`;
const Option = styled.div`
    margin-left: 10px
`;

const LimitList = styled(List)`
    margin-bottom: 10px;
    margin-left: 5px;
    border: 1px solid lightgray;
    box-shadow: none;
    overflow: visible;
`;
const LimitListItem = styled(List.Item)`
    box-shadow: 0 -1px 0 lightgray, 0 1px 0 lightgray !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
`;
