import React from 'react';
import Modal from './Modal';
import { ID } from '../../utils/helpers';


export const API_Modal =  ({
    show = true, 
    extraDisabled = false, 
    onClose, 
    update, 
    checkFirstAll, 
    children, 
    ...props
}) => {

    const [disabled, setDisabled ] = React.useState(false);
    let  formRefs = {};
    

    // clone child
    const copyChild = (child) => {
        const id = ID();
        return React.cloneElement(child, {
                disabled, 
                updateRef:  (ref) => ((id) => formRefs[id] = ref )(id)
            }
        )
    }
    
    
    // clone form
    const form = Array.isArray(children) 
        ? React.Children.map(children, child => copyChild(child))
        : copyChild(children);
   
    
    // submit
    const onSubmit = async () => {
        if(checkFirstAll) {
            if(Object.keys(formRefs).map( key =>
                key && formRefs[key].check && formRefs[key].check()
            ).includes(false))
            return;
        }

        setDisabled(true);

        const results = await Promise.all(
            Object.keys(formRefs).map( key =>
                key && formRefs[key].send && formRefs[key].send()
            )
        );

        setDisabled(false);

        console.log("results 87", results);

        if(!results.includes(false)){
            onClose();
            update && update();
        }
    };
 
    return (
        <Modal
            show
            {...props}
            onSuccess = {onSubmit}
            disabled = {disabled}
            extraDisabled = {extraDisabled}
            onClose = {onClose}
            footer
        >
            {form}
        </Modal>
    )
};

export default API_Modal;