import React from 'react';
import Modal from "../../components/Modal";

export default ({
                    show,
                    data,
                    onClose,
                    onSubmit,
                    disabled,
                    title
                }) => {

    return (
        <>
            <Modal
                show={show}
                footer
                data={data}
                disabled={disabled}
                onSuccess={onSubmit}
                onClose={onClose}
                successText={"Ok"}
                title={title}
            >
                <p>Are you sure you want reject this registration request?</p>
            </Modal>
        </>
    )
};