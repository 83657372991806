import {
    PAYMENTS as PREFIX,
    PAYMENT_REQUEST_LIST,
    PAYMENT_LIST,
    PAYMENT_TRAFFIC_REPORTS_LIST, PAYMENT_HAWALA_LIST, APP_TYPE_ADMIN,
} from "../const";

import {
    PAYMENT_REQUEST_LIST_API,
    PAYMENT_LIST_API,
    PAYMENT_LIST_DIALER_API,
    INVOICE_LIST_API,
    INVOICE_LIST_DIALER_API,
    PAYMENT_REQUEST_HAWALA_GET_LIST_API,
    PAYMENT_REQUEST_CREATE_API,
    PAYMENT_REQUEST_CANCEL_API,
    PAYMENT_REQUEST_APPROVE_API,
    ACCOUNT_SETTINGS_MODIFY_API,
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";
import store from "../store/index";


const setPaymentRequestList = createAction('SET_ITEMS_' + PAYMENT_REQUEST_LIST);
const setPaymentRequestLoading = createAction('SET_LOADING_' + PAYMENT_REQUEST_LIST);
const setPaymentList = createAction('SET_ITEMS_' + PAYMENT_LIST);
const setPaymentListLoading = createAction('SET_LOADING_' + PAYMENT_LIST);
const setTrafficReportsList = createAction('SET_ITEMS_' + PAYMENT_TRAFFIC_REPORTS_LIST);
const setTrafficReportsLoading = createAction('SET_LOADING_' + PAYMENT_TRAFFIC_REPORTS_LIST);
const setHawalaPaymentRequestList = createAction('SET_ITEMS_' + PAYMENT_HAWALA_LIST);
const setHawalaPaymentRequestLoading = createAction('SET_LOADING_' + PAYMENT_HAWALA_LIST);

export const getPaymentRequestList = (data = {}) => (dispatch) => {
    dispatch( setPaymentRequestLoading(true) );
    api(PAYMENT_REQUEST_LIST_API, {...data})
        .then((response) => {
            if (response !== undefined) {
                dispatch( setPaymentRequestList({
                    items: response.payment_request_list || []
                }) );
            }
        })
        .finally(() => {
            dispatch( setPaymentRequestLoading(false) );
        });
};

export const getPaymentList = (data = {}) => (dispatch) => {
    dispatch(setPaymentListLoading(true));
    const appType = store.getState().auth.appType;
    const request = appType === APP_TYPE_ADMIN ? PAYMENT_LIST_API : PAYMENT_LIST_DIALER_API;
    api(request, {...data})
        .then((response) => {
            if (response !== undefined) {
                dispatch(setPaymentList({
                    items: response.payment_list || []
                }));
            }
        })
        .finally(() => {
            dispatch(setPaymentListLoading(false));
        });
};

export const getTrafficReportsList = (data = {}) => (dispatch) => {
    dispatch(setTrafficReportsLoading(true));
    const appType = store.getState().auth.appType;
    const request = appType === APP_TYPE_ADMIN ? INVOICE_LIST_API : INVOICE_LIST_DIALER_API;
    api(request, {...data})
        .then((response) => {
            if (response !== undefined) {

                dispatch(setTrafficReportsList({
                    items: response.invoice_list || []
                }));
            }
        })
        .finally(() => {
            dispatch(setTrafficReportsLoading(false));
        });
};

export const requestPayment = (data) => async (dispatch) => {
    const response = api(PAYMENT_REQUEST_CREATE_API, {...data});
    if (response && response.response && response.response.data.error) {
        return response.response.data.error;
    }
    return response;
};

export const cancelPaymentRequest = (data) => async (dispatch) => {
    const response = await api(PAYMENT_REQUEST_CANCEL_API, {...data});
    if (response && response.response && response.response.data.error) {
        return response.response.data.error;
    }
    return response;
};

export const approvePaymentRequest = (data) => async (dispatch) => {
    const response = await api(PAYMENT_REQUEST_APPROVE_API, {...data});
    if (response && response.response && response.response.data.error) {
        return response.response.data.error;
    }
    return response;
};

export const modifyPaymentSettings = (data) => async (dispatch) => {
    const response = await api(ACCOUNT_SETTINGS_MODIFY_API, data);
    return response;
};

export const getHawalaPaymentRequestList = (data = {}) => (dispatch) => {
    dispatch(setHawalaPaymentRequestLoading(true));
    api(PAYMENT_REQUEST_HAWALA_GET_LIST_API, {...data})
        .then((response) => {
            if (response !== undefined) {
                dispatch (setHawalaPaymentRequestList({
                    items: response.account_list || []
                }) );
            }
        })
        .finally(() => {
            dispatch(setHawalaPaymentRequestLoading(false));
        });
};