import React, {useEffect, useState} from 'react';
import Table from "components/Table/TableClientSort";
import {ASC_SORT} from "const";
import {connect} from "react-redux";
import {getTrafficReports} from "actions/traffic_reports";
import _ from 'lodash';

const columns = [
    {label: 'Period', dataKey: 'group_period', minWidth: 160, sortable: true},
    {label: 'Range', dataKey: 'range_name', minWidth: 200, title: true, sortable: true},
    {label: 'Originator', dataKey: 'originator_name', minWidth: 150, sortable: true},
    {
        label: 'Dialer', 
        dataKey: 'dialer_name', 
        value: ({dialer_name, account_manager_name}) => `${dialer_name} / ${account_manager_name || 'no manager'}`, 
        minWidth: 120, 
        sortable: true
    },
    {label: 'Supplier', dataKey: 'supplier_name', minWidth: 120, sortable: true},
    {label: 'Zone (origin)', dataKey: 'a_wz_name', minWidth: 130, title: true, sortable: true},
    {label: 'Zone (dest)', dataKey: 'b_wz_name', minWidth: 130, title: true, sortable: true},
    {label: 'Dest (origin)', dataKey: 'a_de_name', minWidth: 130, title: true, sortable: true},
    {label: 'Dest (dest)', dataKey: 'b_de_name', minWidth: 130, title: true, sortable: true},
    {label: 'Area (origin)', dataKey: 'a_sde_name', minWidth: 130, title: true, sortable: true},
    {label: 'Area (dest)', dataKey: 'b_sde_name', minWidth: 130, title: true, sortable: true},
    {label: 'A-Number', dataKey: 'a_number', minWidth: 120, sortable: true},
    {label: 'B-Number', dataKey: 'b_number', minWidth: 120, sortable: true},
    {label: 'Margin', dataKey: 'sum_margin', minWidth: 80, sortable: true, align: 'right'},
    {label: 'Duration', dataKey: 'sum_duration', minWidth: 75, sortable: true, align: 'right'},
    {label: 'Cost', dataKey: 'sum_cost_dialer', minWidth: 75, sortable: true, align: 'right'},
    {label: 'Calls', dataKey: 'count_cost_dialer', minWidth: 75, sortable: true, align: 'right'},
];

const TrafficReportsTable = (props) => {
    const [tableColumns, setTableColumns] = useState(columns);
    const {getTrafficReports, reportsData, loading, count, perPage, page, filters} = props;

    const getReportsItems = (page, perPage, sort) => {
        getTrafficReports(filters, page, perPage, sort);
    };

    useEffect(() => {
        getReportsItems(1, perPage);
    }, [filters]);

    useEffect(() => {
        if (reportsData && reportsData.length) {
            //SELECT Columns To SHOW
            setTableColumns(_.filter(columns, (column) => {
                return reportsData.find(item => !_.isNil(item[column.dataKey]))
                // return !_.isNil(reportsData[0][column.dataKey]);
            }));
        }
    }, [reportsData]);

    return (
        <Table
            data = {reportsData}
            loading = {loading}
            columns = {tableColumns}
            count = {count}
            per_page = {perPage}
            page = {page}
            getItems = {getReportsItems}
            ispagination
            sortColumn={tableColumns[0].dataKey}
            sortType={ASC_SORT}
        />
    );
};

const mapStateToProps = ({traffic_reports}) => ({
    loading: traffic_reports.loading,
    reportsData: traffic_reports.list,
    count: traffic_reports.count,
    perPage: traffic_reports.perPage,
    page: traffic_reports.page
});

const mapDispatchToProps = {
    getTrafficReports
};

export default connect(mapStateToProps, mapDispatchToProps)(TrafficReportsTable);