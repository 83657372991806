import React, {useState} from 'react';
import {ButtonPrimary} from '../../../../../components/base/BaseButton';
import Modal from './Modal';

export default ({
    activeTrunk,
    trunk_id,
    sp_key,
    spOtpType,
    sp_auth,
    update,
    onClose,
    forsed_open = false,
    account_id,
    disabled,
    account,
    getTrunkNumberCsvById,

    hideBtn
}) => {
    const [show, setShow] = useState(false);

    React.useEffect(() => {
        if (forsed_open) {
            setShow(true)
        }
    }, [forsed_open]);

    return (
        <>
            {!hideBtn &&
                <ButtonPrimary disabled={!trunk_id || disabled || !activeTrunk.active || activeTrunk.closed} onClick={() => setShow(true)}>
                    Allocate new Numbers
                </ButtonPrimary>
            }
            <Modal
                show={show}
                account={account}
                activeTrunk={activeTrunk}
                trunk_id={trunk_id}
                disabledTrunk
                sp_key={sp_key}
                sp_auth={sp_auth}
                spOtpType={spOtpType}
                update={update}
                onClose={() => {
                    setShow(false);
                    onClose();
                }}
                account_id={account_id}
                {...{
                    getTrunkNumberCsvById
                }}
            />
        </>
    );
}
