import React, {useState} from 'react';
import {Alert, Checkbox, DatePicker} from 'rsuite';
import ModalSendApi from '../../../components/Modal/ModalSendApi'
import ApiRequest from '../../../components/ApiRequest'
import {toEndDayUTCTime, toStartDayUTCTime, toUTCDateTime} from 'utils';
import {useWindowWidth} from "../../../hooks";


export default ({initialValues, target, getCount, entity, ...props}) => {
    const [endDate, setEndDate] = useState(null);
    const [withDate, setWithDate] = useState(false);

    const windowWidth = useWindowWidth();

    const numbersCount = getCount();

    return (
        <ModalSendApi 
            {...props}
            title="Revoke Number"
            successText="Yes"
            extraDisabled={withDate && !endDate}
        >
            <ApiRequest
                method="trunk_number__filter:revoke"
                checkResultKey = 'trunk_numbers'
                target= {target}
                update = { ({trunk_numbers}) => {
                    Alert.success(`Revoked ${trunk_numbers} numbers`)
                }}
                data={{
                    ...(endDate ? {end_date: endDate} : {}),
                    ...initialValues
                }}
            >
                <div>
                    <p>Selected <strong>{numbersCount}</strong> {numbersCount === 1 ? "number" : "numbers"} from this trunk will be revoked. Do you really want to do it?</p>
                    <div
                        style={{textAlign: windowWidth >= 600 ? 'center' : 'left'}}
                        className="mt-4"
                    >
                        <Checkbox inline className="mr-2" onChange={(v, checked) => {
                            setWithDate(checked);

                            if (!checked) {
                                setEndDate(null);
                            }
                        }}>From date</Checkbox>
                        <DatePicker
                            placeholder="Enter Date" 
                            disabled={!withDate} 
                            onChange={(date) => {
                                date && setEndDate(toStartDayUTCTime(date));
                            }}
                            ranges={[
                                {
                                    label: 'Today',
                                    value: new Date()
                                },
                                {
                                    label: 'Tomorrow',
                                    value: new Date( new Date().setDate(new Date().getDate() + 1) )
                                }
                            ]}

                            // mobile
                            inline={windowWidth < 600 && withDate}
                            oneTap={windowWidth < 600 && withDate}
                        />
                    </div>
                </div>
            </ApiRequest>
        </ModalSendApi>
    );
};