import {connect} from 'react-redux';
import Notes from './Notes';
import {
    createAccountNote,
    getAccountNoteList,
    removeAccountNote
} from "../../../actions/account_note";

const mapState = ({account_note}) => ({
    notesList: account_note.list,
    notesLoading: account_note.loading,
    isFetched: account_note.isFetched,
});

export default connect(mapState, {
    getAccountNoteList,
    removeAccountNote,
    createAccountNote,
})(Notes);