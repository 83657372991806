import React, {useContext, useEffect, useState} from 'react';
import {Icon, Table, Tooltip, Whisper} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import {renderColumn} from '../../utils/helpers';
import {compose} from 'redux';
import {useIntl} from 'react-intl';
import {useWindowWidth} from '../../hooks';
import {SCREEN_MEDIA, SP_OTP_TYPE} from '../../const';
import m from "../../definedMessages";
import BaseTable from "../../components/base/BaseTable";
import {ButtonRowClient, ButtonToolbar} from '../../components/base/BaseButton';
import styled from "styled-components";
import {RtlContext} from "../../App";

const {sm} = SCREEN_MEDIA;
const {Column, HeaderCell, Cell} = Table;

const TrunkTable = (
    {
        data = [],
        loading,
        onClickTrunk,
        onDownloadNumbers,
        onShowNumbersModal,
        onShowGoogleOTPModal,
        permissionList,
        uploadLoading,
        accountDialerState,

        onChangeActiveTrunk,
        activeTrunk,
    }) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    const resizedWidth = useWindowWidth();
    const isMobile = resizedWidth <= sm.max;

    useEffect(() => {
        if (filteredData.length > 0 && activeTrunk === null) {
            onChangeActiveTrunk(filteredData[0].id);
        }
    });

    const [currentTop, setCurrentTop] = useState(0);
    // const [activeTrunk, onChangeActiveTrunk] = useState(null);

    const handleClickRow = (id, sp_auth) => {

        onClickTrunk(id, sp_auth);

        const top = isScrolled ? scrollTop : currentTop;
        setCurrentTop(top);

        if (isScrolled)
            isScrolled = false;
    };

    const filteredData = data.filter(value => !value.closed);

    let scrollTop = 0;
    let isScrolled = false;
    let tableRef = null;

    useEffect(() => {
        const top = Math.abs(currentTop);
        tableRef.scrollTop(top);
    }, [activeTrunk, data, loading, uploadLoading]);

    const columns = [
        {
            id: 'name',
            label: formatMessage(m.name),
            dataKey: 'name',
            align: 'center',
            minWidth: 170,
            flexGrow: 4
        },
        {
            id: 'range',
            label: formatMessage(m.type),
            value: (({tprefix, ip, port, is_default_term_point}) => {
                return !is_default_term_point
                    ? `${ip}:${port} ${tprefix}`
                    : `Default IVR`;
            }),
            align: 'center',
            minWidth: 120,
            flexGrow: 3
        },
        {
            id: 'price',
            label: formatMessage(m.price),
            dataKey: 'service_plan_name',
            // value: (({service_plan_name, sp_otp_type}) => sp_otp_type === SP_OTP_TYPE  ? `${service_plan_name} (Google special)` : service_plan_name),
            align: 'center',
            minWidth: 120,
            flexGrow: 2
        },
        {
            id: 'allocated_numbers',
            label: formatMessage(m.allocatedNumbersTrunkTable),
            value: (({allocated_numbers, numbers_with_traffic}) => `${allocated_numbers || 0}/${numbers_with_traffic || 0}`),
            align: 'center',
            minWidth: 100,
            flexGrow: 2
        }
    ];

    const fixedColumnPlacement = rtl ? '' : 'right';

    const tableActions = (
        <Column
            fixed={fixedColumnPlacement}
            key="table__actions"
            width={resizedWidth >= 768 ? 120 : 75}
        >
            <HeaderCell> </HeaderCell>
            <Cell>

                {rowData => (

                    <ButtonToolbar>
                        <StyledButtonRowClient
                            onClick={() => onDownloadNumbers(rowData.id)}
                            loading={uploadLoading[rowData.id]}
                        >
                            <Icon icon="cloud-download"/>
                        </StyledButtonRowClient>

                        {(!rowData.sp_otp_type || rowData.sp_otp_type !== SP_OTP_TYPE) &&
                        permissionList && 
                        permissionList.includes('allocate_pattern') &&
                            <>
                                {!rowData.active

                                    ? <Whisper
                                        preventOverflow
                                        trigger="hover"
                                        speaker={<Tooltip>{formatMessage(m.trunkIsNotActive)}</Tooltip>}
                                        placement="auto"
                                    >
                                        <StyledButtonRowClient
                                            // use className instead of prop to avoid Tooltip issue on Chrome
                                            className="rs-btn-disabled"
                                        >
                                            <Icon icon="lock"/>
                                        </StyledButtonRowClient>
                                    </Whisper>

                                    : <StyledButtonRowClient onClick={() => onShowNumbersModal(rowData.id)}>
                                        <b>A</b>
                                    </StyledButtonRowClient>
                                }
                            </>
                        }

                        {rowData.sp_auth && 
                        rowData.sp_otp_type === SP_OTP_TYPE &&
                        (
                            accountDialerState && 
                            accountDialerState.permission_list && 
                            accountDialerState.permission_list.includes('google_otp_allocation')
                        ) &&
                            <>
                                {!isMobile
                                    ? <Whisper
                                        preventOverflow
                                        trigger="hover"
                                        speaker={<Tooltip>
                                            {rowData.active
                                                ? formatMessage(m.googleOTPAllocation)
                                                : formatMessage(m.trunkIsNotActive)
                                            }
                                        </Tooltip>}
                                        placement="auto"
                                    >
                                        {rowData.active

                                            ? <StyledButtonRowClient onClick={() => onShowGoogleOTPModal(rowData.id)}>
                                                <b>A</b>
                                            </StyledButtonRowClient>

                                            : <StyledButtonRowClient className="rs-btn-disabled">
                                                <b>A</b>
                                            </StyledButtonRowClient>
                                        }
                                    </Whisper>
                                    : <>
                                        {rowData.active

                                            ? <StyledButtonRowClient onClick={() => onShowGoogleOTPModal(rowData.id)}>
                                                <b>A</b>
                                            </StyledButtonRowClient>

                                            : <Whisper
                                                preventOverflow
                                                trigger="hover"
                                                speaker={<Tooltip>{formatMessage(m.trunkIsNotActive)}</Tooltip>}
                                                placement="auto"
                                            >
                                                <StyledButtonRowClient className="rs-btn-disabled">
                                                    <b>A</b>
                                                </StyledButtonRowClient>
                                            </Whisper>
                                        }
                                    </>
                                }
                            </>
                        }
                    </ButtonToolbar>
                )}
            </Cell>
        </Column>
    );

    const columnMobileFix = (
        <Column key="table_scrollfix" width={0} fixed>
            <HeaderCell></HeaderCell>
            <Cell></Cell>
        </Column>
    );

    const renderedColumns = columns.map(renderColumn);

    renderedColumns.push(tableActions);
    renderedColumns.push(columnMobileFix);

    // reverse columns for RTL. Haven't found good solutions for CSS yet
    if (rtl) renderedColumns.reverse();

    return (
        <BaseTable
            data={filteredData}
            onRowClick={({id, sp_auth}) => handleClickRow(id, sp_auth)}
            ref={ref => tableRef = ref}
            loading={loading}
            className={'trunk-table'}
            height={120}
            autoHeight={isMobile}
            rowHeight={30}
            headerHeight={31}
            onScroll={(scrollX, scrollY) => {
                if (!isScrolled)
                    isScrolled = true;

                scrollTop = scrollY;
            }}
            rowClassName={(rowData) => {
                if (rowData !== undefined) {
                    if (rowData.id === activeTrunk) {
                        return 'row-active';
                    }
                    if (!rowData.active) {
                        return 'row-closed';
                    }
                }
                return 'row-table';
            }}
        >
            {renderedColumns}
        </BaseTable>
    );
};

export default compose(
    FormHOC
)(TrunkTable);


const StyledButtonRowClient = styled(ButtonRowClient)`
    &&&& {
        height: 25px;
        line-height: 25px;
        padding: 0px;
        min-width: 25px;
    }
`;