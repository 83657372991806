import React from "react";
import Modal from "./Modal";
import {ButtonToolbar, FlexboxGrid, Button} from "rsuite";
import { useWindowWidth } from "../../hooks";
import styled from "styled-components";


// Codes
const reasonCodes = {
    not_found_rate: "not_found_rate",
    not_found_free_numbers: "not_found_free_numbers",
    account_trunk_range_mismatch: "account_trunk_range_mismatch",
    self_allocation_limit_exceeded_once: "self_allocation_limit_exceeded_once",
    self_allocation_limit_exceeded: "self_allocation_limit_exceeded",
    self_allocation_for_sde_limit_exceeded: "self_allocation_for_sde_limit_exceeded",
    not_defined_range: "not_defined_range",

    prefix_in_quarantine: "prefix_in_quarantine",
    non_google_otp_allocation: "non_google_otp_allocation",
    max_numbers_per_range_value_exceeded: "max_numbers_per_range_value_exceeded",
    allocation_to_test_trunk: "allocation_to_test_trunk",
    non_google_otp_trunk: "non_google_otp_trunk",
    non_multiple_100_number: "non_multiple_100_number",
    not_allowed_self_google_otp_allocation: "not_allowed_self_google_otp_allocation",
    non_power_of_10_number: "non_power_of_10_number",
    non_power_of_10_number_start_1000: "non_power_of_10_number_start_1000",
    illegal_template_length: "illegal_template_length",
    illegal_template: "illegal_template",
    too_much_operations: "too_much_operations",
};


const ModalResponseHandler = (
    {
        show,
        onClose,
        onSuccess,
        changeRandom = () => {},
        maxWidth = 600,
        params = {}
    }
) => {

    const {
        trunk = {}, 
        numbers = 0,
        range,
        random_number = null,
        sde_key: subDest, 
        template,
        reasonCode,
        reasonHash
    } = params;

    const windowWidth = useWindowWidth();


    const handleSuccess = () => {
        onClose(false);
        if (reasonCode === reasonCodes.not_found_free_numbers && !random_number && random_number !== null) {
            changeRandom(true);
        }
        onSuccess(true);
    };


    const getMessage = () => {
        const defaultMessage = `Numbers cannot be allocated`;

        switch (reasonCode) {

            case reasonCodes.non_google_otp_trunk:
                return "Trunk is not Gooogle OTP";

            case reasonCodes.non_google_otp_allocation:
                return "Incorrect allocation method";

            case reasonCodes.non_power_of_10_number:
                return "Incorrect number amount";

            case reasonCodes.non_power_of_10_number_start_1000:
                return "Incorrect number amount";

            case reasonCodes.too_much_operations:
                return "Too much simultaneous operations";

            case reasonCodes.prefix_in_quarantine:
                return "Numbers are temporarily unavailable";

            case reasonCodes.max_numbers_per_range_value_exceeded:
                return "Too much numbers per range";

            case reasonCodes.allocation_to_test_trunk:
                return "Invalid operation";
                
            case reasonCodes.non_multiple_100_number:
                return "Incorrect number amount";

            case reasonCodes.illegal_template:
            case reasonCodes.illegal_template_length:
                return "Wrong template";

            case reasonCodes.not_allowed_self_google_otp_allocation:
                return "Action is prohibited";

            case reasonCodes.self_allocation_limit_exceeded_once:
                return "You're trying to allocate to much numbers per one";

            case reasonCodes.self_allocation_limit_exceeded:
                return "You have exceeded the limit for self-allocation numbers";

            case reasonCodes.self_allocation_for_sde_limit_exceeded:
                return "You're trying to allocate to much numbers per subdestination";

            case reasonCodes.not_found_rate:
                return "No rate for destination";

            case reasonCodes.account_trunk_range_mismatch:
                if (template)
                    return `Numbers from template "${template}" cannot be allocated to trunk "${trunk.name}". Please try another trunk.`;
                if (range)
                    return `Numbers from range "${range}" cannot be allocated to trunk "${trunk.name}". Please try another trunk.`;
                return `Numbers cannot be allocated to trunk "${trunk.name}". Please try another trunk.`;

            case reasonCodes.not_found_free_numbers:
                if (template)
                    return !random_number
                        ? `We cannot allocate ${numbers} numbers from template "${template}" successively. Please decrease number amount or use random order`
                        : `Numbers from template "${template}" cannot be allocated`;
                if (subDest)
                    return !random_number
                        ? `We cannot allocate ${numbers} numbers for destination "${subDest}" successively. Please decrease number amount or use random order`
                        : `Numbers for "${subDest}" cannot be allocated`;
                if (range)
                    return !random_number
                        ? `We cannot allocate ${numbers} numbers from range "${range}" successively. Please decrease number amount or use random order`
                        : `Numbers from range ${range} cannot be allocated`;
                return defaultMessage;

            default:
                if (template)
                    return `Numbers from template "${template}" cannot be allocated`;

        }

        return defaultMessage;
    };

    return (
        <Modal
            {...{show, onClose}}
            onClose={handleSuccess}
            onSuccess={handleSuccess}
            showCloseSuccessButtons={false}
            width={windowWidth > maxWidth ? maxWidth : windowWidth}
        >

            <FlexboxGrid justify="end">
                <FlexboxGrid.Item colspan={23}>
                    <span>{getMessage()}{reasonHash ? ` (Operation code: ${reasonHash})` : ""}</span>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <ModalButtonToolbar>
                <Button
                    appearance="subtle"
                    onClick={handleSuccess}
                    autoFocus
                >
                    Close
                </Button>
            </ModalButtonToolbar>

        </Modal>
    )
};

export default ModalResponseHandler;

const ModalButtonToolbar = styled(ButtonToolbar)`
    float: right;
    margin-right: 20px;
    margin-top: 40px;
    right: 0;
`;