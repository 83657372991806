import {
    REGISTRATION_LIST,
    REGISTRATION_COUNT, REGISTRATION_ACCOUNT
} from '../const/'

const initialState = {
    loading: false,
    registration_list: [],
    registration_count: 0
};

const handlers = {
    [REGISTRATION_LIST]: (state, {payload}) => ({...state, ...payload, loading: false}),
    [REGISTRATION_COUNT]: (state, {payload}) => ({...state, ...payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
