import {connect} from 'react-redux';
import {getLiveCalls} from '../../actions/client/liveCalls';
import LiveCalls from './LiveCalls';

const mapState = ({client_live_calls}) => ({
    loading: client_live_calls.loading,
    live_calls_list: client_live_calls.live_calls_list,
    page: client_live_calls.page,
    per_page: client_live_calls.per_page,
    count: client_live_calls.count,
});

export default connect(mapState, {getLiveCalls})(LiveCalls);
