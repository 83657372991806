import {
    API_PAGE_DIALER as PREFIX,
} from '../../const';
import {API_GENERATE_NEW_DIALER_API} from "../../const/apiMethods";

const initialState = {
    items: [],
    count: 0,
    newItem: null,
    loading: true
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {keyList}}) => ({
        ...state,
        items: keyList,
        count: keyList.length,
        newItem: state.newItem ? state.newItem : keyList.length && keyList.filter(key => key.active).length ? keyList.filter(key => key.active)[0].api_key : null,
        loading: false
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    [API_GENERATE_NEW_DIALER_API]: (state, {payload: {apiKey}}) => ({...state, newItem: apiKey}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}