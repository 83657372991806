import React from 'react';
import {Dropdown, Icon, Badge} from 'rsuite';
import {ACCOUNT_USER_MODIFY_BY_SELF} from '../../const/apiMethods';
import {checkPermissionsFor} from "../../store/storeHelpers";
import styled, {css} from 'styled-components';
import * as S from "./styled.js";
import {APP_TYPE_CLIENT} from "../../const";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import m from "../../definedMessages";

export default ({
    savedUser,
    onChangeShowEditProfileModal,
    appType,
    localMessages
}) => {
    const styled = getComputedStyle(document.documentElement);
    const {formatMessage} = useIntl();

    return (
        <>
            { checkPermissionsFor(ACCOUNT_USER_MODIFY_BY_SELF)
                ? <UserDropdown
                    menuStyle={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
                    title={
                        <UserInfo>
                            <div className="user-account">
                                <AccountBadge badgeColor={styled.getPropertyValue('--color-status-badge')}>
                                    {savedUser && savedUser.account_name}
                                </AccountBadge>
                            </div>
                            <p className="user-name">{savedUser && savedUser.name}</p>
                        </UserInfo>
                    }
                >

                    <Dropdown.Item 
                        eventKey="edit-profile" 
                        onSelect={onChangeShowEditProfileModal}
                    >
                        <Icon icon="edit2"/> {formatMessage(localMessages.editProfile)}
                    </Dropdown.Item>

                    {appType === APP_TYPE_CLIENT &&
                        <Dropdown.Item
                            componentClass={Link}
                            eventKey="payment-details"
                            to="payment-details"
                        >
                            <Icon icon="usd"/> {formatMessage(m.managePaymentDetails)}
                        </Dropdown.Item>
                    }
                </UserDropdown>
                : <User>{savedUser && savedUser.name}</User>
            }
        </>
    )
}

const UserDropdown = styled(S.StyledDropdown)`
    user-select: none;
    
    &&& {
    
        .rs-dropdown-toggle {
            align-items: center;
            border-radius: 0;
            box-sizing: border-box;
            display: flex;
            padding: 15px 16px 10px;
        }
        
        ${props => props.theme.appType === APP_TYPE_CLIENT && css`
            .rs-dropdown-toggle {
                padding-left: 10px;
                padding-right: 10px;
            }
        `}

        .rs-dropdown-toggle-caret {
            margin: 0;
            position: static;
            transform: none;
        }
    }
`;

const AccountBadge = styled(Badge)`
    padding-left: 6px;
    padding-top: 1px;
    
    .rtl & {
        padding-left: 0;
        padding-right: 6px;
    }

    &&& {

        .rs-badge-content {
            height: 4px;
            left: 0; 
            top: 0;
            width: 4px;
            transform: none;

            ${props =>
                props.badgeColor &&
                css`
                    background-color: ${props.badgeColor}
                `
            }
        }
    }
    
    .rtl &&& {
        
        .rs-badge-content {
            left: auto;
            right: 0;
        }
    }
`;

const UserInfo = styled.div`
    .user-account {
        font-size: 8px;
        left: 2px;
        line-height: 1.2em;
        position: absolute;
        top: 2px;
    }
    
    ${props => props.theme.appType === APP_TYPE_CLIENT && css`
        .user-account {
            text-transform: uppercase;
        }
    `}

    .user-name {
        margin: 0;
        max-width: 170px;
        min-width: 0;
        overflow: hidden;
        padding-right: 10px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (min-width: 768px) {
            min-width: 130px;
        }
    }
    
    .rtl & {
        
        .user-account {
            left: auto;
            right: 2px;
        }
        
        .user-name {
            padding-left: 10px;
            padding-right: 0;
            text-align: right;
        }
    }
`;


const User = styled.div`
    display: inline-block
    padding: 19px 16px;
    height: 56px;
    font-size: 13px;
    color: #fff;
`;
