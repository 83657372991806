import React from 'react';
import PageHeader from '../../components/base/PageHeader';
import PriceFilters from './PriceFilters';
import PriceTable from './PriceTable';
import {
    LOCAL_STORAGE_PRICE_FILTERS_DIALER,
    LOCAL_STORAGE_PRICE_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER,
    SP_OTP_TYPE
} from '../../const';
import {
    RATECARD_LIST_ACCOUNT_DIALER,
    RATECARD_LIST_OTP_DEFAULT_DIALER,
    RATECARD_LIST_OTP_ACCOUNT_DIALER,
    RATECARD_LIST_DEFAULT_DIALER
} from '../../const/apiMethods';
import {savePdfByTemplate, getBlobContent} from '../../utils/helpers';
import {injectIntl} from "react-intl";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import {Spacer} from '../../components/base/Spacer';
import styled, {css} from "styled-components";
import {getFileResponse} from '../../api/loginRoutes';
import {ButtonToolbar, Dropdown, Loader, Icon} from 'rsuite';
import {SCREEN_MEDIA} from '../../const';
import m from "../../definedMessages";
import PriceGetNumbersModal from './PriceGetNumbersModal';
import PanelLayout from "../../components/base/PanelLayout";

import ModalAllocationResult from '../../components/client/Modal/ModalAllocationResult';
import GoogleOTPAllocateModal from '../Numbers/GoogleOTPAllocateModal/GoogleOTPAllocateModal';
import ModalResponseHandler from "../../components/client/Modal/ModalResponseHandler";

const {md} = SCREEN_MEDIA;
const widthDesktopMin = md.min;


class Price extends React.Component {
    
    constructor(props) {
        super(props);

        this.currentPerPage = null;
        this.defaultFilter = {str: '', sp_key: 1};
        this.savedTableFilter = JSON.parse( localStorage.getItem(LOCAL_STORAGE_PRICE_FILTERS_DIALER) );
        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);
        console.log("props.account", props);
        const {account} = props.account.session || {};
        const currencyKey = ( (this.savedTableFilter && this.savedTableFilter.cur_key) || {} ).cur_key || account.cur_key;
        const isTest = account.hasOwnProperty('is_test') ? account.is_test : false;

        const filter = {
            ...this.defaultFilter,
            ...(this.savedTableFilter || {}),
            ...(isTest ? {cur_key: currencyKey} : {}),
        };

        if (!isTest && filter.cur_key) {
            delete filter.cur_key;
        }

        if ( props.service_plan_list
            && props.service_plan_list.length
            && !props.service_plan_list.find(item => item.sp_key === filter.sp_key)
        ) {
            filter.sp_key = props.service_plan_list[0].sp_key;
        }

        this.state = {
            currencyKey: currencyKey,
            isTest: isTest,
            filter: {...filter},
            selectedData: {id: null, range_name: null, av_numbers: null, pr_key: null, sp_key: null},
            accountInfo: account,
            spAuth: false,
            isGoogleOtp: false,

            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_PRICE_PER_PAGE_DIALER)),
            searchLoading: false,
            showGetNumbersModal: false,
            showAllocatedNumbersModal: false,
            resizedWidth: window.innerWidth,
            trunkId: null,
            formValue: {trunk_id: null, numbers: 1},
            showModalResponseHandler: null,
            randomNumber: false,
            reasonModalProps: {},

            csvContent: null,
            csvContentLoading: true,

            transactionId: null,
            notAllocatedNumbers: null,
            allocatedNumbersCount: 0,

            showGoogleOTPModal: false,
            googleOTPFormValue: {numbers: 100},
        };
    }


    componentDidMount() {
        const {filter, savedPerPage} = this.state;
        const {getTrunkList, service_plan_list, getPriceList, per_page} = this.props;
        
        getTrunkList();

        if (service_plan_list.length && filter.sp_key) {
            const currentServicePlan = service_plan_list.find( item => item.sp_key === filter.sp_key );
            this.setState({
                spAuth: currentServicePlan.auth,
                isGoogleOtp: currentServicePlan.otp_type === SP_OTP_TYPE
            });
            getPriceList(savedPerPage || per_page, filter, 1, currentServicePlan.auth);
        }

        window.addEventListener('resize', this.handleResize);
    }


    componentDidUpdate(prevProps, prevState) {
        const {filter, savedPerPage} = this.state;
        const {service_plan_list, getPriceList, per_page} = this.props;

        if (service_plan_list.length && filter.sp_key) {
            if ( prevState.filter !== filter || prevProps.service_plan_list !== service_plan_list) {
                const currentServicePlan = service_plan_list.find(item => item.sp_key === +filter.sp_key);
                if (currentServicePlan) {
                    this.setState({
                        spAuth: currentServicePlan.auth,
                        isGoogleOtp: currentServicePlan.otp_type === SP_OTP_TYPE
                    });
                    getPriceList(savedPerPage || this.currentPerPage || per_page, filter, 1, currentServicePlan.auth);
                } else {
                    this.setState({
                        filter: {...filter, sp_key: service_plan_list[0].sp_key}
                    });
                }
            }
        }
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };


    setShowGetNumbersModal = (value) => {
        this.setState({showGetNumbersModal: value});
    };


    onShowGetNumbersModal = (data) => {
        this.setShowGetNumbersModal(true);
        this.setState({selectedData: data});
    };


    setShowAllocatedNumbersModal = (value) => {
        this.setState({showAllocatedNumbersModal: value});
    };


    filterIsNotNull = (filter, filter_param) => {
        return filter.hasOwnProperty(filter_param) && filter[filter_param];
    };


    onChangeFilters = (filtersObj) => {
        const {filter} = this.state;
        const newFilter = {...filter, ...filtersObj}
        this.setState({filter: newFilter});
        localStorage.setItem(LOCAL_STORAGE_PRICE_FILTERS_DIALER, JSON.stringify(newFilter));
    };


    onChangePerPage = (perPage) => {
        const {filter} = this.state;
        const {getPriceList, service_plan_list} = this.props;

        this.setState({savedPerPage: perPage ? null : this.currentPerPage});
        const per_page = Number(perPage) || this.currentPerPage;
        this.currentPerPage = per_page <= 0 
            ? MIN_PER_PAGE_DIALER
            : per_page > MAX_PER_PAGE_DIALER ? MAX_PER_PAGE_DIALER : per_page;
        localStorage.setItem(LOCAL_STORAGE_PRICE_PER_PAGE_DIALER, JSON.stringify(this.currentPerPage));

        const currentServicePlan = service_plan_list.find(item => item.sp_key === +filter.sp_key);

        getPriceList(this.currentPerPage, filter, 1, currentServicePlan.auth);
    };


    onChangePage = (pageNumber) => {
        const {savedPerPage, filter} = this.state;
        const {getPriceList, per_page, service_plan_list} = this.props;

        const currentServicePlan = service_plan_list.find(item => item.sp_key === +filter.sp_key);
        getPriceList(savedPerPage || this.currentPerPage || per_page, filter, pageNumber, currentServicePlan.auth);
    };


    exportPrice = () => {
        const {filter} = this.state;
        const {service_plan_list} = this.props;

        const currentServicePlan = service_plan_list.find(item => item.sp_key === +filter.sp_key);
        this.props.exportPrice({...filter}, currentServicePlan.auth);
    };


    exportPricePdf = () => {
        const {isTest, filter} = this.state;
        const {account, setUploadingPriceLoading, service_plan_list, getTemplate} = this.props;

        const currentServicePlan = service_plan_list.find( item => item.sp_key == filter.sp_key );

        const requestMethod = currentServicePlan.auth 
            ? !isTest ? RATECARD_LIST_OTP_ACCOUNT_DIALER: RATECARD_LIST_OTP_DEFAULT_DIALER
            : !isTest ? RATECARD_LIST_ACCOUNT_DIALER : RATECARD_LIST_DEFAULT_DIALER;
        
        setUploadingPriceLoading(true);
        getTemplate(account.id, 0, 'allocated_numbers').then(({template = {}}) => {
            getFileResponse(requestMethod, {filter: filter}, true)
                .then((response) => {
                    const csvText = getBlobContent(response);
                    csvText.then((csvContent) => {
                        const csvStringsArray = csvContent ? csvContent.split('\n') : [];
                        const csvDataArrayData = csvStringsArray.filter(value => !!value);
                        const csvHeaders = csvDataArrayData.length && csvDataArrayData[0].split(';');
                        const csvBody = csvDataArrayData.length && csvDataArrayData.slice(1, csvDataArrayData.length).map(line => line.split(';'));

                        savePdfByTemplate(
                            csvBody,
                            [csvHeaders],
                            'prices',
                            template,
                            {},
                            account
                        );
                        setUploadingPriceLoading(false);
                    });
                }).catch(() => {
                setUploadingPriceLoading(false)
            });
        })
    };

    getNumbers = async (data) => {
        const {selectedData, spAuth} = this.state;
        const {allocateByPriceRange, allocateByAccess} = this.props;

        let response;
        
        if (!spAuth) {
            response = await allocateByPriceRange(data.trunk_id, selectedData.pr_key, data.numbers, data.random_order);
        } else {
            response = await allocateByAccess(data.trunk_id, selectedData.sde_key, data.numbers, data.random_order);
        }

        if (response === undefined || (response.code && !response.reason_code))
            return;

        this.setShowGetNumbersModal(false);

        // reason_code recieved in an error message or with no numbers
        if ( response.reason_code && (!response.trunk_number_transaction || !response.trunk_number_transaction.numbers) ) {
            this.setModalResponseHandler(true);
            return {
                status: 1,
                reason_code: response.reason_code,
                reason_hash: response.hash,
            };
        }

        this.onAllocationGetResult(response);

        // update table after succesful numbers allocation (with new total numbers value)
        this.onChangePage(this.props.page || 1);

        this.setState({
            showAllocatedNumbersModal: true,
            trunkId: data.trunk_id
        });
        return {status: 0};
    };


    onAllocateGoogleOTP = async (formValue) => {
        const {allocateGoogleOTPTrunkNumbers} = this.props;

        const response = await allocateGoogleOTPTrunkNumbers(formValue);
        if (response === undefined || (response.code && !response.reason_code))
            return;

        this.setState({
            showGoogleOTPModal: false,
            trunkId: formValue.trunk_id
        });

        // reason_code recieved in an error message or with no numbers
        if (response.reason_code && 
            (!response.trunk_number_transaction || !response.trunk_number_transaction.numbers) 
        ) {
            this.setModalResponseHandler(true);
            return {
                status: 1,
                reason_code: response.reason_code,
                reason_hash: response.hash,
            };
        }

        this.onAllocationGetResult(response);
        this.setShowAllocatedNumbersModal(true);
        return {status: 0};
    };


    onAllocationGetResult = (response) => {
        const {spAuth} = this.state;
        const {setTrunkNumberTransaction, setLoadingAllocatedNumbersDialer, setAccessLoadingAllocatedNumbers} = this.props;

        const notAllocatedNumbers = response.not_allocated_numbers;
        notAllocatedNumbers && this.setState({notAllocatedNumbers});
        
        const trunkNumberTransaction = response.trunk_number_transaction;
        setTrunkNumberTransaction(trunkNumberTransaction || {});

        if (trunkNumberTransaction) {
            const transactionId = trunkNumberTransaction.id;
            const allocatedNumbersCount = trunkNumberTransaction.numbers;
            this.setState({transactionId, allocatedNumbersCount});

            getFileResponse('trunk_number:get_list', {
                target: {
                    trunk_number_transaction_id: transactionId
                }
            }, true)
                .then((response) => {
                    if (spAuth) {
                        setAccessLoadingAllocatedNumbers(false);
                    } else {
                        setLoadingAllocatedNumbersDialer(false);
                    }
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        this.setState({
                            csvContent: text,
                            csvContentLoading: false,
                        })
                    });
                })
        }
    };


    onResponseHandlerSuccess = (value) => {
        const {isGoogleOtp} = this.state;
        if (isGoogleOtp) {
            this.setState({showGoogleOTPModal: true});
            return;
        }
        this.setShowGetNumbersModal(true);
    };


    onDownloadNumbers = (trunk_id) => this.props.downloadNumbers(trunk_id);

    
    downloadNumbers = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.csv');

        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };

    downloadNumbersTxt = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.txt');

        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };

    
    setModalResponseHandler = (value) => {
        this.setState({showModalResponseHandler: value});
    };

    
    setRandomNumber = (value) => {
        this.setState({
            randomNumber: value
        });
    };

    
    setFormValue = (value) => {
        this.setState({
            formValue: value
        });
    };

    
    setReasonModalProps = (props) => {
        this.setState({
            reasonModalProps: {...props}
        });
    };

    
    clearAllocatedData = () => {
        this.setState({
            csvContent: null,
            allocatedNumbersCount: 0
        })
    };


    setGoogleOTPFormValue = (value) => {
        this.setState({
            googleOTPFormValue: {...value}
        });
    };
    
    
    setShowGoogleOTPModal = (data) => {
        const {googleOTPFormValue} = this.state;

        this.setState({showGoogleOTPModal: true});
        if (data)
            this.setState({
                selectedData: data,
                googleOTPFormValue: {
                    ...googleOTPFormValue, 
                    template: data.template
                }
            })
    };
    
    
    exportPriceDropdown = () => {
        const {uploadLoading} = this.props;
        return (
            <StyledButtonToolbar>
                <StyledDropdown
                    color="violet"
                    appearance="default"
                    placement={this.state.resizedWidth < widthDesktopMin ? 'topStart' : 'bottomEnd'}
                    title={<>{uploadLoading ? <StyledLoader/> : <><Icon icon="upload2"/>{this.formatMessage(m.download)}</>}</>}
                    disabled={uploadLoading}
                >
                    <Dropdown.Item onClick={() => this.exportPrice()}>{this.formatMessage(m.exportPriceList)}</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.exportPricePdf()}>{this.formatMessage(m.exportPriceListAs, {type: "pdf"})}</Dropdown.Item>
                </StyledDropdown>
            </StyledButtonToolbar>
        )
    };


    render() {

        const {
            searchLoading, showGetNumbersModal, showAllocatedNumbersModal, showModalResponseHandler,savedPerPage,
            trunkId, csvContent, allocatedNumbersCount, notAllocatedNumbers, selectedData, filter, spAuth,
            isGoogleOtp, accountInfo, isTest, googleOTPFormValue, showGoogleOTPModal, reasonModalProps, csvContentLoading
        } = this.state;

        const {
            priceList, priceLoading, page, count, per_page, service_plan_list, account,
            trunk_number_transaction, setUploadingLoading, trunk_list, loadingGoogleOTPAllocation, accountDialerState
        } = this.props;

        const {currency_list, loadingAllocation, loadingAccessAllocation, uploadLoadingSetting, allocation_limit} = this.props;

        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const onShowGetNumbersModal = this.onShowGetNumbersModal;
        const setShowGetNumbersModal = this.setShowGetNumbersModal;
        const setShowAllocatedNumbersModal = this.setShowAllocatedNumbersModal;
        const getNumbers = this.getNumbers;
        const downloadNumbers = this.downloadNumbers;
        const downloadNumbersTxt = this.downloadNumbersTxt;
        const onDownloadNumbers = this.onDownloadNumbers;
        const setGoogleOTPFormValue = this.setGoogleOTPFormValue;
        const setShowGoogleOTPModal = this.setShowGoogleOTPModal;
        const onAllocateGoogleOTP = this.onAllocateGoogleOTP;

        return (
            <PanelLayout>
                <PageHeader title={this.formatMessage(m.ratecard)}/>

                <FlexGrid justify="space-between">
                    <FlexGridItem>
                        <PriceFilters
                            onChangeFilter={this.onChangeFilters}
                            loading={priceLoading || searchLoading}
                            prices={service_plan_list}
                            savedTableFilter={filter}
                            {...{
                                currency_list
                            }}
                        />
                    </FlexGridItem>
                    {this.state.resizedWidth >= widthDesktopMin &&
                        this.exportPriceDropdown()
                    }
                </FlexGrid>

                <Spacer/>

                <PriceTable
                    data={priceList}
                    loading={priceLoading || searchLoading}
                    filters={filter}
                    spAuth={spAuth}
                    isGoogleOtp={isGoogleOtp}
                    {...{
                        accountInfo,
                        isTest,
                        onChangePage,
                        onChangePerPage,
                        onShowGetNumbersModal,
                        setShowGoogleOTPModal,
                        page,
                        count,
                        per_page,
                        savedPerPage,
                        accountDialerState
                    }}
                />

                {this.state.resizedWidth < widthDesktopMin &&
                    this.exportPriceDropdown()
                }


                <PriceGetNumbersModal
                    show={showGetNumbersModal}
                    onClose={setShowGetNumbersModal}
                    onSend={getNumbers}
                    spAuth={spAuth}
                    randomNumber={this.state.randomNumber}
                    onChangeRandomNumber={this.setRandomNumber}

                    formValue={this.state.formValue}
                    onChangeFormValue={this.setFormValue}

                    setReasonModalProps={this.setReasonModalProps}
                    loading={loadingAllocation || loadingAccessAllocation}
                    trunks={trunk_list}
                    priceData={selectedData}
                    allocationLimit={allocation_limit}
                />


                <GoogleOTPAllocateModal
                    show={showGoogleOTPModal}
                    trunks={trunk_list}
                    formValue={googleOTPFormValue}
                    onChangeFormValue={setGoogleOTPFormValue}
                    loading={loadingGoogleOTPAllocation}
                    allocationLimit={allocation_limit}
                    onClose={() => this.setState({showGoogleOTPModal: false})}
                    setReasonModalProps={this.setReasonModalProps}
                    {...{
                        onAllocateGoogleOTP,
                    }}
                />
                <ModalAllocationResult
                    account={account}
                    show={showAllocatedNumbersModal}
                    maxWidth={isGoogleOtp ? 850 : 760}
                    onClose={() => {
                        this.clearAllocatedData();
                        this.setState({
                            notAllocatedNumbers: null,
                            csvContentLoading: true,
                        });
                        setShowAllocatedNumbersModal(false);
                    }}
                    priceData={selectedData}
                    downloadNumbers={downloadNumbers}
                    downloadNumbersTxt={downloadNumbersTxt}
                    onDownloadNumbers={onDownloadNumbers}
                    trunkId={trunkId}
                    methodName={'Ratecard'}
                    csvContent={csvContent}
                    notAllocatedNumbers={notAllocatedNumbers}
                    trunkNumberTransaction={trunk_number_transaction}
                    allocatedNumbersCount={allocatedNumbersCount}

                    loading={csvContentLoading}
                    uploadLoading={uploadLoadingSetting}
                    setUploadingLoading={setUploadingLoading}
                />

                <ModalResponseHandler
                    show={showModalResponseHandler}
                    onClose={this.setModalResponseHandler}
                    onSuccess={this.onResponseHandlerSuccess}
                    changeRandom={this.setRandomNumber}
                    params={reasonModalProps}
                />

            </PanelLayout>
        );

    }
}


export default injectIntl(Price)


const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 140px;
    display: inline-block; 
    margin-right: 10px;
    height: 36px;
    margin-bottom: 20px;
    ${props => props.width && css`
        width: ${props.width};
    `
    }
    .rtl & {
        margin-right: 0;
        margin-left: 10px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
`;


const StyledDropdown = styled(Dropdown)`
    width: 100%;
    height: 36px;
    
    &&&&& {
        .rs-btn {
            background-color: var(--color-brand2);
            width: 100%;
            color: white;
            height: 36px;
            padding: 8px 20px 8px 12px;
            
            &:hover {
                background-color: #5f2bb3;
                color: white;
            }
            
            .rtl & {
                padding: 8px 12px 8px 32px;
            }
        }
        
        .rs-dropdown-menu {
            background-color: #fdfdfd !important;
        }
        
        & > .rs-dropdown-item > .rs-dropdown-item-content:hover {
            background-color: #cfcfcf !important;
            color: #5c5c5c !important;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        @media (max-width: 768px) {
            width: 140px;
        }
    }
`;


const StyledLoader = styled(Loader)`
  & span.rs-loader-spin {
    margin-top: 3px;
    margin-right: 5px;
  }
`;