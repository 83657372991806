import React from 'react'
import List from 'rsuite/es/List'
import styled from 'styled-components';

export default ({contactList}) => {
    return (
        <>
            <StyledList bordered>
                {contactList.map(
                    (contact) => {
                        return <List.Item className="contact_list_item">
                            <div className="Container" dangerouslySetInnerHTML={{__html: contact}}/>
                        </List.Item>
                    }
                )}
            </StyledList>
        </>
    )
};

const StyledList = styled(List)`
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    
    .contact_list_item {
        max-width: 90%;
        word-break: break-all;
        line-break: auto;
    }
`;