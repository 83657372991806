import React, {useEffect, useState} from "react";
import ActionLogsFilter from "./ActionLogsFilter";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import ActionLogsTable from "./ActionLogsTable";
import ModalActionParams from "./ModalActionParams";
import ModalActionError from "./ModalActionError";

export default ({
    data,
    count,
    perPage,
    page,
    loading,
    managerList,
    referencesFetched,
    accountsFetched,
    actionList,
    userList,
    accounts,
    getReferences,
    getLogsList,
    getAccounts,
}) => {

    const today = new Date();
    const [filter, onChangeFilter] = useState({
        site_list: [4],
        range: [today, today]
    });
    const [showParamsModal, setShowParamsModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [currentLogItem, setCurrentLogItem] = useState(null);

    useEffect(() => {
        getReferences();
        getAccounts();
    }, []);

    useEffect(() => {
        getLogsList(filter, 1, perPage);
    }, [filter]);

    const getItems = (page, perPage) => {
        getLogsList(filter, page, perPage);
    };

    const toggleParamsModal = (show, data) => {
        setShowParamsModal(show);
        if (data) {
            setCurrentLogItem({...data});
        }
    };

    const toggleErrorModal = (show, data) => {
        setShowErrorModal(show);
        if (data) {
            setCurrentLogItem({...data});
        }
    };

    return (
        <PanelLayout>
            <PageHeader title={"Action Logs"}/>
            <ActionLogsFilter
                defaultFilter={filter}
                {...{
                    onChangeFilter,
                    userList,
                    actionList,
                    accounts
                }}
            />
            <ActionLogsTable
                loading={loading || !referencesFetched || !accountsFetched}
                {...{
                    data,
                    count,
                    perPage,
                    page,
                    toggleParamsModal,
                    toggleErrorModal
                }}
                getItems={getItems}
            />
            <ModalActionParams
                onClose={() => toggleParamsModal(false)}
                title={"Action Parameters"}
                show={showParamsModal}
                currentLogItem={currentLogItem}
            />
            <ModalActionError
                onClose={() => toggleErrorModal(false)}
                title={"Error result"}
                show={showErrorModal}
                currentLogItem={currentLogItem}
            />
        </PanelLayout>
    )
};