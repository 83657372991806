import React from 'react';
import {connect} from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import {Message} from 'rsuite';
import './audio-player-style.css';
import {useIntl} from "react-intl";
import {compose} from "redux";
import m from "../../definedMessages";
import PanelLayout from "../../components/base/PanelLayout";


const IVR = (props) => {
    const {formatMessage} = useIntl();
    return (
        <PanelLayout>
            <Message
                style={{
                    marginTop: '40px',
                    borderRadius: '5px',
                    background: '#85d9ef'
                }}
                showIcon
                type="info"
                title={formatMessage(m.listenToIvr)}
            />
            <AudioPlayer
                style={{
                    marginTop: '20px'
                }}
                src={`/${process.env.PUBLIC_URL}Complete_once_Premiumy_IVR.mp3`}
                autoPlay={false}
            />
        </PanelLayout>
    );
};

export default compose(
    connect(),
)(IVR);