import {SET_NUMBER_ALLOCATION_MODAL} from '../const/';
import {createAction} from './defaults';


const setStatusAllocationModal = createAction(SET_NUMBER_ALLOCATION_MODAL);
export const openAllocationModal = () => (dispatch) => {
    dispatch(setStatusAllocationModal(true));
};
export const closeAllocationModal = () => (dispatch) => {
    dispatch(setStatusAllocationModal(false));
};