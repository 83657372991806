import React from 'react';
import {
    API_Modal,
    MyForm,
    NumbersSelect,
    SDE_Component,
    NumberField,
    CheckboxControl,
    ErrorMessage
} from '../../components/';
import {Table, Alert, SelectPicker} from 'rsuite';
import {api} from '../../api/loginRoutes'
import styled from 'styled-components';
import RateField from "../../components/Form/RateField";
import {connect} from "react-redux";
import CustomField from '../../components/Form/CustomField';
import {ButtonPrimary} from "../../components/base/BaseButton";
import BaseTable from "../../components/base/BaseTable";


const AddNewSubdestination = ({p_key, currency_list, update, price_list, defaultSPKey, paymentTermsList}) => {
    const [show, setShow] = React.useState(false);
    const [disabledRanges, setDisabled] = React.useState(true);
    const [rates, setRates] = React.useState([]);
    const [touched, setTouched] = React.useState(false);
    const [sdeList, setSdeList] = React.useState([]);
    const [sde_key, setSdeKey] = React.useState(null);

    const [isEntered, setIsEntered] = React.useState(false);


    React.useEffect(() => {
        if(show) {
            setTouched(false);
            setRates([]);
            setSdeKey(null);
            setSdeList([]);
            api('newsubdestination_list_for_price', {p_key})
                .then( ({ subdestination_list: data }) => setSdeList(data || []))
        }
    }, [show]);

    const onChangeRate = (cur_key, pt_key, rate) => {
        if(!touched) setTouched(true);

        setRates(
            rates => {
                const _rates = rates.filter(
                    rateObj => rateObj.cur_key !== cur_key || rateObj.pt_key !== pt_key
                );

                if (rate)
                    return [..._rates, {cur_key, pt_key, rate}];

                return _rates
            }
        )
    };


    const field1 =<NumberField
        disabled = {disabledRanges}
        min={1}
        step={1}
        label = "Ranges"
        name = "ranges"
    />;
    const field2 = <NumbersSelect
        disabled = {disabledRanges}
        label = "Numbers"
        name = "numbers"
    />;

    const noGenerateRange = disabledRanges || !sde_key;

    const unsendFields = [];
    for (const currency of currency_list) {
        for (const term of paymentTermsList) {
            unsendFields.push(`${currency.cur_key}-${term.pt_key}`);
        }
    }

    return (
        <>
            <ButtonPrimary
                onClick={() => setShow(true)}
            >
                + Add subdestination
            </ButtonPrimary>

            {show &&
                <API_Modal
                    title = "Add new Subdestination"
                    onClose  = {() =>{
                        setDisabled(true);
                        setShow(false);
                        setIsEntered(false);
                    }}
                    successText = "Create"
                    update = {update}
                    checkFirstAll
                    checkBefore = {() => {
                        if(!touched) setTouched(true);

                        return !!rates.length;
                    }}
                    onEntered={() => setIsEntered(true)}
                >
                    <MyForm
                        method="service_plan_price:new_subdestination"
                        formDefaultValue = {{p_key}}
                        addData = {{
                            rates: rates
                        }}
                        noCheck
                        deepCloned={false}
                        //remove rates fields from request
                        unsendFields={unsendFields}
                    >

                        <CustomField
                            accepter={SelectPicker}
                            labelKey="name"
                            valueKey="p_key"
                            name="p_key"
                            data={price_list}
                            placeholder="Prices"
                            searchable={false}
                            cleanable={false}
                            classPrefix="allocated"
                        />
                        <SDE_Component
                            subdestination_list = {sdeList}
                            labelKey = "subdestination_name"
                            name = "sde_key"
                            validationKey = "num_required"
                            onChange = {(v) => setSdeKey(v)}
                        />
                        {touched && !rates.length &&
                            <ErrorMessage>
                                Must be filled at least one Currency.
                            </ErrorMessage>}
                        <TablePayments
                            data={currency_list}
                            onChange={onChangeRate}
                            paymentTermsList={paymentTermsList}
                            loading={!isEntered}
                        />
                    </MyForm>
                    <MyForm
                        formDefaultValue = {{sp_key: defaultSPKey, numbers: 1000}}
                        method="price_range__generate_by_sde"
                        checkResultKey = 'ranges'
                        update = { ({ranges}) => {
                            Alert.success(`Generated ${ranges || 0} ranges`)
                        }}
                        addData={{
                            sde_key
                        }}
                        noSend = {noGenerateRange}
                        deepCloned={false}
                    >
                        <CheckboxControl
                            onChange = {(value) => setDisabled(!value)}
                            useBr>
                            Make Ranges
                        </CheckboxControl>

                        {disabledRanges  ?  <Fieldset>{field1} {field2}</Fieldset> : <></>}
                        {!disabledRanges ?  field1 : <></>}
                        {!disabledRanges ?  field2 : <></>}
                    </MyForm>
                </API_Modal>
            }
        </>
    );
};

const mapStateToProps = ({references}) => ({
    price_list: references.price_list,
    defaultSPKey: references.defaultSPKey
});

export default connect(mapStateToProps)(AddNewSubdestination);

const { Column, HeaderCell, Cell } = Table;

const TablePayments =  ({data, disabled, onChange, paymentTermsList = []}) => (
    <BaseTable
        height={140}
        data={data}
        rowHeight={50}
    >
        <Column width={100}>
            <HeaderCell>Currency</HeaderCell>
            <Cell dataKey="name"/>
        </Column>

        {paymentTermsList.map(term => (
            <Column flexGrow={1}>
                <HeaderCell>{term.name.replace("_", "-")}</HeaderCell>
                <Cell>
                    { (row, i) => (
                        <RateField
                            width={paymentTermsList.length > 2 ? 110 : 135}
                            disabled = {disabled}
                            onChange = { v => onChange(row.cur_key, term.pt_key, +v)}
                            name={`${row.cur_key}-${term.pt_key}`}
                        />
                    ) }

                </Cell>
            </Column>
        ))}
        <Column key="table_scrollfix" width={0} fixed>
            <HeaderCell></HeaderCell>
            <Cell></Cell>
        </Column>
    </BaseTable>
);

const Fieldset = styled.fieldset`
    border: none;
    margin: 0;
    padding: 0;
     > div {
         margin-right: 10px;
         vertical-align: middle;
     }
`;