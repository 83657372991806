import React from "react";
import {FormControl, SelectPicker} from "rsuite";
import styled from "styled-components";

const LangSwitcher = ({name, placeholder, ...props}) => {

    const langs = [
        {lang_key: 'en', lang_name: 'English'},
        {lang_key: 'ar', lang_name: 'اللغة العربية'}
    ];

    return (
        <Switcher
            name={name || 'lang'}
            accepter={SelectPicker}
            data={langs}
            labelKey="lang_name"
            valueKey="lang_key"
            placeholder={placeholder || 'Select Language'}
            searchable={false}
            cleanable={false}
            {...props}
        />
    )
};

export default LangSwitcher;

const Switcher = styled(FormControl) `
    width: 180px;
`;