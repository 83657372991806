import {Modal} from "rsuite";
import styled from "styled-components";
import {ButtonPrimary, ButtonSubtle} from "./BaseButton";
import React from "react";
import {useIntl} from "react-intl";
import m from "../../definedMessages";


export const BaseModal = styled(Modal)`
    && {
        margin: 7.5% auto 0 auto;
        max-width: 100%;
        width: ${props => props.width || '600'}px;
        
        .rs-modal-header {
            position: absolute;
            width: 100%;
            top: -25px;
            
            .rs-modal-title {
                color: #FFF;
            }
            
            .rs-modal-header-close {
                right: 24px;
                top: 0;
                color: #fff;
            }
            
            @media (max-width: 800px) {
                left: 0;
                padding-left: 20px;
                padding-right: 0;
            }
        }
        
        .rs-modal-body {
            margin-top: 0;
            padding-top: 30px;
        }
    }
`;

export const CustomModalRefactored = (
    {
        show,
        title,
        children,

        // footer
        loading = false,
        disabled = false,
        extraDisabled = false,
        showFooter = false,
        confirmButtonText,
        confirmButtonStyle,
        cancelButtonText,
        cancelButtonStyle,
        focusTarget = null,

        // callbacks
        onClose,
        onConfirm,

        // crutches
        extraDialogClassName = "",
        checkBefore,

        ...props
    }
) => {

    const {formatMessage} = useIntl();

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName={extraDialogClassName}
            {...props}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <BaseModal.Body>
                {children}
            </BaseModal.Body>

            {showFooter &&
                <BaseModal.Footer>
                    <ButtonPrimary
                        appearance={confirmButtonStyle}
                        loading={loading}
                        disabled={disabled || extraDisabled}
                        autoFocus={focusTarget === "success"}
                        minWidth={0}
                        className="button-confirm"
                        onClick={() => {
                            if (checkBefore && !checkBefore())
                                return false;
                            onConfirm && onConfirm();
                        }}
                    >
                        {confirmButtonText || "Ok"}
                    </ButtonPrimary>

                    <ButtonSubtle
                        appearance={cancelButtonStyle}
                        disabled={extraDisabled}
                        autoFocus={focusTarget === "close"}
                        minWidth={0}
                        className="button-cancel"
                        onClick={onClose}
                    >
                        {cancelButtonText || formatMessage(m.cancel)}
                    </ButtonSubtle>

                </BaseModal.Footer>
            }

        </Modal>
    );
};

export const CustomModalClient = styled(CustomModalRefactored)`
    && {
        margin: 7.5% auto 0 auto;
        max-width: 100%;
        width: ${props => props.width || '600'}px;

        .rs-modal-header {
            padding-right: 0;
            display: flex;
            flex-direction: row-reverse;

            .rs-modal-header-close {
                position: static;
            }
        }
        
        .rs-modal-body {
            margin-top: 0;
            padding-top: 30px;
        }
    }
`;

export default BaseModal;