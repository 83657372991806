import React from "react";
import {Form, Input, Schema, SelectPicker} from "rsuite";
import CustomField from "../../../components/Form/CustomField";

const test_number_options = [
    {id: "none", label: "Do not create test number"},
    {id: "first", label: "Use first number as a test number"},
    {id: "random", label: "Use random number as a test number"},
];

const {StringType} = Schema.Types;

const formModel = Schema.Model({
    test_number_option: StringType().isRequired('This field is required'),
    number_list: StringType()
        .addRule((value) => {
            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ')
                .filter( s => s !== "");
            for (const row of valueList) {
                if (row.length > 15 || row.length < 7) {
                    return false;
                }
            }
            return true;
        }, 'Each row should be greater than 7 and less than 15')
        .addRule((value) => {
            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ')
                .filter( s => s !== "");
            return !((valueList).length > 100)
        }, 'The number of entered telephone numbers exceeds 100 rows')
        .pattern(/^(?: *\d+ *(?:\n|$))+$/, 'Please enter a valid numbers')
        .isRequired('This field is required'),
});

export default React.forwardRef( (props, ref) => {
    return (
        <Form
            layout="inline"
            ref={ref}
            model={formModel}
            method="price_range__generate_by_number_list"
        >

            <CustomField
                accepter={Input}
                name="number_list"
                validationKey="in_numbers_list_range"
                componentClass="textarea"
                wrap="off"
                rows={3}
                style={{width: 400}}
                placeholder="Number list(one per row)"
            />

            <CustomField
                block
                data={test_number_options}
                accepter={SelectPicker}
                labelKey="label"
                valueKey="id"
                placeholder="Test number option"
                name="test_number_option"
                searchable={false}
                cleanable={false}
            />
        </Form>
    );
});