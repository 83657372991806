import styled from "styled-components";
import {FlexboxGrid} from "rsuite";
import {SCREEN_MEDIA} from "../../const";

const {md} = SCREEN_MEDIA;
const defaultVSpace = 20;
const defaultHSpace = 10;


export const FlexGrid = styled(FlexboxGrid)`
    margin-bottom: ${props => props.vSpace || `-${defaultVSpace}px`};
    
    @media (min-width: ${md.min}px) {
        margin-left: ${props => props.hSpace || `-${defaultHSpace}px`};
        margin-right: ${props => props.hSpace || `-${defaultHSpace}px`};
    }

    && {
        flex-wrap: ${props => props.noWrap ? 'nowrap' : 'wrap'};
    }
`;

export const FlexGridItem = styled(FlexboxGrid.Item)`
    padding-bottom: ${props => props.vSpace || `${defaultVSpace}px`};
    position: relative;
    width: ${props => props.width ? props.width : "auto"};

    @media (min-width: ${md.min}px) {
        padding-left: ${props => props.hSpace || `${defaultHSpace}px`};
        padding-right: ${props => props.hSpace || `${defaultHSpace}px`};
    }
    
    && {
        flex-shrink: ${props => props.shrink || 1};
        flex-grow: ${props => props.grow || 0};
    }
`;