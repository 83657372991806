import React, {useContext, useState} from 'react';
import {renderColumn} from '../../../utils/helpers';
import CustomizableTable from '../../../components/client/CustomizableTable/CustomizableTable';
import FormHOC from '../../../hoc/FilltersForm';
import {RtlContext} from "../../../App";

export default FormHOC(({
    data,
    pickedColumns,
    savedTableColumns,
    loading,
    page,
    count,
    per_page,
    savedPerPage,
    onChangePerPage,
    onChangePage,
    onSaveColumnsTable,
    extraRows
}) => {

    const rtl = useContext(RtlContext);
    const [columns, onChangeColumns] = useState(savedTableColumns && savedTableColumns.length
        ? pickedColumns.filter(elem=>savedTableColumns.includes(elem.id))
        : pickedColumns);

    const renderedColumns = columns.map(renderColumn);

    return (
        <CustomizableTable
            {...{
                rtl,
                data,
                savedTableColumns,
                pickedColumns,
                onChangeColumns,
                count,
                per_page,
                savedPerPage,
                page,
                loading,
                onChangePage,
                onChangePerPage,
                onSaveColumnsTable,
                renderedColumns,
                extraRows
            }}
            isChangedColumns={true}
            isPaginated={true}
            rowClassName={rowData => {
                if (rowData && rowData.id === "summaries") {
                    return "summaries";
                }

                return "";
            }}
        />

    );
});
