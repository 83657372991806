import {defineMessages} from "react-intl";

export default defineMessages({
    exportPrice: { id: "price.exportPrice", defaultMessage: "Export price", description: 'Price: Export price' },
    exportPriceList: { id: "price.exportPriceList", defaultMessage: "Export price list", description: 'Price: Export price list' },
    exportPriceListAs: { id: "price.exportPriceListAs", defaultMessage: "Export price list as {type}", description: 'Price: Export price list as {type}' },
    filterPerPrefixOrRangeName: {
        id: "price.filterPerPrefixOrRangeName",
        defaultMessage: "Filter per prefix or range name",
        description: 'Price: Filter per prefix or range name'
    },
    rangeName: {id: "price.rangeName",
        defaultMessage: "Range name",
        description: 'Price: Range name'
    },
    getNumbersModal: {id: "price.getNumbersModal", defaultMessage: "Numbers(max {count})", description: 'Price, PriceGetNumbersModal: Numbers(max value)'},
    getNumbersForRange: {id: "price.getNumbersForRange", defaultMessage: "Get numbers for range", description: 'Price, PriceGetNumbersModal: Get numbers for range'},
    allTheTrafficMustBeClearedByTheCarrier: {
        id: "priceTable.allTheTrafficMustBeClearedByTheCarrier",
        defaultMessage: "Warning: All the traffic must be cleared by the carrier. Premiumy reserves the right to withhold the payment in case of dispute. All pbx traffic is subjected to be paid on 30/45 payment terms.",
        description: "PriceTable: Warning: All the traffic must be cleared by the carrier. Premiumy reserves the right to withhold the payment in case of dispute. All pbx traffic is subjected to be paid on 30/45 payment terms."
    },
    allTheTrafficWillBePaidOnMonthlyBasis: {
        id: "priceTable.allTheTrafficWillBePaidOnMonthlyBasis",
        defaultMessage: "All the traffic with less than {count} seconds duration will be paid on monthly basis",
        description: "PriceTable: All the traffic with less than {count} seconds duration will be paid on monthly basis"
    },
})