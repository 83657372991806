import React from "react";
import {Form, Schema, SelectPicker} from "rsuite";
import SDE from '../../../components/Filters/SDE';
import CustomField from "../../../components/Form/CustomField";
import NumberField from '../../../components/Form/NumberField';
import styled from "styled-components";

const {NumberType} = Schema.Types;

const numbers = ['1000', '10000', '100000', '1000000'].map(x => ({label: x, value: +x}));

const formModel = Schema.Model({
    sde_key: NumberType().isRequired('Required'),
    ranges: NumberType()
        .isRequired('Required')
        .isInteger('Only whole numbers')
        .max(100, "The maximum of this field is 100"),
});

export default React.forwardRef(({sde_key}, ref) => {
    return (
        <Form
            layout="inline"
            ref={ref}
            formDefaultValue={{
                numbers: 1000,
                ranges: 1,
                sde_key
            }}
            model={formModel}
            method="price_range__generate_by_sde"
        >
            <SdeWrapper>
                <SDE
                    name="sde_key"
                    validationKey="num_required"
                    classPrefix={undefined}
                />
            </SdeWrapper>

            <NumberField
                label="Number of ranges"
                name="ranges"
                placeholder="0"
                useBr
                min={1}
                max={Infinity}
                step={1}
            />

            <CustomField
                accepter={SelectPicker}
                data={numbers}
                label="Number per range"
                name="numbers"
                cleanable={false}
                searchable={false}
            />

        </Form>
    );
});

const SdeWrapper = styled.div`
    &&&& {
        display: inline-block;
        
        .rs-form-control-wrapper {
            margin-left: 0;
        }
    }
`;