import React from 'react';
import {FlexboxGrid} from 'rsuite';
import PanelLayout from '../../components/base/PanelLayout';
import TableServerSort from './../../components/Table/TableServerSort';
import Filters from './RangesFilters';
import Numbers from './Numbers';
import AddNewRange from '../Prices/Ranges/AddNewRange';
import DeleteRanges from './DeleteRanges';
import Loader from '../../components/Loader/Loader';
import {connect} from 'react-redux';
import {setCurrentTrunkId} from '../../actions/accounts';
import {getRanges} from '../../actions/ranges';
import {getNumbers} from '../../actions/numbers';
import queryString from 'query-string';
import {ASC_SORT} from 'const';
import {setCurrentRangeNumber} from 'actions';

class Ranges extends React.Component {
    constructor(props) {
        super(props);

        const urlParams = queryString.parse(props.history.location.search);

        this.filters = {
            sde_key: +urlParams.sde_key,
            de_key: +urlParams.de_key,
            wz_key: +urlParams.wz_key,
            only_with_unallocated_numbers: true
        };
    }

    /** @type Numbers **/
    refNumbers = null;
    /** @type TableServerSort **/
    refRangesTable = null;

    state = {
        pr_key: null,
        selected: {all: false, list: []},
        sort: {column: 'name', type: ASC_SORT},

        number_filters: {
            show_allocated_numbers: true,
            show_unallocated_numbers: true,
            show_test_numbers: true,
            show_block_allocation_numbers: true,
            show_only_block_allocation_numbers: false
        }
    };

    getSelectedList = () => ({...this.state.selected, countAll: this.props.rangesCount});

    async componentDidMount() {
        const {sort} = this.state;
        const {rangesPerPage} = this.props;
        await this.props.getRanges(this.filters, 1, rangesPerPage, sort);
        // The code below was the reason of the unnecessary API fetch (price_range_number:get_list)
        // If something went wrong - uncomment it and check FLUX one more time
        // (c) Applesinkin

        /*
        const {ranges} = this.props;
        if (ranges && ranges.length) {
            this.setState({pr_key: ranges[0].pr_key}, () => {
                this.getNumbers(this.refNumbers.getFilters(), 1, this.props.numbersPerPage);
            });
        }
        */

    }

    shouldComponentUpdate({ranges: _ranges, numbersPerPage}) {
        const {number_filters, pr_key} = this.state;
        const {ranges, getNumbers} = this.props;


        if (ranges !== _ranges && _ranges.length) {
            if (!pr_key) {
                this.setState({pr_key: _ranges[0].pr_key});
                getNumbers(_ranges[0].pr_key, number_filters, 1, numbersPerPage);
            } else {
                getNumbers(pr_key, number_filters, 1, numbersPerPage);
            }
        }
        if (ranges !== _ranges && !_ranges.length) {
            this.setState({pr_key: null});

            getNumbers(null, number_filters, 1, numbersPerPage);
        }

        return true;
    }

    componentWillMount() {
        const {pr_key} = this.state;
        const {ranges} = this.props;
        if (ranges && ranges.length && !pr_key) this.setState({pr_key: ranges[0].pr_key});
    }

    clearNumberFilters = () => {
        this.setState({
            number_filters: {
                show_allocated_numbers: true,
                show_unallocated_numbers: true,
                show_test_numbers: true,
                show_block_allocation_numbers: true,
                show_only_block_allocation_numbers: false
            }
        });
    };
    onChangeNumberFilters = (value) => {
        this.setState({number_filters: value});
    };

    clearSelected() {
        this.refRangesTable.clear();
        this.refNumbers.clearSelected();
    }

    onClickRange = (pr_key) => {
        const {number_filters} = this.state;

        this.setState({pr_key}, () => {
            this.getNumbers(number_filters, 1, this.props.numbersPerPage);
            this.refNumbers.clearSelected();
        });
    };

    onChangeFilters = (filters) => {
        this.filters = filters;
        this.props.getRanges(this.filters, 1, this.props.rangesPerPage, this.state.sort);
        this.clearSelected();
    };

    getNumbers = (filters, page, per_page, sort) => {
        if (this.props.rangesLoading) return;

        const {pr_key} = this.state;

        this.props.getNumbers(pr_key, filters, page, per_page, sort);
    };

    getRanges = (page, per_page, sort) => {
        this.props.getRanges(this.filters, page, per_page, sort);
    };

    render() {
        const {
            worldzone_list, subdestination_list, destination_list,
            ranges, rangesLoading, rangesPage, rangesCount, rangesPerPage,
            numbersLoading, numbersPage, numbersCount, numbersPerPage, permissions,
            account_id, setCurrentTrunkId, allocatedNumbersCount, setCurrentRangeNumber
        } = this.props;
        const {selected, number_filters} = this.state;


        //Clear numbers when no one range filtered
        let numbers = ranges && ranges.length ? this.props.numbers : [];
        const pickedRange = ranges && ranges.length ? ranges.find(range => range.pr_key === this.state.pr_key) : {};
        const sdeKey = pickedRange ? pickedRange.sde_key : null;

        return (
            <PanelLayout>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={12} style={{paddingRight: 20, borderRight: ' 3px solid #C4C4C4'}}>
                        <div style={{marginBottom: 25}}>
                            <div style={{display: 'inline-block', marginRight: 15}}>
                                <AddNewRange
                                    {...{
                                        subdestination_list
                                    }}
                                />
                            </div>
                            <DeleteRanges
                                permissionMethods={permissions}
                                disabled={!selected.list.length}
                                getList={this.getSelectedList}
                                update={() => {
                                    this.setState({pr_key: null});
                                    this.props.getRanges(this.filters, 1, rangesPerPage, this.state.sort);
                                    this.clearNumberFilters();
                                    this.clearSelected();
                                }}
                            />
                        </div>
                        <Filters
                            onChange={this.onChangeFilters}
                            defaultValues={this.filters}
                            {...{
                                worldzone_list,
                                subdestination_list,
                                destination_list,
                            }}/>

                        <TableServerSort
                            ref={ref => this.refRangesTable = ref}
                            data={ranges}
                            onRowClick={this.onClickRange}
                            loading={rangesLoading}
                            columns={columns}
                            count={rangesCount}
                            per_page={rangesPerPage}
                            page={rangesPage}
                            getItems={this.getRanges}
                            active_id={this.state.pr_key}
                            row_key="pr_key"
                            setSelected={selected => this.setState({selected})}
                            ispagination
                            isselected
                            isSetObject
                            style={{width: '100%'}}
                            onSort={(column, type) => this.setState({sort: {column, type}})}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12}>
                        <Loader show={rangesLoading} isLocal/>
                        <Numbers
                            ref={ref => this.refNumbers = ref}
                            getNumbers={this.getNumbers}
                            allocatedNumbersCount={allocatedNumbersCount}
                            updateRanges={()=>this.props.getRanges(this.filters, 1, this.props.rangesPerPage, this.state.sort)}
                            filters={number_filters}
                            onChangeNumberFilters={this.onChangeNumberFilters}
                            {...{
                                numbers,
                                numbersLoading,
                                numbersPage,
                                numbersCount,
                                numbersPerPage,
                                account_id,
                                setCurrentTrunkId,
                                setCurrentRangeNumber,
                                pr_key: this.state.pr_key,
                                sde_key: sdeKey
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </PanelLayout>
        );

    }
}

const columns = [
    {label: 'Range', dataKey: 'name', title: true, sortable: true, width: 300},
    {label: 'Test numbers', dataKey: 'test_number', width: 300},
    {
        label: 'Numbers',
        dataKey: 'all_numbers',
        formatData: (value, key, row) => (!row.blocked && row.allocated_numbers || '0') + '/' + (row.all_numbers || '0'),
        sortable: true
    }
];

const mapState = ({actions, references, ranges, numbers, auth}) => ({
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    defaultSPKey: references.defaultSPKey,

    ranges: ranges.ranges,
    rangesLoading: ranges.loading,
    rangesPage: ranges.page,
    rangesCount: ranges.count,
    rangesPerPage: ranges.per_page,

    numbers: numbers.numbers,
    numbersLoading: numbers.loading,
    numbersPage: numbers.page,
    numbersCount: numbers.count,
    allocatedNumbersCount: numbers.allocatedNumbersCount,
    numbersPerPage: numbers.per_page,
    account_id: auth.authInfo.session.account.id,
    permissions: auth.permissions
});

export default connect(mapState, {
    getRanges,
    getNumbers,
    setCurrentTrunkId,
    setCurrentRangeNumber
})(Ranges);