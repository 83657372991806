import {defineMessages} from "react-intl";

export default defineMessages({
    cancelPaymentRequest: {
        id: "payments.cancelPaymentRequest",
        defaultMessage: "Cancel payment request",
        description: 'Payments: Cancel payment request'
    },
    comment: {id: "payments.comment", defaultMessage: "Comment", description: 'Payments: Comment'},
    paymentInfo: {id: "payments.paymentInfo", defaultMessage: "Payment info", description: 'Payments: Payment info'},
    paymentRequest: {id: "payments.paymentRequest", defaultMessage: "Payment request", description: 'Payments: Payment request'},
    managePaymentDetails: {id: "payments.managePaymentDetails", defaultMessage: "Manage payment details", description: 'Payments: Manage payment details'},
    requestPayment: {id: "payments.requestPayment", defaultMessage: "Request payment", description: 'Payments: Request payment'},
    transferToAccountName: {id: "payments.transferToAccountName", defaultMessage: "Transfer to account {account_name}", description: "Payments: Transfer to account {account_name}"},
    pending: {id: "payments.pending", defaultMessage: "Pending", description: 'Payments: Pending'},
    readyForPayment: {id: "payments.readyForPayment", defaultMessage: "Ready for payment", description: 'Payments: Ready for payment'},
    paid: {id: "payments.paid", defaultMessage: "Paid", description: 'Payments: Paid'},

})