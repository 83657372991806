import {
    NUMBERS_DIALER as PREFIX,
    SET_ALL_ALLOCATED_NUMBER_DIALER,
    SET_ALLOCATED_NUMBER_DIALER,
    SET_TRUNK_NUMBER_DIALER,
    SET_TRUNK_NUMBER_ALLOCATED_LOADING_DIALER,
    SET_TRUNK_NUMBER_LOADING_DIALER,

    SET_LOADING_NUMBERS_ALLOCATED_NUMBERS_DIALER,
    SET_UPLOAD_TRUNK_LOADING_DIALER,
    SET_TRUNK_NUMBER_TRANSACTION_DIALER
} from '../../const';
import {
    ALLOCATION_NUMBER_LIST_DIALER_API,
    ALLOCATION_TEMPLATE_DIALER_API,
    ALLOCATION_GOOGLE_OTP_DIALER_API
} from "../../const/apiMethods";

const initialState = {
    items: [],
    loading: false,
    numberTrunkLoading: false,
    numberTrunks: [],
    allocatedNumbersLoading: false,
    loadingAllocatedNumbers: false,
    allocatedNumbers: [],
    allAllocatedNumbers: [],
    allocated_numbers_by_template: [],
    allocated_google_otp_numbers: [],
    allocated_numbers_by_number_list: [],
    count: 0,
    totalNumbersCount: 0,
    page: 1,
    per_page: 10,
    template: {},
    uploadTrunkLoading: {},
    trunk_number_transaction: {}
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload}) => ({...state, items: payload, loading: false}),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    [SET_LOADING_NUMBERS_ALLOCATED_NUMBERS_DIALER]: (state, {payload}) => ({...state, loadingAllocatedNumbers: payload}),
    [SET_TRUNK_NUMBER_DIALER]: (state, {payload: numberTrunks}) => ({...state, numberTrunks, numberTrunkLoading: false}),
    [SET_TRUNK_NUMBER_LOADING_DIALER]: (state, {payload: accountTrunkLoading}) => ({...state, accountTrunkLoading}),
    [SET_ALLOCATED_NUMBER_DIALER]: (state, {payload: {items, trunk_number_count, row_count, page, per_page}}) => ({
        ...state,
        allocatedNumbers: items,
        allocatedNumbersLoading: false,
        count: row_count,
        totalNumbersCount: trunk_number_count,
        page,
        per_page
    }),
    [SET_UPLOAD_TRUNK_LOADING_DIALER]: (state, {payload}) => ({...state, uploadTrunkLoading: {...state.uploadTrunkLoading, ...payload}}),
    [SET_ALL_ALLOCATED_NUMBER_DIALER]: (state, {payload: {trunk_number_list}}) => ({
        ...state,
        allAllocatedNumbers: trunk_number_list
    }),
    [SET_TRUNK_NUMBER_ALLOCATED_LOADING_DIALER]: (state, {payload: allocatedNumbersLoading}) => ({
        ...state,
        allocatedNumbersLoading
    }),
    [ALLOCATION_NUMBER_LIST_DIALER_API]: (state, {payload: {price_range_number_list}}) => ({
        ...state,
        allocated_numbers_by_number_list: price_range_number_list,
        loadingAllocatedNumbers: false
    }),
    [ALLOCATION_TEMPLATE_DIALER_API]: (state, {payload: {price_range_number_list}}) => ({
        ...state,
        allocated_numbers_by_template: price_range_number_list,
        loadingAllocatedNumbers: false
    }),
    [ALLOCATION_GOOGLE_OTP_DIALER_API]: (state, {payload: {price_range_number_list}}) => ({
        ...state,
        allocated_google_otp_numbers: price_range_number_list,
        loadingAllocatedNumbers: false
    }),
    [SET_TRUNK_NUMBER_TRANSACTION_DIALER]: (state, {payload}) => ({
        ...state,
        trunk_number_transaction: payload
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}