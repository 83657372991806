import React, {useEffect, useState} from "react";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import GoogleOtpAllocationTable from "./GoogleOtpAllocationTable";
import GoogleOtpAllocationCreateForm from './GoogleOtpAllocationCreateForm'
import GoogleOtpAllocationFilter from './GoogleOtpAllocationFilter'

export default ({
        data,
        loading,
        subdestinationList,
        getGoogleOtpAllocationList,
        createGoogleOtpAllocation,
        modifyGoogleOtpAllocation,
        removeGoogleOtpAllocation
    }) => {
    const [filter, onChangeFilter] = useState({});

    useEffect(() => {
        getGoogleOtpAllocationList(filter);
    }, [filter]);

    return (
        <>
            <PanelLayout>
                <PageHeader title="Google OTP allocation subdestination"/>

                <GoogleOtpAllocationFilter
                    defaultFilter={filter}
                    {...{
                        subdestinationList,
                        onChangeFilter
                    }}
                />
                <GoogleOtpAllocationTable
                    {...{
                        data,
                        loading,
                        filter,
                        subdestinationList,
                        getGoogleOtpAllocationList,
                        modifyGoogleOtpAllocation,
                        removeGoogleOtpAllocation
                    }}
                />
                <GoogleOtpAllocationCreateForm
                    onSubmit={createGoogleOtpAllocation}
                    {...{
                        getGoogleOtpAllocationList,
                        subdestinationList,
                    }}
                />
            </PanelLayout>
        </>
    )
};