import {connect} from 'react-redux';
import {getIvrList} from "../../../actions/ivr";
import {getDefaultIvr, changeDefaultIvr} from "../../../actions/ivr_default";
import PageIvrDefault from "./PageIvrDefault";

const mapState = ({ivr, ivr_default}) => ({
    ivrDefault: ivr_default.ivrDefault,
    ivrList: ivr.list,
});

export default connect(mapState, {
    getIvrList,
    getDefaultIvr,
    changeDefaultIvr,
})(PageIvrDefault);