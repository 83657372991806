import React, {useState, useEffect} from "react";
import {useWindowWidth} from "../../../hooks";
import {useIntl} from "react-intl";
import {parse} from 'object-flaser';
import {toBase64} from "../../../utils/helpers";
import {Spacer} from "../../../components/base/Spacer";
import {Form, Schema, Divider, Alert} from "rsuite";
import * as S from "./styled";
import m from "../../../definedMessages";

import {
    FieldInput, 
    FieldSelectCountry, 
    FieldFile, 
    FieldOrganizationType, 
    FieldPaymentMethod
} from "./Field";

import fields, {
    customerFields,
    companyFields,
    bankTransferFields, 
    paypalFields, 
    bitcoinFields, 
    hawalaFields, 
    otherTransferSystemsFields
} from "./fields";

const MAX_CHARACTERS = 255;

const methodFields = [
    bankTransferFields, 
    paypalFields, 
    otherTransferSystemsFields, 
    otherTransferSystemsFields, 
    hawalaFields,
    bitcoinFields
];

const {StringType, NumberType, ArrayType} = Schema.Types;


const maxFileSize = 5; // MB
const maxFiles = 5;

export default ({
    show,
    onClose,
    maxWidth = 1000,
    modify = false,
    organizationTypeList,
    countryList,
    paymentMethodList,
    createPaymentDetails,
    modifyPaymentDetails,
    getPaymentDetailsList,
    defaultFormValue = false,
}) => {
    
    const {formatMessage} = useIntl();

    const formModel = Schema.Model({
        // type fields
        ot_key: NumberType().isRequired( formatMessage(m.thisFieldIsRequired) ),
        pm_key: NumberType()
            .isRequired( formatMessage(m.thisFieldIsRequired) )
            .addRule((value, data) => {
                if (data.ot_key === 2 && value === 5)
                    return false;
            }, 'This method is not allowed with the "company" option'),

        // customer fields
        customer_name: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        customer_last_name: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        tax_vat_number: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        customer_cntr_code: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'customer_address.city': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'customer_address.region': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        'customer_address.address': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'customer_address.postal_code': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'customer_phone': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        ext_files: ArrayType()
            .addRule( (value, data) => {
                return (data.ot_key === 2 || (value && value.length > 0)) ? true : false;
            }, formatMessage(m.thisFieldIsRequired), true )
            .addRule( (value) => value.length > maxFiles ? false : true, `Maximum number of files is ${maxFiles}` )
            .addRule( (value) => {
                const names = [];
                for (const file of value) {
                    if (file.blobFile && file.blobFile.size > (1024 * 1024 * maxFileSize))
                        names.push(file.name);
                }
                return !names.length
            }, formatMessage(m.fileSizeShouldNotExceed, {size: maxFileSize}) ),

        // beneficiary fields
        beneficiary_name: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        beneficiary_last_name: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS}))
            .addRule( (value, data) => {
                if ( !value ) {
                    if (data.ot_key === 2 && data.pm_key === 1)
                        return true;
    
                    return false
                }
            }, formatMessage(m.thisFieldIsRequired), true ),
        beneficiary_cntr_code: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'beneficiary_address.region': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        'beneficiary_address.city': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'beneficiary_address.address': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'beneficiary_address.postal_code': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),

        // bank transfer fields
        bank_name: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        bank_cntr_code: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'bank_address.region': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        'bank_address.city': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        'bank_address.address': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        'bank_address.postal_code': StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        account_iban_number: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        swift_number: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        intermediate_bank_details: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),

        // paypal fields
        paypal_id: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        
        // btc fields
        btc_wallet_number: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
        
        // hawala fields
        hawala_description: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})).isRequired( formatMessage(m.thisFieldIsRequired) ),
    });


    let formRef = null;

    const [formValue, setFormValue] = useState(defaultFormValue || {ot_key: 1, pm_key: 1});
    const [activeFields, setActiveFields] = useState([]);
    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);


    // effect: change form data by defaults
    useEffect(() => {
        if (defaultFormValue) {
            setFormValue({...defaultFormValue});
        }
    }, [defaultFormValue]);


    // effect: set active fields
    useEffect( () => {
        const [personal, beneficiary] = setFormFields(formValue.ot_key, formValue.pm_key);
        setActiveFields([...personal, ...beneficiary]);
    }, [formValue.ot_key, formValue.pm_key] );


    // effect: prevent "hawala" && "company" option
    useEffect( () => {
        if (formValue.ot_key === 2 && formValue.pm_key === 5) {
            setFormValue({...formValue, pm_key: 1});
        }
    }, [formValue.ot_key, formValue.pm_key] );


    // submit data
    const handleFormSubmit = async () => {

        const fieldsValidity = [];
        for (const fieldName of activeFields) {
            fieldsValidity.push( formRef.checkForField(fieldName) );
        }
        if (fieldsValidity.includes(false))
            return;

        setLoading(true);
        const formValue = formRef.getFormValue();
        const data = {
            ot_key: formValue.ot_key,
            pm_key: formValue.pm_key
        };
        if (modify && formValue.id) {
            delete data.ot_key;
            delete data.pm_key;
            data.target = {
                payment_detail_id: formValue.id
            }
        }

        for (const field of activeFields) {
            
            if (formValue[field] === undefined || formValue[field] === null)
                continue;
           
            if (field === "ext_files") {
                const files = [];
                for (const file of formValue[field]) {
                    const fileBase64 = file.blobFile 
                        ? await toBase64(file.blobFile)
                        : file.url;
                    const [type, content] = fileBase64.split(',');
    
                    files.push( {
                        file: content,
                        type: type.split(';')[0].split(':')[1],
                        fileKey: file.fileKey,
                        name: file.name,
                        status: file.status
                    } );
                }
                data[field] = files;
                continue;
            }

            data[field] = formValue[field];
        }

        const response = !modify
            ? await createPaymentDetails( parse(data) ) 
            : await modifyPaymentDetails( parse(data) );

        if (response) {
            if (response instanceof Error) {
                Alert.error("No permissions");
            } else {
                getPaymentDetailsList();
                onClose(response);
            }
        }
        
        setLoading(false);
    };


    const setFormFields = (organizationType, paymentMethod) => {
        // company + hawala
        if (organizationType === 2 && paymentMethod === 5) {
            return [[], []];
        }
        // private person + hawala
        if (organizationType === 1 && paymentMethod === 5) {
            return [[], ['hawala_description']];
        }
        // default
        const organizationFields = organizationType === 1 ? customerFields : companyFields;
        return [organizationFields, methodFields[paymentMethod - 1]]
    };


    const renderFields = (fieldNames = [], formValue) => (

        fieldNames.map((fieldName) => {

            const {type, name, label: defaultLabel, labelId, hideOn, ...props} = fields[fieldName];

            // hide on option
            if (hideOn && Array.isArray(hideOn)) {
                for (const conditionItem of hideOn) {
                    const keys = Object.keys(conditionItem || {});
                    const status = keys.map(key => {
                        return formValue[key] && formValue[key] === conditionItem[key];
                    });
                    
                    if (!status.includes(false))
                        return null;
                }
            }

            // get label
            const label = labelId && m[labelId] ? formatMessage(m[labelId]) : defaultLabel;

            switch (type) {
                case 'select_country':
                    return <FieldSelectCountry key={name} name={name} label={label} data={countryList} {...props} />;

                case 'file':
                    const label_ = formValue.ot_key === 1 
                        ? formatMessage(m.passportScans)
                        : formatMessage(m.certificateOfIncorporation);
                    return <FieldFile key={name} name={name} defaultFileList={formValue[name]} label={label_} {...props} />;

                default:
                    return <FieldInput key={name} name={name} label={label} {...props} />
            }
        })
    );


    const clearForm = () => {
        setFormValue({ot_key: 1, pm_key: 1});
    };


    const cleanErrors = () => {
        formRef.cleanErrors();
    };

    return (
        <S.FormModal
            show={show}
            title={modify 
                ? formatMessage(m.modifyPaymentDetails)
                : formatMessage(m.addNewPaymentDetails)
            }
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            confirmButtonText={formatMessage(m.saveChanges)}
            loading={loading}
            keyboard={false}
            onConfirm={handleFormSubmit}
            onClose={() => {
                onClose(false);
            }}
            onExited={() => {
                clearForm();
            }}
        >

                <Form
                    ref={ref => formRef = ref}
                    model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >
                    <S.FlexBlock>
                        <S.FlexChild>
                            <FieldOrganizationType
                                data={organizationTypeList}
                                label={formatMessage(m.chooseOrganizationType)}
                                disabled={modify}
                                onChange={cleanErrors}
                            />
                        </S.FlexChild>

                        <S.FlexChild>
                            <FieldPaymentMethod
                                data={paymentMethodList}
                                label={formatMessage(m.paymentMethod)}
                                disabledItemValues={formValue.ot_key === 2 ? [5] : []}
                                disabled={modify}
                                onChange={cleanErrors}
                            />
                        </S.FlexChild>

                    </S.FlexBlock>

                    <Spacer size={30}/>

                    <S.FormColumns>
                        {setFormFields(formValue.ot_key, formValue.pm_key).map((fieldsGroup, index, arr) => {
                            if (!fieldsGroup.length)
                                return null;
                            
                            return (
                                <>
                                    {index > 0 && arr[0].length > 0 && 
                                        <Divider style={{width: 0}} vertical />
                                    }
                                    <S.FormColumn colWidth="50%">
                                        {renderFields(fieldsGroup, formValue)}
                                    </S.FormColumn>
                                </>
                            )
                        })}
                    </S.FormColumns>
                </Form>
        </S.FormModal>
    )
};