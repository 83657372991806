import React, {useState} from 'react'
import {Divider, Button, FlexboxGrid} from 'rsuite';
import ApiPageKeys from '../../../pages/Account/ApiPage/ApiPageKeys';
import PageHeader from '../../../components/base/PageHeader';
import ApiKeyModal from '../../../pages/Account/ApiPage/ApiKeyModal';
import '../../../pages/Account/ApiPage/ApiPage.css';


export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            createModal: false,
            rangeRows: [{ip_range_start: '', ip_range_end: ''}],
            createFormError: {},
            formChangeActive: false,
            addedNewApiKey: false
        };
    }

    componentDidMount() {
        // get keys
        const {getKeyList, account_id} = this.props;

        getKeyList(account_id);
    }

    // componentWillUpdate(nextProps) {
    //     const {keyList, getKeyList, account_id} = this.props;
    //     if (JSON.stringify(keyList) !== JSON.stringify(nextProps.keyList)) {
    //         getKeyList(account_id)
    //     }
    // }
    onAddedNewApiKey = (value) => {
        this.setState({addedNewApiKey: value});
    }

    onChangeCreateModal = (value) => {
        this.setState({createModal: value});
    };

    render() {
        const {keyList, getKeyList, deleteApiKey, modifyApiKey, createApiKey, account_id} = this.props;
        const {createModal, addedNewApiKey} = this.state;

        return (
            <>

                <PageHeader
                    title={"API Keys"}
                    style={{
                        marginBottom: '-20px',
                        marginLeft: '15px'
                    }}
                />
                <Divider/>
                <FlexboxGrid align="top" className="api_page-create">
                    <FlexboxGrid.Item className="api_page_create-wrapper">
                        <Button
                            appearance="primary"
                            className="api_page_create-button"
                            onClick={() => {
                                this.onChangeCreateModal(true);
                                this.onAddedNewApiKey(true);
                            }}
                        >
                            Create new
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid align="top" className="api_page-keylist">
                    <FlexboxGrid.Item className="api_page_keylist-wrapper">
                        <ApiPageKeys
                            keyList={keyList}
                            modifyApiKey={modifyApiKey}
                            deleteApiKey={deleteApiKey}
                            account_id={account_id}
                            addedNewApiKey={addedNewApiKey}
                            onAddedNewApiKey={this.onAddedNewApiKey}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                {createModal &&
                    <ApiKeyModal
                        title="Create new"
                        show={createModal}
                        onClose={this.onChangeCreateModal}
                        onSuccessMethod={createApiKey}
                        account_id={account_id}
                    />
                }
            </>
        );

    }

}