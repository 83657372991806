import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {Loader, Popover, Whisper, Icon} from 'rsuite';
import {TableClientSortHOC} from '../../components/Table/TableClientSort'
import { withRouter } from "react-router";
import {commentTypes} from "../../const";
import {api} from "../../api/loginRoutes";
import BaseTable, {Column, HeaderCell, Cell} from "../../components/base/BaseTable";
import styled from "styled-components";
import Badge from 'rsuite/es/Badge'
import Tooltip from 'rsuite/es/Tooltip'


export default TableClientSortHOC(withRouter(({
    data = [],
    loading,
    history,
    isMobile = false,
    ...props
}) => {

    const columns = useMemo( () => {

        const columns = [
            {
                flexGrow: 2,
                minWidth: 260,
                sortable: true,
                name: "Account",
                dataKey: "name",
                value: ({name, id, account_note_last, account_manager_name = ""}) => {
                    return <StyledAccountTableLink>
                        <AccountNotesIcon
                            type={account_note_last}
                            accountId={id}
                        />
                        <span className="white-space-pre">{name + '/' + account_manager_name}</span>
                    </StyledAccountTableLink>
                },
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 130,
                sortable: true,
                name: "Currency",
                dataKey: "currency",
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 170,
                sortable: true,
                align: 'right',
                dataKey: "balance",
                name: "Balance",
                value: ({balance, invoice_balance}) => balance + '/' + invoice_balance,
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 130,
                sortable: true,
                align: 'right',
                name: "Traffic for 1/7/30",
                dataKey: "VOLUME_1_OUT",
                value: ({VOLUME_1_OUT = 0, VOLUME_7_OUT = 0, VOLUME_30_OUT = 0}) => (
                    `${VOLUME_1_OUT}/${VOLUME_7_OUT}/${VOLUME_30_OUT}`
                ),
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 130,
                sortable: true,
                align: 'right',
                dataKey: "allocated_numbers",
                name: "Allocated Numbers",
                value: ({allocated_numbers = 0, numbers_with_traffic = 0}) => (
                    `${allocated_numbers || 0}/${numbers_with_traffic || 0}`
                ),
                isMobile: false,
            },
            {
                flexGrow: 1,
                minWidth: 130,
                sortable: true,
                align: 'right',
                name: "Creation date",
                dataKey: "created_at",
                value: ({created_at}) => {
                    const date = new Date(created_at);
                    const options = {year: 'numeric', month: 'long', day: 'numeric'};
                    return date.toLocaleDateString('en-US', options);
                },
                isMobile: false,
            },
            {
                width: 50,
                sortable: false,
                align: 'right',
                name: "",
                value: ({not_verified_users}) => {
                    return <AccountNotVerifiedUsersIcon
                        show={not_verified_users && not_verified_users !== 0 }
                        count={not_verified_users}
                    />
                },
                isMobile: false,
            },
            {
                flexGrow: 2,
                minWidth: 170,
                sortable: true,
                name: "Account",
                dataKey: "name",
                value: ({name, account_manager_name = "", account_note_last, id, payment_terms_name}) => (
                    <StyledAccountCellMobile>
                        <AccountNotesIcon
                            type={account_note_last}
                            accountId={id}
                        />
                        <div className="table-two-staged__cell">
                            <div
                                className="table-two-staged__cell-first"
                            >
                                {name}
                            </div>
                            <div
                                className="table-two-staged__cell-second"
                            >
                                {`${account_manager_name}, ${payment_terms_name}`}
                            </div>
                        </div>
                    </StyledAccountCellMobile>
                ),
                isMobile: true,
            },
            {
                flexGrow: 1,
                minWidth: 100,
                sortable: true,
                dataKey: "balance",
                name: "Balance",
                value: ({balance, invoice_balance, currency}) => (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{balance}</div>
                        <div className="table-two-staged__cell-second">{`${invoice_balance}, ${currency}`}</div>
                    </div>
                ),
                isMobile: true,
            },

            {
                width: 50,
                sortable: false,
                align: 'right',
                name: "",
                value: ({not_verified_users}) => {
                    return <AccountNotVerifiedUsersIcon
                        show={not_verified_users && not_verified_users !== 0 }
                        count={not_verified_users}
                    />
                },
                isMobile: true,
            },
        ];

        return columns.filter(column => column.isMobile === isMobile);

    }, [isMobile] );

    return (
        <BaseTable
            virtualized
            height={700}
            data={data}
            loading={loading}
            rowHeight={!isMobile ? 30 : 50}
            rowClassName={(rowData) => {
                if (rowData) {
                    let rowClassName = rowData.closed
                        ? 'table-row-account__closed'
                        : 'table-row-account table-row-account---link';
                    
                    if (!rowData.is_managed) {
                        rowClassName += " table-row-account--disabled";
                    }

                    return rowClassName;
                }
            }}
            onRowClick={ ({id, is_managed}) => {
                if (is_managed)
                    window.open(`/accounts/view/${id}`);
            }}
            {...props}
        >
            {columns.map(({dataKey, name, value = null, ...columnProps}) => (
                <Column {...columnProps}>
                    <HeaderCell>{name}</HeaderCell>
                    <Cell dataKey={dataKey}>{value}</Cell>
                </Column>
            ))}

            <Column key="table_scrollfix" width={0} fixed>
                <HeaderCell></HeaderCell>
                <Cell></Cell>
            </Column>

        </BaseTable>
    );
}));

const AccountNotVerifiedUsersIcon = ({show, count}) => {

    return (
        <StyledAccountNotVerifiedUsersIcon
            style={{
                width: 20,
                marginRight: 10,
                display: 'inline-block'
            }}
        >
            {show
                ? <Whisper
                    onClick={() => {
                        localStorage.setItem("ACCOUNT_ACTIVE_TAB", "general");
                    }}
                    placement="auto"
                    speaker={<Tooltip>Count of unconfirmed users: {count}</Tooltip>}
                >
                    <Badge
                        content={<span className="badge-count">{count}</span>}
                    >
                        <Icon icon="envelope-o" className="badge-icon"/>
                    </Badge>
                </Whisper>
                : null
            }
        </StyledAccountNotVerifiedUsersIcon>
    )
}

const AccountNotesIcon = ({
    accountId,
    type
}) => {

    const [comments, setComments] = useState([]);
    const [timerId, setTimerId] = useState(null);


    useEffect(() => {
        setComments([]);
    }, [timerId]);


    const getNotes = useCallback( () => {
        const timerId = setTimeout(() => {
            api('account_note:get_short_list', {
                target: {account_id: accountId}
            })
                .then(res => {
                    if (res && res.account_note_list)
                        setComments(res.account_note_list);
                })
        }, 500);
        setTimerId(timerId);
    }, [accountId] );


    const clearNotes = useCallback(() => {
        clearTimeout(timerId);
    }, [timerId]);


    return (
        <span style={{width: 20, display: 'inline-block'}}>
            {type || type === 0
                ? <Whisper
                    enterable={comments.length}
                    placement="auto"
                    speaker={
                        <Popover
                            title={"Recent notes"}
                            onClick={() => {
                                localStorage.setItem("ACCOUNT_ACTIVE_TAB", "notes");
                            }}
                        >
                            <div>
                                {comments && comments.length
                                    ? comments.map(item => (
                                        <div style={{textAlign: 'left'}}>
                                            <span
                                                className={"rs-icon rs-icon-" + commentTypes[item.type].icon}
                                                style={{
                                                    width: 20,
                                                    color: commentTypes[item.type].color,
                                                }}
                                            />
                                            &nbsp;{item.text}
                                        </div>
                                    ))
                                    : <Loader content="Loading..." style={{paddingTop: 6}} size="xs"/>
                                }
                            </div>
                        </Popover>
                    }
                >
                    <span
                        onClick={() => {
                            localStorage.setItem("ACCOUNT_ACTIVE_TAB", "notes");
                        }}
                        onMouseEnter={getNotes}
                        onMouseOut={clearNotes}
                        className={"rs-icon rs-icon-" + commentTypes[type].icon}
                        style={{color: commentTypes[type].color}}
                    />
                </Whisper>
                : null
            }
        </span>
    )
};


const StyledAccountTableLink = styled.span`
    .table-row-account--disabled .rs-table-cell-first & {
        color: #8e8e93;
        cursor: not-allowed;
    }
`;


const StyledAccountCellMobile = styled.div`
    display: flex;
    
    & > * {
        flex-shrink: 0;
    }
`;

const StyledAccountNotVerifiedUsersIcon = styled.span`
    .rs-badge {
    
        padding: 2px;
        font-size: 9px;
        
        .rs-badge-content {
            box-shadow: 0 0 0 1px #ffffff;
            background-color: var(--color-info);
            border-radius: 11px;
            font-size: 10px !important;
            line-height: 11px;
            padding: 0 2px;
            color: #ffffff;
            margin-right: 1px;
            margin-top: 3px;
            height: 11px;
            min-width: 11px;
            text-align: center;
        }
        
        .badge-count {
            font-weight: 700;
            font-size: 9px;
        }
    }
`;