import React from 'react';
import PageHeader from '../../components/base/PageHeader';
import AccessesFilters from './AccessesFilters';
import AccessesTable from './AccessesTable';
import ButtonDownload from '../../components/client/ButtonDownload';
import {
    LOCAL_STORAGE_ACCESSES_FILTERS_DIALER,
    LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from '../../const';
import {getBlobContent} from '../../utils/helpers';
import {defineMessages, injectIntl} from 'react-intl';
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {Spacer} from '../../components/base/Spacer';
import {SCREEN_MEDIA} from '../../const';
import styled from "styled-components";
import {getFileResponse} from '../../api/loginRoutes';
import {SP_OTP_TYPE} from "../../const/index";
import AccessGetNumbersModal from './AccessGetNumbersModal';
import PanelLayout from "../../components/base/PanelLayout";

import ModalResponseHandler from "../../components/client/Modal/ModalResponseHandler";
import ModalAllocationResult from '../../components/client/Modal/ModalAllocationResult';
import GoogleOTPAllocateModal from '../Numbers/GoogleOTPAllocateModal/GoogleOTPAllocateModal';
import axios from "axios";
import m from "../../definedMessages";


const {md} = SCREEN_MEDIA;
const widthDesktopMin = md.min;


class Accesses extends React.Component {

    constructor(props) {
        super(props);

        this.currentPerPage = null;
        this.defaultFilter = {str: '', sp_key: 1};
        this.savedTableFilter = JSON.parse( localStorage.getItem(LOCAL_STORAGE_ACCESSES_FILTERS_DIALER) );
        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);
        this.cancelTokenAccessesList = axios.CancelToken.source();

        const {account} = props.account.session || {};
        const currencyKey = ( (this.savedTableFilter && this.savedTableFilter.cur_key) || {} ).cur_key || account.cur_key;
        const isTest = account.hasOwnProperty('is_test') ? account.is_test : false;

        const filter = {
            ...this.defaultFilter,
            ...(this.savedTableFilter || {}),
            ...(isTest ? {cur_key: currencyKey} : {}),
        };

        if (!isTest && filter.cur_key) {
            delete filter.cur_key;
        }

        if ( props.service_plan_list
            && props.service_plan_list.length
            && !props.service_plan_list.find(item => item.sp_key == filter.sp_key)
        ) {
            filter.sp_key = props.service_plan_list[0].sp_key;
        }

        this.state = {
            currencyKey: currencyKey,
            isTest: isTest,
            filter: {...filter},
            selectedData: {},
            accountInfo: account,
            accountId: account.id,
            spAuth: false,
            isGoogleOtp: false,

            savedPerPage: JSON.parse( localStorage.getItem(LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER) ),
            searchLoading: false,
            trunkId: null,
            formValue: {trunk_id: null, numbers: 1},
            randomNumber: false,
            csvContent: null,
            transactionId: null,
            notAllocatedNumbers: null,
            allocatedNumbersCount: 0,
            googleOTPFormValue: {numbers: 100},
            showGoogleOTPModal: false,
            
            showGetNumbersModal: false,
            showNewNumbersModal: false,
            showModalResponseHandler: false,
            reasonModalProps: {},

            resizedWidth: window.innerWidth,
        };
    }


    componentDidMount() {
        const {savedPerPage, filter} = this.state;
        const {getTrunkList, getAccessList, per_page, service_plan_list} = this.props;
        this.currentPerPage = savedPerPage;

        getTrunkList();

        if (service_plan_list.length && filter.sp_key) {
            const currentServicePlan = service_plan_list.find( item => item.sp_key === filter.sp_key );
            this.setState({
                spAuth: currentServicePlan.auth,
                isGoogleOtp: currentServicePlan.otp_type === SP_OTP_TYPE
            });
            getAccessList(savedPerPage || per_page, filter, 1, currentServicePlan.auth);
        }

        // resize listener
        window.addEventListener('resize', this.handleResize);
    }

    
    componentDidUpdate(prevProps, prevState) {
        const {savedPerPage, filter} = this.state;
        const {getAccessList, per_page, service_plan_list} = this.props;

        if (service_plan_list.length && filter.sp_key) {
            if (prevState.filter !== filter || prevProps.service_plan_list !== service_plan_list) {
                const currentServicePlan = service_plan_list.find( item => item.sp_key === filter.sp_key );
                if (currentServicePlan) {
                    this.setState({
                        spAuth: currentServicePlan.auth,
                        isGoogleOtp: currentServicePlan.otp_type === SP_OTP_TYPE
                    });
                    if (typeof this.cancelTokenAccessesList !== typeof undefined) {
                        this.cancelTokenAccessesList.cancel("Operation canceled due to new request.");
                    }
                    this.cancelTokenAccessesList = axios.CancelToken.source();
                    getAccessList(savedPerPage || this.currentPerPage || per_page, filter, 1, currentServicePlan.auth, this.cancelTokenAccessesList);
                } else {
                    this.setState({
                        filter: {...filter, sp_key: service_plan_list[0].sp_key}
                    });
                }
            }
        }
    }

    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    onChangeFilters = (filtersObj) => {
        const {filter} = this.state;
        const newFilter = {...filter, ...filtersObj}
        this.setState({filter: newFilter});
        localStorage.setItem(LOCAL_STORAGE_ACCESSES_FILTERS_DIALER, JSON.stringify(newFilter));
    };

    
    onChangePerPage = (perPage) => {
        const {filter} = this.state;
        const {getAccessList, service_plan_list} = this.props;

        this.setState({savedPerPage: perPage ? null : this.currentPerPage});
        const per_page = Number(perPage) || this.currentPerPage;
        this.currentPerPage = per_page <= 0
            ? MIN_PER_PAGE_DIALER
            : per_page > MAX_PER_PAGE_DIALER ? MAX_PER_PAGE_DIALER : per_page;
        localStorage.setItem(LOCAL_STORAGE_ACCESSES_PER_PAGE_DIALER, JSON.stringify(this.currentPerPage));

        const currentServicePlan = service_plan_list.find( item => item.sp_key == filter.sp_key );
        getAccessList(this.currentPerPage, filter, 1, currentServicePlan.auth);
    };

    
    onChangePage = (pageNumber) => {
        const {savedPerPage, filter} = this.state;
        const {getAccessList, per_page, service_plan_list} = this.props;

        const currentServicePlan = service_plan_list.find( item => item.sp_key == filter.sp_key );
        getAccessList(savedPerPage || this.currentPerPage || per_page, filter, pageNumber, currentServicePlan.auth);
    };

    
    onDownloadNumbers = (trunk_id) => this.props.downloadNumbers(trunk_id);

    
    downloadAccesses = () => {
        const {accountId, filter} = this.state;
        const {downloadAccesses, service_plan_list} = this.props;

        const currentServicePlan = service_plan_list.find( item => item.sp_key == filter.sp_key );
        downloadAccesses(accountId, filter, currentServicePlan.auth);
    };

    
    getNumbers = async (data) => {
        const {allocateByAccess, setTrunkNumberTransaction, setAccessLoadingAllocatedNumbers} = this.props;
        const {selectedData} = this.state;
        const response = await allocateByAccess(data.trunk_id, selectedData.b_sde_key, data.numbers, data.random_order);
        
        if (response === undefined || (response.code && !response.reason_code))
            return;
        
        this.onShowGetNumbersModal(false);

        // reason_code recieved in an error message or with no numbers
        if (response.reason_code && 
            (!response.trunk_number_transaction || !response.trunk_number_transaction.numbers) 
        ) {
            this.setModalResponseHandler(true);
            setAccessLoadingAllocatedNumbers(false);
            return {
                status: 1,
                reason_code: response.reason_code,
                reason_hash: response.hash,
            };
        }

        this.onAllocationGetResult(response);
        
        this.setState({showNewNumbersModal: true, trunkId: data.trunk_id, });
        return {status: 0};
    };


    onShowGoogleOTPModal = (value, data) => {
        const {googleOTPFormValue} = this.state;

        this.setState({showGoogleOTPModal: value});
        if (value)
            this.setState({
                selectedData: data,
                googleOTPFormValue: {
                    ...googleOTPFormValue, 
                    sde_key: data.b_sde_key
                }
            })
    };


    onAllocateGoogleOTP = async (formValue) => {
        const {allocateGoogleOTPTrunkNumbers} = this.props;

        const response = await allocateGoogleOTPTrunkNumbers(formValue);
        if (response === undefined || (response.code && !response.reason_code))
            return;

        this.onShowGoogleOTPModal(false);
        this.setState({
            trunkId: formValue.trunk_id
        });

        // reason_code recieved in an error message or with no numbers
        if ( response.reason_code && (!response.trunk_number_transaction || !response.trunk_number_transaction.numbers) ) {
            this.setModalResponseHandler(true);
            return {
                status: 1,
                reason_code: response.reason_code,
                reason_hash: response.hash,
            };
        }

        this.onAllocationGetResult(response);
        this.onShowNewNumbersModal(true);
        return {status: 0};
    };


    onAllocationGetResult = (response) => {
        const {setTrunkNumberTransaction, setAccessLoadingAllocatedNumbers} = this.props;

        const notAllocatedNumbers = response.not_allocated_numbers;
        notAllocatedNumbers && this.setState({notAllocatedNumbers});

        const trunkNumberTransaction = response.trunk_number_transaction;
        setTrunkNumberTransaction(trunkNumberTransaction || {});

        if (trunkNumberTransaction) {
            const transactionId = trunkNumberTransaction.id;
            const allocatedNumbersCount = trunkNumberTransaction.numbers;
            this.setState({transactionId, allocatedNumbersCount});

            getFileResponse('trunk_number:get_list', {
                target: {
                    trunk_number_transaction_id: transactionId
                }
            }, true)
                .then((response) => {
                    setAccessLoadingAllocatedNumbers(false);
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        this.setState({
                            csvContent: text,
                        })
                    });
                })
        }
    };


    onResponseHandlerSuccess = (value) => {
        const {isGoogleOtp} = this.state;
        if (isGoogleOtp) {
            this.setState({showGoogleOTPModal: true});
            return;
        }
        this.onShowGetNumbersModal(value, this.state.selectedData);
    };


    downloadNumbers = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.csv');
        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };

    downloadNumbersTxt = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.txt');
        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };

    
    handleResize = () => this.setState({resizedWidth: window.innerWidth});
    
    setFormValue = (value) => this.setState({formValue: value});    
    
    setRandomNumber = (value) => this.setState({randomNumber: value});    
    
    setReasonModalProps = (props) => this.setState({reasonModalProps: props});
    
    setModalResponseHandler = (value) => this.setState({showModalResponseHandler: value});
    
    onShowNewNumbersModal = (state) => this.setState({showNewNumbersModal: state});
    
    onShowGetNumbersModal = (state, data = {}) => {
        this.setState({showGetNumbersModal: state});
        if (state)
            this.setState({selectedData: data})
    };

    setGoogleOTPFormValue = (value) => {
        this.setState({
            googleOTPFormValue: {...value}
        });
    };
    
    clearAllocatedData = () => this.setState({
        csvContent: null,
        allocatedNumbersCount: 0
    });


    render() {
        
        const {
            searchLoading, savedPerPage, showModalResponseHandler, showGetNumbersModal,
            showNewNumbersModal, trunkId, csvContent, allocatedNumbersCount, notAllocatedNumbers,
            selectedData, filter, spAuth, accountInfo, isTest, googleOTPFormValue, showGoogleOTPModal,
            isGoogleOtp, activeField
        } = this.state;

        const {
            account, accessesList, accessesLoading, page, count, service_plan_list, per_page, currency_list, 
            trunkList, loadingAllocation, loadingGoogleOTPAllocation, uploadLoading, uploadLoadingSetting, trunk_number_transaction, 
            allocation_limit, setUploadingLoading, accountDialerState
        } = this.props;

        const getNumbers = this.getNumbers;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const downloadAccesses = this.downloadAccesses;
        const onShowGetNumbersModal = this.onShowGetNumbersModal;
        const onShowNewNumbersModal = this.onShowNewNumbersModal;
        const downloadNumbers = this.downloadNumbers;
        const downloadNumbersTxt = this.downloadNumbersTxt;
        const onDownloadNumbers = this.onDownloadNumbers;
        const onShowGoogleOTPModal = this.onShowGoogleOTPModal;
        const setGoogleOTPFormValue = this.setGoogleOTPFormValue;
        const onAllocateGoogleOTP = this.onAllocateGoogleOTP;


        return (
            <PanelLayout>
                <PageHeader title={this.formatMessage(m.accessList)}/>

                <FlexGrid justify="space-between">
                    <FlexGridItem grow={1}>
                        <AccessesFilters
                            spAuth={spAuth}
                            onChangeFilter={this.onChangeFilters}             
                            loading={accessesLoading || searchLoading}
                            prices={service_plan_list}
                            savedTableFilter={this.savedTableFilter}
                            {...{currency_list}}
                        />
                    </FlexGridItem>
                    {this.state.resizedWidth >= widthDesktopMin &&
                        <FlexGridItem>
                            <ButtonDownload
                                disabled={!service_plan_list || (service_plan_list && !service_plan_list.length)}
                                onDownload={downloadAccesses}
                                limitWidth={widthDesktopMin}
                                buttonText={this.formatMessage(m.download)}
                                loading={uploadLoading}
                            />
                        </FlexGridItem>
                    }
                </FlexGrid>

                <Spacer/>

                <AccessesTable
                    data={accessesList}
                    spAuth={spAuth}
                    isGoogleOtp={isGoogleOtp}
                    loading={accessesLoading || searchLoading}
                    filter={filter}
                    servicePlanList={service_plan_list}
                    {...{
                        accountInfo,
                        isTest,
                        onShowGoogleOTPModal,
                        onShowGetNumbersModal,
                        onShowNewNumbersModal,
                        onChangePage,
                        onChangePerPage,
                        page,
                        count,
                        per_page,
                        savedPerPage,
                        accountDialerState,
                    }}
                />

                {this.state.resizedWidth < widthDesktopMin &&
                    <StyledActionButtonWrapper>
                        <ButtonDownload
                            disabled={!service_plan_list || (service_plan_list && !service_plan_list.length)}
                            onDownload={downloadAccesses}
                            limitWidth={widthDesktopMin}
                            buttonText={this.formatMessage(m.download)}
                            loading={uploadLoading}
                        />
                    </StyledActionButtonWrapper>
                }

                <AccessGetNumbersModal
                    show={showGetNumbersModal}
                    onSend={getNumbers}
                    onClose={() => onShowGetNumbersModal(false)}
                    loading={loadingAllocation}
                    allocationLimit={allocation_limit}
                    formValue={this.state.formValue}
                    onChangeFormValue={this.setFormValue}

                    randomNumber={this.state.randomNumber}
                    onChangeRandomNumber={this.setRandomNumber}

                    setReasonModalProps={this.setReasonModalProps}
                    selectedData={selectedData}
                    trunks={trunkList}
                />


                <GoogleOTPAllocateModal
                    show={showGoogleOTPModal}
                    trunks={trunkList}
                    formValue={googleOTPFormValue}
                    onChangeFormValue={setGoogleOTPFormValue}
                    loading={loadingGoogleOTPAllocation}
                    allocationLimit={allocation_limit}
                    onClose={onShowGoogleOTPModal}
                    activeField={googleOTPFormValue.sde_key ? "sde_key" : "template"}
                    setReasonModalProps={this.setReasonModalProps}
                    {...{
                        onAllocateGoogleOTP,
                    }}
                />

                <ModalAllocationResult
                    show={showNewNumbersModal}
                    maxWidth={!trunk_number_transaction || trunk_number_transaction.method2 !== "google_otp" ? 760 : 850}
                    account={account}
                    trunkId={trunkId}
                    csvContent={csvContent}
                    methodName={'AccessList'}
                    loading={loadingAllocation}
                    downloadNumbers={downloadNumbers}
                    downloadNumbersTxt={downloadNumbersTxt}
                    uploadLoading={uploadLoadingSetting}
                    onDownloadNumbers={onDownloadNumbers}
                    allocatedNumbersCount={allocatedNumbersCount}
                    notAllocatedNumbers={notAllocatedNumbers}
                    trunkNumberTransaction={trunk_number_transaction}
                    setUploadingLoading={setUploadingLoading}
                    onClose={() => {
                        this.clearAllocatedData();
                        this.setState({notAllocatedNumbers: null});
                        onShowNewNumbersModal(false);
                    }}
                />

                <ModalResponseHandler
                    show={showModalResponseHandler}
                    onClose={this.setModalResponseHandler}
                    onSuccess={this.onResponseHandlerSuccess}
                    changeRandom={this.setRandomNumber}
                    params={this.state.reasonModalProps}
                />


            </PanelLayout>
        );

    }
}


export default injectIntl(Accesses);


const StyledActionButtonWrapper = styled.div`
    padding-top: 15px;
    text-align: center;
    
    @media (min-width: ${widthDesktopMin}px) {
        text-align: right;
    }
`;