import React, {useState} from 'react';
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import {Alert, Checkbox, DatePicker} from 'rsuite';
import {toUTCDateTime} from 'utils';
import {ButtonClose as Revoke} from "../../../components/base/BaseButton";

export default ({getList, disabled, update, pr_key, filters}) => {
    const [show, setShow] = React.useState(false);
    const [_disabled, setDisabled] = React.useState(false);
    const [date, setDate] = useState(null);
    const [withDate, setWithDate] = useState(false);


    const onSubmit = async () => {
        setDisabled(true);

        const selected = getList();
        const numbersKeysList = selected.list;
        const selectedAll = selected.all;

        const params = {end_date: date};
        if (selectedAll) {
            params.pr_key = pr_key;
            params.filter = filters;
        } else {
            params.prn_key_list = numbersKeysList;
        }

        const result = await api('price_range_number:revoke_list', params);

        if (result && result.price_range_numbers) {
            Alert.success(`Generated ${result.price_range_numbers} numbers`);
            setShow(false);
            update();
        } else {
            Alert.warning(
                `${numbersKeysList.length > 1 ? 'These numbers' : 'This number'} can't be revoked`
            );
            setShow(false);
            update();
        }

        setWithDate(false);
        setDate(null)
        setDisabled(false);
    };

    const selectedCount = () => {
        const data = getList();

        console.log("data", data);

        if (data.all)
            return data.countAll;
        return data.list.length;
    };

    return (
        <>
            <Revoke disabled={disabled} onClick={() => setShow(true)}>Revoke</Revoke>
            {show && <Modal
                show={show}
                title="Revoke numbers"
                onClose={() => setShow(false)}
                footer={true}
                successText="Yes"
                disabled={_disabled}
                onSuccess={onSubmit}
            >
                Selected {selectedCount()} numbers from this range will be revoked.
                Do you really want to do it?
                <div className="text-center mt-4">
                    <Checkbox inline className="mr-2" onChange={(v, checked) => {
                        setWithDate(checked);

                        if (!checked) {
                            setDate(null);
                        }
                    }}>From date</Checkbox>
                    <DatePicker 
                        placeholder="Enter Date" 
                        disabled={!withDate} 
                        onChange={(date) => {
                            date && setDate(toUTCDateTime(date));
                        }}
                        ranges={[
                            {
                                label: 'Today',
                                value: new Date()
                            },
                            {
                                label: 'Tomorrow',
                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                            }
                        ]}
                    />
                </div>
            </Modal>
            }
        </>

    );
}