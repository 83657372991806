import React from "react";
import {Input, InputGroup} from "rsuite";
import styled from "styled-components";

export default (props) => {
    return (
        <StyledField>
            <InputGroup>
                <Input
                    placeholder={'Sound filename, IVR, CLI or phone'}
                />
            </InputGroup>
        </StyledField>
    )
}

const StyledField = styled.div`
    max-width: 300px;
    width: 100%;
`;