import React from 'react';
import {FormControl, Input, Schema} from 'rsuite';
import MyForm from '../../../../../components/MyForm';
import {getBlobContent, splitList} from '../../../../../utils';
import {FlexboxGrid, Icon} from 'rsuite/es';
import {MIN_RATE_VALUE, valueAllocationLimitList} from 'const';
import {getFileResponse} from 'api/loginRoutes';
import styled from 'styled-components';
import {Spacer} from "../../../../../components/base/Spacer";

const {NumberType, StringType} = Schema.Types;



export default ({
    activeTab, showResult, onSelect, target, activeTrunk,
    setHideBufferButtons, allocationLimit, setSummaryState, setTransactionId, setTotalNumbersForced,
    setCsvContent, setAllocateByPrefixesStatus, setNotAllocatedNumbers, isMobile, ...props
}) => {

    let timerid = null;
    const [number_list, setNumberList] = React.useState([]);
    const [showRateInput, onChangeShowRateInput] = React.useState(null);
    const [customRateValue, onChangeCustomRateValue] = React.useState(null);
    const [valueForm, setValueForm] = React.useState({});

    const changeNumberList = (str) => {
        clearTimeout(timerid);

        timerid = setTimeout((str) => {
            setNumberList(splitList(str));
        }, 300, str);
    };

    const model = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
            .max(10, 'The maximum of this field is 10'),
        number_list: StringType()
            .isRequired('Required')
            .pattern(/^(?: *\d+ *(?:\n|$))+$/, 'Please enter a valid numbers')
            .addRule((value) => {
                const valueList = splitList(value);
                for (const row of valueList) {
                    if (row.length > 15 || row.length < 7) {
                        return false
                    }
                }
                return true;
            }, 'Each row should be greater than 7 and less than 15')
            .addRule((value) => !(splitList(value).length > (valueAllocationLimitList === -1 ? Infinity : valueAllocationLimitList)),
                `The number of entered telephone numbers exceeds ${valueAllocationLimitList === -1 ? 'Infinity' : valueAllocationLimitList} rows`),

    };

    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            getFileResponse("trunk_number:get_list", {target: {trunk_number_transaction_id: transactionId}}, true)
                .then((response) => {
                    const csvText =  getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    })
                });

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);
            setAllocateByPrefixesStatus(false);
            setHideBufferButtons(false);
            setNotAllocatedNumbers(not_allocated_numbers);
            showResult();
        }
    };

    const rateField = (
        <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>

            {!showRateInput ? <FlexboxGrid.Item>
                    <div
                        style={{
                            marginTop: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            onChangeShowRateInput(true);
                        }}
                    >
                        <Icon icon="book" inverse
                              style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'}}/>
                        <a
                            style={{
                                color: showRateInput === null ? '#2f63bf' : '#5e2f9d',
                            }}
                        >
                            Put custom rate
                        </a>
                    </div>

                </FlexboxGrid.Item>
                : <>
                    <FlexboxGrid.Item
                        style={{
                            marginTop: '8px',
                            marginRight: '10px'
                        }}
                    >
                        Rate
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        <FormControl
                            style={{
                                width: 140
                            }}
                            errorPlacement="bottomEnd"
                            placeholder="0.0001"
                            name="rate"
                            value={customRateValue}
                            onChange={(value) => {
                                onChangeCustomRateValue(value);
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item
                        style={{
                            marginTop: '8px',
                            marginRight: '10px'
                        }}
                    >
                        <Icon
                            icon="close"
                            inverse
                            style={{
                                color: '#5e2f9d',
                                marginRight: '5px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                onChangeShowRateInput(false);
                                onChangeCustomRateValue(null)
                            }}
                        />
                    </FlexboxGrid.Item>
                </>
            }
        </FlexboxGrid>
    );

    return (
        <FormWrapper>
            <MyForm
                activeTrunk={activeTrunk}
                target={target}
                method="allocation:number_list"
                checkResultKey='price_range_number_list'
                update={handleUpdateNew}
                onChange={(value) => {
                    setValueForm(value);
                }}
                value={valueForm}
                addData={{
                    number_list,
                    rate: customRateValue !== null ? +customRateValue : null
                }}
                {...props}
                layout="vertical"
                allocateByPrefixesStatus={null}
                model={model}

                deepCloned={true}
            >

                {!isMobile && rateField}

                <div style={{margin: '0 0 5px 0'}}> Number list</div>

                <FormControl
                    accepter={Input}
                    errorPlacement="topRight"
                    componentClass="textarea"
                    name='number_list'
                    onChange={changeNumberList}
                    rows={3}
                    style={{width: '100%', height: 500, resize: 'auto'}}
                    placeholder="Enter your numbers here"
                />

                {isMobile &&
                    <>
                        <Spacer size={10}/>
                        {rateField}
                    </>
                }

            </MyForm>
        </FormWrapper>
    );
};

const FormWrapper = styled.div`
    @media (min-width: 768px) {
        margin-left: 40px;
        marginRight: 40px;
    }
    
    & {
        textarea.rs-input {
            min-width: 1px;
            
            @media (min-width: 767px) {
                min-width: 300px;
            }
        }
    }
`;