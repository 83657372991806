import React, {useEffect, useState} from 'react';
import Table from '../../components/Table/TableServerSort';
import {connect} from "react-redux";
import {getCDRList} from "../../actions/cdr";
import {ASC_SORT, DESC_SORT} from 'const';



const CDRTable = (props) => {
    const [sort, setSort] = useState({column: 'datetime', type: DESC_SORT});
    const {cdrList, loading, count, perPage, page, filters, permissions_add} = props;
    const noMoneyMatch = !permissions_add || (permissions_add && !permissions_add['no_money']);
    const columns = [
        {label: 'Date Time', dataKey: 'datetime', formatData: 'datetime', flexGrow: 1, minWidth: 170, title: true, sortable: true},
        {label: 'Originator', dataKey: 'originator_name', minWidth: 200, sortable: true},
        {label: 'Tprefix', fullLabel: 'Originator Tprefix', dataKey: 'tprefix_originator', width: 90, hideDefault: true, sortable: true},
        ...(noMoneyMatch ? [{label: 'Rate', fullLabel: 'Originator Rate', dataKey: 'rate_originator', width: 75, sortable: true, align: 'right'}] : []),
        ...(noMoneyMatch ? [{label: 'Cost', fullLabel: 'Originator Cost', dataKey: 'cost_originator', width: 75, sortable: true, align: 'right'}] : []),
        ...(noMoneyMatch ? [{
            label: 'Margin',
            fullLabel: 'Originator Margin',
            dataKey: 'margin',
            value: (({margin}) => margin !== null && margin !== undefined && !isNaN(margin) ? margin.toFixed(5) : ''),
            width: 80,
            sortable: true,
            align: 'right'
        }] : []),
        {label: 'A-Number', dataKey: 'a_number', width: 120, sortable: true},
        {label: 'Origin', dataKey: 'a_subdestination_name', minWidth: 200, title: true, sortable: true},
        {label: 'B-Number', dataKey: 'b_number', width: 120, sortable: true},
        {label: 'Destination', dataKey: 'b_subdestination_name', minWidth: 250, title: true, sortable: true},
        {label: 'Range', dataKey: 'range', minWidth: 300, title: true, sortable: true},
        {
            label: 'Dialer', 
            dataKey: 'dialer_name', 
            value: ({dialer_name, account_manager_name}) => `${dialer_name} / ${account_manager_name || 'no manager'}`,
            minWidth: 250, 
            sortable: true
        },
        {label: 'TPrefix', fullLabel: 'Dialer TPrefix', dataKey: 'tprefix_dialer', width: 90, hideDefault: true, sortable: true},
        ...(noMoneyMatch ? [{label: 'Rate',fullLabel: 'Dialer Rate', dataKey: 'rate_dialer', width: 75, sortable: true, align: 'right'}] : []),
        ...(noMoneyMatch ? [{label: 'Cost',fullLabel: 'Dialer Cost', dataKey: 'cost_dialer', width: 75, sortable: true, align: 'right'}] : []),
        {label: 'Term. Point',
            dataKey: 'term_point',
            minWidth: 200,
            hideDefault: true,
            sortable: true
        },
        {label: 'Duration', dataKey: 'duration', width: 100, sortable: true},
        {label: 'SIP Cause', dataKey: 'sip_cause', width: 100, sortable: true, align: 'right'},
    ];

    let getCDRItems = (page, perPage, sort) => {
        props.getCDRList(filters, page, perPage, sort);
    };

    useEffect(() => {
        props.getCDRList(filters, 1, perPage, sort);
    }, [filters]);

    return (
        <Table
            data = {cdrList}
            loading = {loading}
            columns = {columns}
            count = {count}
            per_page = {perPage}
            page = {page}
            getItems = {getCDRItems}
            ispagination
            columnSelectorLSKey="CDRTable"
            defaultSortColumn="datetime"
            defaultSortType={DESC_SORT}
            onSort={(column, type) => setSort({column, type})}
        />
    );
};

const mapStateToProps = ({cdr, auth}) => ({
    loading: cdr.loading,
    cdrList: cdr.list,
    count: cdr.count,
    perPage: cdr.perPage,
    page: cdr.page,
    permissions_add: Object.keys(auth.permissions_add).includes('cdr_full:group_get_list_admin') ? auth.permissions_add['cdr_full:group_get_list_admin'] : null
});

const mapDispatchToProps = {
    getCDRList
};

export default connect(mapStateToProps, mapDispatchToProps)(CDRTable);
