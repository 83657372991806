import {
    ACCESS_LIST_DIALER as PREFIX,
    REFERENCES_SERVICE_PLAN_DIALER,
    SET_LOADING_ACCESS_ALLOCATED_NUMBERS_DIALER
} from '../../const';
import {DEFAULT_PER_PAGE_DIALER} from '../../const';

const initialState = {
    items: [],
    service_plan_list: [],
    count: 0,
    page: 1,
    per_page: DEFAULT_PER_PAGE_DIALER,
    loading: false,
    loadingAllocatedNumbers: false
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {count, page, access_list_list, per_page}}) => ({
        ...state,
        count,
        items: access_list_list,
        page,
        loading: false,
        per_page
    }),
    ['SET_LOADING_' + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    [SET_LOADING_ACCESS_ALLOCATED_NUMBERS_DIALER]: (state, {payload}) => ({...state, loadingAllocatedNumbers: payload}),
    [REFERENCES_SERVICE_PLAN_DIALER]: (state, {payload:  service_plan_list}) => ({...state,  service_plan_list}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);

}