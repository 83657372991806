import React from 'react';
import TrafficStatTable from './TrafficStatTable';
import TrafficStatFilters from './TrafficStatFilters';
import {removeTZFromDate} from '../../../utils/helpers';
import {ButtonPrimary} from '../../../components/base/BaseButton';
import {
    LOCAL_STORAGE_TRAFFIC_STAT_COLUMNS_DIALER,
    LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER,
    LOCAL_STORAGE_TRAFFIC_STAT_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from '../../../const';
import {injectIntl} from "react-intl";
import {FlexGrid, FlexGridItem} from '../../../components/base/FlexGrid';
import { Spacer } from '../../../components/base/Spacer';
import {Drawer, Icon, IconButton} from "rsuite";
import styled from "styled-components";
import m from "../../../definedMessages";
import ButtonDownload from "../../../components/client/ButtonDownload";


class TrafficStat extends React.Component {
    constructor(props) {
        super(props);

        const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
        const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

        const savedTableFilter = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER));
        localStorage.removeItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS_DIALER);

        this.defaultFilter = savedTableFilter
            ? {...savedTableFilter}
            : {
                start_end_date: [todayDateStart, todayDateEnd],
                start_date: removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
                end_date: removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999))),
                group: 'range/a_number/b_number',
                checkbox: ['range', 'a_number', 'b_number']
            };

        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);

        this.curPerPage = null;
        this.columns = [
            {
                id: 'A-Number',
                label: this.formatMessage(m.aNumber),
                dataKey: 'a_number',
                flexGrow: 5, 
                minWidth: 130,
                align: 'center'
            },
            {
                id: 'B-Number',
                label: this.formatMessage(m.bNumber),
                dataKey: 'b_number',
                flexGrow: 5,
                minWidth: 130,
                align: 'center'
            },
            {
                id: 'range', 
                label: this.formatMessage(m.ranges), 
                dataKey: 'range',
                flexGrow: 5, 
                minWidth: 150, 
                align: 'center'
            },
            {
                id: 'duration', 
                label: this.formatMessage(m.duration), 
                dataKey: 'sum_duration',
                value: (item) => {
                    return item.id === "summaries"
                        ? <span title={item.sum_duration}>{item.sum_duration}</span>
                        : item.sum_duration
                },
                flexGrow: 3, 
                minWidth: 80, 
                align: 'right'
            },
            {
                id: 'call', 
                label: this.formatMessage(m.calls), 
                dataKey: 'count_cost_dialer', 
                flexGrow: 3,
                minWidth: 80, 
                align: 'right'
            },
            {
                id: 'cost', 
                label: this.formatMessage(m.cost), 
                dataKey: 'sum_cost_dialer', 
                flexGrow: 3,
                minWidth: 80,
                align: 'right'
            },

        ];

        this.state = {
            searchLoading: false,
            filter: {...this.defaultFilter},
            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_PER_PAGE_DIALER)),
            savedTableColumns: JSON.parse(localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_COLUMNS_DIALER)),
            resizedWidth: window.innerWidth,
            activeFiltersCount: 0,
            showFilters: false,
        };
    }

    componentDidMount() {
        const {savedPerPage, filter} = this.state;
        const {getTrafficStat, per_page} = this.props;
        this.setActiveFiltersCount(filter);
        this.curPerPage = savedPerPage;

        getTrafficStat(savedPerPage || per_page, filter, 1, filter.group, filter.checkbox);
    }


    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    onChangeFilters = (filtersObj) => {
        const {savedPerPage} = this.state;
        const {getTrafficStat, per_page} = this.props;

        this.setActiveFiltersCount(filtersObj);

        this.setState({
            filter: filtersObj
        });

        getTrafficStat(savedPerPage || this.curPerPage || per_page, filtersObj, 1, filtersObj.group, filtersObj.checkbox);

    };

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        let activeFilters = 0;
        activeFilters = value.checkbox.length !== 3 ? activeFilters + 1 : activeFilters;
        activeFilters = value.a_number ? activeFilters + 1 : activeFilters;

        this.setState({
            activeFiltersCount: activeFilters + 1
        });
    };

    onChangePerPage = (perPage) => {
        const {filter} = this.state;
        const {getTrafficStat} = this.props;

        if (perPage)
            this.setState({savedPerPage: null});
        else
            this.setState({savedPerPage: this.curPerPage});

        const per_page = Number(perPage) || this.curPerPage;

        this.curPerPage = per_page <= 0
            ? MIN_PER_PAGE_DIALER
            : per_page > MAX_PER_PAGE_DIALER ? MAX_PER_PAGE_DIALER : per_page;

        localStorage.setItem(LOCAL_STORAGE_TRAFFIC_STAT_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        getTrafficStat(this.curPerPage, filter, 1, filter.group, filter.checkbox);
    };

    onChangePage = (pageNumber) => {
        const {filter, savedPerPage} = this.state;
        const {getTrafficStat, per_page} = this.props;

        getTrafficStat(savedPerPage || this.curPerPage || per_page, filter, pageNumber, filter.group, filter.checkbox);
    };

    exportReport = () => {
        const {filter} = this.state;
        this.props.exportReport(filter, filter.group, filter.checkbox);
    };

    onSaveColumnsTable = (idListToSave) => {
        localStorage.setItem(LOCAL_STORAGE_TRAFFIC_STAT_COLUMNS_DIALER, JSON.stringify(idListToSave));
    };

    render() {
        const {searchLoading, filter, savedPerPage, savedTableColumns} = this.state;
        const {loading, uploadLoading, trafficStat, page, count, per_page, summaries, globalSettings} = this.props;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const exportReport = this.exportReport;
        const onSaveColumnsTable = this.onSaveColumnsTable;

        // remake with reselector
        let dataWithSummaries = [];
        let summariesData = {};
        
        if (Object.keys(summaries).length && trafficStat.length) {
            summariesData = {...trafficStat[0]};

            Object.keys(summariesData).forEach(item => {

                switch (item) {
                    case "sum_cost_dialer":
                        summariesData[item] = summaries["cost"] !== undefined ? summaries["cost"] : null;
                        break;

                    case "count_cost_dialer":
                        summariesData[item] = summaries["calls"] || null;
                        break;

                    case "sum_duration":
                        summariesData[item] = summaries["duration"] || null;
                        break;
                    
                    case "id":
                        summariesData[item] = "summaries";
                        break;
                    
                    default:
                        summariesData[item] = summaries[item] || null;
                }
                
            });

            dataWithSummaries = [...trafficStat];
            dataWithSummaries.push(summariesData);
        }

        return (
            <>
                {this.state.resizedWidth < 768 &&
                    <>
                        <Toggler
                            icon={<Icon icon="filter"/>}
                            onClick={() => this.setState({showFilters: true})}
                        >
                            {this.state.activeFiltersCount}
                        </Toggler>
                        <Drawer
                            size="xs"
                            placement="bottom"
                            style={{height: '260px'}}
                            show={this.state.showFilters}
                            onHide={() => this.setState({showFilters: false})}
                        >
                            <Drawer.Header>
                            </Drawer.Header>
                            <Drawer.Body>
                                <TrafficStatFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    {...{
                                        filter,
                                        globalSettings
                                    }}
                                />
                            </Drawer.Body>
                        </Drawer>
                    </>
                }

                <Spacer size={30}/>

                {this.state.resizedWidth >= 768 &&
                    <>
                        <FlexGrid justify="space-between">
                            <FlexGridItem>
                                <TrafficStatFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    {...{
                                        filter,
                                        globalSettings
                                    }}
                                />
                            </FlexGridItem>
                            <FlexGridItem>
                                <ButtonDownload
                                    onDownload={exportReport}
                                    limitWidth={875}
                                    buttonText={this.formatMessage(m.exportReport)}
                                    loading={uploadLoading}
                                />
                            </FlexGridItem>
                        </FlexGrid>

                        <Spacer size={19}/>
                    </>
                }

                <Spacer size={1}/>

                <TrafficStatTable
                    data={Object.keys(summariesData).length ? dataWithSummaries : trafficStat}
                    extraRows={Object.keys(summariesData).length ? 1 : 0}
                    pickedColumns={this.columns}
                    loading={loading || searchLoading}
                    {...{
                        onChangePage,
                        onChangePerPage,
                        page,
                        per_page,
                        count,
                        savedPerPage,
                        savedTableColumns,
                        onSaveColumnsTable
                    }}
                />
                {this.state.resizedWidth < 768 &&
                    <StyledActionButtonWrapper>
                        <ButtonDownload
                            onDownload={exportReport}
                            limitWidth={875}
                            buttonText={this.formatMessage(m.exportReport)}
                            loading={uploadLoading}
                        />
                    </StyledActionButtonWrapper>
                }
            </>
        );
    }
}

export default injectIntl(TrafficStat)

const Toggler = styled(IconButton)`
    && {
        color: #363434;
        float: right;
        margin-top: 6px;
    }
`;

const StyledActionButtonWrapper = styled.div`
    padding-top: 15px;
    text-align: center;
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;