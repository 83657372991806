import React, {useEffect, useMemo, useState} from 'react'
import {Whisper, Tooltip, Icon, Button} from "rsuite";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import messages from "../../definedMessages";
import {Link} from 'react-router-dom'
import {createExternalLink} from './Navigation'


export default ({
    isMobile = false,
    localMessages: m,
    authInfo = {},
    showContactModal,
    setShowContactModal
}) => {
    const managerInfo = useMemo(() => {
        const managerName = _.get(authInfo, 'session.account.account_manager_name', null);
        const managerEmail = _.get(authInfo, 'session.account.account_manager_email', null);
        return {
            managerName,
            managerEmail
        }
    }, [authInfo]);

    const [showWhisper, onShowWhisper] = useState(false);

    useEffect(() => {
        if (!showContactModal) {
            onShowWhisper(false)
        }
    }, [showContactModal]);

    if (isMobile)
        return (
            <Whisper
                placement="auto"
                trigger="click"
                speaker={<StyledTooltip
                    show={!showContactModal && showWhisper}
                >
                        <div><FormattedMessage {...m.yourAccountManager}/>: {managerInfo.managerName}</div>
                        <div><Link to={`mailto:${managerInfo.managerEmail}`}>{managerInfo.managerEmail}</Link></div>
                        {!showContactModal && showWhisper && <MobileStyledTextButton appearance="link" onClick={() => setShowContactModal(true)}>
                           <Icon icon="book2" className="contact_icon"/> <FormattedMessage {...messages.moreContacts}/>
                        </MobileStyledTextButton>}
                    </StyledTooltip>
                }
            >
                <StyledIcon
                    size="lg"
                    icon="avatar"
                    className="menu_account-avatar"
                    onClick={() => {onShowWhisper(true)}}
                />
            </Whisper>
        );

    return (
        <StyledAccountManagerInfo>
            {managerInfo.managerName &&
                <div className="name">
                    <FormattedMessage {...m.yourAccountManager}/>: {managerInfo.managerName}
                </div>
            }
            {managerInfo.managerEmail &&
                <div className="email">{createExternalLink(managerInfo.managerEmail, "mailto")}</div>
            }
            <StyledTextButton appearance="link" onClick={() => {setShowContactModal(true)}}><FormattedMessage {...messages.moreContacts}/></StyledTextButton>
        </StyledAccountManagerInfo>
    )
};

const StyledIcon = styled(Icon)`
    padding-top: 17px;
    margin-right: 20px;
    
    .app-client.rtl & {
        margin-left: 20px;
        margin-right: 0;
    }
`;

const StyledTooltip = styled(Tooltip)`
    display: ${props => !props.show ? 'none !important' : undefined};
    && .rs-tooltip-inner {
        padding-bottom: 5px;
        padding-top: 5px;
    }
`;

const StyledAccountManagerInfo = styled.div`
    font-size: 0.7rem;
    padding: 5px 10px 0 0;
    
    .app-client.rtl & {
        padding-right: 0;
        padding-left: 10px;
    }
    
    .email {
        font-size: 0.9rem;
    }
`;

const StyledTextButton = styled(Button)`
    line-height: 0 !important;
    padding: 0 !important;
    margin-top: 5px !important;
    display: flex !important;
    font-size: 0.7rem !important;
    color: #ffffff !important;
    text-decoration: underline !important;
`;
const MobileStyledTextButton = styled(Button)`
    padding: 0 !important;
    margin-left: 0px !important;
    margin-top: 8px !important;
    display: flex !important;
    font-size: 0.7rem !important;
    color: #ffffff !important;
    text-decoration: underline !important;
    .contact_icon {
        margin-right: 5px;
    }
`;