import {defineMessages} from "react-intl";

export default defineMessages({
    aNumber: { id: "testCalls.aNumber", defaultMessage: "A-number", description: 'Test calls: A-number' },
    bNumber: { id: "testCalls.bNumber", defaultMessage: "B-number", description: 'Test calls: B-number' },
    duration: {id: "testCalls.duration", defaultMessage: "Duration", description: 'Test calls: Duration'},
    datetime: { id: "testCalls.datetime", defaultMessage: "Datetime", description: 'Test calls: Datetime' },
    calltime: { id: "testCalls.calltime", defaultMessage: "Call time", description: 'Test calls: Call time' },
    originatedFrom: { id: "testCalls.originatedFrom", defaultMessage: "Originated from", description: 'Test calls: Originated from' },
    destination: { id: "testCalls.destination", defaultMessage: "Destination", description: 'Test calls: Destination' },
    range: { id: "testCalls.range", defaultMessage: "Range", description: 'Test calls: Range' },
})