import React, {useContext} from 'react';
import {Table} from 'rsuite';
import {renderColumn} from '../../utils/helpers';
import {useIntl} from "react-intl";
import CustomizableTable from '../../components/client/CustomizableTable/CustomizableTable';
import {ButtonRowClient, ButtonToolbar} from "../../components/base/BaseButton";
import m from "../../definedMessages";
import {RtlContext} from "../../App";

const {Column, HeaderCell, Cell} = Table;

export default (
    {
        data = [],
        loading,
        page,
        count,
        per_page,
        savedPerPage,
        onChangePerPage,
        onChangePage,
        onShowGetNumbersModal,
        onShowGoogleOTPModal,
        filter,
        spAuth,
        isGoogleOtp,
        accountInfo,
        isTest,
        accountDialerState,
        ...props
    }
) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    let actionShowFlag = null;
    if (accountInfo.hasOwnProperty('permission_list')) {
        if (accountInfo.permission_list !== null)
            actionShowFlag = accountInfo.permission_list.includes('allocate_number');
        if (accountInfo.permission_list !== null && isGoogleOtp)
            actionShowFlag = accountInfo.permission_list.includes('google_otp_allocation');
    }


    const columns = [
        {
            id: 'a_sub', 
            label: formatMessage(m.accessOrigin), 
            dataKey: 'a_subdestination_name',
            flexGrow: 5,
            minWidth: 200,
            align: 'center',
            spAuth: false,
        },
        {
            id: 'a_number',
            label: formatMessage(m.cli),
            dataKey: 'a_number', 
            flexGrow: 3,
            minWidth: 130,
            align: 'center',
            spAuth: false,
        },
        {
            id: 'origin',
            label: formatMessage(m.service),
            value: ({a_service, a_number}) => (
                a_service || a_number
                    ? `${a_service} (${a_number})`
                    : ""
            ), 
            flexGrow: 3,
            minWidth: 130,
            align: 'center',
            spAuth: true,
        },
        {
            id: 'destination',
            label: formatMessage(m.destination),
            value: ({b_subdestination_name, b_template}) => (
                    `${b_subdestination_name ? b_subdestination_name : ""}${b_template ? ` (${b_template})` : ""}`
            ),
            flexGrow: 3,
            minWidth: 130,
            align: 'center',
            spAuth: true,
        },
        {
            id: 'b_sub', 
            label: formatMessage(m.accessDestination),
            dataKey: 'b_subdestination_name', 
            flexGrow: 5,
            minWidth: 200,
            align: 'center',
            spAuth: false,
        },
        {
            id: 'b_number', 
            label: formatMessage(m.testNumber), 
            dataKey: 'b_number', 
            flexGrow: 3,
            minWidth: 130,
            align: 'center',
            spAuth: false,
        },
    ].filter(value => value.spAuth === undefined || value.spAuth === spAuth);


    const nonTestColumns = [
        {
            id: 'payout',
            label: formatMessage(m.payout),
            value: (({payment_terms_rate_list}) => {
                const obj = payment_terms_rate_list ? payment_terms_rate_list.find(value => {
                    return value.payment_terms_name === (accountInfo.hasOwnProperty('payment_terms') ? accountInfo.payment_terms : '');
                }) : null;
                if (obj) {
                    return `${Number(obj.rate).toFixed(4)} ${accountInfo.currency_name}`;
                } else {
                    return '—';
                }
            }),
            width: 120,
            align: 'center'
        }
    ];


    if (!isTest) {
        for (const column of nonTestColumns) {
            columns.push(column);
        }
    }

    
    const testColumns = [];
    const allTermList = [];

    for (const priceRange of data) {
        if (priceRange && priceRange.payment_terms_rate_list) {
            for (const term of priceRange.payment_terms_rate_list)
                allTermList.push(term.payment_terms_name);
        }
    }

    
    const uniqueTermList = allTermList.filter((v, i, a) => a.indexOf(v) === i);
    for (const term of uniqueTermList) {
        testColumns.push(
            {
                id: 'payouts',
                label: `${formatMessage(m.payout)} ${term}`,
                value: (({payment_terms_rate_list}) => {
                    if (payment_terms_rate_list) {
                        const obj = payment_terms_rate_list.find(value => value.payment_terms_name === term);
                        let currency = payment_terms_rate_list.find(value=> value.rate !== null);
                        if (currency) {
                            currency = currency.currency_name;
                        }
                        if (obj && obj.rate && currency) {
                            if (accountInfo.hasOwnProperty('currency_name')) {
                                return `${Number(obj.rate).toFixed(4)} ${currency ? currency : ''}`;
                            }
                        } else {
                            return '—';
                        }
                    } else {
                        return '—';
                    }
                }),
                width: 120,
                align: 'center'
            });
    }

    
    if (isTest) {
        for (const column of testColumns) {
            columns.push(column);
        }
    }
    
    const renderedColumns = columns.map(renderColumn);
    
    return (
        <>
            <CustomizableTable
                data={data.filter(item => !!item)}
                isChangedColumns={false}
                isPaginated={true}
                {...{
                    rtl,
                    count,
                    page,
                    per_page,
                    loading,
                    savedPerPage,
                    onChangePage,
                    onChangePerPage,
                    renderedColumns,
                    ...props
                }}
                actionColumn={() => {
                    
                    if (spAuth && isGoogleOtp && (
                        !accountDialerState || 
                        !accountDialerState.permission_list ||
                        accountDialerState.permission_list && !accountDialerState.permission_list.includes('google_otp_allocation')
                    )) {
                        return null;
                    }

                    return actionShowFlag && 
                        <Column 
                            key="table_actions" 
                            width={rtl ? 160 : 130}
                            align='center'
                        >
                            <HeaderCell>{formatMessage(m.action)}</HeaderCell>
                            <Cell>
                                {
                                    rowData => {
                                        
                                        const data = {
                                            id: rowData.id,
                                            a_subdestination_name: rowData.a_subdestination_name,
                                            b_subdestination_name: rowData.b_subdestination_name,
                                            sp_key: rowData.sp_key,
                                            a_number: rowData.a_number,
                                            b_number: rowData.b_number,
                                            a_sde_key: rowData.a_sde_key,
                                            b_sde_key: rowData.b_sde_key
                                        };

                                        return (
                                            <ButtonToolbar>
                                                <ButtonRowClient
                                                    size="sm"
                                                    onClick={() => {
                                                        if (isGoogleOtp) {
                                                            onShowGoogleOTPModal(true, data);
                                                            return;
                                                        }
                                                        onShowGetNumbersModal(true, data);
                                                    }}
                                                >
                                                    <b>{formatMessage(m.getNumbers)}</b>
                                                </ButtonRowClient>
                                            </ButtonToolbar>
                                        );
                                    }
                                }
                            </Cell>
                        </Column>
                }}
            />
        </>
    );
};