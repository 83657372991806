import React, {useMemo, useRef} from 'react';
import {
    Checkbox,
    CheckboxGroup,
    ControlLabel,
    DateRangePicker,
    FormControl,
    FormGroup,
    Schema,
    Input,
    Form
} from 'rsuite';
import FormHOC from '../../../hoc/FilltersForm';
import {getAllowedRangesByIntervalMonth, removeTZFromDate} from '../../../utils/helpers';
import {debounce} from 'lodash'
import {useIntl} from "react-intl";
import {compose} from "redux";
import {useWindowWidth} from "../../../hooks";
import { FlexGrid, FlexGridItem } from '../../../components/base/FlexGrid';
import m from "../../../definedMessages";
import {BaseCheckboxField, BaseDateRangePicker, BaseInputField} from "../../../components/base/BaseForm";

const {ArrayType, StringType} = Schema.Types;
const MAX_CHARACTERS = 40;

const groupsList = ['range', 'a_number', 'b_number'];

const TrafficStatFilters = ({
    loading,
    onChangeFilters,
    filter,
    globalSettings = {},
    rtl
}) => {

    const {formatMessage} = useIntl();
    const form = useRef();
    const resizedWidth = useWindowWidth();


    const filtersModel = useMemo(() => {
        const allowedRanges = getAllowedRangesByIntervalMonth(globalSettings.cdr_full__interval_month);
        return Schema.Model({
            start_end_date: ArrayType()
                .addRule((value) => {
                    if (value && value[0] && value[1]) {
                        const startDate = new Date(value[0]);
                        const startDateYmd = `${startDate.getFullYear()}${('0' + (startDate.getMonth() + 1)).slice(-2)}${('0' + startDate.getDate()).slice(-2)}`;
                        if (startDateYmd < allowedRanges[0]) {
                            return false
                        }
                    }
                    return true;
                }, formatMessage(m.cdrHistoryPeriodIs, {count: globalSettings.cdr_full__interval_month})),
            a_number: StringType()
                .pattern(/^\d+$/, formatMessage(m.correctNumber))
                .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS}))
        });
    }, [globalSettings]);


    const onSubmit = (data) => {

        const startDate = new Date(new Date(data.start_end_date[0]).setHours(0, 0, 0, 0));
        const endDate = new Date(new Date(data.start_end_date[1]).setHours(23, 59, 59, 999));

        if ( !form.current || !form.current.check() )
            return;

        const filtersDataGroup = groupsList.reduce((sum, current) => (
            data['checkbox'] && data['checkbox'].find(dataItem => dataItem === current)
                ? [...sum, current]
                : sum
        ), []);

        const filtersData = {
            ...data,

            group: (data['checkbox']
                ? filtersDataGroup.join('/')
                : 'range/a_number/b_number'),

            checkbox: data.checkbox && data.checkbox
                ? data.checkbox
                : ['range', 'a_number', 'b_number'],

            start_date: removeTZFromDate(startDate),
            end_date: removeTZFromDate(endDate),
        };
        onChangeFilters(filtersData);
    };


    return (
        <Form
            ref={form}
            model={filtersModel}
            onChange={debounce(onSubmit, 1000)}
            formDefaultValue={filter}
            fluid
            disabled={loading}
        >
            <FlexGrid align="middle">
                {(resizedWidth > 406) &&
                    <FlexGridItem>{formatMessage(m.dateFrom)}</FlexGridItem>
                }
                <FlexGridItem>
                    <FormControl
                        name="start_end_date"
                        showOneCalendar={resizedWidth <= 767}
                        accepter={BaseDateRangePicker}
                        placement={resizedWidth >= 768 ? (rtl ? "bottomEnd" : "bottomStart") : 'auto'}
                        cleanable={false}
                        ranges={[
                            {
                                label: formatMessage(m.yesterday),
                                value: [
                                    new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)),
                                    new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999))
                                ]
                            }, {
                                label: formatMessage(m.today),
                                value: [
                                    new Date(new Date().setHours(0, 0, 0, 0)),
                                    new Date(new Date().setHours(23, 59, 59, 999))
                                ]
                            }, {
                                label: formatMessage(m.last7Days),
                                value: [
                                    new Date(new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0, 0)),
                                    new Date(new Date().setHours(23, 59, 59, 999))
                                ]
                            }]}
                    />
                </FlexGridItem>

                <FlexGridItem>
                    <FormControl
                        name="a_number"
                        accepter={BaseInputField}
                        placement={resizedWidth >= 768 ? (rtl ? "bottomEnd" : "bottomStart") : 'auto'}
                        placeholder={formatMessage(m.aNumber)}
                    />
                </FlexGridItem>

                <FlexGridItem>
                    <FlexGrid align="middle">

                        {resizedWidth > 406 &&
                            <FlexGridItem>
                                <ControlLabel>{formatMessage(m.groupBy)}</ControlLabel>
                            </FlexGridItem>
                        }

                        <FlexGridItem>
                            <FormGroup>
                                <FormControl
                                    name="checkbox"
                                    accepter={CheckboxGroup}
                                    inline
                                >
                                    {[['a_number', m.aNumber], ['b_number', m.bNumber], ['range', m.ranges]].map(item => (
                                        <BaseCheckboxField
                                            key={item[0]}
                                            value={item[0]}
                                            defaultChecked
                                            disabled={loading}
                                        >
                                            {formatMessage(item[1])}
                                        </BaseCheckboxField>
                                    ))}
                                </FormControl>
                            </FormGroup>
                        </FlexGridItem>

                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
        </Form>
    );
};

export default compose(
    FormHOC,
)(TrafficStatFilters)