import {
    SET_ACCOUNT_DIALER_INFO,
    REFERENCES_ALLOCATION_LIMIT_LIST,
    SET_ACCOUNT_READY_FOR_PAYMENT
} from '../const';
import {
    ACCOUNT_GET_DIALER_API,
    ACCOUNT_GET_TEST_DIALER_API
} from "../const/apiMethods";
import {createAction} from  './defaults';
import {api} from "../api/loginRoutes";


const setDialerInfo = createAction(SET_ACCOUNT_DIALER_INFO);
const setAllocationLimit = createAction(REFERENCES_ALLOCATION_LIMIT_LIST);
const setAccountReadyForPaymentAction = createAction(SET_ACCOUNT_READY_FOR_PAYMENT);

export const setReadyForPayment = (value) => (dispatch) => {
    dispatch( setAccountReadyForPaymentAction(value.toFixed(2)) );
};

export const getAccountDialerInfo = () => (dispatch) => {
    const authInfo = localStorage.getItem('userInfo');
    const isTest = authInfo ? JSON.parse(authInfo).session.account.is_test : false;
    const request = !isTest ? ACCOUNT_GET_DIALER_API : ACCOUNT_GET_TEST_DIALER_API;

    api(request)
        .then(response => {
            if (response !== undefined && response.account !== undefined) {
                dispatch( setDialerInfo(response.account) );
                dispatch( setAllocationLimit( response.account.allocation_limit) )
            }
        });
};