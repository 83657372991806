import React, {useEffect, useRef} from 'react';
import WZ from '../../../../components/Filters/WZ';
import DE from '../../../../components/Filters/DE';
import SDE from '../../../../components/Filters/SDE';
import Search, {str} from '../../../../components/Filters/Search';
import {
    Form, FormGroup, Schema, DatePicker, SelectPicker,
    FormControl, IconButton, Icon, RadioGroup, Radio
} from 'rsuite';
import FormHOC from '../../../../hoc/FilltersForm';
import './Allocated.css';
import Checkbox from '../../../../hoc/Checkbox';
import {debounce} from 'lodash';
import {DEFAULT_EQUAL_SIGN} from '../../../../const'
import * as S from "./styled";
import {Collapse} from "react-collapse";
import styled from "styled-components";
import NumbersDownloadDropdown from "./NumbersDownloadDropdown";

const numbersFiltersModel = Schema.Model({str});

const NumbersFilters = React.forwardRef(({
    onChange,
    disabled,
    filters,
    currentDisplay,
    currentRangeNumber,
    onChangeGroup,
    isMobile,

    downloadLoading,
    downloadDisabled,
    onDownloadNumbersCsv,
    onDownloadOnlyNumbersCsv,

}, ref) => {

    let searchRef = useRef(null);

    const [showFilters, setShowFilters] = React.useState(false);
    const [searchInput, onChangeSearchInput] = React.useState(currentRangeNumber);
    const [formValue, onChangeFormValue] = React.useState({});
    const [startDateState, onChangeStartDate] = React.useState(null);
    const [equalSignState, onChangeEqualSign] = React.useState(DEFAULT_EQUAL_SIGN);
    const equalObject = {"<": -1, "=": DEFAULT_EQUAL_SIGN, ">": 1};

    useEffect(() => {
        onChangeSearchInput(currentRangeNumber);
        if (searchRef && searchRef.current) {
            if (!currentRangeNumber) {
                searchRef.current.value = null;
            }
        }
    }, [currentRangeNumber]);

    useEffect(() => {
        equalSignState && onChangeEqualSign(DEFAULT_EQUAL_SIGN);
        onChangeStartDate(null);
    }, [currentDisplay]);


    const NumbersFiltersForm = (
        <Form
            defaultValue={filters || {}}
            model={numbersFiltersModel}
            ref={ref}
            readOnly={disabled}
            onChange={(value) => {
                onChangeFormValue(value);
                const equalSign = value.start_date_inequation || Number.isInteger(value.start_date_inequation)
                    ? value.start_date_inequation.toString()
                    : undefined;
                let startDate = new Date(value.start_date);
                startDate = value.start_date
                    ? `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
                    : undefined;
                const filterObject = {
                    ...value,
                    str: searchInput,
                    start_date: startDate,
                    start_date_inequation: startDate
                        ? startDateState ? equalSign : DEFAULT_EQUAL_SIGN.toString()
                        : undefined
                };
                onChange(filterObject);
            }}
        >
            <S.Grid noGutters={isMobile}>

                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <WZ disabled={disabled}/>
                    </StyledField>
                </S.GridItem>

                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <DE disabled={disabled}/>
                    </StyledField>
                </S.GridItem>

                <S.GridItem isFullWidth={isMobile}>
                    <StyledField>
                        <SDE disabled={disabled}/>
                    </StyledField>
                </S.GridItem>

                {currentDisplay === "trunk_number" &&
                    <S.GridItem isFullWidth={isMobile}>
                        <StyledField>
                            <FormGroup style={{marginRight: 10}}>
                                <FormControl
                                    name="start_date"
                                    style={{width: 150}}
                                    accepter={DatePicker}
                                    placeholder="Enter Date"
                                    onChange={() => {
                                        onChangeStartDate(true);
                                    }}
                                    onClean={() => {
                                        onChangeStartDate(null);
                                        onChangeEqualSign(DEFAULT_EQUAL_SIGN);
                                    }}
                                    defaultValue={!startDateState && filters && filters.start_date}
                                    disabled={disabled}
                                />
                            </FormGroup>
                        </StyledField>
                    </S.GridItem>
                }

                {currentDisplay === "trunk_number" &&
                    <S.GridItem isFullWidth={isMobile}>
                        <StyledField>
                            <FormGroup>
                                <FormControl
                                    labelKey="label"
                                    valueKey="value"
                                    cleanable={false}
                                    searchable={false}
                                    value={equalSignState}
                                    accepter={SelectPicker}
                                    disabled={!startDateState || disabled}
                                    name="start_date_inequation"
                                    onChange={(value) => {
                                        onChangeEqualSign(value)
                                    }}
                                    data={Object.keys(equalObject).map(val => { return {value: equalObject[val], label: val}})}
                                />
                            </FormGroup>
                        </StyledField>
                    </S.GridItem>
                }

                <S.GridItem
                    isFullWidth={isMobile}
                    style={{paddingLeft: isMobile ? 0 : 20}}
                >
                    <FormGroup>
                        <StyledField>
                            <Checkbox
                                disabled={disabled}
                                name="actual"
                                checked={(filters || {}).actual === false}
                            >
                                Show outdated/revoked
                            </Checkbox>
                        </StyledField>
                    </FormGroup>
                </S.GridItem>
            </S.Grid>
        </Form>
    );

    return (
        <S.Grid>
            <S.GridItem isFullWidth={isMobile}>
                <S.Grid noWrap>
                    <S.GridItem>
                        <Form
                            style={{paddingRight: isMobile ? 0 : 18}}
                            formDefaultValue={{str: searchInput}}
                        >
                            <StyledField>
                                <Search
                                    inputRef={searchRef}
                                    onBlur={(event) => {
                                        // event.target && event.target.name === 'str' && event.target.focus();
                                    }}
                                    disabled={disabled}
                                    placeholder="Range/Number search"
                                    onChange={debounce((value) => {
                                        onChangeSearchInput(value);
                                        onChange({...formValue, str: value})
                                    }, 1000)}
                                />
                            </StyledField>
                        </Form>
                    </S.GridItem>

                    {isMobile &&
                        <S.GridItem>
                            <IconButton
                                icon={<Icon icon="filter"/>}
                                appearance={showFilters ? "primary" : "default"}
                                onClick={() => setShowFilters(!showFilters)}
                            >
                            </IconButton>
                        </S.GridItem>
                    }

                    {isMobile &&
                        <S.GridItem style={{marginLeft: 'auto'}}>
                            <NumbersDownloadDropdown
                                loading={downloadLoading}
                                disabled={downloadDisabled}
                                onDownloadNumbersCsv={onDownloadNumbersCsv}
                                onDownloadOnlyNumbersCsv={onDownloadOnlyNumbersCsv}
                                isMobile={isMobile}
                            />
                        </S.GridItem>
                    }
                </S.Grid>
            </S.GridItem>

            <S.GridItem>
                <Collapse isOpened={!isMobile || showFilters}>

                    {NumbersFiltersForm}

                    {isMobile &&
                        <S.GridItem>
                            <RadioGroup
                                inline
                                onChange={onChangeGroup}
                                defaultValue={'trunk_number'}
                                name="radioList"
                            >
                                <Radio value="trunk_number" disabled={disabled}>Numbers</Radio>
                                <Radio value="price_range" disabled={disabled}>Ranges</Radio>
                                <Radio value="subdestination" disabled={disabled}>Subdestinations</Radio>
                            </RadioGroup>
                        </S.GridItem>
                    }
                </Collapse>
            </S.GridItem>
        </S.Grid>
    );
});

export default FormHOC(NumbersFilters, numbersFiltersModel, 300);

const StyledField = styled.div`
    display: inline-block;
    
    @media (max-width: 567px) {
        display: block;
        
        .rs-input {
            min-width: 30px;
        }
        
        && .rs-control-label {
            display: block;
            margin-bottom: 5px;
            margin-top: 0;
        }
        
        && .rs-form-group {
            display: block;
        }
    }
`;