import {
    TRAFFIC_REPORTS_GET_LIST_SUCCESS,
    TRAFFIC_REPORTS_SET_LOADING
} from "../actions/actionTypes";

const initialState = {
    loading: false,
    list: [],
    count: 0,
    perPage: 1000,
    page: 1
};


const handlers = {
    [TRAFFIC_REPORTS_SET_LOADING]: (state, {payload}) =>({...state, loading: payload}),
    [TRAFFIC_REPORTS_GET_LIST_SUCCESS]: (state, {payload}) =>({...state, ...payload, loading: false}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
