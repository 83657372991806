// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var Calendar = {
    sunday: 'ح',
    monday: 'ن',
    tuesday: 'ث',
    wednesday: 'ر',
    thursday: 'خ',
    friday: 'ج',
    saturday: 'س',
    ok: 'حسناً',
    today: 'اليوم',
    yesterday: 'أمس',
    hours: 'ساعات',
    minutes: 'دقائق',
    seconds: 'ثواني',
    formattedMonthPattern: 'MM, YYYY',
    formattedDayPattern: 'MMM DD, YYYY'
};

var _default = {

    Pagination: {
        more: 'المزيد',
        prev: 'السابق',
        next: 'التالي',
        first: 'الأول',
        last: 'الأخير'
    },

    Table: {
        emptyMessage: 'لا يوجد المزيد من البيانات',
        loading: 'جاري التحميل...'
    },

    TablePagination: {
        lengthMenuInfo: 'صفحة / {0}',
        totalInfo: 'الإجمالي: {0}'
    },

    Calendar: Calendar,
    DatePicker: (0, _extends2.default)({}, Calendar),
    DateRangePicker: (0, _extends2.default)({}, Calendar, {
        last7Days: 'أخر 7 أيام'
    }),

    Picker: {
        noResultsText: 'لا يوجد نتائج',
        placeholder: 'إختيار',
        searchPlaceholder: 'البحث',
        checkAll: 'الجميع'
    },

    InputPicker: {
        newItem: 'عنصر جديد',
        createOption: 'إنشاء العنصر "{0}"'
    },

    Uploader: {
        inited: 'تم البدء',
        progress: 'جاري الرفع',
        error: 'خطأ',
        complete: 'تم الإنتهاء',
        emptyFile: 'فارغ',
        upload: 'رفع'
    }
};

exports.default = _default;
module.exports = exports.default;