import {connect} from "react-redux";
import GoogleOtpAllocation from "./GoogleOtpAllocation";
import {getGoogleOtpAllocationList, createGoogleOtpAllocation, modifyGoogleOtpAllocation, removeGoogleOtpAllocation} from "../../actions/google_otp_allocation";


const mapStateToProps = ({references, google_otp_allocation}) => ({
    subdestinationList: references.subdestination_list,
    data: google_otp_allocation.list,
    count: google_otp_allocation.count,
    loading: google_otp_allocation.loading,
    page: google_otp_allocation.page,
    perPage: google_otp_allocation.perPage,
});

export default connect(mapStateToProps, {
    getGoogleOtpAllocationList,
    createGoogleOtpAllocation,
    modifyGoogleOtpAllocation,
    removeGoogleOtpAllocation
})(GoogleOtpAllocation);