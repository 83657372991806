import React from 'react';
import {Table} from 'rsuite';
import RenderedColumns from '../../../components/Table/RenderColumns';
import Pagination from '../../../components/base/Pagination'
import BaseTable from "../../../components/base/BaseTable";
import {ButtonRowDelete, ButtonRowEdit} from "../../../components/base/BaseButton";

const { Column, HeaderCell, Cell } = Table;

const columns = RenderedColumns([
    {label: 'Subdestination', dataKey: 'subdestination_name'},
    {label: 'Rate', dataKey: 'rate', align: 'right'},
    {label: 'Start date', dataKey: 'start_date', formatData: 'date', align: 'right'},
    {label: 'End date', dataKey: 'end_date', formatData: 'date', align: 'right'}
], {end_date: true});

export default ({data = [], loading = false, total, per_page, activePage, onChangePage, onChangePerPage, onClickDelete, onClickEdit}) => {
    
    const getData = () =>  {
        const start = per_page * (activePage - 1);
        const end = start + per_page;
        
        return data.filter((v, i) => i >= start && i < end);
    };

    const _data = getData().map(item => ({
        ...item,
        start_date: item.start_date ? item.start_date.replace(" ", "T") : "",
        end_date: item.end_date ? item.end_date.replace(" ", "T") : "",
    }));

    return (
        <>
            <BaseTable
                data={_data}
                loading={loading}
                rowHeight={30}
                height={ ((_data.length * 30) || 100) + 50}
            >
                {columns}
                <Column width={50}>
                    <HeaderCell/>
                    <Cell style={{overflow: 'unset'}}>
                        {(rate) => <ButtonRowEdit onClick = { () => onClickEdit(rate)} style={{top: '-2px'}}/> }
                    </Cell>
                </Column>
                <Column width={50}>
                    <HeaderCell/>
                    <Cell style={{overflow: 'unset'}}>
                        {({id}) => <ButtonRowDelete onClick = { () => onClickDelete(id)} style={{top: '-2px'}}/> }
                    </Cell>
                </Column>
            </BaseTable>
            <Pagination {...{total,per_page, activePage, onChangePage, onChangePerPage}} />
        </>
    );
};