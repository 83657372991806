import {defineMessages} from "react-intl";

export default defineMessages({
    numbersFromTemplateCannotBeAllocated: {
        id: "validation.numbersFromTemplateCannotBeAllocated",
        defaultMessage: "Numbers from template \"{template}\" cannot be allocated",
        description: "validation: Numbers from template \"{template}\" cannot be allocated"
    },
    accountTrunkRangeMismatchTemplate: {
        id: "modalResponseHandler.accountTrunkRangeMismatchTemplate",
        defaultMessage: "Numbers from template \"{template}\" cannot be allocated to trunk \"{trunkName}\". Please try another trunk.",
        description: "ModalResponseHandler: Numbers from template {template} cannot be allocated to trunk \"{trunkName}\". Please try another trunk."
    },
    accountTrunkRangeMismatchRange: {
        id: "modalResponseHandler.accountTrunkRangeMismatchRange",
        defaultMessage: "Numbers from range \"{range}\" cannot be allocated to trunk \"{trunkName}\". Please try another trunk.",
        description: "ModalResponseHandler: Numbers from range {range} cannot be allocated to trunk \"{trunkName}\". Please try another trunk."
    },
    notFoundFreeNumbersTemplate: {
        id: "modalResponseHandler.notFoundFreeNumbersTemplate",
        defaultMessage: "We cannot allocate {numbersAmount} numbers from template \"{template}\" successively. Please decrease number amount or use random order",
        description: "ModalResponseHandler: We cannot allocate {numbersAmount} numbers from template \"{template}\" successively. Please decrease number amount or use random order"

    },
    notFoundFreeNumbersRange: {
        id: "modalResponseHandler.notFoundFreeNumbersRange",
        defaultMessage: "We cannot allocate {numbersAmount} numbers from range \"{range}\" successively. Please decrease number amount or use random order",
        description: "ModalResponseHandler: We cannot allocate {numbersAmount} numbers from template \"{range}\" successively. Please decrease number amount or use random order"
    },
    notFoundFreeNumbersDestination: {
        id: "modalResponseHandler.notFoundFreeNumbersDestination",
        defaultMessage: "We cannot allocate {numbersAmount} numbers for destination \"{subDest}\" successively. Please decrease number amount or use random order",
        description: "ModalResponseHandler: We cannot allocate {numbersAmount} numbers for destination \"{subDest}\" successively. Please decrease number amount or use random order"
    },
    destinationIsNotAvailable: {
        id: "modalResponseHandler.destinationIsNotAvailable",
        defaultMessage: "This destination is not available for allocation. Please contact to your account manager.",
        description: "ModalResponseHandler: This destination is not available for allocation. Please contact to your account manager."
    },
    numbersForSubdestCannotBeAllocated: {
        id: "modalResponseHandler.numbersForSubdestCannotBeAllocated",
        defaultMessage: "Numbers for \"{subDest}\" cannot be allocated",
        description: "ModalResponseHandler: Numbers for \"{subDest}\" cannot be allocated."
    },
    youHaveExceededTheLimit: {
        id: "modalResponseHandler.youHaveExceededTheLimit",
        defaultMessage: "You have exceeded the limit for self-allocation numbers",
        description: "ModalResponseHandler: You have exceeded the limit for self-allocation numbers."
    },
    tooMuchNumbersPerSubdestination: {
        id: "modalResponseHandler.tooMuchNumbersPerSubdestination",
        defaultMessage: "You're trying to allocate too much numbers per subdestination",
        description: "ModalResponseHandler: You're trying to allocate too much numbers per subdestination"
    },
    numbersCannotBeAllocated: {
        id: "modalResponseHandler.numbersCannotBeAllocated",
        defaultMessage: "Numbers cannot be allocated",
        description: "ModalResponseHandler: Numbers cannot be allocated"
    },



    actionIsProhibited: {
        id: "modalResponseHandler.actionIsProhibited",
        defaultMessage: "Action is prohibited",
        description: "ModalResponseHandler: Action is prohibited"
    },
    tooMuchSimultaneousOperations: {
        id: "modalResponseHandler.tooMuchSimultaneousOperations",
        defaultMessage: "Too much simultaneous operations",
        description: "ModalResponseHandler: Too much simultaneous operations"
    },
    numbersAreTemporarilyUnavailable: {
        id: "modalResponseHandler.numbersAreTemporarilyUnavailable",
        defaultMessage: "Numbers are temporarily unavailable",
        description: "ModalResponseHandler: Numbers are temporarily unavailable"
    },
    tooMuchNumbersPerRange: {
        id: "modalResponseHandler.tooMuchNumbersPerRange",
        defaultMessage: "Too much numbers per range",
        description: "ModalResponseHandler: Too much numbers per range"
    },
    invalidOperation: {
        id: "modalResponseHandler.invalidOperation",
        defaultMessage: "Invalid operation",
        description: "ModalResponseHandler: Invalid operation"
    },
    incorrectNumberAmount: {
        id: "modalResponseHandler.incorrectNumberAmount",
        defaultMessage: "Incorrect number amount",
        description: "ModalResponseHandler: Incorrect number amount"
    },
    wrongTemplate: {
        id: "modalResponseHandler.wrongTemplate",
        defaultMessage: "Wrong template",
        description: "ModalResponseHandler: Wrong template"
    },
    operationCode: {
        id: "modalResponseHandler.operationCode",
        defaultMessage: "Operation code",
        description: "ModalResponseHandler: Operation code"
    },
    tooMuchNumbersPerOne: {
        id: "modalResponseHandler.tooMuchNumbersPerOne",
        defaultMessage: "You're trying to allocate to much numbers per one",
        description: "ModalResponseHandler: You're trying to allocate too much numbers per one"
    },
    numbersCannotBeAllocatedToTrunk: {
        id: "modalResponseHandler.numbersCannotBeAllocatedToTrunk",
        defaultMessage: "Numbers cannot be allocated to trunk \"{trunkName}\". Please try another trunk.",
        description: "ModalResponseHandler: Numbers cannot be allocated to trunk \"{trunkName}\". Please try another trunk."
    },
    numbersFromRangeCannotBeAllocated: {
        id: "modalResponseHandler.numbersFromRangeCannotBeAllocated",
        defaultMessage: "Numbers from range \"{range}\" cannot be allocated.",
        description: "ModalResponseHandler: Numbers from range \"{range}\" cannot be allocated."
    },
})