import React from 'react';
import Pagination from '../base/Pagination';
import {Button, ButtonToolbar, Checkbox, Notification} from 'rsuite';
import RenderedColumns from './RenderColumns';
import {ObjectSet} from '../../utils/ObjectSet';
import ShowColumnPicker from "components/Table/ShowColumnPicker";
import _ from 'lodash';
import BaseTable, {Column, HeaderCell, Cell} from "../base/BaseTable";
import css from './Table.module.css';


/**
 * Для правильной работы выбора колонок в таблицу нужно передать columnSelectorLSKey
 * это и будет являться ключём для храрения выбраных колонок в LocalStorage
 */
export class MyTable extends React.Component{

    constructor(props) {
        super(props);

        this.selectedlist = this.props.isSetObject
            ? new ObjectSet()
            : new Set();

        const allColumnsKeys = props.columns
            .map(column => column.dataKey);

        let columnsKeys = props.columns
            .filter(column => !column.hideDefault)
            .map(column => column.dataKey);

        if (props.columnSelectorLSKey) {
            //Get saved columns to show from Local Storage
            columnsKeys = localStorage.getItem(props.columnSelectorLSKey)
                ? JSON.parse(localStorage.getItem(props.columnSelectorLSKey))
                : columnsKeys;
        }

        this.state = {
            selectAll: false,
            columns: [],
            isSelectColumnsDropdownOpen: false,
            showColumnsKeys: columnsKeys || allColumnsKeys,
        };
    }

    configureColumns () {
        let columns = [...this.props.columns];
        const showColumnsKeys = this.state.showColumnsKeys;
        const {columnSelectorLSKey} = this.props;

        if (columnSelectorLSKey) {
            //Set saved
            localStorage.setItem(columnSelectorLSKey, JSON.stringify(showColumnsKeys));

            columns = columns.filter((column) => showColumnsKeys.includes(column.dataKey));

            if (columns.length) {
                columns[0].headerProps = {className:`rs-table-cell-header-with-icon`};
            }
        }

        this.setState({columns});
    }

    clear = () => {
        const { isselected } = this.props;
        const {selectAll } = this.state;

        if(isselected) {
            this.selectedlist.clear();
            if(selectAll)
                this.setState({selectAll: false}, this.setSelectParent)
            else
                this.setSelectParent()
        }
    };

    shouldComponentUpdate({data, stateChecked, row_key = 'id'}) {
        if(stateChecked && data !== this.props.data) {
            data.map(row => {
                return (row.checked) && ( this.selectedlist.add( this.gettingSelectItem(row) ) )
            })
        }
        return true;
    }

    componentDidMount() {
        this.configureColumns();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Uncheck checkboxes when data in table changes
        if (this.props.count && prevProps.count !== this.props.count) {
            this.onToggleAll(false);
        }

        if (this.props.columns !== prevProps.columns
            || this.state.showColumnsKeys !== prevState.showColumnsKeys) {
            this.configureColumns();
        }
        if (this.props.clearSelected) {
            this.clear()
        }
    }

    onChangePage = (page) => {
        this.props.getItems(page, this.props.per_page)
    };

    onChangePerPage = (per_page) => {
        this.props.getItems(1, per_page)
    };

    setSelectParent = () => {
        const {setSelected} = this.props;
        const {selectAll} = this.state;

        if (!setSelected) return;

        setSelected({
            list: Array.from(this.selectedlist.values()),
            all: selectAll
        })
    };

    onToggle = (id, checked) => {
        const {useSelectedData, data = [], row_key = 'id', onCheckPrevented} = this.props;

        if (checked) {
            this.selectedlist.add(id);
        } else {
            this.selectedlist.delete(id);
        }

        if (useSelectedData) {
            const checkedData = data.filter(item => item[row_key] === id);
            useSelectedData(checkedData, checked)
        }

        if (checked && onCheckPrevented) {
            const checkedItem = data.find(item => item.id === id);
            if (checkedItem.preventCheck)
                onCheckPrevented();
        }

        this.setSelectParent();
        this.forceUpdate();
    };

    onToggleAll = (checked) => {
        const {selectAll} = this.state;
        const {data, row_key = 'id', ispagination, isSelectedAll, useSelectedData, partialSelectedAll} = this.props;

        if (!checked) {
            speaker(Notification.close("allocatedNumberChooser"));
        }
        if (partialSelectedAll) {
            this.setState({selectAll: true});
        }

        if (isSelectedAll && checked && !selectAll && ispagination) {
            Notification.open({
                duration: 0,
                description: [
                    <div className="mb-2">Do you want to choose records on all pages according to clause of filter?</div>,
                    <div className="text-right">{
                        speaker(
                            () => {
                                this.setState({selectAll: true}, this.setSelectParent);
                                Notification.closeAll();
                            },
                            () => {
                                Notification.closeAll();
                            })
                    }</div>
                ],
                key: 'allocatedNumberChooser'
            });
        } else if (!checked && selectAll) {
            this.setState({selectAll: false}, this.setSelectParent);
        }

        for (let row of data) {
            (checked && !row.preventCheck && !row.disabled)
            ? this.selectedlist.add(this.gettingSelectItem(row))
            : this.selectedlist.delete(this.gettingSelectItem(row))
        }

        if (useSelectedData) {
            useSelectedData(data, checked)
        }
        
        this.setSelectParent();
        this.forceUpdate();
    };

    getHeight = () => {
        const { data = [],height, rowHeight}  = this.props;
        const heightValues = ((data.length * (rowHeight || 30)) || 100) + 50;

        switch (height) {
            case '50%':
                const h = (window.innerHeight/2);
                return Math.min(h,heightValues);
            case '30%':
                const h30 = (window.innerHeight/3);
                return Math.min(h30,heightValues);
            case '25%':
                const h25 = (window.innerHeight/4);
                return Math.min(h25,heightValues);
            default:
                return heightValues;
        }
    };

    gettingSelectItem = (row) => {
        const { isSetObject, row_key = 'id' } = this.props;
        return isSetObject ? row : row[row_key];
    };

    render () {
        const {
            data = [], loading, isselected, row_key = 'id', active_id,
            ispagination, count, page, per_page, onRowClick, isMobile, partialSelectedAll,
            width, ActionCell, hidePerPage, disabled, columns, getItems, height, extraHeight, ...props
        } = this.props;

        const {selectAll} = this.state;
        const _renderedColumns = RenderedColumns(this.state.columns, null, isMobile);

        const _columns = _renderedColumns;

        const propTables = {...props};

        if (onRowClick)
            propTables.onRowClick = (row) => onRowClick(row[row_key]);
        if (active_id) {
            propTables.rowClassName = (row) => {
                let rowClass = row && row[row_key] === active_id ? 'row-active' : null;
                if (!rowClass && props.rowClassName) {
                    rowClass = _.isFunction(props.rowClassName)
                        ? props.rowClassName(row)
                        : props.rowClassName;
                }
                return rowClass;
            }
        }

        if (width)
            propTables.width = width;

        if (!ispagination)
            propTables.virtualized = true;

        const dataEnabled = data.filter(value => !value.disabled);

        return  (
            <div className="position-relative">
                {this.props.columnSelectorLSKey &&
                    <ShowColumnPicker
                        value={this.state.showColumnsKeys}
                        columns={this.props.columns}
                        onChange={(value) => this.setState({showColumnsKeys: value})}
                    />
                }
                <BaseTable
                    height={ extraHeight ? extraHeight : this.getHeight()}
                    data={data}
                    loading={loading}
                    rowHeight={30}
                    {...propTables}
                >
                    {_columns}
                    {ActionCell}

                    {isselected &&
                        <Column width={50}>
                            <HeaderCell>
                                {data.length
                                    ? <Checkbox
                                        onChange={(v,checked) => this.onToggleAll(checked)}
                                        checked = {selectAll && (!partialSelectedAll && !dataEnabled.length) || dataEnabled.every(
                                            row => this.selectedlist.has( this.gettingSelectItem(row) )
                                        )}
                                        style={{position:'relative', top:'-5px', right: '8px'}}
                                    />
                                    : ''
                                    }
                            </HeaderCell>
                            <Cell>
                                {(row) => (
                                    <Checkbox
                                        disabled = {selectAll && (!partialSelectedAll && row.disabled) || row.disabled}
                                        checked = {row.checked || this.selectedlist.has( this.gettingSelectItem(row) )
                                        || selectAll && (!partialSelectedAll && row.disabled)}
                                        onChange={(v,checked) => this.onToggle( this.gettingSelectItem(row), checked )}
                                        style={{position:'relative', top:'-5px',  right: '8px'}}
                                    />
                                )}
                            </Cell>
                        </Column>
                    }

                    <Column key="table_scrollfix" width={0} fixed>
                        <HeaderCell></HeaderCell>
                        <Cell></Cell>
                    </Column>
                </BaseTable>

                {ispagination &&
                    <Pagination
                        disabled = {disabled}
                        total = {count}
                        per_page = {per_page}
                        activePage = {page}
                        hidePerPage = {hidePerPage}
                        onChangePage = {this.onChangePage}
                        onChangePerPage  = {this.onChangePerPage}
                    />
                }

            </div>
        );
             
    }
}


export default MyTable;

const speaker = (Ok,No) =>  (
    <div>
        <ButtonToolbar>
          <Button onClick ={Ok}>Yes</Button>
          <Button onClick ={No} >No, only on this page</Button>
        </ButtonToolbar>
      </div>
);