import React, {useState} from 'react';
import {Checkbox, FormControl, FormGroup} from 'rsuite';

export default (props) => {
    return  <FormGroup>
                <FormControl
                    name={props.name}
                    accepter={CheckboxHOC}
                    {...props}                  
                /> 
            </FormGroup>;
}


const CheckboxHOC = (props) => {        
    const onChange = (value, checked) => props.onChange(props.inverse ? !checked : checked);
    return <Checkbox {...props} onChange={onChange}  /> ;
};