import React from 'react';
import { FlexboxGrid } from 'rsuite';
import PageHeader from '../../components/base/PageHeader'
import LiveCallsTable from './LiveCallsTable';
import LiveCallsHeader from "./LiveCallsHeader";
import StopCallModal from './StopCallModal';
import PanelLayout from '../../components/base/PanelLayout';
import {LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE} from "../../const/localStorageKeys";

const SearchKeysIncluses = ['acc_name_orig', 'acc_name_term',  'a_subdestination_name', 'b_subdestination_name'];
const SearchKeysStarts = ['a_number', 'b_number']
    
export default class extends React.Component{
    constructor(props) {
        super(props);

        const savedAutoUpdate = localStorage.getItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE);
        const timerValue = savedAutoUpdate && !isNaN(savedAutoUpdate) && parseFloat(savedAutoUpdate) <= 100000
            ? savedAutoUpdate
            : null;
        this.updateDataTimer = null;

        this.state = {
            show: false,
            data: props.live_calls_list,
            searchLoading: false,

            autoUpdateFormValue: {
                timer: timerValue || null
            },
        }
    }

    shouldComponentUpdate (nextProps){  
        if(nextProps.live_calls_list !== this.props.live_calls_list){
            this.setState({data: nextProps.live_calls_list});
            if(this.searchValue) {
                this.onChangeFilters(this.searchValue);
            }
            return false;
        }
        return true;
    }

    componentDidMount() {
        this.props.getLiveCalls();
    }

    componentWillUnmount() {
        if (this.updateDataTimer) {
            clearTimeout(this.updateDataTimer);
            this.updateDataTimer = null;
        }
    }

    onChangeFilters = (value) => {
        clearTimeout(this.timerOnInputChange);
        const { searchLoading }=  this.state;
        if(!searchLoading) this.setState({searchLoading:true});
        
        this.timerOnInputChange = setTimeout( (value) => {
            this.searchValue = value;

            const { live_calls_list }  = this.props;
            const data = live_calls_list.filter( row => {
                for (let k of SearchKeysIncluses){
                    if(row[k].toLowerCase().includes(value))
                        return true;
                }
                for (let k of SearchKeysStarts){
                    if(row[k].toLowerCase().startsWith(value))
                        return true;
                }
                return false;
            })
            
            this.setState({data,searchLoading:false}) 
        }, 300, value.toLowerCase())
        
    };
    
    onStop = (id) => {
        this.call_key = id;
        this.setState({show: true})
    }
    onConfirmStopCallModal = () => this.props.stopCall(this.call_key, this.onCancelStopCallModal);
    onCancelStopCallModal = () => this.setState({show: false}, this.onUpdate)
    onUpdate = () => this.props.getLiveCalls();

    onTableDataUpdate = () => {
        const {autoUpdateFormValue} = this.state;

        if (!autoUpdateFormValue.timer) {
            if (this.updateDataTimer) {
                clearTimeout(this.updateDataTimer);
                this.updateDataTimer = null;
            }
            return;
        }

        if (this.updateDataTimer) {
            clearTimeout(this.updateDataTimer);
            this.updateDataTimer = null;
        }

        this.updateDataTimer = setTimeout(() => {
            this.onUpdate()
        }, autoUpdateFormValue.timer * 1000);
    };

    onChangeAutoUpdateValue = (value) => {
        const timer = isNaN(value.timer) || !parseFloat(value.timer) ? "" : value.timer;
        localStorage.setItem(LOCAL_STORAGE_LIVE_CALLS_AUTO_UPDATE, timer);
        this.setState(() => ({autoUpdateFormValue: {timer}}), this.onTableDataUpdate);
    };

    render () {
        const {data, searchLoading, show, autoUpdateFormValue} = this.state;
        const {loading, loadingItem} = this.props;
        return (
            <PanelLayout >
                    <PageHeader title="Live calls"/>
                    <FlexboxGrid >
                        <FlexboxGrid.Item colspan={24}>
                            <LiveCallsHeader
                                autoUpdateFormValue={autoUpdateFormValue}
                                onChangeAutoUpdateValue={this.onChangeAutoUpdateValue}
                                onUpdate={this.onUpdate}
                                onChange={this.onChangeFilters}
                                loading={loading}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                        <LiveCallsTable
                            onStop={this.onStop}
                            data={data}
                            loading={loading || searchLoading}
                            onDataUpdated={this.onTableDataUpdate}
                        />
                    <StopCallModal size={'xs'}
                                   show={show}
                                   loading={loadingItem}
                                   onCancel={this.onCancelStopCallModal}
                                   onConfirm={this.onConfirmStopCallModal}
                    />
            </PanelLayout>
        )
    }
}