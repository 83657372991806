import React from "react";
import {getFormattedTime} from "../../../utils/helpers";
import {useIntl} from "react-intl";
import {Table} from "rsuite";
import CommonTable from './../CommonTable';
import * as S from "../styled.js";
import m from "../../../definedMessages";

const {Column, HeaderCell, Cell} = Table;


export default ({
    data = [], 
    loading = false, 
    handleCancelPaymentRequest,
    currencyName,
}) => {

    const {formatMessage} = useIntl();

    const statusLabels = [
        formatMessage(m.active),
        formatMessage(m.canceled),
        formatMessage(m.passed),
        formatMessage(m.rejected),
        formatMessage(m.completed)
    ];

    const columns = [
        {
            id: 'amount',
            label: formatMessage(m.amount),
            value: ({amount}) => `${amount}${currencyName ? ' ' + currencyName : ''}`,
            flexGrow: 2,
            minWidth: 150,
            width: 287,
            align: 'center'
        },
        {
            id: 'date',
            label: formatMessage(m.date),
            value: ({date}) => getFormattedTime(date),
            flexGrow: 2,
            minWidth: 150,
            width: 287,
            align: 'center'
        },
        {
            id: 'paymentInfo',
            label: formatMessage(m.paymentInfo),
            value: ({payment_detail_info, move_to_account_name}) => {
                if (payment_detail_info)
                    return payment_detail_info;

                if (move_to_account_name)
                    return <>
                        {formatMessage(m.transferToAccountName, {
                            account_name: <b>{move_to_account_name}</b>
                        })}
                    </>;

                return '-';
            },
            flexGrow: 5,
            minWidth: 150,
            width: 718,
            align: 'center'
        },
        {
            id: 'status',
            label: formatMessage(m.status),
            value: ({status}) => statusLabels[status],
            flexGrow: 2,
            minWidth: 150,
            width: 287,
            align: 'center'
        },
    ];

    const tableActions = (
        <Column key="table__actions">
            <HeaderCell> </HeaderCell>
            <Cell>
                {rowData => {

                    if (rowData.status !== 0)
                        return null;

                    return (
                        <S.StyledTableActions>

                            <S.StyledTableButton
                                onClick={() => {
                                    handleCancelPaymentRequest(rowData)
                                }}
                            >
                                {formatMessage(m.cancel)}
                            </S.StyledTableButton>
                            
                        </S.StyledTableActions>
                    );
                }}
            </Cell>
        </Column>
    );  

    return (
        <CommonTable
            data={data}
            loading={loading}
            columns={columns}
            tableActions={tableActions}
        />
    )
};