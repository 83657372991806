import {BaseButton, ButtonContent, ButtonSubtle as CancelButton_} from "../../base/BaseButton";
import styled, {css} from 'styled-components';
import {
    List as RsuiteList, 
    ButtonToolbar as RsuiteToolbar, 
    Loader as RsuiteLoader, 
    Dropdown as RsuiteDropdown
} from 'rsuite';


export const ButtonToolbar = styled(RsuiteToolbar)`
    width: 100%;
    display: inline-block; 
    ${props =>
    props.width && css`
        width: ${props.width};
        `
    }
`;


export const CancelButton = styled(ButtonContent)`
    width: 100%;
    ${props =>
        props.width && css`
            width: ${props.width};
        `
    }
`;


export const Dropdown = styled(RsuiteDropdown).attrs(props => ({
    appearance: "default"
}))`
    & > a {
        width: 100%;
    }
    &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
        background-color: #d6d6db !important;
    }
    width: 100%;
`;


export const Loader = styled(RsuiteLoader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;


export const List = styled(RsuiteList)`
    margin-top: 10px;
    box-shadow: none !important;
    
    @media (max-width: 767px) {
        display: none !important;
    }
`;