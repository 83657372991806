import {connect} from 'react-redux';
import {getAccount, getAccountAllocatedNumbers, getAccountTrunk, getAccountUsers, setCurrentTrunkId,
    setAllocationRestrictions, setModifiedLimitId, setCurrentRangeNumber} from '../../actions/accounts';
import {getTrunkNumberCsvById} from '../../actions/numbers';
import {getRates} from '../../actions/rates';
import Account from './Account';
import {getAccessListFilter} from 'actions';
import {getKeyList} from 'actions/apiPage';
import {getAccountManagersList} from "../../actions/account_managers";

const mapState = ({accounts, references, roles, account_managers, numbers}) => ({
    account: accounts.accountView,
    accountLoading: accounts.loading,
    hasData: !!accounts.items.length,
    trunks: accounts.accountTrunk,
    trunksLoading: accounts.accountTrunkLoading,
    currency_list: references.currency_list,
    payment_terms_list: references.payment_terms_list,
    loadingReferences: references.loading,
    allocatedNumbers: accounts.accountAllocated,
    allocatedNumbersCount: accounts.accountAllocatedCount,
    allocatedNumbersTotalCount: accounts.accountAllocatedNumbersTotalCount,
    allocatedNumbersPage: accounts.allocatedNumbersPage,
    allocatedNumbersPerPage: accounts.allocatedNumbersPerPage,
    allocatedNumbersLoading: accounts.accountAllocatedLoading,
    modifiedLimit: accounts.modifiedLimit,
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    account_manager_list: account_managers.list,

    accountUsers: accounts.accountUsers,
    accountUsersLoading: accounts.accountUsersLoading,

    rates: accounts.rates,
    ratesLoading: accounts.ratesLoading,
    account_user_modify_method: roles.account_user_modify_method,
    account_user_remove_method: roles.account_user_remove_method,

    accessList: accounts.accessList,
    accessListLoading: accounts.accessListLoading,
    currentTrunkId: accounts.currentTrunkId,
    currentRangeNumber: accounts.currentRangeNumber,
    allocation_limit: accounts.allocation_limit,
    client_allocation_limit: references.client_allocation_limit,
    client_role_list: references.client_role_list,
    admin_role_list: references.admin_role_list,

    redirectTab: accounts.redirectTab
});

export default connect(mapState,
    {
        setAllocationRestrictions,
        setCurrentTrunkId,
        setCurrentRangeNumber,
        getAccount,
        getAccountTrunk,
        getAccessListFilter,
        getAccountAllocatedNumbers,
        getTrunkNumberCsvById,
        setModifiedLimitId,
        getAccountUsers,
        getRates,
        getKeyList,
        getAccountManagersList,
    })(Account);
  
