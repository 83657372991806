import React, {useState, useEffect, useContext} from "react";
import {useIntl} from "react-intl";
import PaymentsSectionHeader from "../PaymentsSectionHeader";
import PaymentsRequestTable from "./PaymentsRequestTable";
import {Spacer} from "../../../components/base/Spacer";
import {Link} from "react-router-dom";
import ModalCancelPaymentRequest from "./../ModalCancelPaymentRequest";
import {Collapse} from "react-collapse";
import {ButtonPrimary, ButtonWrapper} from "../../../components/base/BaseButton";
import m from "../../../definedMessages";
import {RtlContext} from "../../../App";
import { PAYMENT_REQUEST_LIST_API } from "const/apiMethods";
import { checkPermissionsFor } from "store/storeHelpers";


export default ({
    paymentRequestItems,
    paymentRequestLoading,
    accountId,
    cancelPaymentRequest,
    getPaymentRequestList,
    openModalRequestPayment,
    currencyName,
    readyForPayment,
    setReadyForPayment,
    permissions,
}) => {

    const {formatMessage} = useIntl();
    
    const [showTable, setShowTable] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [showModalCancelPaymentRequest, setShowModalCancelPaymentRequest] = useState(false);

    useEffect( () => {
        if ( !checkPermissionsFor(PAYMENT_REQUEST_LIST_API) ) {
            return;
        }
        getPaymentRequestList();
    }, [permissions] );

    const handleCancelPaymentRequest = (value) => {
        setSelectedRequest(value);
        setShowModalCancelPaymentRequest(true);
    };

    const changeReadyForPayment = (amount) => {
        setReadyForPayment(+readyForPayment + amount);
    };

    return (
        <>
            <PaymentsSectionHeader
                title={formatMessage(m.paymentRequest)}
                show={showTable}
                callback={() => {
                    setShowTable(!showTable);
                }}
            />

            <Collapse isOpened={showTable}>
                <div>
                    <Spacer />
                    <ButtonWrapper justify={"end"}>
                        <ButtonPrimary componentClass={Link} to="/payment-details">
                            {formatMessage(m.managePaymentDetails)}
                        </ButtonPrimary>
                        <ButtonPrimary onClick={openModalRequestPayment}>
                            {formatMessage(m.requestPayment)}
                        </ButtonPrimary>
                    </ButtonWrapper>

                    <Spacer/>
                    <PaymentsRequestTable
                        data={paymentRequestItems}
                        loading={paymentRequestLoading}
                        {...{
                            handleCancelPaymentRequest,
                            currencyName,
                        }}
                    />
                </div>
            </Collapse>

            <ModalCancelPaymentRequest 
                show={showModalCancelPaymentRequest}
                onSuccess={changeReadyForPayment}
                onClose={() => {
                    setShowModalCancelPaymentRequest(false)
                }}
                {...{
                    accountId,
                    selectedRequest,
                    cancelPaymentRequest,
                    getPaymentRequestList
                }}
            />
        </>
    )
};