import React from "react";
import {getFormattedTime, getISOTime} from "../../../utils/helpers";
// import {LOCAL_STORAGE_TRAFFIC_STAT_FILTERS} from '../../../../const';
import {Table} from "rsuite";
import * as S from "../styled.js";
import CommonTable from "../CommonTable";
import {LOCAL_STORAGE_TRAFFIC_STAT_FILTERS} from "../../../const/localStorageKeys";

const {Column, HeaderCell, Cell} = Table;

export default ({
    data = [], 
    loading = false, 
    openModalRequestPayment,
    setSelectedRequest,
    height,
    checkedStatus = [],
    withFilters = false
}) => {

    const statusLabels = ["Pending", "Ready for payment", "Paid"];

    const columns = [
        {
            id: 'trafficPeriod',
            label: "Traffic period",
            value: ({date_from, date_to}) => (
                `${ getFormattedTime( date_from.replace(" ", "T") ) } - ${ getFormattedTime( date_to.replace(" ", "T") )}`
            ),
            width: 200,
            flexGrow: !withFilters ? 2 : null,
            minWidth: !withFilters ? 200 : null,
            align: 'center'
        },
        {
            id: 'date',
            label: "Date",
            dataKey: "date2",
            width: 150,
            flexGrow: !withFilters ? 2 : null,
            minWidth: !withFilters ? 200 : null,
            align: 'center'
        },
        {
            id: 'amount',
            label: "Amount",
            value: ({agr_send, currency_name}) => `${agr_send}${currency_name ? ' ' + currency_name : ''}`,
            width: 150,
            flexGrow: !withFilters ? 2 : null,
            minWidth: !withFilters ? 200 : null,
            align: 'center'
        },
        {
            id: 'status',
            label: "Status",
            value: ({status}) => statusLabels[status],
            width: 150,
            flexGrow: !withFilters ? 2 : null,
            minWidth: !withFilters ? 200 : null,
            align: 'center'
        },
    ];

    if (withFilters) {
        columns.unshift({
            id: 'account',
            label: "Account",
            value: ({account_name, account_manager_name}) => `${account_name} / ${account_manager_name || 'no manager'}`,
            flexGrow: 2,
            minWidth: 300,
            align: 'center'
        })
    }

    const tableActions = (
        <Column width={210} key="table__actions">
            <HeaderCell> </HeaderCell>
            <Cell>
                {rowData => {

                    return (
                        <S.StyledTableActions alignEnd>

                            {rowData.status === 1 &&
                                <S.StyledTableButton
                                    onClick={() => openModalRequestPayment(rowData)}
                                >
                                    Request payment
                                </S.StyledTableButton>
                            }

                            <S.StyledTableButton
                                onClick={(e) => {
                                    const dateFrom = new Date( rowData.date_from.replace(" ", "T") );
                                    const dateTo = new Date( rowData.date_to.replace(" ", "T") );
                                    const filtersDefaultValue = {
                                        range: [getISOTime(dateFrom), getISOTime(dateTo)],
                                        start_end_date: [dateFrom, dateTo],
                                        dialer_id: rowData.account_id,
                                        group_checkbox: ['originator_key'],
                                    }
                                    localStorage.setItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS, JSON.stringify(filtersDefaultValue));
                                    window.open('/traffic-reports');
                                }}
                            >
                                Details
                            </S.StyledTableButton>

                        </S.StyledTableActions>
                    );
                }}
            </Cell>
        </Column>
    );

    const filteredData = withFilters && checkedStatus.length 
        ? data.filter(item => {
            // exclude pended
            if (item.status === 2)
                return false;

            // exclude all except ready for payment
            if (checkedStatus.includes(1) && item.status !== 1) {
                return false
            }

            return true;
        })
        : data;

    return (
        <CommonTable
            height={height}
            data={filteredData}
            loading={loading}
            columns={columns}
            tableActions={tableActions}
        />
    )
};