import React, {useState} from 'react'
import {
    SelectPicker,
} from 'rsuite'
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';

export default ({onClose, update, account_id, trunk_id, data, trunkList}) => {
    const [disabled, setDisabled ] = React.useState(false)
    const [trunk1_id, setTrunk1 ] = React.useState(null)

    const onSubmit = async () => {
        setDisabled(true)
        const result = await api('trunk:remove',{
            target:{
                account_id,
                trunk_id,
                trunk1_id: trunk1_id || undefined
            }
        })
        if(result && result.trunk) {
            onClose();
            update()
        }
        setDisabled(false)
    }

    return (
        <Modal 
            show 
            onClose={onClose}
            footer
            successText = "Yes"
            onSuccess = {onSubmit}
            disabled = {disabled}
            title='Delete trunk'>    
                Trunk {data.name} for dialer {data.account_name} will be deleted. Do you want to move numbers to another trunk?
                <br />
                Move to trunk
                <SelectPicker
                    labelKey="name"
                    valueKey="id"
                    data={[{id:null,name: 'Delete trunk with all numbers'},...trunkList]}
                    cleanable={false}
                    searchable = {false}
                    defaultValue = {null}
                    onChange = {(id) => setTrunk1(id)}
                    style = {{marginLeft: 10}}
                    // classPrefix="minWidth"
                />
        </Modal>
    )
};