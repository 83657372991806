import React from "react";
import {Form, FormControl, Icon, InputGroup} from 'rsuite'
import CheckboxControl from "../../../components/Form/CheckboxControl";
import styled from 'styled-components'
import SelectPicker from 'rsuite/es/SelectPicker'
import CustomField from '../../NewAccount/CustomField'

export default ({
    filters,
    account_manager_list,
    paymentTermsList,
    onChangeFilters,
}) => {

    return (
        <StyledForm
            formDefaultValue={filters}
            onChange={(value) => {
                console.log('onChange', value);
                onChangeFilters(value);
            }}
        >
            <StyledFormControl
                accepter={SelectPicker}
                placeholder="Account manager"
                data={account_manager_list}
                labelKey="name"
                valueKey="id"
                name="account_manager_id"
                label="Account manager"
                searchable={false}
                errorPlacement="topRight"
            />

            <StyledFormControl
                accepter={SelectPicker}
                data={paymentTermsList}
                labelKey="name"
                valueKey="name"
                style={{width: 224}}
                placeholder="Payment term"
                name="payment_terms_name"
                searchable={false}
                errorPlacement="topRight"
            />

            <CheckboxControl
                name="paid_only"
                defaultChecked={true}
            >
                Show amounts above $100
            </CheckboxControl>
        </StyledForm>
    )
}
const StyledForm = styled(Form)`
    && {
        width: 100%;
        display: flex;
    }
    .rs-form-control-wrapper {
        width: auto;
        margin-right: 20px;
    }
`;
const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
        min-width: 200px;
        max-width: 220px;
        overflow: hidden;
    }
`
