import store from './index';
import {getFirstValueByName} from "../utils";

export const getPaymentTermKeyByName = (paymentTermName) => {
    const paymentTermsList = store.getState().references.payment_terms_list;

    return getFirstValueByName(paymentTermsList, paymentTermName, 'pt_key');
};

export const getCurrencyKeyByName = (currencyKey) => {
    const currencyList = store.getState().references.currency_list;

    return getFirstValueByName(currencyList, currencyKey, 'cur_key');
};

export const checkPermissionsFor = (method) => {
    const permissionMethods = store.getState().auth.permissions;

    return permissionMethods.length ? permissionMethods.includes(method) : false;
};