import React, {useEffect, useState} from "react";
import PageHeader from "../../components/base/PageHeader";

import RegistrationRequestsTable from './RegistrationRequestsTable'
import RegistrationRequestsFilter from './RegistrationRequestsFilter'
import PanelLayout from "../../components/base/PanelLayout";


export default ({
    accountList,
    getAccounts,
    countryList,
    currencyList,
    onSetRedirectTab,
    registrationList,
    getRegistrationList,
    approveRegistration,
    rejectRegistration,
    setDefaultFormCreateAccount
}) => {

    useEffect(() => {
        getAccounts();
        getRegistrationList()
    }, []);

    const [filterState, onChangeFilter] = useState({processed: false});

    return (
        <>
            <PanelLayout>
                <PageHeader title="Registration requests"/>
                <RegistrationRequestsFilter
                    filter={filterState}
                    onChangeFilter={onChangeFilter}
                />
                <RegistrationRequestsTable
                    dataList={registrationList}
                    {...{
                        filter: filterState,
                        accountList,
                        countryList,
                        currencyList,
                        onSetRedirectTab,
                        getRegistrationList,
                        approveRegistration,
                        rejectRegistration,
                        setDefaultFormCreateAccount
                    }}
                />
            </PanelLayout>
        </>
    )
};