import React from 'react';
import {Panel} from 'rsuite';
import TabGeneral from './General/TabGeneral';
import TabRates from './Rates/TabRates';
import TabTrunks from './Trunks';
import TabSelfAllocation from './TabSelfAllocation/TabSelfAllocation'
import AccountHeader from './AccountHeader';
import TabAccessListFilters from './TabAccessListFilters/TabAccessListFilters';
import ApiPageContainer from 'pages/Account/ApiPage';
import TabPaymentManagement from "./TabPaymentManagement";
import Notes from "./Notes";

export default class extends React.Component {

    constructor(props) {
        super(props);

        this.storagedAcctiveTab = localStorage.getItem("ACCOUNT_ACTIVE_TAB");
        if (this.storagedAcctiveTab) {
            localStorage.removeItem("ACCOUNT_ACTIVE_TAB");
        }

        this.state = {
            activeTab: this.storagedAcctiveTab || 'trunks',
            resizedWidth: window.innerWidth,
        };
    }

    componentDidMount() {
        const {redirectTab} = this.props;

        this.tabTrunks = React.createRef();
        if (!redirectTab) {
            const tab = new URLSearchParams(this.props.location.search).get('tab');
            if (tab && ['general', 'trunks', 'rates', 'api', 'self-allocation', 'payment-management'].includes(tab)) this.setState({activeTab: tab});
        } else {
            this.setState({activeTab: redirectTab})
        }

        this.acc_key = this.props.match.params.id;
        this.props.getAccount(this.acc_key, !!this.props.hasData, true);
        this.getTrunks();
        this.props.getAccountUsers(this.acc_key);
        this.props.getRates(this.acc_key);

        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps, prevState) {
        const {activeTab} = this.state;

        if (prevState.activeTab !== activeTab && this.tabTrunks.current) {
            this.getTrunks();
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState.activeTab !== 'trunks' && this.props.allocatedNumbers !== nextProps.allocatedNumbers) {
            return false;
        }

        return true;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    onSelect = (activeKey) => {
        this.setState({activeTab: activeKey});
    };

    getRates = (filter) => this.props.getRates(this.acc_key, filter);
    getNumbers = (trunk_id, group, filter, page, per_page, sort) => () => {
        if (trunk_id) this.props.getAccountAllocatedNumbers(this.acc_key, trunk_id, group, filter, page, per_page, sort);
    };
    getTrunks = () => {
        if (this.tabTrunks.current)
            this.tabTrunks.current.setTrunk(null);
        this.props.getAccountTrunk(this.acc_key,
        );
    };
    getAccess = (filter, page, per_page, sort) => {
        this.props.getAccessListFilter(this.acc_key, filter, page, per_page, sort);
    };
    getApiKeys = () => this.props.getKeyList(this.acc_key);

    render() {
        const {activeTab, resizedWidth} = this.state;
        const {currency_list, payment_terms_list, account_manager_list, loadingReferences, accessList, accessListLoading} = this.props;
        const {accountUsers, account_user_modify_method, account_user_remove_method, accountLoading, accountUsersLoading} = this.props;

        const {
            account = {}, getAccount, trunks, trunksLoading, worldzone_list, subdestination_list, destination_list,
            allocatedNumbers, allocatedNumbersLoading, allocatedNumbersTotalCount, allocatedNumbersCount, allocatedNumbersPage,
            allocatedNumbersPerPage, currentTrunkId, setCurrentTrunkId, client_role_list, admin_role_list, client_allocation_limit,
            allocation_limit, setAllocationRestrictions, modifiedLimit, setModifiedLimitId, currentRangeNumber, setCurrentRangeNumber,
            getTrunkNumberCsvById, getAccountManagersList
        } = this.props;

        const acc_key = this.acc_key;
        const isGeneralTabDependencyLoading = loadingReferences || accountLoading || accountUsersLoading;

        const {rates, ratesLoading} = this.props;

        const isMobile = resizedWidth < 1200;

        return (

            <Panel style={{marginTop: 10}} classPrefix="white-panel">
                <AccountHeader
                    activeTab={activeTab}
                    onSelect={this.onSelect}
                    account={account}
                    isMobile={isMobile}
                />
                {
                    (() => {
                        switch (activeTab) {
                            case 'general':
                                return <TabGeneral
                                    disabled={isGeneralTabDependencyLoading}
                                    {...{
                                        account,
                                        currency_list,
                                        payment_terms_list,
                                        account_manager_list,
                                        client_role_list,
                                        admin_role_list,
                                        accountUsers,
                                        account_user_modify_method,
                                        account_user_remove_method,
                                        getAccount,
                                        loading: isGeneralTabDependencyLoading,
                                        getAccountManagersList
                                    }}
                                    getAccountUsers={() => this.props.getAccountUsers(this.acc_key)}
                                />;
                            case 'trunks':
                                return <TabTrunks
                                    ref={this.tabTrunks}
                                    worldzone_list={worldzone_list}
                                    subdestination_list={subdestination_list}
                                    destination_list={destination_list}
                                    getNumbers={this.getNumbers}
                                    getTrunks={this.getTrunks}
                                    {...{
                                        activeTab,
                                        trunks,
                                        trunksLoading,
                                        acc_key,
                                        account,
                                        currentRangeNumber,
                                        setCurrentRangeNumber,
                                        currentTrunkId,
                                        setCurrentTrunkId,
                                        allocatedNumbers,
                                        allocatedNumbersLoading,
                                        allocatedNumbersCount,
                                        allocatedNumbersTotalCount,
                                        allocatedNumbersPage,
                                        allocatedNumbersPerPage,
                                        getTrunkNumberCsvById,
                                        isMobile,
                                    }}
                                />;
                            case 'rates':
                                return <TabRates
                                    {...{
                                        rates,
                                        ratesLoading,
                                        worldzone_list,
                                        subdestination_list,
                                        destination_list
                                    }}
                                    getItems={this.getRates}
                                    account_id={this.acc_key}
                                />;
                            case 'access':
                                return <TabAccessListFilters {...{
                                        accessList,
                                        accessListLoading
                                    }}
                                    getItems={this.getAccess}
                                    account_id={this.acc_key}
                                />;
                            case 'api':
                                return <ApiPageContainer
                                    getItems={this.getApiKeys}
                                    account_id={this.acc_key}
                                />;
                            case 'self-allocation':
                                return <TabSelfAllocation
                                    {...{
                                        account,
                                        getAccount,
                                        allocationLimits: allocation_limit,
                                        loading: isGeneralTabDependencyLoading,
                                        setAllocationRestrictions,
                                        defaultAllocationLimits: client_allocation_limit,
                                        setModifiedLimitId,
                                        modifiedLimit
                                    }}
                                />;
                            
                            case 'payment-management':
                                return <TabPaymentManagement
                                    accountId={this.acc_key}
                                />;

                            case 'notes':
                                return <Notes
                                    accountId={this.acc_key}
                                />;

                            default:
                                return null;
                        }
                    })()
                }
            </Panel>
        );
    }
};