import React from 'react';
import { Form, FormGroup,SelectPicker, Input, InputGroup, Icon, Schema} from 'rsuite';
import CustomField from '../../../components/Form/CustomField';
import FormHOC from '../../../hoc/FilltersForm';
import InputSearch from '../../../components/Form/InputSearch';
const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});

const RangeFilters =   ( {onChange, onUpdateRef} ) =>  {

    return (
            <>
                <Form layout="inline"
                    onChange={onChange}
                    ref= { ref => onUpdateRef(ref)}
                    // formDefaultValue={
                    model={filtersModel}
                    >
                    <CustomField
                        name="str"
                        accepter={InputSearch}
                        errorPlacement="topRight"
                        placeholder="Range name/Numbers search"
                        style={{width:220}}
                    />
                </Form>
            </>
        )
}

export default FormHOC (RangeFilters, filtersModel,300);