import React from "react";
import { useWindowWidth } from "../../hooks";
import { DateRangePicker } from "rsuite";
import CustomField from "./CustomField";

export default ({name = "start_end_date", ...props}) => {

    const resizedWidth = useWindowWidth();

    return (
        <CustomField
            name={name}
            showOneCalendar={resizedWidth > 767 ? false : true}
            accepter={DateRangePicker}
            placement="auto"
            cleanable={false}
            ranges={[
                {
                    label: "Yesterday",
                    value: [
                        new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)),
                        new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999))
                    ]
                },
                {
                    label: "Today",
                    value: [
                        new Date(new Date().setHours(0, 0, 0, 0)),
                        new Date(new Date().setHours(23, 59, 59, 999))
                    ]
                },
                {
                    label: "Last 7 days",
                    value: [
                        new Date(new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0, 0)),
                        new Date(new Date().setHours(23, 59, 59, 999))
                    ]
                }
            ]}
            {...props}
        />
    )
}