import React from 'react';
import {FlexboxGrid} from 'rsuite'
import PageHeader from '../../components/base/PageHeader'
import AccessListFilters from './AccessListFilters'
import AccessListTable from './AccessListTable'
import PanelLayout from '../../components/base/PanelLayout';
import ExportCsv from '../../components/ExportCsv';
import {connect} from "react-redux";
import {getAccessList} from "../../actions/access_list";
import {USD_DEFAULT_CURRENCY_KEY} from "../../const";

class AccessList extends React.Component {
    constructor(props) {
        super(props);

        this.filters = {
            str: '',
            sp_key: props.defaultSPKey,
            cur_key: USD_DEFAULT_CURRENCY_KEY
        };
        this.defaultSort = {column: "a_subdestination_name", type: "asc"};
        this.defaultSortOTP = {column: "time", type: "desc"};
        this.state = {
            spAuth: false,
            sort: {...this.defaultSort}
        }
    }

    componentDidMount() {
        const {getAccessList, per_page, spAuth} = this.props;

        getAccessList(this.filters, 1, per_page, this.state.sort, spAuth);
    }

    onChangeFilters = (filtersObj) => {
        const {getAccessList, per_page, service_plan_list} = this.props;
        
        const currentServicePlan = service_plan_list.find(item => item.sp_key === filtersObj.sp_key);
        if (this.filters.sp_key !== filtersObj.sp_key) {
            this.setState({
                spAuth: currentServicePlan.auth,
                sort: currentServicePlan.auth 
                    ? {...this.defaultSortOTP} 
                    : {...this.defaultSort}
            });
        }
        
        this.filters = filtersObj;
        
        getAccessList(this.filters, 1, per_page, this.state.sort, currentServicePlan.auth);
    };

    getItems = (page, per_page, sort) => {
        const { getAccessList } = this.props;
        const {spAuth} = this.state;

        getAccessList(this.filters, page, per_page, sort, spAuth);
    };

    render () {
        const {accessList, accessListLoading, page, count, per_page, currency_list, paymentTermsList} = this.props;
        const {spAuth} = this.state;

        const method = spAuth ? "access_list_otp__get_list" : "access_list__get_list";

        return (
            <PanelLayout>
                <PageHeader title="Access List">
                    <ExportCsv
                        method={method}
                        params={{filter: this.filters}}
                        title='Download'
                        fileName='accesses_list.csv'
                        style={{position: 'relative', right: 15}}
                    />
                </PageHeader>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={24}>
                        <AccessListFilters 
                            onChange = {this.onChangeFilters}
                            defaultFormValue = {this.filters}
                            currency_list={currency_list}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
                <AccessListTable
                    {...{
                        accessList,
                        accessListLoading,
                        page, 
                        count,
                        per_page,
                        spAuth
                    }}
                    paymentTermsList={paymentTermsList}
                    getItems = {this.getItems}
                    onSort={(column, type) => this.setState({sort: {column, type}})}
                    sortType={this.state.sort.type}
                    sortColumn={this.state.sort.column}
                    filters={this.filters}
                />
                
            </PanelLayout>
        )
    }
}

const mapState = ( {access_list, references} )=> ({
    accessList: access_list.items,
    count: access_list.count,

    accessListLoading: access_list.loading,
    page: access_list.page,
    per_page: access_list.per_page,
    defaultSPKey: references.defaultSPKey,
    currency_list: references.currency_list,
    paymentTermsList: references.payment_terms_list,
    service_plan_list: references.service_plan_list,
});

export default connect(mapState, {
    getAccessList
})(AccessList);