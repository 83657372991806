import React from "react";
import {ButtonToolbar, FlexboxGrid} from "rsuite";
import {
    ALLOCATION_TEMPLATE_DIALER_API,
    ALLOCATION_SUBDESTINATION_DIALER_API,
    ALLOCATION_PRICE_RANGE_DIALER_API
} from "../../../const/apiMethods";
import {defineMessages, FormattedMessage, injectIntl, useIntl} from "react-intl";
import {useWindowWidth} from "../../../hooks";
import {BaseButton} from "../../base/BaseButton";
import styled from "styled-components";
import {CustomModalClient} from "../../base";
import m from "definedMessages";


// Codes
const reasonCodes = {
    not_found_rate: "not_found_rate",
    not_found_free_numbers: "not_found_free_numbers",
    account_trunk_range_mismatch: "account_trunk_range_mismatch",
    self_allocation_limit_exceeded_once: "self_allocation_limit_exceeded_once",
    self_allocation_limit_exceeded: "self_allocation_limit_exceeded",
    self_allocation_for_sde_limit_exceeded: "self_allocation_for_sde_limit_exceeded",
    not_defined_range: "not_defined_range",

    prefix_in_quarantine: "prefix_in_quarantine",
    non_google_otp_allocation: "non_google_otp_allocation",
    max_numbers_per_range_value_exceeded: "max_numbers_per_range_value_exceeded",
    allocation_to_test_trunk: "allocation_to_test_trunk",
    non_google_otp_trunk: "non_google_otp_trunk",
    non_multiple_100_number: "non_multiple_100_number",
    not_allowed_self_google_otp_allocation: "not_allowed_self_google_otp_allocation",
    non_power_of_10_number: "non_power_of_10_number",
    non_power_of_10_number_start_1000: "non_power_of_10_number_start_1000",
    illegal_template_length: "illegal_template_length",
    illegal_template: "illegal_template",
    too_much_operations: "too_much_operations",
};


export default (
    {
        show,
        onClose,
        onSuccess,
        changeRandom = () => {},
        maxWidth = 600,
        params = {},
    }
) => {

    const {
        trunk = {},
        range = "",
        subDest = "",
        template,
        reasonCode,
        isRandom,
        method,
        numbersAmount,
        reasonHash
    } = params;

    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();


    const handleSuccess = () => {
        onClose(false);
        if (reasonCode === reasonCodes.not_found_free_numbers && !isRandom) {
            changeRandom(true);
        }
        onSuccess(true);
    };

    
    const getMessage = () => {

        switch (reasonCode) {

            case reasonCodes.non_google_otp_trunk:
                return "Trunk is not Gooogle OTP";

            case reasonCodes.non_google_otp_allocation:
                return "Incorrect allocation method";

            case reasonCodes.non_power_of_10_number:
                return "Incorrect number amount";

            case reasonCodes.non_power_of_10_number_start_1000:
                return "Incorrect number amount";

            case reasonCodes.too_much_operations:
                return formatMessage(m.tooMuchSimultaneousOperations);
            
            case reasonCodes.prefix_in_quarantine:
                return formatMessage(m.numbersAreTemporarilyUnavailable);

            case reasonCodes.max_numbers_per_range_value_exceeded:
                return formatMessage(m.tooMuchNumbersPerRange);

            case reasonCodes.allocation_to_test_trunk:
                return formatMessage(m.invalidOperation);
                
            case reasonCodes.non_multiple_100_number:
                return formatMessage(m.incorrectNumberAmount);

            case reasonCodes.illegal_template:
            case reasonCodes.illegal_template_length:
                return formatMessage(m.wrongTemplate);

            case reasonCodes.not_allowed_self_google_otp_allocation:
                return formatMessage(m.actionIsProhibited);

            case reasonCodes.self_allocation_limit_exceeded_once:
                return formatMessage(m.tooMuchNumbersPerOne);

            case reasonCodes.self_allocation_limit_exceeded:
                return formatMessage(m.youHaveExceededTheLimit);

            case reasonCodes.self_allocation_for_sde_limit_exceeded:
                return formatMessage(m.tooMuchNumbersPerSubdestination);

            // ALLOCATION_TEMPLATE_DIALER_API, ALLOCATION_SUBDESTINATION_DIALER_API
            case reasonCodes.not_found_rate:
                return formatMessage(m.destinationIsNotAvailable);

            // ALLOCATION_TEMPLATE_DIALER_API, ALLOCATION_PRICE_RANGE_DIALER_API
            case reasonCodes.account_trunk_range_mismatch:
                if (method === ALLOCATION_TEMPLATE_DIALER_API)
                    return formatMessage(m.accountTrunkRangeMismatchTemplate, {
                        template: template,
                        trunkName: trunk.name
                    });
                if (method === ALLOCATION_PRICE_RANGE_DIALER_API)
                    return formatMessage(m.accountTrunkRangeMismatchRange, {
                        range: range,
                        trunkName: trunk.name
                    });
                return formatMessage(m.numbersCannotBeAllocatedToTrunk, {trunkName: trunk.name});

            // ALLOCATION_TEMPLATE_DIALER_API, ALLOCATION_PRICE_RANGE_DIALER_API
            case reasonCodes.not_found_free_numbers:
                if (method === ALLOCATION_TEMPLATE_DIALER_API)
                    return !isRandom
                        ? formatMessage(m.notFoundFreeNumbersTemplate, {
                            numbersAmount: numbersAmount,
                            template: template
                        })
                        : formatMessage(m.numbersFromTemplateCannotBeAllocated, {
                            template: template
                        });
                if (method === ALLOCATION_PRICE_RANGE_DIALER_API)
                    return !isRandom
                        ? formatMessage(m.notFoundFreeNumbersRange, {
                            numbersAmount: numbersAmount,
                            range: range
                        })
                        : formatMessage(m.numbersFromRangeCannotBeAllocated, {range: range})
                if (method === ALLOCATION_SUBDESTINATION_DIALER_API)
                    return !isRandom
                        ? formatMessage(m.notFoundFreeNumbersDestination, {
                            numbersAmount: numbersAmount,
                            subDest: subDest
                        })
                        : formatMessage(m.numbersForSubdestCannotBeAllocated, {
                            subDest: subDest
                        });
                return formatMessage(m.numbersCannotBeAllocated);

            // ALLOCATION_TEMPLATE_DIALER_API, ALLOCATION_PRICE_RANGE_DIALER_API, ALLOCATION_SUBDESTINATION_DIALER_API
            default:
                if (template)
                    return formatMessage(m.numbersFromTemplateCannotBeAllocated, {template: template});
        }

        return formatMessage(m.numbersCannotBeAllocated)
    };

    return (
        <CustomModalClient
            {...{show, onClose}}
            onClose={handleSuccess}
            onSuccess={handleSuccess}
            showCloseSuccessButtons={false}
            width={windowWidth > maxWidth ? maxWidth : windowWidth}
        >

            <FlexboxGrid justify="end">
                <FlexboxGrid.Item colspan={23}>
                    <span>{getMessage()}{reasonHash ? ` (${formatMessage(m.operationCode)}: ${reasonHash})` : ""}</span>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <ModalButtonToolbar>
                <BaseButton
                    buttonStyle="default"
                    onClick={handleSuccess}
                    autoFocus
                >
                    <FormattedMessage id="common.close" defaultMessage="Close" />
                </BaseButton>
            </ModalButtonToolbar>

        </CustomModalClient>
    )
};

const ModalButtonToolbar = styled(ButtonToolbar)`
    float: right;
    margin-right: 20px;
    margin-top: 40px;
    right: 0;
`;