import React from 'react';
import AccessListFiltersContainer from './AccessListFilters/AccessListFiltersContainer';
import {Header} from './styled';


export default  class  extends React.Component{
    render () {
        const { account_id, accessListLoading } = this.props;

        return (
            <>
                <Header>Access list mailing</Header>
                <AccessListFiltersContainer/>
            </>
        )
    }
}
