import {defineMessages} from "react-intl";

export default defineMessages({
    correctNumber: {
        id: 'validation.correctNumber',
        defaultMessage: 'Enter the correct number',
        description: 'common: Enter the correct number'
    },
    eachRowShouldBeSize: {
        id: 'validation.eachRowShouldBeSize',
        defaultMessage: 'Each row should be greater than {minNumber} and less than {maxNumber}',
        description: 'common: Each row should be greater than {minNumber} and less than {maxNumber}'
    },
    fileSizeShouldNotExceed: {
        id: 'validation.fileSizeShouldNotExceed',
        defaultMessage: 'File size should not exceed {size} MB',
        description: 'common: File size should not exceed {size} MB'
    },
    incorrectTemplate: {
        id: 'validation.incorrectTemplate',
        defaultMessage: 'Incorrect template',
        description: 'common: Incorrect template'
    },
    maxLength: {
        id: 'validation.maxLength',
        defaultMessage: 'The maximum of this field is {count} {count, plural, one {character} other {characters}}',
        description: 'common: The maximum of this field is {count} {count, plural, one {character} other {characters}}'
    },
    maxLengthDigits: {
        id: 'validation.maxLengthDigits',
        defaultMessage: 'The maximum of this field is {count} {count, plural, one {digit} other {digits}}',
        description: 'common: The maximum of this field is {count} {count, plural, one {digit} other {digits}}'
    },
    maxLengthOfTemplate: {
        id: 'validation.maxLengthOfTemplate',
        defaultMessage: 'The maximum length of template is {count}',
        description: 'common: The maximum length of template is {count}'
    },
    maxNumber: {
        id: 'validation.maxNumber',
        defaultMessage: 'The maximum number is {number}',
        description: 'common: The maximum number is {number}'
    },
    minLengthDigits: {
        id: 'validation.minLengthDigits',
        defaultMessage: 'The minimum of this field is {count} {count, plural, one {digit} other {digits}}',
        description: 'common: The minimum of this field is {count} {count, plural, one {digit} other {digits}}'
    },
    minLengthOfTemplate: {
        id: 'validation.minLengthOfTemplate',
        defaultMessage: 'The minimum length of template is {count}',
        description: 'common: The minimum length of template is {count}'
    },
    minNumber: {
        id: 'validation.minNumber',
        defaultMessage: 'The minimum number is {number}',
        description: 'common: The minimum number is {number}'
    },
    numberMust: {
        id: 'validation.numberMust',
        defaultMessage: 'The number must not begin with 0 and must contain only digits',
        description: 'common: The number must not begin with 0 and must contain only digits'
    },
    numberOfPhoneNumbersExceeds: {
        id: 'validation.numberOfPhoneNumbersExceeds',
        defaultMessage: 'The number of entered telephone numbers exceeds {number} rows',
        description: 'common: The number of entered telephone numbers exceeds {number} rows'
    },
    numbersFromTemplateCannotBeAllocated: {
        id: 'validation.numbersFromTemplateCannotBeAllocated',
        defaultMessage: 'Numbers from template "{template}" cannot be allocated',
        description: 'common: Numbers from template "{template}" cannot be allocated'
    },
    passwordLetters: {
        id: 'validation.passwordLetters',
        defaultMessage: 'At least one number and uppercase and lowercase letter',
        description: 'common: At least one number and uppercase and lowercase letter'
    },
    passwordMinLength: {
        id: 'validation.passwordMinLength',
        defaultMessage: 'Password must be at least {count} {count, plural, one {character} other {characters}}',
        description: 'common: Password must be at least {count} {count, plural, one {character} other {characters}}'
    },
    pleaseEnterEmail: {
        id: 'validation.pleaseEnterEmail',
        defaultMessage: 'Please enter an email address',
        description: 'common: Please enter an email address'
    },
    pleaseEnterValidNumber: {
        id: 'validation.pleaseEnterValidNumber',
        defaultMessage: 'Please enter a valid number',
        description: 'common: Please enter a valid number'
    },
    pleaseEnterValidNumbers: {
        id: 'validation.pleaseEnterValidNumbers',
        defaultMessage: 'Please enter valid numbers',
        description: 'common: Please enter valid numbers'
    },
    pleaseEnterValidString: {
        id: 'validation.pleaseEnterValidString',
        defaultMessage: 'Please enter a valid string',
        description: 'common: Please enter a valid string'
    },
    templateShouldBeSize: {
        id: 'validation.templateShouldBeSize',
        defaultMessage: 'Template should be greater than {minNumber} and less than {maxNumber}',
        description: 'common: Template should be greater than {minNumber} and less than {maxNumber}'
    },
    theNumberMustBeAMultipleOf: {
        id: 'validation.theNumberMustBeAMultipleOf',
        defaultMessage: 'The number must be a multiple of {value}',
        description: 'common: The number must be a multiple of {value}'
    },
    thisFieldIsRequired: {
        id: 'validation.thisFieldIsRequired',
        defaultMessage: 'This field is required',
        description: 'common: This field is required'
    },
    tooFewNumbers: {
        id: 'validation.tooFewNumbers',
        defaultMessage: 'Too few numbers',
        description: 'common: Too few numbers'
    },
    tooMuchNumbers: {
        id: 'validation.tooMuchNumbers',
        defaultMessage: 'Too much numbers',
        description: 'common: Too much numbers'
    },
})