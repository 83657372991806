import {IVR_DEFAULT as PREFIX} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {IVR_DEFAULT_API, IVR_SET_DEFAULT_API} from "../const/apiMethods";
import {Alert} from 'rsuite';

const setDefaultIvr = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);

export const getDefaultIvr = () => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_DEFAULT_API)
        .then(({ivr_default}) => {
            if (ivr_default === undefined) {
                return;
            }
            dispatch(setDefaultIvr({ivr_default}));
        })
        .finally(() => dispatch(setLoading(false)));
};


export const changeDefaultIvr = (params) => (dispatch) => {
    dispatch(setLoading(true));
    api(IVR_SET_DEFAULT_API, params)
        .then((r) => {
            if (r && r.ivr) {
                Alert.success("Default IVR was successfully changed");
            }
        })
        .finally(() => dispatch(setLoading(false)));
};