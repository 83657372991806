import React, {useState} from 'react';
import apiNavigation from './apiNavigation.json';
import {Tree, List, Icon} from 'rsuite';
import {NavHashLink as NavLink} from 'react-router-hash-link';
import styled from 'styled-components';

export default ({keyList, loading, onSelect: handleSelect, ...props}) => {

    const [apiTurn, setApiTurn] = useState('docs');
    
    return (
        <Tree
            className="api_menu"
            defaultExpandAll={true}
            defaultValue={'docs'}
            data={apiNavigation}
            style={{
                maxWidth: '200px',
                height: '360px',
                overflowX: 'hidden'
            }}
            renderTreeNode={(node) => {
                const activeKeyList = keyList.length && keyList.filter(key => key.active);
                const keysHaveLoading = (loading || !activeKeyList.length) && node.value === "keys";

                return (
                    !keysHaveLoading ?
                        <span
                            className="api_menu_item"
                            onClick={() => {
                                // console.log("node.value", node.value);
                                setApiTurn(node.value);
                                handleSelect(node.value);
                            }}
                        >
                            {node.label}

                            {node.anchors && apiTurn === node.value &&
                                <List className="api_menu_item-anchor">
                                    {node.anchors.map(anchor => {
                                        return (
                                            <List.Item className="api_menu_item_anchor-item">
                                                <NavLink className="api_menu_item_anchor_item-link" to={anchor.to}>
                                                    {anchor.icon && 
                                                        <StyledIcon icon={anchor.icon}/>
                                                    }
                                                    {anchor.label}
                                                </NavLink>
                                            </List.Item>
                                        )
                                    })}
                                </List>
                            }
                        </span>

                    : <></>
                )
            }}
        />
    )
}

const StyledIcon = styled(Icon)`
    margin-right: 5px;

    .rtl & {
        margin-left: 5px;
        margin-right: 0;
    }
`;