import React, {useState} from 'react';
import PageHeader from "../../components/base/PageHeader";
import {FlexboxGrid} from "rsuite";
import ExportCsv from "../../components/ExportCsv";
import PanelLayout from "../../components/base/PanelLayout";
import CDRTable from "./CDRTable";
import CDRFilters from "pages/CDR/CDRFilters";


const CDRPage = () => {
    const today = new Date();
    const [filters, setFilters] = useState({range:[today, today]});

    return (
        <PanelLayout>
            <PageHeader title="CDR">
                <ExportCsv
                    method='cdr_full:get_list_admin'
                    params={{filter: filters}}
                    title='Export CSV'
                    fileName='cdr.csv'
                    style={{position: 'relative', right: 15}}
                />
            </PageHeader>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <CDRFilters onApplyFilters={(filters) => {setFilters(filters)}} defaultFilters={filters}/>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <CDRTable filters={filters} />
        </PanelLayout>
    );
};

export default CDRPage;