import React, {useEffect, useState} from "react";
import PaymentsRequestSection from "./PaymentsRequestSection";
import PaymentsListSection from "./PaymentsListSection";
import PaymentsTrafficReportsSection from "./PaymentsTrafficReportsSection";
import ModalRequestPayment from "./ModalRequestPayment";
import ModalPaymentDetails from "./ModalPaymentDetails";
import ModalAddPaymentDetail from "../PaymentDetails/ModalAddPaymentDetail";
import {Panel} from "rsuite";
import PaymentsHeader from "./PaymentsHeader";
import HawalaPaymentRequest from "./HawalaPaymentRequest";


export default ({
    paymentItems,
    paymentItemsLoading,
    paymentRequestItems,
    paymentRequestLoading,
    paymentTrafficReportsItems,
    paymentTrafficReportsLoading,
    paymentDetailsItems,
    hawalaItems,
    hawalaLoading,

    accounts,
    account_manager_list,
    organizationTypeList,
    paymentMethodList,
    paymentTermsList,
    countryList,
    globalSettings,

    // API methods
    getPaymentList,
    getTrafficReportsList,
    getPaymentRequestList,
    getHawalaPaymentRequestList,
    getAccountManagersList,
    requestPayment,
    cancelPaymentRequest,
    approvePaymentRequest,
    getPaymentDetailsList,
    createPaymentDetails,
    modifyPaymentDetails,
    getAccount,
    getAccounts,
}) => {

    const [activeTab, setActiveTab] = useState('payment-requests'); // invoices, payment-requests, payments
    const [requestPaymentFormValue, setRequestPaymentFormValue] = useState({amount: 0});
    const [showModalRequestPayment, setShowModalRequestPayment] = useState(false);
    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [showModalPaymentDetail, setShowModalPaymentDetail] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(true);
    const [amountLimitMin, setAmountLimitMin] = useState(0.01);

    // active account info (on action button click)
    const [activeAccountId, setActiveAccountId] = useState(null);
    const [activeAccountBalance, setActiveAccountBalance]  = useState(0);
    const [activeAccountCurrency, setActiveAccountCurrency]  = useState(null);

    useEffect( () => {
        getAccounts();
    }, [] );

    useEffect( () => {
        // get account and set options
        if (activeAccountId)
            getAccount(activeAccountId);
    }, [activeAccountId]);


    // set payment detail amount min limit
    useEffect( () => {
        if (!requestPaymentFormValue.payment_detail_id || !paymentMethodList.length || !paymentDetailsItems.length) {
            setAmountLimitMin(0.01);
            return;
        }
        const currentPaymentDetail = paymentDetailsItems.find(item => item.id === requestPaymentFormValue.payment_detail_id);
        if (!currentPaymentDetail) {
            setAmountLimitMin(0.01);
            return;
        }

        const currentPaymentMethod = paymentMethodList.find(item => item.pm_key === currentPaymentDetail.pm_key);
        const currentPaymentMethodLimit = currentPaymentMethod && currentPaymentMethod.limit
            ? currentPaymentMethod.limit
            : 0.01;
        setAmountLimitMin(currentPaymentMethodLimit);
    }, [requestPaymentFormValue, paymentMethodList, paymentDetailsItems] );


    const clearActiveData = () => {
        setActiveAccountId(null);
        setActiveAccountBalance(0);
        setActiveAccountCurrency(null);
    };


    const openModalRequestPayment = (value) => {
        setActiveAccountId(value.account_id);
        setActiveAccountBalance(value.available_for_payment);
        setActiveAccountCurrency(value.currency_name);
        setShowModalRequestPayment(true);
    };


    const closeModalRequestPayment = () => {
        setShowModalRequestPayment(false);
        clearActiveData();
    };


    const openModalPaymentDetail = () => {
        setShowModalPaymentDetail(true);
    };


    const handleShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
        setDropdownVisibility(false);
    };


    const closeModalAddPaymentDetail = (value) => {
        if (value) {
            setRequestPaymentFormValue({
                ...requestPaymentFormValue,
                payment_detail_id: value.payment_detail.id
            });
        }
        setShowModalAddPaymentDetail(false);
        setDropdownVisibility(true);
    };


    const handleSetActiveTab = (activeTab) => {
        // setBreadcrumbs(`${document.location.pathname}/${activeTab}`);
        setActiveTab(activeTab);
    };


    return (
        <Panel
            style={{marginTop: 10}}
            classPrefix="white-panel"
        >

            <PaymentsHeader
                activeTab={activeTab}
                onSelect={handleSetActiveTab}
            />

            {( () => {

                switch (activeTab) {

                    case 'payment-requests':
                        return (
                            <PaymentsRequestSection
                                {...{
                                    paymentRequestItems,
                                    setActiveAccountId,
                                    setShowModalPaymentDetail,
                                    paymentRequestLoading,
                                    cancelPaymentRequest,
                                    approvePaymentRequest,
                                    getPaymentRequestList,
                                    openModalRequestPayment,
                                    openModalPaymentDetail,
                                    accounts,
                                }}
                            />
                        );

                    case 'payments':
                        return (
                            <PaymentsListSection
                                {...{
                                    paymentItems,
                                    paymentItemsLoading,
                                    getPaymentList,
                                    accounts,
                                }}
                            />
                        );

                    case 'invoices':
                        return (
                            <PaymentsTrafficReportsSection
                                {...{
                                    globalSettings,
                                    paymentTrafficReportsItems,
                                    paymentTrafficReportsLoading,
                                    openModalRequestPayment,
                                    getTrafficReportsList,
                                    accounts,
                                }}
                            />
                        );

                    case 'hawala-payment-request':
                        return (
                            <HawalaPaymentRequest
                                data={hawalaItems}
                                loading={hawalaLoading}
                                fetchData={getHawalaPaymentRequestList}
                                {...{
                                    account_manager_list,
                                    requestPayment,
                                    paymentMethodList,
                                    paymentTermsList,
                                    getAccountManagersList
                                }}
                            />
                        );

                    default:
                        return null;
                }

            } )()}

            <ModalRequestPayment
                accountId={activeAccountId}
                show={showModalRequestPayment}
                onClose={closeModalRequestPayment}
                formValue={requestPaymentFormValue}
                setFormValue={setRequestPaymentFormValue}
                amountLimit={activeAccountBalance}
                amountLimitMin={amountLimitMin}
                currencyName={activeAccountCurrency}
                {...{
                    requestPayment,
                    paymentDetailsItems, 
                    dropdownVisibility,
                    handleShowModalAddPaymentDetail,
                    paymentMethodList,
                    accounts,
                }}
            />

            <ModalPaymentDetails
                accountId={activeAccountId}
                show={showModalPaymentDetail}
                onClose={() => setShowModalPaymentDetail(false)}
            />
            
            <ModalAddPaymentDetail
                accountId={activeAccountId}
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                {...{
                    organizationTypeList,
                    countryList,
                    paymentMethodList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    getPaymentDetailsList
                }}
            />

        </Panel>
    )
};