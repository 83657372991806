import {checkVersionAxios, requestAxios, requestAxiosWithHeaders, requestFormDataAxios} from '.';
import {responseErrorToString} from "utils";
import {Alert} from 'rsuite';
import axios from "axios";
import {pageIdLogObject} from "../utils/pageIdLogger";
import {APP_VERSION} from "../index";
import _ from "lodash";




export const api = (method = '', params = {}, processErrorManual, cancelToken, withHeaders = false) => {

    const paramsCloned = {...params};
    const config = {
        headers: {"X-App-Version": 'atx_admin/' + APP_VERSION}
    };

    // cancelToken as f argument is deprecated. use params property "cancelToken" instead
    if (cancelToken) {
        config.cancelToken = cancelToken.token;
    }

    if (paramsCloned.cancelToken) {
        config.cancelToken = paramsCloned.cancelToken.token;
        delete paramsCloned.cancelToken;
    }

    const currentPage = pageIdLogObject.pageMatch.path;
    const requestApi = withHeaders ? requestAxiosWithHeaders : requestAxios;
    return requestApi.post('',
        {
            jsonrpc: "2.0",
            method,
            params: paramsCloned,
            page_id: currentPage,
            id: null
        },
        config
    )
    .then(response => {
        if (axios.isCancel(response))
            return response;

        const result = _.get(response, 'data.result', {});

        if ( result.reason_code && method.lastIndexOf("allocation:") === 0 ) {
            result.hash = response.data.hash;
        }

        return (response && response.data && response.data.result) || {}
    })
        .catch((error) => {

            if (error && !error.response) {
                return error;
            }

            if (processErrorManual) {
                return error.response.data;
            } else {

                if ( error.response && error.response.data.error.reason_code && method.lastIndexOf("allocation:") === 0 ) {
                    const data = error.response.data.error;
                    return {
                        ...data,
                        hash: error.response.data.hash
                    };
                }

                if (!error.response || error.response.status !== 503)
                    Alert.error(responseErrorToString(error.response.data.error));
            }
        })
};




export const apiFormData = (method = '', params = {}, processErrorManual) => {

    const paramsCloned = {...params};
    return requestFormDataAxios.post("",
        {
            jsonrpc: "2.0",
            method,
            params: paramsCloned,
            page_id: pageIdLogObject.pageMatch.path,
            id: null
        }
    )
    .then(response => (
        response && response.data && response.data.result) || {}
    )
    .catch((error) => {
        if (error && !error.response) {
            return error;
        }
        if (processErrorManual) {
            return error.response.data;
        } else {
            if (!error.response || error.response.status !== 503) {
                Alert.error(responseErrorToString(error.response.data.error));
            }
        }
    })
};




export const generateApiUrl = ({method, path = ''}) => {
    return `${process.env.REACT_APP_API_URL}${path}?method=${method}`
};




export const getCsvResponse = (method, params) => {
    const currentPage = pageIdLogObject.pageMatch.path;

    return requestAxios.post(
        generateApiUrl({method, path: '/csv'}),
        {
            jsonrpc: '2.0',
            method,
            params,
            page_id: currentPage,
            id: null,
        },
        {
            responseType: 'text/csv',
            headers: {
                "X-App-Version": 'atx_admin/' + APP_VERSION,
            }
        }

        )
        .then( response => (response && response.data) )
        .catch( e => null )
};




export const getFileResponse = (method, params) => {
    const currentPage = pageIdLogObject.pageMatch.path;

    return requestAxios.post(
        generateApiUrl({method, path: '/csv'}),
        {
            jsonrpc: '2.0',
            method,
            params,
            page_id: currentPage,
            id: null,
        },
        {
            responseType: 'blob',
            headers: {
                "X-App-Version": 'atx_admin/' + APP_VERSION,
            }
        }

    )
    .then( response => (response && response.data) )
    .catch( e => null )
};




export const apiWithHeaders = (method, params) => {
    const currentPage = pageIdLogObject.pageMatch.path;
    console.log("+++ awh ", currentPage, method);
    return requestAxiosWithHeaders.post(``,
        {
            jsonrpc: '2.0',
            params,
            method,
            page_id: currentPage,
            id: null
        },
        {headers: {
                "X-App-Version": 'atx_client/' + APP_VERSION,
                httpAgent: 123
            }})
        .then(response => (response && response.data && response.data.result) || {})
        .catch(e => e)
};




export const getAppVersion = () => {
    return checkVersionAxios.get(`/meta.json?timestamp=${Date.now()}`)
        .then( (response) => response && response.data && response.data.version
            ? response.data
            : null
        )
        .catch( e => null )
};