import {connect} from "react-redux";
import {getLogsList, getReferences} from "../../actions/action_logs";
import ActionLogs from "./ActionLogs";
import {getDropdownAccounts} from "../../actions/dropdown_accounts";


const mapStateToProps = ({action_logs, dropdown_accounts}) => ({
    managerList: action_logs.managerList,
    actionList: action_logs.actionList,
    userList: action_logs.userList,
    referencesFetched: action_logs.referencesFetched,
    
    data: action_logs.list,
    count: action_logs.count,
    page: action_logs.page,
    perPage: action_logs.perPage,
    loading: action_logs.loading,

    accounts: dropdown_accounts.items,
    accountsFetched: dropdown_accounts.fetched,
});

export default connect(mapStateToProps, {
    getReferences,
    getLogsList,
    getAccounts: getDropdownAccounts,
})(ActionLogs);