import {connect} from 'react-redux';
import PageIvrSpecialCli from './PageIvrSpecialCli';
import {getIvrCliList} from '../../../actions/ivr_cli';
import {getIvrList} from '../../../actions/ivr';

const mapState = ({ivr_cli, ivr}) => ({
    list: ivr_cli.list,
    loading: ivr_cli.loading,
    ivrList: ivr.list,
});

export default connect(mapState, {
    getIvrCliList,
    getIvrList
})(PageIvrSpecialCli);