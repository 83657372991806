import {
    SET_NUMBER_ALLOCATION_MODAL
} from '../const/';

const initialState = {
    showAllocation: false
};

const handlers = {
    [SET_NUMBER_ALLOCATION_MODAL]: (state, {payload: showAllocation}) => ({...state, showAllocation}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action)
};
