import React, {useEffect} from "react";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import AccountManagersTable from "./AccountManagersTable";
import AccountManagersCreateForm from "./AccountManagersCreateForm";

export default ({
    managersList,
    loading,
    teamLeadList,
    userList,
    billingList,
    getAccountManagersList,
    getAccountManagersReferences,
    getAccountManagersBillingList,
    createAccountManager,
    modifyAccountManager,
    removeAccountManager,
}) => {

    useEffect(() => {
        getAccountManagersList();
        getAccountManagersReferences();
        getAccountManagersBillingList();
    }, []);

    return (
        <PanelLayout>
            <PageHeader title="Account Managers"/>
            <AccountManagersTable
                data={managersList}
                {...{
                    loading,
                    teamLeadList,
                    userList,
                    billingList,
                    modifyAccountManager,
                    removeAccountManager,
                    getAccountManagersList,
                }}
            />
            <AccountManagersCreateForm
                onSubmit={createAccountManager}
                {...{
                    managersList,
                    billingList,
                    teamLeadList,
                    userList,
                    getAccountManagersList,
                }}
            />
        </PanelLayout>
    )
};