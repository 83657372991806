import React, {useEffect, useState} from "react";
import PaymentsSectionHeader from "../../Payments/PaymentsSectionHeader";
import PaymentsListTable from "../../Payments/PaymentsListSection/PaymentsListTable";
import { Spacer } from "../../../components/base/Spacer";
import {LOCAL_STORAGE_PAYMENTS_LIST_FILTERS} from "../../../const/localStorageKeys";
import {getISOTime} from "../../../utils/helpers";
import { Collapse } from "react-collapse";
import CommonDateFilter from "../../Payments/CommonDateFilter";

export default ({
    paymentItems, 
    paymentItemsLoading,
    currencyName,
    getPaymentList,
    accountId
}) => {

    const dateStart = new Date();
    dateStart.setDate(1);
    dateStart.setHours(0, 0, 0, 0);
    dateStart.setMonth(dateStart.getMonth() - 1);
    const dateEnd = new Date(new Date().setHours(23, 59, 59, 999));

    const [showTable, setShowTable] = useState(true);

    useEffect( () => {
        if (!accountId)
            return;

        const data = {target: {account_id: accountId}};
        data.filter = {
            start_date: getISOTime(dateStart),
            end_date: getISOTime(dateEnd)
        }
        getPaymentList(data);
    }, [accountId] );

    const handleChangeFilters = (value) => {
        if (!value)
            return;

        const start_date = new Date(value.start_end_date[0].setHours(0, 0, 0, 0));
        const end_date = new Date(value.start_end_date[1].setHours(23, 59, 59, 999));

        const filterData = {
            start_date: getISOTime(start_date),
            end_date: getISOTime(end_date),
            start_end_date: value.start_end_date
        }

        localStorage.setItem( LOCAL_STORAGE_PAYMENTS_LIST_FILTERS, JSON.stringify(filterData) );

        getPaymentList({
            target: {
                account_id: accountId
            },
            filter: filterData
        });
    };

    return (
        <>
            <PaymentsSectionHeader
                title="Payment list"
                show={showTable}
                callback={() => {
                    setShowTable(!showTable);
                }}
            />

            <Collapse isOpened={showTable}>
                <div>
                    <Spacer/>

                    <CommonDateFilter
                        filters={{start_end_date: [dateStart, dateEnd]}}
                        onChangeFilters={handleChangeFilters}
                    />
                    
                    <Spacer/>

                    <PaymentsListTable
                        height={160}
                        data={paymentItems}
                        loading={paymentItemsLoading}
                        {...{currencyName}}
                    />
                </div>
            </Collapse>
        </>
    )
};