import {defineMessages} from "react-intl";

export default defineMessages({
    passportScans: {id: "paymentDetails.passportScans", defaultMessage: "Passport scans", description: "ModalAddPayments: Passport scans"},
    certificateOfIncorporation: {id: "paymentDetails.certificateOfIncorporation", defaultMessage: "Certificate of Incorporation", description: "ModalAddPayments: Certificate of Incorporation"},

    beneficiaryName: {id: "paymentDetails.beneficiaryName", defaultMessage: "Beneficiary name", description: "ModalAddPayments: Beneficiary name"},
    beneficiaryLastName: {id: "paymentDetails.beneficiaryLastName", defaultMessage: "Beneficiary last name", description: "ModalAddPayments: Beneficiary last name"},
    accountIbanNumber: {id: "paymentDetails.accountIbanNumber", defaultMessage: "Account number/IBAN", description: "ModalAddPayments: Account number/IBAN"},
    intermediateBankDetails: {id: "paymentDetails.intermediateBankDetails", defaultMessage: "Intermediate bank details", description: "ModalAddPayments: Intermediate bank details"},
    beneficiaryCountry: {id: "paymentDetails.beneficiaryCountry", defaultMessage: "Beneficiary country", description: "ModalAddPayments: Beneficiary country"},
    beneficiaryRegion: {id: "paymentDetails.beneficiaryRegion", defaultMessage: "Beneficiary region", description: "ModalAddPayments: Beneficiary region"},
    beneficiaryCity: {id: "paymentDetails.beneficiaryCity", defaultMessage: "Beneficiary city", description: "ModalAddPayments: Beneficiary city"},
    beneficiaryAddress: {id: "paymentDetails.beneficiaryAddress", defaultMessage: "Beneficiary address", description: "ModalAddPayments: Beneficiary address"},
    beneficiaryPostalCode: {id: "paymentDetails.beneficiaryPostalCode", defaultMessage: "Beneficiary postal code", description: "ModalAddPayments: Beneficiary postal code"},

    bankName: {id: "paymentDetails.bankName", defaultMessage: "Bank name", description: "ModalAddPayments: Bank name"},
    bankCountry: {id: "paymentDetails.bankCountry", defaultMessage: "Bank country", description: "ModalAddPayments: Bank country"},
    bankRegion: {id: "paymentDetails.bankRegion", defaultMessage: "Bank region", description: "ModalAddPayments: Bank region"},
    bankCity: {id: "paymentDetails.bankCity", defaultMessage: "Bank city", description: "ModalAddPayments: Bank city"},
    bankAddress: {id: "paymentDetails.bankAddress", defaultMessage: "Bank address", description: "ModalAddPayments: Bank address"},
    bankPostalCode: {id: "paymentDetails.bankPostalCode", defaultMessage: "Bank postal code", description: "ModalAddPayments: Bank postal code"},

    paypalId: {id: "paymentDetails.paypalId", defaultMessage: "Paypal ID", description: "ModalAddPayments: Paypal ID"},
    walletNumber: {id: "paymentDetails.walletNumber", defaultMessage: "Wallet number", description: "ModalAddPayments: Wallet number"},
    message: {id: "paymentDetails.message", defaultMessage: "Message", description: "ModalAddPayments: Message"},

    saveChanges: {id: "paymentDetails.saveChanges", defaultMessage: "Save changes", description: 'ModalAddPayments: Save changes'},
    paymentMethod: {id: "paymentDetails.paymentMethod", defaultMessage: "Payment method", description: 'ModalAddPayments: Payment method'},
    chooseOrganizationType: {id: "paymentDetails.chooseOrganizationType", defaultMessage: "Choose organization type", description: 'ModalAddPayments: Choose organization type'},
    addNewPaymentDetails: {id: "paymentDetails.addNewPaymentDetails", defaultMessage: "Add new payment details", description: 'ModalAddPayments: Add new payment details'},
    modifyPaymentDetails: {id: "paymentDetails.modifyPaymentDetails", defaultMessage: "Modify payment details", description: 'ModalAddPayments: Modify payment details'},
    removePaymentDetail: {
        id: "paymentDetails.removePaymentDetail",
        defaultMessage: "Remove payment detail",
        description: "ModalRemovePaymentDetail: Remove payment detail"
    },
    alreadyHasBeenConfirmed: {id: "paymentDetails.alreadyHasBeenConfirmed", defaultMessage: "Already has been confirmed", description: 'ModalAddPayments: Already has been confirmed'},
    alreadyHasBeenRejected: {id: "paymentDetails.alreadyHasBeenRejected", defaultMessage: "Already has been rejected", description: 'ModalAddPayments: Already has been rejected'},
    alreadyHasBeenDeleted: {id: "paymentDetails.alreadyHasBeenDeleted", defaultMessage: "Already has been deleted", description: 'ModalAddPayments: Already has been deleted'},
})