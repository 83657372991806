import React from "react";
import {Redirect, Switch} from "react-router";
import AuthRoute from "../components/Router/AuthRoute";
import Payments from "../pages/Payments";
import Accounts from "../containers/Accounts";
import AccountView from "../pages/Account";
import NewAccount from "../containers/CreateAccount";
import AccessList from "../pages/AccessList/AccessList";
import LiveCalls from "../pages/LiveCalls";
import Prices from "../pages/Prices/Prices";
import RangesNumbers from "../pages/RangesAndNumbers/Ranges";
import CDRPage from "../pages/CDR/CDRPage";
import TrafficReportsPage from "../pages/TrafficReports/TrafficReportsPage";
import AuthNumbers from "../pages/AuthNumbers/AuthNumbers";
import Reload from "../components/Reload";
import AccessListException from "../pages/AccessListException/AccessListException";
import SIMTrafficRangesPage from "../pages/SIMTrafficRanges/SIMTrafficRangesPage";
import BlockedDestinationsPage from "../pages/BlockedDestinations/BlockedDestinationsPage";
import ActionLogs from "../pages/ActionLogs";
import GoogleOtpQuarantined from "../pages/GoogleOtpQuarantined";
import AmActivities from "../pages/AmActivities";
import AccountManagers from "../pages/AccountManagers";
import SettingsPage from "../pages/Settings/SettingsPage";
import PriceRangeQuarantineSetting from "../pages/PriceRangeQuarantineSetting";
import RegistrationRequests from '../pages/RegistrationRequests';
import {DEFAULT_AUTH_PATH_ADMIN} from "../const";
import GoogleOtpAllocation from '../pages/GoogleOtpAllocation';
import Ivr from "../pages/Ivr/Ivr";


export default ({auth}) => {
    return (
        <Switch>
            <AuthRoute exact path="/payments" component={Payments}/>
            <AuthRoute exact path="/accounts" component={Accounts}/>
            <AuthRoute exact path="/accounts/view/:id" component={AccountView}/>
            <AuthRoute exact path="/accounts/new" component={NewAccount}/>
            <AuthRoute exact path="/access-list" component={AccessList}/>
            <AuthRoute exact path="/live-calls" component={LiveCalls}/>
            <AuthRoute exact path="/prices" component={Prices}/>
            <AuthRoute exact path="/ranges-numbers" component={RangesNumbers}/>
            <AuthRoute exact path="/cdr" component={CDRPage}/>
            <AuthRoute exact path="/traffic-reports" component={TrafficReportsPage}/>
            <AuthRoute exact path="/auth-numbers" component={AuthNumbers}/>
            <AuthRoute exact path="/reload" component={Reload}/>
            <AuthRoute exact path="/exceptions-accesses" component={AccessListException}/>
            <AuthRoute exact path="/sim-traffic-ranges" component={SIMTrafficRangesPage}/>
            <AuthRoute exact path="/blocked-destinations" component={BlockedDestinationsPage}/>
            <AuthRoute exact path="/action-logs" component={ActionLogs}/>
            <AuthRoute exact path="/google-otp-quarantined-ranges" component={GoogleOtpQuarantined}/>
            <AuthRoute exact path="/am-activities" component={AmActivities}/>
            <AuthRoute exact path="/account-managers" component={AccountManagers}/>
            <AuthRoute exact path="/settings" component={SettingsPage}/>
            <AuthRoute exact path="/special-quarantine-terms" component={PriceRangeQuarantineSetting}/>
            <AuthRoute exact path="/registration-requests" component={RegistrationRequests}/>
            <AuthRoute exact path="/google-otp-allocation-subdestination" component={GoogleOtpAllocation}/>
            <AuthRoute exact path="/ivrs" component={Ivr}/>

            <Redirect to={DEFAULT_AUTH_PATH_ADMIN}/>
        </Switch>
    )
}