import React, {useState} from 'react';
import {Alert, CheckPicker, Form, Loader, Schema, SelectPicker} from 'rsuite';
import {ControlLabel, FormControl, CheckboxGroup, FormGroup, Checkbox, Placeholder} from 'rsuite/es';
import ServicePlans from '../../../../../components/Filters/ServicePlans';
import {ButtonPrimary} from "../../../../../components/base/BaseButton";

const { StringType } = Schema.Types;

const formModel = Schema.Model({
    // during: StringType().isRequired('Required')
});

// get During options
let getDuringOptions = () => {
    let duringOptions = [];
    for (let i = 0; i < 24; i++) {
        const start_time = new Date(0,0,0, i);
        const end_time = new Date(0,0,0, i + 1);

        duringOptions.push({
            dr_key: `${start_time.toLocaleTimeString('it-IT')}-23:59:59`,
            dr_name: `${start_time.toLocaleTimeString(['it-IT'], { hour: '2-digit', minute: '2-digit' })} - ${end_time.toLocaleTimeString(['it-IT'], { hour: '2-digit', minute: '2-digit' })} GMT`});
    }
    return duringOptions;
};


const AccessListFiltersSettings = ({settings, ...props}) => {

    let [isDaily, setIsDaily] = React.useState(false);
    let [loading, setLoading] = React.useState(false);

    React.useEffect( () => {

        console.log("settings", settings);

        if (settings && settings.daily) {
            setIsDaily(true);
        }
    }, [settings]);

    if (!settings) {
        return  <>
            <div style={{ height: 106, position: "relative" }}>
                <Loader backdrop content="loading..." vertical />
                <Placeholder.Paragraph/>
            </div>
        </>
    }

    let formRef = null;

    let getDefaultFilters = () => {
        let start_time = settings.start_time;
        let end_time = settings.end_time;
        let during = `${start_time}-23:59:59`;
        // let during = "";

        return (
            {
                ...settings,
                daily: settings.daily ? [true] : [],
                appearance: settings.appearance ? [true] : [],
                during: during,
                prices: settings.prices
            }
        )
    };

    const handleChange = (value) => {
        let isDaily = value.daily && value.daily.length ? true : false;
        setIsDaily(isDaily);
    };

    const handleApply = () => {
        // console.log("form value", formRef.getFormValue() );
        if ( !formRef.check() )
            return;

        setLoading(true);

        let formValues = formRef.getFormValue();

        //get time values
        let during = formValues["during"];
        let start_time = "00:00:00";
        let end_time = "23:59:59";
        if (during !== null) {
            [start_time, end_time] = during.split('-');
        }

        let newData = {
            daily: formValues.daily && formValues.daily.length ? true : false,
            appearance: formValues.appearance && formValues.appearance.length ? true : false,
            sp_key_list: formValues.sp_key_list ? formValues.sp_key_list : [],
            start_time: start_time,
            end_time: end_time,
            type: formValues.type ? formValues.type : 2
        };

        props.handleSubmit(props.accountId, newData).then( (r) => {
            setLoading(false);
            Alert.success('Saved');
        } );
    };

    return (
        <>
            <Form
                disabled={true}
                ref={ref => (formRef = ref)}
                onChange={(value) => handleChange(value)}
                formDefaultValue={getDefaultFilters()}
                layout="inline"
                model={formModel}
                className="mailingSettings"
            >
                <div style={{marginBottom: "10px"}}>
                    <FormGroup>
                        <FormControl name="appearance" accepter={CheckboxGroup}>
                            <Checkbox value={true}> Per appearance for filtered destinations</Checkbox>
                        </FormControl>
                    </FormGroup>
                </div>

                <FormGroup>
                    <FormControl name="daily" accepter={CheckboxGroup}>
                        <Checkbox value={true}> Daily</Checkbox>
                    </FormControl>
                </FormGroup>

                <FormGroup className={(isDaily ? '' : 'disabled')}>
                    <ControlLabel> for price</ControlLabel>
                    <ServicePlans accepter={CheckPicker} name="sp_key_list" disabled={!isDaily} filtered style={{marginLeft: '12px'}}/>
                </FormGroup>
                <FormGroup className={(isDaily ? '' : 'disabled')}>
                    <ControlLabel> During </ControlLabel>
                    <FormControl
                        name="during"
                        accepter={SelectPicker}
                        data={getDuringOptions()}
                        labelKey="dr_name"
                        valueKey="dr_key"
                        placeholder="Timing"
                        style={{width: '180px'}}
                        searchable={false}
                        disabled={!isDaily}
                        cleanable={false}
                    />
                </FormGroup>
                <FormGroup className={(isDaily ? '' : 'disabled')}>
                    <ControlLabel> Type </ControlLabel>
                    <FormControl
                        name="type"
                        accepter={SelectPicker}
                        data={[
                            {tp_key: 1, tp_name: "Full price"},
                            {tp_key: 2, tp_name: "Just for allocated"},
                            {tp_key: 4, tp_name: "Only filtered destinations"}
                        ]}
                        labelKey="tp_name"
                        valueKey="tp_key"
                        placeholder="Type"
                        style={{width: '180px'}}
                        searchable={false}
                        disabled={!isDaily}
                    />
                </FormGroup>
                <FormGroup>
                    <ButtonPrimary
                        style={{padding: '8px 34px', marginLeft: 12}}
                        onClick={handleApply}
                        disabled={loading}
                    >
                        Save
                    </ButtonPrimary>
                </FormGroup>

            </Form>
        </>
    )
}

export default AccessListFiltersSettings