import React from 'react';
import {Button, Icon, IconButton, ButtonToolbar as ButtonToolbar_} from 'rsuite';
import styled, {css} from 'styled-components';
import {APP_TYPE_CLIENT, SCREEN_MEDIA} from "../../const";

const {sm} = SCREEN_MEDIA;

const buttonSizes = {
    sm: '6px 12px',
    esm: '2px 14px',
};


/* Base Buttons */
const StyledBaseButton = styled(Button)`
    outline: none;
`;



/* Default Buttons*/

export const BaseButton = styled(StyledBaseButton).attrs(props => ({
    buttonStyle_: props.buttonStyle || 'default',
    isClient: props.theme.appType === APP_TYPE_CLIENT
}))`
    && {
        border-radius: 5px;
        color: #ffffff;
        background-color: var(--color-grey);
        box-shadow: ${props => props.withShadow 
            ? 'rgba(0, 0, 0, 0.16) 0px 4px 4px' 
            : 'none'
        }
        
        ${props => props.isClient && css`
            box-shadow: none;
            min-width: ${props => props.minWidth || props.minWidth === 0 || '160'}px;
        `}
        
        &:focus,
        &:hover,
        &:focus:hover,
        &:active:hover,
        &:active:focus, 
        &:active.focus {
            background-color: #f7f7fa;
            color: var(--color-text);
        }
        
        ${props => {
            switch (props.buttonStyle) {
                case "default":
                    return css`
                        color: var(--color-text);
                        background-color: #f7f7fa;
                        border-color: #f7f7fa;
                        
                        &:focus,
                        &:hover,
                        &:focus:hover,
                        &:active:hover,
                        &:active:focus, 
                        &:active.focus {
                            color: var(--color-text);
                            background-color: #e5e5ea;
                            border-color: #e5e5ea;
                        }
                    `;
                    
                case "secondary":
                    return css `
                        color: #ffffff;
                        background-color: var(--color-primary);
                        border-color: var(--color-primary);
                        
                        &:focus,
                        &:hover,
                        &:focus:hover,
                        &:active:hover,
                        &:active:focus, 
                        &:active.focus {
                            color: #ffffff;
                            background-color: var(--color-primary-hover);
                            border-color: var(--color-primary-hover);
                        }
                    `;
                    
                case "primary":
                    return css `
                        color: #ffffff;
                        background-color: var(--color-secondary);
                        border-color: var(--color-secondary);
                        
                        &:focus,
                        &:hover,
                        &:focus:hover,
                        &:active:hover,
                        &:active:focus, 
                        &:active.focus {
                            color: #ffffff;
                            background-color: var(--color-secondary-hover);
                            border-color: var(--color-secondary-hover);
                        }
                    `;

                case "error":
                    return css `
                        color: #ffffff;
                        background-color: var(--color-error);
                        border-color: var(--color-error);
                        
                        &:hover {
                            color: #575757;
                            background-color: #f7f7fa;
                            border-color: #f7f7fa;
                        }

                        &:focus,
                        &:focus:hover,
                        &:active:hover,
                        &:active:focus, 
                        &:active.focus {
                            color: var(--color-text);
                            background-color: #e5e5ea;
                            border-color: #e5e5ea;
                        }
                    `;
            }
        }}
        
        ${props => props.bordered && css`
            background-color: transparent;
            border-style: solid;
            border-width: 2px;
            color: #363434;
            
            &:focus,
            &:hover,
            &:focus:hover,
            &:active:hover,
            &:active:focus, 
            &:active.focus {
                color: #363434;
                background-color: #e5e5ea;
            }
        `}

        &.rs-btn-disabled,
        &:disabled {
            color: #ffffff !important;
            background: #939191 !important;
            opacity: 0.3 !important;
            
            ${props => !props.isClient && css`
                &:hover {
                    opacity: 0.45 !important;
                }
            `}

        }
        
        @media (max-width: ${sm.max}px) {
            padding: ${props => buttonSizes[props.size] || '12px 18px'};
        }
    }
`;

export const ButtonContent = styled(BaseButton).attrs((props) => ({
    size: props.theme.appType !== APP_TYPE_CLIENT ? "lg" : "md",
    withShadow: true
}))``;


export const ButtonDefault = styled(ButtonContent).attrs(() => ({
    buttonStyle: 'default'
}))``;

export const ButtonPrimary = styled(ButtonContent).attrs(() => ({
    buttonStyle: 'primary'
}))``;

export const ButtonSecondary = styled(ButtonContent).attrs(() => ({
    buttonStyle: 'secondary'
}))``;

export const ButtonSubtle = styled(ButtonContent)`
    &&&& {
        color: #8e8e93;
        background-color: transparent;
        
        &:hover {
            color: #575757;
            background-color: #f7f7fa;
        }
        
        &:focus,
        &:focus:hover,
        &:active:hover,
        &:active:focus, 
        &:active.focus {
            color: var(--color-text);
            background-color: #e5e5ea;
        }
    }
`;

export const ButtonClose = styled(ButtonContent).attrs(() => ({
    buttonStyle: 'error'
}))``;

export const BorderBtn = ({children, ...props}) => (
    <BaseButton {...props} bordered>{children}</BaseButton>
);

export const ButtonCancel = ({children, ...props}) => (
    <BaseButton {...props} buttonStyle={"default"}>{children}</BaseButton>
);



/* Row Buttons */

export const ButtonRow = styled(StyledBaseButton).attrs((props) => ({
    appearance: "primary",
    size: props.size || "xs",
}))`
    background-color: #20BA88;
    margin-right: 15px;
`;

export const ButtonRowClient = styled(ButtonRow)`
    &&& {
        background-color: var(--color-brand2) !important;
        font-size: 14px;
        
        &:hover {
            background-color: #5f2bb3 !important;
        }
    }
`;

export const ButtonRowRevoke = (props) => (
    <ButtonRow {...props}><b>R</b></ButtonRow>
);

export const ButtonRowAllocate = (props) => (
    <ButtonRow {...props}><b>A</b></ButtonRow>
);

export const ButtonRowEdit = (props) => (
    <ButtonRow
        {...props}
        as={IconButton}
        icon={<Icon icon="edit"/>}
    />
);

export const ButtonRowDownload = (props) => (
    <ButtonRow
        {...props}
        as={IconButton}
        icon={<Icon icon="cloud-download"/>}
    />
);

export const ButtonRowDelete = (props) => (
    <ButtonRow
        {...props}
        as={IconButton}
        icon={<Icon icon="trash"/>}
    />
);



/* Misc buttons */

const StyledButtonSideIcon = styled(StyledBaseButton)`
    && {
        background: transparent;
        color: #363434;
        padding: 3px 8px;
        border: 2px solid #20BA88;
        position: relative;
        padding-left: 35px;
        
        i {
            position: absolute;
            background: #20BA88;
            top: 0;
            left: 0;
            color: #fff;
            width: 26px;
            height: 30px;
            display: inline-block;
            line-height: 26px;
        }
    }
`;

export const ButtonSideIcon = ({children, icon, ...props}) => (
    <StyledButtonSideIcon {...props}>
        <Icon icon={icon}/>
        {children}
    </StyledButtonSideIcon>
);


export const ButtonList = styled(IconButton).attrs(() => ({
    size: "xs"
}))`
    &&& {
        background: #fff;
        border: 2px solid #20BA88;
        border-radius: 100%;
        margin-left: 5px;
        margin-right: 15px;
        color: #000;
        padding: 0;
        width: 24px;
        height: 24px;
        
        .rs-icon {
            line-height: 17px;
            font-size: 10px;
            height: 20px;
            width: 20px;
        }
    }
`;

export const ButtonListAdd = (props) => (
    <ButtonList
        {...props}
        icon={<Icon icon="plus" />}
    />
);

export const ButtonListRemove = (props) => (
    <ButtonList
        {...props}
        icon={<Icon icon="minus" />}
    />
);

export const ButtonWrapper = styled.div.attrs(() => ({
    className: 'button-wrapper'
}))`
    & {
        margin-bottom: -10px;
        margin-left: -5px;
        margin-right: -5px;
        
        & > .rs-btn,
        & > .rs-btn-toolbar {
            display: inline-block;
            margin-bottom: 10px;
            margin-left: 5px;
            margin-right: 5px;
            vertical-align: top;
        }
        
        ${props => props.justify === "end" && css`
            text-align: right;
            
            .app-client & {
                text-align: left;
            }
        `}
    }
`;

export const ButtonToolbar = styled(ButtonToolbar_).attrs(props => ({
    classPrefix: 'button-toolbar',
    size: (props.size || 10) / 2,
}))`
    & {
        margin-left: -${props => props.size + 'px'};
        margin-right: -${props => props.size + 'px'};
        
        & > a,
        & > .rs-btn {
            display: inline-block;
            margin-left: ${props => props.size + 'px'};
            margin-right: ${props => props.size + 'px'};
        }
    }

    ${props => props.alignEnd && css`
        && {
            text-align: right;

            .rtl & {
                text-align: left;
            }
        }
    `}

    .rs-table & {
        margin-top: -3px;
    }
`;


export const ButtonFixedBottom = styled(ButtonPrimary)`
    && {
        border-radius: 0;
        bottom: 0;
        left: 0;
        padding-bottom: 17px;
        padding-top: 17px;
        position: fixed;
        width: 100%;
        z-index: 10;
    }
`;