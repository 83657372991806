import React, {useEffect, useState} from 'react';
import {useWindowWidth} from '../../../hooks';
import {Form, FormControl, Input, InputNumber, Schema} from 'rsuite';
import SDE from '../../../components/client/SDE';
import {useIntl} from 'react-intl';
import {Spacer} from '../../../components/base/Spacer';
import CustomField from '../../../components/client/Form/CustomField/CustomField';
import {ALLOCATION_GOOGLE_OTP_DIALER_API} from '../../../const/apiMethods';
import TrunksList from '../../../components/TrunksList/TrunksList';
import * as S from './styled';
import FormWhisper from '../NumbersAllocateModal/FormWhisper';
import m from "../../../definedMessages";
import {BaseInputField, BaseInputNumberField} from "../../../components/base/BaseForm";

const {StringType, NumberType} = Schema.Types;

const MIN_CHARACTERS = 7;
const MAX_CHARACTERS = 15;


const MIN_NUMBER = 100;


export default ({
    show,
    onClose,
    onAllocateGoogleOTP,
    maxWidth = 600,
    formValue,
    onChangeFormValue,
    trunks,
    loading,
    allocationLimit,
    activeField = "template",
    rtl,

    ...props
}) => {

    const resizedWidth = useWindowWidth();
    const [requestedField, setRequestedField] = useState(activeField);
    const [removedField, setRemovedField] = useState(activeField === 'template' ? 'sde_key' : 'template');


    useEffect( () => {
        if (activeField && show) {
            setRequestedField(activeField);
            setRemovedField(activeField === 'template' ? 'sde_key' : 'template');
        }
    }, [show, activeField] );


    const MAX_NUMBER = allocationLimit && allocationLimit.google_otp
        ? allocationLimit.google_otp.once
        : allocationLimit && allocationLimit.other && allocationLimit.other.once || 1000;


    let formRef;
    const {formatMessage} = useIntl();
    const trunkSelectData = trunks.filter(trunk => trunk.sp_otp_type === 1 && !trunk.closed);
    const errorPlacement = rtl ? 'bottomEnd' : 'bottomStart';

    const clearForm = () => {
        onChangeFormValue({
            numbers: 100
        });
    };

    const formModel = Schema.Model({
        template: StringType()
            .pattern(/^[1-9](?:\d*[xX]*(\[((\d)|(\d-\d))((,\d-\d)?(,\d)?)*\])?)*$/, formatMessage(m.pleaseEnterValidNumbers))
            .addRule((value) => {
                let replacedValue = value.replace(/\[[\d\,\-]+\]/g, 'X');
                console.log("replacedValue2", replacedValue);
                if (replacedValue.length > MAX_CHARACTERS || replacedValue.length < MIN_CHARACTERS) {
                    return false;
                }
            }, formatMessage(m.templateShouldBeSize, {minNumber: MIN_CHARACTERS, maxNumber: MAX_CHARACTERS}))
            .isRequired(formatMessage(m.thisFieldIsRequired)),

        sde_key: NumberType()
            .isRequired(formatMessage(m.thisFieldIsRequired)),
        

        trunk_id: StringType()
            .isRequired(formatMessage(m.thisFieldIsRequired)),

        numbers: NumberType(formatMessage(m.pleaseEnterValidNumber))
            .min(MIN_NUMBER, formatMessage(m.tooFewNumbers))
            .max(MAX_NUMBER, formatMessage(m.tooMuchNumbers))
            .addRule((value) => {
                if (value % 100) {
                    return false;
                }
                return true;
            }, formatMessage(m.theNumberMustBeAMultipleOf, {value: 100}))
            .isRequired(formatMessage(m.thisFieldIsRequired))
    });


    const onSetRequestedField = (activeField) => {
        const removedField = activeField === 'template' ? 'sde_key' : 'template';
        setRequestedField(activeField);
        setRemovedField(removedField);

        const newFormValue = {...formValue};
        delete newFormValue[removedField];
        formRef.cleanErrorForFiled(removedField);
        onChangeFormValue(newFormValue);
    };


    const onSubmit = async () => {

        // custom fields checking
        const fieldsToCheck = ['numbers', 'trunk_id'];
        const fieldsValidity = [];
        fieldsToCheck.push(requestedField);
        formRef.cleanErrorForFiled(removedField);
        for (const fieldName of fieldsToCheck) {
            fieldsValidity.push( formRef.checkForField(fieldName) );
        }
        
        if (fieldsValidity.includes(false))
            return;

        const formValue = formRef.getFormValue();
        const data = {...formValue};

        const response = await onAllocateGoogleOTP(data);

        if (response === undefined) {
            return;
        }
        
        if (response.status === 0) {
            clearForm();
            return;
        }

        props.setReasonModalProps({
            reasonCode: response.reason_code,
            method: ALLOCATION_GOOGLE_OTP_DIALER_API,
            trunk: trunks.find(trunk => trunk.id === data.trunk_id),
            template: formValue.template,
            sde_key: formValue.sde_key,
            numbersAmount: formValue.numbers,
            reasonHash: response.reason_hash,
        });
    };


    const templateField = (
        <S.Field
            inline={resizedWidth > 767}
            as={CustomField}
            label={formatMessage(m.putTemplatePrefixForDestination)}
            accepter={BaseInputField}
            errorPlacement={errorPlacement}
            name='template'
            placeholder={'12356789XXXXXX'}
        />
    );


    const sdeField = (
        <S.Field
            inline={resizedWidth > 767}
            as={SDE}
            label={formatMessage(m.orChooseItFromList)}
            errorPlacement={errorPlacement}
            name="sde_key"
            placeholder={formatMessage(m.subdestination)}
            // placement={resizedWidth > maxWidth ? "bottomStart" : "auto"}
        />
    );

    return (

        <S.FormModal
            show={show}
            title={formatMessage(m.allocateNumbersForGoogleOTP)}
            width={maxWidth}
            showFooter={true}
            confirmButtonText={formatMessage(m.allocate)}
            loading={loading}
            focusTarget="close"
            onConfirm={onSubmit}
            onClose={() => {
                clearForm();
                onClose(false);
            }}
        >

            <Form
                formValue={formValue}
                ref={ref => formRef = ref}
                model={formModel}
                onChange={formValue => {
                    onChangeFormValue(formValue);
                }}
            >

                <S.FormColumns>
                    <S.FormColumn width="100%">
                        <FormControl
                            accepter={TrunksList}
                            data={trunkSelectData}
                            name="trunk_id"
                            cleanable={false}
                        />
                    </S.FormColumn>
                </S.FormColumns>

                <Spacer size={30}/>

                <S.FormColumns>
                    <S.FormColumn width="100%">
                        {requestedField === 'template'
                            ? templateField

                            : <FormWhisper>
                                <S.Fieldset
                                    onClick={() => {
                                        onSetRequestedField('template');
                                    }}
                                >
                                    {templateField}
                                </S.Fieldset>
                            </FormWhisper>
                        }
                    </S.FormColumn>
                </S.FormColumns>

                <Spacer size={30}/>

                <S.FormColumns>
                    <S.FormColumn width="100%">
                        {requestedField === 'sde_key'
                            ? sdeField

                            : <FormWhisper>
                                <S.Fieldset
                                    onClick={() => {
                                        onSetRequestedField('sde_key');
                                    }}
                                >
                                    {sdeField}
                                </S.Fieldset>
                            </FormWhisper>
                        }
                    </S.FormColumn>
                </S.FormColumns>

                <Spacer size={30}/>

                <S.FormColumns>
                    <S.FormColumn className="formColumn">
                        <S.NumbersField 
                            label={formatMessage(m.numbers)}
                            inline={resizedWidth > 767}
                            step={100}
                            accepter={BaseInputNumberField}
                            name='numbers'
                            errorPlacement={errorPlacement}
                        />
                    </S.FormColumn>
                </S.FormColumns>

            </Form>

        </S.FormModal>
    );

};