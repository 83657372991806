import styled, { css } from "styled-components";
import Modal from "../../../components/Modal";
import { FormGroup, ControlLabel, FormControl } from "rsuite";
import TextareaAutosize from 'react-textarea-autosize';

export const FormModal = styled(Modal)`
    &&& {
        margin-bottom: 40px !important;
    }

    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;

export const FileUploader = styled(FormControl).attrs(props => ({
    listType: "picture-text",
    autoUpload: false,
    multiple: true,
    accept: "image/jpeg,image/gif,image/png,image/x-eps,application/pdf,application/zip,application/x-zip,application/x-zip-compressed",
}))`
    &&& {
        .rs-uploader-file-item-size {
            display: none;
        }
    }

    .rtl &&& {
        
        .rs-uploader-file-item {
            padding-left: 30px;
            padding-right: 50px;
        }

        .rs-uploader-file-item-preview {
            left: auto;
            right: 0;
        }

        .rs-uploader-file-item-progress {
            padding-left: 0;
            padding-right: 50px;
        }

        .rs-uploader-file-item-btn-remove {
            left: 12px;
            right: auto;
        }
    }
`;

export const Group = styled(FormGroup)`
    margin-bottom: 15px;
    
    @media (min-width: 768px) {
        align-items: center;
        display: flex;
    }
`;

export const Label = styled(ControlLabel)`
    flex-shrink: 0;
    font-weight: 600;
    padding-right: 20px;
    text-align: left;
    width: auto;

    .rtl & {
        padding-left: 20px;
        padding-right: 0;
        text-align: right;
    }
    
    && {
        
        @media (min-width: 768px) {
            margin-bottom: 0;
        }
    }
`;

export const Field = styled(FormControl)`
    &&& {
        min-width: 100%;
        width: 100%;
    } 
`;
export const FieldTextAreaAutoResize = styled(TextareaAutosize)`
    &&& {
        min-width: 100%;
        width: 100%;
        overflow: hidden;
        resize: none;
    } 
`;

export const LabelWrapper = styled.div`
    flex-shrink: 0;
    width: 160px;
    
    ${props => props.vAlign &&
        css`
            align-self: ${props.vAlign};
        `
    }

    ${props => props.top &&
        css`
            margin-top: ${props.top}px;
        `
    }
`;

export const FormColumns = styled.div`
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
`;

export const FormColumn = styled.div`
    flex: 0 0 auto;
    padding: 0 20px;
    position: relative;
    width: 100%;

    ${props => 
        props.flexGrow && css`
            flex-grow: ${props.flexGrow};
        `
    }

    @media (min-width: 600px) {
        flex-grow: 1;
        width: 1px;
        
        &&& {
            .rs-form-control-wrapper {
                max-width: 450px;
                width: 100%;
            }
        }
    }
`;

export const FlexBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FlexChild = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;