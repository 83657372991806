import React, {useEffect, useState} from "react";
import GoogleOtpQuarantinedFilter from "./GoogleOtpQuarantinedFilter";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import GoogleOtpQuarantinedTable from "./GoogleOtpQuarantinedTable";
import {FlexboxGrid} from "rsuite";
import ModalRemoveNumbers from "./ModalRemoveNumbers";
import {ButtonPrimary} from "../../components/base/BaseButton";

export default ({
    subDestinationList,
    data,
    count,
    loading,
    page,
    perPage,
    getGoogleOtpQuarantinedList,
    removeGoogleOtpQuarantinedNumbers,
}) => {

    const [showRemoveNumbersModal, setShowRemoveNumbersModal] = useState(false);
    const [removeNumbersLoading, setRemoveNumbersLoading] = useState(false);
    const [filter, onChangeFilter] = useState({});
    const [selected, setSelected] = useState({all: false, list: []});

    useEffect(() => {
        getGoogleOtpQuarantinedList(filter, 1, perPage);
    }, [filter]);

    const getItems = (page, perPage) => {
        getGoogleOtpQuarantinedList(filter, page, perPage);
    };

    const removeNumbers = () => {
        setRemoveNumbersLoading(true);
        removeGoogleOtpQuarantinedNumbers(selected, filter)
            .then((response) => {
                if (response) {
                    getGoogleOtpQuarantinedList(filter, page, perPage);
                }
                setRemoveNumbersLoading(false);
                setShowRemoveNumbersModal(false);
            })
    };

    return (
        <>
            <PanelLayout>
                <PageHeader title="Google OTP quarantined ranges"/>
                <FlexboxGrid>
                    <FlexboxGrid.Item style={{marginRight: '30px'}}>
                        <GoogleOtpQuarantinedFilter
                            defaultFilter={filter}
                            {...{
                                subDestinationList,
                                onChangeFilter
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item style={{marginLeft: 'auto'}}>
                        <ButtonPrimary
                            disabled={!selected.list.length && !selected.all}
                            onClick={() => setShowRemoveNumbersModal(true)}
                        >
                            Remove selected numbers from quarantine
                        </ButtonPrimary>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <GoogleOtpQuarantinedTable
                    {...{
                        data,
                        count,
                        loading,
                        page,
                        perPage,
                        getItems,
                        setSelected,
                        filter
                    }}
                />

                <ModalRemoveNumbers
                    onClose={() => setShowRemoveNumbersModal(false)}
                    title={"Remove selected numbers from quarantine"}
                    show={showRemoveNumbersModal}
                    onSuccess={removeNumbers}
                    loading={removeNumbersLoading}
                />
            </PanelLayout>
        </>
    )
};