import {connect} from "react-redux";
import Payments from "./Payments";
import {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    cancelPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings, getHawalaPaymentRequestList,
} from "../../actions/payments";
import {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
} from "../../actions/paymentDetails";
import {getAccount} from "../../actions";
import {getDropdownAccounts} from "../../actions/dropdown_accounts";
import {getAccountManagersList} from '../../actions/account_managers'


const mapStateToProps = ({account_managers, payments, paymentDetails, references, dropdown_accounts}) => ({
    paymentItems: payments.paymentItems,
    paymentItemsLoading: payments.paymentItemsLoading,

    paymentRequestItems: payments.paymentRequestItems,
    paymentRequestLoading: payments.paymentRequestLoading,
    
    paymentTrafficReportsItems: payments.paymentTrafficReportsItems,
    paymentTrafficReportsLoading: payments.paymentTrafficReportsLoading,

    hawalaItems: payments.hawalaItems,
    hawalaLoading: payments.hawalaLoading,
    
    paymentDetailsItems: paymentDetails.items,
    paymentDetailsLoadinig: paymentDetails.loading,
    
    accounts: dropdown_accounts.items,
    account_manager_list: account_managers.list,
    globalSettings: references.global_setting,
    organizationTypeList: references.organization_type_list,
    countryList: references.country_list,
    paymentMethodList: references.payment_method_list,
    paymentTermsList: references.payment_terms_list
});

export default connect(mapStateToProps, {
    getPaymentList,
    getPaymentRequestList,
    getTrafficReportsList,
    requestPayment,
    cancelPaymentRequest,
    approvePaymentRequest,
    modifyPaymentSettings,
    getHawalaPaymentRequestList,
    
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails,

    getAccounts: getDropdownAccounts,
    getAccount,
    getAccountManagersList
})(Payments);