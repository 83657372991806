import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Loader as RSuiteLoader} from 'rsuite';
import css from './Loader.module.css'

const Loader = (props) => {
    if (!props.show) {
        return null;
    }

    const component = (
        <div className={css.loaderWrapper}>
            <RSuiteLoader size="md"
                          center
                          backdrop
            />
        </div>
    );

    if (props.isLocal) {
        return component;
    }

    return ReactDOM.createPortal(
        component,
        document.getElementById('modal-root')
    );
};

Loader.propTypes = {
    show: PropTypes.bool,
    isLocal: PropTypes.bool,
};

export default Loader;