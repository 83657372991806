import {CDR_GET_LIST_SUCCESS, CDR_SET_LOADING} from "../actions/actionTypes";
import {CDR_PER_PAGE} from "../const/localStorageKeys";
import {DEFAULT_PER_PAGE_BIG, DEFAULT_PER_PAGE_MEDIUM} from "../const";

const initialState = {
    loading: false,
    list: [],
    count: 0,
    perPage: +localStorage.getItem(CDR_PER_PAGE) || DEFAULT_PER_PAGE_BIG,
    page: 1
};


const handlers = {
    [CDR_SET_LOADING]: (state, {payload}) =>({...state, loading: payload}),
    [CDR_GET_LIST_SUCCESS]: (state, {payload}) =>({...state, ...payload, loading: false}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
