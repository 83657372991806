import React, {useContext} from "react";
import CustomizableTable from "../../components/client/CustomizableTable/CustomizableTable";
import {renderColumn} from "../../utils/helpers";
import {useIntl} from "react-intl";
import {Table, Icon} from 'rsuite';
import {ButtonRowClient, ButtonToolbar} from "../../components/base/BaseButton";
import m from "../../definedMessages";
import {RtlContext} from "../../App";

const {Column, HeaderCell, Cell} = Table;

const PaymentDetailsTable = ({
    data, 
    loading, 
    loadingEditButtonsSet,
    handleRemovePaymentDetails,
    handleEditPaymentDetails,
    ...props
}) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);

    const statuses = [
        formatMessage(m.pending),
        formatMessage(m.confirmed),
        formatMessage(m.declined),
    ];

    const columns = [
        {
            id: 'details',
            label: formatMessage(m.details),
            dataKey: "payment_detail_info",
            flexGrow: 5,
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'created',
            label: formatMessage(m.created),
            value: ({start_date, customer_name, customer_last_name}) => {
                const name = customer_name
                    ? customer_name + (customer_last_name !== null ? (' ' + customer_last_name) : '' )
                    : null;
                return `${start_date}${name ? ' ' + formatMessage(m.byName, {name: name}) : ''}`;
            },
            flexGrow: 2,
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'status',
            label: formatMessage(m.status),
            value: ({status}) => {
                return statuses[status]
            },
            flexGrow: 2,
            minWidth: 200,
            align: 'center'
        }
    ];
    const confirmedColumn = (
        <Column align="center" key="confirm__column">
            <HeaderCell>{formatMessage(m.confirmed)}</HeaderCell>
            <Cell dataKey="confirmed" className="confirm__cell">
                {rowData => {
                    return (
                        <div>
                            {rowData['confirmed'] && <Icon icon="check-square"/>}
                            {!rowData['confirmed'] && <Icon icon="collasped"/>}
                        </div>
                    )
                }}
            </Cell>
        </Column>
    );
    const tableActions = (
        <Column key="table__actions">
            <HeaderCell> </HeaderCell>
            <Cell>
                {rowData => {

                    return (
                        <ButtonToolbar>

                            <ButtonRowClient
                                disabled={loadingEditButtonsSet.size && !loadingEditButtonsSet.has(rowData.id)}
                                loading={loadingEditButtonsSet.has(rowData.id)}
                                onClick={() => handleEditPaymentDetails(rowData)}
                            >
                                <Icon icon="edit2"/>
                            </ButtonRowClient>
                            
                            <ButtonRowClient
                                onClick={() => handleRemovePaymentDetails(rowData)}
                            >
                                <Icon icon="trash2"/>
                            </ButtonRowClient>
                            
                        </ButtonToolbar>
                    );
                }}
            </Cell>
        </Column>
    );

    const renderedColumns = columns.map(renderColumn);
    // renderedColumns.push(confirmedColumn);
    renderedColumns.push(tableActions);

    return (
        <CustomizableTable
            data={data}
            loading={loading}
            {...{
                rtl,
                renderedColumns
            }}
        />
    )
};

export default PaymentDetailsTable;