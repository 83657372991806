/* eslint-disable default-case */
import React from 'react';
import TableServerSort from '../../../../components/Table/TableServerSort';
import Filters from './NumbersFilters';
import {Alert, Radio, RadioGroup} from 'rsuite';
import {FooterActions} from '../styled';
import AllocatedNumbersModal from './AllocateNumbersModal';
import NumbersActions from './NumbersActions';
import ChangeRateModal from './AllocateNumbersModal/ChangeRateModal';
import RevokeNumbersModal from '../RevokeNumbersModal';
import MoveNumbersModal from 'pages/Account/Trunks/MoveNumbersModal';
import {
    canIMoveNumbersToAnotherTrunk,
    convertDataInColumn,
    getRangeByNumber, makeNumbersFromPrefixesToCsv,
    makeRangeFromPrefixesToCsv,
    saveFileBlob
} from 'utils';
import {DEFAULT_EQUAL_SIGN} from '../../../../const'
import {getFileResponse} from 'api/loginRoutes';
import {getBlobContent} from "../../../../utils";
import NumbersDownloadDropdown from "./NumbersDownloadDropdown";
import * as S from "./styled";
import {api} from "../../../../api/loginRoutes";


const NUMBER_KEY = "trn_key";
const RANGE_KEY = "pr_key";
const SUBDEST_KEY = "sde_key";

const columns = [
    {
        label: 'Number',
        dataKey: 'number',
        sortable: true,
        value: (({number, numbers_count}) => {
            return getRangeByNumber(number, numbers_count)
        }),
        displayWhen: ['trunk_number'],
        minWidth: 196,
        minWidthMobile: 225,
    },
    {
        label: 'Range', 
        dataKey: 'name', 
        sortable: true, 
        displayWhen: ['trunk_number', 'price_range'],
        minWidth: 196,
        minWidthMobile: 300,
    },
    {
        label: 'User',
        dataKey: 'user_name',
        displayWhen: ['trunk_number'],
        minWidth: 196,
        minWidthMobile: 300,
    },
    {
        label: 'Rate', 
        dataKey: 'rate', 
        sortable: true, 
        displayWhen: ['trunk_number', 'price_range', 'subdestination'], 
        width: 100, 
        align: 'right'
    },
    {
        label: 'Created Date',
        dataKey: 'start_date',
        displayWhen: ['trunk_number'],
        formatData: 'date',
        sortable: true,
        align: 'right',
        minWidth: 196,
    },
    {
        label: 'Revoked Date',
        dataKey: 'end_date',
        displayWhen: ['trunk_number'],
        formatData: 'date',
        sortable: true,
        align: 'right',
        minWidth: 196,
    },
    {
        label: 'Allocated numbers', 
        dataKey: 'trunk_numbers', 
        sortable: true, 
        displayWhen: ['price_range'], 
        align: 'right',
        minWidth: 196,
    },
    {
        label: 'Subdestination', 
        dataKey: 'subdestination_name', 
        sortable: true, 
        displayWhen: ['subdestination'],
        minWidth: 196,
        minWidthMobile: 300,
    },
    {
        label: 'Allocated numbers and ranges', 
        dataKey: 'allocated_numbers_and_ranges', 
        displayWhen: ['subdestination'], 
        align: 'right',
        minWidth: 196,
    },
];


export default class extends React.Component {

    filters = null;
    tableRef = null;
    _selectedList = new Set();

    state = {
        footerActionsDisabled: false,
        footerActionsLoading: false,


        selected: {all: false, list: []},
        filterState: null,
        currentDisplay: 'trunk_number',
        showChangeRateModal: false,
        currentSubDestinationsList: [],
        showRevokeModal: false,
        showMoveModal: false,
        showAllocatedNumbersModal: false,
        searchLoading: false,
        sort: {},

        isDownloadInProgress: false,
        selectedItems: new Map(),
        resizedWidth: window.innerWidth

    };

    componentDidMount() {
        const {currentDisplay, filterState} = this.state;
        const {allocatedNumbersPerPage, currentRangeNumber} = this.props;

        const filters = {
            start_date_inequation: 0,
            ...(currentRangeNumber ? {str: currentRangeNumber} : {}),
            ...(filterState || this.filters.getValues())
        };

        this.props.getNumbers(currentDisplay, filters, 1, allocatedNumbersPerPage, {});

        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate({trunk_id}) {
        if (trunk_id !== this.props.trunk_id) {
            const {currentDisplay, filterState} = this.state;
            const {allocatedNumbersPerPage, currentRangeNumber} = this.props;

            const filters = {
                start_date_inequation: 0,
                ...(currentRangeNumber ? {str: currentRangeNumber} : {}),
                ...(filterState || this.filters.getValues())
            };
            this.props.getNumbers(currentDisplay, filters, 1, allocatedNumbersPerPage);
            this.tableRef.clear();
            this._selectedList.clear();
            this.clearSelectedNumbers();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    getFilters = () => ({...this.filters.getValues()});

    getSelectedList = () => ({
        ...this.state.selected, 
        countAll: this.props.allocatedNumbersCount
    });

    getNumbers = (page, per_page, sort) => {
        const {currentDisplay, filterState} = this.state;

        this.props.getNumbers(currentDisplay, filterState || this.filters.getValues(), page, per_page, sort);
    };

    setFocusOnInput = (input) => {
        if (input.ref.current._reactInternalFiber.firstEffect.stateNode.focus)
            input.ref.current._reactInternalFiber.firstEffect.stateNode.focus();
    };

    onChangeFilters = (filters) => {
        const {currentDisplay, sort, searchLoading} = this.state;

        if (!searchLoading) this.setState({searchLoading: true});
        const filterState = {...filters, start_date_inequation: filters.start_date_inequation ? Number.parseInt(filters.start_date_inequation) : DEFAULT_EQUAL_SIGN};
        Object.keys(filterState).forEach(key => filterState[key] === undefined  || filterState[key] === null ? delete filterState[key] : {});

        const intervalTimer = () => {
            setTimeout(() => {
                this.setState({searchLoading: false, filterState: filterState});
                this.setFocusOnInput(this.filters);
            }, 1000);
        };
        intervalTimer();
        this.props.getNumbers(currentDisplay, filterState, 1, this.props.allocatedNumbersPerPage, sort);
        // ref>current>_reactInternalFiber>firstEffect>stateNode
    };

    onChangeGroup = (currentDisplay) => {
        const {filterState} = this.state;

        this.tableRef.clear();
        this._selectedList.clear();
        this.clearSelectedNumbers();
        this.setState({currentDisplay});
        this.props.getNumbers(currentDisplay, filterState || this.filters.getValues(), 1, this.props.allocatedNumbersPerPage, this.state.sort);
    };

    getInitialValues = () => {
        const {currentDisplay, selected, filterState} = this.state;
        let list = null;

        if (selected.all) {
            return {filter: filterState || this.filters.getValues(), filterAll: true};
        }

        switch (currentDisplay) {
            case 'trunk_number':
                list = {trn_key_list: selected.list};
                break;
            case 'price_range':
                list = {pr_key_list: selected.list};
                break;
            case 'subdestination':
                list = {sde_key_list: selected.list};
                break;

        }
        return {...list};
    };

    getCurrentEntityName = () => {
        return {
            'trunk_number': 'number',
            'price_range': 'range',
            'subdestination': 'subdestination'
        }[this.state.currentDisplay];
    };

    updateItems = () => {
        const {currentDisplay, sort, filterState} = this.state;
        const {allocatedNumbersPerPage: per_page, allocatedNumbersPage: page, getNumbers} = this.props;
        this.tableRef && this.tableRef.clear();
        this._selectedList.clear();
        this.clearSelectedNumbers();
        getNumbers(currentDisplay, filterState || this.filters.getValues(), page, per_page, sort);
    };

    proxyFilters = (filters) => {
        return {
            ...filters,
            actual: !filters.actual
        };

    };

    getRowKey = () => {
        const {currentDisplay} = this.state;
        switch (currentDisplay) {
            case 'trunk_number':
                return NUMBER_KEY;
            case 'price_range':
                return RANGE_KEY;
            case 'subdestination':
                return SUBDEST_KEY;
        }
    };

    updateSelectedList = (selected) => {
        const {allocatedNumbers} = this.props;
        const rowKey = this.getRowKey();

        for (let item of this._selectedList) {
            if (!selected.list.find(key => key === item[rowKey]))
                this._selectedList.delete(item);
        }

        selected.list.map(key => {
            const value = allocatedNumbers.find(item => item[rowKey] === key);
            if (value)
                this._selectedList.add(value);
        });

        if (selected.list.length === 0) {
            this.clearSelectedNumbers();
        }

        this.setState({selected});
    };

    getDataForChangeRate = () => {
        for (let item of this._selectedList) {
            return {
                sde_key: item.sde_key,
                sde_name: item.subdestination_name,
                account_name: this.props.account_name,
                sp_key: this.props.sp_key
            };
        }
    };

    onDownloadNumbersCsv = async () => {
        const {acc_key: account_id, trunk_id} = this.props;
        const {filterState} = this.state;

        this.setState({isDownloadInProgress: true});


        const filter = filterState || this.filters.getValues();

        const result = await getFileResponse('trunk_number__get_list', {
            target: {trunk_id}, filter
        });

        if (result) {
            getBlobContent(result).then((text) => {
                const csvContent = this.props.sp_auth ? makeRangeFromPrefixesToCsv(text) : text;
                const csvDataList = csvContent.split('\n').map((data) => {
                    const line = data.split(';');
                    return [line[0], ...line.slice(2)].join(';')
                });
                const csvData = csvDataList.join('\n');
                saveFileBlob(csvData, 'allocated_numbers.csv');
            })
        }
        
        this.setState({isDownloadInProgress: false});
    };

    onDownloadOnlyNumbersCsv = async () => {
        const {trunk_id} = this.props;
        const {filterState} = this.state;

        this.setState({isDownloadInProgress: true});

        const filter = filterState || this.filters.getValues();

        const result = await getFileResponse('trunk_number__get_list', {
            target: {trunk_id}, filter
        });

        if (result) {
            getBlobContent(result).then((text) => {
                const csvContent = this.props.sp_auth ? makeNumbersFromPrefixesToCsv(text) : text;
                const csvDataList = csvContent.split('\n').slice(1).map((data) => {
                    const line = data.split(';');
                    return line[0]
                });
                const csvData = csvDataList.join('\n');
                saveFileBlob(csvData, 'allocated_numbers.txt');
            })
        }

        this.setState({isDownloadInProgress: false});
    };

    /* section: Count Selected Numbers */

    keepSelectedItems = (data, checked) => {
        const {selectedItems} = this.state;
        const updatedCollection = new Map(selectedItems);

        const rowKey = this.getRowKey();

        for (const item of data) {
            if (checked) {
                updatedCollection.set(item[rowKey], item);
            } else {
                updatedCollection.delete(item[rowKey]);
            }
        }

        this.setState({selectedItems: updatedCollection});
    };

    getSelectedNumbersCount = () => {
        const {selectedItems, selected: {list, all}} = this.state;

        if (all)
            return this.props.allocatedNumbersTotalCount;

        const rowKey = this.getRowKey();
        let key = "id";
        switch (rowKey) {
            case NUMBER_KEY:
                key = "numbers_count";
                break;
            case RANGE_KEY:
                key = "price_range_trunk_numbers";
                break;
            case SUBDEST_KEY:
                key = "subdestination_trunk_numbers";
                break;
        }

        let count = 0;
        for (const item of selectedItems) {
            count += item[1][key];
        }

        return count;
    };

    clearSelectedNumbers = () => {
        this.setState({
            selectedItems: new Map()
        });
    };

    handleOpenChangeRateModal = () => {
        this.setState({
            footerActionsDisabled: true,
            footerActionsLoading: true,
        });

        this.getSubDestinations()
            .then(response => {
                if (!response || !response.subdestination_list)
                    return;

                const isUniqueDest = response.subdestination_list
                    .map(item => item.de_key)
                    .every( (val, index, arr) => val === arr[0] );

                if (!isUniqueDest) {
                    Alert.error(`You cannot change the rate for different destinations at one time`);
                    return;
                }

                this.setState({
                    currentSubDestinationsList: response.subdestination_list,
                    showChangeRateModal: true,
                });
            })
            .finally(() => {
                this.setState({
                    footerActionsDisabled: false,
                    footerActionsLoading: false,
                });
            })
    };

    getSubDestinations = async () => {
        const {trunk_id} = this.props;
        const initialData = this.getInitialValues();
        const target = {trunk_id};

        return await api('trunk_number__filter:get_subdestination_list', {target, ...initialData});
    };

    render() {
        const {
            trunk_id, acc_key, account, spOtpType, showAllocatedNumbersModal, closeAllocatedNumbersModal,
            allocatedNumbers, allocatedNumbersLoading, allocatedNumbersCount, allocatedNumbersPage,
            allocatedNumbersPerPage, trunksLoading, getTrunkNumberCsvById, currentRangeNumber
        } = this.props;

        const {selected, currentDisplay, showChangeRateModal, showRevokeModal, showMoveModal, searchLoading,
            filterState, isDownloadInProgress, resizedWidth, currentSubDestinationsList, footerActionsDisabled,
            footerActionsLoading
        } = this.state;

        const isMobile = resizedWidth < 1200;
        const loading = trunksLoading || allocatedNumbersLoading;
        const currentTrunk = this.props.trunks.find((trunk) => trunk.id === trunk_id);

        const isselected = this.filters && this.filters.formValue ? !this.filters.formValue.actual : true;

        const formattedData = allocatedNumbers.map(item => ({
            ...item,
            start_date: item.start_date ? item.start_date.replace(" ", "T") : "",
            end_date: item.end_date ? item.end_date.replace(" ", "T") : "",
        }));
        
        const data = convertDataInColumn(formattedData, "end_date", (value, column, key) => {
            return {
                ...column, 
                [key]: value 
                    ? new Date( new Date( new Date(value).setSeconds(new Date(value).getSeconds() - 1) ) ) 
                    : null
                }
        });

        const disabledActions = [];
        if (!this.props.account_name) {
            disabledActions.push('Revoke numbers');
        }

        return (
            <div>
                <Filters
                    ref={ref => this.filters = ref}
                    onChange={this.onChangeFilters}
                    proxy={this.proxyFilters}
                    disabled={searchLoading || loading}
                    filters={filterState}
                    isMobile={isMobile}
                    currentDisplay={currentDisplay}
                    currentRangeNumber={currentRangeNumber}
                    onChangeGroup={this.onChangeGroup}

                    downloadLoading={isDownloadInProgress}
                    downloadDisabled={currentDisplay !== "trunk_number" || !trunk_id || isDownloadInProgress}
                    onDownloadNumbersCsv={this.onDownloadNumbersCsv}
                    onDownloadOnlyNumbersCsv={this.onDownloadOnlyNumbersCsv}
                />


                    <S.Grid align={'middle'} justify={'space-between'}>
                        {!isMobile &&
                            <S.GridItem>
                                <RadioGroup
                                    inline
                                    onChange={this.onChangeGroup}
                                    defaultValue={'trunk_number'}
                                    name="radioList"
                                >
                                    <Radio value="trunk_number" disabled={loading}>Numbers</Radio>
                                    <Radio value="price_range" disabled={loading}>Ranges</Radio>
                                    <Radio value="subdestination" disabled={loading}>Subdestinations</Radio>
                                </RadioGroup>
                            </S.GridItem>
                        }

                        <S.GridItem>
                            <S.Grid>
                                {!isMobile &&
                                    <S.GridItem>
                                        <NumbersDownloadDropdown
                                            loading={isDownloadInProgress}
                                            disabled={currentDisplay !== "trunk_number" || !trunk_id || isDownloadInProgress}
                                            onDownloadNumbersCsv={this.onDownloadNumbersCsv}
                                            onDownloadOnlyNumbersCsv={this.onDownloadOnlyNumbersCsv}
                                        />
                                    </S.GridItem>
                                }

                                <S.GridItem>
                                    <AllocatedNumbersModal
                                        hideBtn={isMobile}
                                        account={account}
                                        forsed_open={showAllocatedNumbersModal}
                                        onClose={closeAllocatedNumbersModal}
                                        activeTrunk={currentTrunk}
                                        trunk_id={trunk_id}
                                        sp_key={this.props.sp_key}
                                        sp_auth={this.props.sp_auth}
                                        spOtpType={spOtpType}
                                        update={this.updateItems}
                                        account_id={acc_key}
                                        disabled={this.props.readonly}
                                        {...{
                                            getTrunkNumberCsvById
                                        }}
                                    />
                                </S.GridItem>
                            </S.Grid>
                        </S.GridItem>

                    </S.Grid>

                <TableServerSort
                    shouldUpdateScroll={false}
                    ref={ref => this.tableRef = ref}
                    data={data}
                    height="50%"
                    loading={searchLoading || loading}
                    columns={columns.filter(el => el.displayWhen.indexOf(currentDisplay) !== -1)}
                    count={allocatedNumbersCount}
                    per_page={allocatedNumbersPerPage}
                    page={allocatedNumbersPage}
                    useSelectedData={this.keepSelectedItems}
                    getItems={this.getNumbers}
                    row_key={this.getRowKey()}
                    setSelected={this.updateSelectedList}
                    disabled={!trunk_id}
                    ispagination
                    isselected={isselected}
                    isSelectedAll={isselected}
                    onSort={(column, type) => this.setState({sort: {column, type}})}
                    isMobile={isMobile}
                />

                <FooterActions>
                    <NumbersActions
                        actions={[
                            {
                                label: 'Change rate',
                                handler: () => this.handleOpenChangeRateModal()
                            },
                            {
                                label: 'Revoke numbers',
                                handler: () => this.setState({showRevokeModal: true}),
                                hidden: true,
                            },
                            {
                                label: 'Move to another trunk',
                                handler: () => this.setState({showMoveModal: true})
                            },
                        ]}
                        disabled={!selected.list.length || footerActionsDisabled}
                        loading={footerActionsLoading}
                        disabledItemValues={disabledActions}
                    />
                </FooterActions>

                {showChangeRateModal &&
                    <ChangeRateModal
                        subDestinationsList={currentSubDestinationsList}
                        target={{trunk_id}}
                        targetPrice={{account_id: acc_key}}
                        initialValues={this.getInitialValues()}
                        update={this.updateItems}
                        getData={this.getDataForChangeRate}
                        onClose={() => {
                            this.setState({
                                showChangeRateModal: false,
                                currentSubDestinationsList: [],
                            })
                        }}
                    />
                }

                {showRevokeModal &&
                    <RevokeNumbersModal
                        target={{trunk_id}}
                        initialValues={this.getInitialValues()}
                        getCount={this.getSelectedNumbersCount}
                        update={this.updateItems}
                        onClose={() => this.setState({showRevokeModal: false})}
                    />
                }

                {showMoveModal &&
                    <MoveNumbersModal
                        target={{trunk_id}}
                        trunks={this.props.trunks.filter((trunk) => canIMoveNumbersToAnotherTrunk(currentTrunk, trunk))}
                        initialValues={this.getInitialValues()}
                        getCount={this.getSelectedNumbersCount}
                        update={this.updateItems}
                        onClose={() => this.setState({showMoveModal: false})}
                    />
                }

            </div>
        );

    }
}