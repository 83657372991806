import {CLI_DIALER as PREFIX} from '../../const';
import {CLI_LIST_DIALER_API, CLI_LIST_OTP_DIALER_API} from '../../const/apiMethods';
import {api} from '../../api/loginRoutes';
import {_setValue} from './defaults';

const setCLIList = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);

export const getCLIList = (filter = {}, isAuth = false) => (dispatch) => {
    dispatch(setLoading(true));
    const request = isAuth ? CLI_LIST_OTP_DIALER_API : CLI_LIST_DIALER_API;
    console.log("request", request);
    api(request, {filter, add: {}}).then((response) => {
        if (response !== undefined) {
            dispatch(setCLIList({
                cli_lookup: response.cli_lookup || []
            }));
        }
    });
};

export const loadingData = (load) => (dispatch) => {
    dispatch(setLoading(load));
};
