import React from 'react';
import {Icon, IconButton} from 'rsuite';
import AccountsTable from './AccountsTable';
import AccountsFilters from './AccountsFilters';
import {pipe} from '../../utils/helpers';
import {newAccount} from '../../routes';
import {Link} from 'react-router-dom';
import PanelLayout from '../../components/base/PanelLayout';
import {checkPermissionsFor} from "../../store/storeHelpers";
import {ACCOUNT_CREATE_METHOD} from "../../const/apiMethods";
import AccountsApiFilters from './AccountsApiFilters';
import * as S from "./styled";
import {ButtonPrimary} from "../../components/base/BaseButton";

const FiltersFuncs = {
    account_managger: (x,f) => f.some( am_key => am_key === x.account_manager_id),
    payment_term: (x,f) => f === x.pt_key,
    traffic_check: (x,f) => !!x.VOLUME_1_OUT ||  !!x.VOLUME_7_OUT ||  !!x.VOLUME_30_OUT,
    allocated_check: (x,f) => !!x.allocated_numbers,
    closed_check: (x,f) => x.closed || !x.closed,
};

export default class extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            accounts: this.props.accounts,
            accountsFirstFetched: false,
            inner_loading: false,
            resizedWidth: window.innerWidth,
            filters: {
                traffic_check: true
            },
            showMobileFilters: false
        };
    }


    componentDidMount() {
        this.props.getAccounts({str: ""});
        this.props.getAccountManagersList();
        window.addEventListener('resize', this.handleResize);
    };


    componentDidUpdate(prevProps) {
        const {filters} = this.state;

        if (prevProps.accounts !== this.props.accounts) {
            this.setState({
                accounts: filters.closed_check
                    ? pipe(this.props.accounts, filters, FiltersFuncs)
                    : this.onlyOpened(pipe(this.props.accounts, filters, FiltersFuncs)),
                inner_loading: false
            });
        }
    };


    componentWillUnmount() {
        const {setAccountsCurrentApiRequestStr} = this.props;
        window.removeEventListener('resize', this.handleResize);
        setAccountsCurrentApiRequestStr("");
    };


    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };


    onlyOpened = (accounts) => {
        return accounts.filter(acc => !acc.closed)
    };


    onChangeApiFilters = (filters) => {
        const {setAccountsCurrentApiRequestStr} = this.props;
        setAccountsCurrentApiRequestStr(filters.str);
        this.props.getAccounts(filters);
    };


    onChangeFilters = (filters) => {
        const {accounts} = this.props;

        this.setState({
            inner_loading: true,
            filters: {...filters}
        });
        
        setTimeout(() => this.setState({
            accounts: filters.closed_check
                ? pipe(accounts, filters, FiltersFuncs)
                : this.onlyOpened(pipe(accounts, filters, FiltersFuncs)),
            inner_loading: false
        }), 0)
    };


    setLoading = (loading) => {
        this.setState({inner_loading:loading});
    };


    render () {
        const {loading, account_manager_list, paymentTermsList} = this.props;
        const {accounts, inner_loading, resizedWidth, showMobileFilters} = this.state;

        const isMobile = resizedWidth < 1200;

        return (
            <PanelLayout>
                <S.Grid
                    noWrap={!isMobile}
                    align={!isMobile ? "top" : "middle"}
                >

                    <S.GridItem
                        isFullWidth={resizedWidth < 420}
                        style={{
                            marginLeft: 'auto', 
                            order: resizedWidth >= 420 ? 1 : 0,
                            textAlign: isMobile ? 'right' : 'inherit'
                        }}
                    >
                        <ButtonAddAccount isMobile={isMobile}/>
                    </S.GridItem>
                    
                    <S.GridItem style={{flexGrow: 1}}>
                        <S.Grid noWrap>
                            <S.GridItem style={{flexGrow: 1, width: '100%', maxWidth: 284}}>
                                <AccountsApiFilters
                                    onChange={this.onChangeApiFilters}
                                    setLoading={this.setLoading}
                                    isMobile={isMobile}
                                />
                            </S.GridItem>
                            <S.GridItem>
                                {!isMobile
                                    ? <AccountsFilters
                                        onChange={this.onChangeFilters}
                                        defaultValue={this.state.filters}
                                        setLoading={this.setLoading}
                                        isMobile={false}
                                        show={showMobileFilters}
                                        {...{
                                            account_manager_list,
                                            paymentTermsList
                                        }}
                                    />
                                    : <IconButton
                                        appearance={showMobileFilters ? "primary" :  "default"}
                                        icon={<Icon icon="filter"/>}
                                        onClick={() => this.setState({
                                            showMobileFilters: !showMobileFilters
                                        })}
                                    >
                                    </IconButton>
                                }
                            </S.GridItem>
                        </S.Grid>
                    </S.GridItem>

                </S.Grid>

                {isMobile
                    && <AccountsFilters
                        onChange={this.onChangeFilters}
                        defaultValue={this.state.filters}
                        setLoading={this.setLoading}
                        isMobile={true}
                        show={showMobileFilters}
                        {...{account_manager_list}}
                    />
                }
                
                <AccountsTable
                    data={accounts}
                    loading={loading || inner_loading}
                    defaultSortColumn="name"
                    isMobile={isMobile}
                />
            </PanelLayout>
        )
    }
}


const ButtonAddAccount = ({children, isMobile = false, ...props}) => (
    checkPermissionsFor(ACCOUNT_CREATE_METHOD) &&
        <ButtonPrimary
            componentClass={Link}
            to={newAccount}
            isMobile={isMobile}
            {...props}
        >
            {isMobile ?
                <Icon icon="plus"/>
                : "+Add account"
            }
        </ButtonPrimary>
);

