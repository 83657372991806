import React from 'react';
import { Form, FormGroup,SelectPicker, Input, InputGroup, Icon, Schema} from 'rsuite';
import CustomField from '../../../components/Form/CustomField';
import FormHOC from '../../../hoc/FilltersForm';
import ServicePlans from "../../../components/Filters/ServicePlans";
import {connect} from "react-redux";
const { StringType } = Schema.Types;

const filtersModel = Schema.Model({});


const filters = ({onChange, worldzone_list, subdestination_list, destination_list, defaultSPKey}) => {
    return (
            <>
                <Form layout="inline" 
                    onChange={onChange}
                    formDefaultValue={{sp_key: defaultSPKey}}
                    model={filtersModel}
                    >
                    <ServicePlans classPrefix="allocated"/>
                    <CustomField
                        accepter={SelectPicker}
                        labelKey="name"
                        valueKey="wz_key"
                        data={worldzone_list}
                        placeholder="Worldzone"
                        name="wz_key"
                        classPrefix="allocated"
                    />
                     <CustomField
                        accepter={SelectPicker}
                        data={destination_list}
                        labelKey="name"
                        valueKey="de_key"
                        placeholder="Destination"
                        name="de_key"
                        classPrefix="allocated"
                    /> 
                    <CustomField
                        accepter={SelectPicker}
                        data={subdestination_list}
                        labelKey="name"
                        valueKey="sde_key"
                        placeholder="Subdestination"
                        name="sde_key"
                        classPrefix="allocated"
                    />
                </Form>
            </>
        )
};

const mapState = ({references})=> ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapState, null)(FormHOC(filters, filtersModel,300));