import {REFERENCES_DIALER_TRUNK} from '../const/';
import {REFERENCES_ALL_SET_LISTS, REFERENCES_SET_LISTS, REFERENCES_SET_LOADING} from './actionTypes';

import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {
    REFERENCES_FOR_REPORTS_METHOD,
    REFERENCES_ADMIN,
    REFERENCES_CLIENT,
    REFERENCES_ALL_METHOD,
} from '../const/apiMethods';
import {APP_TYPE_ADMIN} from "../const";


const setLoading = createAction(REFERENCES_SET_LOADING);
const setReferences = createAction(REFERENCES_SET_LISTS);
const setAllReferences = createAction(REFERENCES_ALL_SET_LISTS);


// GET APP REFERENCES
export const getReferences = (appType = APP_TYPE_ADMIN) => (dispatch) => {
    dispatch(setLoading(true));
    const method = appType === APP_TYPE_ADMIN ? REFERENCES_ADMIN : REFERENCES_CLIENT;
    api(method)
        .then((references) => {
            dispatch(setReferences(references));
        })
        .finally(() => dispatch(setLoading(false)));
};



export const getReferencesForReports = () => (dispatch) => {
    dispatch(setLoading(true));

    api(REFERENCES_FOR_REPORTS_METHOD)
        .then((references) => {
            dispatch(setReferences(references));
        })
        .finally(() => dispatch(setLoading(false)));
};

const setDialerTrunk = createAction(REFERENCES_DIALER_TRUNK);

export const getDialerTrunk = () => (dispatch) => {
    api('trunk_list_for_accounts')
        .then(({trunk_list = []}) => {
            dispatch(setDialerTrunk(trunk_list.map(
                x =>({
                    ...x,
                    trunk_id: x.tr_uuid,
                    _name:`${x.acc_name}\\${x.tr_name}\\${x.sp_name}`
                })
            )));
        })
};

export const getAllReferences = () => (dispatch) => {
    api(REFERENCES_ALL_METHOD).then((response) => {
            dispatch(setAllReferences(response));
            dispatch(setLoading(false));

    })
};