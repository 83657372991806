import React from 'react';
import Form from 'rsuite/es/Form';
import FormHOC from 'hoc/FilltersForm';
import {Schema} from 'rsuite';
import {debounce} from 'lodash';
import Text from '../../components/Form/Text';
import styled from "styled-components";

const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(256, 'The maximum is only 256 characters.'),
});


const AccessListExceptionFilter = ({onChange, defaultFilters}) => {
    const handleChange = (formValues) => {
        onChange(formValues)
    };

    return (
        <>
            <Form
                className="access_list_exception_filter"
                model={filtersModel}
                layout="inline"
                onChange={debounce((value) => {
                    handleChange(value);
                }, 400)}
                formDefaultValue={defaultFilters}
            >
                <StyledTextField
                    name="str"
                    placeholder="Origin/origin prefix/destination"
                />
            </Form>
        </>
    );
};
export default FormHOC (AccessListExceptionFilter, filtersModel, 300);


const StyledTextField = styled(Text)`
    &&& {
        width: 100%;
        min-width: 300px;
    }
`;