import React from 'react';
import Filters from './NumbersFilters';
import TableServerSort from '../../../components/Table/TableServerSort';
import Revoke from './Revoke';
import Allocate from './Allocate';
import SetOrUnsetTest from './SetOrUnsetTest';
import Block from 'pages/RangesAndNumbers/Numbers/Block';
import Unblock from 'pages/RangesAndNumbers/Numbers/Unblock';
import FlexboxGrid from 'rsuite/es/FlexboxGrid';
import {getRangeByNumber} from 'utils';
import {Link} from 'react-router-dom';
import './Numbers.css';


export default class Numbers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: {all: false, list: []},
            sort: {},
            columns: [
                {
                    label: 'Number',
                    dataKey: 'number',
                    value: (({number, numbers_count}) => getRangeByNumber(number, numbers_count)),
                    sortable: true,
                    width: 260
                },
                {
                    label: 'Allocated to and trunk',
                    dataKey: 'trunk_name',
                    value: (({account_name, account_manager_name, trunk_name, number, account_id, trunk_id}) => {
                        return account_name ?
                            <>
                                <Link className="ranges_numbers_table_link" to={`/accounts/view/${account_id}`} onClick={() => {
                                    this.props.setCurrentTrunkId(trunk_id);
                                    this.props.setCurrentRangeNumber(number)
                                }}>
                                    {account_name} / {account_manager_name || 'no manager'}&nbsp;
                                </Link>
                                {trunk_name.slice(account_name.length,-1)}
                            </>
                            : trunk_name
                    }),
                    sortable: true
                },
            ]
        }
    }



    /** @type MyTable */
    tableRef = null;

    getFilters = () => {
        return this.props.filters
    };
    getSelectedList = () => ({
        ...this.state.selected,
        countAll: this.props.allocatedNumbersCount
    });

    getNumbers = (page, per_page, sort) => {
        const {filters} = this.props;
        this.props.getNumbers(filters, page, per_page, sort);
    };
    // componentDidMount() {
    //     this.props.getNumbers(this.props.filters, 1, this.props.numbersPerPage, this.state.sort);
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.numbers !== this.props.numbers) {

            const isRange = this.props.numbers.some(item => item.numbers_count > 1);

            this.setState({
                columns: [
                    {
                        label: 'Number',
                        dataKey: 'number',
                        value: (({number, numbers_count}) => getRangeByNumber(number, numbers_count)),
                        sortable: true,
                        width: isRange ? 260 : 130
                    },
                    {
                        label: 'Allocated to and trunk',
                        dataKey: 'trunk_name',
                        value: (({account_name, account_manager_name, trunk_name, number, account_id, trunk_id}) => {
                            return account_name ?
                                <>
                                    <Link className="ranges_numbers_table_link" to={`/accounts/view/${account_id}`} onClick={() => {
                                        this.props.setCurrentTrunkId(trunk_id);
                                        this.props.setCurrentRangeNumber(number)
                                    }}>
                                        {account_name} / {account_manager_name || 'no manager'}&nbsp;
                                    </Link>
                                    {trunk_name.slice(account_name.length,-1)}
                                </>
                                : trunk_name
                        }),
                        sortable: true
                    },
                ]
            })
        }

        if (!this.props.numbers.length && prevProps.numbers !== this.props.numbers) {
            this.clearSelected();
        }
    }

    onChangeFilters = (filters) => {
        this.props.onChangeNumberFilters(filters);
        this.props.getNumbers(this.props.filters, 1, this.props.numbersPerPage, this.state.sort);
        this.clearSelected();
    };


    clearSelected() {
        this.setState({selected: {all: false, list: []}});
        this.tableRef.clear();
    }

    render() {
        const {
            numbers, numbersLoading, numbersPage, numbersCount, numbersPerPage,
            account_id, sde_key, pr_key, filters
        } = this.props;
        const {
            selected,
            columns
        } = this.state;


        const includedNumber = selected.list.length && numbers.filter(number => selected.list.includes(number.prn_key));
        const disabledBlockedNumbersButtonSign = includedNumber && includedNumber.some(number => number.trunk_name === 'blocked');
        const hasNoZeroPrefixNumbers = includedNumber && includedNumber.some(number => number.numbers_count !== 1);

        return (
            <div>
                <FlexboxGrid justify="space-around" style={{marginBottom: 25, textAlign: 'right'}}>
                    <FlexboxGrid.Item>
                        <Revoke
                            disabled={!selected.list.length}
                            pr_key={pr_key}
                            filters={filters}
                            getList={this.getSelectedList}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Allocate
                            disabled={!selected.list.length || disabledBlockedNumbersButtonSign || hasNoZeroPrefixNumbers}
                            getList={this.getSelectedList}
                            pr_key={pr_key}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                            account_id={account_id}
                            sde_key={sde_key}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <SetOrUnsetTest
                            disabled={!selected.list.length || disabledBlockedNumbersButtonSign || hasNoZeroPrefixNumbers || selected.all}
                            getList={this.getSelectedList}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                            isSetTest={true}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <SetOrUnsetTest
                            disabled={!selected.list.length || disabledBlockedNumbersButtonSign || selected.all}
                            getList={this.getSelectedList}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                            isSetTest={false}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Block
                            disabled={!selected.list.length || disabledBlockedNumbersButtonSign || selected.all}
                            getList={this.getSelectedList}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Unblock
                            disabled={!selected.list.length || selected.list.length && !disabledBlockedNumbersButtonSign || selected.all}
                            getList={this.getSelectedList}
                            update={() => {
                                this.props.updateRanges();
                                this.tableRef.clear();
                            }}
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <div style={{marginLeft: 20}}>
                    <Filters
                        value={this.props.filters}
                        onChange={this.onChangeFilters}
                    />
                    <TableServerSort
                        ref={ref => this.tableRef = ref}
                        data={numbers}
                        loading={numbersLoading}
                        columns={columns}
                        count={numbersCount}
                        per_page={numbersPerPage}
                        page={numbersPage}
                        getItems={this.getNumbers}
                        row_key="prn_key"
                        isSelectedAll={true}
                        setSelected={selected => {
                            this.setState({selected});
                        }}
                        ispagination
                        isselected
                        onSort={(column, type) => this.setState({sort: {column, type}})}
                    />
                </div>
            </div>
        );

    }
}