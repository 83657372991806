import React, {useMemo} from "react";
import {SelectPicker, Schema, Button, Icon} from "rsuite";
import TableEditCells from "../../../components/Table/TableEditCells";
import styled from "styled-components";
import {BaseButton} from "../../../components/base/BaseButton";


const {StringType} = Schema.Types;

const formModelPrefix = Schema.Model({
    prefix: StringType()
        .isRequired('Required')
        .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
        .minLength(6, 'The minimum is 6 characters.')
        .maxLength(40, "The maximum is 15 characters"),
});
const formModelDescription = Schema.Model({
    description: StringType()
    .isRequired('Required')
});
const formModelIvr = Schema.Model({
    ivr_id: StringType()
    .isRequired('Required')
});
const formModel = Schema.Model.combine(
    formModelPrefix, 
    formModelDescription, 
    formModelIvr
);


export default ({
    data = [],
    loading,
    getIvrCliList,
    ivrList,
    handleSubmit,
    handleRemove,
    ...props
}) => {


    const handleUpdate = () => {
        getIvrCliList();
    }


    const columns = useMemo(() => [
        {
            dataKey: "prefix",
            dataValue: 'prefix',
            flexGrow: 2,
            label: 'CLI',
        },
        {
            dataKey: 'description',
            dataValue: 'description',
            flexGrow: 2,
            label: 'Description',
        },
        {
            dataKey: 'ivr_id',
            dataValue: 'ivr_name',
            flexGrow: 2,
            label: 'IVR',

            placeholder: 'IVR',
            accepter: SelectPicker,
            model: formModelIvr,

            data: ivrList,
            valueKey: "id",
            labelKey: "name",
            searchable: false,
            cleanable: false,
        },
        {
            dataKey: 'listen',
            customValue: () => (
                <StyledButtonPlay buttonStyle="secondary">
                    <Icon icon="play" />
                </StyledButtonPlay>
            )
        }
    ], [ivrList]);


    return (
        <>
            <TableEditCells
                data={data}
                loading={loading}
                idKey="prefix"
                columns={columns}

                formModel={formModel}

                onDataUpdate={handleUpdate}
                onModifyRow={handleSubmit}
                onRemoveRow={handleRemove}

                ivrList={ivrList}

                {...props}
            />
        </>
    )
}

const StyledButtonPlay = styled(BaseButton)`
    && {
        padding: 0 0 0 3px;
        width: 28px;
        height: 28px;
        line-height: 28px;
        border-radius: 28px;
        margin: -3px 0 0;
        
        .rs-icon {
            line-height: 28px;
            font-size: 13px;
        }
    }
`;
