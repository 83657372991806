import {
    ACCOUNT_NOTE_LOADING,
    ACCOUNT_NOTE_LIST,
} from '../const';

const initialState = {
    list: [],
    count: 0,
    loading: true,
    isFetched: false,
};

const handlers = {
    [ACCOUNT_NOTE_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [ACCOUNT_NOTE_LIST]: (state, {payload: {list, count}}) => ({
        ...state,
        list: list,
        count: count,
        loading: false,
        isFetched: true,
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}