import React, {useState} from 'react';
import {FlexboxGrid, Form, Schema} from 'rsuite';
import styled from 'styled-components';
import Checkbox from '../../../hoc/Checkbox';
import CustomField from '../../../pages/NewAccount/CustomField';
import LimitForm from '../../../pages/Account/TabSelfAllocation/LimitForm';
import DefaultLimitationDescription from '../../../pages/Account/TabSelfAllocation/DefaultLimitationDescription';
import {someFieldHasError, compareObjects} from '../../../utils';
import {ButtonContent, ButtonPrimary} from "../../../components/base/BaseButton";
import 'pages/Account/TabSelfAllocation/selfAllocation.css';

const {NumberType} = Schema.Types;

const modelOnce = Schema.Model({
    once: NumberType().addRule((value, data) => {
        if (value || value === 0) {
            return value < data.daily || value < data.daily_sde;
        }
        return false;
    }, 'You should use limit per once less than a day or destination')
});
const modelDaily = Schema.Model({
    daily: NumberType().addRule((value, data) => {
        if (value || value === 0) {
            return value >= data.once;
        }
        return false;
    }, 'You should use limit per daily greater than once')
});
const modelDailySde = Schema.Model({
    daily_sde: NumberType().addRule((value, data) => {
        if (value || value === 0) {
            return value >= data.once;
        }
        return false;
    }, 'You should use limit per daily sde greater than once')
});


export function SelfAllocationUpdateAll(form, limitState) {
    this.permission_list = [];
    this.allocation_limit = {};

    form.allocate_number && this.permission_list.push('allocate_number');
    form.allocate_pattern && this.permission_list.push('allocate_pattern');
    form.google_otp_allocation && this.permission_list.push('google_otp_allocation');
    form.google_otp_autorevoke && this.permission_list.push('google_otp_autorevoke');

    Object.keys(limitState).map((limitKey) => {
        this.allocation_limit[limitKey] = limitState[limitKey];
    });
}

export function SelfAllocationUpdateSingleLimit(limitState) {
    this.allocation_limit = {};

    Object.keys(limitState).map((limitKey) => {
        this.allocation_limit[limitKey] = limitState[limitKey];
    });
}


const styleRow = {margin: '10px 0px 20px 0px'};

export default ({disabled, reloadPage,  formDefaultValue, account_id, onCancel, defaultAllocationLimits,
                    allocationLimits, setAllocationRestrictions, modifiedLimit, setModifiedLimitId}) => {
    let cleanTimer;
    let modifyTimer;

    let formLimitRefs = {};

    const [form, setForm] = useState(null);
    const [formError, onChangeFormError] = useState({});

    const defaultLimitKeys = Object.keys(defaultAllocationLimits);
    defaultLimitKeys.length && defaultLimitKeys
        .push(defaultLimitKeys.splice(defaultLimitKeys.indexOf("other"), 1)[0]);

    const objectsKeys = Object.keys(Object.assign({}, defaultAllocationLimits, allocationLimits));

    let combinedLimitObject = JSON.parse(JSON.stringify(defaultAllocationLimits));

    objectsKeys.map((key) =>
        allocationLimits.hasOwnProperty(key) && Object.keys(allocationLimits[key]).map((accKey) => {
            if (combinedLimitObject.hasOwnProperty(key)) {
                if (combinedLimitObject[key].hasOwnProperty(accKey)) combinedLimitObject[key][accKey] = allocationLimits[key][accKey]
            } else {
                combinedLimitObject = {...combinedLimitObject, [key]: allocationLimits[key]}
            }
            return combinedLimitObject
        })
    );
    const combinedLimitObjectKeys = Object.keys(combinedLimitObject);
    combinedLimitObjectKeys.length && combinedLimitObjectKeys
        .push(combinedLimitObjectKeys.splice(combinedLimitObjectKeys.indexOf("other"), 1)[0]);

    const [limitState, onChangeLimitState] = useState(combinedLimitObject);

    const allocationLimitsCleanable = JSON.parse(JSON.stringify(combinedLimitObject));

    Object.keys(allocationLimitsCleanable).map((limit) => {
        const limitObject = allocationLimitsCleanable[limit];

        Object.keys(limitObject).map((rule) => limitObject[rule] = false);
        allocationLimitsCleanable[limit] = limitObject
    });

    const [cleanedFields, onCleanFields] = useState(allocationLimitsCleanable);

    const onSubmit = (formValue, state, key) => {
        const selfAllocationUpdateObject = formValue && new SelfAllocationUpdateAll(formValue, state);
        const selfAllocationUpdateSingleLimitObject = !formValue && new SelfAllocationUpdateSingleLimit(state);
        clearTimeout(modifyTimer);

        if (formValue) {
            setAllocationRestrictions(account_id, selfAllocationUpdateObject, key);
        }
        else {
            setAllocationRestrictions(account_id, selfAllocationUpdateSingleLimitObject, key);
        }

        cleanTimer = setTimeout(() => {
            setModifiedLimitId(null);
        }, 3000);
    };

    const cleanLimit = (key) => {
        const defaultAllocationRules = defaultAllocationLimits[key];
        const defaultAllocationRulesKeys = defaultAllocationRules ? Object.keys(defaultAllocationRules): [];
        const accountAllocationRules = limitState[key];
        const accountAllocationRulesKeys = defaultAllocationRules ?  Object.keys(defaultAllocationRules) : [];
        const keysToClean = defaultAllocationRulesKeys.filter(freqKey => defaultAllocationRules[freqKey] !== accountAllocationRules[freqKey]);
        keysToClean.map((clKey) => {cleanedFields[key][clKey] = true});
        onCleanFields({...cleanedFields, [key]: {...cleanedFields[key]}});
        onChangeLimitState({...limitState, [key]: defaultAllocationLimits[key]});
        clearTimeout(cleanTimer);

        cleanTimer = setTimeout(() => {
            accountAllocationRulesKeys.map((clKey) => {cleanedFields[key][clKey] = false});
            onCleanFields({...cleanedFields, [key]: {...cleanedFields[key]}});
        }, 1500);

        onSubmit(null, {[key]: null}, null)
    };

    const accountModelCombined = JSON.parse(JSON.stringify(limitState));

    Object.keys(accountModelCombined).map((key) => {
        if (accountModelCombined[key].hasOwnProperty('once')) {
            accountModelCombined[key]['once'] = modelOnce;
        }
        if (accountModelCombined[key].hasOwnProperty('daily')) {
            accountModelCombined[key]['daily'] = modelDaily;
        }
        if (accountModelCombined[key].hasOwnProperty('daily_sde')) {
            accountModelCombined[key]['daily_sde'] = modelDailySde;
        }
    });

    const prepareDefaultObj = (accountData, combinedData = {}) => {
        const combinedDataClone = JSON.parse(JSON.stringify(combinedData));
        Object.keys(combinedDataClone).forEach((key) => {
            if (!Object.keys(accountData).includes(key) && compareObjects(combinedDataClone[key], defaultAllocationLimits[key])) {
                combinedDataClone[key] = null
            } else {
                combinedDataClone[key] = combinedData[key]
            }
        });
        Object.keys(combinedDataClone).forEach(limitKey => {
            onChangeFormError({...formError, [limitKey]: {}})
        });

        return combinedDataClone
    };

    const fillLimitFormRef = (key, ref) => {
        formLimitRefs[key] = ref;


    };

    return (
        <Form
            style={{marginBottom: '20px'}}
            layout="horizontal"
            ref={ref => setForm(ref)}
            formDefaultValue={formDefaultValue}
        >
            <FlexboxGrid style={styleRow} justify="start" align="top">
                <StyledFLexBoxItem>

                    <CustomField
                        disabled={disabled}
                        accepter={Checkbox}
                        name="allocate_number"
                        defaultChecked={formDefaultValue.allocate_number}
                    >
                        Allow to allocate numbers himself
                    </CustomField>

                    <CustomField
                        disabled={disabled}
                        accepter={Checkbox}
                        name="allocate_pattern"
                        defaultChecked={formDefaultValue.allocate_pattern}
                    >
                        Allow to allocate own numbers
                    </CustomField>
                    <CustomField
                        disabled={disabled}
                        accepter={Checkbox}
                        name="google_otp_allocation"
                        defaultChecked={formDefaultValue.google_otp_allocation}
                    >
                        Google OTP Allocation
                    </CustomField>
                    <CustomField
                        disabled={disabled}
                        accepter={Checkbox}
                        name="google_otp_autorevoke"
                        defaultChecked={formDefaultValue.google_otp_autorevoke}
                    >
                        Allow autorevoke for Google OTP numbers
                    </CustomField>
                </StyledFLexBoxItem>
                <StyledFLexBoxItem>
                    <DefaultLimitationDescription
                        {...{
                            defaultLimitKeys,
                            defaultAllocationLimits
                        }}
                    />
                </StyledFLexBoxItem>
                <StyledFLexBoxItem>
                    <LimitForm
                        {...{
                            onSubmit,
                            formError,
                            cleanLimit,
                            limitState,
                            cleanedFields,
                            formLimitRefs,
                            accountLimitKeys: combinedLimitObjectKeys,
                            onChangeLimitState,
                            defaultAllocationLimits,
                            modifiedLimit,
                            fillLimitFormRef,
                            onChangeFormError,
                            model: accountModelCombined
                        }}
                    />
                </StyledFLexBoxItem>
            </FlexboxGrid>
            <ButtonFooter>
                <ButtonPrimary
                    disabled={disabled || someFieldHasError(formError)}
                    onClick={() => {
                        onSubmit(form.getFormValue(), prepareDefaultObj(allocationLimits, limitState), true);
                        reloadPage()
                    }}
                >
                    Save
                </ButtonPrimary>
                <ButtonContent onClick={() => onCancel()}>Cancel</ButtonContent>
            </ButtonFooter>
        </Form>
    );
};
const StyledFLexBoxItem = styled(FlexboxGrid.Item)`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    >div.rs-form-group[role="group"] {
      display: inline-block;
      margin-bottom: 0;
      width: 280px !important;
    }
    >div.rs-form-group[role="group"] .rs-checkbox {
      width: 100% !important;
    }
    >div.rs-form-group[role="group"] label {
      width: 300px;
    }
`;

const ButtonFooter = styled.div`
    text-align: center
    > * {
        margin-right: 50px
    }
`;