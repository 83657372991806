import React, {useEffect, useState} from 'react';
import {DateRangePicker, Form, FormGroup, SelectPicker} from "rsuite";
import CustomField from "../../components/Form/CustomField";
import {connect} from "react-redux";
import {getReferencesForReports} from "../../actions/references";
import Text from "../../components/Form/Text";
import FormHOC from "../../hoc/FilltersForm";
import {removeNilValues} from "../../utils";
import {ButtonPrimary} from "../../components/base/BaseButton";


const CDRFilters = ({onApplyFilters, defaultFilters, loading, ...props}) => {
    const [filters, setFilters] = useState(defaultFilters);

    const handleChange = (formValues) => {setFilters(formValues)};

    const handleApply = () => {onApplyFilters(removeNilValues(filters));};


    useEffect(() => {
        props.getReferencesForReports();
    }, []);

    return (
        <>
            <Form layout="inline"
                  onChange={handleChange}
                  formDefaultValue={defaultFilters}
            >
                <CustomField
                    accepter={DateRangePicker}
                    cleanable={false}
                    placeholder="Select Date Range"
                    name="range"
                    width={300}
                />
                <CustomField
                    accepter={SelectPicker}
                    labelKey="name"
                    valueKey="acc_key"
                    data={props.originatorList}
                    placeholder="Originator"
                    name="originator_key"
                    width={200}
                />
                <CustomField
                    accepter={SelectPicker}
                    data={props.dialerList}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Dialer"
                    name="dialer_id"
                    width={200}
                />
                <CustomField
                    accepter={SelectPicker}
                    data={props.supplierList}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Supplier"
                    name="supplier_key"
                    width={200}
                    disabled={true}
                />
                <br />
                <CustomField
                    accepter={SelectPicker}
                    width={300}
                    data={props.subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    placeholder="Origin"
                    name="a_sde_key"
                />
                <CustomField
                    accepter={SelectPicker}
                    width={300}
                    data={props.subdestinationList}
                    labelKey="name"
                    valueKey="sde_key"
                    placeholder="Destination"
                    name="b_sde_key"
                />
                <Text name="a_number" placeholder="A-Number" width={150}/>
                <Text name="b_number" placeholder="B-Number" width={150}/>
                <FormGroup>
                    <ButtonPrimary
                        style={{padding: '8px 34px', marginLeft: 12}}
                        onClick={handleApply}
                        disabled={loading}
                    >
                        Apply Filters
                    </ButtonPrimary>
                </FormGroup>
            </Form>
        </>
    );
};

const mapStateToProps = ({references, cdr}) => ({
    loading: cdr.loading,
    originatorList: references.client_list,
    dialerList: references.dialer_list,
    supplierList: references.supplier_list,
    subdestinationList: references.subdestination_list,
});

const mapDispatchToProps = {
    getReferencesForReports
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHOC(CDRFilters));