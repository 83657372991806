import {
    GOOGLE_OTP_ALLOCATION_SET_LOADING,
    GOOGLE_OTP_ALLOCATION_SET_LIST
} from "../const";

import {
    GOOGLE_OTP_ALLOCATION_GET_LIST_API,
    GOOGLE_OTP_ALLOCATION_CREATE_API,
    GOOGLE_OTP_ALLOCATION_MODIFY_API,
    GOOGLE_OTP_ALLOCATION_REMOVE_API
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setGoogleOtpAllocationList = createAction(GOOGLE_OTP_ALLOCATION_SET_LIST);
const setGoogleOtpAllocationListLoading = createAction(GOOGLE_OTP_ALLOCATION_SET_LOADING);

// get list
export const getGoogleOtpAllocationList = (filter = {}) => (dispatch) => {
    dispatch( setGoogleOtpAllocationListLoading(true) );

    api(GOOGLE_OTP_ALLOCATION_GET_LIST_API, {filter})
        .then((response) => {
            if (response !== undefined) {

                dispatch( setGoogleOtpAllocationList({
                    list: response.otp_allocation2 || []
                }) );
            }
        })
        .finally(() => {
            dispatch( setGoogleOtpAllocationListLoading(false) );
        })
};

export const createGoogleOtpAllocation = (params) => async (dispatch) => {
    return await api(GOOGLE_OTP_ALLOCATION_CREATE_API, {...params});
};

export const modifyGoogleOtpAllocation = (params) => async (dispatch) => {
    return await api(GOOGLE_OTP_ALLOCATION_MODIFY_API, {...params});
};

export const removeGoogleOtpAllocation = (key) => async (dispatch) => {
    return await api(GOOGLE_OTP_ALLOCATION_REMOVE_API, {oa_key: key});
};