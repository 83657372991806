import React from 'react';
import {Pagination, Schema, Form, InputNumber} from 'rsuite';
import {debounce} from "lodash";

import {DEFAULT_PER_PAGE_MEDIUM} from "../../../const";
import s from "./Pagination.module.css"

import CustomField from '../../Form/CustomField';
import styled from "styled-components";

const { NumberType } = Schema.Types;

const perPageModel = Schema.Model({
    per_page: NumberType()
        .isInteger('Integer')
        .isRequired('Required')
        .min(1,'Minimum 1')
        .max(10000,'Maximum 10000'),
});

export default ({
    total = 0,
    per_page = DEFAULT_PER_PAGE_MEDIUM,
    hidePerPage,
    activePage = 1,
    onChangePage,
    onChangePerPage,
    disabled = false
}) => {

    const handleChange = debounce( ({per_page = 0}) => (
        per_page ? onChangePerPage(+per_page) : null
    ), 500);

    return (
        <div className={s.wrapper}>
            <div className={s.pagination}>
                <StyledPagination
                    size="xs"
                    maxButtons={5}
                    pages={Math.ceil(total/per_page)}
                    activePage={activePage}
                    onSelect={onChangePage}
                    disabled={disabled || total === 0}
                    first
                    last
                />
            </div>

            {!hidePerPage &&
                <Form
                    layout="inline"
                    model={perPageModel}
                    className={s.form}
                    formDefaultValue={{per_page}}
                    onChange={handleChange}
                >
                    <CustomField
                        name="per_page"
                        accepter={InputNumber}
                        errorPlacement="topRight"
                        defaultValue={per_page}
                        max={10000}
                        min={1}
                        size="sm"
                        style={{width: 135}}
                        placeholder="Rows per page"
                        disabled={disabled}
                    />
                </Form>
            }

        </div>
    )
}


const StyledPagination = styled(Pagination)`
    && {
        border: 1px solid #C4C4C4;
        border-radius: 3px;
        
        a {
            color: #C4C4C4;
            border-right: 1px solid #C4C4C4;
            border-radius: 0 !important;
        }
        
        .rs-pagination-btn:last-child a {
            border-right: none;
        }
        
        & > li.rs-pagination-btn-active > a {
            color: #fff;
            background-color: var(--color-brand2);
        }
    }
`;