import React, {useState} from 'react';
import PageHeader from "../../components/base/PageHeader";
import {FlexboxGrid} from "rsuite";
import ExportCsv from "../../components/ExportCsv";
import PanelLayout from "../../components/base/PanelLayout";
import TrafficReportsTable from "pages/TrafficReports/TrafficReportsTable";
import {TRAFFIC_REPORTS_METHOD} from "const/apiMethods";
import TrafficReportsFilters from "pages/TrafficReports/TrafficReportsFilters";
import { LOCAL_STORAGE_TRAFFIC_STAT_FILTERS } from 'const/localStorageKeys';
import {connect} from "react-redux";


const TrafficReportsPage = ({globalSettings}) => {
    const today = new Date(Date.now());

    let savedFilters = null;
    const storagedFilters = localStorage.getItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS);
    if (storagedFilters && storagedFilters !== '') {
        savedFilters = {
            ...JSON.parse(storagedFilters),
            range: [
                new Date(JSON.parse(storagedFilters).start_end_date[0]),
                new Date(JSON.parse(storagedFilters).start_end_date[1]),
            ]
        };

        localStorage.removeItem(LOCAL_STORAGE_TRAFFIC_STAT_FILTERS);
    }

    const [filters, setFilters] = useState(
        savedFilters
            ? savedFilters
            : {range:[today, today], group_checkbox: ['originator_key']}
    );

    return (
        <PanelLayout>
            <PageHeader title="Traffic Reports">
                <ExportCsv
                    method={TRAFFIC_REPORTS_METHOD}
                    params={{filter: filters}}
                    title='Export CSV'
                    fileName='traffic_report.csv'
                    style={{position: 'relative', right: 15}}
                />
            </PageHeader>
            <FlexboxGrid >
                <FlexboxGrid.Item colspan={24}>
                    <TrafficReportsFilters
                        onApplyFilters={(filters) => {setFilters(filters)}}
                        defaultFilters={filters}
                        {...{
                            globalSettings
                        }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <TrafficReportsTable filters={filters} />
        </PanelLayout>
    );
};

const mapState = ( {references} )=> ({
    globalSettings: references.global_setting,
});

export default connect( mapState)(TrafficReportsPage);