import React from 'react';
import css from './VersionLabel.module.css';
import {APP_VERSION} from "index";

const VersionLabel = () => {
    return (
        <div className={css.versionLabel}>
            {APP_VERSION}
        </div>
    );
};

export default VersionLabel;