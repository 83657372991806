import React from 'react';
import {InputGroup, Input, Icon, FlexboxGrid, Button} from "rsuite";
import {ButtonPrimary} from '../../components/base/BaseButton';
import LiveCallsAutoUpdateForm from "./LiveCallsAutoUpdateForm";
import styled from "styled-components";

const styles = {
    width: 350,
    marginBottom: 10,
};

export default (
    {
        onChange,
        onUpdate,
        loading,

        autoUpdateFormValue = {},
        onChangeAutoUpdateValue
    })  => (
    <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item colspan={12}>
            <InputGroup style={styles}>
                <Input onChange={onChange}
                    placeholder={'Filter per account/dialer/numbers/destinations'}
                />
            </InputGroup> 
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} style={{display: 'flex', 'justify-content': 'flex-end'}}>
            <StyledUpdateBlock>
                <LiveCallsAutoUpdateForm
                    formDefaultValue={autoUpdateFormValue}
                    onChange={onChangeAutoUpdateValue}
                />
                <ButtonPrimary onClick={onUpdate} disabled={loading}>
                    Update data
                </ButtonPrimary>
            </StyledUpdateBlock>
        </FlexboxGrid.Item>
    </FlexboxGrid>
)

const StyledUpdateBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    
    .rs-form {
        margin-right: 20px;
    }
`;