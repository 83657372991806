import React, {useLayoutEffect} from "react";
import {withRouter} from "react-router";
import {Dropdown, Icon} from "rsuite";
import {connect} from "react-redux";
import {pageIdLogObject} from "../../utils/pageIdLogger";
import BaseNav from "../base/BaseNav";

const PageTabs = ({
    isMobile,
    activeTab,
    onSelect,
    menuItems = [],
    match
}) => {

    useLayoutEffect(() => {
        savePageId(activeTab);
    }, [activeTab]);

    console.log("activeTab", activeTab);


    const savePageId = (val) => {
        pageIdLogObject.pageMatch = {...match, path: match.path + '/' + val};
        pageIdLogObject.lastTab = val;
    };


    if (isMobile) {
        return (
            <Dropdown
                icon={<Icon icon="bars" />}
                appearance="ghost"
                placement={"bottomEnd"}
                onSelect={onSelect}
                activeKey={activeTab}
            >
                {menuItems.map(item => (
                    <Dropdown.Item key={item.id} eventKey={item.eventKey}>{item.title}</Dropdown.Item>
                ))}
            </Dropdown>
        )
    }

    return (
        <BaseNav
            activeKey={activeTab}
            onSelect={onSelect}
            appearance="tabs"
        >
            {menuItems.map(item => (
                <BaseNav.Item key={item.id} eventKey={item.eventKey}>{item.title}</BaseNav.Item>
            ))}
        </BaseNav>
    )
};

export default connect(
    null,
    {}
)(withRouter(PageTabs));