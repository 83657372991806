import React, {useState, useEffect} from "react";
import PageHeader from "../../components/base/PageHeader";
import {FormattedMessage} from "react-intl";
import PaymentDetailsTable from "./PaymentDetailsTable";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import styled from "styled-components";
import {Icon} from "rsuite";
import {Spacer} from "../../components/base/Spacer";
import ModalAddPayment from "./ModalAddPaymentDetail";
import {LOCAL_STORAGE_USER_INFO_DIALER} from "../../const";
import ModalRemovePaymentDetail from "./ModalRemovePaymentDetail";
import flaser from "object-flaser/lib/flaser";
import {ButtonPrimary} from "../../components/base/BaseButton";
import PanelLayout from "../../components/base/PanelLayout";
import { PAYMENT_DETAIL_LIST_API } from "const/apiMethods";
import { checkPermissionsFor } from "store/storeHelpers";


const PaymentDetails = ({
    data, 
    loading,
    organizationTypeList,
    countryList,
    paymentMethodList, 
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails,
    permissions,
}) => {

    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [showModalRemovePaymentDetail, setShowModalRemovePaymentDetail] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModify, setIsModify] = useState(false);
    const [addPaymentDefaultValue, setAddPaymentDefaultValue] = useState(false);
    const [loadingEditElementsSet, setLoadingEditElementsSet] = useState(new Set());

    const accountInfoStorage = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
    const parsedAccountInfo = accountInfoStorage !== null ? JSON.parse(accountInfoStorage) : {
        session: false
    };
    const accountId = parsedAccountInfo && parsedAccountInfo.session ? parsedAccountInfo.session.account.id : null;

    
    useEffect(() => {
        if (checkPermissionsFor(PAYMENT_DETAIL_LIST_API)) {
            getPaymentDetailsList();
        }
    }, [permissions]);

    const onShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
    };

    const closeModalAddPaymentDetail = () => {
        setIsModify(false);
        setAddPaymentDefaultValue(false);
        setShowModalAddPaymentDetail(false);
    };

    const handleEditPaymentDetails = async (value) => {
        const {id} = value;

        // set elements loading status
        const editElements = new Set(loadingEditElementsSet);
        setLoadingEditElementsSet( editElements.add(id) );

        const response = await getPaymentDetailsSingle({target: {payment_detail_id: id}});

        // remove elements loading status
        if (editElements.has(id)) {
            editElements.delete(id);
            setLoadingEditElementsSet(editElements);
        }

        if (!response && response.payment_detail) {
            return;
        }

        const fetchedData = response.payment_detail;

        let files = null;
        if (fetchedData.ext_files) {
            files = [];
            for (const file of fetchedData.ext_files) {
                files.push({
                    url: `data:${file.type};base64,${file.file}`,
                    fileKey: file.file_id,
                    name: file.name,
                });
            }
        }

        const defaultValue = {
            ...fetchedData,
            ext_files: files
        }

        setIsModify(true);
        setAddPaymentDefaultValue( flaser(defaultValue) );
        setShowModalAddPaymentDetail(true);
    };

    const handleRemovePaymentDetails = (value) => {
        setSelectedItem(value);
        setShowModalRemovePaymentDetail(true);
    };


    return (
        <PanelLayout>
            <PageHeader title={<FormattedMessage id="common.paymentDetails" defaultMessage="Payment details" />}/>

            <FlexGrid>
                <FlexGridItem grow={1}>
                    <StyledActionButtonWrapper>
                        <ButtonPrimary
                            onClick={onShowModalAddPaymentDetail}
                        >
                            + <FormattedMessage id="paymentDetails.addNew" defaultMessage="Add new"/>
                        </ButtonPrimary>
                    </StyledActionButtonWrapper>
                </FlexGridItem>
            </FlexGrid>

            <Spacer/>

            <PaymentDetailsTable
                data={data}
                loading={loading}
                loadingEditButtonsSet={loadingEditElementsSet}
                {...{
                    handleRemovePaymentDetails,
                    handleEditPaymentDetails
                }}
            />

            <ModalAddPayment
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                defaultFormValue={isModify ? addPaymentDefaultValue : false }
                modify={isModify}
                {...{
                    organizationTypeList,
                    countryList,
                    paymentMethodList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                    getPaymentDetailsList
                }}
            />

            <ModalRemovePaymentDetail
                show={showModalRemovePaymentDetail}
                onClose={() => {
                    setShowModalRemovePaymentDetail(false)
                }}
                {...{
                    accountId,
                    selectedItem,
                    getPaymentDetailsList,
                    removePaymentDetails
                }}
            />
        </PanelLayout>
    )
};

export default PaymentDetails;

const StyledActionButtonWrapper = styled.div`
    text-align: center;
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;

const StyledActionButtonIcon = styled(Icon)`
    margin-right: 5px;
`;