import {
    AM_ACTIVITIES_SET_LOADING,
    AM_ACTIVITIES_LIST,
    AM_ACTIVITIES_DATE_LIST_ITEM,
    AM_ACTIVITIES_DATE_LIST_ITEM_REMOVE,
    AM_ACTIVITIES_DATE_LIST_REMOVE,
} from '../const';

const initialState = {
    list: [],
    dateList: [],
    loading: true,
};

const handlers = {
    [AM_ACTIVITIES_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [AM_ACTIVITIES_LIST]: (state, {payload: {list}}) => ({
        ...state,
        list: list,
        dateList: [],
        loading: false,
    }),

    [AM_ACTIVITIES_DATE_LIST_ITEM_REMOVE]: (state, {payload}) => ({
        ...state,
        dateList: state.dateList.filter(item => item.id !== payload),
    }),
        
    [AM_ACTIVITIES_DATE_LIST_ITEM]: (state, {payload: {list, id}}) => ({
        ...state, 
        dateList: [...state.dateList, {list, id}],
        loading: false,
    }),

    [AM_ACTIVITIES_DATE_LIST_REMOVE]: (state) => ({
        ...state,
        dateList: [],
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}