import {defineMessages} from "react-intl";

export default defineMessages({
    editProfile: { id: "editProfile", defaultMessage: "Edit Profile", description: 'Edit Profile modal title' },
    email: { id: "editProfile.email", defaultMessage: "Email", description: 'Edit Profile email' },
    name: { id: "editProfile.name", defaultMessage: "Name", description: 'Edit Profile name' },
    surname: { id: "editProfile.surname", defaultMessage: "Surname", description: 'Edit Profile surname' },
    phone: { id: "editProfile.phone", defaultMessage: "Phone", description: 'Edit Profile phone' },
    send: { id: "editProfile.send", defaultMessage: "Send", description: 'Edit Profile send' },
    nothingToUpdate: { id: "editProfile.nothingToUpdate", defaultMessage: "Nothing to update", description: 'Edit Profile Nothing to update' },
    password: { id: "editProfile.password", defaultMessage: "Password", description: 'Edit Profile Password' },
    changePassword: { id: "editProfile.changePassword", defaultMessage: "Change password", description: 'Edit Profile Change password' },
    confirm: { id: "editProfile.confirm", defaultMessage: "Confirm", description: 'Edit Profile Confirm' },
    confirmPassword: { id: "editProfile.confirmPassword", defaultMessage: "Confirm password", description: 'Edit Profile Confirm password' },
    dontChangePassword: { id: "editProfile.dontChangePassword", defaultMessage: "Don't change password", description: 'Edit Profile Don\'t change password' },
    equalPasswordsAlert: { id: "editProfile.equalPasswordsAlert", defaultMessage: "Password and confirm password should be equal!", description: 'Edit Profile Password and confirm password should be equal!' },
    confirmProfileChanges: { id: "editProfile.confirmProfileChanges", defaultMessage: "Confirm profile changes", description: 'Edit Profile Confirm profile changes' },
    typeCurrentPassword: { id: "editProfile.typeCurrentPassword", defaultMessage: "Type current password if you want to confirm changes", description: 'Edit Profile Type current password if you want to confirm changes' },
    profileDataHasSuccessfullyChanged: { id: "editProfile.profileDataHasSuccessfullyChanged", defaultMessage: "Profile data has successfully changed!", description: 'Edit Profile Profile data has successfully changed!' },
})