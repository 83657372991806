import React, {useState, useEffect} from "react";
import PaymentDetailsTable from "./PaymentDetailsTable";
import styled from "styled-components";
import {Button, FlexboxGrid} from "rsuite";
import {Spacer} from "../../components/base/Spacer";
import ModalAddPayment from "./ModalAddPaymentDetail";
import ModalRemovePaymentDetail from "./ModalRemovePaymentDetail";
import flaser from "object-flaser/lib/flaser";
import {SCREEN_MEDIA} from "../../const";
import {ButtonContent} from "../../components/base/BaseButton";

const {sm, md} = SCREEN_MEDIA;


const PaymentDetails = ({
    accountId,
    data, 
    loading,
    organizationTypeList,
    countryList,
    paymentMethodList, 
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails,
    allowGetList = true
}) => {

    const [showModalAddPaymentDetail, setShowModalAddPaymentDetail] = useState(false);
    const [showModalRemovePaymentDetail, setShowModalRemovePaymentDetail] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModify, setIsModify] = useState(false);
    const [addPaymentDefaultValue, setAddPaymentDefaultValue] = useState(false);
    const [loadingEditElementsSet, setLoadingEditElementsSet] = useState(new Set());
    
    useEffect(() => {
        if (allowGetList && accountId) {
            getPaymentDetailsList({target: {account_id: accountId}});
        }
    }, [accountId, allowGetList]);

    const onShowModalAddPaymentDetail = () => {
        setShowModalAddPaymentDetail(true);
    };

    const closeModalAddPaymentDetail = () => {
        setIsModify(false);
        setAddPaymentDefaultValue(false);
        setShowModalAddPaymentDetail(false);
    };

    const handleEditPaymentDetails = async (value) => {
        const {id} = value;

        // set elements loading status
        const editElements = new Set(loadingEditElementsSet);
        setLoadingEditElementsSet( editElements.add(id) );

        const response = await getPaymentDetailsSingle({target: {payment_detail_id: id}});

        // remove elements loading status
        if (editElements.has(id)) {
            editElements.delete(id);
            setLoadingEditElementsSet(editElements);
        }

        if (!response && response.payment_detail) {
            return;
        }

        const fetchedData = response.payment_detail;

        let files = null;
        if (fetchedData.ext_files) {
            files = [];
            for (const file of fetchedData.ext_files) {
                files.push({
                    url: `data:${file.type};base64,${file.file}`,
                    fileKey: file.file_id,
                    name: file.name,
                });
            }
        }

        const defaultValue = {
            ...fetchedData,
            ext_files: files
        };

        setIsModify(true);
        setAddPaymentDefaultValue( flaser(defaultValue) );
        setShowModalAddPaymentDetail(true);
    };

    const handleRemovePaymentDetails = (value) => {
        setSelectedItem(value);
        setShowModalRemovePaymentDetail(true);
    };

    return (
        <>
            <FlexboxGrid justify="end">
                <FlexboxGrid.Item>
                    <StyledActionButtonWrapper>
                        <ActionButton
                            onClick={onShowModalAddPaymentDetail}
                        >
                            + Add new
                        </ActionButton>
                    </StyledActionButtonWrapper>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <Spacer/>
 
            <PaymentDetailsTable
                data={data}
                loading={loading || !allowGetList}
                loadingEditButtonsSet={loadingEditElementsSet}
                {...{
                    handleRemovePaymentDetails,
                    handleEditPaymentDetails
                }}
            />

            <ModalAddPayment
                show={showModalAddPaymentDetail}
                onClose={closeModalAddPaymentDetail}
                value={isModify ? addPaymentDefaultValue : false }
                modify={isModify}
                {...{
                    accountId,
                    organizationTypeList,
                    countryList,
                    paymentMethodList,
                    getPaymentDetailsList,
                    createPaymentDetails,
                    modifyPaymentDetails,
                }}
            />

            <ModalRemovePaymentDetail
                show={showModalRemovePaymentDetail}
                onClose={() => {
                    setShowModalRemovePaymentDetail(false)
                }}
                {...{
                    accountId,
                    selectedItem,
                    getPaymentDetailsList,
                    removePaymentDetails
                }}
            />

        </>
    )
};

export default PaymentDetails;

const StyledActionButtonWrapper = styled.div`
    text-align: center;
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;


export const ActionButton = styled(ButtonContent).attrs(() => ({
    size: 'md',
    buttonStyle: 'secondary',
}))`
    && {
        padding: ${props => props.isLink ? '0' : '12px 20px'};
        width: auto;
    
        .rs-icon {
            margin-right: 5px;
        }
    
        @media (min-width: ${sm.min}px) {
            min-width: 160px;
        }
        
        @media (min-width: ${md.min}px) {
            padding: ${props => props.isLink ? '0' : '8px 12px'};
        }
    }
`;