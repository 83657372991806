import styled, {css} from "styled-components";
import {FlexboxGrid} from "rsuite";

export const Grid = styled(FlexboxGrid)`
    
    ${props => !props.noGutters && css`
        margin-bottom: -15px;
        margin-left: -7px;
        margin-right: -7px;
        
        @media (min-width: 768px) {
            margin-left: -15px;
            margin-right: -15px; 
        }
    `}
    
    ${props => props.noWrap && css`
        && {
            flex-wrap: nowrap;
        }
    `}
`;

export const GridItem = styled(FlexboxGrid.Item)`
    padding-bottom: 15px;
    
    ${props => props.isFullWidth && css`
        width: 100%;
    `}
    
    ${props => !props.noGutters && css`
        padding-left: 7px;
        padding-right: 7px;    
        
        @media (min-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    `}
    
    && {
        flex-shrink: 1;
    }
    
    &:last-child {
        margin-right: 0;
    }
`;