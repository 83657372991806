import React, {useRef, useState} from "react";
import {Button, Form, FormControl, Input, SelectPicker, Icon} from "rsuite";
import styled from "styled-components";
import ModalSubmitRowApprove from "./ModalSubmitRowApprove";


export default ({
    formModel,
    onSubmit,
    onDataUpdate,
    ivrList,
}) => {

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [modalApproveData, setModalApproveData] = useState(false);
    const [showModalApprove, setShowModalApprove] = useState(false);
    const [modalApproveLoading, setModalApproveLoading] = useState(null);

    const handleSubmit = () => {
        if (!formRef.current.check())
            return;

        const formValue = formRef.current.getFormValue();

        setLoading(true);
        onSubmit(formValue)
            .then(res => {
                setLoading(false);
                if (res) {
                    onDataUpdate();
                }
            });
    };

    const onApprovedSubmit = async () => {
        setModalApproveLoading(true);
        await onSubmit(modalApproveData);
        onDataUpdate();
        setModalApproveLoading(false);
        setShowModalApprove(false);
    };

    return (
        <>
            <StyledForm
                ref={formRef}
                model={formModel}
                onSubmit={handleSubmit}
            >
                <StyledFieldWrapper grow={2}>
                    <StyledField
                        accepter={Input}
                        name="prefix"
                        className="tableFilters_field"
                        errorPlacement="topEnd"
                        placeholder="CLI"
                        type="text"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper grow={2}>
                    <StyledField
                        accepter={Input}
                        name="description"
                        className="tableFilters_field"
                        errorPlacement="topEnd"
                        placeholder="Description"
                        type="text"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper grow={2}>
                    <StyledField
                        accepter={SelectPicker}
                        name="ivr_id"
                        className="tableFilters_field"
                        errorPlacement="topEnd"
                        data={ivrList}
                        cleanable={false}
                        searchable={false}
                        placeholder="IVR"
                        valueKey="id"
                        labelKey="name"
                        type="text"
                    />
                </StyledFieldWrapper>

                <StyledSubmitWrapper>
                    <Button
                        className="tableFilters_submit"
                        color="green"
                        type="submit"
                        loading={loading}
                    >
                        <Icon icon="plus"/>
                    </Button>
                </StyledSubmitWrapper>
            </StyledForm>

            <ModalSubmitRowApprove
                show={showModalApprove}
                onSubmit={onApprovedSubmit}
                onClose={() => setShowModalApprove(false)}
                disabled={modalApproveLoading}
            />
        </>
    )
};

const StyledField = styled(FormControl).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    placement: "autoVerticalStart",
    type: "text",
    cleanable: true,
    searchable: true,
}))`
`;

const StyledFieldWrapper = styled.div`
    flex-grow: ${props => props.grow || '0'};
    flex-shrink: 0;
    flex-basis: 200px;
    padding: 5px 10px;
    width: 174px;
`;
const StyledSubmitWrapper = styled.div`
    padding: 5px 10px;
    width: 174px;
`;

const StyledForm = styled(Form)`
    display: flex;
`;