import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import { useWindowWidth } from "../../../hooks";
import { Form, InputNumber, SelectPicker, Button, Input, Schema } from "rsuite";
import {Spacer} from "../../../components/base/Spacer";
import * as S from "../styled";
import {SCREEN_MEDIA} from '../../../const';
import m from "../../../definedMessages";
import {BaseInputField, BaseInputNumberField, BaseSelectPicker} from "../../../components/base/BaseForm";

const {sm} = SCREEN_MEDIA;
const {StringType, NumberType} = Schema.Types;

export default ({
    show,
    onClose,
    maxWidth = 700,
    paymentDetailsItems,
    currencyName,
    requestPayment,
    getPaymentRequestList,
    handleShowModalAddPaymentDetail,
    accountId,
    dropdownVisibility,
    amountLimitMin = 0.01,
    amountLimit,
    onSuccess,

    formValue,
    setFormValue,
}) => {

    const {formatMessage} = useIntl();
    let formRef = null;

    const formModel = Schema.Model({
        amount: NumberType()
            .isRequired( formatMessage(m.thisFieldIsRequired) )
            .min(amountLimitMin, formatMessage(m.minNumber, {number: amountLimitMin}))
            .max(amountLimit, formatMessage(m.maxNumber, {number: amountLimit})),
        payment_detail_id: StringType()
            .isRequired( formatMessage(m.thisFieldIsRequired) ),
    });

    const [loading, setLoading] = useState(false);
    const resizedWidth = useWindowWidth();


    // clean Amount error on limit change
    useEffect( () => {
        if (formRef)
            formRef.cleanErrorForFiled('amount');
    }, [amountLimitMin] );


    const handleSubmit = async () => {
        
        if (!formRef.check() )
            return null;

        setLoading(true);

        const value = formRef.getFormValue();
        const data = {
            ...value,
            amount: +value.amount,
            target: {
                payment_detail_id: value.payment_detail_id,
            },
        };

        delete data.payment_detail_id;

        await requestPayment(data);
        
        getPaymentRequestList({accountId});
        setLoading(false);
        onSuccess(+value.amount);
        onClose();
    };


    const renderMenuItem = (label, item) => {
        return item.status === 1 ? label : <span style={{color: 'var(--color-disabled)'}}>{label}</span>;
    };

    return (
        <S.FormModal
            show={show}
            title={formatMessage(m.paymentRequest)}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            confirmButtonText={formatMessage(m.confirm)}
            loading={loading}
            focusTarget="close"
            onConfirm={handleSubmit}
            onClose={() => {
                onClose(false);
            }}
            onExited={() => {
                setFormValue({amount: 0});
            }}
        >
            <Form
                ref={ref => formRef = ref}
                model={formModel}
                formValue={formValue}
                onChange={setFormValue}
            >

                <p>{formatMessage(m.readyForPaymentInfo, {count: `${amountLimit} ${currencyName}`})}</p>

                <Spacer/>

                <S.FormRow>
                    <S.FormItem>

                        <S.FormGroup>
                            <S.Label>{formatMessage(m.amount)}</S.Label>
                            <S.FieldAmountWrapper>
                                <S.Field
                                    accepter={BaseInputNumberField}
                                    name='amount'
                                />
                            </S.FieldAmountWrapper>
                            {currencyName && <S.Currency>{currencyName}</S.Currency>}
                        </S.FormGroup>

                    </S.FormItem>
                    <S.FormItem grow>

                        <S.FormGroup>
                            <S.Label>{formatMessage(m.paymentInfo)}</S.Label>
                            <S.Field
                                accepter={BaseSelectPicker}
                                data={paymentDetailsItems}
                                cleanable={false}
                                name='payment_detail_id'
                                valueKey="id"
                                block={true}
                                placement={resizedWidth <= sm ? "auto" : "bottomStart"}
                                labelKey="payment_detail_info"
                                menuClassName={dropdownVisibility ? "" : "hide-dropdown"}
                                renderMenuItem={renderMenuItem}
                                renderExtraFooter={() => {
                                    return (
                                        <Button 
                                            appearance="link"
                                            onClick={() => {
                                                handleShowModalAddPaymentDetail();
                                            }}
                                        >
                                            + <FormattedMessage id="paymentDetails.addNew" defaultMessage="Add new"/>
                                        </Button>
                                    )
                                }}
                            />
                        </S.FormGroup>
                    </S.FormItem>
                
                </S.FormRow>

                <Spacer/>

                <S.Field
                    name="comment"
                    accepter={BaseInputField}
                    componentClass="textarea"
                    placeholder={formatMessage(m.comment)}
                />
                
            </Form>
        </S.FormModal>
    )
};