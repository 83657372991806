import {CDR_DIALER as PREFIX} from '../../const';
import {api, getFileResponse} from '../../api/loginRoutes';
import {_setValue} from './defaults';
import {createUrl} from '../../utils/helpers';
import {SET_UPLOAD_LOADING_DIALER} from '../../const';
import {CDR_LIST_DIALER_API} from "../../const/apiMethods";

const setTestCalls = _setValue('SET_ITEMS_' + PREFIX);
const setLoading = _setValue('SET_LOADING_' + PREFIX);
const setUploadLoading = _setValue(SET_UPLOAD_LOADING_DIALER);

export const getDialerCDRList = (per_page = 12, filter = {}, page = 1) => (dispatch) => {
    dispatch( setLoading(true) );
    // api method not found
    api(CDR_LIST_DIALER_API, {per_page, filter, page})
        .then((response) => {
            if (response === undefined) {
                dispatch( setLoading(false) );
                return;
            }
            dispatch(setTestCalls({
                cdr_full_list: response.cdr_full_list,
                cdr_full_count: response.cdr_full_count,
                cdr_full_summaries: response.cdr_full_summaries,
                page,
                per_page
            }));
        })
        .catch(() => {
            dispatch( setLoading(false) );
        });
};

export const exportDialerCDR = (filter = {}) => async (dispatch) => {
    dispatch(setUploadLoading(true));
    await getFileResponse(CDR_LIST_DIALER_API, {filter}).then((response) => createUrl(response, 'cdr.csv'));
    dispatch(setUploadLoading(false));
};
