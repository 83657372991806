import React from 'react';
import Form from 'rsuite/es/Form';
import Text from '../../../../../components/Form/Text';
import FormHOC from '../../../../../hoc/FilltersForm';
import {Schema} from 'rsuite';
import {debounce} from 'lodash';

const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    number: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});


const AccessListFiltersFilter = ({onChange, defaultFilters}) => {
    const handleChange = (formValues) => {
        onChange(formValues)
    };

    return (
        <>
            <Form
                model={filtersModel}
                layout="inline"
                onChange={debounce((value) => {
                    handleChange(value);
                }, 400)}
                formDefaultValue={defaultFilters}
            >
                <Text name="str" placeholder="Origin/origin prefix/destination" style={{display: 'inline-block'}}/>
            </Form>
        </>
    );
};
export default FormHOC (AccessListFiltersFilter, filtersModel,300);