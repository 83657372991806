import styled, { css } from 'styled-components';
import { ControlLabel, FormControl, Toggle } from 'rsuite';
import {CustomModalClient} from "../../../components/base";
import {BaseToggle} from "../../../components/base/BaseToggle";


export const FormModal = styled(CustomModalClient)`
    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;

export const FormColumns = styled.div`
    align-items: stretch;
    display: flex;
`;

export const FormColumn = styled.div`
    flex: 1 1 30%;
    position: relative;
    
    ${props => 
        props.divider &&
        css`
            flex: 0 0 auto;
        `
    }

    @media (min-width: 768px) {
        padding: 0 15px;
    }
`;

export const FormDivider = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;

    .divider__text {
        text-transform: uppercase;
    }

    .divider {
        flex-grow: 1;
        margin: 0;
    }
`;

export const FormTitle = styled.div`
    &&& {
        font-size: 18px;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 20px;
        color: ${props => props.disabled
            ? "var(--color-grey)"
            : "var(--color-secondary)"
        };

        @media (min-width: 768px) {
            font-size: 14px;
            width: 150px;
        }
    }
`;

export const Label = styled(ControlLabel)`
    font-size: 18px;
    padding: 0;   
    text-align: left;
    
    @media (min-width: 768px) {
        font-size: 14px;
    }

    .rtl & {
        text-align: right;
    }
    
    ${props =>
        props.disabled &&
        css`
            color: var(--color-disabled)
        `
    }
`;

export const Field = styled(FormControl)`

    &&& {
        min-width: 0;
        width: 100%;

        ${props => 
            props.componentClass === 'textarea' &&
            css`
                min-height: 200px;
                background: #fff;
                overflow-x: auto;
            `
        }
    }

    ${props =>
        props.disabled &&
        css`
            opacity: var(--value-disabled-opacity)
        `
    }
`;

export const FormToggle = styled(BaseToggle)`
    margin: 0;
`;