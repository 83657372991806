import React from 'react';
import {FormControl, Schema} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import {debounce} from 'lodash';
import {CustomizableForm} from '../../components/client/Form/CustomizableForm';
import {useIntl} from "react-intl";
import {useWindowWidth} from "../../hooks";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import m from "../../definedMessages";
import {BaseInputField} from "../../components/base/BaseForm";

const MIN_LENGTH = 6;
const MAX_LENGTH = 17;

const {StringType} = Schema.Types;

const filtersModel = Schema.Model({
        str: StringType("Please enter a valid string")
            .minLength(MIN_LENGTH, `The minimum of this field is ${MIN_LENGTH} digits`)
            .maxLength(MAX_LENGTH, `The maximum of this field is ${MAX_LENGTH} digits`)
            .isRequired( "This field is required" )
    }
);

export default FormHOC( ({loading, onChangeFilter, savedTableFilter, ...props}) => {
    const {formatMessage} = useIntl();
    const resizedWidth = useWindowWidth();

    const filtersModelInner = Schema.Model({
            str: StringType(formatMessage(m.pleaseEnterValidString))
                .minLength(MIN_LENGTH, formatMessage(m.minLengthDigits, {count: MIN_LENGTH}))
                .maxLength(MAX_LENGTH, formatMessage(m.maxLengthDigits, {count: MAX_LENGTH}))
                .isRequired( formatMessage(m.thisFieldIsRequired) )
        }
    );

    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);

        if (checker) {
            onChangeFilter(data);
        }
        return false;
    };

    return (
        <CustomizableForm
            model={filtersModelInner}
            onChange={debounce((data) => {
                const checkedData = filtersModel.check(data);
                onSubmit(data, checkedData);
            }, 1000)}
            formDefaultValue={savedTableFilter || {str: ''}}
            fluid
            readOnly={loading}
        >
            <FlexGrid align="top">
                <FlexGridItem width="320px">
                    <FormControl
                        name="str"
                        accepter={BaseInputField}
                        errorPlacement="topEnd"
                        placeholder={resizedWidth > 300 ? formatMessage(m.putYourCliMin, {count: 6}) : formatMessage(m.putYourCli)}
                    />
                </FlexGridItem>
            </FlexGrid>
        </CustomizableForm>
    );
}, filtersModel, 500);