import React from 'react';
import TableServerSort from '../../components/Table/TableServerSort';
import {OTP_SERVICE_PLAN_KEY} from './../../const';

export default ({
    accessList = [], 
    getItems, 
    accessListLoading = false, 
    page = 1, 
    count = 0, 
    per_page,
    paymentTermsList,
    spAuth,

    ...props
}) => {

    const columns = [
        {
            label: 'Access origin', 
            dataKey: 'a_subdestination_name', 
            sortable: true,
            spAuth: false,
            flexGrid: 1,
            minWidth: 220
        },
        {
            label: 'CLI', 
            dataKey: 'a_number', 
            sortable: true,
            spAuth: false,
            flexGrid: 1,
            minWidth: 220
        },
        {
            label: 'Access destination', 
            dataKey: 'b_subdestination_name', 
            sortable: true,
            spAuth: false,
            flexGrid: 1,
            minWidth: 220
        },
        {
            label: 'Test number', 
            dataKey: 'b_number', 
            sortable: true,
            spAuth: false,
            flexGrid: 1,
            minWidth: 220
        },
        {
            label: 'Service', 
            value: ({a_service, a_number}) => (
                a_service || a_number 
                    ? `${a_service ? a_service : ""} ${a_number ? `(${a_number})` : ""}`
                    : ""
            ), 
            sortable: true,
            spAuth: true,
            dataKey: "a_service",
            flexGrid: 1,
            minWidth: 140
        },
        {
            label: 'Destination', 
            value: ({b_subdestination_name, b_template}) => (
                b_subdestination_name || b_template 
                    ? `${b_subdestination_name ? b_subdestination_name : ""} ${b_template ? `(${b_template})` : ""}`
                    : ""
            ), 
            sortable: true,
            spAuth: true,
            dataKey: "b_subdestination_name",
            flexGrid: 1,
            minWidth: 140
        },
    ].filter(value => value.spAuth === undefined || value.spAuth === spAuth);

    const paymentColumns = [];

    for (const term of paymentTermsList) {
        paymentColumns.push(
            {
                id: `payment_${term.name}`,
                label: `Payment ${term.name}`,
                value: (({payment_terms_rate_list}) => {
                    if (payment_terms_rate_list) {

                        const termsRate = payment_terms_rate_list
                            ? payment_terms_rate_list.find(item => item.pt_key === term.pt_key)
                            : null;

                        let currency = payment_terms_rate_list
                            ? payment_terms_rate_list.find(value => value.rate !== null)
                            : null;
                        
                        if (currency) {
                            currency = currency.currency_name;
                        }
                        if (termsRate && termsRate.rate && currency) {
                            return `${Number(termsRate.rate).toFixed(4)} ${currency ? currency : ''}`;
                        } else {
                            return '—';
                        }
                    } else {
                        return '—';
                    }
                }),
                width: 140,
                align: 'center'
            });
    }

    if (paymentColumns) {
        for (const column of paymentColumns) {
            columns.push(column);
        }
    }

    return (
        <TableServerSort
            data={accessList}
            loading={accessListLoading}
            columns = {columns}
            count = {count}
            per_page = {per_page}
            page = {page}
            getItems = {getItems}
            ispagination
            {...props}
        />
    )
}

