import React, {useState} from 'react';
import {Alert, Loader, Placeholder} from 'rsuite';
import FormSelfAllocation from './FormSelfAllocation';
import {api} from '../../../api/loginRoutes';
import {withRouter} from 'react-router-dom';

const {Paragraph} = Placeholder;

function SelfAllocationForm(account) {
    this.allocate_number = account.permission_list && account.permission_list.includes('allocate_number');
    this.allocate_pattern = account.permission_list && account.permission_list.includes('allocate_pattern');
    this.google_otp_allocation = account.permission_list && account.permission_list.includes('google_otp_allocation');
    this.google_otp_autorevoke = account.permission_list && account.permission_list.includes('google_otp_autorevoke');
}

const TabSelfAllocation = ({disabled = false, account, getAccount, allocationLimits, defaultAllocationLimits,
                               setAllocationRestrictions, modifiedLimit, setModifiedLimitId, ...props}) => {

    const [loading, setLoading] = useState(false);

    if (props.loading)
        return (
            <>
                <Loader backdrop content="loading..." vertical/>
                <Paragraph rows={5}/>
            </>
        );

    const reloadPage = () => {

        try {
            setLoading(true);

            getAccount(account.id, true, true);
            props.history.replace('/reload');
            setTimeout((location, history) => {
                history.replace({
                    ...location,
                    search: '?tab=self-allocation'
                });
            },
            500,
            props.location, props.history);

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const onCancel = () => {
        props.history.replace('/reload');
        setTimeout((location, history) => {
            history.replace({
                ...location,
                search: '?tab=trunks'
            });
        },
        0,
        props.location, props.history);
    };

    return (
        <FormSelfAllocation
            {...{
                reloadPage, onCancel, defaultAllocationLimits, allocationLimits,
                setAllocationRestrictions, modifiedLimit, setModifiedLimitId
            }}
            formDefaultValue={new SelfAllocationForm(account)}
            account_id={account.id}
            disabled={disabled || loading}
            active={account.active}
        />
    );
};

export default withRouter(TabSelfAllocation);