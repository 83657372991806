import React from "react";
import {Form, Input, Schema} from "rsuite";
import CustomField from "../../../components/Form/CustomField";

const {StringType} = Schema.Types;

const formModel = Schema.Model({
    template_list: StringType()
        .addRule((value) => {
            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ')
                .filter( s => s !== "")
            for (const row of valueList) {
                if (row.length > 15 || row.length < 7)
                    return false
            }
            return true
        }, 'Each row should be greater than 7 and less than 15')
        .addRule((value) => {
            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ').filter( s => s !== "")
            return !((valueList).length > 100)
        }, 'The number of entered telephone numbers exceeds 100 rows')
        .pattern(/^(?: *[0-9]{6,14} *(?:[X]{3,6}\r|[X]{3,6}\n|[X]{3,6}$))+$/, 'Please enter a valid numbers')
        .isRequired('This field is required'),
});

export default React.forwardRef( (props, ref) => {
    return (
        <Form
            layout="inline"
            ref={ref}
            model={formModel}
            method="price_range__generate_by_templates"
        >

            <CustomField
                accepter={Input}
                name="template_list"
                componentClass="textarea"
                wrap="off"
                rows={3}
                style={{width: 400}}
                placeholder="Template list(one per row)"
            />

        </Form>
    );
});