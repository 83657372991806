import React, {useEffect, useState, useMemo} from "react";
import {Button, FormControl, Icon, InputNumber, Schema, Table, Form} from "rsuite";
import styled from "styled-components";
import BaseTable from "../../components/base/BaseTable";
import {countDecimals} from '../../utils'
import ModalGoogleOtpAllocationRemove from './ModalGoogleOtpAllocationRemove'

const {Cell, Column, HeaderCell} = Table;
const {NumberType} = Schema.Types;

const formModel = Schema.Model({
    range_numbers_default: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0')
        .addRule((value) => value % 1 === 0, 'The number must be integer')
        .addRule((value, formValues) => {
            const currentValue =  Number.parseInt(value);
            const compareValue = formValues.range_numbers_max ? Number.parseInt(formValues.range_numbers_max) : null;

            return currentValue <= compareValue;
        }, 'The default number must be lesser than default'),
    range_numbers_max: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0')
        .addRule((value) => value % 1 === 0, 'The number must be integer')
        .addRule((value, formValues) => {
            const currentValue =  Number.parseInt(value);
            const compareValue = formValues.range_numbers_default ? Number.parseInt(formValues.range_numbers_default) : null;

            return  currentValue >= compareValue
        }, 'The max number must be greater than default'),
    autorevoke_hours: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater then 0')
        .addRule((value) => countDecimals(value) <= 1, 'This non-integer number must have maximum of one decimal')
});

const idKey = 'oa_key';

export default ({
        data = [],
        loading,
        filter,
        subdestinationList,
        getGoogleOtpAllocationList,
        modifyGoogleOtpAllocation,
        removeGoogleOtpAllocation,
        ...props
    }) => {

    const columns = useMemo(() => {
        return [
            {
                flexGrow: 2,
                dataKey: 'sde_key',
                dataValue: 'sde_name',
                label: 'Subdestination',
                editable: false
            },
            {
                flexGrow: 1,
                dataKey: 'range_numbers_default',
                label: 'Range number default',
                model: formModel,

                // field props
                placeholder: 'Range number default',
                min: 0,
                max: 1000,
                accepter: InputNumber,
            },
            {
                flexGrow: 1,
                dataKey: 'range_numbers_max',
                label: 'Range number maximum',
                model: formModel,

                // field props
                placeholder: 'Range number maximum',
                min: 0,
                max: 1000,
                accepter: InputNumber,
            },
            {
                flexGrow: 1,
                dataKey: 'autorevoke_hours',
                label: 'Autorevoke hours',
                model: formModel,

                // field props
                placeholder: 'Autorevoke hours',
                min: 0.01,
                max: 1000,
                accepter: InputNumber,
            }
        ]
    }, [subdestinationList]);

    const refs = new Map();

    const [editedRows, setEditedRows] = useState(new Map());
    const [rowsLoading, setRowsLoading] = useState([]);

    const [modalRemoveData, setModalRemoveData] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [removeDataLoading, setRemoveDataLoading] = useState(null);

    const [formErrorState, onChangeFormError] = useState({});
    const [formValueState, onChangeFormValue] = useState({});
    
    useEffect(() => {
        const editedRowsCopy = new Map(editedRows);
        const rowsLoadingCopy = new Set(rowsLoading);
        for (const editedRowData of editedRows) {
            const rowData = data.find(item => item[idKey] === editedRowData[0]);
            if (rowData && JSON.stringify(rowData) !== JSON.stringify(editedRowData[1])) {
                editedRowsCopy.delete(rowData[idKey]);
                rowsLoadingCopy.delete(rowData[idKey]);
            }
        }
        setEditedRows(editedRowsCopy);
        setRowsLoading(Array.from(rowsLoadingCopy));
        data.sort((item) => {
            if (item.sde_key === null) {
                return -1
            } else {
                return 1
            }
        });
    }, [data]);


    const modifyRow = async (rowData) => {
        // check form
        const rowForms = refs.get(rowData[idKey]);
        for ( const form of Object.keys(rowForms) ) {
            if (!rowForms[form].check()) {
                return;
            }
        }

        // get params
        const params = Object.keys(rowForms)
            .reduce( (sum, current) => (
                {...sum, [current]: rowForms[current].getFormValue()[current]}
            ), {[idKey]: rowData[idKey]} );

        // check differences
        const hasDifferences = Object.keys(params)
            .reduce( (sum, current) => {
                if (sum === true)
                    return sum;
                const value = ( rowData[current] !== null && !isNaN(rowData[current]) )
                    ? +params[current]
                    : params[current];
                return rowData[current] !== value;
            }, false );

        setRowLoading(rowData, true);
        if (hasDifferences) {
            params.range_numbers_default = +params.range_numbers_default;
            params.range_numbers_max = +params.range_numbers_max;
            params.autorevoke_hours = +params.autorevoke_hours;
        } else {
            setRowEditing(rowData, false);
            setRowLoading(rowData, false);
            return;
        }

        const res = await modifyGoogleOtpAllocation(params);
        if (!res) {
            setRowLoading(rowData, false);
            return;
        }

        getGoogleOtpAllocationList(filter);
    };


    const deleteRow = async () => {
        setRemoveDataLoading(true);
        const res = await removeGoogleOtpAllocation(modalRemoveData[idKey]);
        if (res) {
            await getGoogleOtpAllocationList(filter);
            setRemoveDataLoading(false);
        }
    };

    const setRowEditing = (rowData, status) => {
        const editedRowsCopy = new Map(editedRows);

        if ( editedRows.has(rowData[idKey]) && !status) {
            editedRowsCopy.delete(rowData[idKey]);
        } else if (!editedRows.has(rowData[idKey] && status)) {
            editedRowsCopy.set(rowData[idKey], rowData)
        }
        setEditedRows(editedRowsCopy);
    };


    const setRowLoading = (rowData, status) => {
        if (!status) {
            setRowsLoading( rowsLoading.filter(item => item !== rowData[idKey]) );
            return;
        }
        setRowsLoading([...rowsLoading, rowData[idKey]]);
    };

    const updateFormErrorState = (value) => {
        const checkedFormData = formModel.check(value);
        const existErrorData = {
            ...formErrorState,
            ...Object.keys(checkedFormData).reduce((result, dataKey) => {
                if (checkedFormData[dataKey].hasError && value.hasOwnProperty(dataKey)) {
                    result[dataKey] = checkedFormData[dataKey].errorMessage
                } else {
                    result[dataKey] = null
                }
                return result
            }, formErrorState)
        }

        onChangeFormError(existErrorData)
    };

    return (
        <>
            <StyledTable
                className={'tableFilters'}
                shouldUpdateScroll={false}
                headerHeight={46}
                rowHeight={46}
                autoHeight
                {...{
                    data,
                    loading
                }}
                {...props}
            >
                {columns.map(({dataKey, dataValue, flexGrow = null, minWidth = 130, model, label = "", type, value, editable = true, ...fieldProps}) => (
                    <Column {...{flexGrow, minWidth}}>
                        <HeaderCell>
                            <span className="tableFilters__headerText">{label}</span>
                        </HeaderCell>
                        <Cell {...{dataKey}}>
                            {(rowData) => {
                                if (editedRows.has(rowData[idKey]) && editable) {
                                    return (
                                        <Form
                                            ref={ref => {
                                                if (ref) {
                                                    if (refs.has(rowData[idKey])) {
                                                        const currentRefsCopy = refs.get(rowData[idKey]);
                                                        refs.set(rowData[idKey], {
                                                            ...currentRefsCopy,
                                                            [dataKey]: ref
                                                        });
                                                        return;
                                                    }
                                                    refs.set(rowData[idKey], {[dataKey]: ref});
                                                }
                                            }}
                                            formError={formErrorState}
                                            formDefaultValue={{[dataKey]: rowData[dataKey]}}
                                        >
                                            <EditField
                                                name={dataKey}
                                                {...fieldProps}
                                                onChange={(value) => {
                                                    const formValue = {...formValueState, [dataKey]: value};
                                                    updateFormErrorState(formValue);
                                                    onChangeFormValue(formValue);
                                                }}
                                            />
                                        </Form>
                                    );
                                }

                                return <span className="tableFilters__previewText">{rowData[dataValue || dataKey]}</span>
                            }}
                        </Cell>
                    </Column>
                ))}

                <Column width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => {
                            const rowLoading = rowsLoading.includes(rowData[idKey]);
                            const defaultItem = rowData.sde_key === null;

                            return <div className="tableFilters_buttons">
                                {!editedRows.has(rowData[idKey])
                                    ? <Button
                                        size="sm"
                                        color="lightblue"
                                        disabled={rowLoading}
                                        onClick={() => setRowEditing(rowData, true)}
                                    >
                                        <Icon icon="edit2"/>
                                    </Button>
                                    : <>
                                        <Button
                                            size="sm"
                                            color="green"
                                            disabled={rowLoading}
                                            onClick={() => modifyRow(rowData)}
                                        >
                                            <Icon icon="check-circle"/>
                                        </Button>

                                        <Button
                                            size="sm"
                                            color="red"
                                            disabled={rowLoading}
                                            onClick={() => setRowEditing(rowData, false)}
                                        >
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                }

                                <Button
                                    size="sm"
                                    color="red"
                                    disabled={rowLoading || defaultItem}
                                    onClick={() => {
                                        setModalRemoveData(rowData);
                                        setShowRemoveModal(true);
                                    }}
                                >
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        }}
                    </Cell>
                </Column>

            </StyledTable>

            <ModalGoogleOtpAllocationRemove
                show={showRemoveModal}
                onSubmit={async () => {
                    await deleteRow()
                    setShowRemoveModal(false)
                }}
                onClose={() => setShowRemoveModal(false)}
                disabled={removeDataLoading}
            />
        </>
    )
}

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;

const EditField = styled(FormControl).attrs(() => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
    cleanable: true,
    searchable: true,
}))`
`;
