import React, {useRef, useState, useMemo} from "react";
import {Button, CheckPicker, Form, FormControl, Input, Schema, SelectPicker} from "rsuite";
import styled from "styled-components";
import Icon from "rsuite/es/Icon";

const {StringType, NumberType, ArrayType} = Schema.Types;

const formModel = Schema.Model({
    email: StringType()
        .isRequired('Required')
        .isEmail('Please enter an email address')
        .maxLength(256, 'The maximum of this field is 256 characters'),
    name: StringType()
        .isRequired('Required')
        .maxLength(256, 'The maximum of this field is 256 characters'),
    account_user_teamlead_id: StringType()
        .isRequired('Required'),
    account_user_manager_id_list: ArrayType()
        .isRequired('Required'),
    billing_acm_key: NumberType()
        .isRequired('Required'),
    skype: StringType()
        .isRequired('Required')
});

export default ({
    teamLeadList,
    billingList,
    managersList,
    userList,
    onSubmit,
    getAccountManagersList,
}) => {

    const formRef = useRef(null);
    const [createAmLoading, setCreateAmLoading] = useState(false);
    const disabledBillingList = useMemo(() => (
        billingList
            .filter(billingAM => managersList.find(manager => manager.billing_acm_key === billingAM.ACM_KEY))
            .map(item => item.ACM_KEY)
    ), [billingList, managersList]);

    const handleSubmit = () => {
        if (!formRef.current.check())
            return;

        setCreateAmLoading(true);

        const formValue = formRef.current.getFormValue();
        onSubmit(formValue)
            .then(res => {
                setCreateAmLoading(false);
                if (res) {
                    getAccountManagersList()
                }
            });
    };

    return (
        <StyledForm
            ref={formRef}
            model={formModel}
            onSubmit={handleSubmit}
        >
            <StyledFieldWrapper>
                <StyledField
                    name="name"
                    accepter={Input}
                    placeholder="Name"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="email"
                    accepter={Input}
                    placeholder="Email"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="account_user_teamlead_id"
                    accepter={SelectPicker}
                    data={teamLeadList}
                    placeholder="Team Lead"
                    valueKey="account_user_id"
                    labelKey="account_user_name"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="billing_acm_key"
                    accepter={SelectPicker}
                    data={billingList}
                    disabledItemValues={disabledBillingList}
                    placeholder="AM from Billing"
                    valueKey="ACM_KEY"
                    labelKey="ACM_NAME"
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    accepter={CheckPicker}
                    data={userList}
                    valueKey="account_user_id"
                    labelKey="account_user_name"
                    name="account_user_manager_id_list"
                    placeholder={"Users"}
                    sticky
                />
            </StyledFieldWrapper>

            <StyledFieldWrapper>
                <StyledField
                    name="skype"
                    accepter={Input}
                    placeholder="Skype"
                />
            </StyledFieldWrapper>

            <StyledSubmitWrapper>
                <Button
                    className="tableFilters_submit"
                    color="green"
                    type="submit"
                    loading={createAmLoading}
                >
                    <Icon icon="plus"/>
                </Button>
            </StyledSubmitWrapper>
        </StyledForm>
    )
};

const StyledField = styled(FormControl).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    placement: "autoVerticalStart",
    type: "text",
    cleanable: true,
    searchable: false,
}))`
`;

const StyledFieldWrapper = styled.div`
    flex: 1 0 200px;
    padding: 5px 10px;
    width: 200px;
`;
const StyledSubmitWrapper = styled.div`
    padding: 5px 10px;
    width: 174px;
`;

const StyledForm = styled(Form)`
    display: flex;
`;