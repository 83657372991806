import React from 'react';
import {ApiRequest, API_Modal} from '../../components'
import {Alert} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonPrimary} from "../../components/base/BaseButton";

export default ({target, onUpdate}) => {
    const [show, setShow] = React.useState(false);

    return (
        <>
            <ButtonPrimary
                onClick={() => setShow(true)}
            >
                <Icon icon="unlock" style={{marginRight: 5}}/> Open Account
            </ButtonPrimary>

            {show &&
                <API_Modal
                    title="Open Account"
                    onClose={() => setShow(false)}

                >
                    <ApiRequest
                        method="account:restore"
                        target={target}
                        checkResultKey="account"
                        update={() => {
                            onUpdate();
                            Alert.success(`Account was opened`)
                        }}
                    >
                        <p>You are sure?</p>
                    </ApiRequest>
                </API_Modal>
            }
        </>
    );
}