import React from 'react';
import { connect } from 'react-redux';
import { Select } from '../../index';
import CustomField from '../../Form/CustomField';

export const SDE_Component = ({subdestination_list, ...props}) => (
    <CustomField
        accepter={Select}
        data={subdestination_list}
        labelKey="name"
        valueKey="sde_key"
        placeholder="Subdestination"
        name="sde_key"
        classPrefix="allocated"
        {...props}
    />  
)

const mapState = ( {references} )=> ({
    subdestination_list: references.subdestination_list
});

export const SDE = connect( mapState, {})(SDE_Component); 
export default SDE;