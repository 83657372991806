import React, {useContext, useState} from 'react';
import {renderColumn} from '../../utils/helpers';
import CustomizableTable from '../../components/client/CustomizableTable/CustomizableTable';
import FormHOC from '../../hoc/FilltersForm';
import {RtlContext} from "../../App";

export default FormHOC(({
    data,
    loading,
    count,
    page,
    per_page,
    pickedColumns,
    savedTableColumns,
    savedPerPage,
    onChangePerPage,
    onChangePage,
    onSaveColumnsTable,
    ...props
}) => {

    const [columns, onChangeColumns] = useState(savedTableColumns && savedTableColumns.length ? pickedColumns.filter(elem => savedTableColumns.includes(elem.id)) : pickedColumns);
    const rtl = useContext(RtlContext);

    const renderedColumns = columns.map(renderColumn);
    return (
        <CustomizableTable
            {...{
                rtl,
                data,
                count,
                page,
                per_page,
                loading,
                pickedColumns,
                savedPerPage,
                onChangePage,
                onChangePerPage,
                renderedColumns,
                onChangeColumns,
                onSaveColumnsTable,
                savedTableColumns,
                ...props
            }}
            isChangedColumns={true}
            isPaginated={true}
            rowClassName={rowData => {
                if (rowData && rowData.is_test_call) {
                    return "rs-table-row rs-table-row--test-call-highlighted";
                }

                return "";
            }}
        />
    );
});

