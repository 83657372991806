import {
    DEFAULT_AUTH_PATH_ADMIN,
    DEFAULT_NOT_AUTH_PATH,
    SET_AUTH,
    SET_LOADING_INFO,
    SET_USER_LOGIN_INFO,
    SET_MAINTENANCE_MODE,
    SET_USER_LOCALIZATION,
    APP_TYPE_DEFAULT,
    APP_TYPE_CLIENT, APP_TYPE_ADMIN, DEFAULT_AUTH_PATH_CLIENT_TEST, DEFAULT_AUTH_PATH_CLIENT
} from '../const';
import {DEFAULT_ERROR_MESSAGE, SET_INFO_ACCOUNT} from '../const';
import {login, logout} from '../api/auth';
import {createAction} from  './defaults';
import { setRoles } from './index';
import history from '../config/history';
import {NEED_SECOND_AUTH, SET_PERMISSION, SET_PERMISSION_ADD} from "../actions/actionTypes";
import {api} from "../api/loginRoutes";
import {Alert} from 'rsuite';
import _ from 'lodash';
import {LOCAL_STORAGE_USER_INFO} from '../const/localStorageKeys';


export const setAuth = (payload, appType = false) => ({type: SET_AUTH, payload, appType});
const setLoading = createAction(SET_LOADING_INFO);
const setInfoAccount = createAction(SET_INFO_ACCOUNT);
const setUserLoginInfo = createAction(SET_USER_LOGIN_INFO);
const setMaintenanceModeAction = createAction(SET_MAINTENANCE_MODE);
const setSecondAuthType = createAction(NEED_SECOND_AUTH);
const setUserLocals = createAction(SET_USER_LOCALIZATION);


export const setMaintenanceMode = (value) => (dispatch) => {
    dispatch( setMaintenanceModeAction(value) );
    if (value) {
        localStorage.removeItem('api_key');
        localStorage.removeItem(LOCAL_STORAGE_USER_INFO);
        dispatch( setAuth(false) );
        dispatch( setInfoAccount(null) );
    }
};


export const loginUser = ({lang = "en", ...data}) => (dispatch) => {
    dispatch(setLoading(true));
    return login(data)
        .then((response) => {

            if (!response) {
                return;
            }

            const result = _.get(response, 'data.result', {});
            const secondAuthType = result.second_authentication_type;

            if (secondAuthType) {
                dispatch(setSecondAuthType({
                    secondAuthType: secondAuthType === 1
                        ? 'email'
                        : 'phone',
                    secondAuthLink: secondAuthType === 1
                        ? result.email
                        : result.phone,
                    secondAuthEmail: result.email
                }));
                return;
            }

            const sessionSite = result.session.site;
            const sessionLang = sessionSite === APP_TYPE_ADMIN ? 'en' : lang;
            const sessionLRtl = sessionLang === "ar";
            const isTest = result.session.account.is_test;

            // save data in LocalStorage
            localStorage.setItem('lang', sessionLang);
            localStorage.setItem('rtl', sessionLRtl);
            localStorage.setItem('api_key', result.session.session_key);
            localStorage.setItem('userInfo', JSON.stringify(result));

            dispatch( setAuth(true, sessionSite) );
            dispatch( setRoles(result.session.account_user.role_list) );
            dispatch( setUserLoginInfo(result) );
            dispatch( setUserLocals({lang: sessionLang, rtl: sessionLRtl}) );

            if (sessionSite === APP_TYPE_ADMIN) {
                history.push(DEFAULT_AUTH_PATH_ADMIN);
                return;
            }

            if (isTest) {
                history.push(DEFAULT_AUTH_PATH_CLIENT_TEST);
                return;
            }

            history.push(DEFAULT_AUTH_PATH_CLIENT);
            return response;

        })
        .catch((error) => {

            dispatch(setAuth(false));

            const reasonCode = _.get(error, 'response.data.error.reason_code', null);
            if (!reasonCode) {
                Alert.error(_.get(error, 'response.data.error.data.error_type', DEFAULT_ERROR_MESSAGE));
            }

            return error;
            // return Promise.reject(error);

        })
        .finally(() => {
            dispatch(setLoading(false));
        })
};


export const getPermission = () => (dispatch) => {
    api('permitted_methods').then((resp) => {
        dispatch({
            type: SET_PERMISSION,
            payload: resp
        })
    });
    api('permitted_methods_add').then((resp) => {
       dispatch({
           type: SET_PERMISSION_ADD,
           payload: resp
       })
    })
};


export const logoutUser = () => async (dispatch) => {
    await logout();

    localStorage.removeItem('api_key');
    localStorage.removeItem(LOCAL_STORAGE_USER_INFO);
    dispatch( setAuth(false) );
    dispatch( setInfoAccount(null) );

    history.push(DEFAULT_NOT_AUTH_PATH);
};


export const ping = () => async (dispatch) => {
    const res = await api('ping');

    if (res instanceof Error || !res.ip)
        return false;

    dispatch( setMaintenanceModeAction(false) );
    return true;
};