import React from "react";
import {List, Panel} from "rsuite";
import styled from 'styled-components';


export default ({count, numbers = []}) => {
    return (
        <div style={{paddingBottom: '20px'}}>
            <StyledPanel
                header={`${count} numbers were rejected`}
                collapsible
                bordered
            >
                <StyledList size='sm' hover>
                    {numbers.map( (item, index) => (
                        <List.Item key={index} index={index}>
                            {item.number} number is busy
                        </List.Item>
                    ))}
                </StyledList>
            </StyledPanel>
        </div>
    )
};


const StyledList = styled(List)`
    width: 100%;
`;

const StyledPanel = styled(Panel)`
    .rs-panel-body {
        box-shadow: none;
    }
    
    .rs-panel-heading {
        font-size: 14px;
        line-height: 1.5em;
        padding-bottom: 10px;
        padding-top: 10px;
        
        &:before {
            top: 10px !important;
        }   
    }
`;