import React from 'react';
import { connect } from 'react-redux';
import { Select } from '../../index';
import CustomField from '../../Form/CustomField';

const DE = ({destination_list, ...props}) => (
    <CustomField
        accepter={Select}
        data={destination_list}
        labelKey="name"
        valueKey="de_key"
        placeholder="Destination"
        name="de_key"
        classPrefix="allocated"
        {...props}
    />
)

const mapState = ( {references} )=> ({
    destination_list: references.destination_list
});

export default connect( mapState, {})(DE);
