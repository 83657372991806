export class ObjectSet {

    constructor() {
        this._set = new Set();
        this[Symbol.iterator] = this._set.values();
    }


    add(item) {
        const strItem = JSON.stringify(item);
        for(let _item of this._set){
            if(JSON.stringify(_item) === strItem){
                return _item;
            }
        }
        return this._set.add(item)
    }

    has(item){
        const strItem = JSON.stringify(item);
        for(let _item of this._set){
            if(JSON.stringify(_item) === strItem)
                return true; 
        }
        return false;
    }

    values() {
        return this._set.values();
    }

    delete(item) {
        const strItem = JSON.stringify(item);
        for(let _item of this._set){
            if(JSON.stringify(_item) === strItem){
                return this._set.delete(_item)
            } 
        }
    }
    clear() {
        this._set.clear()
    }
}
