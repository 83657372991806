import React from 'react';
import MyForm from './../../../../../components/MyForm';
import Text from '../../../../../components/Form/Text';
import NumberField from '../../../../../components/Form/NumberField';
import {Form, FormControl, FormGroup, Icon, Schema} from 'rsuite/es';
import {MIN_RATE_VALUE, valueAllocationLimit} from 'const';
import {getFileResponse} from 'api/loginRoutes';
import {getBlobContent} from "../../../../../utils";

const {NumberType} = Schema.Types;

export default ({
    activeTab, onSelect, target, showResult, setHideBufferButtons, activeTrunk,
    allocationLimit, setSummaryState, setTransactionId, setTotalNumbersForced, setCsvContent,
    setAllocateByPrefixesStatus, setNotAllocatedNumbers, ...props
}) => {

    const [valueStartNumber, setValueStartNumber] = React.useState('');
    const [valueForm, setValueForm] = React.useState({numbers: 1});
    const [showRateInput, onChangeShowRateInput] = React.useState(null);
    const [customRateValue, onChangeCustomRateValue] = React.useState(null);

    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    const model = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
            .max(10, 'The maximum of this field is 10'),
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .addRule((value, data) => {
                if (value > valueAllocationLimit) {
                    return false
                }
                return true;
            }, `The maximum of this field is ${valueAllocationLimit}`),
    };


    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            getFileResponse("trunk_number:get_list", {target: {trunk_number_transaction_id: transactionId}}, true)
                .then((response) => {
                    const csvText =  getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    })
                });

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);
            setAllocateByPrefixesStatus(false);
            setHideBufferButtons(false);
            setNotAllocatedNumbers(not_allocated_numbers);
            showResult();
        }
    };

    return (
        <>
            <MyForm
                activeTrunk={activeTrunk}
                target={target}
                method="allocation:number_sequence"
                checkResultKey='price_range_number_list'
                update={handleUpdateNew}
                {...props}
                allocateByPrefixesStatus={null}
                className="numberForm"
                onChange={handleFormChange}
                formValue={valueForm}
                addData={{
                    rate: customRateValue !== null ? +customRateValue : null
                }}
                model={model}

                deepCloned={true}
            >

                <Text
                    name="start_number"
                    validationKey={null}
                    label="First number" width="md"
                    onChange={(value) => {
                        setValueStartNumber(value);
                    }}
                    value={valueStartNumber}
                    placeholder="1234567890"
                />

                <NumberField name="numbers" label="Amount" min={1} max={valueAllocationLimit}/>

                <br/>

                {/* Show Rate form */}
                {!showRateInput

                    ? <div
                        style={{cursor: 'pointer', margin: '8px 20px 0 0', display: 'inline-block'}}
                        onClick={() => {onChangeShowRateInput(true)}}
                    >
                        <Icon icon="book"
                              inverse
                              style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'}}
                        />
                        <a style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d',}}>Put custom rate</a>
                    </div>
                    : <>
                        <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>Rate</div>
                        <div style={{display: 'inline-block', marginRight: '10px'}}>
                            <FormControl
                                style={{width: 140}}
                                errorPlacement="bottomStart"
                                placeholder="0.0001"
                                name="rate"
                                value={customRateValue}
                                onChange={(value) => {
                                    onChangeCustomRateValue(value);
                                }}
                            />
                        </div>
                        <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>
                            <Icon
                                icon="close"
                                inverse
                                style={{
                                    color: '#5e2f9d',
                                    marginRight: '5px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    onChangeShowRateInput(false);
                                    onChangeCustomRateValue(null)
                                }}
                            />
                        </div>
                    </>
                }

            </MyForm>
        </>
    );
};