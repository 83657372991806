import React from 'react';
import {ButtonToolbar, ButtonDefault} from '../../base/BaseButton';
import * as S from './styled';

const Speaker = ({
    successText = "Yes",
    cancelText = "No, only on this page",
    onSuccess = () => {}, 
    onCancel = () => {},
    rtl = false
}) => {
    
    return (
        <S.SpeakerContainer rtl={rtl}>
            <ButtonToolbar>
                <ButtonDefault appearance="default" onClick ={onSuccess}>{successText}</ButtonDefault>
                <ButtonDefault appearance="default" onClick ={onCancel} >{cancelText}</ButtonDefault>
            </ButtonToolbar>
        </S.SpeakerContainer>
    )
};

export default Speaker