import React, {useEffect} from 'react';
import {Container, Content, FlexboxGrid, Header} from "rsuite";
import Menu from "components/Navigation/Navigation";
import NumberAllocation from "../pages/NumberAllocation";
import ErrorBoundary from "./Error";
import {connect} from "react-redux";
import {
    getRealtimePaymentStatus,
    modifyAccountRequest,
    openAllocationModal,
} from '../actions'
import {logoutUser} from "../actions/auth";
import VersionLabel from "components/VersionLabel/VersionLabel";

import {pageIdLogObject} from "../utils/pageIdLogger";
import {useRouteMatch} from "react-router";
import {useWindowWidth} from "../hooks";
import {getRegistrationCount} from "../actions/registration";
import {APP_TYPE_CLIENT, APP_TYPE_DEFAULT} from "../const";
import SkypeWidget from "./SkypeWidget";


const Layout = ({
    showModalInterface,
    showModalColor,
    logoutUser,
    openAllocationModal,
    method_list,
    modifyAccountRequest,
    modifyPasswordRequest,
    getRegistrationCount,
    getRealtimePaymentStatus,
    accountInfo,
    error,
    roles,
    errorMessage,

    lang,
    rtl,

    ...props
}) => {

    const windowWidth = useWindowWidth();
    const routeMatch = useRouteMatch();

    // set logger
    pageIdLogObject.pageMatch = routeMatch;

    // set rtl DOM
    useEffect(() => {
        if (APP_TYPE_DEFAULT !== APP_TYPE_CLIENT) {
            return;
        }

        if ( rtl && !["/login", "/reset-password"].includes(routeMatch.path) ) {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

    }, [rtl, lang, routeMatch]);

    return (
        <Container style={{minHeight: '100vh'}}>
            <Header>
                <Menu {...{
                    logoutUser,
                    openAllocationModal,
                    method_list,
                    modifyAccountRequest,
                    modifyPasswordRequest,
                    getRegistrationCount,
                    getRealtimePaymentStatus,
                    accountInfo,
                    error,
                    roles,
                    errorMessage
                }}/>
                <NumberAllocation/>
            </Header>
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={windowWidth < 768 ? 24 : 22}>
                        <ErrorBoundary>
                            {props.children}
                        </ErrorBoundary>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            
            <VersionLabel/>
            <SkypeWidget/>
            {/* <Footer>Footer</Footer> */}
        </Container>
    );
}

const mapState = ( {auth, references, accounts, roles} )=> ({
    auth: auth.auth,
    lang: auth.lang,
    rtl: auth.rtl,
    roles: roles.role_list,
    method_list: references.method_list,
    accountInfo: accounts.accountInfo,
    error: accounts.editError,
    errorMessage: accounts.editErrorMessage
});

export default connect(mapState, {
    openAllocationModal,
    logoutUser,
    modifyAccountRequest,
    getRegistrationCount,
    getRealtimePaymentStatus
})(Layout);