import React, {useRef, useState} from 'react';
import FormHOC from '../../hoc/FilltersForm';
import ReactJson from 'react-json-view';
import {List, Alert, FlexboxGrid, Message, Icon, Divider, Affix} from 'rsuite';
import {NavHashLink as NavLink} from 'react-router-hash-link';
import ApiCurrentApiKey from './ApiCurrentApiKey';
import styled from 'styled-components';

import {SCREEN_MEDIA} from '../../const';

const {md} = SCREEN_MEDIA;

export default FormHOC(({pickedApiKey, newItem, methods = []}) => {
    const refTextAreaObj = {};

    const api_key = pickedApiKey || newItem || '{YOUR_API_KEY}';

    const [topAnchorVisible, onChangeFixedTopAnchor] = useState(false);
    return (
        <>
            <div className="top-mark" id="top"/>
            <span className="api_documentation">API Documentation</span>
            <ApiCurrentApiKey apiKey={api_key}/>
            <Affix
                onChange={(fixed) => {
                    onChangeFixedTopAnchor(fixed)
                }}
                className="api_documentation-anchor"
            >
                {topAnchorVisible && <NavLink className="api_documentation_anchor-link" to="#top">To the top <Icon icon="sequence-up"/></NavLink>}
            </Affix>

            <BriefList bordered>
                {methods.map(method => {
                    return (
                        <List.Item className="briefList__item">
                            <NavLink
                                className="briefList__method"
                                to={`#${method.method_api}`}
                                scroll={el => el.scrollIntoView({
                                    behaviour: 'smooth',
                                    block: 'start',
                                    inline: 'center'
                                })}
                            >
                                {method.method_api}
                            </NavLink>
                            <Divider vertical className="briefList__divider"/>
                            <p className="briefList__desc">{method.description}</p>
                        </List.Item>
                    )
                })}
            </BriefList>
            
            <List className="api_documentation_list">
                {
                    methods.map(method => {
                        const baseURL = process.env.REACT_APP_API_URL;
                        console.log('baseURL', baseURL)
                        const api_link = `${baseURL}${method.csv ? '/csv' : ''}`;
                        const copy_link = `${api_link}?method=${method.method_api}&api_key=${api_key}`;

                        refTextAreaObj[copy_link] = useRef(null);
                        const group = method['group'];

                        return <List.Item className="api_documentation_list-item" id={method.method_api}>
                            
                            <FlexboxGrid justify="center">
                                <FlexboxGrid.Item>
                                    <span className="api_documentation_list-group" id={`${method.id ? method.id : ''}`}>{group}</span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid className="api_documentation_list-method_http">
                                <FlexboxGrid.Item>
                                    <span>{method.method_http}</span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid className="api_documentation_list-method_api">
                                <FlexboxGrid.Item>
                                    <span className="api_documentation_list-method_api_header">METHOD:</span><span
                                    className="api_documentation_list-method_api_src">{method.method_api} {method.csv ? 'CSV' : ''}</span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            
                            <FlexboxGrid className="api_documentation_list-method_api_link">
                                <FlexboxGrid.Item>
                                    <Message
                                        className="api_documentation_list-method_api_link_wrapper"
                                        appereace="ghost"
                                        title={
                                            <>
                                                <span className="api_documentation_list-method_api_link_wrapper_header">
                                                    API Link
                                                </span>
                                                <Icon
                                                    className="api_documentation_list-method_api_link_wrapper_icon"
                                                    onClick={() => {
                                                        refTextAreaObj[copy_link].current.select();
                                                        document.execCommand('copy');
                                                        Alert.info('URL has been copied');
                                                    }}
                                                    icon="clone"
                                                />
                                            </>
                                        }
                                        description={<div>{api_link}?method=<span style={{
                                            fontStyle: 'oblique',
                                            fontWeight: 600
                                        }}>{method.method_api}</span>&api_key={api_key}</div>}
                                    />
                                    <textarea
                                        ref={refTextAreaObj[copy_link]}
                                        value={copy_link}
                                        style={{
                                            width: '0',
                                            height: '0',
                                            resize: 'none',
                                            border: '0',
                                            position: 'absolute',
                                            opacity: 0
                                        }}
                                        readOnly
                                    />
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <span className="api_documentation_list-description_header">Description:</span>
                            <FlexboxGrid className="api_documentation_list-description">
                                <FlexboxGrid.Item>
                                    <span>{method.description}</span>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <span className="api_documentation_list-parameters_header">Parameters:</span>
                            <FlexboxGrid className="api_documentation_list-parameters">
                                <FlexboxGrid.Item className="api_documentation_list-parameters_item">
                                    {
                                        method.params && Array.isArray(method.params)
                                            ? <List className="api_documentation_list-parameters_list" bordered>
                                                {
                                                    method.params.map(param => {
                                                        const key = Object.keys(param).length !== 0 ? Object.keys(param)[0] : null;
                                                        const paramProps = param && key && param.hasOwnProperty(key) ? param[key] : {};
                                                        const cloneParams = Object.assign({}, paramProps);
                                                        const description = paramProps['description'];
                                                        const required = (method.required ? method.required.includes(key) : false).toString();
                                                        delete cloneParams.description;

                                                        return <List.Item
                                                            className="api_documentation_list-parameters_list_item">
                                                            <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item header">Name:</FlexboxGrid.Item>
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item">{key}</FlexboxGrid.Item>
                                                            </FlexboxGrid>
                                                            <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item header">Description:</FlexboxGrid.Item>
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item">{description}</FlexboxGrid.Item>
                                                            </FlexboxGrid>
                                                            <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item header">Type:</FlexboxGrid.Item>
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item">
                                                                    {!cloneParams.type ? 'Object' : cloneParams.type}
                                                                </FlexboxGrid.Item>
                                                            </FlexboxGrid>
                                                            <FlexboxGrid align="middle" className="api_documentation_list-parameters_list_item_wrapper">
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item header">Required:</FlexboxGrid.Item>
                                                                <FlexboxGrid.Item
                                                                    className="api_documentation_list-parameters_list_item_wrapper_item">{required}</FlexboxGrid.Item>
                                                            </FlexboxGrid>
                                                            <div className="api_documentation_list-parameters_list_item_wrapper">
                                                                <div className="api_documentation_list-parameters_list_item_wrapper_item header">Structure:
                                                                </div>
                                                            </div>
                                                            <ReactJson
                                                                src={cloneParams}
                                                                name={key}
                                                                displayDataTypes={false}
                                                                displayObjectSize={false}
                                                            />

                                                        </List.Item>;
                                                    })
                                                }
                                            </List>
                                            : <span style={{color: 'var(--cancel-bg)'}}>
                                                {method.params ? method.params : 'Additional parameters are not available'}
                                            </span>
                                    }
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>;
                    })
                }
            </List>
        </>
    );
});


const BriefList = styled(List)`
    margin-top: 20px;

    & .briefList__item {
        padding-top: 7px;
        padding-bottom: 7px;

        @media (min-width: ${md.min}px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    
    .rs-list-item-content {
        min-height: 40px;
        
        @media (min-width: ${md.min}px) {
            align-items: center;
            display: flex;
        }
    }

    .briefList__method {
        color: var(--activate-bg) !important;
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        padding-bottom: 4px;
        padding-top: 4px;
        word-break: break-word;

        @media (min-width: ${md.min}px) {
            min-width: 300px;
        }
    }

    .briefList__desc {
        padding-bottom: 4px;
        padding-top: 4px;
        padding-left: 20px;

        .rtl & {
            padding-left: 0;
            padding-right: 20px;
        }
    }

    .briefList__divider {
        display: none;
        align-self: stretch;
        flex-shrink: 0;
        height: auto;

        @media (min-width: ${md.min}px) {
            display: block;
        }
    }
`;