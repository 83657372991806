import React, {useState} from "react";
import {Table} from 'rsuite';
import {Form, Schema, SelectPicker, FormControl, Input, InputNumber, Button} from "rsuite";
import NumberField from "../../../../../components/Form/NumberField";
import Icon from "rsuite/es/Icon";
import BaseTable from "../../../../../components/base/BaseTable";

const {Column, HeaderCell, Cell} = Table;

const FormAddFilter = React.memo( ({defaultFilter, ...props}) => {

    const form = React.createRef();
    const account_id = props.accountId;

    const defaultValues = {rate_min: 0};
    const [fields, setFields] = useState(defaultValues);

    const {StringType, NumberType} = Schema.Types;
    const formModel = Schema.Model({
        a_sde_key: NumberType(),
        a_prefix: StringType()
            .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
            .minLength(6, 'The minimum is 6 characters.')
            .maxLength(15, "The minimum is 15 characters"),
        b_sde_key: NumberType(),
        rate_min: NumberType(),
        rate_min2: NumberType(),
    });

    const formControlItems = [
        <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="a_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Origin zone" valueKey="wz_key" labelKey="name" type="text" />,
        <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="a_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Origin country" valueKey="de_key" labelKey="name" type="text" />,
        <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="a_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Origin operator" valueKey="sde_key" labelKey="name" type="text" />,
        <FormControl errorPlacement="topEnd" className="tableFilters_field" name="a_prefix" accepter={Input} placeholder="Origin Prefix" type="text" />,
        <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="b_wz_key" accepter={SelectPicker} data={props.worldzoneList} placeholder="Desctination zone" valueKey="wz_key" labelKey="name" type="text" />,
        <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="b_de_key" accepter={SelectPicker} data={props.destinationList} placeholder="Destination country" valueKey="de_key" labelKey="name" />,
        <FormControl placement={"autoVerticalStart"} errorPlacement="topEnd" className="tableFilters_field" name="b_sde_key" accepter={SelectPicker} data={props.subdestinationList} placeholder="Destination operator" valueKey="sde_key" labelKey="name" type="text" />,
        <FormControl errorPlacement="topEnd" className="tableFilters_field" name="rate_min" accepter={InputNumber} placeholder="Minimal payout" step={0.1} min={0} />,
    ];

    const handleChange = (formValues) => {
        setFields(formValues)
    };

    const handleSubmit = async (_, event) => {
        event.preventDefault();

        let validStatus = form.current.check();

        if (!validStatus)
            return;

        let formValues = form.current.getFormValue();
        let data = {
            ...formValues,
            rate_min: +formValues.rate_min
        };

        if (!data.a_prefix && data.a_prefix !== undefined) {
            delete data.a_prefix;
        }

        props.addItem(account_id, data);

    };

    const formControlNames = ["a_wz_key", "a_de_key", "a_sde_key", "a_prefix", "b_wz_key", "b_de_key", "b_sde_key", "rate_min"];

    const columns = formControlNames.map( key => {
        return {dataKey: key, label: ''}
    } );

    const tableColumnsElements = {};
    for (let i = 0; i < formControlNames.length; i++) {
        tableColumnsElements[formControlNames[i]] = formControlItems[i];
    }

    const columnsElements = formControlNames.map( (item) => {
        return (
            <Column flexGrow>
                <HeaderCell></HeaderCell>
                <Cell dataKey={item}/>
            </Column>
        )
    } );

    return(
        <>
            <Form
                onSubmit={handleSubmit}
                ref={form}
                model={formModel}
                onChange={handleChange}
                formDefaultValue={fields}
                style={{margin: '0px', width: '100%'}}
            >

                <BaseTable
                    data={[tableColumnsElements]}
                    loading={false}
                    columns={columns}
                    headerHeight={0}
                    autoHeight
                    rowHeight={46}
                    className="tableFilters tableFilters__form"
                >
                    {columnsElements}
                    <Column width={174}>
                        <HeaderCell></HeaderCell>
                        <Cell>
                            {rowData => (
                                <Button className="tableFilters_submit" color="green" type="submit"><Icon icon="plus"/></Button>
                            )}
                        </Cell>
                    </Column>
                </BaseTable>

            </Form>
        </>
    )
} );

export default FormAddFilter