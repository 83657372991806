import React, { useEffect } from 'react';
import MyForm from './../../../../../components/MyForm';
import Checkbox from '../../../../../hoc/Checkbox';
import Text from '../../../../../components/Form/Text';
import SDE from '../../../../../components/Filters/SDE';
import NumberField from '../../../../../components/Form/NumberField';
import styled from 'styled-components';
import {ControlLabel, Form, FormControl, FormGroup, Icon, Schema} from 'rsuite/es';
import {MIN_RATE_VALUE, valueAllocationLimit, valueAllocationLimitByPrefix} from 'const';
import {SelectPicker, Toggle} from "rsuite";
import {getFileResponse} from 'api/loginRoutes';
import {getBlobContent} from "../../../../../utils";


const {NumberType} = Schema.Types;

const defaultNumberList = [
    {key: 1, name: "1"},
    {key: 10, name: "10"},
    {key: 100, name: "100"},
    {key: 1000, name: "1000"},
    {key: 10000, name: "10000"},
    {key: 100000, name: "100000"},
    {key: 1000000, name: "1000000"}
];

export default ({
    onSelect, target, showResult, setPriceRangeNumbers, setCsvContent, activeTrunk,
    allocateByPrefixesStatus = false, setHideBufferButtons, spAuth = false, allocationLimit,
    getTrunkNumberCsvById, setSummaryState, setTransactionId, setTotalNumbersForced,
    setNotAllocatedNumbers, isMobile, ...props
}) => {

    const [method, setMethod] = React.useState('allocation:template');
    const [valueTemplate, setValueTemplate] = React.useState('');
    const [valueSde, setValueSde] = React.useState(null);
    const [pickedInput, changeInput] = React.useState(true); // true - template, false - subdestination
    const [valueForm, setValueForm] = React.useState({
        random_number: true,
        numbers: 10, 
        ranges: 1
    });
    const [showRateInput, onChangeShowRateInput] = React.useState(null);
    const [customRateValue, onChangeCustomRateValue] = React.useState(null);
    const [fieldsToRemove, setFieldsToRemove] = React.useState([]);
    
    // callback: on Form change
    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    const changeSpAuthOption = (item) => {
        props.setAllocateByPrefixesStatus(item);
    };

    // input: Template
    const templateInput = (
        <StyledField>
            <Text
                name="template"
                validationKey={method === 'allocation:template' ? 'in_template_range' : null}
                label="Template" width="md"
                onChange={(value) => {
                    setValueTemplate(value);
                }}
                value={valueTemplate}
                placeholder="1234567890XX"
                onClick={() => {
                    setMethod('allocation:template');
                    changeInput(true);
                    setValueSde(null);
                    setFieldsToRemove(["sde_key"]);
                }}
            />
        </StyledField>
    );


    // input: SubDestination
    const sdeInput = (
        <StyledField>
            <SDE
                name="sde_key"
                onChange={(value) => {
                    setValueSde(value);
                }}
                value={valueSde}
                validationKey="num_required"
                onClick={() => {
                    setMethod('allocation:subdestination');
                    changeInput(false);
                    setValueTemplate(null);
                    setFieldsToRemove(["template"]);
                }}
            />
        </StyledField>
    );

    const allocationLimitCondition = allocateByPrefixesStatus ? valueAllocationLimitByPrefix : valueAllocationLimit;

    const model = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
            .max(10, 'The maximum of this field is 10'),
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .addRule((value, data) => {
                if (data.allocate_by_prefix !== undefined) {
                    if (value > allocationLimitCondition) {
                        return false
                    }
                }
                return true;
            }, `The maximum of this field is ${allocationLimitCondition}`)
    };
    
    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            getFileResponse("trunk_number:get_list", {target: {trunk_number_transaction_id: transactionId}}, true)
                .then((response) => {
                    const csvText =  getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    })
                });

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);

            setHideBufferButtons(false);
            setNotAllocatedNumbers(not_allocated_numbers);
            
            showResult();
        }
    };

    const fieldRate = !showRateInput

        ? <div
            style={{cursor: 'pointer', margin: '8px 20px 0 0', display: 'inline-block'}}
            onClick={() => {onChangeShowRateInput(true)}}
        >
            <Icon icon="book"
                  inverse
                  style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'}}
            />
            <a style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d',}}>Put custom rate</a>
        </div>
        : <>
            <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>Rate</div>
            <div style={{display: 'inline-block', marginRight: '10px'}}>

                <FormControl
                    style={{width: 140}}
                    errorPlacement="bottomStart"
                    placeholder="0.0001"
                    name="rate"
                    value={customRateValue}
                    onChange={(value) => {
                        onChangeCustomRateValue(value);
                    }}
                />

            </div>
            <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>
                <Icon
                    icon="close"
                    inverse
                    style={{
                        color: '#5e2f9d',
                        marginRight: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        onChangeShowRateInput(false);
                        onChangeCustomRateValue(null)
                    }}
                />
            </div>
        </>;

    return (
        <>
            <MyForm
                activeTrunk={activeTrunk}
                target={method === 'allocation:subdestination' ? {...target, sde_key: valueSde} : target}
                method={method}
                checkResultKey='price_range_number_list'
                update={handleUpdateNew}
                allocateByPrefixesStatus={allocateByPrefixesStatus}
                {...props}
                fieldsToRemove={fieldsToRemove}
                className="numberForm"
                onChange={handleFormChange}
                formValue={valueForm}
                addData={{
                    rate: customRateValue !== null ? +customRateValue : null
                }}
                model={model}

                deepCloned={true}
            >

                {!isMobile
                    ? <>
                        {!pickedInput
                            ? <Fieldset name="template-disabled"> {templateInput} </Fieldset>
                            : templateInput
                        }
                        <span className="rs-form-inline rs-control-label">or</span>
                        {pickedInput
                            ? <>
                                <Fieldset name="sde-disabled"> {sdeInput} </Fieldset>
                            </>
                            : sdeInput
                        }
                        <br/>
                    </>
                    : <>
                        <StyledToggleWrapper>
                            <StyledToggle
                                // style={styles}
                                size="lg"
                                checkedChildren={"Template"}
                                className=""
                                unCheckedChildren={"Subdestination"}
                                defaultChecked={pickedInput}
                                onChange={changeInput}
                            />
                        </StyledToggleWrapper>
                        {pickedInput
                            ? templateInput
                            : sdeInput
                        }
                    </>
                }

                {/* Show Rate form */}
                {!isMobile && fieldRate}

                <StyledField>
                    <NumberField name="ranges" label="Ranges" max={Infinity} step={1} min={1}/>
                </StyledField>

                {allocateByPrefixesStatus && spAuth
                    ? <StyledField>
                        <FormGroup>
                            <ControlLabel>Numbers per range</ControlLabel>
                            <FormControl
                                name="numbers"
                                validationKey="numbers_by_prefix"
                                accepter={SelectPicker}
                                data={defaultNumberList.filter(amount => amount.key <= allocationLimitCondition)}
                                style={{width: '135px'}}
                                labelKey="name"
                                valueKey="key"
                                cleanable={false}
                                searchable={false}
                            />
                        </FormGroup>
                    </StyledField>
                    : <StyledField>
                        <NumberField
                            name="numbers"
                            label="Numbers per range"
                            min={1}
                            max={allocationLimitCondition}
                        />
                    </StyledField>
                }
                {spAuth &&
                    <StyledField>
                        <Checkbox
                            name="allocate_by_prefix"
                            onChange={changeSpAuthOption}
                            defaultChecked={allocateByPrefixesStatus}
                        >
                            Allocate by prefixes
                        </Checkbox>
                    </StyledField>
                }
                <StyledField>
                    <Checkbox name="random_number" defaultChecked>Random order</Checkbox>
                </StyledField>

                {isMobile && <div>{fieldRate}</div>}
            </MyForm>
        </>
    );
};

const Fieldset = styled.fieldset`
    border: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    
    .rs-input,
    .rs-btn {
        cursor: not-allowed !important;
    }
    
    > div > div > input {
        cursor: not-allowed
    }
    > div > div {
        opacity: 0.6;
        color: #888888
    }
`;

const StyledToggleWrapper = styled.div`
    && {
        margin: 0;
        width: 100%;
    }
`;

const StyledToggle = styled(Toggle)`
    && {
        background-color: #939191;
        margin: 0 0 20px;
        
        &:hover {
            background-color: #939191;
        }
    }
    &.rs-btn-toggle-checked {
        background-color: var(--color-brand1);
        
        &:hover {
            background-color: var(--color-brand1);
        }
    }
`;


const StyledField = styled.div`
    display: inline-block;
    
    @media (max-width: 567px) {
        display: block;
        padding-bottom: 10px;
        
        && .rs-control-label {
            display: block;
            margin-bottom: 5px;
            margin-top: 0;
        }
        
        && .rs-form-group {
            display: block;
        }
    }
`;