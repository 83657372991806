export const CDR_LIST_METHOD = 'cdr_full:get_list_admin';
export const TRAFFIC_REPORTS_METHOD = 'cdr_full:group_get_list_admin';

export const AUTH_NUMBERS_GET_LIST = 'auth_number:get_list';
export const AUTH_NUMBERS_ADD_UPLOAD = 'auth_number:add_upload';
export const AUTH_NUMBERS_ADD = 'auth_number:create';
export const AUTH_NUMBERS_EDIT = 'auth_number:modify';
export const AUTH_NUMBERS_DELETE = 'auth_number:remove';

export const REFERENCES_ADMIN = 'references_admin';
export const REFERENCES_CLIENT = 'references_client';
export const REFERENCES_FOR_REPORTS_METHOD = 'references_admin_report';
export const REFERENCES_ALL_METHOD = 'references_all';

export const ACCOUNT_USER_MODIFY_BY_SELF = 'account_user:modify_by_self';
export const ACCOUNT_USER_MODIFY_PASSWORD_BY_SELF = 'account_user:modify_password_by_self';
export const SET_REALTIME_PAYMENT_STATUS = 'account_user:get_realtime_status';

export const ACCOUNT_USER_GET_REALTIME_STATUS = 'account_user:get_realtime_status';

/*Service plan methods*/
export const SP_CHANGE_RATE_METHOD = 'service_plan_price:change_rate';
export const SP_PRICE_NEW_SUBDESTINATION_METHOD = 'service_plan_price:new_subdestination';

export const ACCOUNT_CREATE_METHOD = 'account:create';
export const ACCOUNT_SETTINGS_MODIFY_API = 'account_setting:modify';

// Payments API
export const PAYMENT_REQUEST_LIST_API = 'payment_request:get_list';
export const PAYMENT_LIST_API = 'payment__get_list_by_admin';
export const INVOICE_LIST_API = 'invoice__get_list_by_admin';
export const PAYMENT_REQUEST_CREATE_API = 'payment_request:create';
export const PAYMENT_REQUEST_CANCEL_API = 'payment_request:cancel';
export const PAYMENT_REQUEST_APPROVE_API = 'payment_request:approve';
export const PAYMENT_REQUEST_HAWALA_GET_LIST_API = 'payment_request__hawala_get_list';

export const PAYMENT_LIST_DIALER_API = 'payment__get_list';
export const INVOICE_LIST_DIALER_API = 'invoice__get_list';

// Payment Details API
export const PAYMENT_DETAIL_LIST_API = 'payment_detail:get_list';
export const PAYMENT_DETAIL_GET_API = 'payment_detail:get';
export const PAYMENT_DETAIL_CREATE_API = 'payment_detail:create';
export const PAYMENT_DETAIL_MODIFY_API = 'payment_detail:modify';
export const PAYMENT_DETAIL_REMOVE_API = 'payment_detail:remove';
export const PAYMENT_DETAIL_CONFIRM_API = 'payment_detail:confirm';
export const PAYMENT_DETAIL_CONFIRM_STATUS_API = 'payment_detail_confirm_status';

// Templates
export const TEMPLATE_GET = 'template:get';

// Access Exception List
export const ACCESS_EXCLUSION_LIST = 'access_list_exclusion:get_list';

// SIM Traffic Ranges
export const SIM_TRAFFIC_RANGES_GET_LIST = 'price_range_priority:get_list';
export const SIM_TRAFFIC_RANGES_ADD_NEW = 'price_range_priority:create';
export const SIM_TRAFFIC_RANGES_GET_POSSIBLE_LIST = 'price_range_priority:subdestination_code_get_list';
export const SIM_TRAFFIC_RANGES_REMOVE = 'price_range_priority:remove';

// Blocked destinations
export const BLOCKED_DESTINATIONS_GET_LIST_API = 'allocation_block:get_list';
export const BLOCKED_DESTINATIONS_CREATE_API = 'allocation_block:create';
export const BLOCKED_DESTINATIONS_MODIFY_API = 'allocation_block:modify';
export const BLOCKED_DESTINATIONS_MODIFY_LIST_API = 'allocation_block:modify_list';
export const BLOCKED_DESTINATIONS_REMOVE_API = 'allocation_block:remove';

// Global setting
export const GLOBAL_SETTING_GET_LIST_API = 'global_setting:get_list';
export const GLOBAL_SETTING_CREATE_API = 'global_setting:create';
export const GLOBAL_SETTING_MODIFY_API = 'global_setting:modify';

// Google OTP Quarantined ranges
export const GOOGLE_OTP_QUARANTINED_GET_LIST_API = 'price_range_quarantine:get_list';
export const GOOGLE_OTP_QUARANTINED_REMOVE_NUMBERS_API = 'price_range_quarantine:remove';

// Google OTP Allocation
export const GOOGLE_OTP_ALLOCATION_GET_LIST_API = 'otp_allocation2:get_list';
export const GOOGLE_OTP_ALLOCATION_CREATE_API = 'otp_allocation2:create';
export const GOOGLE_OTP_ALLOCATION_MODIFY_API = 'otp_allocation2:modify';
export const GOOGLE_OTP_ALLOCATION_REMOVE_API = 'otp_allocation2:remove';

// Price ranges
export const PRICE_RANGE_GENERATE_BY_SDE_API = 'price_range__generate_by_sde';
export const PRICE_RANGE_GENERATE_BY_TEMPLATE_API = 'price_range__generate_by_templates';
export const PRICE_RANGE_GENERATE_BY_NUMBER_LIST_API = 'price_range__generate_by_number_list';

export const PRICE_RANGE_DELETE_API = 'price_range__delete';
export const PRICE_RANGE_DELETE_NOT_ALLOCATED_API = 'price_range__delete_not_allocated';

// Action logs
export const ACTION_LOGS_GET_LIST_API = 'log_action:get_list';
export const ACTION_LOGS_GET_REFERENCES_API = 'log_action:references';

// AM Activities
export const AM_ACTIVITIES_LIST_API = 'log_action:statistic_by_account_user_manager';
export const AM_ACTIVITIES_DATE_LIST_API = 'log_action:statistic_by_account_user_manager_date';

// Account Managers
export const ACCOUNT_MANAGERS_LIST_API = 'account_manager:get_list';
export const ACCOUNT_MANAGERS_REFERENCES_API = 'account_manager:references';
export const ACCOUNT_MANAGERS_MODIFY_API = 'account_manager:modify';
export const ACCOUNT_MANAGERS_REMOVE_API = 'account_manager:remove';
export const ACCOUNT_MANAGERS_CREATE_API = 'account_manager:create';
export const ACCOUNT_MANAGERS_BIL_API = 'account_manager_bil__get_list';

// Special limit terms
export const PRICE_RANGE_QUARANTINE_SETTING_GET_LIST_API = 'price_range_quarantine_setting:get_list';
export const PRICE_RANGE_QUARANTINE_SETTING_CREATE_API = 'price_range_quarantine_setting:create';
export const PRICE_RANGE_QUARANTINE_SETTING_MODIFY_API = 'price_range_quarantine_setting:modify';
export const PRICE_RANGE_QUARANTINE_SETTING_REMOVE_API = 'price_range_quarantine_setting:remove';

// Account note
export const ACCOUNT_NOTE_GET_LIST_API = 'account_note:get_list';
export const ACCOUNT_NOTE_CREATE_API = 'account_note:create';
export const ACCOUNT_NOTE_DEACTIVATE_API = 'account_note:deactivate';

export const REGISTRATION_CREATE_API = 'registration:create';
export const REGISTRATION_GET_LIST_API = 'registration:get_list';
export const REGISTRATION_GET_COUNT_API = 'registration:get_count';
export const REGISTRATION_APPROVE_API = 'registration:approve';
export const REGISTRATION_REJECT_API = 'registration:reject';
export const REGISTRATION_ACCOUNT_API = 'registration:account';


// client auth
export const ACCOUNT_GET_DIALER_API = 'account:get';
export const ACCOUNT_GET_TEST_DIALER_API = 'account:get_by_test_user';

// client numbers
export const TRUNK_GET_LIST_API = 'trunk:get_list';

// client allocation
export const ALLOCATION_PRICE_RANGE_DIALER_API = 'allocation:price_range';
export const ALLOCATION_NUMBER_LIST_DIALER_API = 'allocation:number_list_by_account_user';
export const ALLOCATION_TEMPLATE_DIALER_API = 'allocation:template_by_account_user';
export const ALLOCATION_GOOGLE_OTP_DIALER_API = 'allocation:google_otp_by_account_user';
export const ALLOCATION_SUBDESTINATION_DIALER_API = 'allocation:subdestination_by_account_user';

// test calls
export const TEST_CALLS_LIST_DIALER_API = 'cdr_full:test_get_list';

// live calls
export const LIVE_CALLS_LIST_DIALER_API = 'live_call:get_list_by_account_user';

// cdr
export const CDR_LIST_DIALER_API = 'cdr_full:get_list';
export const TRAFFIC_STAT_LIST_DIALER_API = 'cdr_full:group_get_list';

// price
export const RATECARD_LIST_ACCOUNT_DIALER = 'ratecard_standart:get_account_price';
export const RATECARD_LIST_OTP_ACCOUNT_DIALER = 'ratecard_otp:get_account_price';
export const RATECARD_LIST_DEFAULT_DIALER = 'ratecard_standart:get_default_price';
export const RATECARD_LIST_OTP_DEFAULT_DIALER = 'ratecard_otp:get_default_price';

// access
export const ACCESS_LIST_ACCOUNT_PRICE_DIALER_API = 'access_list__get_list:account_price';
export const ACCESS_LIST_OTP_ACCOUNT_PRICE_DIALER_API = 'access_list_otp__get_list:account_price';
export const ACCESS_LIST_DEFAULT_PRICE_DIALER_API = 'access_list__get_list:default_price';
export const ACCESS_LIST_OTP_DEFAULT_PRICE_DIALER_API = 'access_list_otp__get_list:default_price';

// cli
export const CLI_LIST_DIALER_API = 'cdr_short__cli_lookup';
export const CLI_LIST_OTP_DIALER_API = 'cdr_short__cli_lookup_otp';

// api page
export const API_PAGE_LIST_DIALER_API = 'account_apikey:get_list';
export const API_GENERATE_NEW_DIALER_API = 'account_apikey:generate';

// IVR
export const IVR_SOUND_LIST_API = 'ivr_sound:get_list'; // файл загружается через multipart/form-data обычный json передается через секцию json
export const IVR_SOUND_CREATE_API = 'ivr_sound:create';
export const IVR_SOUND_MODIFY_API = 'ivr_sound:modify'; // файл загружается через multipart/form-data обычный json передается через секцию json
export const IVR_SOUND_REMOVE_API = 'ivr_sound:remove';

export const IVR_LIST_API = 'ivr:get_list';
export const IVR_CREATE_API = 'ivr:create';
export const IVR_MODIFY_API = 'ivr:modify';
export const IVR_REMOVE_API = 'ivr:remove';

export const IVR_ACTION_TYPE_LIST_API = 'ivr:get_ivr_action_type_list'; // типы действий

export const IVR_CLI_LIST_API = 'ivr_cli:get_list';
export const IVR_CLI_CREATE_MODIFY_API = 'ivr_cli:create_modify';
export const IVR_CLI_REMOVE_API = 'ivr_cli:remove';

export const IVR_DEFAULT_API = 'ivr:get_default';
export const IVR_SET_DEFAULT_API = 'ivr:set_default';
export const IVR_RECALC_ALL_API = 'ivr__recalc_all';
export const IVR_LISTEN_API = 'ivr:listen';