import React from 'react';
import AllocatedNumbers from './AllocatedNumbers/AllocatedNumbers';
import Trunks from './Trunks';
import {Header} from './styled';


export default class extends React.Component{
    state = {
        showAllocatedNumbersModal: false,
        showClosedTrunks: false,
        numbersTableReadonly: false
    };

    componentDidMount() {
        const {trunks, currentTrunkId, setCurrentTrunkId} = this.props;
        const trunkId = trunks && trunks.length && trunks[0].id;

        !currentTrunkId && setCurrentTrunkId(trunkId);
    }

    getNumbers = (group, filter, page, per_page, sort, first=false) => {
        const {trunks, currentTrunkId} = this.props;
        const trunkId = trunks.map(item => item.id).includes(currentTrunkId) && !first ? currentTrunkId : trunks && trunks.length && trunks[0].id;

        this.props.getNumbers(trunkId, group, filter, page, per_page, sort)();
    };

    setTrunk = (trunk_id) => {
        const {trunks, setCurrentTrunkId, allocatedNumbersLoading} = this.props;
        if (allocatedNumbersLoading) return;

        const activeTrunk = trunks.find((trunk) => trunk.id === trunk_id);
        const numbersTableReadonly = activeTrunk ? activeTrunk.closed: true;

        setCurrentTrunkId(trunk_id);
        this.setState({numbersTableReadonly})
    };

    render () {
        const {showAllocatedNumbersModal} = this.state;
        const { account, activeTab,
                trunks, trunksLoading, acc_key, getTrunks, setCurrentTrunkId, allocatedNumbers, allocatedNumbersLoading,
                allocatedNumbersTotalCount, allocatedNumbersCount, allocatedNumbersPage, allocatedNumbersPerPage,
                currentTrunkId, getTrunkNumberCsvById, currentRangeNumber, setCurrentRangeNumber, isMobile
        } = this.props;

        const trunkId = trunks
            .map(item => item.id)
            .includes(currentTrunkId)
                ? currentTrunkId
                : trunks && trunks.length && trunks[0].id;

        const currentTrunk = trunks.find(trunk => trunk.id === trunkId) || {};

        return (
            <>

                <Trunks
                    trunk_id={trunkId}currentTrunkId
                    account_name={account.name}
                    onAllocateNumbers={()=>this.setState({showAllocatedNumbersModal:true})}
                    getTrunks={getTrunks}
                    setTrunk={this.setTrunk}
                    setCurrentTrunkId={setCurrentTrunkId}
                    setCurrentRangeNumber={setCurrentRangeNumber}
                    closedTrunksShow={this.state.showClosedTrunks}
                    setClosedTrunksShow={() => {
                        this.setState({showClosedTrunks: !this.state.showClosedTrunks})
                    }}
                    trunks={trunks}
                    {...{
                        currentTrunkId,
                        trunksLoading,
                        allocatedNumbersLoading,
                        acc_key,
                        isMobile
                    }}
                />

                <Header>Allocated numbers</Header>

                <AllocatedNumbers
                    {...{
                        activeTab,
                        account,
                        currentRangeNumber,
                        allocatedNumbers,
                        allocatedNumbersLoading,
                        allocatedNumbersCount,
                        allocatedNumbersTotalCount,
                        allocatedNumbersPage,
                        allocatedNumbersPerPage,
                        showAllocatedNumbersModal,
                        getTrunkNumberCsvById,
                        trunksLoading,
                        trunks
                    }}
                    trunk_id={trunkId}
                    closeAllocatedNumbersModal = {()=>this.setState({showAllocatedNumbersModal:false})}
                    sp_key={currentTrunk.sp_key}
                    sp_auth={currentTrunk.sp_auth}
                    spOtpType={currentTrunk.sp_otp_type}
                    acc_key={acc_key}
                    account_name={account.name}
                    getNumbers={this.getNumbers}
                    readonly={this.state.numbersTableReadonly}
                />

            </>
        )
    }
}