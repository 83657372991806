import React, {useEffect, useRef, useState} from 'react';
import FormHOC from '../../hoc/FilltersForm';
import {Tooltip, List, FlexboxGrid, Button, Icon, Whisper, Message} from 'rsuite';
import Modal from '../../components/Modal';
import {useWindowWidth} from '../../hooks';
import {CustomModalClient} from "../../components/base";


export default FormHOC(({keyList, pickApiKey, pickedApiKey, generateNewApiKey, newItem, maxWidth = 600}) => {
    const activeKeyList = keyList.filter(key => key.active);
    const refTextAreaDict = {};
    const [generateModal, onGenerateModal] = useState(null);
    const [activeKey, onChangeActiveKey] = useState(pickedApiKey || newItem);

    const resizedWidth = useWindowWidth();

    useEffect(() => {
        const updatedActiveKeyList = keyList.filter(key => key.active).map(key => key.api_key);

        if (newItem && pickedApiKey) {
            if (newItem && newItem !== activeKey && !updatedActiveKeyList.includes(newItem)) {
                onChangeActiveKey(newItem);
                pickApiKey(newItem);
            }
            else if (pickedApiKey !== activeKey && updatedActiveKeyList.includes(activeKey)) {
                onChangeActiveKey(pickedApiKey);
            }
        }
    }, [newItem, pickedApiKey, keyList]);

    return (
        <>
            <span className="api_documentation">API Keys
                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Click on the api key from list to make it picked for documentation</Tooltip>}>
                    <Icon className="api_documentation-tooltip" icon="info"/>
                </Whisper>
            </span>
            <FlexboxGrid className="api_keys">
                {activeKeyList
                    ? <>
                        <FlexboxGrid.Item>
                            <List bordered className="api_keys-list">
                                {
                                    activeKeyList.map(key => {
                                        return <List.Item
                                            className={`api_keys-list_item ${activeKey === key.api_key ? 'active' : ''}`}
                                            onClick={() =>{
                                                pickApiKey(key.api_key);
                                            }}>
                                            <span>{key.api_key}</span>
                                            <Icon
                                                icon={activeKey === key.api_key ? 'check-square' : 'square-o'}
                                                className={`api_keys-list_item_icon ${activeKey === key.api_key ? 'active' : ''}`}
                                            />
                                        </List.Item>
                                    })
                                }
                            </List>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {
                                activeKeyList.map(key => {
                                    refTextAreaDict[key.key] = useRef(null);
                                    return <div className="api_keys-panel">
                                        <Button
                                            className="api_keys-panel_button_copy"
                                            appearance="primary"
                                            onClick={() => {
                                                refTextAreaDict[key.key] && refTextAreaDict[key.key].current.select();
                                                document.execCommand('copy');
                                            }}
                                        >
                                            Copy
                                            <Icon
                                                inverse
                                                className="api_keys-panel_button_icon"
                                                icon="copy-o"
                                            />
                                        </Button>
                                        <textarea
                                            ref={refTextAreaDict[key.key]}
                                            value={key.api_key}
                                            style={{width: '0', height: '0', resize: 'none', border: '0', visibility: false}}
                                        />
                                        <Button
                                            className="api_keys-panel_button_generate"
                                            appearance="primary"
                                            onClick={() => {
                                                onGenerateModal(key.api_key);
                                            }}
                                        >
                                            Generate new
                                            <Icon
                                                reverse
                                                className="api_keys-panel_button_icon"
                                                icon="exclamation-triangle"
                                            />
                                        </Button>
                                        {generateModal &&
                                            <CustomModalClient
                                                show={generateModal}
                                                title="Warning"
                                                showFooter={true}
                                                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                                                onClose={() => onGenerateModal(null)}
                                                onConfirm={() => {
                                                    generateNewApiKey(generateModal);
                                                    onGenerateModal(null);
                                                }}
                                            >
                                                This api key will be changed. Requests with old api key will not work
                                            </CustomModalClient>
                                        }
                                    </div>
                                })
                            }
                        </FlexboxGrid.Item>
                    </> :
                    <FlexboxGrid.Item>
                        <Message
                            description="You don't have any api key"
                        />
                    </FlexboxGrid.Item>
                }
            </FlexboxGrid>
        </>
    )
});