import {api} from '../api/loginRoutes'
import {createAction} from './defaults'
import {
    TRAFFIC_REPORTS_GET_LIST_SUCCESS, TRAFFIC_REPORTS_SET_LOADING,
} from "./actionTypes";
import {TRAFFIC_REPORTS_METHOD} from "../const/apiMethods";
import {toEndDayUTCTime, toStartDayUTCTime} from "utils";

const setLoading = createAction(TRAFFIC_REPORTS_SET_LOADING);
const setTrafficReportsList = createAction(TRAFFIC_REPORTS_GET_LIST_SUCCESS);

export const getTrafficReports = (filter = {}, page = 1, per_page = 1000) => (dispatch) => {
    dispatch(setLoading(true));

    if (filter.range && filter.range.length) {
        filter['start_date'] = toStartDayUTCTime(filter.range[0]);
        filter['end_date'] = toEndDayUTCTime(filter.range[1]);

        delete filter.range;
    }

    api(TRAFFIC_REPORTS_METHOD, {filter, page, per_page})
        .then(({group_count = 0, group_list = []}) => {
            dispatch(setTrafficReportsList({
                list: group_list,
                count: group_count,
                perPage: per_page,
                page: page
            }));
        })
        .catch((error) => {
            dispatch(setLoading(false));
            console.log('TRAFFIC_REPORTS_METHOD_FAILED', error);
        })
};
