import React, {useEffect, useState} from "react";
import PanelLayout from "../../components/base/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import AmActivitiesFilter from "./AmActivitiesFilter";
import AmActivitiesTable from "./AmActivitiesTable";

const dateNow = new Date();

export default ({
    data,
    dateList,
    loading,
    managerList,

    getReferences,
    getAmActivitiesList,
    getAmActivitiesDateList,
    removeAmActivitiesDateListItem,
    removeAmActivitiesDateList,
}) => {

    const [dateRange, setDateRange] = useState([
        new Date( dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() ),
        new Date( dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() )
    ]);

    const [filter, onChangeFilter] = useState({});

    useEffect(() => {
        getReferences();
        return () => removeAmActivitiesDateList();
    }, []);

    useEffect(() => {
        const data = {
            ...filter,
            range: dateRange,
        }
        getAmActivitiesList(data);
    }, [filter, dateRange]);


    const setManagerDailyActivity = (id, isExpanded) => {
        if (isExpanded) {
            removeAmActivitiesDateListItem(id);
            return;
        }
        getAmActivitiesDateList(id, {
            ...filter,
            range: dateRange,
        });
    };

    return (
        <PanelLayout>
            <PageHeader title={"AM Activities"}/>
            <AmActivitiesFilter
                defaultFilter={filter}
                {...{
                    managerList,
                    dateRange,
                    onChangeFilter,
                    setDateRange
                }}
            />
            <AmActivitiesTable
                handleExpanded={setManagerDailyActivity}
                expandedRowData={dateList}
                {...{
                    data,
                    loading,
                    dateList,
                    dateRange
                }}
            />
        </PanelLayout>
    )
};