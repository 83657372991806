import React from 'react';
import MyForm from './../../../../../components/MyForm';
import Checkbox from '../../../../../hoc/Checkbox';
import NumberField from '../../../../../components/Form/NumberField';
import {ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, Icon, Schema} from 'rsuite/es';
import {MIN_RATE_VALUE, valueAllocationLimit, valueAllocationLimitByPrefix} from 'const';
import {Input, SelectPicker} from "rsuite";
import {getBlobContent, splitList} from "../../../../../utils";
import {getFileResponse} from 'api/loginRoutes';
import {Spacer} from "../../../../../components/base/Spacer";
import styled from "styled-components";

const {NumberType} = Schema.Types;

export default ({
    activeTab, onSelect, target, showResult, setHideBufferButtons, activeTrunk,
    spAuth = false, allocateByPrefixesStatus = false, allocationLimit, setSummaryState, setCsvContent,
    setTransactionId, setTotalNumbersForced, setAllocateByPrefixesStatus, setNotAllocatedNumbers, isMobile, ...props
}) => {

    const defaultNumberList = [
        {key: 1, name: "1"},
        {key: 10, name: "10"},
        {key: 100, name: "100"},
        {key: 1000, name: "1000"},
        {key: 10000, name: "10000"},
        {key: 100000, name: "100000"},
        {key: 1000000, name: "1000000"}
    ];

    let timerid = null;
    const [templateList, setTemplateList] = React.useState([]);
    const [valueForm, setValueForm] = React.useState({random_number: true, numbers: 1});
    const [showRateInput, onChangeShowRateInput] = React.useState(null);
    const [customRateValue, onChangeCustomRateValue] = React.useState(null);

    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    const changeTemplateList = (str) => {
        clearTimeout(timerid);

        timerid = setTimeout((str) => {

            const list = str.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ').filter( s => s !== "");

            setTemplateList(list);
        }, 300, str);
    };

    const changeSpAuthOption = (item) => {
        setAllocateByPrefixesStatus(item);
    };

    const allocationLimitCondition = allocateByPrefixesStatus ? valueAllocationLimitByPrefix : valueAllocationLimit;

    const model = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
            .max(10, 'The maximum of this field is 10'),
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .addRule((value, data) => {
                if (data.allocate_by_prefix !== undefined) {
                    if (value > allocationLimitCondition) {
                        return false
                    }
                }
                return true;
            }, `The maximum of this field is ${allocationLimitCondition}`)
    };


    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            getFileResponse("trunk_number:get_list", {target: {trunk_number_transaction_id: transactionId}}, true)
                .then((response) => {
                    const csvText =  getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    })
                });


            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);
            setHideBufferButtons(false);
            setNotAllocatedNumbers(not_allocated_numbers);
            showResult();
        }
    };

    const rateField = (
        <FlexboxGrid justify="start" style={{margin: '0 0 20px 0'}}>
            {!showRateInput

                ? <FlexboxGrid.Item>
                    <div
                        style={{cursor: 'pointer', margin: '8px 20px 0 0', display: 'inline-block'}}
                        onClick={() => {onChangeShowRateInput(true)}}
                    >
                        <Icon icon="book"
                              inverse
                              style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'}}
                        />
                        <a style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d',}}>Put custom rate</a>
                    </div>
                </FlexboxGrid.Item>

                : <FlexboxGrid.Item>
                    <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>Rate</div>
                    <div style={{display: 'inline-block', marginRight: '10px'}}>
                        <FormControl
                            style={{width: 140}}
                            errorPlacement="bottomStart"
                            placeholder="0.0001"
                            name="rate"
                            value={customRateValue}
                            onChange={(value) => {
                                onChangeCustomRateValue(value);
                            }}
                        />
                    </div>
                    <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>
                        <Icon
                            icon="close"
                            inverse
                            style={{
                                color: '#5e2f9d',
                                marginRight: '5px',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                onChangeShowRateInput(false);
                                onChangeCustomRateValue(null)
                            }}
                        />
                    </div>
                </FlexboxGrid.Item>
            }
        </FlexboxGrid>
    );

    return (
        <>
            <MyForm
                activeTrunk={activeTrunk}
                target={target}
                method="allocation:template_list"
                checkResultKey='price_range_number_list'
                update={handleUpdateNew}
                allocateByPrefixesStatus={allocateByPrefixesStatus}
                {...props}
                className="numberForm"
                onChange={handleFormChange}
                formValue={valueForm}
                addData={{
                    template_list: templateList,
                    rate: customRateValue !== null ? +customRateValue : null
                }}
                model={model}

                deepCloned={true}
            >

                {/* Show Rate form */}
                {!isMobile && rateField}

                <FormControl
                    accepter={StyledTextarea}
                    errorPlacement="topLeft"
                    componentClass="textarea"
                    className="test123"
                    classPreffix="test234"
                    name='template_list'
                    onChange={changeTemplateList}
                    rows={3}
                    placeholder="Enter your numbers here"
                />

                {allocateByPrefixesStatus && spAuth
                    ? <FormGroup>
                        <ControlLabel>Numbers per range</ControlLabel>
                        <FormControl
                            name="numbers"
                            validationKey="numbers_by_prefix"
                            accepter={SelectPicker}
                            data={defaultNumberList.filter(amount => amount.key <= allocationLimitCondition)}
                            style={{width: '135px'}}
                            labelKey="name"
                            valueKey="key"
                            cleanable={false}
                            searchable={false}
                        />
                    </FormGroup>
                    : <NumberField name="numbers" label="Numbers per template" min={1} max={allocationLimitCondition}/>
                }

                <br style={{display: isMobile ? 'none' : 'block'}}/>

                <Checkbox name="random_number" defaultChecked>Random order</Checkbox>

                <br style={{display: isMobile ? 'none' : 'block'}}/>

                {spAuth &&
                    <Checkbox
                        name="allocate_by_prefix"
                        onChange={changeSpAuthOption}
                        defaultChecked={allocateByPrefixesStatus}
                    >
                        Allocate by prefixes
                    </Checkbox>
                }

                {isMobile && rateField}

            </MyForm>
        </>
    );
};


const StyledTextarea = styled(Input)`
    &&& {
        min-width: 1px;
        width: 100%;
        
        @media (min-width: 767px) {
            width: 40%;
            height: 500px;
            float: left;
            margin-right: 5%;
            min-width: 300px;
        }
    }
`;