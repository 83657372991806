import React from 'react';
import MyForm from '../../../../../components/MyForm/MyForm';
import Text from '../../../../../components/Form/Text';
import SDE from '../../../../../components/Filters/SDE';
import NumberField from '../../../../../components/Form/NumberField';
import styled from 'styled-components';
import {FormControl, Icon, Schema} from 'rsuite/es';
import {MIN_RATE_VALUE, valueAllocationLimitGoogleOTP} from 'const';
import {getFileResponse} from 'api/loginRoutes';
import {getBlobContent} from "../../../../../utils";
import {Spacer} from "../../../../../components/base/Spacer";
import {Input, Toggle} from "rsuite";

const {NumberType} = Schema.Types;


export default ({
    activeTab, onSelect, target, showResult, setCsvContent, setSummaryState, setTransactionId,
    setHideBufferButtons, spAuth = false, allocationLimit, setTotalNumbersForced, activeTrunk,
    setNotAllocatedNumbers, isMobile, ...props
}) => {

    const [valueTemplate, setValueTemplate] = React.useState('');
    const [valueSde, setValueSde] = React.useState(null);
    const [pickedInput, changeInput] = React.useState(true); // true - template_list, false - subdestination
    const [valueForm, setValueForm] = React.useState({numbers: 100});
    const [showRateInput, onChangeShowRateInput] = React.useState(null);
    const [customRateValue, onChangeCustomRateValue] = React.useState(null);
    const [fieldsToRemove, setFieldsToRemove] = React.useState([]);

    // callback: on Form change
    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    const model = {
        rate: NumberType()
            .min(MIN_RATE_VALUE, `The minimum of this field is ${MIN_RATE_VALUE}`)
            .max(10, 'The maximum of this field is 10'),
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .max(valueAllocationLimitGoogleOTP, `The maximum of this field is ${valueAllocationLimitGoogleOTP}`)
            .addRule((value) => {
                return !(value % 100);
            }, 'The number must be a multiple of 100'),

    };

    // input: Template list
    const templateInput = (
        <StyledField>
            <FormControl
                accepter={StyledTextarea}
                name="template_list"
                componentClass="textarea"
                label="Template list"
                width="md"
                onChange={(value) => {
                    setValueTemplate(value);
                }}
                value={valueTemplate}
                errorPlacement={"topStart"}
                placeholder="Enter your templates here"
                onClick={() => {
                    changeInput(true);
                    setValueSde(null);
                    setValueForm({
                        ...valueForm,
                        sde_key: undefined
                    });
                    setFieldsToRemove(["sde_key"]);
                }}
            />
        </StyledField>
    );


    // input: SubDestination
    const sdeInput = (
        <StyledField>
            <SDE
                name="sde_key"
                onChange={(value) => {
                    setValueSde(value);
                }}
                value={valueSde}
                validationKey="num_required"
                useBr={!isMobile}
                onClick={() => {

                    changeInput(false);
                    setValueTemplate(null);
                    setValueForm({
                        ...valueForm,
                        template_list: undefined
                    });
                    setFieldsToRemove(["template_list"]);
                }}
            />
        </StyledField>
    );


    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            getFileResponse("trunk_number:get_list", {target: {trunk_number_transaction_id: transactionId}})
                .then((response) => {
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    })
                });

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);

            setHideBufferButtons(false);
            setNotAllocatedNumbers(not_allocated_numbers);
            showResult();
        }
    };

    const rateField = (
        !showRateInput

            ? <div
                style={{cursor: 'pointer', margin: '8px 20px 0 0', display: 'inline-block'}}
                onClick={() => {onChangeShowRateInput(true)}}
            >
                <Icon icon="book"
                      inverse
                      style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d', marginRight: '5px'}}
                />
                <a style={{color: showRateInput === null ? '#2f63bf' : '#5e2f9d',}}>Put custom rate</a>
            </div>
            : <>
                <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>Rate</div>
                <div style={{display: 'inline-block', marginRight: '10px'}}>

                    <FormControl
                        style={{width: 140}}
                        errorPlacement="bottomStart"
                        placeholder="0.0001"
                        name="rate"
                        value={customRateValue}
                        onChange={(value) => {
                            onChangeCustomRateValue(value);
                        }}
                    />

                </div>
                <div style={{display: 'inline-block', marginTop: '8px', marginRight: '10px'}}>
                    <Icon
                        icon="close"
                        inverse
                        style={{
                            color: '#5e2f9d',
                            marginRight: '5px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            onChangeShowRateInput(false);
                            onChangeCustomRateValue(null)
                        }}
                    />
                </div>
            </>
    )

    return (
        <>
            <MyForm
                activeTrunk={activeTrunk}
                target={target}
                method="allocation:google_otp"
                checkResultKey='price_range_number_list'
                update={handleUpdateNew}
                fieldsToRemove={fieldsToRemove}
                className="numberForm"
                onChange={handleFormChange}
                formValue={valueForm}
                optionFields={true}
                addData={{
                    ...(pickedInput && valueTemplate ? {
                        template_list: valueTemplate.replace( /\r?\n/g, ' ')
                            .split(' ').filter( s => s !== "")
                    } : {}),
                    rate: customRateValue !== null ? +customRateValue : null
                }}
                model={model}
                {...props}
                allocateByPrefixesStatus={null}

                deepCloned={true}
            >

                {!isMobile
                    ? <>
                        {!pickedInput
                            ? <Fieldset name="template-disabled"> {templateInput} </Fieldset>
                            : templateInput
                        }
                        <span className="rs-form-inline rs-control-label">or</span>
                        {pickedInput
                            ? <><Fieldset name="sde-disabled"> {sdeInput} </Fieldset></>
                            : sdeInput
                        }
                        <br/>
                    </>
                    : <>
                        <StyledToggleWrapper>
                            <StyledToggle
                                // style={styles}
                                size="lg"
                                checkedChildren={"Template list"}
                                className=""
                                unCheckedChildren={"Subdestination"}
                                defaultChecked={pickedInput}
                                onChange={changeInput}
                            />
                        </StyledToggleWrapper>
                        {pickedInput
                            ? templateInput
                            : sdeInput
                        }
                    </>
                }

                {/* Show Rate form */}
                {!isMobile && rateField}

                <StyledField>
                     <NumberField
                        name="numbers"
                        label="Numbers"
                        min={1}
                        step={100}
                        max={Infinity}
                    />
                </StyledField>

                {isMobile && rateField}
                <Spacer size={10} />
            </MyForm>
        </>
    );
};

const Fieldset = styled.fieldset`
    border: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    
    .rs-input,
    .rs-btn {
        cursor: not-allowed !important;
    }
    
    > div > div > input {
        cursor: not-allowed
    }
    > div > div {
        opacity: 0.6;
        color: #888888
    }
`;

const StyledToggleWrapper = styled.div`
    && {
        margin: 0;
        width: 100%;
    }
`;

const StyledToggle = styled(Toggle)`
    && {
        background-color: #939191;
        margin: 0 0 20px;
        
        &:hover {
            background-color: #939191;
        }
    }
    &.rs-btn-toggle-checked {
        background-color: var(--color-brand1);
        
        &:hover {
            background-color: var(--color-brand1);
        }
    }
`;


const StyledField = styled.div`
    display: inline-block;
    
    @media (max-width: 567px) {
        display: block;
        padding-bottom: 10px;
        
        && .rs-control-label {
            display: block;
            margin-bottom: 5px;
            margin-top: 0;
        }
        
        && .rs-form-group {
            display: block;
        }
    }
`;

const StyledTextarea = styled(Input)`
    &&& {
        min-width: 1px;
        width: 100%;
        
        @media (min-width: 767px) {
            width: 40%;
            height: 150px;
            float: left;
            margin-right: 5%;
            min-width: 300px;
        }
    }
`;