import React, {useEffect, useState, useMemo} from "react";
import {ButtonGroup, Icon, IconButton, Input, List, Loader, Panel, SelectPicker, Whisper} from "rsuite";
import {ButtonClose, ButtonDefault, ButtonPrimary, ButtonSecondary} from "../../../components/base/BaseButton";
import styled from "styled-components";
import {Spacer} from "../../../components/base/Spacer";
import { FlexGrid, FlexGridItem } from "../../../components/base/FlexGrid";
import BlockIvrParameters from "./BlockIvrParameters";
import { api } from "../../../api/loginRoutes";
import { IVR_LISTEN_API } from "../../../const/apiMethods";
import Tooltip from "rsuite/es/Tooltip";
import {generateId} from "../../../utils";
import IvrListener from "../../../components/IvrListener/IvrListener";


export default (
    {
        activeIvr,
        ivrTypes,
        loading,

        submitIvr,
        removeIvr,
    }
) => {

    const [name, setName] = useState(null);
    const [showNameEditField, setShowNameEditField] = useState(false);
    const [activeIvrActionList, setActiveIvrActionList] = useState(null);
    const [activeActionId, setActiveActionId] = useState(null);


    // set active IVR actions
    useEffect(() => {

        if (!activeIvr) {
            setName(null);
            setShowNameEditField(false);
            setActiveIvrActionList(null);
            setActiveActionId(null);
            return;
        }

        if (activeIvr.isNew) {
            const id = generateId();
            setName("");
            setShowNameEditField(true);
            setActiveActionId(id);
            setActiveIvrActionList([{
                id: id,
                params: null,
                action_name: null,
                sound_name: null,
            }]);
            return;
        }

        setName(activeIvr.name);
        setShowNameEditField(false);
        const activeIvrActionList = activeIvr.action_list
            .sort((a, b) => {
                return a.position - b.position
            })
            .map((item) => ({
                id: generateId(),
                params: item.params,
                action_name: item.action_type.name,
                sound_name: item.sound && item.sound.name,
            }));

        setActiveActionId(activeIvrActionList.length ? activeIvrActionList[0].id : null);

        setActiveIvrActionList(activeIvrActionList);
    }, [activeIvr]);


    const handleAddAction = (index) => {
        const actionCloned = [...activeIvrActionList];
        actionCloned.splice(index + 1, 0, {
            id: generateId(),
            params: null,
            action_name: null,
            sound_name: null,
        });
        setActiveIvrActionList(actionCloned);
    };


    const handleSubmit = () => {
        const actions = activeIvrActionList.map((item, index) => ({
            ...item,
            position: index + 2,
            params: {},
            sound_name: "SOUND1",
        }));

        const data = {
            name: name,
            action_list: [...actions]
        };

        submitIvr(data);
    };


    const handleChangeType = (currentIndex, type) => {
        const actions = activeIvrActionList.map((item, index) => (
            currentIndex === index
                ? {...item, action_name: type}
                : item
        ));
        setActiveIvrActionList(actions);
    };


    const handleIncreasePosition = (index, e) => {
        if (index >= activeIvrActionList.length - 1)
            return;

        e.stopPropagation();

        const actionCloned = [...activeIvrActionList];
        const currentElement = actionCloned[index];
        actionCloned[index] = actionCloned[index + 1];
        actionCloned[index + 1] = currentElement;
        setActiveActionId(currentElement.id);
        setActiveIvrActionList(actionCloned);
    };


    const handleDecreasePosition = (index, e) => {
        if (index === 0)
            return;

        e.stopPropagation();

        const actionCloned = [...activeIvrActionList];
        const currentElement = actionCloned[index];
        actionCloned[index] = actionCloned[index - 1];
        actionCloned[index - 1] = currentElement;
        setActiveActionId(currentElement.id);
        setActiveIvrActionList(actionCloned);
    };


    const handleRemoveAction = (index) => {
        const actionCloned = [...activeIvrActionList];
        actionCloned.splice(index, 1);
        setActiveIvrActionList(actionCloned);
    };


    const activeActionType = useMemo(() => {
        if (!activeIvrActionList || !activeIvrActionList.length) {
            return null;
        }
        const activeActionData = activeIvrActionList.find(item => item.id === activeActionId);
        return activeActionData ? activeActionData.action_name : null;
    }, [activeActionId, activeIvrActionList]);


    return (
        <FlexGrid hSpace={'-20px'} align={'stretch'}>
            <FlexGridItem grow={1} hSpace={'20px'}>
                <Panel style={{minHeight: '506px'}} shaded>

                    <StyledHeader>
                        <StyledHeaderItem>
                            <BlockHeader>
                                {name === null
                                    ? "Choose IVR"
                                    : showNameEditField
                                        ? <Input
                                            name="name"
                                            value={name}
                                            onChange={setName}
                                            placeholder="Enter IVR name"
                                        />
                                        : name
                                }
                            </BlockHeader>
                        </StyledHeaderItem>

                        {activeIvrActionList &&
                            <StyledHeaderItem>

                                <Whisper
                                    placement="left"
                                    speaker={<Tooltip>Listen IVR</Tooltip>}
                                >
                                    <IvrListener ivrId={activeIvr.id}/>
                                </Whisper>

                                <ButtonPrimary onClick={handleSubmit}>Save/Edit</ButtonPrimary>
                                <ButtonClose onClick={() => removeIvr()}>Delete</ButtonClose>
                            </StyledHeaderItem>
                        }
                    </StyledHeader>

                    {loading && <Loader backdrop size="sm" />}

                    {activeIvr &&
                        <>
                            <Spacer size={30}/>
                            <StyledList>
                                {activeIvrActionList && activeIvrActionList.map((item, index) => {

                                    // console.log(activeActionId, item.id);

                                    const isActive = activeActionId === item.id;

                                    return (
                                        <StyledListItem
                                            key={item.id}
                                            index={index}
                                            onClick={(e) => {
                                                setActiveActionId(item.id);
                                            }}
                                            className={isActive ? "active" : ""}
                                        >
                                            <StyledListItemContent>
                                                <SelectPicker
                                                    className="ivr-action-type-dropdown"
                                                    data={ivrTypes}
                                                    labelKey="name"
                                                    valueKey="name"
                                                    value={item.action_name}
                                                    placeholder="Dialer"
                                                    name="dialer_id"
                                                    searchable={false}
                                                    cleanable={false}
                                                    onChange={(val) => handleChangeType(index, val)}
                                                />

                                                <div className="ivr-action-type-control">
                                                    <StyledListButton
                                                        icon={<Icon icon="plus"/>}
                                                        onClick={() => handleAddAction(index)}
                                                    />

                                                    <StyledListButton
                                                        icon={<Icon icon="minus"/>}
                                                        onClick={() => handleRemoveAction(index)}
                                                    />

                                                    <ButtonGroup style={{paddingLeft: 10}}>
                                                        <StyledListButton
                                                            disabled={index === 0}
                                                            onClick={(e) => handleDecreasePosition(index, e)}
                                                            icon={<Icon icon="caret-up"/>}
                                                        />
                                                        <StyledListButton
                                                            disabled={index === activeIvrActionList.length - 1}
                                                            onClick={(e) => handleIncreasePosition(index, e)}
                                                            icon={<Icon icon="caret-down"/>}
                                                        />
                                                    </ButtonGroup>
                                                </div>
                                            </StyledListItemContent>
                                        </StyledListItem>
                                    )
                                })}
                            </StyledList>
                        </>
                    }

                    <Spacer size={20}/>
                </Panel>
            </FlexGridItem>
            
            <FlexGridItem width="430px" hSpace={'20px'}>
                <BlockIvrParameters
                    {...{
                        activeActionType,
                        activeIvr,
                        loading,
                        activeIvrActionList,
                        activeActionId
                    }}
                />
            </FlexGridItem>
        </FlexGrid>
    )
};

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledHeaderItem = styled.div`
    .rs-btn {
        margin-left: 20px;
    }
`;

const BlockHeader = styled.h5`
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-brand1);
`;

const StyledList = styled(List)`
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: none !important;
    overflow: visible !important;
`;

const StyledListItemContent = styled.div.attrs(() => ({
    className: 'ivr-list-item-content'
}))`
    display: flex;
    
    .ivr-action-type-dropdown {
        width: 100%;
        max-width: 400px;
    }
    
    .ivr-action-type-control {
        margin-left: auto;
        flex-shrink: 0;
        .rs-btn {
            margin-left: 10px;
        }
    }
`;

const StyledListButton = styled(IconButton).attrs(() => ({
    className: 'action-list-button'
}))`
    
`;

const StyledListItem = styled(List.Item)`
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    box-shadow: none !important;
    border-bottom: 1px solid #e5e5ea;
    border-top: 1px solid #e5e5ea;
    
    & + & {
        margin-top: -1px;
    }
    
    &.active {
        background-color: #f2faff;
    }
    
    &:hover {
        position: relative;
        z-index: 10;
        box-shadow: 0 0 4px 1px #e5e5ea !important;
    }
`;