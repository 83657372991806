import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import {
    getAccessListFiltersItems,
    createNewAccessListFiltersItem,
    modifyAccessListFiltersItem,
    removeAccessListFiltersItem,

    getAccessListNotificationSettings,
    unsetAccessListNotificationSettings,
    modifyAccessListNotificationSettings
} from "../../../../actions/accounts";
import AccessListFilters from "./AccessListFilters";


class AccessListFiltersContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    getAccessListFiltersItems = (accountId) => {
        this.props.getAccessListFiltersItems(accountId);
    };

    createNewAccessListFiltersItem = (id, data) => {
        const promise = this.props.createNewAccessListFiltersItem(id, data);
        return promise;
    };

    modifyAccessListFiltersItem = (accountId, alf_key, data) => {
        let promise = this.props.modifyAccessListFiltersItem(accountId, alf_key, data);
        return promise;
    };

    removeAccessListFiltersItem = (id) => {
        this.props.removeAccessListFiltersItem(id);
    };

    getAccessListNotificationSettings = (id) => {
        this.props.getAccessListNotificationSettings(id);
    };

    modifyAccessListNotificationSettings = async (id, data) => {
        let reposone = await this.props.modifyAccessListNotificationSettings(id, data);
        return reposone;
    };

    render() {

        let {items, itemsLoading, itemsPage, itemsPerPage} = this.props;

        return (
            <>
                <AccessListFilters
                    accountId={this.props.match.params.id}

                    items={items}
                    itemsLoading={itemsLoading}
                    itemsPage={itemsPage}
                    itemsPerPage={itemsPerPage}

                    notificationSettings={this.props.accessListNotificationSettings}

                    worldzoneList={this.props.worldzoneList}
                    destinationList={this.props.destinationList}
                    subdestinationList={this.props.subdestinationList}

                    getNotificationSettings={this.getAccessListNotificationSettings}
                    unsetNotificationSettings={this.props.unsetAccessListNotificationSettings}
                    modifyNotificationSettings={this.modifyAccessListNotificationSettings}

                    getItems={this.getAccessListFiltersItems}
                    addItem={this.createNewAccessListFiltersItem}
                    modifyItem={this.modifyAccessListFiltersItem}
                    removeItem={this.removeAccessListFiltersItem}/>
            </>
        )
    }
}


const mapStateToProps = ({accounts, references}) => {
    return {
        items: accounts.accessListFiltersList,
        itemsLoading: accounts.accessListFiltersListLoading,
        accessListNotificationSettings: accounts.accessListNotificationSettings,

        worldzoneList: references.worldzone_list,
        destinationList: references.destination_list,
        subdestinationList: references.subdestination_list,
    }
}

export default compose(
    connect(mapStateToProps, {
        getAccessListFiltersItems,
        removeAccessListFiltersItem,
        createNewAccessListFiltersItem,
        modifyAccessListFiltersItem,

        getAccessListNotificationSettings,
        unsetAccessListNotificationSettings,
        modifyAccessListNotificationSettings,
    }),
    withRouter
)(AccessListFiltersContainer);