import React, {useState, useEffect} from "react";
import PaymentsListTable from "./PaymentsListTable";
import { Spacer } from "../../../components/base/Spacer";
import {getISOTime} from "../../../utils/helpers";
import PaymentsListFilter from "./PaymentsListFilter";


export default ({
    paymentItems, 
    paymentItemsLoading,
    getPaymentList,
    accounts
}) => {

    const dateStart = new Date();
    dateStart.setDate(1);
    dateStart.setHours(0, 0, 0, 0);
    dateStart.setMonth(dateStart.getMonth() - 1);
    const dateEnd = new Date(new Date().setHours(23, 59, 59, 999));



    useEffect( () => {
        const data = {
            target: {
                account_id_list: null
            },
            filter: {
                start_date: getISOTime(dateStart),
                end_date: getISOTime(dateEnd),
            }
        };
        getPaymentList(data);
    }, [] );


    const handleChangeFilters = (value) => {
        const dateStart = new Date(value.start_end_date[0].setHours(0, 0, 0, 0));
        const dateEnd = new Date(value.start_end_date[1].setHours(23, 59, 59, 999));

        let filterValue = {...value};
        const accountIdList = value.account_id_list !== undefined ? value.account_id_list : []

        delete filterValue.account_id_list;
        filterValue = {
            start_date: getISOTime(dateStart),
            end_date: getISOTime(dateEnd),
            start_end_date: value.start_end_date
        };

        getPaymentList({
            target: {
                account_id_list: accountIdList.length ? accountIdList : null
            },
            filter: filterValue
        });
    };


    return (
        <>
            <PaymentsListFilter
                accounts={accounts}
                filters={{
                    start_end_date: [dateStart, dateEnd]
                }}
                onChangeFilters={handleChangeFilters}
            />

            <Spacer/>

            <PaymentsListTable
                withFilters
                data={paymentItems}
                loading={paymentItemsLoading}
            />
        </>
    )
};