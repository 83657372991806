import React, {useState, useEffect} from "react";
import {ControlLabel, Divider, Form, FormControl, Input, InputNumber, Loader, Panel} from "rsuite";
import styled from "styled-components";
import {Spacer} from "../../../components/base/Spacer";
import {params} from "./parametersData";

export default (
    {
        activeIvrActionList,
        activeActionId,
        loading,
        activeActionType = null
    }
) => {

    // console.log("activeActionType", activeActionType);

    const [value, setValue] = useState({});

    useEffect(() => {
        const actionData = activeIvrActionList && activeIvrActionList.find(item => item.id === activeActionId);
        if (actionData) {
            setValue(actionData.params || {});
        }
    }, [activeIvrActionList, activeActionId]);

    return (
        <Panel  style={{minHeight: '100%'}} shaded>
            <BlockHeader>Parameters</BlockHeader>
            <Spacer size={20} />

            {loading && <Loader backdrop size="sm" />}

            <Form
                formValue={value}
            >

                {activeActionType === 'Wait before connect' &&
                <>
                    <FormSection>
                        <FormSectionTitle>Delay before ringback</FormSectionTitle>
                        <FormRow>
                            <ControlLabel>from</ControlLabel>
                            <ParameterNumberField name="before_min"/>

                            <ControlLabel>to</ControlLabel>
                            <ParameterNumberField name="before_max"/>
                        </FormRow>
                    </FormSection>

                    <FormSection>
                        <FormSectionTitle>Ringback duration</FormSectionTitle>
                        <FormRow>
                            <ControlLabel>from</ControlLabel>
                            <ParameterNumberField name="ringing_min"/>

                            <ControlLabel>to</ControlLabel>
                            <ParameterNumberField name="ringing_max"/>
                        </FormRow>
                    </FormSection>
                </>
                }

                {activeActionType === 'Playback' &&
                <FormSection>
                    <FormSectionTitle>Playback</FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="playback_min"/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="playback_max"/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Playback repeat' &&
                <FormSection>
                    <FormSectionTitle></FormSectionTitle>
                    <FormRow>
                        <ControlLabel>Repeat</ControlLabel>
                        <ParameterNumberField name="repetitions"/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Radio' &&
                <FormSection>
                    <FormSectionTitle>Radio params</FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="radio_min"/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="radio_max"/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Send DTMF' &&
                <FormSection>
                    <FormSectionTitle></FormSectionTitle>
                    <FormRow>
                        <ControlLabel>DTMF Signal</ControlLabel>
                        <FormControl accepter={Input} name="digits"/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Silence' &&
                <FormSection>
                    <FormSectionTitle>Silence params</FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="silence_min"/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="silence_max"/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Timeout' &&
                <FormSection>
                    <FormSectionTitle>Timeout</FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="min"/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="max"/>
                    </FormRow>
                </FormSection>
                }

                {activeActionType === 'Wait' &&
                <FormSection>
                    <FormSectionTitle>Wait</FormSectionTitle>
                    <FormRow>
                        <ControlLabel>from</ControlLabel>
                        <ParameterNumberField name="wait_min"/>

                        <ControlLabel>to</ControlLabel>
                        <ParameterNumberField name="wait_max"/>
                    </FormRow>
                </FormSection>
                }

            </Form>
            <Spacer size={20}/>
        </Panel>
    )
};


const BlockHeader = styled.h5`
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-brand1);
`;

const FormSection = styled.div`
    & + * {
        padding-top: 20px;
    }
`;

const FormSectionTitle = styled.p`
    padding-bottom: 10px;
    font-weight: 500;
`;

const FormRow = styled.div`
    display: flex;
    align-items: center;
    
    .rs-control-label {
        margin-right: 10px;
    }
    .form-input {
        padding-right: 15px;
    }
    
    & + * {
        padding-top: 20px;
    }
`;

const ParameterNumberField = (props) => (
    <div className="form-input" style={{maxWidth: '90px'}}>
        <FormControl
            accepter={InputNumber}
            errorPlacement="topStart"
            min={0}
            step={1}
            {...props}
        />
    </div>
);