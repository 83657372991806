import React, {useState, useEffect} from 'react';
import RatesTable from './RatesTable';
import RatesFilters from './RatesFilters';
import styled from 'styled-components';
import ExportPrice from './ExportPrice';
import {Form, RadioGroup, Radio, SelectPicker, Input, InputNumber, Schema, Alert, DatePicker, FormGroup} from 'rsuite';
import CustomField from '../../../components/Form/CustomField';
import Modal from '../../../components/Modal';
import {api} from '../../../api/loginRoutes';
import Checkbox from '../../../hoc/Checkbox';
import AddPrice from './AddPrice';
import { getRates } from '../../../actions/rates';
import RateField from "../../../components/Form/RateField";
import {connect} from "react-redux";
import {toStartDayUTCTime, toUTCDateTime} from 'utils';
import {DEFAULT_PER_PAGE_SMALL} from 'const';
const { StringType, NumberType } = Schema.Types;

const TabRates = ({rates, ratesLoading, worldzone_list, subdestination_list, destination_list, getItems, account_id, defaultSPKey}) => {
    const [items, setItems] = React.useState(rates || []);
    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState( (rates || []).length);
    const [per_page, onChangePerPage]  = React.useState(+localStorage.getItem('ratesPerPage') || DEFAULT_PER_PAGE_SMALL);
    const [activePage, onChangePage] = React.useState(1);

    const [filter, setFilter] = React.useState({sp_key: defaultSPKey});

    React.useEffect(() => {
        setItems(rates || []);
        setTotal((rates || []).length);
        onChangePage(1);
    },[rates]);

    useEffect( () => {
        getItems(filter);
    }, [] );
    
    const onChangeFilters = (filter) => {
        getItems(filter);
        setFilter(filter);
    };

    const [deleteModal, setDeleteModal] = React.useState(false);
    const [deleteID, setDeleteId] = React.useState(false);

    const onClickDelete = (id) => { 
        setDeleteId(id);
        setDeleteModal(true)
    };

    const deleteId = async () => {
        setDeleteModal(false);
        setLoading(true);
        const result = await api('account_price:remove',{target: {account_id, account_price_id: deleteID}});
        setLoading(false);
        if(result){
            return getItems(filter);
        }
        
    };
    const onSavePerPage = (per_page) => {
        localStorage.setItem('ratesPerPage', per_page);
        onChangePerPage(per_page);
    };

    const [editModal, setEditModal] = React.useState(false);
    const [rateToEdit, setRateToEdit] = React.useState(null);
    const [editDisabled, setEditDisabled] = React.useState(false);
    const [formRef , setFormRef] = React.useState(null);

    const onClickEdit = (rate) => {
        setRateToEdit(rate);
        setEditModal(true)   
    };

    const editId = async () => {
        if(formRef.check()){
            const data = {...formRef.getFormValue()};
            setEditDisabled(true);

            if (data.withDate) {
                data.start_date = data.start_date ? toStartDayUTCTime(new Date(data.start_date)) : null;
            } else {
                delete data.start_date;
            }

            delete data.withDate;
            if (data.hasOwnProperty('end_date'))
                delete data.end_date;

            const result = await api('account_price:modify',{
                target:{account_id, account_price_id: rateToEdit.id},
                ...data,
                rate: +formRef.getFormValue().rate
            });

            setEditDisabled(false);

            if (result) {
                setEditModal(false);
                getItems(filter);
            }

        }
    };

    return (
        <Container>
            <RatesFiltersContainer>
                <RatesFilters {...{
                    worldzone_list, 
                    subdestination_list, 
                    destination_list
                }}
                onChange = {onChangeFilters}
                />
            </RatesFiltersContainer>
            <ExportPrice account_id = {account_id} filter = {filter} />
            <AddPrice {...{
                subdestination_list,
                account_id,
                }}
                updateItems = {() => getItems(filter)}
            />
            <RatesTable  
                data={items} 
                loading={ratesLoading || loading}
                onChangePerPage={onSavePerPage}
                {...{
                    total,
                    per_page,
                    activePage, 
                    onChangePage,
                    onClickDelete,
                    onClickEdit
                }}

            />
            <Modal show={deleteModal} title="Deleting Price" footer
                onClose={() =>{setDeleteModal(false)}}
                onSuccess={deleteId}
                >
                <p>You are sure ?</p>
            </Modal>

            <Modal show={editModal} title="Change Rate" footer
                onClose={() =>{setEditModal(false)}}
                onSuccess={editId}
                successText="Update"
                >
                
                <ModalForm  disabled={editDisabled} updateFormRef={setFormRef} rate={rateToEdit}/>
            </Modal>
        </Container>
    )
};

const mapStateToProps = ({references}) => ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapStateToProps)(TabRates);

const Container = styled.div`
    margin-top: 30px
`;

const RatesFiltersContainer = styled.div`
    display: inline-block;    
`;

const formModel = Schema.Model({
    rate: NumberType().isRequired('Required'),
});

const ModalForm  =  ({updateFormRef, rate}) => {
    const [withDate, setWithDate] = useState(false);

    return (
        <Form
            layout = "inline"
            model={formModel}
            formDefaultValue={rate}
            ref= { ref => updateFormRef(ref)}
        >
            <RateField name="rate" label="Rate"/>
            <br/>
                <Checkbox onChange={(checked) => {setWithDate(checked)}} name={'withDate'}>From date</Checkbox>
                <CustomField
                    accepter={DatePicker}
                    placeholder="Enter Date"
                    name="start_date"
                    disabled={!withDate}
                    ranges={[
                        {
                            label: 'Today',
                            value: new Date()
                        },
                        {
                            label: 'Tomorrow',
                            value: new Date().setDate(new Date().getDate() + 1)
                        }
                    ]}
                />
            <br/>
            <Checkbox name = "update_allocated_numbers">Update for all allocated number</Checkbox>          
         </Form>
    );
};