import React, {createRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {confirmPaymentDetails} from '../../actions/paymentDetails';
import Header from '../../components/base/PageHeader';
import {
    Header as PageHeader,
    Container,
    Content,
    FlexboxGrid,
    Icon,
    Nav,
    Navbar,
    Loader,
    Divider,
    Form
} from 'rsuite';
import {Link, withRouter} from 'react-router-dom';
import './PaymentDetails.css';
import Button from 'rsuite/es/Button';
import {flaser} from 'object-flaser';
import fields, {
    // organization field groups
    customerConfirmFields,
    companyConfirmFields,
    // payment method field groups
    bankTransferConfirmFields,
    paypalFields,
    bitcoinFields,
    hawalaFields,
    otherTransferSystemsConfirmFields
} from "./ModalAddPaymentDetail/fields";
import {FieldInput, FieldSelectCountry, FieldTextArea} from "./ModalAddPaymentDetail/Field";
import * as S from '../../pages/PaymentDetails/ModalAddPaymentDetail/styled';
import {useIntl} from 'react-intl';
import m from "../../definedMessages";


const methodFields = [
    bankTransferConfirmFields,
    paypalFields,
    otherTransferSystemsConfirmFields,
    otherTransferSystemsConfirmFields,
    hawalaFields,
    bitcoinFields
];

const styleContainer = {
    minHeight: '100vh'
};

const CONFIRMED = false;
const ALREADY_CONFIRMED = true;


const CONFIRM_STATUS__DELETED = 3;

const PaymentDetailConfirm = ({
    auth, 
    loading, 
    history, 
    confirmPayment, 
    confirmStatus, 
    countryList, 
    confirmPaymentDetails,
})=> {

    const {formatMessage} = useIntl();

    const statusLabels = [
        formatMessage(m.confirmed),
        formatMessage(m.alreadyHasBeenConfirmed),
        formatMessage(m.alreadyHasBeenRejected),
        formatMessage(m.alreadyHasBeenDeleted),
    ];
    
    const tokenId = history.location.pathname.split('/').filter(item => item)[1];
    let textAreaRef = createRef();


    const setFormFields = (organizationType, paymentMethod) => {
        // company + hawala
        if (organizationType === 2 && paymentMethod === 5) {
            return [[], []];
        }
        // private person + hawala
        if (organizationType === 1 && paymentMethod === 5) {
            return [[], ['hawala_description']];
        }
        // default
        const organizationFields = organizationType === 1 ? customerConfirmFields : companyConfirmFields;
        return [organizationFields, methodFields[paymentMethod - 1]];
    };


    const renderFields = (fieldNames = [], formValue) => (

        fieldNames.map((fieldName) => {

            const {type, name, label: defaultLabel, labelId, componentClass, hideOn, ...props} = fields[fieldName];

            // hide on option
            if (hideOn && Array.isArray(hideOn)) {
                for (const conditionItem of hideOn) {
                    const keys = Object.keys(conditionItem || {});
                    const status = keys.map(key => {
                        return formValue[key] && formValue[key] === conditionItem[key];
                    });

                    if (!status.includes(false))
                        return null;
                }
            }

            // get label
            const label = labelId && m[labelId] ? formatMessage(m[labelId]) : defaultLabel;

            const formField = formValue.find(field => Object.keys(field).includes(name));

            if (componentClass === 'textarea') 
                return (
                    <FieldTextArea 
                        value={formField[name]} 
                        readOnly={true} 
                        key={name} 
                        name={name} 
                        label={label} 
                        {...props} 
                    />
                );
            
            if (type === 'select_country') 
                return (
                    <FieldSelectCountry 
                        key={name} 
                        name={name} 
                        label={label} 
                        data={countryList} 
                        {...props} 
                    />
                );
            
            if (type !== 'file') 
                return (
                    <FieldInput 
                        readOnly={true} 
                        key={name} 
                        name={name} 
                        label={label} 
                        {...props} 
                    />
                )

        })
    );

    const confirmForm = (data) => {
        return  <Form formValue={flaser(data)}>
            <S.FormColumns>
                {setFormFields(data.ot_key, data.pm_key).map((fieldsGroup, index, arr) => {
                    if (!fieldsGroup.length)
                        return null;
                    return (
                        <>
                            {
                                index > 0 && arr[0].length > 0 &&
                                <Divider style={{width: 0}} vertical />
                            }
                            <S.FormColumn colWidth="50%">
                                {renderFields(fieldsGroup, [data])}
                            </S.FormColumn>
                        </>
                    )
                })}
            </S.FormColumns>
        </Form>
    };


    useEffect(() => {
        if (!confirmPayment || confirmPayment && !confirmPayment.length) {
            confirmPaymentDetails(tokenId);
        }
    }, []);

    const statusHeader = Number.isInteger(confirmStatus) && statusLabels[confirmStatus];
    return (
        !auth 

        ? <Container style={styleContainer}>
            <PageHeader>
                <Navbar appearance="inverse">
                    <Navbar.Header>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav>
                            <Link to={'/login'}>< Nav.Item icon={<Icon icon="home"/>}>Login</Nav.Item></Link>
                        </Nav>
                    </Navbar.Body>
                </Navbar>
            </PageHeader>
            <Content>
                <FlexboxGrid justify="center" style={{width: '100%'}}>
                    <FlexboxGrid.Item>
                        {loading || !Object.keys(confirmPayment).length
                            ? <Header
                                className="unlogin_loader"
                                title={<>{formatMessage(m.confirmation)}... <Loader/></>}
                            />
                            : <div className="unlogin_confirm_payment_content">
                                <Header title={statusHeader} />
                                {confirmForm(confirmPayment)}
                                <Link className="link" to={'/login'}><Button appearance="primary">Please log in to continue</Button></Link>
                            </div>
                        }
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>

        : loading || !Object.keys(confirmPayment).length
            ? <Header>{formatMessage(m.confirmation)}... <Loader/></Header>
            : <div className="login_confirm_payment_content">
                <Header title={statusHeader}/>
                {confirmStatus !== CONFIRM_STATUS__DELETED ? confirmForm(confirmPayment) : <></>}
            </div>
    );
};


const mapState = ({auth, references, paymentDetails}) => ({
    auth: auth.auth,
    loading: auth.loading,
    confirmPayment: paymentDetails.confirm_payment,
    confirmStatus: paymentDetails.confirm_status,
    countryList: references.country_list
});

export default withRouter(connect(mapState, {
    confirmPaymentDetails
})(PaymentDetailConfirm));
