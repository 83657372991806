import {API_CREATE_KEY, API_DELETE_KEY, API_MODIFY_KEY, API_PAGE as PREFIX, API_PAGE_LIST} from '../const';
import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {Alert} from 'rsuite';

const setKeyList = createAction('SET_ITEMS_' + PREFIX);
const setCreateKey = createAction(API_CREATE_KEY);
const setModifyKey = createAction(API_MODIFY_KEY);
const setDeleteKey = createAction(API_DELETE_KEY);

export const getKeyList = (id) => (dispatch) => {
    api(API_PAGE_LIST, {target: {account_id: id}}).then((response) => {
        if (response !== undefined) {
            dispatch(setKeyList({
                keyList: response.account_apikey_list || []
            }));
        }
    });
};

export const createApiKey = (account_apikey, id) => async (dispatch) => {
    await api(API_CREATE_KEY, {
        account_apikey: {
            active: account_apikey.active,
            access_list: account_apikey.access_list
        },
        target: {account_id: id}
    }).then((response) => {
        if (response !== undefined) {
            const api_key = response.account_apikey.api_key;
            dispatch(setCreateKey({
                apiKey: api_key
            }));
            Alert.success(`${api_key} has been created`);

            api(API_PAGE_LIST, {target: {account_id: id}}).then((response) => {
                if (response !== undefined) {
                    dispatch(setKeyList({
                        keyList: response.account_apikey_list || []
                    }));
                }
            });
        }
    });
};

export const modifyApiKey = (account_apikey, id, api_key) => async (dispatch) => {
    await api(API_MODIFY_KEY, {
        target: {api_key, account_id: id},
        account_apikey: {active: account_apikey.active, access_list: account_apikey.access_list}
    }).then((response) => {
        if (response !== undefined) {
            const api_key = response.account_apikey.api_key;
            dispatch(setModifyKey({
                apiKey: api_key
            }));

            Alert.success(`${api_key} has been modified`);

            api(API_PAGE_LIST, {target: {account_id: id}}).then((response) => {
                if (response !== undefined) {
                    dispatch(setKeyList({
                        keyList: response.account_apikey_list || []
                    }));
                }
            });
        }
    });
};

export const deleteApiKey = (api_key, id) => async (dispatch) => {
    await api(API_DELETE_KEY, {target: {api_key}}).then((response) => {
        if (response !== undefined) {
            const api_key = response.account_apikey.api_key;
            dispatch(setDeleteKey({
                apiKey: api_key
            }));

            Alert.success(`${api_key} has been deleted`);

            api(API_PAGE_LIST, {target: {account_id: id}}).then((response) => {
                if (response !== undefined) {
                    dispatch(setKeyList({
                        keyList: response.account_apikey_list || []
                    }));
                }
            });
        }
    });
};

