import React, {useEffect, useState} from 'react';
import {Form, Schema, Alert} from 'rsuite';
import CustomField from 'pages/NewAccount/CustomField';
import styled from 'styled-components';
import Button from 'rsuite/es/Button';
import 'pages/Account/TabSelfAllocation/selfAllocation.css';
import {containsOnlyNumbers} from '../../../utils';



export default ({limitState, onChangeLimitState, cleanedFields, accountLimitKeys, defaultAllocationLimits,
                    cleanLimit, model, formError, onSubmit, modifiedLimit, onChangeFormError, fillLimitFormRef,
                    formLimitRefs}) => {

    useEffect(() => {
        Object.keys(formLimitRefs).map(limitKey => formLimitRefs[limitKey].check());
    }, [limitState]);

    return accountLimitKeys.map(limitKey => {
        const {once = {}, daily = {}, daily_sde = {}} = Object.keys(model).includes(limitKey) && model[limitKey];
        const limitLabels = `${limitKey.charAt(0).toUpperCase()}${limitKey.replace('_', ' ').slice(1)}`;
        const combineModel = Schema.Model.combine(once, daily, daily_sde);

        return <>
            <Header><Name>{limitLabels}</Name></Header>
            <InlineFormLimits
                ref={ref => fillLimitFormRef(limitKey, ref)}
                onCheck={(check) => {
                    onChangeFormError((prevState) => {
                        return {...prevState, [limitKey]: check}
                    });
                }}
                formValue={limitState[limitKey]}
                onChange={(value) => {
                    Object.keys(value).map((elem) => {
                        value[elem] = value[elem] && containsOnlyNumbers(value[elem]) ? parseInt(value[elem], 10) : 0
                    });
                    onChangeLimitState({
                        ...limitState,
                        [limitKey]: value
                    });

                }}
                onSubmit={() => {
                    onSubmit(null, {[limitKey]: limitState[limitKey]}, limitKey)
                }}
                {...{
                    ...(Object.keys(defaultAllocationLimits).includes(limitKey) && limitKey ? {model: combineModel} : {})
                }}
            >
                <BoxLimitFields>
                    {(limitState[limitKey]['once'] || limitState[limitKey]['once'] === 0) && <CustomField
                        errorPlacement="topStart"
                        className={`cleanable-custom-field ${cleanedFields[limitKey]['once'] && 'has-changed'} ${modifiedLimit === limitKey && 'has-modified'}`}
                        name="once" label="One time"/>}
                    {(limitState[limitKey]['daily'] || limitState[limitKey]['daily'] === 0) && <CustomField
                        errorPlacement="bottomStart"
                        className={`cleanable-custom-field ${cleanedFields[limitKey]['daily'] && 'has-changed'} ${modifiedLimit === limitKey && 'has-modified'}`}
                        name="daily" label="Daily"/>}
                    {(limitState[limitKey]['daily_sde'] || limitState[limitKey]['daily_sde'] === 0) && <CustomField
                        errorPlacement="topStart"
                        className={`cleanable-custom-field ${cleanedFields[limitKey]['daily_sde'] && 'has-changed'} ${modifiedLimit === limitKey && 'has-modified'}`}
                        name="daily_sde" label="Destination"/>}
                </BoxLimitFields>
                <div>
                    <CleanLimitButton><Button onClick={() => {
                        Object.values(formLimitRefs).map(ref => ref.cleanErrors());
                        cleanLimit(limitKey);
                    }}>Clean</Button></CleanLimitButton>
                    <SaveLimitButton><Button type="submit" disabled={formError[limitKey] && Object.keys(formError[limitKey]).length > 0}>Save</Button></SaveLimitButton>
                </div>
            </InlineFormLimits>
        </>
    });
};
const Header = styled.div`
    margin-bottom: 15px;
    margin-left: 5px;
    margin-top: 10px;
`;
const Name = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #8256C8;
    margin-right: 3px;
`;
const InlineFormLimits = styled(Form)`
  margin-left: 5px;
  display: flex;
  white-space: nowrap;
  .rs-control-label {
      width: auto !important;
      min-width: 75px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left !important;
  }
  .rs-form-group {
      width: 100%;
      max-width: 220px;
      min-width: 165px;
      display: flex;
      margin-right: 10px;
      margin-bottom: 0 !important;
  }
`;
const BoxLimitFields = styled.div`
    min-width: 42%;
    height: 36px;
    display: flex;
    margin-bottom: 15px;
`;
const CleanLimitButton = styled.div`
    display: inline-block;
    padding: 0;
    margin-right: 20px;
    button {
        background: #F8432F;
        color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        padding: 8px 60px;
        border-radius: 5px;
    }
    button:disabled {
        color: #fff;
        background: #939191;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
        opacity: 0.3;
    }
    button:disabled:hover {
        color: #fff;
        opacity: 0.45;
        background: #939191;
    }
`;

const SaveLimitButton = styled.div`
    display: inline-block;
    padding: 0;
    margin-right: 20px;
    button {
        background: #20BA88;
        color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
        padding: 8px 60px;
        border-radius: 5px;
    }
    button:disabled {
        color: #fff;
        background: #939191;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
        opacity: 0.3;
    }
    button:disabled:hover {
        color: #fff;
        opacity: 0.45;
        background: #939191;
    }
`;
