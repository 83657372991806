import React, {useState} from "react";
import Modal from '../../components/Modal';
import { useWindowWidth } from "../../hooks";

export default ({
    key,
    show,
    onClose,
    maxWidth = 600,
    selectedItem,
    accountId,
    removePaymentDetails,
    getPaymentDetailsList
}) => {

    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const handleSuccess = async () => {
        setLoading(true);

        const data = {
            target: {
                payment_detail_id: (selectedItem || {})['id']
            },
        }

        await removePaymentDetails(data);

        getPaymentDetailsList({target: {account_id: accountId}});
        setLoading(false);
        onClose();
    };

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => onClose(false)}
            title="Remove payment detail"
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            loading={loading}
            onSuccessClose={false}
            successButton="Yes"
            focusTarget="close"
            onSuccess={handleSuccess}
        >
            Are you sure?
        </Modal>
    )
}