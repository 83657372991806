import React from "react";
import {Form, FormControl, Icon, InputGroup} from "rsuite";
import styled from "styled-components";
import {debounce} from "lodash";

export default ({
    filters,
    onChange,
}) => {

    return (
        <StyledForm
            formDefaultValue={filters}
            onChange={debounce(onChange, 500)}
        >
            <StyledInputGroup>
                <FormControl
                    name="str"
                    errorPlacement="topRight"
                    placeholder={"Account name/Email address"}
                />
                <InputGroup.Addon><Icon icon="search" /></InputGroup.Addon>
            </StyledInputGroup>
        </StyledForm>
    )
}

const StyledForm = styled(Form)`
    && {
        margin-right: 20px;
    }
`

const StyledInputGroup = styled(InputGroup)`
    && {
        max-width: 320px; 
        width: 100%;
        overflow: visible;
    }

    &&&& {
        .rs-input {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
    }
`;