import React from "react";
import {CheckPicker, Form, FormControl} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import FieldDataRange from "../../components/Form/FieldDataRange";
import {debounce} from "lodash";

const siteList = [
    {id: 2, name: 'Client'},
    {id: 4, name: 'Panel'},
];

export default ({
    userList,
    actionList,
    accounts,
    defaultFilter,
    onChangeFilter
}) => {

    return (
        <>
            <Form
                formDefaultValue={defaultFilter}
                onChange={debounce((value) => {
                    onChangeFilter(value);
                }, 1000)}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        <FieldDataRange
                            name="range"
                            placement="bottomStart"
                            placeholder={"Date"}
                            style={{ width: 234 }}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            sticky
                            name="account_id_list"
                            data={accounts}
                            valueKey="id"
                            labelKey="name"
                            style={{ width: 224 }}
                            placeholder={"Accounts"}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            sticky
                            name="account_user_id_list"
                            data={userList}
                            valueKey="account_user_id"
                            labelKey="account_user_name"
                            style={{ width: 224 }}
                            placeholder={"Users"}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            sticky
                            name="action_list"
                            data={actionList}
                            valueKey="action"
                            labelKey="action"
                            style={{ minWidth: 224, maxWidth: 400 }}
                            placeholder={"Actions"}
                            menuStyle={{minWidth: 400}}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <FormControl
                            accepter={CheckPicker}
                            name="site_list"
                            data={siteList}
                            valueKey="id"
                            labelKey="name"
                            style={{width: 224}}
                            placeholder={"Site list"}
                        />
                    </FlexGridItem>

                </FlexGrid>
            </Form>
        </>
    )
};