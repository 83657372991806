import React from 'react';
import {Input, Form, CheckPicker, Schema} from 'rsuite';
import CustomField from './ContactCustomField';
import {fullPhoneRegex} from '../../utils';
import {ButtonListRemove} from "../../components/base/BaseButton";

const { StringType, ArrayType } = Schema.Types;

export const contactModel = Schema.Model({
    person: StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    email: StringType().isRequired('Required').isEmail('Email incorrect format'),
    phone: StringType().addRule( value => fullPhoneRegex.test(value) , 'Please enter valid phone number'),
    messenger: StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    role_list: ArrayType().isRequired('This field required').minLength(1, 'Required')
});


export const initContact = {person: '', email: '', phone: '', messenger: '', role_list: [], key: null};
const roles = ["billing", "rates", "technical", "general"].map(x => ({value: x, label: x}));

export default ( {disabled, contacts, updateContacts, formsRef, addableIsChanged, initialList, updateFormRef} ) => {
    const updateFormValues = (formValues, key) => {
        const value = [
            ...contacts.map( contact =>
                contact.key === key
                    ?
                    {...contact, ...formValues} : contact
            )
        ];
        updateContacts(value);
    };

    const onRemove = (key) => {
        updateContacts( contacts.filter( contact => contact.key !== key ) )
    };

    return contacts.map(
        (contact) => {
            const contactChanged = addableIsChanged(contact.id, initialList, contact);
            return <Form
                layout="inline"
                key={contact.key}
                ref={ref => updateFormRef(ref, contact.key)}
                onChange={(formValues) => {
                    updateFormValues(formValues, contact.key)
                }}
                formDefaultValue={contact}
                className={`tab-general-addable ${contactChanged && 'has-changed'}`}
                model={contactModel}
            >
                <CustomField
                    disabled={disabled}
                    accepter={Input}
                    name="person"
                    placeholder="Name"
                />
                <CustomField
                    disabled={disabled}
                    accepter={Input}
                    name="email"
                    placeholder="Email"
                />
                <CustomField
                    disabled={disabled}
                    accepter={Input}
                    name="phone"
                    placeholder="Phone"
                />
                <CustomField
                    disabled={disabled}
                    accepter={Input}
                    name="messenger"
                    placeholder="Messenger"
                />
                <CustomField
                    disabled={disabled}
                    accepter={CheckPicker}
                    name="role_list"
                    data={roles}
                    placeholder="Roles"
                    searchable={false}
                    classPrefix="minWidth"
                />
                <div style={{marginTop: 5, marginLeft: 20, display: 'inline-block'}}>
                    {contacts.length > 1 &&
                    <ButtonListRemove onClick={() => onRemove(contact.key)}/>}
                </div>
            </Form>
        }
    );
}