import React from 'react';
import {Table as _Table} from 'rsuite';
import PanelLayout from '../../components/base/PanelLayout';
import PageHeader from '../../components/base/PageHeader';
import PriceFilters from './PriceFilters';
import Ranges from './Ranges';
import AddNewSubdestination from './AddNewSubdestination';
import styled from 'styled-components';
import ShowPrice from './ShowPrice';
import ExportPrice from './ExportPrice';
import EditPrice from './EditPrice';
import Table from '../../components/Table';
import {connect} from 'react-redux';
import {getPrices} from '../../actions/prices';
import {getRanges} from '../../actions/ranges';
import {USD_DEFAULT_CURRENCY_KEY} from '../../const';
import {checkPermissionsFor} from 'store/storeHelpers';
import {SP_CHANGE_RATE_METHOD, SP_PRICE_NEW_SUBDESTINATION_METHOD} from 'const/apiMethods';
import {getTemplate} from 'actions/numbers';
import {ButtonRowEdit, ButtonWrapper} from "../../components/base/BaseButton";
import {Spacer} from "../../components/base/Spacer";


class Prices extends React.Component {
    state = {
        sde_key: null,
        edit: false,
        ptActiveTermRates: [],
        otpType: null,
        columns: [
            {label: 'Subdestination', dataKey: 'subdestination_name'},
        ]
    };

    constructor(props) {
        super(props);

        this.rangesFilters = {};

        this.filters = {
            cur_key: USD_DEFAULT_CURRENCY_KEY,
            p_key: props.defaultSPKey,
            sp_key: props.defaultSPKey
        };
    }

    componentDidMount() {
        this.props.getPrices(this.filters, 1);
        // При открытии Prices из страниц не выбирался первый элемент списка прайсов. Add ranges random требует sde_key
        this.selectFirstPrice();

        if (this.props.paymentTermsList.length) {
            this.addTermColumns();
        }
    }

    shouldComponentUpdate({service_plan_price_list: list},) {
        const {service_plan_price_list} = this.props;

        if (!service_plan_price_list.length
            &&
            service_plan_price_list !== list
            &&
            list[0]
            &&
            list[0].sde_key
        ) {
            this.setState({sde_key: list[0].sde_key}, () => {
                this.getRanges(this.filters);
            });
            return false;
        }

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.paymentTermsList !== prevProps.paymentTermsList) {
            this.addTermColumns();
        }

        // if (prevProps.service_plan_price_list !== this.props.service_plan_price_list) {
        //     this.selectFirstPrice();
        // }
    }

    addTermColumns = () => {
        const {paymentTermsList} = this.props;
        const newColumns = [];
        for (const term of paymentTermsList) {
            newColumns.push({label: term.name.replace("_", "-"), dataKey: `payment_terms_rate:${term.name}`});
        }
        this.setState({columns: [...this.state.columns, ...newColumns]});
    }

    onClickPrice = (sde_key) => {
        this.setState({sde_key}, () => {
            this.getRanges(this.rangesFilters, 1);
        });
    };

    onChangeFilters = (filters) => {
        const {servicePlanList} = this.props;

        const currentServicePlan = servicePlanList.find(item => item.sp_key === filters.p_key);
        this.setState({otpType: currentServicePlan.otp_type});

        this.filters = filters;
        this.props.getPrices(filters, 1);
        this.selectFirstPrice();
    };

    getRanges = (filters, page = 1) => {
        const {sde_key} = this.state;
        const _filters = {...filters};

        delete _filters['cur_key'];

        this.props.getRanges({..._filters, sde_key}, page);
    };

    onEditPrice = (ptRates) => {
        this.setState({
            edit: true,
            ptActiveTermRates: ptRates
        });
    };

    selectFirstPrice = () => {
        const {service_plan_price_list} = this.props;

        if (service_plan_price_list.length
            &&
            service_plan_price_list[0]
            &&
            service_plan_price_list[0].sde_key
        ) {
            const first_sde_key = service_plan_price_list[0].sde_key;
            this.setState({sde_key: first_sde_key}, () => {
                this.getRanges(this.rangesFilters);
            });
        }
    };

    handleChangeRangesFilters = (filters) => {
        this.rangesFilters  = filters;
    };

    render() {

        const {
            service_plan_price_list, loading, count, page, account, getTemplate,
            currency_list, worldzone_list, subdestination_list, destination_list, price_list,
            ranges, rangesLoading, rangesPage, rangesCount, paymentTermsList, servicePlanList
        } = this.props;

        window.sde_list = service_plan_price_list;

        const {sde_key, edit, ptActiveTermRates, columns, otpType} = this.state;

        return (
            <PanelLayout>

                {edit &&
                    <EditPrice
                        sde_key={sde_key}
                        cur_key={this.filters.cur_key}
                        p_key={this.filters.p_key}
                        sp_key={this.filters.p_key}
                        ratesValue={ptActiveTermRates}
                        servicePlanList={servicePlanList}
                        paymentTermsList={paymentTermsList}
                        update={() => this.props.getPrices(this.filters, page)}
                        setShow={() => this.setState({edit: false})}
                    />
                }

                <Spacer size={18}/>

                <PageHeader title={"Prices"}>
                    <ButtonWrapper>
                        <ShowPrice
                            currency_list={currency_list}
                            paymentTermsList={paymentTermsList}
                            sp_key={this.filters.p_key}
                            getTemplate={getTemplate}
                            account={account}
                            otpType={otpType}
                        />
                        <ExportPrice
                            sp_key={this.filters.p_key}
                            account={account}
                            getTemplate={getTemplate}
                            otpType={otpType}
                        />
                    </ButtonWrapper>
                </PageHeader>

                <Spacer size={13}/>

                <Top>
                    <div>
                        <PriceFilters
                            onChange={this.onChangeFilters}
                            {...{
                                currency_list,
                                worldzone_list,
                                subdestination_list,
                                destination_list,
                                price_list
                            }}/>
                    </div>
                    <div>
                        {checkPermissionsFor(SP_PRICE_NEW_SUBDESTINATION_METHOD) &&
                            <AddNewSubdestination
                                sp_key={this.filters.sp_key}
                                p_key={this.filters.p_key}
                                update={() => this.props.getPrices(this.filters, page)}
                                currency_list={currency_list}
                                paymentTermsList={paymentTermsList}
                            />
                        }
                    </div>

                </Top>
                <Table
                    data={service_plan_price_list}
                    height="50%"
                    width="100%"
                    loading={loading}
                    columns={columns}
                    count={count}
                    per_page={10}
                    page={page}
                    getItems={(page) => this.props.getPrices(this.filters, page)}
                    active_id={sde_key}
                    row_key='sde_key'
                    onRowClick={this.onClickPrice}
                    hidePerPage
                    ispagination
                    ActionCell={checkPermissionsFor(SP_CHANGE_RATE_METHOD) ?
                        ActionCell(this.onEditPrice, paymentTermsList)
                        : null
                    }
                />
                <Ranges
                    onChangeFilters={this.handleChangeRangesFilters}
                    getRanges={this.getRanges}
                    items={!service_plan_price_list.length  && sde_key && ranges && ranges.length ? [] : ranges}
                    loading={rangesLoading}
                    count={rangesCount}
                    page={rangesPage}
                    sde_key={sde_key}
                    filters={this.filters}
                    subdestination_list={subdestination_list}
                    rangesFilters={this.rangesFilters}
                />
            </PanelLayout>
        );

    }
}


const {Column, HeaderCell, Cell} = _Table;


const ActionCell = (onEdit, termsList) => (
    <Column flexGrow={1}>
        <HeaderCell>Options</HeaderCell>
        <Cell>
            {rowData => (
                <div style={{position: 'relative', 'top': '-2px'}}>
                    <ButtonRowEdit
                        onClick={() => {
                            const ptRates = termsList.map( rate => ({
                                ...rate,
                                value: rowData[`payment_terms_rate:${rate.name}`]
                            }));
                            onEdit(ptRates);
                        }}
                    />
                </div>
            )}
        </Cell>
    </Column>
);

const mapState = ({references, prices, numbers, accounts, ranges}) => ({
    service_plan_price_list: prices.service_plan_price_list,
    loading: prices.loading,

    currency_list: references.currency_list,
    worldzone_list: references.worldzone_list,
    subdestination_list: references.subdestination_list,
    destination_list: references.destination_list,
    defaultSPKey: references.defaultSPKey,
    servicePlanList: references.service_plan_list,
    paymentTermsList: references.payment_terms_list,
    price_list: references.price_list,
    template: numbers.template,
    account: accounts.accountView,

    page: prices.page,
    count: prices.count,
    ranges: ranges.ranges,
    rangesLoading: ranges.loading,
    rangesPage: ranges.page,
    rangesCount: ranges.count,

});

export default connect(mapState, {
    getPrices,
    getRanges,
    getTemplate,
})(Prices);


const Top = styled.div`
    margin-bottom: -20px;
    > div:first-child {
        display: inline-block;
        width: 80%
    }
    >div:last-child{
        display: inline-block;
        width: 20%;
        text-align: right
    }
`;