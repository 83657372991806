import {DEFAULT_PER_PAGE_SMALL, PRICE_NUMBERS as PREFIX, SET_NUMBERS} from '../const/';

const initialState = {
    loading: false,
    page: 1,
    count: 0,
    numbers: [],
    allocatedNumbersCount: 0,
    per_page: +localStorage.getItem(PREFIX+'_per_page') || DEFAULT_PER_PAGE_SMALL
};
const handlers = {
    [SET_NUMBERS]: (state, {payload: {items, page, count, per_page, allocatedNumbersCount}}) => ({
        ...state,
        numbers: items,
        count,
        page,
        per_page,
        allocatedNumbersCount,
        loading: false
    }),
    ['SET_LOADING_'+PREFIX]: (state,{payload}) =>({...state,loading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};