import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {Button, Icon, Whisper, Popover} from "rsuite";
import styled, {css} from "styled-components";
import {APP_TYPE_CLIENT} from 'const';
import {RtlContext} from 'App';


const SkypeWidget = ({supportContacts, appType}) => {
    
    const rtl = useContext(RtlContext);

    if (!supportContacts || !supportContacts.arabic || appType !== APP_TYPE_CLIENT) {
        return null;
    }

    const {skype} = supportContacts.arabic;

    return (
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>Premiumy Support</Popover>}
            enterable={true}
        >
            <SkypeButton rtl={rtl} href={`skype:${skype}?chat`}>
                <Icon icon="skype" />
            </SkypeButton>
        </Whisper>
    );
}


const SkypeButton = styled(Button)`
    && {
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: linear-gradient(#88d9f8, #00aff0);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 4px;
        position: relative;

        &:before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            background: #00aff0;
            z-index: -1;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        &:hover {
            background: linear-gradient(#88d9f8, #00aff0);
            
            &:before {
                opacity: 1;
            }
        }

        &:focus, 
        &:active, 
        &:active:hover, 
        &:active:focus, 
        &:active.focus {
            background: linear-gradient(#88d9f8, #00aff0);
        }

        .rs-icon {
            color: #fff;
            font-size: 40px;
            line-height: 60px;
        }

        bottom: 20px;
        position: fixed;
        right: 35px;
        z-index: 10;

        @media (max-width: 767px) {
            bottom: 10px;
            right: 10px;
        }

        ${props => props.rtl && css`
            left: 35px;
            right: auto;

            @media (max-width: 767px) {
                left: 10px;
                right: auto;
            }
        `}
    }
`;


const mapState = ({references, auth})=> ({
    supportContacts: references.support_contacts,
    appType: auth.appType,
});

export default connect(mapState)(SkypeWidget);