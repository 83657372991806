import {api} from '../api/loginRoutes'
import {createAction} from './defaults'
import {
    CDR_GET_LIST_SUCCESS,
    CDR_SET_LOADING,
} from "./actionTypes";
import {CDR_LIST_METHOD} from "../const/apiMethods";
import {CDR_PER_PAGE} from "../const/localStorageKeys";
import {DEFAULT_PER_PAGE_BIG} from "../const";
import {DESC_SORT} from "const";
import {toEndDayUTCTime, toStartDayUTCTime} from "utils";

const setLoading = createAction(CDR_SET_LOADING);
const setCDRList = createAction(CDR_GET_LIST_SUCCESS);

export const getCDRList = (filter = {}, page = 1, per_page = DEFAULT_PER_PAGE_BIG, sort = {}) => (dispatch) => {
    dispatch(setLoading(true));

    const sort1 = sort.column;
    const sort1_desc = sort.type && sort.type === DESC_SORT;

    if (filter.range && filter.range.length) {
        filter['start_date'] = toStartDayUTCTime(filter.range[0]);
        filter['end_date'] = toEndDayUTCTime(filter.range[1]);

        delete filter.range;
    }

    api(CDR_LIST_METHOD, {filter, page, per_page, sort1, sort1_desc})
        .then(({cdr_full_count = 0, cdr_full_list = []}) => {
            localStorage.setItem(CDR_PER_PAGE, per_page);

            dispatch(setCDRList({
                list: cdr_full_list,
                count: cdr_full_count,
                perPage: per_page,
                page: page
            }));
        })
        .catch((error) => {
            dispatch(setLoading(false));
            console.log('GET_CDR_LIST_METHOD_FAILED', error);
        })
};
