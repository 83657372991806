import {connect} from 'react-redux';
import {
    setUploadingPriceLoadingDialer,
    allocateByPriceRangeDialer,
    exportPriceDialer,
    getPriceListDialer,
    getTrunkListDialer,
    setLoadingDataDialer,
    setLoadingAllocatedNumbersDialer
} from '../../actions/client/price';
import {allocateByAccess, setAccessLoadingAllocatedNumbers} from '../../actions/client/accesses';
import {downloadNumbers, setTrunkNumberTransaction, getTemplate, setUploadingLoading, allocateGoogleOTPTrunkNumbers} from '../../actions/client/numbers'


import Price from './Price';
import client_price from "../../reducers/client/price";

const mapState = ({auth, client_price, client_numbers, client_accesses, auth_dialer, references}) => ({
    account: auth.authInfo,
    accountDialerState: auth_dialer.dialerInfo,

    priceList: client_price.items,
    count: client_price.count,
    priceLoading: client_price.loading,
    page: client_price.page,
    per_page: client_price.per_page,
    trunk_list: client_price.trunk_list,
    allocated_numbers: client_price.allocated_numbers,
    loadingAllocation: client_price.loadingAllocatedNumbers,
    loadingGoogleOTPAllocation: client_numbers.loadingAllocatedNumbers,
    loadingAccessAllocation: client_accesses.loadingAllocatedNumbers,
    
    service_plan_list: references.service_plan_list,
    worldzone_list: references.worldzone_list,
    destination_list: references.destination_list,
    subdestination_list: references.subdestination_list,
    currency_list: references.currency_list,
    payment_terms_list: references.payment_terms_list,
    uploadLoading: references.uploadLoadingDialer,
    
    uploadLoadingSetting: client_numbers.uploadTrunkLoading,
    trunk_number_transaction: client_numbers.trunk_number_transaction,
    allocation_limit: {
        ...references.client_allocation_limit, 
        ...auth.allocation_limit
    }
});

export default connect(mapState,
    {
        getPriceList: getPriceListDialer,
        exportPrice: exportPriceDialer,
        setLoadingData: setLoadingDataDialer,
        getTrunkList: getTrunkListDialer,
        allocateByPriceRange: allocateByPriceRangeDialer,
        setLoadingAllocatedNumbersDialer,
        setUploadingPriceLoading: setUploadingPriceLoadingDialer,
        allocateByAccess,
        setAccessLoadingAllocatedNumbers,

        allocateGoogleOTPTrunkNumbers,
        setUploadingLoading,
        getTemplate,
        setTrunkNumberTransaction,
        downloadNumbers,
    })(Price);