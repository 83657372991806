import React from 'react';
import {Button, List} from "rsuite";
import {useWindowWidth} from "../../hooks";
import {Spacer} from "../../components/base/Spacer";
import {BaseModal as Modal} from "../../components/base/BaseModal";

export default ({
    show,
    data = {},
    onClose,
}) => {

    const resizedWidth = useWindowWidth();

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            width={resizedWidth > 600 ? 600 : resizedWidth}
        >
            <Modal.Header>
                <Modal.Title>Delete Range Result</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: '100%'}}>
                <p style={{textAlign: 'center'}}>Removed <b>{data.ranges}</b> ranges</p>
                {data.price_range_list_with_not_owned_numbers && data.price_range_list_with_not_owned_numbers.length
                    ? <>
                        <Spacer size={40}/>
                        Some of chosen ranges contain numbers allocated to not owned accounts and cannot be deleted:
                        <Spacer/>
                        <List size="sm">
                            {data.price_range_list_with_not_owned_numbers
                                .map(({pr_key, name}) => {
                                    return <List.Item key={pr_key}>{name}</List.Item>
                                })}
                        </List>
                    </>
                    : null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} appearance="primary">
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
};