import {connect} from "react-redux";
import GoogleOtpQuarantined from "./GoogleOtpQuarantined";
import {getGoogleOtpQuarantinedList, removeGoogleOtpQuarantinedNumbers} from "../../actions/google_otp_quarantined";


const mapStateToProps = ({references, google_otp_quarantined}) => ({
    subDestinationList: references.subdestination_list,
    data: google_otp_quarantined.list,
    count: google_otp_quarantined.count,
    loading: google_otp_quarantined.loading,
    page: google_otp_quarantined.page,
    perPage: google_otp_quarantined.perPage,
});

export default connect(mapStateToProps, {
    getGoogleOtpQuarantinedList,
    removeGoogleOtpQuarantinedNumbers
})(GoogleOtpQuarantined);