import React from 'react';
import {FlexboxGrid, Dropdown, ButtonToolbar, Loader} from 'rsuite';
import {
    LOCAL_STORAGE_NUMBERS_FILTERS_DIALER,
    LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER,
    LOCAL_STORAGE_USER_INFO_DIALER,
    MAX_RATE_DIALER,
    MIN_PER_PAGE_DIALER,
    MIN_RATE_DIALER
} from '../../const';
import PageHeader from './../../components/base/PageHeader';
import TrunkTable from './TrunkTable';
import NumbersFilters from './NumbersFilters/';
import AllocatedNumbersTable from './AllocatedNumbersTable';
import ModalResponseHandler from '../../components/client/Modal/ModalResponseHandler';
import {
    objectIsEmpty,
    copyToClipboard,
    onDownloadCompleteOnlyNumbersCSV,
    saveFileBlob,
    makeRangeFromPrefixesToCsv,
    getBlobContent
} from '../../utils/helpers';
import {FormattedMessage, injectIntl} from 'react-intl';
import NumbersRevokeModal from './NumbersRevokeModal';
import {api, getFileResponse} from '../../api/loginRoutes';
import {ALLOCATION_GOOGLE_OTP_DIALER_API, ALLOCATION_TEMPLATE_DIALER_API} from '../../const/apiMethods';
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import {Spacer} from '../../components/base/Spacer';
import {Alert} from 'rsuite';
import {SCREEN_MEDIA} from './../../const';
import NumbersAllocateModal from './NumbersAllocateModal';
import styled, {css} from 'styled-components';
import GoogleOTPAllocateModal from './GoogleOTPAllocateModal';
import ModalAllocationResult from '../../components/client/Modal/ModalAllocationResult';
import {ButtonPrimary, ButtonFixedBottom, ButtonSecondary, BaseButton} from '../../components/base/BaseButton';
import m from "../../definedMessages";
import PanelLayout from "../../components/base/PanelLayout";

const {sm, md} = SCREEN_MEDIA;

const NUMBER_KEY = 'trn_key';
const RANGE_KEY = 'pr_key';
const SUBDEST_KEY = 'sde_key';


class Numbers extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = null;

        this.filter = {};
        this.defaultFilter = {
            str: '',
            rate_min: MIN_RATE_DIALER,
            rate_max: MAX_RATE_DIALER
        };

        this.curPerPage = null;

        const accountInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
        const parsedAccountInfo = accountInfo !== null ? JSON.parse(accountInfo) : {};

        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);

        let storagedPerPage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER));
        if ( storagedPerPage && !isNaN(storagedPerPage) && storagedPerPage > 100 ) {
            localStorage.setItem(LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER, 100);
            storagedPerPage = 100;
        }

        this.state = {
            firstTrunkId: null,
            activeTrunkId: null,
            permissionList: parsedAccountInfo && parsedAccountInfo.session ? parsedAccountInfo.session.account.permission_list : null,
            savedTableFilter: JSON.parse(localStorage.getItem(LOCAL_STORAGE_NUMBERS_FILTERS_DIALER)),
            savedPerPage: storagedPerPage,
            showNumbersModal: null,
            showGoogleOTPModal: false,
            showNumbersListModal: null,
            showNumbersRevokeModal: null,
            numbersRevokeModalLoading: false,
            showModalResponseHandler: null,
            searchLoading: false,
            selectedAllocatedNumbers: [],
            resizedWidth: window.innerWidth,
            selectedData: {all: false, list: []},

            templateFormValue: {numbers: 3},
            listFormValue: {},
            googleOTPFormValue: {numbers: 100},
            randomNumber: false,
            reasonModalProps: {},

            activeFiltersCount: 0,
            selectedItems: new Map(),

            filtersValue: JSON.parse(localStorage.getItem(LOCAL_STORAGE_NUMBERS_FILTERS_DIALER)),
            currentDisplay: 'trunk_number',

            trunkAllocatedByOTP: false,
            csvContent: null,
            transactionId: null,
            notAllocatedNumbers: null,
            allocatedNumbersCount: 0,
            prefixViewNumbers: null
        };
    };


    componentDidMount() {
        const {getNumberTrunksList, account} = this.props;

        this.setActiveFiltersCount(this.state.savedTableFilter);

        getNumberTrunksList();
    }


    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    componentDidUpdate(prevProps) {
        const {trunks} = this.props;
        if (prevProps.trunks !== trunks) {
            const filteredTrunks = trunks.filter(trunk => !trunk.closed);
            if (filteredTrunks.length) {
                this.setState({prefixViewNumbers: filteredTrunks[0].sp_auth})
            }
        }
    }


    componentWillReceiveProps(nextProps) {
        const {trunks} = nextProps;
        const id = this.state.firstTrunkId;

        const filteredTrunks = trunks.filter(trunk => !trunk.closed);

        if (filteredTrunks.length && !id) {
            this.setState({
                firstTrunkId: filteredTrunks[0].id,
                activeTrunkId: filteredTrunks[0].id
            });
            this.onGetTrunkNumbers(filteredTrunks[0].id);
        }
    };


    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };


    onCopyList = (text) => {
        copyToClipboard(text);
        Alert.success('Copied!')
    };


    onGetTrunkNumbers = (trunk_id, sp_auth) => {
        const {savedPerPage, savedTableFilter, currentDisplay} = this.state;
        const {getTrunkNumbers, per_page} = this.props;
        let pickedFilter;

        this.curPerPage = savedPerPage;

        if (!objectIsEmpty(savedTableFilter)) {
            pickedFilter = savedTableFilter;
        } else if (!objectIsEmpty(this.filter)) {
            pickedFilter = this.filter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        getTrunkNumbers(trunk_id, savedPerPage || per_page, pickedFilter, 1, currentDisplay);
        this.setState({prefixViewNumbers: sp_auth});
        this.tableRef.clear();
        this.clearSelectedNumbers();
        this.setActiveTrunk(trunk_id);
    };


    setActiveTrunk = (trunk_id) => {
        this.setState({
            activeTrunkId: trunk_id
        });
    };


    combineFilters = (filter, savedFilter) => {
        return {

            rate_min: filter && filter.rate_min 
                ? filter.rate_min 
                : savedFilter && savedFilter.rate_min 
                    ? savedFilter.rate_min 
                    : MIN_RATE_DIALER,
                    
            rate_max: filter && filter.rate_max 
                ? filter.rate_max 
                : savedFilter && savedFilter.rate_max 
                    ? savedFilter.rate_max 
                    : MAX_RATE_DIALER,

            str: filter && (filter.str || filter.str === '') 
                ? filter.str 
                : savedFilter && (savedFilter.str || savedFilter.str === '') 
                    ? savedFilter.str 
                    : ''
        };
    };
    

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        const filters = Object.keys(value);
        const activeFilters = filters.filter(item => {
            if (value[item] === this.defaultFilter[item] || (!value[item] && value[item] !== 0)) {
                return false;
            }
            return true;
        });

        this.setState({
            activeFiltersCount: activeFilters.length
        });
    };


    onChangeGroup = (currentDisplay) => {
        const {activeTrunkId, savedPerPage} = this.state;
        const {getTrunkNumbers, per_page} = this.props;

        this.setState({
            currentDisplay
        });

        if (activeTrunkId) {
            getTrunkNumbers(
                activeTrunkId, 
                savedPerPage || this.curPerPage || per_page, 
                this.filter, 
                1, 
                currentDisplay
            );
        }

        this.tableRef.clear();
        this.clearSelectedNumbers();
    };


    onChangeFilters = (filtersObj) => {
        const {activeTrunkId, savedPerPage, currentDisplay} = this.state;
        const {getTrunkNumbers, per_page} = this.props;

        this.setActiveFiltersCount(filtersObj);

        this.filter = filtersObj;
        localStorage.setItem(LOCAL_STORAGE_NUMBERS_FILTERS_DIALER, JSON.stringify(this.filter));
        this.setState({
            savedTableFilter: filtersObj
        });

        if (activeTrunkId) {
            getTrunkNumbers(activeTrunkId, savedPerPage || this.curPerPage || per_page, this.filter, 1, currentDisplay);
        }
        this.tableRef.clear();
        this.clearSelectedNumbers();
    };


    onChangePerPage = (perPage) => {
        const {activeTrunkId, currentDisplay} = this.state;
        const {getTrunkNumbers} = this.props;

        const savedPerPage = perPage ? null : this.curPerPage;
        this.setState({savedPerPage: savedPerPage});

        const pickedFilter = this.getActiveFilter();
        const per_page = Number(perPage) || this.curPerPage;
        this.curPerPage = per_page <= 0 ? MIN_PER_PAGE_DIALER : per_page > 100 ? 100 : per_page;
        localStorage.setItem(LOCAL_STORAGE_NUMBERS_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        getTrunkNumbers(activeTrunkId, this.curPerPage, pickedFilter, 1, currentDisplay);
    };


    onChangePage = (pageNumber) => {
        const {activeTrunkId, savedPerPage, currentDisplay} = this.state;
        const {getTrunkNumbers, per_page} = this.props;
        
        const pickedFilter = this.getActiveFilter();

        getTrunkNumbers(activeTrunkId, savedPerPage || this.curPerPage || per_page, pickedFilter, pageNumber, currentDisplay);
    };

    
    onDownloadNumbers = (trunk_id) => this.props.downloadNumbers(trunk_id);

    
    getActiveFilter = () => {
        const {savedTableFilter} = this.state;
        const combinedFilter = this.combineFilters(this.filter, savedTableFilter);

        return (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) 
            ? combinedFilter 
            : this.defaultFilter;
    };


    onAllocationGetResult = (response) => {
        const {setTrunkNumberTransaction, setLoadingAllocatedNumbers} = this.props;

        setTrunkNumberTransaction(response.trunk_number_transaction || {});
        const trunkNumberTransaction = response.trunk_number_transaction;
        const notAllocatedNumbers = response.not_allocated_numbers;

        if (trunkNumberTransaction) {
            const transactionId = trunkNumberTransaction.id;
            const allocatedNumbersCount = trunkNumberTransaction.numbers;
            this.setState({transactionId, allocatedNumbersCount});

            getFileResponse('trunk_number:get_list', {target: {trunk_number_transaction_id: transactionId}}, true).then(
                (response) => {
                    setLoadingAllocatedNumbers(false);
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        this.setState({
                            csvContent: text,
                        })
                    })
                }
            )
        }
        notAllocatedNumbers && this.setState({notAllocatedNumbers});
    };


    onAllocateNumbersTemplate = async (formValue) => {
        const {allocateTrunkNumbersByTemplate} = this.props;

        this.setState({
            activeTrunkId: formValue.trunk_id, 
            trunkAllocatedByOTP: false
        });

        const response = await allocateTrunkNumbersByTemplate(
            formValue.trunk_id,
            formValue.template,
            Number(formValue.numbers),
            formValue.random_order
        );

        if (response === undefined || (response.code && !response.reason_code))
            return;

        this.setShowNumbersModal(false);

        // reason_code recieved in an error message or with no numbers
        if ( response.reason_code && (!response.trunk_number_transaction || !response.trunk_number_transaction.numbers) ) {
            this.setModalResponseHandler(true);
            return {
                status: 1,
                reason_code: response.reason_code,
                reason_hash: response.hash
            };
        }

        this.onAllocationGetResult(response);
        this.setShowNumbersListModal(true);
        return {status: 0};
    };


    onAllocateNumberList = async (formValue) => {
        const {allocateTrunkNumbersByNumberList} = this.props;
        const {activeTrunkId} = this.state;

        let numbers = formValue.num_list.trim().split('\n');
        this.setState({
            activeTrunkId: formValue.trunk_id, 
            trunkAllocatedByOTP: false
        });

        const response = await allocateTrunkNumbersByNumberList(activeTrunkId, numbers);

        if (response === undefined || (response.code && !response.reason_code))
            return;

        this.setShowNumbersModal(false);

        // reason_code recieved in an error message or with no numbers
        if ( (!response.not_allocated_numbers || !response.not_allocated_numbers.length) && 
        (!response.trunk_number_transaction || !response.trunk_number_transaction.numbers) ) {
            this.setModalResponseHandler(true);
            return {
                status: 1,
                reason_code: 'default_numbers_list',
                reason_hash: response.hash,
            };
        }

        this.onAllocationGetResult(response);
        this.setShowNumbersListModal(true);
        return {status: 0};
    };


    onAllocateGoogleOTP = async (formValue) => {
        const {allocateGoogleOTPTrunkNumbers} = this.props;

        this.setState({
            activeTrunkId: formValue.trunk_id, 
            trunkAllocatedByOTP: true
        });

        const response = await allocateGoogleOTPTrunkNumbers(
            {...formValue}
        );

        if (response === undefined || (response.code && !response.reason_code))
            return;

        this.setShowGoogleOTPModal(false);

        // reason_code recieved in an error message or with no numbers
        if ( response.reason_code && (!response.trunk_number_transaction || !response.trunk_number_transaction.numbers) ) {
            this.setModalResponseHandler(true);
            return {
                status: 1,
                reason_code: response.reason_code,
                reason_hash: response.hash
            };
        }

        this.onAllocationGetResult(response);
        this.setShowNumbersListModal(true);
        return {status: 0};
    };


    setShowNumbersModal = (value) => {
        this.setState({showNumbersModal: value});
    };


    setShowGoogleOTPModal = (value) => {
        this.setState({showGoogleOTPModal: value});
    };


    setShowNumbersListModal = (value) => {
        this.setState({showNumbersListModal: value});
    };


    setShowNumbersRevokeModal = (value) => {
        this.setState({showNumbersRevokeModal: value});
    };


    setModalResponseHandler = (value) => {
        this.setState({showModalResponseHandler: value});
    };


    onShowNumbersModal = (trunk_id) => {
        this.setShowNumbersModal(true);
        this.setState({activeTrunkId: trunk_id});
    };


    onShowGoogleOTPModal = (trunk_id) => {
        const {googleOTPFormValue} = this.state;

        this.setShowGoogleOTPModal(true);
        this.setState({
            activeTrunkId: trunk_id,
            googleOTPFormValue: {...googleOTPFormValue, trunk_id: trunk_id}
        });
    };


    handleRevokeNumbersSubmit = async () => {
        const {savedTableFilter} = this.state;
        const {all, list} = this.state.selectedData;
        const trunkId = this.state.activeTrunkId;

        const request = 'trunk_number__filter:revoke';
        const params = {
            target: {
                trunk_id: trunkId
            }
        };

        if (all) {
            params.filterAll = true;
            if (savedTableFilter) {
                params.filter = savedTableFilter;
            }
        }

        if (!all) {
            const key = this.getRowKeyList();
            params[key] = list;
        }

        this.setState({numbersRevokeModalLoading: true});

        await api(request, params);

        this.setState({numbersRevokeModalLoading: false});

        this.onGetTrunkNumbers(trunkId);
        this.setShowNumbersRevokeModal(false);
    };

    
    downloadNumberList = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.csv');
        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };

    downloadNumberListTxt = (csvNumberList, trunkId, setUploadingLoading) => {
        const encodedUri = encodeURI(csvNumberList);
        const link = document.createElement('a');

        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'allocated_numbers.txt');
        document.body.appendChild(link);
        link.click();
        setUploadingLoading(trunkId, false);
    };


    setSelectedData = (selected) => {
        this.setState({selectedData: selected});
    };


    getRowKey = () => {
        const {currentDisplay} = this.state;
        switch (currentDisplay) {
            case 'trunk_number':
                return NUMBER_KEY;
            case 'price_range':
                return RANGE_KEY;
            case 'subdestination':
                return SUBDEST_KEY;
        }
    };


    getRowKeyList = () => {
        const {currentDisplay} = this.state;
        switch (currentDisplay) {
            case 'trunk_number':
                return 'trn_key_list';
            case 'price_range':
                return 'pr_key_list';
            case 'subdestination':
                return 'sde_key_list';
        }
    };


    setTableRef = (ref) => {
        this.tableRef = ref;
    };


    setTemplateFormValue = (value) => {
        this.setState({
            templateFormValue: {...value}
        });
    };


    setListFormValue = (value) => {
        this.setState({
            listFormValue: {...value}
        });
    };


    setGoogleOTPFormValue = (value) => {
        this.setState({
            googleOTPFormValue: {...value}
        });
    };


    setRandomNumber = (value) => {
        this.setState({
            randomNumber: value
        });
    };


    setReasonModalProps = (props) => {
        this.setState({
            reasonModalProps: {...props}
        });
    };


    /* section: Count Selected Numbers */

    keepSelectedItems = (data, checked) => {
        const {selectedItems} = this.state;
        const updatedCollection = new Map(selectedItems);

        const rowKey = this.getRowKey();

        for (const item of data) {
            if (checked) {
                updatedCollection.set(item[rowKey], item);
            } else {
                updatedCollection.delete(item[rowKey]);
            }
        }

        this.setState({selectedItems: updatedCollection});
    };


    getSelectedNumbersCount = () => {
        const {selectedItems, selectedData: {all}} = this.state;

        if (all)
            return this.props.totalNumbersCount;

        const rowKey = this.getRowKey();
        let key = 'id';
        switch (rowKey) {
            case NUMBER_KEY:
                key = 'numbers_count';
                break;
            case RANGE_KEY:
                key = 'price_range_trunk_numbers';
                break;
            case SUBDEST_KEY:
                key = 'subdestination_trunk_numbers';
                break;
        }

        let count = 0;
        for (const item of selectedItems) {
            count += item[1][key];
        }

        return count;
    };
    

    clearSelectedNumbers = () => {
        this.setState({
            selectedItems: new Map()
        });
    };

    clearAllocatedData = () => {
        this.setState({
            csvContent: null,
            allocatedNumbersCount: 0
        })
    };
    downloadCompleteNumbersCSV = async (trunkId, setUploadingLoading, filter = null) => {
        const {allocated_numbers_google} = this.props;
        await getFileResponse('trunk_number__get_list', {
            target: {
                trunk_id: trunkId
            },
            filter: {...(filter ? filter : {})}
        })
            .then((response) => {
                const csvText = getBlobContent(response);
                csvText.then((text) => {
                    const csvContent = allocated_numbers_google ? makeRangeFromPrefixesToCsv(text) : text;
                    const csvHeaderList = csvContent.split('\n')[0].split(';');
                    const csvDataList = csvContent.split('\n').slice(1).map(line => {
                        const items = line.split(';');
                        return [items[0], ...items.slice(2)].join(';')
                    });
                    const csvHeaders = [csvHeaderList[0], ...csvHeaderList.slice(2)].join(';');
                    csvDataList.unshift(csvHeaders);
                    const csvData = csvDataList.join('\n');

                    saveFileBlob(csvData, 'allocated_numbers.csv');
                    setUploadingLoading(trunkId, false);
                });
            });
    };
    render() {
        const {
            activeTrunkId,
            permissionList,
            showModalResponseHandler,
            showNumbersModal,
            showNumbersRevokeModal,
            showNumbersListModal,
            showGoogleOTPModal,
            searchLoading,
            savedPerPage,
            savedTableFilter,
            resizedWidth,
            activeFiltersCount,
            currentDisplay,

            reasonModalProps,
            trunkAllocatedByOTP,
            csvContent,
            allocatedNumbersCount,
            notAllocatedNumbers,
            prefixViewNumbers,
            numbersRevokeModalLoading
        } = this.state;

        const {list: selectedList, all: selectedAll} = this.state.selectedData;

        const {
            trunks, allocatedNumbers, numberTrunkLoading, allocatedNumbersLoading, page, count, per_page, allocated_numbers_num_list,
            allocated_numbers_temp, allocated_numbers_google, loadingAllocation, uploadLoading, accountDialerState, allocation_limit,
            trunk_number_transaction, account, setUploadingLoading
        } = this.props;

        const isMobile = resizedWidth < md.min;

        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const onChangeFilters = this.onChangeFilters;
        const onChangeGroup = this.onChangeGroup;
        const onDownloadNumbers = this.onDownloadNumbers;
        const onAllocateNumbersTemplate = this.onAllocateNumbersTemplate;
        const onAllocateNumberList = this.onAllocateNumberList;
        const onAllocateGoogleOTP = this.onAllocateGoogleOTP;
        const onShowNumbersModal = this.onShowNumbersModal;
        const onShowGoogleOTPModal = this.onShowGoogleOTPModal;
        const setSelectedData = this.setSelectedData;
        const getRowKey = this.getRowKey;
        const keepSelectedItems = this.keepSelectedItems;
        const getSelectedNumbersCount = this.getSelectedNumbersCount;

        const activeFilter = this.getActiveFilter();
        const revokeButton = (
            <BaseButton
                buttonStyle="primary"
                as={!isMobile ? BaseButton : ButtonSecondary}
                onClick={() => {
                    this.setShowNumbersRevokeModal(true);
                }}
                loading={false}
                disabled={!selectedList.length && !selectedAll}
            >
                <>
                    {!isMobile
                        ? <FormattedMessage id="common.revoke" defaultMessage="Revoke"/>
                        : <FormattedMessage
                            id="common.revokeCountNumbers"
                            defaultMessage="Revoke {count} items"
                            values={{count: this.getSelectedNumbersCount()}}
                        />
                    }
                </>
    
            </BaseButton>
        );

        return (
            <PanelLayout>
                {/* Trunks */}
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <PageHeader title={<FormattedMessage id="numbers.trunks" defaultMessage="Trunks"/>}/>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                <TrunkTable
                    data={trunks}
                    loading={numberTrunkLoading || allocatedNumbersLoading}
                    onClickTrunk={this.onGetTrunkNumbers}
                    activeTrunk={activeTrunkId}
                    accountDialerState={accountDialerState}
                    onChangeActiveTrunk={this.setActiveTrunk}
                    {...{
                        onDownloadNumbers,
                        onShowNumbersModal,
                        onShowGoogleOTPModal,
                        permissionList,
                        uploadLoading
                    }}
                />

                <Spacer/>

                {/* Numbers */}

                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item>
                        <PageHeader title={<FormattedMessage id="numbers.allocatedNumbers" defaultMessage="Allocated numbers"/>}/>
                    </FlexboxGrid.Item>

                    {isMobile &&
                    <FlexboxGrid.Item>
                        <NumbersFilters
                            {...{
                                onChangeFilters,
                                onChangeGroup,
                                savedTableFilter,
                                allocatedNumbersLoading,
                                activeFiltersCount,
                                currentDisplay
                            }}
                            isMobile={isMobile}
                        />
                    </FlexboxGrid.Item>
                    }

                </FlexboxGrid>

                {!isMobile &&
                <>
                    <FlexGrid justify="space-between">
                        <FlexGridItem>
                            <NumbersFilters
                                {...{
                                    onChangeFilters,
                                    onChangeGroup,
                                    savedTableFilter,
                                    allocatedNumbersLoading,
                                    currentDisplay
                                }}
                            />
                        </FlexGridItem>
                        <FlexGridItem>
                            <StyledButtonToolbar>
                                <StyledDropdown
                                    onSelect={() => setUploadingLoading(activeTrunkId, true)}
                                    title={<>{uploadLoading[activeTrunkId] 
                                        ? <StyledLoader/> 
                                        : this.formatMessage(m.download)
                                    }</>} appearance="default"
                                    disabled={currentDisplay !== "trunk_number" || !activeTrunkId || uploadLoading[activeTrunkId]}
                                >
                                    <Dropdown.Item 
                                        onClick={() => this.downloadCompleteNumbersCSV(activeTrunkId, setUploadingLoading, activeFilter)}
                                    >
                                        <FormattedMessage
                                            id="common.downloadAllocatedNumbersAs"
                                            defaultMessage="Download allocated numbers as {type}"
                                            values={{type: 'csv'}}
                                        />
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                        onClick={() => onDownloadCompleteOnlyNumbersCSV(activeTrunkId, setUploadingLoading, activeFilter)}
                                    >
                                        <FormattedMessage
                                            id="common.downloadAllocatedOnlyNumbersAs"
                                            defaultMessage="Download only numbers as {type}"
                                            values={{type: 'csv'}}
                                        />
                                    </Dropdown.Item>

                                </StyledDropdown>
                            </StyledButtonToolbar>
                            
                            {revokeButton}

                        </FlexGridItem>
                    </FlexGrid>
                    <Spacer/>
                </>
                }

                <AllocatedNumbersTable
                    data={allocatedNumbers}
                    setRef={this.setTableRef}
                    loading={allocatedNumbersLoading || searchLoading}
                    useSelectedData={keepSelectedItems}
                    {...{
                        page,
                        count,
                        per_page,
                        savedPerPage,
                        currentDisplay,
                        getRowKey,
                        onChangePage,
                        onChangePerPage,
                        setSelectedData,
                    }}
                />

                {isMobile &&
                    <StyledButtonToolbar>
                        <StyledDropdown
                            onSelect={() => setUploadingLoading(activeTrunkId, true)}
                            title={<>{uploadLoading[activeTrunkId] ? <StyledLoader/> : this.formatMessage(m.download)}</>} appearance="default"
                            disabled={currentDisplay !== "trunk_number" || !activeTrunkId || uploadLoading[activeTrunkId]}
                            placement={'topStart'}
                        >
                            
                            <Dropdown.Item 
                                onClick={() => this.downloadCompleteNumbersCSV(activeTrunkId, setUploadingLoading, activeFilter)}
                            >
                                <FormattedMessage
                                    id="common.downloadAllocatedNumbersAs"
                                    defaultMessage="Download allocated numbers as {type}"
                                    values={{type: 'csv'}}
                                />
                            </Dropdown.Item>
                            

                            <Dropdown.Item
                                onClick={() => onDownloadCompleteOnlyNumbersCSV(activeTrunkId, setUploadingLoading, activeFilter)}
                            >
                                <FormattedMessage
                                    id="common.downloadAllocatedOnlyNumbersAs"
                                    defaultMessage="Download only numbers as {type}"
                                    values={{type: 'csv'}}
                                />
                            </Dropdown.Item>

                        </StyledDropdown>
                    </StyledButtonToolbar>

                }

                {isMobile && (selectedList.length || selectedAll) &&
                <>
                    <Spacer size={40}/>
                    <FlexboxGrid.Item>

                        {revokeButton}

                    </FlexboxGrid.Item>
                </>
                }


                {/* Modals */}

                <NumbersRevokeModal
                    show={showNumbersRevokeModal}
                    loading={numbersRevokeModalLoading}
                    onClose={this.setShowNumbersRevokeModal}
                    getCount={getSelectedNumbersCount}
                    onSuccess={() => {
                        this.handleRevokeNumbersSubmit();
                    }}
                >
                </NumbersRevokeModal>

                <NumbersAllocateModal
                    show={showNumbersModal}
                    setReasonModalProps={this.setReasonModalProps}
                    onClose={this.setShowNumbersModal}

                    leftFormValue={this.state.templateFormValue}
                    onChangeLeftFormValue={this.setTemplateFormValue}

                    rightFormValue={this.state.listFormValue}
                    onChangeRightFormValue={this.setListFormValue}

                    randomNumber={this.state.randomNumber}
                    onChangeRandomNumber={this.setRandomNumber}

                    loading={loadingAllocation}
                    selectedTrunkId={this.state.activeTrunkId}
                    allocationLimit={allocation_limit}
                    {...{
                        onAllocateNumbersTemplate,
                        onAllocateNumberList,
                        trunks
                    }}
                />

                <GoogleOTPAllocateModal
                    show={showGoogleOTPModal}
                    formValue={this.state.googleOTPFormValue}
                    loading={loadingAllocation}
                    allocationLimit={allocation_limit}
                    onClose={this.setShowGoogleOTPModal}
                    onChangeFormValue={this.setGoogleOTPFormValue}
                    setReasonModalProps={this.setReasonModalProps}
                    activeField={this.state.googleOTPFormValue.sde_key ? "sde_key" : "template"}
                    {...{
                        onAllocateGoogleOTP,
                        trunks
                    }}
                />

                <ModalAllocationResult
                    show={showNumbersListModal}
                    maxWidth={!trunk_number_transaction || trunk_number_transaction.method2 !== "google_otp" ? 760 : 850}
                    onCopy={this.onCopyList}
                    downloadNumbers={this.downloadNumberList}
                    downloadNumbersTxt={this.downloadNumberListTxt}
                    onDownloadNumbers={onDownloadNumbers}
                    uploadLoading={uploadLoading}
                    loading={loadingAllocation}
                    trunkId={this.state.activeTrunkId}
                    trunkAllocatedByOTP={trunkAllocatedByOTP}
                    methodName={'Trunks'}
                    account={account}
                    csvContent={csvContent}
                    prefixViewNumbers={prefixViewNumbers}
                    notAllocatedNumbers={notAllocatedNumbers}
                    trunkNumberTransaction={trunk_number_transaction}
                    allocatedNumbersCount={allocatedNumbersCount}
                    setUploadingLoading={setUploadingLoading}
                    onClose={() => {
                        this.clearAllocatedData();
                        this.setState({notAllocatedNumbers: null});
                        this.setShowNumbersListModal(false);
                    }}
                />

                <ModalResponseHandler
                    show={showModalResponseHandler}
                    onClose={this.setModalResponseHandler}
                    onSuccess={reasonModalProps.method !== ALLOCATION_GOOGLE_OTP_DIALER_API
                        ? this.setShowNumbersModal 
                        : this.setShowGoogleOTPModal
                    }
                    changeRandom={this.setRandomNumber}
                    method={ALLOCATION_TEMPLATE_DIALER_API}
                    params={reasonModalProps}
                />
            </PanelLayout>
        );
    }
};


export default injectIntl(Numbers);


const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 140px;
    display: inline-block; 
    margin-right: 10px;
    ${props =>
        props.width && css`
            width: ${props.width};
        `
    }
    .rtl & {
        margin-right: 0;
        margin-left: 10px;
    }
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
`;

const StyledDropdown = styled(Dropdown)`
    width: 100%;
    height: 36px;
    
    &&&&& {
        .rs-btn {
            background-color: var(--color-brand2);
            width: 100%;
            color: white;
            height: 36px;
            padding: 8px 20px 8px 12px;
            
            &:hover {
                background-color: #5f2bb3;
                color: white;
            }
            
            .rtl & {
                padding: 8px 12px 8px 32px;
            }
        }
        
        .rs-dropdown-menu {
            background-color: #fdfdfd !important;
        }
        
        & > .rs-dropdown-item > .rs-dropdown-item-content:hover {
            background-color: #cfcfcf !important;
            color: #5c5c5c !important;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        &.rs-dropdown-disabled > a.rs-dropdown-toggle.rs-btn {
            background-color: #D5D4D4 !important;
            color: white;
        }
        
        @media (max-width: 768px) {
            width: 140px;
        }
    }
`;
const StyledLoader = styled(Loader)`
  & span.rs-loader-spin {
    margin-top: 3px;
    margin-right: 5px;
  }
`;