import {LIVE_CALLS as PREFIX, LIVE_CALLS_LIST} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

const setLiveCalls = createAction('SET_ITEMS_'+PREFIX);
const setLoading = createAction('SET_LOADING_'+PREFIX);
const setLoadingItem = createAction('SET_LOADING_ITEM_'+PREFIX);
const removeItem = createAction('REMOVE_ITEM_'+PREFIX);

export const getLiveCalls = () => (dispatch) => {
    dispatch(setLoading(true));
    api(LIVE_CALLS_LIST,)
        .then( ({ live_call_list = [] }) => dispatch(
            setLiveCalls(
                    (live_call_list || []).map(
                        x => ({
                                ...x,
                                termination_point: `${x.ip_term}:${x.port_term} ${x.tprefix_term}`
                             })
                    )
                )
        ))
        .finally(() => dispatch(setLoading(false)));
};


export const stopCall = (CALL_KEY, onSuccess) => (dispatch) => {
    dispatch(setLoadingItem(true));
    api('live_call:stop',{CALL_KEY})
        .then( (data) => {
            onSuccess();
        })
        .finally(() => dispatch(setLoadingItem(false)));
};