import { connect } from "react-redux";
import {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
} from "../../actions/paymentDetails";
import PaymentDetails from "./PaymentDetails";

const mapState = ({paymentDetails, references, auth}) => ({
    permissions: auth.permissions,
    organizationTypeList: references.organization_type_list,
    countryList: references.country_list,
    paymentMethodList: references.payment_method_list,
    data: paymentDetails.items,
    loading: paymentDetails.loading
});

export default connect(mapState, {
    getPaymentDetailsList,
    getPaymentDetailsSingle,
    createPaymentDetails,
    modifyPaymentDetails,
    removePaymentDetails
})(PaymentDetails);