import React, {useEffect, useState} from "react";
import PaymentsTrafficReportsTable from "./PaymentsTrafficReportsTable";
import { Spacer } from "../../../components/base/Spacer";
import { getISOTime } from "../../../utils/helpers";
import PaymentsTrafficReportsFilter from "./PaymentsTrafficReportsFilter";

export default ({
    paymentTrafficReportsItems, 
    paymentTrafficReportsLoading,
    openModalRequestPayment,
    getTrafficReportsList,
    globalSettings = {},
    accounts
}) => {

    const dateStart = new Date();
    dateStart.setDate(1);
    dateStart.setHours(0, 0, 0, 0);
    dateStart.setMonth(dateStart.getMonth() - 1);
    const dateEnd = new Date(new Date().setHours(23, 59, 59, 999));
    
    const [checkedStatus, setCheckedStatus] = useState([]);


    useEffect( () => {
        const data = {
            target: {account_id_list: null},
            filter: {
                start_date: getISOTime(dateStart),
                end_date: getISOTime(dateEnd),
            }
        };
        getTrafficReportsList(data);
    }, [] );


    const handleChangeFilters = (value) => {
        const dateStart = new Date(value.start_end_date[0].setHours(0, 0, 0, 0));
        const dateEnd = new Date(value.start_end_date[1].setHours(23, 59, 59, 999));
    console.log(value);
        const filterValue = {
            ...value,
            start_date: getISOTime(dateStart),
            end_date: getISOTime(dateEnd),
            start_end_date: value.start_end_date
        };
        const accountIdList = value.account_id_list !== undefined ? value.account_id_list : []
        delete filterValue.account_id_list;
        getTrafficReportsList({
            target: {
                account_id_list: accountIdList.length ? accountIdList : null
            },
            filter: filterValue
        });
    };

    return (
        <>
            <PaymentsTrafficReportsFilter
                filters={{start_end_date: [dateStart, dateEnd]}}
                onChangeFilters={handleChangeFilters}
                accounts={accounts}
                globalSettings={globalSettings}
                setStatus={setCheckedStatus}
                checkedStatus={checkedStatus}
            />

            <Spacer/>

            <PaymentsTrafficReportsTable
                withFilters
                checkedStatus={checkedStatus}
                data={paymentTrafficReportsItems}
                loading={paymentTrafficReportsLoading}
                {...{
                    openModalRequestPayment,
                }}
            />
        </>
    )
};