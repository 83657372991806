import React from 'react';
import { connect } from 'react-redux';
import CustomField from '../Form/CustomField/CustomField';
import { SelectPicker } from 'rsuite';
import {useIntl} from "react-intl";
import m from "../../../definedMessages";
import {BaseSelectPicker} from "../../base/BaseForm";

export const SDE_Component = ({
    subdestination_list,
    ...props
}) => {

    const {formatMessage} = useIntl();

    return (
        <CustomField
            accepter={Select}
            data={subdestination_list}
            labelKey="name"
            valueKey="sde_key"
            placeholder={formatMessage(m.subdestination)}
            name="sde_key"
            // classPrefix="allocated"
            {...props}
        />
    )
};

const mapState = ( {references} )=> ({
    subdestination_list: references.subdestination_list
});

export const SDE = connect( mapState, {})(SDE_Component); 
export default SDE;


const Select = ({onChange = (v) => v , ...props}) => {
    return (
        <BaseSelectPicker
            onChange={(value) => onChange(value === null ? undefined : value)}
            {...props}
        />
    )
};