import React, {useState} from 'react';
import {DateRangePicker, Schema} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import {removeTZFromDate} from '../../utils/helpers';
import {debounce} from 'lodash';
import {CustomizableForm} from '../../components/client/Form/CustomizableForm';
import {useIntl} from "react-intl";
import {compose} from "redux";
import {useWindowWidth} from "../../hooks";
import {FlexGrid, FlexGridItem} from '../../components/base/FlexGrid';
import CustomField from '../../components/client/Form/CustomField/CustomField';
import m from "../../definedMessages";
import {BaseDateRangePicker, BaseInputField} from "../../components/base/BaseForm";

const {StringType} = Schema.Types;

const MAX_CHARACTERS = 40;

const TestCallsFilters = ({loading, onChangeFilters, savedTableFilter, ...props}) => {
    const {formatMessage} = useIntl();

    const filtersModel = Schema.Model({
            a_number: StringType()
                .pattern(/^\d+$/, formatMessage(m.correctNumber))
                .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
            b_number: StringType()
                .pattern(/^\d+$/, formatMessage(m.correctNumber))
                .maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS})),
        }
    );

    const resizedWidth = useWindowWidth();

    // let form;

    const todayDateStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));
    const [changedFormValue, changeFormData] = useState(savedTableFilter || {
        start_end_date: [todayDateStart, todayDateEnd],
        start_date: null,
        end_date: null,
        a_number: '',
        b_number: ''
    });

    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData).map(i => checkedData[i].hasError).every(value => value === false);

        if (checker) {
            onChangeFilters({
                ...data,
                start_date: removeTZFromDate(data.start_date),
                end_date: removeTZFromDate(data.end_date),
            });
        }

        return false;
    };

    const calendarPlacement = resizedWidth >= 768 ? (props.rtl ? "bottomEnd" : "bottomStart") : 'auto';

    return (
        <CustomizableForm
            // ref={ref => (form = ref)}
            model={filtersModel}
            onChange={debounce((data) => {
                const startDate = new Date(new Date(data.start_end_date[0]).setHours(0, 0, 0, 0));
                const endDate = new Date(new Date(data.start_end_date[1]).setHours(23, 59, 59, 999));
                const formData = {...data, start_date: startDate, end_date: endDate};
                const checkedData = filtersModel.check(formData);
                onSubmit(formData, checkedData);
                changeFormData(formData);
            }, 1000)}
            formDefaultValue={savedTableFilter || {
                    start_end_date: [todayDateStart, todayDateEnd],
                    start_date: null,
                    end_date: null,
                    a_number: '',
                    b_number: ''
                }
            }
            // formValue={changedFormValue}
            fluid
            readOnly={loading}
        >

            <FlexGrid align="middle">

                {
                    (resizedWidth > 912) &&
                        <FlexGridItem>{formatMessage(m.fromDate)}</FlexGridItem>
                }
                <FlexGridItem width={resizedWidth > 767 ? "230px" : "100%"}>
                    <CustomField
                        name="start_end_date"
                        placement={calendarPlacement}
                        showOneCalendar={resizedWidth <= 767}
                        accepter={BaseDateRangePicker}
                        value={[changedFormValue.start_end_date[0], changedFormValue.start_end_date[1]]}
                        cleanable={false}
                        ranges={[
                            {
                                label: formatMessage(m.yesterday),
                                value: [
                                    new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)),
                                    new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999))
                                ]
                            }, {
                                label: formatMessage(m.today),
                                value: [
                                    new Date(new Date().setHours(0, 0, 0, 0)),
                                    new Date(new Date().setHours(23, 59, 59, 999))
                                ]
                            }, {
                                label: formatMessage(m.last7Days),
                                value: [
                                    new Date(new Date(new Date().setDate(new Date().getDate() - 6)).setHours(0, 0, 0, 0)),
                                    new Date(new Date().setHours(23, 59, 59, 999))
                                ]
                            }]}
                    />
                </FlexGridItem>

                <FlexGridItem width={resizedWidth > 767 ? "230px" : "100%"}>
                    <CustomField
                        accepter={BaseInputField}
                        name="a_number"
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.aNumber)}
                    />
                </FlexGridItem>

                <FlexGridItem width={resizedWidth > 767 ? "230px" : "100%"}>
                    <CustomField
                        accepter={BaseInputField}
                        name="b_number"
                        errorPlacement="topEnd"
                        placeholder={formatMessage(m.bNumber)}
                    />

                </FlexGridItem>
            </FlexGrid>
        </CustomizableForm>
    );
};

export default compose(
    FormHOC
)(TestCallsFilters)