import React, {useState} from 'react';
import {Form, Schema, SelectPicker} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import ServicePlans from '../../components/Filters/ServicePlans';
import Text from '../../components/Form/Text';
import CustomField from "../../components/Form/CustomField";
import {OTP_SERVICE_PLAN_KEY} from '../../const';
import styled from 'styled-components';

const { StringType } = Schema.Types;

const filtersModel = Schema.Model({
    str: StringType().maxLength(40, 'Max characters 40'),
    }
);

export default FormHOC( ({ onChange, currency_list, defaultFormValue }) => {
    const [changedServicePlan, onChangeServicePlan] = useState(null);
    return (
        <Form
            model={filtersModel}
            onChange={onChange}
            formDefaultValue = {defaultFormValue}
            layout="inline"
            style = {{marginBottom: '-10px'}}
        >
            <StyledField>
                <ServicePlans
                    filtered
                    onChange={(value) => {
                        onChangeServicePlan(value)
                    }}
                />
            </StyledField>

            <StyledField>
                <CustomField
                    accepter={SelectPicker}
                    style={{
                        maxWidth: '80px',
                        minWidth: '30px'
                    }}
                    data={currency_list}
                    cleanable={false}
                    searchable={false}
                    labelKey="name"
                    valueKey="cur_key"
                    name="cur_key"
                    classPrefix="allocated"
                />
            </StyledField>

            <StyledField>
                <Text name="str" placeholder={`Filter per origin, CLI${changedServicePlan === OTP_SERVICE_PLAN_KEY ? ', Service' : ''}`}/>
            </StyledField>
        </Form>
    )
},filtersModel, 500)


const StyledField = styled.div`
    display: inline-block;
`;