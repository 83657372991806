import React, {useRef} from "react";
import {Form, SelectPicker, Schema} from "rsuite";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";
import {debounce} from "lodash";
import CustomField from "../../components/Form/CustomField";
import Text from "../../components/Form/Text";

const {StringType} = Schema.Types;

const formModel = Schema.Model({
    prefix: StringType()
        .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
        .minLength(6, 'The minimum is 6 characters.')
        .maxLength(40, "The maximum is 15 characters"),
});


export default ({
    defaultFilter = {},
    subDestinationList = [],
    onChangeFilter,
}) => {

    const formRef = useRef(null);

    const handleChange = debounce( (value) => {
        if ( !formRef.current.check() )
            return;
        onChangeFilter(value);
    }, 500);

    return (
        <>
            <Form
                ref={formRef}
                model={formModel}
                defaultFormValue={defaultFilter}
                onChange={handleChange}
            >
                <FlexGrid align="middle">
                    <FlexGridItem>
                        <CustomField
                            accepter={SelectPicker}
                            width={200}
                            data={subDestinationList}
                            labelKey="name"
                            valueKey="sde_key"
                            placeholder="Subdestination"
                            name="sde_key"
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <Text
                            name="prefix"
                            placeholder="Prefix"
                            styleFormGroupExternal={{'margin-bottom': 0}}
                            width={240}
                        />
                    </FlexGridItem>
                </FlexGrid>
            </Form>
        </>
    )
};