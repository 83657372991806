import React from 'react';
import {Modal as RsuiteModal} from "rsuite";
import {ButtonCancel, ButtonContent, ButtonPrimary} from '../base/BaseButton';
import {BaseModal} from '../base/BaseModal';

export const Modal = ({
    show, 
    onClose, 
    onSuccess,
    checkBefore, 
    children, 
    title, 
    width, 
    disabled,
    footer = false, 
    successText = "Ok",
    extraDisabled = false,
    extraDialogClassName="",
    successButtonColor = "#20BA88",
    onEntered = () => {}
}) => {

    const style = {};
    width && (style.width = width);

    return (
        <BaseModal
            show={show} 
            onHide={onClose} 
            style={style}
            onEntered={onEntered}
            dialogClassName={extraDialogClassName}
        >
            
            <BaseModal.Header>
                <BaseModal.Title>{title}</BaseModal.Title>
            </BaseModal.Header>

            <BaseModal.Body>
                {children}
            </BaseModal.Body>
            
            {footer && 
                <BaseModal.Footer
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}>
                    <ButtonPrimary color={successButtonColor} loading={disabled} disabled={extraDisabled} onClick={() => {
                        if(checkBefore && !checkBefore()) return false;
                        onSuccess()
                    }} >
                        {successText}
                    </ButtonPrimary>
                    <ButtonContent
                        onClick={onClose}
                        disabled={disabled}
                    >
                        Cancel
                    </ButtonContent>
                </BaseModal.Footer>
            }

        </BaseModal>
    );
};

export default Modal;