import React from 'react';
import {FormControl, Schema} from 'rsuite';
import FormHOC from '../../hoc/FilltersForm';
import {CustomizableForm} from '../../components/client/Form/CustomizableForm';
import {debounce} from 'lodash';
import {injectIntl} from "react-intl";
import {compose} from "redux";
import {useWindowWidth} from "../../hooks";
import { FlexGrid, FlexGridItem } from '../../components/base/FlexGrid';
import m from "../../definedMessages";
import {BaseInputField} from "../../components/base/BaseForm";

const {StringType} = Schema.Types;

const MAX_CHARACTERS = 40;

const LiveCallsFilters = (({
    loading, 
    onChangeFilters, 
    savedTableFilter, 
    intl
}) => {

    const {formatMessage} = intl;

    const filtersModel = Schema.Model({
            number_destination: StringType().maxLength(MAX_CHARACTERS, formatMessage(m.maxLength, {count: MAX_CHARACTERS}))
        }
    );

    const resizedWidth = useWindowWidth();

    const onSubmit = (data, checkedData) => {
        const checker = Object.keys(checkedData)
            .map(i => checkedData[i].hasError)
            .every(value => value === false);

        if (checker) {
            onChangeFilters({...data});
        }
        return false;
    };

    return (
        <CustomizableForm
            model={filtersModel}
            formDefaultValue={savedTableFilter || {number_destination: ''}}
            onChange={debounce((data) => {
                const checkedData = filtersModel.check(data);
                onSubmit(data, checkedData);
            }, 1000)}
            fluid
            readOnly={loading}
        >
            <FlexGrid align="middle">
                <FlexGridItem width="260px">
                   <FormControl
                       accepter={BaseInputField}
                       name='number_destination'
                       errorPlacement='topEnd'
                       placeholder={resizedWidth > 300 ? formatMessage(m.filterPerNumOrDest) : 'Numbers/Destination'}
                   />
                </FlexGridItem>
            </FlexGrid>
        </CustomizableForm>
    );
});

export default compose(
    FormHOC,
    injectIntl
)(LiveCallsFilters)
