import React, {useEffect, useMemo, useState} from "react";
import {
    IVR_CREATE_API,
    IVR_REMOVE_API,
    IVR_MODIFY_API,
} from "../../../const/apiMethods";
import {api} from "../../../api/loginRoutes";
import _ from "lodash";
import BlockIvrList from "./BlockIvrList";
import BlockIvrActionList from "./BlockIvrActionList";
import BlockIvrListFilter from "./BlockIvrListFilter";
import {Spacer} from "../../../components/base/Spacer";
import {FlexGrid, FlexGridItem} from "../../../components/base/FlexGrid";


export default (
    {
        loading,
        ivrList,
        ivrTypes,
        getIvrList,
        getIvrTypes,
        getIvrSoundList,
    }
) => {


    const [activeIvr, setActiveIvr] = useState(null);
    const [submitIvrDataLoading, setSubmitIvrDataLoading] = useState(false);
    const [filter, setFilter] = useState({});
    

    useEffect(() => {
        getIvrList();
        getIvrTypes();
        getIvrSoundList();
    }, []);

    useEffect(() => {
        if (ivrList.length) {
            setActiveIvr(ivrList[0]);
        } else {
            setActiveIvr(null);
        }
    }, [ivrList]);


    const handleSelectIvr = (id) => {
        if (!id) {
            setActiveIvr(null);
        }
        const activeIvr = ivrList.find(item => item.id === id);
        setActiveIvr(activeIvr);
    };


    const handleSelectIvrCreate = () => {
        setActiveIvr({name: null, isNew: true});
    };


    const submitIvr = (data) => {
        const method = activeIvr.isNew
            ? IVR_CREATE_API
            : IVR_MODIFY_API;

        setSubmitIvrDataLoading(true);
        api(method, data)
            .then((r) => getIvrList())
            .finally(() => {
                setSubmitIvrDataLoading(false);
            });
    };


    const removeIvr = () => {
        api(IVR_REMOVE_API, {target: {ivr_id: activeIvr.id}})
    };


    const filteredIvrList = useMemo(() => (
        ivrList.filter(item => {
            if (!filter.str)
                return true;
            const str = _.toLower(filter.str);
            return _.toLower(item.name).indexOf(str) !== -1;
        })
    ), [filter, ivrList]);


    return (
        <>
            <Spacer size={30}/>
            <BlockIvrListFilter
                activeIvr={activeIvr}
                onChangeFilter={setFilter}
            />
            <Spacer size={20}/>

            <FlexGrid hSpace={'-20px'} align={'stretch'}>

                <FlexGridItem width="300px" hSpace={'20px'}>
                    <BlockIvrList
                        list={filteredIvrList}
                        submitLoading={submitIvrDataLoading}
                        {...{
                            activeIvr,
                            loading,
                            handleSelectIvr,
                            handleSelectIvrCreate
                        }}
                    />
                </FlexGridItem>

                <FlexGridItem grow={1} hSpace={'20px'}>
                    <BlockIvrActionList
                        {...{
                            loading,
                            activeIvr,
                            ivrTypes,
                            submitIvr,
                            removeIvr
                        }}
                    />
                </FlexGridItem>

            </FlexGrid>

            <Spacer size={30}/>
        </>
    )
};