import React, {useState, useEffect} from 'react';
import {Button, Form, FormControl, Icon, Input, Schema, SelectPicker, Table} from 'rsuite';
import styled from "styled-components";
import BaseTable from "../../components/base/BaseTable";

const {Column, HeaderCell, Cell} = Table;
const {NumberType, StringType} = Schema.Types;

const formModel = Schema.Model({
    a_wz_key: NumberType(),
    a_de_key: NumberType(),
    a_sde_key: NumberType(),
    a_prefix: StringType()
        .pattern(/^[0-9][\d]*$/, 'The number must contain only digits')
        .minLength(6, "Min length is 6 characters")
        .maxLength(15, "Max length is 15 characters"),
    b_wz_key: NumberType(),
    b_de_key: NumberType(),
    b_sde_key: NumberType(),
    bil_acc_orig: NumberType(),
    bil_acc_term: NumberType(),
});

export default ({data = [], filter = {}, onRemoveItem, onEditItem, loading, isLoading, update, ...props}) => {
    const [tableData, setTableData] = useState([]);
    const [currentEditedFieldKeyList, setCurrentEditedFieldKeyList] = React.useState(null);
    const [editedFieldKeyList, setEditedFieldKeyList] = useState([]);
    const [localLoading, setLocalLoading] = useState(isLoading);
    const [inited, setInited] = useState(false);

    const [disabledEditedFields, setDisabledEditedFields] = useState([]);

    let formGroupsRefs = new Map();

    const searchedData = [
        "a_worldzone_name", "a_destination_name", "a_subdestination_name", 
        "a_prefix", "b_worldzone_name", "b_destination_name", 
        "b_subdestination_name", "bil_acc_orig_name", "bil_acc_term_name"
    ];

    useEffect( () => {

        const tableDataList = data.filter((item) => {
            // return item if filter is empty
            if (!filter || filter && !filter.str)
                return true;

            const match = filter.str.toLowerCase();

            const filterMatching = searchedData.map((keyName) => {
                if (item[keyName]) {
                    const matchedString = item[keyName].toString().toLowerCase();
                    console.log('FILTER::matching', matchedString, match, matchedString.includes(match));
                    if (matchedString.includes(match)) {
                        return true
                    }
                }
            });

            return filterMatching.some(dataMatch => !!dataMatch);
        });

        setTableData(tableDataList);
        setInited(true);
        if (currentEditedFieldKeyList) {
            let editedFilters = new Set([...editedFieldKeyList, currentEditedFieldKeyList]);
            editedFilters.delete(currentEditedFieldKeyList);
            setEditedFieldKeyList(Array.from(editedFilters));
            setCurrentEditedFieldKeyList(null);
            setDisabledEditedFields([]);
        }
    }, [data, filter]);


    useEffect( () => {
        if (!inited)
            return;
        setTableData([...tableData]);
        if (tableData.length) {
            setTimeout(() => {
                setTableData([...tableData]);
            });
        }
    }, [editedFieldKeyList, inited]);

    const createFormGroupRefs = (key, ref) => {
        if ( !formGroupsRefs.has(key) ) {
            formGroupsRefs.set(key, new Set([ref]))
        } else {
            formGroupsRefs.get(key).add(ref);
        }
    };

    const handleSubmit = (key) => {
        const formFields = formGroupsRefs.get(key);

        let data = {};
        formFields.forEach(field => {
            if (!field)
                return;

            const formData = field.getFormValue();
            data = Object.assign(data, formData);
        });

        data.a_prefix = data.a_prefix || null;

        const promise = onEditItem(key, data).then(() => {
            update()
        });

        let editedFilters = new Set([...editedFieldKeyList, key]);
        editedFilters.delete(key);
        setEditedFieldKeyList(Array.from(editedFilters));

        promise.then(() => {
            setCurrentEditedFieldKeyList(null)
        })
    };

    const setDisabledItemForm = (id) => {
        if ( id && !disabledEditedFields.includes(id)) {
            setDisabledEditedFields([...disabledEditedFields, id]);
        }
    }

    const setEnabledItemForm = (id) => {
        if ( id && disabledEditedFields.includes(id)) {
            setDisabledEditedFields(disabledEditedFields.filter(item => item !== id))
        }
    }

    return (
        <StyledTable
            className="tableFilters"
            data={tableData}
            loading={loading || localLoading}
            headerHeight={47}
            wordWrap
            autoHeight
        >

            <Column flexGrow minWidth={130}>
                <HeaderCell>
                    <span className="tableFilters__headerText">Originator / Origin</span>
                </HeaderCell>
                <Cell dataKey={"bil_acc_orig_name"}>
                    {(rowData) => {
                        const previewParts = {
                            originator: [
                                rowData["bil_acc_orig_name"]
                            ].filter(item => item),
                            origin: [
                                rowData["a_worldzone_name"],
                                rowData["a_destination_name"],
                                rowData["a_subdestination_name"],
                                rowData["a_prefix"]
                            ].filter(item => item),
                        };
                        if ( editedFieldKeyList.includes(rowData["alx_key"]) ) {
                            return (
                                <StyledForm
                                    model={formModel}
                                    ref={(ref) => createFormGroupRefs(rowData["alx_key"], ref)}
                                    onChange={(val) => {
                                        const isEnabled = Object.keys(val).find(item => {
                                            return !!val[item];
                                        });
                                        if (!isEnabled) {
                                            setDisabledItemForm(rowData["alx_key"]);
                                        } else {
                                            setEnabledItemForm(rowData["alx_key"]);
                                        }
                                    }}
                                    formDefaultValue={{
                                        bil_acc_orig: rowData["bil_acc_orig"],
                                        a_prefix: rowData["a_prefix"],
                                        a_wz_key: rowData["a_wz_key"],
                                        a_de_key: rowData["a_de_key"],
                                        a_sde_key: rowData["a_sde_key"],
                                    }}
                                >
                                    <StyledField
                                        name="bil_acc_orig"
                                        accepter={SelectPicker}
                                        data={props.clientList}
                                        placeholder="Originator name"
                                        valueKey="acc_key"
                                        labelKey="name"
                                    />
                                    <StyledField
                                        name="a_prefix"
                                        accepter={Input}
                                        placeholder="Origin Prefix"
                                    />
                                    <StyledField
                                        name="a_wz_key"
                                        accepter={SelectPicker}
                                        data={props.worldzoneList}
                                        placeholder="Origin zone"
                                        valueKey="wz_key"
                                        labelKey="name"
                                    />
                                    <StyledField
                                        name="a_de_key"
                                        accepter={SelectPicker}
                                        data={props.destinationList}
                                        placeholder="Origin country"
                                        valueKey="de_key"
                                        labelKey="name"
                                    />
                                    <StyledField
                                        name="a_sde_key"
                                        accepter={SelectPicker}
                                        data={props.subdestinationList}
                                        placeholder="Origin operator"
                                        valueKey="sde_key"
                                        labelKey="name"
                                    />
                                </StyledForm>
                            )
                        }
                        return (
                            <>
                                <span className="tableFilters__previewText">
                                    <b>Originator</b>: {previewParts.originator.length ? previewParts.originator.join(' / ') : 'Any'}, &nbsp;
                                    <b>Origin</b>: {previewParts.origin.length ? previewParts.origin.join(' / ') : 'Any'}
                                </span>
                            </>
                        )
                    }}
                </Cell>
            </Column>

            <Column flexGrow minWidth={130}>
                <HeaderCell>
                    <span className="tableFilters__headerText">Destination / Terminator</span>
                </HeaderCell>
                <Cell dataKey={"bil_acc_orig_name"}>
                    {(rowData) => {
                        const previewParts = {
                            destination: [
                                rowData["b_worldzone_name"],
                                rowData["b_destination_name"],
                                rowData["b_subdestination_name"]
                            ].filter(item => item),
                            terminator: [
                                rowData["bil_acc_term_name"]
                            ].filter(item => item),
                        };
                        if ( editedFieldKeyList.includes(rowData["alx_key"]) ) {
                            return (
                                <StyledForm
                                    model={formModel}
                                    ref={(ref) => createFormGroupRefs(rowData["alx_key"], ref)}
                                    onChange={(val) => {
                                        const isEnabled = Object.keys(val).find(item => {
                                            return !!val[item];
                                        });
                                        if (!isEnabled) {
                                            setDisabledItemForm(rowData["alx_key"]);
                                        } else {
                                            setEnabledItemForm(rowData["alx_key"]);
                                        }
                                    }}
                                    formDefaultValue={{
                                        b_wz_key: rowData["b_wz_key"],
                                        b_de_key: rowData["b_de_key"],
                                        b_sde_key: rowData["b_sde_key"],
                                        bil_acc_term: rowData["bil_acc_term"]
                                    }}
                                >
                                    <StyledField
                                        name="b_wz_key"
                                        accepter={SelectPicker}
                                        data={props.worldzoneList}
                                        placeholder="Desctination zone"
                                        valueKey="wz_key"
                                        labelKey="name"
                                    />
                                    <StyledField
                                        name="b_de_key"
                                        accepter={SelectPicker}
                                        data={props.destinationList}
                                        placeholder="Destination country"
                                        valueKey="de_key"
                                        labelKey="name"
                                    />
                                    <StyledField
                                        name="b_sde_key"
                                        accepter={SelectPicker}
                                        data={props.subdestinationList}
                                        placeholder="Destination operator"
                                        valueKey="sde_key"
                                        labelKey="name"
                                    />
                                    <StyledField
                                        name="bil_acc_term"
                                        accepter={SelectPicker}
                                        data={props.clientList}
                                        placeholder="Terminator name"
                                        valueKey="acc_key"
                                        labelKey="name"
                                    />
                                </StyledForm>
                            )
                        }
                        return (
                            <>
                                <span className="tableFilters__previewText">
                                    <b>Destination</b>: {previewParts.destination.length ? previewParts.destination.join(' / ') : 'Any'}, &nbsp;
                                    <b>Terminator</b>: {previewParts.terminator.length ? previewParts.terminator.join(' / ') : 'Any'}
                                </span>
                            </>
                        )
                    }}
                </Cell>
            </Column>

            <Column width={174}>
                <HeaderCell></HeaderCell>
                <Cell>
                    {rowData => (
                        <div className="tableFilters_buttons">
                            {!editedFieldKeyList.includes(rowData["alx_key"])
                                ? <Button 
                                    size="sm" 
                                    color="lightblue" 
                                    onClick={() => {
                                        if ([
                                            rowData["bil_acc_orig"],
                                            rowData["a_prefix"],
                                            rowData["a_wz_key"],
                                            rowData["a_de_key"],
                                            rowData["a_sde_key"],
                                            rowData["b_wz_key"],
                                            rowData["b_de_key"],
                                            rowData["b_sde_key"],
                                            rowData["bil_acc_term"]
                                        ].every(item => !item)) {
                                            setDisabledItemForm(rowData["alx_key"]);
                                        }

                                        let editedFilters = new Set([...editedFieldKeyList, rowData["alx_key"]]);
                                        setEditedFieldKeyList(Array.from(editedFilters));
                                    }}
                                >
                                    <Icon icon="edit2"/>
                                </Button>
                                : <>
                                    <Button 
                                        size="sm" 
                                        color="green" 
                                        disabled={disabledEditedFields.includes(rowData["alx_key"])}
                                        onClick={() => {
                                            handleSubmit(rowData["alx_key"]);
                                        }}
                                    >
                                        <Icon icon="check-circle"/>
                                    </Button>

                                    <Button 
                                        size="sm" 
                                        color="red" 
                                        onClick={() => {
                                            let editedFilters = new Set([...editedFieldKeyList, rowData["alx_key"]]);
                                            editedFilters.delete(rowData["alx_key"]);
                                            setEditedFieldKeyList(Array.from(editedFilters));
                                            setEnabledItemForm(rowData["alx_key"]);
                                        }}
                                    >
                                        <Icon icon="close-circle"/>
                                    </Button>
                                </>
                            }
                            <Button
                                size="sm"
                                color="red"
                                onClick={() => {
                                    onRemoveItem(rowData["alx_key"]);
                                    setEnabledItemForm(rowData["alx_key"]);
                                }}
                            >
                                <Icon icon="trash2"/>
                            </Button>
                        </div>
                    )}
                </Cell>
            </Column>

        </StyledTable>
    )
}

const StyledForm = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    
    .rs-form-control-wrapper {
        flex-shrink: 0;
        min-width: 175px;
        padding-bottom: 5px;
        padding-right: 5px;
        width: 33%;
    }
`;

const StyledField = styled(FormControl).attrs(props =>({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    type: "text",
}))`
`;

const StyledTable = styled(BaseTable)`
    && {
    
        .tableFilters__previewText {
            display: block;
            line-height: 20px;
            margin-top: 7px;
            word-break: normal;
        }
        
        .tableFilters_buttons {
            height: 20px;
            margin-top: 7px;
        }
    }
`;