import {
    AM_ACTIVITIES_SET_LOADING,
    AM_ACTIVITIES_LIST,
    AM_ACTIVITIES_DATE_LIST_ITEM,
    AM_ACTIVITIES_DATE_LIST_ITEM_REMOVE,
    AM_ACTIVITIES_DATE_LIST_REMOVE,
} from "../const";

import {
    AM_ACTIVITIES_LIST_API,
    AM_ACTIVITIES_DATE_LIST_API
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";
import {toEndDayUTCTime, toStartDayUTCTime} from "../utils";

const setAmActivitiesList = createAction(AM_ACTIVITIES_LIST);
const setAmActivitiesLoading = createAction(AM_ACTIVITIES_SET_LOADING);
const setAmActivitiesDateListItem = createAction(AM_ACTIVITIES_DATE_LIST_ITEM);
export const removeAmActivitiesDateListItem = createAction(AM_ACTIVITIES_DATE_LIST_ITEM_REMOVE);
export const removeAmActivitiesDateList = createAction(AM_ACTIVITIES_DATE_LIST_REMOVE);


// get list
export const getAmActivitiesList = (filter = {}) => (dispatch) => {
    dispatch( setAmActivitiesLoading(true) );

    const data = {
        filter: {...filter},
        target: {account_id_list: null},
    };

    if (filter.range && filter.range.length) {
        data.filter['start_date'] = toStartDayUTCTime(filter.range[0]);
        data.filter['end_date'] = toEndDayUTCTime(filter.range[1]);

        delete data.filter.range;
    }

    if (filter.account_manager_user_id_list) {
        data.target.account_user_manager_id_list = filter.account_manager_user_id_list && filter.account_manager_user_id_list.length
            ? filter.account_manager_user_id_list
            : null;
        delete data.filter.account_manager_user_id_list;
    }

    api(AM_ACTIVITIES_LIST_API, data)
        .then((response) => {
            if (response !== undefined) {

                dispatch( setAmActivitiesList({
                    list: response.statistic_by_account_user_manager || [],
                }) );
            }
        })
        .finally(() => {
            dispatch( setAmActivitiesLoading(false) );
        })
};


// get manager list
export const getAmActivitiesDateList = (id, filter = {}) => (dispatch) => {

    dispatch( setAmActivitiesLoading(true) );

    const data = {
        target: {account_user_manager_id: id},
        filter: {...filter},
    };

    if (filter.range && filter.range.length) {
        data.filter['start_date'] = toStartDayUTCTime(filter.range[0]);
        data.filter['end_date'] = toEndDayUTCTime(filter.range[1]);
        delete data.filter.range;
    }

    api(AM_ACTIVITIES_DATE_LIST_API, data)
        .then((response) => {
            if (response === undefined) {
                dispatch( setAmActivitiesLoading(false) );
                return;
            }

            dispatch(setAmActivitiesDateListItem({
                list: response.statistic_by_account_user_manager_date || [],
                id
            }));
        })
};