import React, {useContext, useMemo} from 'react';
import {Alert, FlexboxGrid, Icon, List, Panel, Button} from 'rsuite';
import {
    arrayToObject,
    between,
    getRangeByDigit,
    renderColumn,
    makeRangeFromPrefixesToCsv,
    getCsvDataWithHeaders,
    onDownloadCompleteNumbersCSV,
    onDownloadCompletePrefixesCSV,
    copyToClipboard,
    makeNumbersFromPrefixesToCsv
} from '../../../utils/helpers';
import {useIntl} from 'react-intl';
import {EOL} from './../../../const';
import {useWindowWidth} from '../../../hooks';
import styled, {css} from 'styled-components';
import {Spacer} from '../../../components/base/Spacer';
import ButtonToolbar from 'rsuite/es/ButtonToolbar';
import Dropdown from 'rsuite/es/Dropdown';
import Loader from 'rsuite/es/Loader';
import m from "../../../definedMessages";
import {CustomModalClient} from "../../base";
import BaseTable from "../../base/BaseTable";
import {RtlContext} from "../../../App";


const headerStyle = {
    background: '#fff',
    color: '#000'
};


export default ({
    maxWidth = 600, show, onClose, priceData = {}, extraColumns,
    downloadNumbers, downloadNumbersTxt, loading, trunkId, uploadLoading, trunkAllocatedByOTP,
    methodName, csvContent, allocatedNumbersCount, prefixViewNumbers,
    notAllocatedNumbers, trunkNumberTransaction, setUploadingLoading,
}) => {

    const {formatMessage} = useIntl();
    const rtl = useContext(RtlContext);
    
    const statusLabels = useMemo(() => ({
        1: formatMessage(m.numberIsUnavailable),
        3: formatMessage(m.destinationDoesNotExist),
        4: formatMessage(m.noRateForThisDestination),
        7: formatMessage(m.numberIsQuarantined),
        10: formatMessage(m.numbersFromRangeCannotBeAllocatedSingle),
    }), []);

    const resizedWidth = useWindowWidth();
    const [isEnetered, setIsEntered] = React.useState(false);
    const columns = [
        {
            id: 'Prefix',
            dataKey: 'Prefix',
            align: 'center',
            className: 'number',
            label: formatMessage(m.prefix),
            headerStyle: headerStyle,
            width: !trunkNumberTransaction || trunkNumberTransaction.method2 !== "google_otp" ? 170 : 230,
        },
        {
            id: 'Range name',
            dataKey: 'Range name',
            align: 'left',
            label: formatMessage(m.rangeName),
            className: 'range-name',
            headerStyle: headerStyle,
            width: !trunkNumberTransaction || trunkNumberTransaction.method2 !== "google_otp" ? 230 : 260,
        },
        {
            id: 'Payout',
            dataKey: 'Payout',
            align: 'center',
            label: formatMessage(m.payout),
            headerStyle: headerStyle,
            width: 75,
        },
        {
            id: 'Currency',
            dataKey: 'Currency',
            align: 'center',
            label: formatMessage(m.currency),
            headerStyle: headerStyle,
            width: 75,
        },
        {
            id: 'Billing increment',
            dataKey: 'Billing increment',
            align: 'center',
            label: formatMessage(m.billingIncrement),
            headerStyle: headerStyle,
            width: 110,
        },
    ];


    const compareCsvOnlyNumbersData = (list) => {
        return list && list.length ? list.split().join(';') : [];
    };


    const getCsvBodyWithHeaders = (csvData) => {
        return `data:text/csv;charset=utf-8,${getCsvDataWithHeaders(csvData).join(EOL)}`;
    };


    const getCsvBody = (csvData) => {
        return `data:text/csv;charset=utf-8,${csvData}`;
    };


    const onlyNumbers = (csvData) => {
        if (!csvData || !csvData.length)
            return [];

        const dataArr = csvData.slice(1).map(line => line.split(';')[0]);
        if (trunkNumberTransaction.method2 === "google_otp") {

            const chunksTotal = dataArr.length / 100;
            const chunks = [];
            for (let i = 0; i < chunksTotal; i++) {
                const startItem = i * 100;
                const endItem = startItem + 100;
                const chunk = dataArr.slice(startItem, endItem);
                chunk.sort(() => Math.random() - 0.5);
                chunk.sort(() => Math.random() - 0.5);
                chunks.push(chunk);
            }

            return chunks.reduce((sum, current) => {
                return [...sum, ...current];
            }, []).join(EOL);
        }
        return dataArr.join(EOL);
    };


    const csvOnlyNumbers = (csvData) => {
        return getCsvBody( compareCsvOnlyNumbersData( onlyNumbers(csvData) ) );
    };


    const renderCsvDataToTableData = (data, headers) => {
        const csvBody = data.length ? data.slice(1) : [];

        return (
            csvBody.map(line => {
                const items = line.split(';');
                const prefix = getRangeByDigit(items[0], items[1]);

                const body = items.map((item, index) => {
                    if (between(index, 2, 5)) {
                        return {[headers[index]]: item}
                    }
                    return null;
                }).filter(value => value);

                return {
                    [headers[0]]: prefix,
                    ...(arrayToObject(body))
                }
            })
                .filter(obj => Object.keys(obj).some(key => obj[key]))
        );
    };


    const numberListNotAllocated = notAllocatedNumbers || [];
    const uploadLoadSetting = uploadLoading[trunkId];

    let combinedTitle = formatMessage(m.newAllocatedNumbers);

    if (methodName === 'Trunks') {
        combinedTitle = formatMessage(m.newAllocatedNumbers);
    } else if (methodName === 'Ratecard') {
        combinedTitle = priceData.range_name
            ? `${formatMessage(m.getNumbersForRange)} ${priceData.range_name}`
            : formatMessage(m.newAllocatedNumbers);
    } else if (methodName === 'AccessList') {
        combinedTitle = formatMessage(m.allocatedNumberList);
    }

    const csvDataList = csvContent ? csvContent.split('\n') : [];
    const csvHeaders = csvDataList.length ? csvDataList[0].split(';') : [];
    const comparedData = renderCsvDataToTableData(csvDataList, csvHeaders);

    const tableColumns = extraColumns ? extraColumns : columns;
    const renderedColumns = tableColumns.map(renderColumn);

    if ( rtl ) renderedColumns.reverse();


    return (
        <CustomModalClient
            show={show}
            successButton={formatMessage(m.getNumbers)}
            title={combinedTitle}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            height={800}
            showCloseSuccessButtons={false}
            onEntered={() => {
                setTimeout( () => {
                    setIsEntered(true);
                }, 500 );
            }}
            onClose={() => {
                setIsEntered(false);
                onClose();
            }}
        >

            <div>
                <Icon icon="exclamation-triangle"/> {formatMessage(m.payYourAttention)}
            </div>

            <Spacer/>

            <div className="allocation-total">{formatMessage(m.numbersWereAllocated, {count:allocatedNumbersCount})}</div>

            <Spacer size={25}/>

            <StyledGrid justify="space-between">
                <StyledButtonToolbar>
                    <StyledDropdown
                        title={<>{uploadLoadSetting && <StyledLoader/>} {formatMessage(m.copyList)}</>}
                        disabled={uploadLoadSetting || !allocatedNumbersCount}
                    >
                        <Dropdown.Item
                            onClick={ () => {
                                const csvData = prefixViewNumbers ? makeNumbersFromPrefixesToCsv(csvContent) : csvContent;
                                const csvDataList = csvData && csvData.length ? csvData.split('\n') : [];
                                if (!allocatedNumbersCount) {
                                    Alert.warning( formatMessage(m.unableToCopyEmptyTable) )
                                } else {
                                    copyToClipboard( onlyNumbers(csvDataList) );
                                    Alert.success( formatMessage(m.copied) )
                                }
                            }}
                        >
                            {formatMessage(m.copyJustNumbers)}
                        </Dropdown.Item>

                        {prefixViewNumbers &&
                        <Dropdown.Item
                            onClick={() => {
                                const csvData = prefixViewNumbers
                                    ? makeRangeFromPrefixesToCsv(csvContent)
                                    : csvContent;

                                const csvDataList = csvData && csvData.length
                                    ? csvData.split('\n')
                                    : [];

                                if (!allocatedNumbersCount) {
                                    Alert.warning( formatMessage(m.unableToCopyEmptyTable) )
                                } else {
                                    copyToClipboard( getCsvDataWithHeaders(csvDataList).join(EOL) );
                                    Alert.success( formatMessage(m.copied) )
                                }
                            }}
                        >
                            {formatMessage(m.copyListAllocatedByPrefix)}
                        </Dropdown.Item>
                        }

                        <Dropdown.Item
                            onClick={() => {
                                const csvData = prefixViewNumbers ? makeNumbersFromPrefixesToCsv(csvContent) : csvContent;
                                const csvDataList = csvData && csvData.length ? csvData.split('\n') : [];
                                if (!allocatedNumbersCount) {
                                    Alert.warning( formatMessage(m.unableToCopyEmptyTable) )
                                } else {
                                    copyToClipboard( getCsvDataWithHeaders(csvDataList).join(EOL) );
                                    Alert.success( formatMessage(m.copied) )
                                }
                            }}
                        >
                            {formatMessage(m.copyList)}
                        </Dropdown.Item>
                    </StyledDropdown>
                </StyledButtonToolbar>

                <StyledButtonToolbar>
                    <StyledDropdown
                        title={<>{uploadLoadSetting && <StyledLoader/>} {formatMessage(m.downloadList)}</>}
                        disabled={uploadLoadSetting}
                        onSelect={() => setUploadingLoading(trunkId, true)}
                    >
                        <Dropdown.Item
                            onClick={() => onDownloadCompleteNumbersCSV(trunkId, setUploadingLoading)}>
                            {resizedWidth > 400
                                ? <span>{formatMessage(m.downloadCompleteList)}</span>
                                : <span>{formatMessage(m.downloadAllNumbers)}</span>
                            }
                        </Dropdown.Item>


                        {trunkAllocatedByOTP && <Dropdown.Item
                            onClick={() => onDownloadCompletePrefixesCSV(trunkId, setUploadingLoading)}
                        >
                            {formatMessage(m.downloadCompletePrefixesListAs, {type: 'CSV'})}
                        </Dropdown.Item>}

                        <Dropdown.Item
                            disabled={!allocatedNumbersCount}
                            onClick={() => {
                                const csvData = csvContent ? makeNumbersFromPrefixesToCsv(csvContent).split('\n') : [];
                                const csvBody = getCsvBodyWithHeaders(csvData);
                                downloadNumbers(csvBody, trunkId, setUploadingLoading)
                            }}
                        >
                            {formatMessage(m.downloadNewAllocatedNumbers)}
                        </Dropdown.Item>

                        {prefixViewNumbers && <Dropdown.Item
                            disabled={!allocatedNumbersCount}
                            onClick={() => {
                                const csvDataPrefixes = csvContent ? makeRangeFromPrefixesToCsv(csvContent).split('\n') : [];
                                const csvBody = getCsvBodyWithHeaders(csvDataPrefixes);
                                downloadNumbers(csvBody, trunkId, setUploadingLoading)
                            }}
                        >
                            {formatMessage(m.downloadNewAllocatedNumbersAsRanges)}
                        </Dropdown.Item>}

                        <Dropdown.Item
                            disabled={!allocatedNumbersCount}
                            onClick={() => {
                                const csvDataPrefixes = csvContent ? makeNumbersFromPrefixesToCsv(csvContent).split('\n') : [];
                                downloadNumbersTxt(csvOnlyNumbers(csvDataPrefixes), trunkId, setUploadingLoading)
                            }}
                        >
                            {formatMessage(m.downloadOnlyNumbers)}
                        </Dropdown.Item>

                    </StyledDropdown>
                </StyledButtonToolbar>

                <StyledButton
                    onClick={() => onClose(false)}
                >
                    <Icon icon='window-close-o'/> {formatMessage(m.close)}
                </StyledButton>

            </StyledGrid>

            <FlexboxGrid justify='space-around'>

                <StyledTable
                    prefixViewNumbers={prefixViewNumbers}
                    className="access-table"
                    virtualized={true}
                    height={250}
                    width={resizedWidth > maxWidth ? maxWidth - 100 : resizedWidth - 100}
                    data={comparedData}
                    rowHeight={35}
                    wordWrap={resizedWidth > maxWidth}
                    loading={loading || !isEnetered}
                >
                    {renderedColumns}
                </StyledTable>

            </FlexboxGrid>

            {numberListNotAllocated.length !== 0 &&
            <>
                <Spacer size={30}/>
                <Panel
                    bordered
                    collapsible
                    header={formatMessage(m.numbersWereNotAllocated, {
                        count: numberListNotAllocated.length
                    })}
                >
                    <StyledList>
                        {numberListNotAllocated.map( (item, index) => (
                            <List.Item
                                key={index}
                                index={index}
                            >
                                {statusLabels[item.status]
                                    ? `${item.number} ${statusLabels[item.status]}`
                                    : formatMessage(m.cannotBeAllocated, {number: item.number})
                                }
                            </List.Item>
                        ))}
                    </StyledList>
                </Panel>
            </>
            }

        </CustomModalClient>
    );
};


const StyledGrid = styled(FlexboxGrid)`
    padding: 0 20px 20px 20px;
    width: 100%;
`;


const StyledDropdown = styled(Dropdown).attrs(props => ({
    appearance: "ghost",
    color: "violet"
}))`
    width: 100%;
    
    & > a {
        width: 100%;
    }
    
    .rs-btn {
        padding-right: 20px !important;
    }
    
    ul.rs-dropdown-menu {
        background-color: white;
    }
    
    ul.rs-dropdown-menu li.rs-dropdown-item:hover, 
    ul.rs-dropdown-menu li.rs-dropdown-item:focus, 
    ul.rs-dropdown-menu li.rs-dropdown-item:active,
    ul.rs-dropdown-menu li.rs-dropdown-item a.rs-dropdown-item-content:hover, 
    ul.rs-dropdown-menu li.rs-dropdown-item a.rs-dropdown-item-content:focus, 
    ul.rs-dropdown-menu li.rs-dropdown-item a.rs-dropdown-item-content:active {
        background-color: #c5c5c5;
    }
`;


const StyledButtonToolbar = styled(ButtonToolbar)`
    width: 30%;
    display: inline-block; 
    ${props =>
    props.width && css`
            width: ${props.width};
    `};

    @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;


const StyledTable = styled(BaseTable)`
    && {
        box-shadow: none;
    }

    .rs-table-cell.range-name .rs-table-cell-content {
        word-break: break-word;
        overflow: visible;
        font-size: 12px;
        //margin-top: -5px;
        display: flex;
        align-items: center;
    }
    
    .rs-table-cell.number .rs-table-cell-content {
        ${props => !!props.prefixViewNumbers && css`
            word-break: break-word;
            overflow: visible;
            font-size: 12px;
            //margin-top: -5px;
            display: flex;
            align-items: center;
        `
}
`;


const StyledButton = styled(Button).attrs(() => ({
    appearance: "ghost",
    color: "violet"
}))`

    && {
        width: 30%
    };
    
    @media (max-width: 600px) {
        width: 100% !important;
    }
`;


const StyledList = styled(List).attrs(props => ({
    size: 'sm',
    hover: true
}))`
    width: 100%;
`;


const StyledLoader = styled(Loader)`
    & span.rs-loader-spin {
        margin-top: 3px;
        margin-right: 5px;
    }
`;