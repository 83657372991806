import React from 'react';
import Modal from "../../../components/Modal";

export default ({
    show,
    data,
    onClose,
    onSubmit,
    disabled,
}) => {

    return (
        <>
            <Modal
                show={show}
                footer
                data={data}
                disabled = {disabled}
                onSuccess={onSubmit}
                onClose={onClose}
                successText={"Deactivate"}
                title={"Deactivate note"}
            >
                <p>Are you sure about that?</p>
            </Modal>
        </>
    )
};