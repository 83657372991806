import {
    GOOGLE_OTP_QUARANTINED_SET_LOADING,
    GOOGLE_OTP_QUARANTINED_SET_LIST
} from "../const";

import {
    GOOGLE_OTP_QUARANTINED_GET_LIST_API,
    GOOGLE_OTP_QUARANTINED_REMOVE_NUMBERS_API
} from "../const/apiMethods";

import {createAction} from './defaults';
import {api} from "../api/loginRoutes";

const setGoogleOtpQuarantinedList = createAction(GOOGLE_OTP_QUARANTINED_SET_LIST);
const setGoogleOtpQuarantinedListLoading = createAction(GOOGLE_OTP_QUARANTINED_SET_LOADING);

// get list
export const getGoogleOtpQuarantinedList = (filter = {}, page = 1, per_page = 10) => (dispatch) => {
    dispatch( setGoogleOtpQuarantinedListLoading(true) );

    api(GOOGLE_OTP_QUARANTINED_GET_LIST_API, {filter, page, per_page})
        .then((response) => {
            if (response !== undefined) {

                dispatch( setGoogleOtpQuarantinedList({
                    list: response.price_range_quarantine_list || [],
                    count: response.price_range_quarantine_count,
                    page,
                    perPage: per_page,
                }) );
            }
        })
        .finally(() => {
            dispatch( setGoogleOtpQuarantinedListLoading(false) );
        })
};

export const removeGoogleOtpQuarantinedNumbers = ({list, all}, filter = {}) => async (dispatch) => {
    return await api(GOOGLE_OTP_QUARANTINED_REMOVE_NUMBERS_API, {
        filter: filter,
        prq_key_list: list,
        delete_all: all
    });
};