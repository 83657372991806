import React from "react";
import {getFormattedTime} from "../../../utils/helpers";
import {Table} from "rsuite";
import CommonTable from '../CommonTable';
import * as S from "../styled.js";

const {Column, HeaderCell, Cell} = Table;

export default ({
    data = [], 
    loading = false, 
    handleCancelPaymentRequest,
    handleApprovePaymentRequest,
    handleManagePaymentDetails,
    height = null,
    withFilters = false,
}) => {

    const statusLabels = ["Active", "Canceled", "Approved", "Rejected", "Completed"];

    const columns = [
        {
            id: 'amount',
            label: "Amount",
            value: ({amount, currency_name}) => `${amount}${currency_name ? ' ' + currency_name : ''}`,
            width: 150,
            // width: 287,
            align: 'center'
        },
        {
            id: 'date',
            label: "Date",
            value: ({date}) => getFormattedTime(date),
            width: 150,
            // width: 287,
            align: 'center'
        },
        {
            id: 'paymentInfo',
            label: "Payment Info",
            value: ({payment_detail_info, move_to_account_name}) => {
                if (payment_detail_info)
                    return payment_detail_info;

                if (move_to_account_name)
                    return 'Transfer to account ' + move_to_account_name;

                return '-';
            },
            flexGrow: 4,
            width: 150,
            align: 'center'
        },
        {
            id: 'status',
            label: "Status",
            value: ({status}) => statusLabels[status],
            flexGrow: 0,
            width: 150,
            align: 'center'
        },
    ];


    if (withFilters) {
        columns.unshift({
            id: 'account',
            label: "Account",
            value: ({account_name, account_manager_name}) => `${account_name} / ${account_manager_name || 'no manager'}`,
            flexGrow: 2,
            minWidth: 300,
            align: 'center'
        })
    }

    const tableActions = (
        <Column key="table__actions" width={handleManagePaymentDetails ? 340 : 160}>
            <HeaderCell> </HeaderCell>
            <Cell>
                {rowData => {

                    if (rowData.status !== 0)
                        return null;

                    return (
                        <S.StyledTableActions>

                            {handleManagePaymentDetails &&
                                <S.StyledTableButton
                                    onClick={() => handleManagePaymentDetails(rowData)}
                                >
                                    Manage payment details
                                </S.StyledTableButton>
                            }

                            <S.StyledTableButton
                                color="green"
                                onClick={() => handleApprovePaymentRequest(rowData)}
                            >
                                Approve
                            </S.StyledTableButton>

                            <S.StyledTableButton
                                color="red"
                                onClick={() => handleCancelPaymentRequest(rowData)}
                            >
                                Cancel
                            </S.StyledTableButton>
                            
                        </S.StyledTableActions>
                    );
                }}
            </Cell>
        </Column>
    );  

    return (
        <CommonTable
            height={height}
            data={data}
            loading={loading}
            columns={columns}
            tableActions={tableActions}
        />
    )
};