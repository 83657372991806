import React, {useState} from "react";
import PageTabs from "../../components/PageTabs";
import {Panel} from "rsuite";
import PageIvrs from "./PageIvrs";
import PageIvrSounds from "./PageIvrSounds";
import PageIvrSpecialCli from "./PageIvrSpecialCli";
import PageIvrDefault from "./PageIvrDefault";

export default ({}) => {

    const [activeTab, setActiveTab] = useState("ivrs");

    const menuItems = [
        {id: "ivrs", eventKey: "ivrs", title: "IVRs"},
        {id: "sounds", eventKey: "sounds", title: "Sounds"},
        {id: "special-cli-ivrs", eventKey: "special-cli-ivrs", title: "Special CLI IVRs"},
        {id: "default-ivr", eventKey: "default-ivr", title: "Default IVR"},
    ];

    return (
        <Panel style={{marginTop: 10, marginBottom: 20}} classPrefix="white-panel">
            <PageTabs
                onSelect={setActiveTab}
                activeTab={activeTab}
                {...{
                    menuItems
                }}
            />

            {(() => {
                switch (activeTab) {
                    case 'ivrs':
                        return (
                            <PageIvrs />
                        );
                    case 'sounds':
                        return (
                            <PageIvrSounds />
                        );
                    case 'special-cli-ivrs':
                        return (
                            <PageIvrSpecialCli />
                        );

                    case 'default-ivr':
                        return (
                            <PageIvrDefault />
                        );

                    default:
                        return null;
                }
            })()}
        </Panel>
    )
};