import React from 'react';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'rsuite';
import s from './CustomField.module.css';

export default class extends React.PureComponent {
    render() {
        const {name, message, label, accepter, error, inline = false, ...props} = this.props;
        let formGroupClasses = [s.formGroup];

        if (error)
            formGroupClasses.push('has-error');

        if (inline)
            formGroupClasses.push(s.inline);

        return (
            <FormGroup className={formGroupClasses.join(" ")}>
                {
                    ( () => label ? <ControlLabel className={s.label}>{label}</ControlLabel> : <></> )()
                }
                <div className={s.field}>
                    <FormControl
                        className={s.input}
                        name={name}
                        accepter={accepter}
                        errorMessage={error}
                        {...props}
                    />
                </div>
                {(() => message ? <HelpBlock>{message}</HelpBlock> : <></>)()}
            </FormGroup>
        )
    }
}