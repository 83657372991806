import React from "react";
import {SelectPicker, Uploader, Icon, FormControl} from "rsuite";
import * as S from "./styled";


export const FieldInput = ({name, label, type, ...props}) => (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <S.Field name={name} {...props}/>
    </S.Group>
);


export const FieldTextArea = ({name, label, type, data, ...props}) => (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <div className="rs-form-control-wrapper">
            <S.FieldTextAreaAutoResize
                className="rs-input"
                name={name}
                {...props}
            />
        </div>
    </S.Group>
);


export const FieldSelectCountry = ({name, label, type, data, ...props}) => (
    <S.Group>
        {label &&
            <S.LabelWrapper>
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }
        <S.Field
            name={name}
            accepter={SelectPicker}
            cleanable={false}
            data={data}
            valueKey="cntr_code"
            labelKey="name"
            {...props}
        />
    </S.Group>
);


export const FieldFile = ({name, label, type, defaultFileList, ...props}) => (
    <S.Group>
        {label &&    
            <S.LabelWrapper 
                vAlign="flex-start" 
                top={18}
            >
                <S.Label>{label}</S.Label>
            </S.LabelWrapper>
        }

        <S.FileUploader 
            name={name}
            defaultFileList={defaultFileList || []}
            accepter={Uploader}
            {...props}
        >
            <button>
                <Icon icon='file-image-o' size="2x" />
            </button>
        </S.FileUploader>
    </S.Group>
);


export const FieldOrganizationType = ({label, data = [], ...props}) => (
    <S.Group>
        {label &&
            <S.Label>{label}</S.Label>
        }
        <FormControl
            name="ot_key"
            accepter={SelectPicker}
            data={data}
            labelKey="name"
            valueKey="ot_key"
            cleanable={false}
            searchable={false}
            {...props}
        />
    </S.Group>
);


export const FieldPaymentMethod = ({label, data = [], ...props}) => (
    <S.Group>
        {label &&
            <S.Label>{label}</S.Label>
        }
        <FormControl
            name="pm_key"
            accepter={SelectPicker}
            data={data}
            labelKey="name"
            valueKey="pm_key"
            cleanable={false}
            searchable={false}
            {...props}
        />
    </S.Group>
);


export default FieldInput