import React, {useState, useEffect} from "react";
import { useWindowWidth } from "../../../hooks";
import { Form, InputNumber, SelectPicker, Button, Input, Schema, Message } from "rsuite";
import {Spacer} from "../../../components/base/Spacer";
import * as S from "../styled";
import {SCREEN_MEDIA} from '../../../const';

const {sm} = SCREEN_MEDIA;

const {StringType, NumberType} = Schema.Types;

const AMOUNT_MIN = 0.01;

export default ({
    show,
    onClose,
    maxWidth = 700,
    paymentDetailsItems,
    currencyName,
    requestPayment,
    getPaymentRequestList,
    handleShowModalAddPaymentDetail,
    accountId,
    dropdownVisibility,
    amountLimit,
    amountLimitMin = 0.01,
    accounts = [],
    filter,

    formValue,
    setFormValue,
}) => {

    let formRef = null;
    
    const formModel = Schema.Model({
        amount: NumberType()
            .isRequired("This field is required")
            .min(AMOUNT_MIN, `The minimum number is ${AMOUNT_MIN}`)
            .max(amountLimit, `The maximum number is ${amountLimit}`),
        payment_detail_id: StringType()
            .addRule((value, data) => {
                if (!value && !data.move_to_account_id)
                    return false;

                return true;
            }, "This field is required", true)
            .addRule((value, data) => {
                if (value && data.move_to_account_id)
                    return false;
                
                    return true;
            }, 'You cannot choose payment details with "Transfer to another account" option'),
    });

    const [loading, setLoading] = useState(false);
    const resizedWidth = useWindowWidth();
    const [formIsChanged, setFormIsChanged] = useState(false);


    // clean Amount error on limit change
    useEffect( () => {
        if (formRef)
            formRef.cleanErrorForFiled('amount');
    }, [amountLimitMin] );


    const handleSubmit = async () => {

        if (!formIsChanged)
            setFormIsChanged(true);
        
        if (!formRef.check() )
            return null;

        setLoading(true);

        const value = formRef.getFormValue();
        const data = {
            ...value,
            amount: +value.amount,
            target: {
                account_id: accountId,
                payment_detail_id: value.payment_detail_id,
            },
        };
        delete data.payment_detail_id;

        if (data.move_to_account_id) {
            data.target.account1_id = data.move_to_account_id;
            delete data.move_to_account_id;
        }

        await requestPayment(data);

        if (getPaymentRequestList) {
            const dataGetList = {target: {account_id_list: [accountId]}};
            if (filter) {
                dataGetList.filter = filter;
            }
            getPaymentRequestList(dataGetList);
        }

        setLoading(false);
        onClose();
    };

    const handleAmountChange = () => {
        if (!formIsChanged)
            setFormIsChanged(true);
    }

    const renderMenuItem = (label, item) => {
        return item.status === 1 ? label : <span style={{color: '#939191'}}>{label}</span>;
    };

    return (
        <S.FormModal
            {...{show, onClose}}
            title="Payment request"
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showCloseSuccessButtons={true}
            onSuccessClose={false}
            successButton="Confirm"
            loading={loading}
            focusTarget="close"
            footer={true}
            onSuccess={handleSubmit}
            onClose={() => {
                onClose(false);
            }}
            onExited={() => {
                setFormValue({amount: 0});
            }}
        >
            <Form
                ref={ref => formRef = ref}
                model={formModel}
                formValue={formValue}
                onChange={setFormValue}
            >

                <p>{`${amountLimit || 0} ${currencyName || ''} is available for payment now. You cannot request more than this amount`}</p>

                <Spacer/>

                <S.FormRow>
                    <S.FormItem>

                        <S.FormGroup>
                            <S.Label>Amount</S.Label>
                            <S.FieldAmountWrapper>
                                <S.Field
                                    accepter={InputNumber}
                                    name='amount'
                                    onChange={handleAmountChange}
                                />
                            </S.FieldAmountWrapper>
                            {currencyName && <S.Currency>{currencyName}</S.Currency>}
                        </S.FormGroup>

                    </S.FormItem>
                    <S.FormItem grow>

                        <S.FormGroup>
                            <S.Label>Payment info</S.Label>
                            <S.Field
                                accepter={SelectPicker}
                                data={paymentDetailsItems}
                                cleanable={true}
                                name='payment_detail_id'
                                valueKey="id"
                                block={true}
                                errorPlacement={resizedWidth <= sm ? "auto" : "bottomEnd"}
                                labelKey="payment_detail_info"
                                menuClassName={dropdownVisibility ? "" : "hide-dropdown"}
                                renderMenuItem={renderMenuItem}
                                renderExtraFooter={() => {
                                    return (
                                        <Button 
                                            appearance="link"
                                            onClick={() => {
                                                handleShowModalAddPaymentDetail();
                                            }}
                                        >
                                            + Add new
                                        </Button>
                                    )
                                }}
                            />
                        </S.FormGroup>
                    </S.FormItem>
                
                </S.FormRow>

                <Spacer/>

                <S.FormRow>
                    <S.FormItem grow>
                        <S.FormGroup>
                            <S.Label>or transfer to another account</S.Label>
                            <S.Field
                                accepter={SelectPicker}
                                data={accounts.filter((item) => item.is_managed)}
                                cleanable={true}
                                name='move_to_account_id'
                                valueKey="id"
                                labelKey="name"
                                block={true}
                                errorPlacement={resizedWidth <= sm ? "auto" : "bottomStart"}
                                menuClassName={dropdownVisibility ? "" : "hide-dropdown"}
                            />
                        </S.FormGroup>
                    </S.FormItem>
                </S.FormRow>

                <Spacer/>

                <S.Field
                    name="comment"
                    accepter={Input}
                    componentClass="textarea"
                    placeholder="Comment"
                />

                {amountLimitMin > formValue.amount && formValue.payment_detail_id && formIsChanged &&
                    <>
                        <Spacer/>
                        <Message 
                            type="warning" 
                            closable 
                            description={<p><b>Amount</b> should be greater than <b>{amountLimitMin}</b></p>}
                        />
                    </>
                }
                
            </Form>
        </S.FormModal>
    )
};