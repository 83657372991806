import React from 'react';
import styled from 'styled-components';
import {useWindowWidth} from "../../hooks";
import {Header as SectionHeader} from "./Trunks/styled";
import PageTabs from "../../components/PageTabs";
import {checkPermissionsFor} from 'store/storeHelpers';
import {PAYMENT_REQUEST_LIST_API} from "../../const/apiMethods";
import {FlexGrid, FlexGridItem} from "../../components/base/FlexGrid";


export default ({
    activeTab,
    onSelect,
    account = {},
}) => {

    const {
        name = '',
        account_manager_name = '',
        currency = '',
        payment_terms = ''
    } = account;

    const windowWidth = useWindowWidth();

    const menuItems = [
        ...[account.id
            ? {id: "general", eventKey: "general", title: "General"}
            : null
        ],
        {id: "trunks", eventKey: "trunks", title: "Trunks"},
        {id: "rates", eventKey: "rates", title: "Rates"},
        {id: "access", eventKey: "access", title: "Access List Filters"},
        {id: "api", eventKey: "api", title: "API Keys"},
        {id: "self-allocation", eventKey: "self-allocation", title: "Self Allocation Restrictions"},
        ...[checkPermissionsFor(PAYMENT_REQUEST_LIST_API)
            ? {id: "payment-management", eventKey: "payment-management", title: "Payment management"}
            : null
        ],
        {id: "notes", eventKey: "notes", title: "Notes"},
    ].filter(item => !!item);

    const dropdownTitle = (menuItems.find(item => item.eventKey === activeTab) || {}).title;
    const isMobile = windowWidth < 1200;

    return (
            <>
                <Header>
                    <div><Name>Account:</Name><Value>{name}</Value></div>
                    <div><Name>Manager:</Name><Value>{account_manager_name}</Value></div>
                    <div><Name>Currency:</Name><Value>{currency}</Value></div>
                    <div><Name>Payment terms:</Name><Value>{payment_terms}</Value></div>
                </Header>

                {!isMobile

                    ? <PageTabs
                        {...{activeTab, onSelect, menuItems}}
                    />

                    : <>
                        <FlexGrid
                            noWrap
                            align={"middle"}
                            justify={"space-between"}
                        >
                            <FlexGridItem>
                                <SectionHeader>{dropdownTitle}</SectionHeader>
                            </FlexGridItem>
                            <FlexGridItem>
                                <PageTabs
                                    isMobile={true}
                                    {...{activeTab, onSelect, menuItems}}
                                />
                            </FlexGridItem>
                        </FlexGrid>
                    </>
                }
            </>
        )
}


const Name = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #8256C8;
    margin-right: 3px;
`;

const Value = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #363434;
    margin-right: 15px;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    
    & > div {
        padding-bottom: 10px;
    }
`;