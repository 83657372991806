import React from 'react';
import {FlexboxGrid, Form, Input, Radio, RadioGroup, Schema, SelectPicker} from 'rsuite';
import Contacts, {contactModel, initContact} from './Contacts';
import Users, {initUser, userModel} from './Customers';
import CustomField from './CustomField';
import {ID, compareObjects} from '../../utils/helpers';
import {formValid} from '../../utils/validate';
import {AccountCreate} from './SaveModel';
import styled from 'styled-components';
import CloseAccount from './CloseAccount';
import OpenAccount from '../../pages/NewAccount/OpenAccount';
import './CreateForm.css';
import { useWindowWidth } from '../../hooks';
import { checkPermissionsFor } from '../../store/storeHelpers';
import {ButtonContent, ButtonListAdd, ButtonPrimary} from "../../components/base/BaseButton";

const {StringType, NumberType} = Schema.Types;

const accountModel = Schema.Model({
    name: StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    account_manager_id: StringType().isRequired('Required'),
    cur_key: NumberType().isRequired('Required'),
    pt_key: NumberType().isRequired('Required'),
    call_duration_max: NumberType().min(0, 'Enter a positive number').max(10800, '3 hours is maximum').isRequired('Required')
});


const styleRow = {margin: '10px 0px'};

export default ({
    disabled, disabledCurrency = false, onSubmit: _onSubmit, currency_list, payment_terms_list,
    account_manager_list, formDefaultValue, account_id, onCancel, active, getAccountManagersList,
    client_role_list, admin_role_list, isTestAccount, isSeparatedPage, registration_key, ...props
}) => {

    const {users: formDefaultUsers, contacts: formDefaultContacts} = formDefaultValue || {};

    const formDefaultValueInit = JSON.parse(JSON.stringify(formDefaultValue));
    const formDefaultContactsInit = formDefaultContacts && formDefaultContacts.slice(0);
    const formDefaultUsersInit = formDefaultUsers && formDefaultUsers.slice(0);

    const [form, setForm] = React.useState(null);
    const [formState, setFormState] = React.useState(formDefaultValue || {});
    const [contacts, updateContacts] = React.useState(formDefaultContacts && formDefaultContacts.length ? formDefaultContacts : [{
        ...initContact,
        key: ID()
    }]);
    const [activeState, setActiveState] = React.useState(active);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 1200;

    React.useEffect(() => {
        getAccountManagersList();
    }, []);

    React.useEffect(() => {
        if (!disabled && !contacts.length)
            updateContacts(formDefaultContacts && formDefaultContacts.length ? formDefaultContacts : [{
                ...initContact,
                key: ID()
            }]);
    }, [formDefaultContacts, disabled]);

    const [users, updateUsers] = React.useState(formDefaultUsers || []);

    // Иногда пропадают юзеры, точнее в state users не попадает ничего и он undefined. Разботает раз через 5 раз. Какой-то странный рандом. Данное решение фиксит этот баг
    React.useEffect(() => {
        if (!disabled && !users.length)
            updateUsers(formDefaultUsers || []);
    }, [formDefaultUsers, disabled]);

    const formsContactRef = {};
    const updateContactFormRef = (ref, key) => formsContactRef[key] = ref;

    const addContacts = () => {
        const unValidContacts = contacts.filter(contact => !formValid(contactModel.check(contact)));

        if (unValidContacts.length)
            unValidContacts.map(
                contact => formsContactRef[contact.key].check()
            );
        else
            updateContacts([...contacts, {...initContact, key: ID()}]);
    };

    const onSubmit = () => {
        const validContact =
            !Object.keys(formsContactRef)
                .map(key => formsContactRef[key].check())
                .filter(check => !check).length;

        const validUsers =
            !Object.keys(formsUsersRef)
                .map(key => formsUsersRef[key].check())
                .filter(check => !check).length;

        if (validContact && validUsers && form.check()) {
            _onSubmit(new AccountCreate(form.getFormValue()),
                form.getFormValue().account_manager_id,
                users,
                contacts,
                registration_key
            );
        }
    };

    const formsUsersRef = {};
    const updateUsersFormRef = (ref, key) => formsUsersRef[key] = ref;

    const onAddUser = () => {
        const unValidUsers = users.filter(user => !formValid(userModel.check(user)) && !user.email_disabled);

        if (unValidUsers.length)
            unValidUsers.map(
                user => formsUsersRef[user.key].check()
            );
        else
            updateUsers([{...initUser, key: ID()}, ...users]);
    };
    
    const fieldIsChanged = (key) => {
        return formState && formDefaultValueInit[key] !== formState[key]
    };
    
    const radioIsPicked = (key, radioKey) => {
        return fieldIsChanged(key) && formState[key] === radioKey;
    };

    const addableIsChanged = (id, addableObjectInitial, addableObject) => {
        const addedNew = addableObjectInitial && !addableObjectInitial.map((addable) => addable.id).includes(id);
        const changed = addableObjectInitial && !compareObjects(addableObjectInitial.find(obj => obj.id === id), addableObject);

        return addedNew || changed;
    };

    const accountManagerList = account_manager_list.filter(value => !!value.is_managed);
    return (
        <Form
            model={accountModel}
            style={{marginBottom: '20px'}}
            layout="horizontal"
            ref={ref => setForm(ref)}
            onChange={setFormState}
            formDefaultValue={formDefaultValue}
        >
            <FlexboxGrid style={styleRow}>

                <FlexboxGrid.Item colspan={isMobile ? 24 : 6}>

                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="name"
                        placeholder="Account name"
                        label="Account name"
                        className={`tab-general-custom-field ${fieldIsChanged('name') && 'has-changed'}`}
                    />

                    <CustomField
                        disabled={disabled}
                        accepter={SelectPicker}
                        size="sm"
                        placeholder="Account manager"
                        data={accountManagerList}
                        labelKey="name"
                        valueKey="id"
                        name="account_manager_id"
                        label="Account manager"
                        searchable={false}
                        cleanable={false}
                        className={`tab-general-select-field ${fieldIsChanged('account_manager_id') && 'has-changed'}`}
                    />

                    <CustomField
                        disabled={disabled || disabledCurrency}
                        accepter={SelectPicker}
                        size="sm"
                        name="cur_key"
                        placeholder="Currency"
                        data={currency_list}
                        labelKey="name"
                        valueKey="cur_key"
                        label="Currency"
                        searchable={false}
                        cleanable={false}
                        className={`tab-general-select-field ${fieldIsChanged('cur_key') && 'has-changed'}`}
                    />

                    <CustomField
                        disabled={disabled}
                        accepter={SelectPicker}
                        size="sm"
                        name="pt_key"
                        placeholder="Payment terms"
                        data={payment_terms_list}
                        labelKey="name"
                        valueKey="pt_key"
                        label="Payment terms"
                        searchable={false}
                        cleanable={false}
                        className={`tab-general-select-field ${fieldIsChanged('pt_key') && 'has-changed'}`}
                    />
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="call_duration_max"
                        placeholder="3600"
                        label="Call duration"
                        className={`tab-general-custom-field ${fieldIsChanged('call_duration_max') && 'has-changed'}`}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item 
                    colspan={isMobile ? 24 : 10}
                    style={{marginTop: 33}}
                >
                    <CustomField
                        disabled={disabled}
                        accepter={RadioGroup}
                        name="trunk_notificaton"
                    >
                        <Radio
                            style={{width: isMobile ? 'auto' : 450}}
                            className={`tab-general-radio-field ${radioIsPicked('trunk_notificaton', 'all_numbers_trunk') && 'has-changed'}`}
                            value={'all_numbers_trunk'}
                        >Send full list of allocated numbers for trunk after update</Radio>
                        <Radio
                            style={{width: isMobile ? 'auto' : 450}}
                            className={`tab-general-radio-field ${radioIsPicked('trunk_notificaton', 'all_numbers_all_trunks') && 'has-changed'}`}
                            value={'all_numbers_all_trunks'}
                        >Send full list of allocated numbers for all trunk after update</Radio>
                        <Radio
                            style={{width: isMobile ? 'auto' : 450}}
                            className={`tab-general-radio-field ${radioIsPicked('trunk_notificaton', 'number_trunk') && 'has-changed'}`}
                            value={'number_trunk'}
                        >Send only new allocated numbers</Radio>
                        <Radio
                            style={{width: isMobile ? 'auto' : 450}}
                            className={`tab-general-radio-field ${radioIsPicked('trunk_notificaton', 'nothing') && 'has-changed'}`}
                            value={'nothing'}
                        >Don't send notifications</Radio>

                    </CustomField>

                </FlexboxGrid.Item>
            </FlexboxGrid>

            {isSeparatedPage && !checkPermissionsFor("account_contact:create") 
                ? null
                : <>
                    <FlexboxGrid style={styleRow}>
                        <HeaderList>Contacts <ButtonListAdd onClick={addContacts}/> </HeaderList>
                    </FlexboxGrid>

                    <FlexboxGrid style={styleRow}>
                        <Contacts  {...{contacts, updateContacts, disabled, addableIsChanged}} formsRef={formsContactRef}
                                updateFormRef={updateContactFormRef} initialList={formDefaultContactsInit}/>
                    </FlexboxGrid>
                </>
            }


            {isSeparatedPage && !checkPermissionsFor("account_user:create") 
                ? null
                : <>
                    <FlexboxGrid style={styleRow}>
                        <HeaderList>Customers <ButtonListAdd onClick={onAddUser}/> </HeaderList>
                    </FlexboxGrid>

                    <FlexboxGrid style={styleRow}>
                        <Users
                            {...{
                                users,
                                updateUsers,
                                disabled,
                                addableIsChanged
                            }}
                            clientRoleList={client_role_list || []}
                            adminRoleList={admin_role_list || []}
                            isTestAccount={isTestAccount}
                            formsRef={formsUsersRef}
                            updateFormRef={updateUsersFormRef}
                            initialList={formDefaultUsersInit}
                        />
                    </FlexboxGrid>
                </>
            }

            <ButtonFooter>

                <ButtonPrimary
                    disabled={disabled}
                    onClick={onSubmit}
                >
                    Save
                </ButtonPrimary>

                <ButtonContent
                    onClick={() => onCancel()}
                >
                    Cancel
                </ButtonContent>

                {account_id &&
                    <>
                        {activeState
                            ? <CloseAccount
                                onUpdate={() => setActiveState(false)}
                                target={{account_id}}
                            />
                            : <OpenAccount
                                onUpdate={() => setActiveState(true)}
                                target={{account_id}}
                            />
                        }
                    </>
                }

            </ButtonFooter>
        </Form>
    );
};


const HeaderList = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #8256C8;
`;


const ButtonFooter = styled.div`
    text-align: center;
    margin-top: 30px;
    > .rs-btn {
        margin-right: 50px
    }
`;