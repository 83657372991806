import {
    PRICE_RANGE_QUARANTINE_SETTING_LOADING,
    PRICE_RANGE_QUARANTINE_SETTING_LIST,
} from '../const';

const initialState = {
    list: [],
    loading: true,
};

const handlers = {
    [PRICE_RANGE_QUARANTINE_SETTING_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [PRICE_RANGE_QUARANTINE_SETTING_LIST]: (state, {payload: {list}}) => ({
        ...state,
        list: list,
        loading: false,
    }),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}