import React from 'react';
import CDRTable from './CDRTable';
import CDRFilters from './CDRFilters';
import {objectIsEmpty, removeTZFromDate} from '../../../utils/helpers';
import {ButtonPrimary} from '../../../components/base/BaseButton';
import {
    LOCAL_STORAGE_CDR_COLUMNS_DIALER,
    LOCAL_STORAGE_CDR_FILTERS_DIALER,
    LOCAL_STORAGE_CDR_PER_PAGE_DIALER,
    MAX_PER_PAGE_DIALER,
    MIN_PER_PAGE_DIALER
} from '../../../const';
import {injectIntl} from "react-intl";
import { FlexGrid, FlexGridItem } from '../../../components/base/FlexGrid';
import {Spacer} from "../../../components/base/Spacer"
import {Drawer, Icon, IconButton} from "rsuite";
import styled from "styled-components";
import m from "../../../definedMessages";
import ButtonDownload from "../../../components/client/ButtonDownload";


class CDR extends React.Component {
    constructor(props) {
        super(props);

        this.filter = {};
        this.defaultFilter = {
            start_date: removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999)))
        };
        this.formatMessage = this.props.intl.formatMessage.bind(this.props.intl);
        this.curPage = 1;
        this.curPerPage = null;
        this.columns = [
            {
                id: 'datetime',
                label: this.formatMessage(m.datetime),
                dataKey: 'datetime',
                align: 'center',
                flexGrow: 4,
                minWidth: 200
            },
            {
                id: 'a_number',
                label: this.formatMessage(m.aNumber),
                dataKey: 'a_number',
                flexGrow: 3,
                minWidth: 150,
                align: 'center'
            },
            {
                id: 'b_number',
                label: this.formatMessage(m.bNumber),
                dataKey: 'b_number',
                flexGrow: 3,
                minWidth: 150,
                align: 'center'
            },
            {
                id: 'range',
                label: this.formatMessage(m.range),
                dataKey: 'range',
                flexGrow: 5,
                minWidth: 250,
                align: 'center'
            },
            {
                id: 'rate_dialer',
                label: this.formatMessage(m.rate),
                dataKey: 'rate_dialer',
                flexGrow: 2,
                minWidth: 100,
                align: 'right'
            },
            {
                id: 'cost_dialer',
                label: this.formatMessage(m.cost),
                dataKey: 'cost_dialer',
                flexGrow: 2,
                minWidth: 100,
                align: 'right'
            },
            {
                id: 'term',
                label: this.formatMessage(m.termPoint),
                value: (item) => {
                    if (item.id === "summaries")
                        return "";

                    const {ip, port, tprefix_dialer} = item;
                    return `${ip}:${port} ${tprefix_dialer}`;
                },
                flexGrow: 4,
                minWidth: 200,
                align: 'center'
            },
            {
                id: 'duration',
                label: this.formatMessage(m.duration),
                value: (item) => {
                    return item.id === "summaries"
                        ? <span title={item.duration}>{item.duration}</span>
                        : item.duration
                },
                // dataKey: 'duration',
                flexGrow: 2,
                minWidth: 100,
                align: 'center'
            },
            {
                id: 'sip',
                label: this.formatMessage(m.sipCause),
                dataKey: 'sip_cause',
                flexGrow: 2,
                minWidth: 100,
                align: 'right'
            },
        ];

        this.state = {
            searchLoading: false,
            savedTableFilter: null,
            savedPerPage: JSON.parse(localStorage.getItem(LOCAL_STORAGE_CDR_PER_PAGE_DIALER)),
            savedTableColumns: JSON.parse(localStorage.getItem(LOCAL_STORAGE_CDR_COLUMNS_DIALER)),
            resizedWidth: window.innerWidth,
            activeFiltersCount: 0,
            showFilters: false,
        };
    }


    handleResize = () => {
        this.setState({resizedWidth: window.innerWidth});
    };

    componentDidMount() {
        const {savedPerPage, savedTableFilter} = this.state;
        const {getCDRList, per_page} = this.props;

        this.setActiveFiltersCount(this.state.savedTableFilter);

        this.curPerPage = savedPerPage;
        let pickedFilter;

        if (!objectIsEmpty(savedTableFilter)) {
            pickedFilter = savedTableFilter;
        } else if (!objectIsEmpty(this.filter)) {
            pickedFilter = this.filter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        getCDRList(savedPerPage || per_page, pickedFilter, 1);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    onChangeFilters = (filtersObj) => {
        const {savedPerPage} = this.state;
        const {getCDRList, per_page} = this.props;

        this.setActiveFiltersCount(filtersObj);

        this.filter = filtersObj;

        this.setState({
            savedTableFilter: filtersObj
        });

        getCDRList(savedPerPage || this.curPerPage || per_page, this.filter, 1);
    };

    setActiveFiltersCount = (value = null) => {
        if (!value)
            return;

        const filters = Object.keys(value);
        const dateFilters = ['start_end_date', 'start_date', 'end_date'];
        const activeFilters = filters.filter(item => {
            if ( dateFilters.includes(item) )
                return false;

            if ( value[item] === this.defaultFilter[item] || (!value[item] && value[item] !== 0) ) {
                return false;
            }
            return true;
        });

        this.setState({
            activeFiltersCount: activeFilters.length + 1
        });
    };

    combineFilters = (filter, savedFilter) => {
        return {
            start_date: filter && filter.start_date ? filter.start_date :
                savedFilter && savedFilter.start_date ? savedFilter.start_date :
                    removeTZFromDate(new Date(new Date().setHours(0, 0, 0, 0))),
            end_date: filter && filter.end_date ? filter.end_date :
                savedFilter && savedFilter.end_date ? savedFilter.end_date :
                    removeTZFromDate(new Date(new Date().setHours(23, 59, 59, 999))),
            a_number: filter && (filter.a_number || filter.a_number === '') ? filter.a_number :
                savedFilter && savedFilter.a_number ? savedFilter.a_number : '',
            b_number: filter && (filter.b_number || filter.b_number === '') ? filter.b_number :
                savedFilter && savedFilter.b_number ? savedFilter.b_number : ''
        };
    };

    onChangePerPage = (perPage) => {
        const {savedTableFilter} = this.state;
        const {getCDRList} = this.props;

        if (perPage)
            this.setState({savedPerPage: null});
        else
            this.setState({savedPerPage: this.curPerPage});

        let pickedFilter;
        const per_page = Number(perPage) || this.curPerPage;
        const combinedFilter = this.combineFilters(this.filter, savedTableFilter);

        if (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        per_page <= 0 ? this.curPerPage = MIN_PER_PAGE_DIALER : per_page > MAX_PER_PAGE_DIALER ? this.curPerPage = MAX_PER_PAGE_DIALER : this.curPerPage = per_page;

        localStorage.setItem(LOCAL_STORAGE_CDR_PER_PAGE_DIALER, JSON.stringify(this.curPerPage));

        getCDRList(this.curPerPage, pickedFilter, 1);
    };

    onChangePage = (pageNumber) => {
        const {savedTableFilter, savedPerPage} = this.state;
        const {getCDRList, per_page} = this.props;

        let pickedFilter;
        const combinedFilter = this.combineFilters(this.filter, savedTableFilter);

        if (!objectIsEmpty(this.filter) || !objectIsEmpty(savedTableFilter)) {
            pickedFilter = combinedFilter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        getCDRList(savedPerPage || this.curPerPage || per_page, pickedFilter, pageNumber);
    };

    exportCDR = () => {
        const {savedTableFilter} = this.state;

        let pickedFilter;

        if (!objectIsEmpty(savedTableFilter)) {
            pickedFilter = savedTableFilter;
        } else if (!objectIsEmpty(this.filter)) {
            pickedFilter = this.filter;
        } else {
            pickedFilter = this.defaultFilter;
        }

        this.props.exportCDR(pickedFilter, this.curPage, this.curPerPage);
    };

    onSaveColumnsTable = (idListToSave) => {
        localStorage.setItem(LOCAL_STORAGE_CDR_COLUMNS_DIALER, JSON.stringify(idListToSave));
    };


    render() {
        const {searchLoading, savedPerPage, savedTableFilter, savedTableColumns} = this.state;
        const {loading, uploadLoading, cdr_list, page, count, per_page, summaries, globalSettings} = this.props;
        const exportCDR = this.exportCDR;
        const onChangePage = this.onChangePage;
        const onChangePerPage = this.onChangePerPage;
        const onSaveColumnsTable = this.onSaveColumnsTable;

        // remake with reselector
        let dataWithSummaries = [];
        let summariesData = {};

        if (Object.keys(summaries).length && cdr_list.length) {
            summariesData = {...cdr_list[0]};

            Object.keys(summariesData).forEach(item => {

                switch (item) {
                    case "cost_dialer":
                        summariesData[item] = summaries["cost"] !== undefined ? summaries["cost"] : null;
                        break;

                    case "id":
                        summariesData[item] = "summaries";
                        break;

                    default:
                        summariesData[item] = summaries[item] || null;
                }

            });

            dataWithSummaries = [...cdr_list];
            dataWithSummaries.push(summariesData);
        }

        return (
            <>

                {this.state.resizedWidth < 768 &&

                    <>
                        <Toggler
                            icon={<Icon icon="filter"/>}
                            onClick={() => this.setState({showFilters: true})}
                        >
                            {this.state.activeFiltersCount}
                        </Toggler>
                        <Drawer
                            size="xs"
                            placement="bottom"
                            style={{height: '240px'}}
                            show={this.state.showFilters}
                            onHide={() => this.setState({showFilters: false})}
                        >
                            <Drawer.Header>
                            </Drawer.Header>
                            <Drawer.Body>
                                <CDRFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    {...{
                                        savedTableFilter,
                                        globalSettings
                                    }}
                                />
                            </Drawer.Body>
                        </Drawer>
                    </>

                }

                <Spacer size={30}/>

                {this.state.resizedWidth >= 768 &&
                    <div>
                        <FlexGrid justify="space-between">

                            <FlexGridItem>
                                <CDRFilters
                                    onChangeFilters={this.onChangeFilters}
                                    loading={loading || searchLoading}
                                    {...{
                                        savedTableFilter,
                                        globalSettings
                                    }}
                                />
                            </FlexGridItem>
                            <FlexGridItem>
                                <ButtonDownload
                                    onDownload={exportCDR}
                                    loading={uploadLoading}
                                    limitWidth={1179}
                                    buttonText={this.formatMessage(m.exportReport)}
                                />
                            </FlexGridItem>
                        </FlexGrid>
                        <Spacer size={19}/>
                    </div>
                }

                <Spacer size={1}/>

                <CDRTable
                    data={Object.keys(summariesData).length ? dataWithSummaries : cdr_list}
                    extraRows={Object.keys(summariesData).length ? 1 : 0}
                    pickedColumns={this.columns}
                    loading={loading || searchLoading}
                    {...{
                        onChangePage,
                        onChangePerPage,
                        page,
                        per_page,
                        count,
                        savedPerPage,
                        savedTableColumns,
                        onSaveColumnsTable
                    }}
                />

                {this.state.resizedWidth < 768 &&
                    <StyledActionButtonWrapper>
                        <ButtonDownload
                            onDownload={exportCDR}
                            loading={uploadLoading}
                            limitWidth={1179}
                            buttonText={this.formatMessage(m.exportReport)}
                        />
                    </StyledActionButtonWrapper>
                }
            </>
        );
    }
}

export default injectIntl(CDR)

const Toggler = styled(IconButton)`
    && {
        color: #363434;
        float: right;
        margin-top: 6px;
    }
`;

const StyledActionButtonWrapper = styled.div`
    padding-top: 15px;
    text-align: center;
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;