import React, {useEffect, useState} from "react";
import {injectIntl, defineMessages} from "react-intl";
import PaymentsSectionHeader from "../PaymentsSectionHeader";
import PaymentsListTable from "./PaymentsListTable";
import {Spacer} from "../../../components/base/Spacer";
import {getISOTime} from "../../../utils/helpers";
import {Collapse} from "react-collapse";
import CommonDateFilter from "../CommonDateFilter";
import { PAYMENT_LIST_DIALER_API } from "const/apiMethods";
import { checkPermissionsFor } from "store/storeHelpers";

const m = defineMessages({
    paymentList: {id: "payments.paymentList", defaultMessage: "Payment list", description: 'Payments: Payment list'},
});

export default injectIntl( ({
    paymentItems, 
    paymentItemsLoading,
    currencyName,
    getPaymentList,
    accountId,
    permissions,
    
    intl
}) => {

    const {formatMessage} = intl;

    const dateStart = new Date();
    dateStart.setDate(1);
    dateStart.setHours(0, 0, 0, 0);
    dateStart.setMonth(dateStart.getMonth() - 1);
    const dateEnd = new Date(new Date().setHours(23, 59, 59, 999));

    const [showTable, setShowTable] = useState(true);

    useEffect( () => {
        if ( !checkPermissionsFor(PAYMENT_LIST_DIALER_API) ) {
            return;
        }
        const data = {target: {account_id: accountId}};
        data.filter = {
            start_date: getISOTime(dateStart),
            end_date: getISOTime(dateEnd)
        }
        getPaymentList(data);
    }, [permissions] );

    const handleChangeFilters = (value) => {
        if (!value)
            return;

        const start_date = new Date(value.start_end_date[0].setHours(0, 0, 0, 0));
        const end_date = new Date(value.start_end_date[1].setHours(23, 59, 59, 999));

        const filterData = {
            start_date: getISOTime(start_date),
            end_date: getISOTime(end_date),
            start_end_date: value.start_end_date
        }

        getPaymentList({
            target: {
                account_id: accountId
            },
            filter: filterData
        });
    };

    return (
        <>
            <PaymentsSectionHeader
                title={formatMessage(m.paymentList)}
                show={showTable}
                callback={() => {
                    setShowTable(!showTable);
                }}
            />

            <Collapse isOpened={showTable}>
                <div>
                    <Spacer/>

                    <CommonDateFilter
                        filters={{start_end_date: [dateStart, dateEnd]}}
                        onChangeFilters={handleChangeFilters}
                    />
                    
                    <Spacer/>

                    <PaymentsListTable
                        data={paymentItems}
                        loading={paymentItemsLoading}
                        {...{currencyName}}
                    />
                </div>
            </Collapse>
        </>
    )
} );