import React from 'react';
import FormAddFilter from './FormAddFilter/FormAddFilter';
import AccessListFiltersSettings from './AccessListFiltersSettings/AccessListFiltersSettings';
import ModalRemoveFilter from "./ModalRemoveFilter/ModalRemoveFilter";
import AccessListFiltersTable from './AccessListFiltersTable/AccessListFiltersTable';
import AccessListFiltersFilter from "./AccessListFiltersFilter/AccessListFiltersFilter";
import "./AccessListFilters.css";
import {unsetAccessListNotificationSettings} from "../../../../actions";

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.currentItemData = {};
        this.state = {
            data: [],
            filter: {},
            isLoading: false,
            editedItemId: null,
            showModalItemRemove: false,
            showModalItemEdit: false,
        }
    }

    componentDidMount() {
        this.props.getItems(this.props.accountId);
        this.props.getNotificationSettings(this.props.accountId);
    }

    componentWillUnmount() {
        this.props.unsetNotificationSettings()
    }

    shouldComponentUpdate({items}) {
        if (items !== this.props.items) {
            this.setState({data: items});
            return false;
        }
        return true;
    }

    getItems = () => {
        this.props.getItems(this.props.accountId);
    };

    handleEditFilter = async (accountId, alf_key, data) => {
        return await this.props.modifyItem(accountId, alf_key, data);
    };

    handleRemoveFilter = (rowData) => {
        this.currentItemData = {...rowData};
        this.setState({
            showModalItemRemove: true
        });
    };


    onChangeFilter = (filter) => {
        this.setState({
            filter: filter,
            isLoading: false,
        });
    };


    render() {
        const {accountId, itemsLoading} = this.props;
        const {worldzoneList, destinationList, subdestinationList} = this.props;
        const {data} = this.state;

        return (
            <div>
                <AccessListFiltersSettings
                    accountId={accountId}
                    handleSubmit={this.props.modifyNotificationSettings}
                    settings={this.props.notificationSettings}/>

                <AccessListFiltersFilter
                    onChange={this.onChangeFilter}
                    defaultFilters={this.state.filter}
                />

                <AccessListFiltersTable
                    filter={this.state.filter}
                    accountId={accountId}
                    data={data}
                    loading={itemsLoading || this.state.isLoading}
                    height={'30%'}
                    onRemoveItem={this.handleRemoveFilter}
                    onEditItem={this.handleEditFilter}
                    onItemShowEdit={this.handleFilterShowEdit}
                    update={this.getItems}
                    {...{worldzoneList, destinationList, subdestinationList}}
                />

                <FormAddFilter
                {...{worldzoneList, destinationList, subdestinationList}}
                addItem={this.props.addItem}
                accountId={this.props.accountId}
                update={this.getItems}/>

                {this.state.showModalItemRemove &&
                <ModalRemoveFilter
                    account_id={accountId}
                    data={this.currentItemData}
                    handleClose={ () => this.setState(
                        {showModalItemRemove: false}
                    ) }
                    update={this.getItems}/>
                }
            </div>
        );

    }
}