import React, {useState} from "react";
import { api } from "api/loginRoutes";
import {IVR_SOUND_REMOVE_API} from "const/apiMethods";
import {Modal} from "../../../components/Modal";
import {useWindowWidth} from "../../../hooks";


export default ({
    show,
    onClose,
    activeSoundId,
    maxWidth = 600,
    getIvrSoundList,
}) => {

    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const handleSuccess = async () => {
        if (!activeSoundId) {
            return;
        }

        setLoading(true);

        api(IVR_SOUND_REMOVE_API, {target: {ivr_sound_id: activeSoundId}})
            .then(() => {
                getIvrSoundList();
                onClose();
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <Modal
            {...{show, onClose}}
            onClose={() => onClose(false)}
            title={`Remove IVR sound`}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            footer={true}
            loading={loading}
            onSuccessClose={false}
            successText="Yes"
            focusTarget="close"
            onSuccess={handleSuccess}
        >
            Are you sure?
        </Modal>
    )
}