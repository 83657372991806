import React from "react";
import {FlexboxGrid, Form, FormControl, Icon, InputGroup, Schema} from "rsuite";
import {api} from "../../api/loginRoutes";
import styled from "styled-components";
import {formValid} from '../../utils/validate';

const { StringType } = Schema.Types;

const MAX_LENGTH = 128;

const accFiltersModel = Schema.Model({
    str: StringType().maxLength(MAX_LENGTH, `The maximum is only ${MAX_LENGTH} characters.`),
});


export default class extends React.Component {

    constructor(props) {
        super(props);

        this.timer = null;
        this.formRef = React.createRef();
        this.currentQuery = "";

        this.state = {
            accountSearchQuery: this.currentQuery
        }

    }

    componentDidMount() {
        this.getAccountList();
    }


    componentDidUpdate(prevProps, prevState) {
        const {accountSearchQuery} = this.state;
        const {fetchingTrigger, setLoading, setFilteredAccounts, returnAll} = this.props;

        if (fetchingTrigger !== prevProps.fetchingTrigger || accountSearchQuery !== prevState.accountSearchQuery) {

            // clear filtered accounts on empty query
            if (!accountSearchQuery && !returnAll) {
                setLoading(true);

                setTimeout(() => {
                    setFilteredAccounts(null);
                    setLoading(false);
                }, 100);
                return;
            }
            
            this.getAccountList();
    
        }
    }


    getAccountList = () => {
        const {accountSearchQuery} = this.state;
        const {setLoading, additionalParams, setFilteredAccounts} = this.props;
        
        setLoading(true);

        api("account:get_dropdown_list", {
            filter: {
                str: accountSearchQuery
            }
        })
            .then(({account_list = []}) => {
                if (this.currentQuery === accountSearchQuery)
                    setFilteredAccounts( account_list.filter(account => !account.is_test) );
                setLoading(false);
            });
    }


    handleChange = (filters) => {

        clearTimeout(this.timer);
        if ( !formValid(accFiltersModel.check(filters)) )
            return;

        this.timer = setTimeout(() => {
            const str = filters && filters.str 
                ? filters.str : 
                "";
            this.currentQuery = str;
            this.setState({accountSearchQuery: str});
        }, 500);
    };

    render() {
        const {placeholder = "Account name/Email address/Messenger"} = this.props;
        const handleChange = this.handleChange;

        return (
            <>
                <Form
                    ref={this.formRef}
                    model={accFiltersModel}
                    onChange={handleChange}
                    className="accounts-filter-form"
                >
                    <StyledInputGroup>
                        <FormControl
                            name="str"
                            errorPlacement="topRight"
                            placeholder={placeholder}
                        />
                        <InputGroup.Addon><Icon icon="search" /></InputGroup.Addon>
                    </StyledInputGroup>
                </Form>
            </>
        );
    };
}


const StyledInputGroup = styled(InputGroup)`
    && {
        max-width: 320px; 
        width: 100%;
        overflow: visible;
    }

    &&&& {
        .rs-input {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
    }
`;