import React from "react";
import {Button, Input, Icon} from "rsuite";

const InputButton = ({onChange, children, ...props}) => {
    return (
        <Button
            size="xs"
            appearance="subtle"
            className="rs-input-number-touchspin-up"
            {...props}
        >
            {children}
        </Button>
    )
};

const validCharsRegex = /^[0-9,\-,.]*$/;

const CustomNumber = ({min, max, step = 1, defaultValue = 0, onChange, ...props}) => {

    let [value, setValue] = React.useState(defaultValue);

    return (
        <div className="rs-input-group rs-input-number custom-form-control">
            <Input
                value={value}
                onChange={(val) => {

                    if ( !val.match(validCharsRegex) && val )
                        return;

                    let newValue = val;

                    if (onChange)
                        onChange(newValue);
                    setValue(newValue);
                }}
            />
            <span className="rs-input-number-btn-group-vertical">
                <InputButton
                    disabled={!isNaN(max) && value >= max}
                    onClick={() => {

                        let floatedValue = parseFloat(value);
                        let currentValue = !isNaN(floatedValue) ? floatedValue : 0;

                        const stepRange = step && (step % 1 !== 0) ? step.toString().split('.')[1].length : 0;
                        const fixedValue = stepRange ? parseFloat( (currentValue + step).toFixed(stepRange) ) : currentValue + step;

                        const newValue = (!isNaN(min) && currentValue < min) ? min : fixedValue;
                        if (onChange)
                            onChange(newValue);
                        setValue(newValue)
                    }}
                >
                    <Icon icon='arrow-up-line' />
                </InputButton>
                <InputButton
                    disabled={!isNaN(min) && value <= min}
                    onClick={() => {

                        let floatedValue = parseFloat(value);
                        let currentValue = !isNaN(floatedValue) ? floatedValue : 0;

                        const stepRange = step && (step % 1 !== 0) ? step.toString().split('.')[1].length : 0;
                        const fixedValue = stepRange ? parseFloat( (currentValue - step).toFixed(stepRange) ) : currentValue - step;

                        const newValue = (!isNaN(max) && currentValue > max) ? max : fixedValue;
                        if (onChange)
                            onChange(newValue);
                        setValue(newValue)
                    }}
                >
                    <Icon icon='arrow-down-line' />
                </InputButton>
            </span>
        </div>
    )
};

export default CustomNumber