import React from 'react';
import Modal from '../../components/Modal';

import {Form, Schema, SelectPicker} from 'rsuite';
import CustomField from '../../components/Form/CustomField';
import {api} from '../../api/loginRoutes';
import FormHoc from '../../hoc/FilltersForm';
import InputSearch from '../../components/Form/InputSearch';
import ExportPrice from './ExportPrice';
import styled from 'styled-components';
import {DEFAULT_PER_PAGE_MEDIUM, USD_DEFAULT_CURRENCY_KEY} from '../../const';
import MyTable from 'components/Table';
import {ButtonSideIcon} from "../../components/base/BaseButton";

const {StringType} = Schema.Types;
const per_page = DEFAULT_PER_PAGE_MEDIUM;


const ShowPrice = ({
    currency_list,
    sp_key,
    paymentTermsList,
    account,
    template,
    getTemplate,
    otpType
}) => {

    const [show, setShow] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [filter, setFilter] = React.useState({
        cur_key: USD_DEFAULT_CURRENCY_KEY, str: ''
    });


    React.useEffect(() => {
        if (!show)
            return;
        setFilter({cur_key: USD_DEFAULT_CURRENCY_KEY, str: ''});
        getData({cur_key: USD_DEFAULT_CURRENCY_KEY, str: ''}, 1);
    }, [show]);


    const onChangeFilters = (_filters) => {
        setFilter({str: _filters._str || '', cur_key: _filters.cur_key});
        getData({str: _filters._str || '', cur_key: _filters.cur_key}, 1);
    };


    const getData = (filter, page) => {
        setLoading(true);

        const method = otpType === null
            ? 'ratecard_standart:get_default_price'
            : 'ratecard_otp:get_default_price';
        api(method, {
            filter: {cur_key: USD_DEFAULT_CURRENCY_KEY, str: '', ...filter, sp_key}, page, per_page,
            // add: {test_trunk_number_list:{}, service_plan_price:{} }
        })
            .then(({ratecard_standart_list, ratecard_otp_list, ratecard_standart_count, ratecard_otp_count}) => {
                setCount(ratecard_standart_count || ratecard_otp_count || 0);
                setPage(page);
                setItems(
                    (ratecard_standart_list || ratecard_otp_list || []).map(
                        item => {
                            // set payment terms
                            for (const term of paymentTermsList) {
                                item[`payments_${term.name}`] = '';
                                if (item.payment_terms_rate_list && item.payment_terms_rate_list.length) {
                                    item[`payments_${term.name}`] = (item.payment_terms_rate_list
                                        .find(el => el.pt_key === term.pt_key) || {})
                                        .rate || '';
                                }
                            }

                            // set test number
                            item.test_number = '';
                            if (item.test_trunk_number_list && item.test_trunk_number_list.length) {
                                item.test_number = item.test_trunk_number_list[0];
                                item.test_trunk_number_list[1] && (item.test_number += ' ...');
                            }
                            return item;
                        }
                    )
                );
                setLoading(false);
            })
            .catch(error => setLoading(false));
    };


    const columns = otpType === null
        ? [
            {label: 'Prefix', dataKey: 'prefix'},
            {label: 'Range name', dataKey: 'range_name'},
        ]
        : [
            {
                label: 'Subdestination',
                dataKey: 'subdestination_name',
                value: ({subdestination_name, template}) => (
                    subdestination_name && template
                        ? `${subdestination_name} (${template})`
                        : ""
                ),
            }
        ];

    if (paymentTermsList) {
        for (const term of paymentTermsList) {
            columns.push({label: `Payout ${term.name.replace("_", "-")}`, dataKey: `payments_${term.name}`});
        }
    }

    if (otpType === null) {
        columns.push({label: 'Test number', dataKey: 'test_number'});
    }


    return (
        <>
            <ButtonSideIcon onClick={() => setShow(true)} icon="eye">Show price</ButtonSideIcon>
            <Modal
                show={show}
                title="Price"
                onClose={() => setShow(false)}
                width="90%"
            >
                <Header>
                    <div>
                        <Filters
                            {...{
                                currency_list
                            }}
                            onChange={onChangeFilters}
                        />
                    </div>
                    <div>
                        <ExportPrice
                            str={filter.str}
                            cur_key={filter.cur_key}
                            sp_key={sp_key}
                            account={account}
                            template={template}
                            getTemplate={getTemplate}
                            otpType={otpType}
                        />
                    </div>
                </Header>

                <MyTable
                    data={items}
                    height="50%"
                    extraHeight={470}
                    loading={loading}
                    columns={columns}
                    count={count}
                    page={page}
                    per_page={per_page}
                    getItems={(page) => getData(filter, page)}
                    ispagination
                    hidePerPage
                />
            </Modal>
        </>
    );
};

export default ShowPrice;


const filtersModel = Schema.Model({
    _str: StringType().maxLength(40, 'The maximum is only 40 characters.'),
});

const Filters = FormHoc(({onChange, currency_list}) => {

    return (
        <Form layout="inline"
              onChange={onChange}
              formDefaultValue={{cur_key: USD_DEFAULT_CURRENCY_KEY}}
              model={filtersModel}
        >
            <div style={{display: 'inline-block', width: 200}}>
                <CustomField
                    name="_str"
                    accepter={InputSearch}
                    errorPlacement="topRight"
                    placeholder="Prefix/Range name"
                    // style={{width:220}}
                />
            </div>
            <CustomField
                accepter={SelectPicker}
                data={currency_list}
                cleanable={false}
                searchable={false}
                labelKey="name"
                valueKey="cur_key"
                name="cur_key"
                classPrefix="allocated"
            />
        </Form>
    );
}, filtersModel, 300);

const Header = styled.div`
    margin-bottom: -20px;
    > div {
        display: inline-block;
        width: 50%
    }
    >div:last-child{
        text-align: right
    }
`;
