import {DatePicker, FlexboxGrid, Form, FormControl, Radio, RadioGroup, Schema} from "rsuite";
import Text, {str40} from "../../../../components/Form/Text";
import ServicePlans from "../../../../components/Filters/ServicePlans";
import CustomField from "../../../../components/Form/CustomField";
import Protocols from "../../../../components/Filters/Protocols";
import React from "react";
import {connect} from "react-redux";
import {DEFAULT_PRT_KEY} from "../../../../const";
import CheckboxControl from "components/Form/CheckboxControl";
import styled, {css} from "styled-components";

const {StringType, NumberType} = Schema.Types;

const formModel = Schema.Model({
    name: str40,
});

const formDialerModel = Schema.Model({
    tprefix: StringType()
        .pattern(/^\d*[#]?\d*$/, 'Techprefix can contain only numbers and one character"#"')
        .maxLength(10, 'Technical prefix can contain a maximum of 10 characters'),
    port: NumberType()
        .isRequired('Required')
        .range(0, 65535, 'Enter the correct port'),
    ip: StringType()
        .isRequired('Required')
        .pattern(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            'Please enter legal IP Address'
        ),
    prt_key: NumberType().isRequired('Required')
});


const TrunkForm  =  ({disabled, updateFormRef,updateFormRef2, is_default, onChangeDefault, initialValues, defaultSPKey, isMobile}) => {

    const [chosenSpKey, setChosenSpKey] = React.useState( (initialValues && initialValues.sp_key) || 1 );

    const defaultFormValue = {
        sp_key: defaultSPKey,
        active: true,
        ...initialValues,
        is_default_term_point:is_default,
    };

    if (initialValues.option_google_otp) {
        defaultFormValue.otp_type = "google_otp";
    }

    return (
        <Form
            layout={isMobile ? null : "inline"}
            model={formModel}
            formDefaultValue={defaultFormValue}
            ref= {updateFormRef}
            style={{margin: 0, width: '100%'}}
        >
            <FlexboxGrid
                align={isMobile ? 'flex-start' : 'center'}
                style={{margin: 0, width: '100%'}}
            >
                <FlexboxGrid.Item className={'mb-2'}>
                    <Text
                        name="name"
                        placeholder="Name"
                        style={{width: 240, marginLeft: 0}}
                    />
                    <ServicePlans
                        classPrefix=""
                        disabled={!!initialValues.id}
                        style={{verticalAlign: 'none', marginLeft: 10, height: 36}}
                        onChange={(value) => {
                            if (chosenSpKey === value)
                                return;
                            setChosenSpKey(value);
                        }}
                    />
                    <CheckboxControl
                        name="active"
                        defaultChecked={defaultFormValue.active}
                    >
                        Active
                    </CheckboxControl>
                </FlexboxGrid.Item>
            </FlexboxGrid>


            <StyledGrid
                isMobile={isMobile}
                align={isMobile ? 'flex-start' : 'center'}
                style={{margin: 0, width: '100%'}}
            >
                <FlexboxGrid.Item className={'mb-2 ml-2'}>
                    {isMobile && <p>Start Date</p>}
                    <FormControl
                        name="start_date"
                        placeholder="Start Date"
                        cleanable={false}
                        accepter={DatePicker}
                        className={'mr-2'}
                        style={{width: 165}}
                        ranges={[
                            {
                                label: 'Today',
                                value: new Date()
                            },
                            {
                                label: 'Tomorrow',
                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                            }
                        ]}

                        // mobile
                        inline={isMobile}
                        oneTap={isMobile}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item className={'mb-2 ml-2'}>
                    {isMobile && initialValues.id &&
                        <p>End Date</p>
                    }
                    {isMobile && !initialValues.id
                        ? null
                        : <FormControl
                        name="end_date"
                        placeholder="End Date"
                        accepter={DatePicker}
                        style={{width: 165}}
                        disabled={!initialValues.id}
                        ranges={[
                            {
                                label: 'Today',
                                value: new Date()
                            },
                            {
                                label: 'Tomorrow',
                                value: new Date( new Date().setDate(new Date().getDate() + 1) )
                            }
                        ]}

                        // mobile
                        inline={isMobile}
                        oneTap={isMobile}
                    />}
                </FlexboxGrid.Item>
            </StyledGrid>


            <FlexboxGrid
                align={isMobile ? 'flex-start' : 'center'}
                style={{margin: 0, width: '100%'}}
            >
                <FlexboxGrid.Item className={'mb-2'}>
                    <CustomField
                        disabled={disabled}
                        accepter={RadioGroup}
                        onChange = {onChangeDefault}
                        name="is_default_term_point"
                        inline={isMobile ? false : true}
                        style={{marginLeft: '-10px'}}
                    >
                        <Radio value={true}>Default termination point</Radio>
                        <Radio value={false}>Dialer panel</Radio>
                    </CustomField>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    {!is_default  &&
                    <DialerForm
                        initialValues = {initialValues}
                        disabled = {disabled}
                        updateFormRef = {updateFormRef2}
                        defaultSPKey = {defaultSPKey}
                    />}
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </Form>
    );
};

const mapState = ({references})=> ({
    defaultSPKey: references.defaultSPKey
});

export default connect(mapState, null)(TrunkForm);


const DialerForm  =  ({disabled, updateFormRef, initialValues, defaultSPKey}) => {
    if (initialValues.ip == null) {
        delete initialValues.ip;
        delete initialValues.tprefix;
        delete initialValues.port;
        delete initialValues.prt_key;
    }

    return (
        <Form
            layout="inline"
            model={formDialerModel}
            ref= {updateFormRef}
            formDefaultValue={{prt_key: DEFAULT_PRT_KEY, sp_key: defaultSPKey, port: '5060', tprefix: '', ...initialValues }}
            style={{margin: '0px', width: '100%'}}
        >
            <FlexboxGrid align='center' style={{margin: '0px', width: '100%'}}>
                <FlexboxGrid.Item>
                    <Text name="ip" label="IP" disabled={disabled} labelWidth={65} style={{marginBottom: '20px'}}/>
                    <FlexboxGrid align='center' justify="space-between" style={{margin: '0 0 20px 0'}}>
                        <FlexboxGrid.Item>
                            <Protocols style={{marginLeft: '-10px', width: '160px'}}/>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <Text name="port" label="Port" disabled={disabled} style={{width: '90px'}}  />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Text name="tprefix" label="Techprefix" disabled={disabled} labelWidth={65}/>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Form>
    );
};


const StyledGrid = styled(FlexboxGrid)`
    ${props => props.isMobile && css`
        &&& {
            flex-direction: column;
        }
    `}
`;