import React from 'react';
import {CheckPicker,FormControl} from 'rsuite';



export default (props) => {
    return  <FormControl
                name={props.name}
                accepter={CheckPickerHOC}
                {...props}                  
            /> ;    
}
                    


const CheckPickerHOC = (props) => {    
    let _values = [];
    const onClose = () => console.log('onClose') ||  props.onChange(_values);
    const onChange = (values) =>  _values = values;
    const onClean = () => props.onChange(_values);

    return <CheckPicker            
            {...props} 
            onClose={onClose} onChange={onChange} onClean={onClean}
            /> ;   
}