import React from "react";
import {renderColumn} from "../../utils/helpers";
import {Table} from 'rsuite';
import {SCREEN_MEDIA} from '../../const';
import {useWindowWidth} from "../../hooks";
import * as S from "./styled.js";

const {sm} = SCREEN_MEDIA;
const {Column, HeaderCell, Cell} = Table;

export default ({
    data = [], 
    loading = false,
    columns,
    tableActions,
    height
}) => {

    const resizedWidth = useWindowWidth();

    // push table actions
    const renderedColumns = columns.map(renderColumn);
    if (tableActions) {
        renderedColumns.push(tableActions);
    }

    // add fixing column to fix horizontal scroll
    const columnMobileFix = <Column key="table_scrollfix" width={0} fixed><HeaderCell></HeaderCell><Cell></Cell></Column>;
    renderedColumns.push(columnMobileFix);

    return (
        <S.Table
            shouldUpdateScroll={false}
            virtualized
            data={data}
            loading={loading}
            autoHeight={resizedWidth <= sm.max}
            height={height || 700}
        >
            {renderedColumns}
        </S.Table>
    )
};