import React from "react";
import styled, {css} from 'styled-components';
import {FlexGrid, FlexGridItem} from "./FlexGrid";
import {APP_TYPE_CLIENT} from "../../const";

const StyledPageHeader = styled.div`
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    
    h1 {
        font-family: inherit;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: var(--color-brand1);
    }
    
    ${props => props.theme.appType === APP_TYPE_CLIENT && css`
        padding-top: 10px;
        padding-bottom: 25px;
        
        h1 {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.2em;
        }
    `}
`;

export default ({title, children, ...props}) => (
    <StyledPageHeader
        className="page-header"
        {...props}
    >
        <FlexGrid justify="space-between" align="middle">
            {title &&
                <FlexGridItem><h1>{title}</h1></FlexGridItem>
            }
            <FlexGridItem>{children}</FlexGridItem>
        </FlexGrid>
    </StyledPageHeader>
);