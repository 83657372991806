import React, {useEffect, useState} from 'react';
import {API_Modal, ApiRequest, Info, MyForm} from '../../components';
import {Alert, Checkbox, Table} from 'rsuite';
import {api} from '../../api/loginRoutes';
import RateField from '../../components/Form/RateField';
import {SP_CHANGE_RATE_METHOD} from 'const/apiMethods';
import MyTable from 'components/Table';
import {NumberField} from './../../components/Form/NumberField';
import Form from 'rsuite/es/Form';

const {Column, HeaderCell, Cell} = Table;

const labelWidth = 130;
const inputNumberWidth = 100;
const heightResize = 190;
const rowHeight = 30;
const rowHeaderHeight = 40;

const styleF = {
    display: 'inline-block',
    width: 300,
    borderRight: '3px solid #C4C4C4',
    verticalAlign: 'middle',
    marginTop: 50
};
const styleT = {
    display: 'inline-block',
    width: 410,
    marginLeft: 20,
    verticalAlign: 'top'
};


const EditPrice = ({
    setShow,
    p_key,
    sde_key,
    cur_key,
    update,
    ratesValue = [],
}) => {

    const [accounts, setAccounts] = useState({data: [], loading: true});
    const [ptValue, setPtValue] = useState( ratesValue.map(item => ({
        ...item,
        checked: item.value || item.value == 0 ? true : false
    })) );


    useEffect(() => {
        setAccounts({
            ...accounts,
            loading: true,
        });

        api('service_plan_price:account_with_special_price', {p_key, sde_key, cur_key})
            .then(({account_with_special_price}) => {
                const accountWithExtraKey = account_with_special_price ? account_with_special_price.map(acc => {
                    return {...acc, select_object: {account_id: acc.account_id, sp_key: acc.sp_key}}
                }) : null;
                console.log('account_with_special_price', accountWithExtraKey);
                setAccounts(() => ({
                    data: accountWithExtraKey || [],
                    loading: false
                }))
            });
    }, []);


    const onChangeRate = (account_id, rate, sp_key) => {
        setAccounts({
            ...accounts,
            data: accounts.data.map(
                row => row.account_id === account_id && row.sp_key === sp_key ? {...row, checked: true, rate} : row
            )
        });
    };


    const onChangeChecked = (selected) => {
        setAccounts({
            ...accounts,
            data: accounts.data.map(
                row => selected.list.some(current_selected => row.select_object.sp_key === current_selected.sp_key
                    && row.select_object.account_id === current_selected.account_id)
                    ? {...row, checked: true} : {...row, checked: false}
            )
        });
    };


    const getUpdatedPtValue = (rate, newData) => {
        return ptValue.map(ptValueItem => {
            if (ptValueItem.pt_key !== rate.pt_key)
                return ptValueItem;

            return {...ptValueItem, ...newData};
        });
    };


    const numberControlProps = {
        labelWidth,
        width: inputNumberWidth,
        mr: 5
    };

    const calculatedHeight = accounts.data.length < 5 && accounts.data.length > 0
        ? rowHeight * accounts.data.length + rowHeaderHeight
        : heightResize;

    const checkboxProps = {inline: true};

    const columns = [
        {label: 'Dialer', dataKey: 'acc_name', minWidth: 160},
        {
            label: 'Service Plan',
            value: ({service_plan_name}) => service_plan_name,
            width: 95
        }
    ];

    return (
        <API_Modal
            title="Change rate for subdestination"
            onClose={() => setShow(false)}
            successText="Change"
            width={800}
            update={() => {
                Alert.success('Changed');
                update();
            }}
        >
            <MyForm
                noCheck
                formDefaultValue={{p_key, sde_key, cur_key}}
                method={SP_CHANGE_RATE_METHOD}
                style={{...styleF, height: 'auto'}}
                addData={{
                    new_rates: (ptValue
                        .filter( rate => rate.checked )
                        .map( rate => ({
                            pt_key: rate.pt_key,
                            rate: +rate.value
                        })
                    ))
                }}
            >
                <div
                    style={{
                        // marginTop: (calculatedHeight - 70) / 2,
                        marginRight: 0,
                        marginBottom: 0,
                        // height: 70
                    }}
                >

                    {ptValue.map(rate => (
                        <div key={rate.pt_key}>
                            <RateField
                                {...numberControlProps}
                                style={{padding: 0, margin: 0}}
                                label={`New rate for ${rate.name.replace("_", "/")}`}
                                value={rate.value}
                                onChange={(v) => setPtValue( getUpdatedPtValue(rate, {
                                    value: v,
                                    checked: !!v
                                }))}
                            />
                            <Checkbox
                                {...checkboxProps}
                                style={{padding: 0, margin: 0, marginTop: 0}}
                                checked={rate.checked}
                                onChange={(v, checked) => setPtValue( getUpdatedPtValue(rate, {
                                    checked: checked
                                }))}

                            />
                        </div>
                    ))}
                </div>
            </MyForm>

            <ApiRequest
                method='account_price__set_account_list'
                data={{
                    sde_key,
                    cur_key,
                    rates: accounts.data
                        .filter(x => x.checked)
                        .map(x => ({account_id: x.account_id, sp_key: +x.sp_key, rate: +x.rate}))
                }}
                noCheck
                style={styleT}
            >
                <Info>These dialers have speial rates for this subdestination. Do you want to update them?</Info>
                <MyTable
                    heightResize={calculatedHeight}
                    data={accounts.data}
                    loading={accounts.loading}
                    columns={columns}
                    row_key="select_object"
                    setSelected={onChangeChecked}
                    isselected
                    stateChecked
                    ActionCell={ActionCell(onChangeRate)}
                />
            </ApiRequest>
        </API_Modal>
    );
};

export default EditPrice;


const ActionCell = (onChange) => (
    <Column width={100}>
        <HeaderCell>Rate</HeaderCell>
        <Cell>
            {rowData => (
                <Form>
                    <NumberField
                        key={rowData.account_id}
                        max={10}
                        min={0.00001}
                        size="sm"
                        width="100%"
                        placeholder="0"
                        name="rate"
                        style={{position: 'relative', top: '-5px', width: 80}}
                        calculated={true}
                        value={rowData.rate}
                        onChange={value => onChange(rowData.account_id, value, rowData.sp_key)}
                    />
                </Form>
            )}
        </Cell>
    </Column>
);
