import React, {useState} from 'react';
import {Button, Form, FormControl, Icon, Input, Schema, SelectPicker, Table} from 'rsuite';
import CheckPicker from 'rsuite/es/CheckPicker';
import Badge from 'rsuite/es/Badge';
import * as S from './styled';
import Checkbox from 'rsuite/es/Checkbox';
import Modal from '../../components/Modal';
import {compareTwoArrays, fieldSorter} from '../../utils'
import BaseTable from "../../components/base/BaseTable";

const {Column, HeaderCell, Cell} = Table;
const {NumberType, StringType} = Schema.Types;

const formModel = Schema.Model({
    wz_key: NumberType(),
    de_key: NumberType(),
    sde_key: NumberType(),
    account_id: StringType()
});

const actionSelectData = [
    {option: false, label: 'Allowed'},
    {option: true, label: 'Blocked'},
];

export default ({data = [], filter = {}, onRemoveItem, onEditItem, onEditItemList, onAddItem, loading, isLoading, update, ...props}) => {
    const [tableData, setTableData] = useState([]);
    const [currentEditedFieldKeyList, setCurrentEditedFieldKeyList] = React.useState(null);
    const [editedFieldKeyList, setEditedFieldKeyList] = useState([]);
    const [deleteKeyList, onDeleteKeys] = useState([]);
    const [localLoading, setLocalLoading] = useState(isLoading);
    const [checkedKeys, onChangeCheckedKeys] = useState([]);
    const [changedData, onChangedData] = useState(false);

    let formGroupsRefs = new Map();

    const searchedData = ["sde_wz_name", "sde_de_name", "sde_name", "sp_name", "acc_name", "action"];

    React.useEffect(() => {
        const tableDataList = data.filter((item) => {
            // return item if filter is empty
            if (!filter || filter && !filter.str)
                return true;

            const match = filter.str.toLowerCase();

            const filterMatching = searchedData.map((keyName) => {
                if (item[keyName]) {
                    const matchedString = item[keyName].toString().toLowerCase();

                    if (matchedString.includes(match)) {
                        return true
                    }
                }
            });

            return filterMatching.some(dataMatch => !!dataMatch);
        });
        tableDataList.sort(fieldSorter(['wz_key', 'de_key', 'sde_key', 'acc_name', 'sp_key']))
        const groupedData = Object.values(tableDataList.reduce((a, curr) => {
            const {acc_id, sde_key, de_key, wz_key} = curr;
            const key = `${acc_id}_${sde_key}_${de_key}_${wz_key}`;
            (a[key] = a[key] || []).push(curr);
            return a;
        }, {}));

        const combinedNumber = groupedData.map((value) => {
            const duplicateList = value.map(duplicate => {
                return {sp_key: duplicate.sp_key, sp_name: duplicate.sp_name, ab_key: duplicate.ab_key}
            });

            return {...value[0], sp_duplicates: duplicateList}
        });
        setTableData(combinedNumber);
        onChangeCheckedKeys(combinedNumber.filter(item => item.action).map(item => item.ab_key));
        if (currentEditedFieldKeyList) {
            let editedFilters = new Set([...editedFieldKeyList, currentEditedFieldKeyList]);
            editedFilters.delete(currentEditedFieldKeyList);
            setEditedFieldKeyList(Array.from(editedFilters));
            setCurrentEditedFieldKeyList(null);
        }
        if (changedData && compareTwoArrays(data.map(row => row.ab_key), tableDataList.map(row => row.ab_key))) {
            update()
            onChangedData(false);
        }
    }, [data, filter]);

    const createFormGroupRefs = (key, ref) => {
        if (!formGroupsRefs.has(key)) {
            formGroupsRefs.set(key, new Set([ref]))
        } else {
            formGroupsRefs.get(key).add(ref);
        }
    };

    const clearEditField = (key) => {
        let editedFilters = new Set([...editedFieldKeyList, key]);
        editedFilters.delete(key);
        setEditedFieldKeyList(Array.from(editedFilters));
        setCurrentEditedFieldKeyList(null);
    };

    const handleCheck = (value, checked) => {
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);
        onChangeCheckedKeys(nextCheckedKeys)
    };

    const handleSubmit = async (key) => {
        const formFields = formGroupsRefs.get(key);
        let dataObject = {action: checkedKeys.some(item => item === key)};
        formFields.forEach((field) => {
            if (!field) {
                return;
            }
            const formData = field.getFormValue();

            Object.keys(formData).forEach(key => {
                if (key !== 'action') dataObject[key] = formData[key];
            });
        });
        const tableRow = tableData.find(value => value.ab_key === key);
        const duplicateKeys = tableRow.sp_duplicates.map(duplicate => duplicate.ab_key);
        const filteredData = data.filter(item => duplicateKeys.includes(item.ab_key));

        const absentRows = filteredData.filter(item => !dataObject.sp_key.includes(item.sp_key));
        const existRows = filteredData.filter(item => dataObject.sp_key.includes(item.sp_key));
        const existRowKeys = existRows.map(item => item.sp_key);

        if (!dataObject.sp_key.length) {
            const allRowsToDelete = filteredData.map(item => item.ab_key);

            allRowsToDelete.forEach(key => {
                onRemoveItem(key).then(() => {
                    onChangedData(true)
                })
            })
        } else {
            // compare two objects
            const existRowsComparable = existRows.map(row => {
                return {
                    account_id: row.acc_id,
                    action: row.action,
                    wz_key: row.wz_key,
                    de_key: row.de_key,
                    sde_key: row.sde_key,
                    sp_key: null
                }
            });
            const dataObjectComparable = Object.assign({}, dataObject, {sp_key: null});
            let comparedRows;

            existRowsComparable.forEach(row => {
                comparedRows = !Object.is(row, dataObjectComparable)
            })

            return await onEditItemList(duplicateKeys, dataObjectComparable).then(() => {
                dataObject.sp_key.forEach(sp_key => {
                    let dataToEdit = {};
                    if (filteredData.length) {
                        dataToEdit = dataObject
                    }

                    if (!existRowKeys.includes(sp_key) && dataObject.sp_key.length > existRows.length) {
                        onChangedData(true);
                        onAddItem({...dataToEdit, sp_key})
                    }
                })

                if (absentRows.length) {
                    absentRows.forEach(item => {
                        onChangedData(true);
                        onRemoveItem(item.ab_key)
                    })
                }
            })
        }

        return await key
    }
    return (
        <>
            <BaseTable
                virtualized
                className="tableFilters"
                data={tableData}
                loading={loading || localLoading}
                headerHeight={47}
                rowHeight={46}
                autoHeight
            >
                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Zone</span>
                    </HeaderCell>
                    <S.CustomCell dataKey={"sde_wz_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{wz_key: rowData["wz_key"]}}
                                    >
                                        <FormControl
                                            name="wz_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={props.worldzoneList}
                                            placeholder="Zone"
                                            valueKey="wz_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <>
                                    {rowData["sp_duplicates"].length > 1 &&
                                    <Badge className="duplicates_badge" content={rowData["sp_duplicates"].length}/>}
                                    <span className="tableFilters__previewText">{rowData["sde_wz_name"]}</span>
                                </>
                            )
                        }}
                    </S.CustomCell>
                </Column>
                <Column flexGrow minWidth={130} align="center">
                    <HeaderCell>
                        <span className="tableFilters__headerText">Destination</span>
                    </HeaderCell>
                    <Cell dataKey={"sde_de_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{de_key: rowData["de_key"]}}
                                    >
                                        <FormControl
                                            name="de_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={props.destinationList}
                                            placeholder="Destination"
                                            valueKey="de_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["sde_de_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={2} minWidth={130} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Subdestination</span>
                    </HeaderCell>
                    <Cell dataKey={"sde_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{sde_key: rowData["sde_key"]}}
                                    >
                                        <FormControl
                                            name="sde_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={props.subdestinationList}
                                            placeholder="Subdestination"
                                            valueKey="sde_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["sde_name"]}</span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow={2} minWidth={150} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Service plan</span>
                    </HeaderCell>
                    <Cell dataKey={"sp_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{sp_key: rowData["sp_duplicates"].map(sp => sp.sp_key)}}
                                    >
                                        <FormControl
                                            name="sp_key"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={CheckPicker}
                                            data={props.servicePlanList}
                                            placeholder="Service plan"
                                            cleanable={false}
                                            valueKey="sp_key"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }

                            return (
                                <span className="tableFilters__previewText">
                                    {rowData["sp_duplicates"].length ? rowData["sp_duplicates"].map(sp => sp.sp_name).join(', ') : rowData["sp_name"]}
                                </span>
                            );
                        }}
                    </Cell>
                </Column>

                <Column flexGrow minWidth={130} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Dialer account</span>
                    </HeaderCell>
                    <Cell dataKey={"acc_name"}>
                        {(rowData) => {
                            if (editedFieldKeyList.includes(rowData["ab_key"])) {
                                return (
                                    <Form
                                        model={formModel}
                                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                                        formDefaultValue={{account_id: rowData["acc_id"]}}
                                    >
                                        <FormControl
                                            name="account_id"
                                            className="tableFilters_field"
                                            errorPlacement="topEnd"
                                            accepter={SelectPicker}
                                            data={props.accountList}
                                            placeholder="Account"
                                            valueKey="id"
                                            labelKey="name"
                                            type="text"
                                        />
                                    </Form>
                                )
                            }
                            return (
                                <span className="tableFilters__previewText">{rowData["acc_name"] || 'Any'}</span>
                            );
                        }}
                    </Cell>
                </Column>
                <Column width={200} className="access_list_exclusion__column" align="center">
                    <HeaderCell>
                        <span className="access_list_exclusion__header_text">Block/Allow</span>
                    </HeaderCell>
                    <CheckCell
                        dataKey="ab_key"
                        checkedKeys={checkedKeys}
                        onChange={handleCheck}
                        {...{
                            editedFieldKeyList,
                            createFormGroupRefs
                        }}/>
                </Column>
                <Column width={174}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                        {rowData => (
                            <div className="tableFilters_buttons">
                                {!editedFieldKeyList.includes(rowData["ab_key"])
                                    ? <Button size="sm" color="lightblue" onClick={() => {
                                        let editedFilters = new Set([...editedFieldKeyList, rowData["ab_key"]]);
                                        setEditedFieldKeyList(Array.from(editedFilters));
                                    }}>
                                        <Icon icon="edit2"/>
                                    </Button>
                                    : <>
                                        <Button size="sm" color="green" onClick={() => {
                                            handleSubmit(rowData["ab_key"]).then(() => {
                                                clearEditField(rowData["ab_key"]);
                                                update()
                                            }) // --- SUBMIT
                                        }}>
                                            <Icon icon="check-circle"/>
                                        </Button>

                                        <Button size="sm" color="red" onClick={() => {
                                            let editedFilters = new Set([...editedFieldKeyList, rowData["ab_key"]]);
                                            editedFilters.delete(rowData["ab_key"]);
                                            setEditedFieldKeyList(Array.from(editedFilters));
                                        }}>
                                            <Icon icon="close-circle"/>
                                        </Button>
                                    </>
                                }
                                <Button
                                    size="sm"
                                    color="red"
                                    onClick={() => {
                                        onDeleteKeys(rowData.sp_duplicates.map(duplicate => duplicate.ab_key))
                                    }}
                                >
                                    <Icon icon="trash2"/>
                                </Button>
                            </div>
                        )}
                    </Cell>
                </Column>

            </BaseTable>
            <Modal
                show={deleteKeyList.length}
                title="Confirm deleting"
                onSuccess={() => {
                    deleteKeyList.forEach(key => {
                        onRemoveItem(key).then(() => {
                            update()
                        })
                    });
                    onDeleteKeys([])
                }}
                onClose={() => onDeleteKeys([])}
                successText={'Yes'}
                footer
            >
                <div style={{display: 'flex', justifyContent: "center"}}>Are you sure you want to delete this blocked destination?</div>
            </Modal>
        </>
    )
};

export const CheckCell = ({
    rowData,
    checkedKeys,
    dataKey,
    editedFieldKeyList,
    onChange,
    createFormGroupRefs,
    ...props
}) => {

    const currentLabel = rowData['action'];

    return (
        <Cell
            {...props}
            style={{padding: 0}}
        >
            <div>
                {
                    editedFieldKeyList.includes(rowData["ab_key"])
                    ? <Form
                        model={formModel}
                        ref={(ref) => createFormGroupRefs(rowData["ab_key"], ref)}
                        formDefaultValue={{action: rowData["action"]}}
                    >
                        <FormControl
                            name="action"
                            accepter={SelectPicker}
                            data={actionSelectData}
                            onChange={(val) => onChange(rowData[dataKey], val)}
                            valueKey="option"
                            labelKey="label"
                            className="tableFilters_field"
                            errorPlacement="topEnd"
                            cleanable={false}
                            searchable={false}
                        />
                    </Form>
                    : <span className="tableFilters__previewText">{actionSelectData[+currentLabel].label}</span>
                }
            </div>
        </Cell>
    );
};