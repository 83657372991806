import React, {Component} from 'react';
import PanelLayout from '../../components/base/PanelLayout';
import {FlexboxGrid, Alert, Uploader, Icon} from 'rsuite';
import {connect} from 'react-redux';
import {
    addAuthNumber,
    addUploadAuthNumbers,
    deleteAuthNumber,
    editAuthNumber,
    getAuthNumbers,
    setAnyLoading
} from '../../actions/auth_numbers';
import AuthNumbersFilter from './AuthNumbersFilter';
import PageHeader from '../../components/base/PageHeader';
import AuthNumbersTable from './AuthNumbersTable';
import ModalEdit from '../../components/Modal/ModalEdit';
import {ButtonPrimary} from "../../components/base/BaseButton";
import styled, {css} from 'styled-components';
import {AUTH_NUMBERS_ADD} from '../../const/apiMethods';
import {saveFileBlob} from '../../utils';
import {getCsvResponse} from '../../api/loginRoutes';
import './AuthNumbers.css';

const uploaderIcons =
    {
        'inited': 'arrow-up2',
        'uploading': 'spinner',
        'error': 'warning',
        'finished': 'check-circle',
    };

class AuthNumbers extends Component {
    constructor(props) {
        super(props);

        this.defaultFilters = {number: ''};

        this.state = {
            numbers: [],
            filters: {number: ''},
            inner_loading: false,
            csv: [],
            showDeleteModal: {show: false, key: null},
            onDeletePostFunction: null
        };

    }

    componentDidMount() {
        const {getAuthNumbers} = this.props;
        getAuthNumbers();
    }

    onChangeFilters = (filters) => {
        let filterData;

        if (Object.keys(filters).length === 0) {
            filterData = this.defaultFilters;
        } else {
            filterData = filters;
        }

        this.setState({
            inner_loading: true,
            filters: filterData,
        });

    };

    onAddAuthNumber = async (dataNumber) => {
        const {addAuthNumber, getAuthNumbers} = this.props;
        const response = await addAuthNumber(dataNumber, getAuthNumbers);
        if (response && response.auth_number) {
            Alert.success(`Number ${dataNumber.number} was added`);
        }
        if (response && response.error && response.error.data.number === "dublicate") {
            Alert.error(`Number ${dataNumber.number} is already exists!`)
        }
    };

    onEditAuthNumber = (id, number, description) => {
        const {editAuthNumber} = this.props;

        editAuthNumber(id, number, description);
    };

    onDeleteAuthNumber = (id) => {
        const {deleteAuthNumber} = this.props;

        deleteAuthNumber(id);
    };

    handleChangeCSV = (value) => {
        const file = value.slice(-1).pop();


        this.setState({
            csvList: [file],
            csvFile: file
        });
    };

    getFilteredData = (data) => {
        const {filters} = this.state;

        const filterName = filters && filters.number || '';
        const dataValue = data.filter(value => value && (value.number && value.number.includes(filterName) || value.description && value.description.toLowerCase().includes(filterName.toLowerCase())));

        return dataValue ? dataValue : [];
    };

    onPickDeleteNumber = (show, key, postFunction) => {
        this.setState({
            showDeleteModal: {show, key},
            onDeletePostFunction: postFunction
        });
    };
    downloadAuthNumbers = () => {
        getCsvResponse('auth_number:get_list').then((response) => {
            saveFileBlob(response, 'Auth Numbers.csv')
        })
    };
    alertFirstErrorFromUpload = (status) => {
        const errorBody = status.response.error.data;
        const errorKeys = Object.keys(errorBody);
        const errorMessageList = errorKeys.length ? errorKeys.map(key => errorBody[key]) : [];
        const errorMessage = errorMessageList.length ? errorMessageList[0] : 'Uploading error';
        if (status.response) {
            Alert.error(errorMessage);
        } else {
            Alert.error('File too large')
        }
    }
    render() {
        const {auth_number_list, setAnyLoading, getAuthNumbers, loading, loadingItem, permissions, service_plan_list} = this.props;
        const {csvFile, filters, showDeleteModal, onDeletePostFunction} = this.state;

        const onAddAuthNumber = this.onAddAuthNumber;
        const onEditAuthNumber = this.onEditAuthNumber;
        const onDeleteAuthNumber = this.onDeleteAuthNumber;
        const onPickDeleteNumber = this.onPickDeleteNumber;
        const downloadAuthNumbers = this.downloadAuthNumbers;

        setAnyLoading(false);

        const initialData = this.getFilteredData(auth_number_list);
        const permission = permissions && permissions.includes(AUTH_NUMBERS_ADD);

        return (
            <PanelLayout>
                <PageHeader title="List of auth numbers">
                </PageHeader>
                <StyledFlexboxGrid>
                    <StyledFlexboxGridItem width="74%">
                        <AuthNumbersFilter
                            onChange={this.onChangeFilters}
                            setLoading={setAnyLoading}
                            defaultFilters={this.defaultFilters}
                            textFieldStyles={{'textFieldMarginBottom': 0}}
                        />
                    </StyledFlexboxGridItem>
                    <StyledFlexboxGridItem width="13%" textAlign="right">
                        {auth_number_list.length > 0 && <ButtonPrimary onClick={downloadAuthNumbers}>
                            Download
                        </ButtonPrimary>}
                    </StyledFlexboxGridItem>
                    <StyledFlexboxGridItem width="13%" textAlign="right">
                        {permission ? <StyledUploader
                            onError={(status) => {
                                this.alertFirstErrorFromUpload(status)
                            }}
                            accept={'text/csv'}
                            autoUpload={true}
                            multiple={true}
                            ref={ref => {
                                this.uploader = ref;
                            }}
                            // headers={{'Content-Type': 'text/csv'}}
                            data={csvFile}
                            onChange={this.handleChangeCSV}
                            action={`${process.env.REACT_APP_API_URL}?method=auth_number:upload&session_key=${localStorage.getItem('api_key')}`}
                            onSuccess={(response) => {
                                if (response !== undefined) {
                                    getAuthNumbers();
                                    Alert.success(`Success! ${response.result.inserted_rows / 2} rows were uploaded!`)
                                }
                            }}
                        >
                            <ButtonPrimary>
                                {
                                    csvFile && <Icon

                                        icon={uploaderIcons[csvFile.status]}
                                        pulse={csvFile.status === 'uploading'}
                                        style={{
                                            marginRight: '5px'
                                        }}/>
                                    || <Icon
                                        icon="file"
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    />
                                }
                                <span>Upload from file</span>
                            </ButtonPrimary>
                        </StyledUploader> : <></>}
                    </StyledFlexboxGridItem>
                </StyledFlexboxGrid>
                <AuthNumbersTable
                    data={initialData}
                    dataSetted={this.props.auth_number_list_setted}
                    {...{
                        filters,
                        loading,
                        loadingItem,
                        onAddAuthNumber,
                        onEditAuthNumber,
                        onDeleteAuthNumber,
                        onPickDeleteNumber,
                        getAuthNumbers,
                        service_plan_list,
                        permission
                    }}
                />
                {showDeleteModal && showDeleteModal.key && <ModalEdit show={showDeleteModal.show}
                            onClose={() => this.setState({showDeleteModal: false, key: null})}
                            onSuccess={() => {
                                this.onDeleteAuthNumber(showDeleteModal.key);
                                onDeletePostFunction && onDeletePostFunction();
                            }}
                            title={`Delete auth number`}
                            width={400}
                >
                    Are you sure about that?
                </ModalEdit>
                }
            </PanelLayout>
        );
    }
}

const mapState = ({auth_numbers, references, auth}) => ({
    loading: auth_numbers.loading,
    auth_number_list: auth_numbers.auth_number_list,
    auth_number_list_setted: auth_numbers.auth_number_list_setted,
    service_plan_list: references.service_plan_list,
    loadingItem: auth_numbers.loadingItem,
    permissions: auth.permissions
});

export default connect(mapState, {
    getAuthNumbers,
    editAuthNumber,
    deleteAuthNumber,
    addAuthNumber,
    addUploadAuthNumbers,
    setAnyLoading
})(AuthNumbers);

const StyledFlexboxGrid = styled(FlexboxGrid)`
  width: 100%;
  ${props => props.width && css`
      width: ${props.width};
  `}
`;
const StyledFlexboxGridItem = styled(FlexboxGrid.Item)`
  width: 100%;
  text-align: ${props => props.textAlign || 'left'};
  ${props => props.width && css`
      width: ${props.width};
  `}
`;
const StyledUploader = styled(Uploader)`
  button.rs-btn {
    background: #20BA88;
    border: none;
    color: #fff;
    box-shadow: 0 4px 4px rgba(0,0,0,0.16);
    padding: 12px 34px;
    border-radius: 5px;
  }
  button.rs-btn i.rs-icon {
    color: #fff;
  }
 
  button.rs-btn:hover i.rs-icon {
    color: #c1c1c1;
  }
`;