import {AUTH_NUMBERS as PREFIX} from '../const'
import {
    AUTH_NUMBERS_ADD,
    AUTH_NUMBERS_ADD_UPLOAD,
    AUTH_NUMBERS_DELETE,
    AUTH_NUMBERS_EDIT,
    AUTH_NUMBERS_GET_LIST
} from '../const/apiMethods';

import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

const setAuthNumbers = createAction('SET_ITEMS_'+PREFIX);
const setLoading = createAction('SET_LOADING_'+PREFIX);
const setLoadingItem = createAction('SET_LOADING_ITEM_'+PREFIX);
const removeItem = createAction('REMOVE_ITEM_'+PREFIX);

export const getAuthNumbers = (filters) => (dispatch) => {
    dispatch(setLoading(true));
    api(AUTH_NUMBERS_GET_LIST, {filter: filters}).then( ({ auth_number_list = [] }) => {
        dispatch(setAuthNumbers(auth_number_list || []));
    })
};

export const editAuthNumber = (numberId, formData) => (dispatch) => {
    dispatch(setLoadingItem(true));
    api(AUTH_NUMBERS_EDIT, {an_key: numberId, number: formData.number, description: formData.description, sp_key: formData.sp_key}).then( () => {
        setLoadingItem(false);
    })
};

export const deleteAuthNumber = (id) => (dispatch) => {
    dispatch(setLoadingItem(true));
    api(AUTH_NUMBERS_DELETE, {an_key: id}).then( () => {
        setLoadingItem(false);
    })
};

export const addAuthNumber = (data, onSuccess) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await api(AUTH_NUMBERS_ADD, {number: data.number, description: data.description, sp_key: data.sp_key}, true);

        if (response && response.auth_number)  {
            onSuccess();
            setLoading(false);
        }

        return response;
    } catch {
        dispatch(setLoading(false));
    }

};

export const addUploadAuthNumbers = (jsonData, onSuccess) => (dispatch) => {
    dispatch(setLoading(true));
    api(AUTH_NUMBERS_ADD_UPLOAD, {data: jsonData}).then( () => {
        onSuccess();
        setLoading(false);
    })
};

export const setAnyLoading = (payload) => (dispatch) => {
    dispatch(setLoading(payload))
};