import React from 'react';
import JsSIP from 'jssip';
import * as audioPlayer from './audioPlayer';
import Session from './Session';
import styled, {css} from "styled-components";
import {ButtonSecondary} from "../base/BaseButton";
import {Icon} from "rsuite";
import {api} from "../../api/loginRoutes";
import {IVR_LISTEN_API} from "../../const/apiMethods";
import {Alert} from "rsuite";


const DEFAULT_SETTINGS = {
    display_name: "128",
    authorization_user: "128",
    uri: "sip:128@10.0.22.9:5060",
    password: "40S1joiT",

    registrar_server: "10.0.22.9:5060",
    contact_uri: null,
    instance_id: null,
    session_timers: true,
    use_preloaded_route: false,
    socket: {
        uri: "wss://web-call.lexico-telecom.com:8089/ws",
        via_transport: "wss"
    },
    pcConfig: {
        rtcpMuxPolicy: "negotiate",
        iceServers: [
            // {urls: ["stun:stun.l.google.com:19302"]}
        ]
    },
    callstats: {
        enabled: false,
        AppID: null,
        AppSecret: null
    }
};

export default class Phone extends React.Component {
    constructor(props) {
        super(props);

        this._ua = null;
        this.state = {
            loading: false,
            isInCall: false,
            session: null
        };
    }

    fetchIvrRegisterData () {
        const {ivrId} = this.props;

        if (!ivrId) {
            return;
        }

        this.setState({loading: true});
        api(IVR_LISTEN_API, {target: {ivr_id: ivrId}})
            .then(r => {
                if (!r || r.listen.status !== 'ok') {
                    this.setState({loading: true});
                    return;
                }

                const settings = {
                    ...DEFAULT_SETTINGS,
                    display_name: r.listen.login,
                    authorization_user: r.listen.login,
                    uri: `sip:${r.listen.login}@10.0.22.9:5060`,
                    password: r.listen.password,
                };

                this.handleRegister(settings, r.listen.number);
            })
    };

    handleRegister (settings, callUri) {

        const socket = new JsSIP.WebSocketInterface(settings.socket.uri);
        socket['via_transport'] = "wss";

        try {

            this._ua = new JsSIP.UA({
                uri: settings.uri,
                password: settings.password,
                'display_name': settings.display_name,
                sockets: [socket],
                'registrar_server': settings.registrar_server,
                'contact_uri': settings.contact_uri,
                'authorization_user': settings.authorization_user,
                'instance_id': settings.instance_id,
                'session_timers': settings.session_timers,
                'use_preloaded_route': settings.use_preloaded_route
            });

        }
        catch (error) {
            return;
        }

        // event handlers

        // connecting
        this._ua.on('connecting', () => {
            console.log("connecting params", settings);
        });

        // connected
        this._ua.on('connected', () => {
            console.log("connected params", settings);
        });

        // disconnected
        this._ua.on('disconnected', () => {
            console.log("disconnected params", settings);
        });

        // registered
        this._ua.on('registered', () => {
            console.log("registered params", settings);
            this.handleOutgoingCall(settings, callUri);
        });

        // unregistered
        this._ua.on('unregistered', () => {
            this.setState({isInCall: false});
            console.log("unregistered params", settings);
            // Alert.error("unregistered", 10000);
        });

        // registrationFailed
        this._ua.on('registrationFailed', () => {
            this.setState({
                isInCall: false,
                loading: false
            });
            Alert.error("registrationFailed", 10000);
            console.log("registrationFailed params", settings);
        });

        // newSession
        this._ua.on('newSession', () => {
            console.log("newSession", settings);
        });

        this._ua.start();
    }


    handleOutgoingCall(settings, callUri = '111') {

        const session = this._ua.call(callUri, {
            pcConfig: settings.pcConfig || {iceServers: []},
            mediaConstraints: {
                audio: true,
                video: false
            },
            rtcOfferConstraints: {
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 0
            }
        });

        console.log("start session");

        session.on('connecting', () => {
            // this.setState({session});
            console.log("connecting", session);
        });

        session.on('progress', () => {
            audioPlayer.play('ringback');
            console.log("progress", session);
        });

        session.on('failed', (data) => {
            audioPlayer.stop('ringback');
            audioPlayer.play('rejected');
            this.setState({
                session: null,
                loading: false
            });
            console.log("failed log", data);
            Alert.error(`Session failed: ${data.cause}`, 10000);
        });

        session.on('ended', () => {
            audioPlayer.stop('ringback');
            this.setState({
                session: null,
                isInCall: false,
            });
        });

        session.on('accepted', () => {
            audioPlayer.stop('ringback');
            audioPlayer.play('answered');
            this.setState({
                isInCall: true,
                loading: false
            });
        });

        this.setState({session});
    }


    handleStopSession() {
        const {session} = this.state;
        session.terminate();
        this._ua.stop();
    }

    render() {
        const {session, isInCall, loading} = this.state;

        return (
            <>
                <ButtonPlay
                    isInCall={isInCall}
                    loading={loading}
                    onClick={() => {
                        if (session) {
                            this.handleStopSession();
                            return;
                        }
                        this.fetchIvrRegisterData();
                    }}
                >
                    {isInCall
                        ? <Icon icon="pause"/>
                        : <Icon icon="play"/>
                    }
                </ButtonPlay>

                {session &&
                    <Session session={session}/>
                }
            </>
        );
    }
}


const ButtonPlay = styled(ButtonSecondary)`
    && {
        padding: 0 0 0 3px;
        border-radius: 44px;
        height: 44px;
        width: 44px;
        margin-right: 10px;
        
        ${props => props.isInCall && css`
            padding: 0;
        `}
        
        .rs-icon {
            font-size: 18px;
            line-height: 44px;
        }
    }
`;