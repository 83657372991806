import styled, { css } from "styled-components";
import Modal from "../../components/Modal";
import { FormGroup, ControlLabel, FormControl, Button } from "rsuite";
import TextareaAutosize from 'react-textarea-autosize';
import Icon from 'rsuite/es/Icon'
import Form from 'rsuite/es/Form'

export const FormModalWrapper = styled.div`
    &&& {
        overflow: hidden;
    }
    .rs-modal-body {
        overflow: hidden;
    }
    
    .rs-modal-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px 20px 50px 20px;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 30px;
        padding-top: 40px;
    }
    
    .grecaptcha-badge {
        display: none;
 
    }
`;

export const RegistrationSubmitButton = styled(Button)`
  &&& {
        margin-top: 20px;
        margin-left: 10px;
  }
`;

export const RegistrationCancelButton = styled(Button)`
    margin-top: 20px;
    margin-left: 10px;
`;

export const RegistrationButtonWrapper = styled.div`

`;

export const FormModal = styled(Modal)`
    &&& {
        margin-bottom: 40px !important;
        overflow: hidden;
    }
    .rs-modal-body {
        overflow: hidden;
    }
    .rs-modal-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .grecaptcha-badge {
        right: -30%;
        position: absolute;
        box-shadow: none;
        border: none;
    }
`;

export const CommentIcon = styled(Icon)`
    margin-left: 10px;
`;

export const GroupExtendedComment = styled(FormGroup)`
    &&& {
        .extended {
          width: 100%;
        }
        .extended .rs-form-control-wrapper {
          padding-left: 10px;
        }
    }
    margin-bottom: 15px;
    
    @media (min-width: 768px) {
        align-items: center;
        display: flex;
    }
`;

export const Group = styled(FormGroup)`
    //margin-bottom: 15px;
    
    @media (min-width: 768px) {
        align-items: center;
        display: flex;
    }
`;

export const Label = styled(ControlLabel)`
    flex-shrink: 0;
    font-weight: 600;
    padding-right: 20px;
    text-align: left;
    width: auto;

    .rtl & {
        padding-left: 20px;
        padding-right: 0;
        text-align: right;
    }
    
    && {
        
        @media (min-width: 768px) {
            margin-bottom: 0;
        }
    }
`;

export const Field = styled(FormControl)`
    &&& {
        min-width: 100%;
        width: 100%;
    } 
`;
export const FieldNumber = styled(FormControl)`
  .rs-input-number.without-buttons .rs-input-number-btn-group-vertical {
    display: none;
  }
`;
export const GroupNumber = styled(Group)`
`;
export const FieldTextAreaAutoResize = styled(TextareaAutosize)`
    &&& {
        min-width: 100%;
        width: 100%;
        overflow: hidden;
        resize: none;
    } 
`;

export const LabelWrapper = styled.div`
    flex-shrink: 0;
    width: 160px;
    
    ${props => props.vAlign &&
        css`
            align-self: ${props.vAlign};
        `
    }

    ${props => props.top &&
        css`
            margin-top: ${props.top}px;
        `
    }
`;

export const FormColumns = styled.div`
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
`;

export const FormColumn = styled.div`
    flex: 0 0 auto;
    padding: 0 20px;
    position: relative;
    width: 100%;

    ${props => 
        props.flexGrow && css`
            flex-grow: ${props.flexGrow};
        `
    }

    @media (min-width: 600px) {
        flex-grow: 1;
        width: 1px;
        
        &&& {
            .rs-form-control-wrapper {
                max-width: 450px;
                min-width: 1px;
                width: 100%;
            }
        }
    }
`;

export const FlexBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const FlexChild = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;