import React, {useContext, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components';
import m from "../../definedMessages";
import {Whisper, Tooltip, Icon, Divider} from "rsuite";
import {useIntl} from "react-intl";
import _ from "lodash";
import Badge from 'rsuite/es/Badge'
import {Link} from 'react-router-dom'
import {RtlContext} from "../../App";
import {LOCAL_STORAGE_CHECKED_PAYMENT_STATUS} from '../../const/localStorageKeys'

export default ({
    dialerInfo = {},
    realtimePaymentStatus,
    readyForPayment,
    isMobile = false
}) => {

    const intl = useIntl();
    const rtl = useContext(RtlContext);

    const accountInfo = useMemo(() => {
        return _.get(dialerInfo, 'session.account', {});
    }, [dialerInfo]);

    const currencyIcon = useMemo(() => (
        dialerInfo && dialerInfo.currency_name
            ? dialerInfo.currency_name.toLowerCase()
            : "usd"
    ), [dialerInfo]);

    const data = useMemo(() => {
        const accountInfo = _.get(dialerInfo, 'session.account', {});
        const data = [
            {
                id: 'balance',
                value: dialerInfo.BALANCE ? `${dialerInfo.BALANCE} ${dialerInfo.currency_name}` : 0,
                label: intl.formatMessage(m.pendingAmount),
            },
            {
                id: 'balance_inv',
                value: readyForPayment ? `${dialerInfo.BALANCE_INV} ${dialerInfo.currency_name}` : 0,
                label: intl.formatMessage(m.readyForPayment),
            },
            {
                id: 'payment_terms',
                value: dialerInfo.payment_terms,
                label: intl.formatMessage(m.paymentTerms),
            },
            {
                id: 'traffic',
                value: `${dialerInfo.VOLUME_1_OUT || 0}/${dialerInfo.VOLUME_7_OUT || 0}/${dialerInfo.VOLUME_30_OUT || 0} ${dialerInfo.currency_name || 'currency'}`,
                label: intl.formatMessage(m.traffic),
                hideOnTest: true,
                hideOnMobile: true
            },
            {
                id: 'allocated_numbers',
                value: dialerInfo.allocated_numbers || 0,
                label: intl.formatMessage(m.allocatedNumbers),
                hideOnMobile: true
            },
            // {
            //     id: 'allocated_ranges',
            //     value: dialerInfo.allocated_ranges || 0,
            //     label: intl.formatMessage(m.ranges),
            //     hideOnMobile: true
            // },
        ];

        return data.filter(item => (
            !((accountInfo.isTest && item.hideOnTest) || (isMobile && item.hideOnMobile) ))
        )
    }, [dialerInfo, readyForPayment, isMobile]);

    useEffect(() => {
        if (paymentStatusCountState !== realtimePaymentStatus) {
            localStorage.setItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS, "false");
            onChangePaymentStatusCount(realtimePaymentStatus)
        }
    }, [realtimePaymentStatus])

    const [paymentStatusCountState, onChangePaymentStatusCount] = useState(realtimePaymentStatus);
    const paymentStatusHasChecked = localStorage.getItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS) ? localStorage.getItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS) : false;
    const paymentStatusCount = realtimePaymentStatus < 99 ? realtimePaymentStatus : 99;

    return (
        <Wrapper>
            <Whisper
                enterable
                placement={rtl ? "bottomStart" : "bottomEnd"}
                trigger={isMobile ? "click" : "hover"} // "hover" on devices is bad
                speaker={
                    <StyledTooltip>

                        {data.map(item => (
                            <div key={item.id}>
                                {item.id === "allocated_numbers" && !accountInfo.isTest &&
                                    <StyledDivider />
                                }
                                <StyledItem>{`${item.label}: ${item.value}`}</StyledItem>
                            </div>
                        ))}

                    </StyledTooltip>
                }
            >
                <div>
                    <StyledIcon size="lg" icon={currencyIcon}/>
                    { realtimePaymentStatus > 0 && !JSON.parse(paymentStatusHasChecked) &&
                        <StyledPaymentLink to="/payments" onClick={() => {
                            localStorage.setItem(LOCAL_STORAGE_CHECKED_PAYMENT_STATUS, "true")
                        }}>
                            <div>{paymentStatusCount}</div>
                        </StyledPaymentLink>
                    }
                </div>
            </Whisper>
        </Wrapper>
    )
}


const Wrapper = styled.li`
    display: inline-block;
    margin-right: 20px;
    margin-top: 12px;

    .rtl & {
        margin-right: 0;
        margin-left: 20px;
    }
`;

const StyledPaymentLink = styled(Link)`
    position: absolute;
    background-color: red;
    border-radius: 10px;
    margin-left: -4px;
    height: 14px;
    text-decoration: none;

    div {
        text-decoration: none;
        color: white !important;
        font-weight: 700;
        padding: 4px 3px;
        border: 0;
        line-height: 5px;
        font-size: 13px;
        display: block;
        position: relative;
    }
`;

const StyledTooltip = styled(Tooltip)`
    && .rs-tooltip-inner {
        padding-bottom: 5px;
        padding-top: 5px;
    }
`;

const StyledIcon = styled(Icon)`
    padding: 5px 5px;
`;

const StyledItem = styled.div`
    text-align: left;

    .rtl & {
        text-align: right;
    }
`;

const StyledDivider = styled(Divider)`
    && {
        margin-bottom: 6px;
        margin-top: 6px;
    }    
`;