import {api} from '../api/loginRoutes';
import {createAction} from './defaults';
import {GLOBAL_SETTING_SET_LOADING, GLOBAL_SETTING_SET_LIST} from './actionTypes';
import {
    GLOBAL_SETTING_GET_LIST_API, GLOBAL_SETTING_CREATE_API, GLOBAL_SETTING_MODIFY_API} from '../const/apiMethods';

const setLoading = createAction(GLOBAL_SETTING_SET_LOADING);
const setSettingList = createAction(GLOBAL_SETTING_SET_LIST);

export const getSettingList = () => (dispatch) => {
    dispatch(setLoading(true));

    api(GLOBAL_SETTING_GET_LIST_API, {})
        .then((response) => {
            dispatch(setSettingList({...response}));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const onEditSettingItem = (data) => async (dispatch) => {
    return await api(GLOBAL_SETTING_MODIFY_API, {...data})
};
