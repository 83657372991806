import React, {useState} from "react";
import Modal from '../../../components/Modal';
import {useWindowWidth} from "../../../hooks";
import PaymentDetails from "../../PaymentDetails";
import { Spacer } from "../../../components/base/Spacer";

export default ({
    accountId,
    show,
    onClose
}) => {

    const [allowGetList, setAllowGetList] = useState(false);

    return (
        <Modal
            {...{show, onClose}}
            title="Payment details"
            width={'90%'}
            overflow={false}
            onEntered={() => {
                setAllowGetList(true);
            }}
            onClose={() => {
                onClose(false);
                setAllowGetList(false);
            }}
        >
            <PaymentDetails allowGetList={allowGetList} accountId={accountId}/>
            <Spacer/>
        </Modal>
    )
}