import {
    API_PAGE as PREFIX,
    API_MODIFY_KEY,
    API_DELETE_KEY, API_CREATE_KEY
} from '../const';

const initialState = {
    items: [],
    count: 0
};

const handlers = {
    ['SET_ITEMS_' + PREFIX]: (state, {payload: {keyList}}) => ({
        ...state,
        items: keyList,
        count: keyList.length,
    }),
    [API_CREATE_KEY]: (state, {payload: {apiKey}}) => ({...state}),
    [API_MODIFY_KEY]: (state, {payload: {apiKey}}) => ({...state}),
    [API_DELETE_KEY]: (state, {payload: {apiKey}}) => ({...state}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}