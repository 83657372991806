import {
    SIM_TRAFFIC_RANGES_SET_LIST,
    SIM_TRAFFIC_RANGES_SET_LOADING
} from "../actions/actionTypes";
import {SIM_TRAFFIC_RANGES_GET_POSSIBLE_LIST} from 'const/apiMethods';

const initialState = {
    loading: false,
    list: [],
    codeList: [],
    count: 0,
    perPage: 10000,
    page: 1
};


const handlers = {
    [SIM_TRAFFIC_RANGES_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [SIM_TRAFFIC_RANGES_SET_LIST]: (state, {payload}) => ({...state, list: payload.price_range_priority_list, count: payload.price_range_priority_count, page: payload.page, per_page: payload.per_page, loading: false}),
    [SIM_TRAFFIC_RANGES_GET_POSSIBLE_LIST]: (state, {payload}) => ({...state, codeList: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
