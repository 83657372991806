import React, {useState} from 'react';
import CustomField from './CustomField';
import {InputNumber} from 'rsuite';
import {NumberField} from "./NumberField";
import {MIN_RATE_VALUE} from "const";

const RateField = (props) => {
    return (
        <NumberField
            accepter={InputNumber}
            max={10}
            min={MIN_RATE_VALUE}
            step={0.0001}
            placeholder="0.00"

            {...props}
            className='without-buttons'
        />
    )
};

export default RateField;















