import {DROPDOWN_ACCOUNTS as PREFIX} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';

const setAccounts = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);

export const getDropdownAccounts = (filters = null) => (dispatch) => {
    dispatch(setLoading(true));

    api('account:get_dropdown_list', {
        filter: filters || {}
    }).then(({account_list = []}) => {
        dispatch( setAccounts({
            items: account_list,
            filter: filters
        }) );

    }).finally(() => dispatch(setLoading(false)));
};