import React from 'react';
import Table from '../../../components/Table';
import {ButtonPrimary as Add, ButtonRowAllocate, ButtonRowDelete, ButtonRowDownload, ButtonRowEdit, ButtonRowRevoke} from '../../../components/base/BaseButton';
import {Checkbox, FlexboxGrid, Table as _Table} from 'rsuite';
import TrunkModal from './TrunkModal/TrunkModal';
import {getFileResponse} from '../../../api/loginRoutes';
import {saveFileBlob} from '../../../utils/helpers';
import {Header, HeaderTrunkActions} from './styled';
import RevokeTrunkModal from './RevokeTrunkModal';
import RemoveTrunkModal from './RemoveTrunkModal';
import {canIMoveNumbersToAnotherTrunk} from 'utils';
import styled from "styled-components";
import {Spacer} from "../../../components/base/Spacer";

const {Column, HeaderCell, Cell} = _Table;

const columns = [
    {
        label: 'Name',
        dataKey: 'name',
        minWidth: 96,
    },
    {
        label: 'Type',
        dataKey: 'type',
        minWidth: 96,
    },
    {
        label: 'Price',
        dataKey: 'service_plan_name',
        minWidth: 96,
    },
    {
        id: 'allocated_numbers',
        label: 'Allocated Numbers',
        value: (({allocated_numbers, numbers_with_traffic}) => (
            `${allocated_numbers||0}/${numbers_with_traffic||0}`)
        ),
        align: 'center',
        minWidth: 192,
        flexGrow: 2
    },
    {
        label: 'Start Date',
        dataKey: 'start_date',
        formatData: 'date',
        align: 'right',
        minWidth: 96,
    },
    {
        label: 'End Date',
        dataKey: 'end_date',
        value: ({end_date}) => {
            if (!end_date)
                return "";

            const endDate = new Date(end_date);
            const endDateObg = new Date( 
                endDate.getFullYear(), 
                endDate.getMonth(), 
                endDate.getDate(), 
                endDate.getHours(), 
                endDate.getMinutes(), 
                endDate.getSeconds() - 1 
            );

            return new Date(endDateObg).toLocaleDateString();
        },
        formatData: 'date',
        align: 'right',
        minWidth: 96,
    },
];

const mobileColumns = [
    {
        id: 'name',
        label: 'Name',
        value: (({name, type, service_plan_name}) => (
                <div className={"table-two-staged__cell"}>
                    <div className="table-two-staged__cell-first">{name}</div>
                    <div className="table-two-staged__cell-second">{`${type}, ${service_plan_name}`}</div>
                </div>
            )
        ),
        minWidth: 150,
        flexGrow: 1
    },
];


export default class extends React.Component {
    dataTrunk = {};
    currentTop = 0;
    state = {
        data: [],
        showModal: false,
        showRemoveTrunkModal: false,
        showRevokeTrunkModal: false
    };

    componentWillUnmount() {
        const {setCurrentTrunkId} = this.props;
        setCurrentTrunkId(null)
    }

    componentDidUpdate({trunks, closedTrunksShow}) {
        const {currentTrunkId} = this.props;
        const columnHeight = 30;

        if (trunks !== this.props.trunks || closedTrunksShow !== this.props.closedTrunksShow) {
            const trunksList = this.props.trunks.filter((trunk) => {
                return this.props.closedTrunksShow ? true : !trunk.closed
            });
            let top;
            if (!currentTrunkId)
                top = Math.abs(this.currentTop);
            else {
                top =  Math.abs((trunksList.map(trunk => trunk.id).indexOf(currentTrunkId) || 0) * columnHeight);
            }

            this.currentTop = top;

            if (!this.props.currentTrunkId && trunksList.length) {
                this.props.setCurrentTrunkId(trunksList[0].id)
            }
            this.setState({data: trunksList});
        }
    }


    onClickTrunk = (id) => {
        const {setTrunk, setCurrentRangeNumber} = this.props;
        setTrunk(id);
        setCurrentRangeNumber(null)
    };


    showTrunk = () => {
        this.dataTrunk = {};
        this.setState({showModal: true});
    };


    onDownloadNumbers = async (id) => {
        this.setState(({data, ...state}) => ({
            ...state,
            data: data.map(
                trunk => ({
                    ...trunk,
                    downloadLoading: trunk.id === id ? true : trunk.downloadLoading
                })
            )
        }));

        const {acc_key: account_id, trunk_id} = this.props;
        const result = await getFileResponse('trunk_number:get_list', {
            target: {trunk_id: id} //  trunk_id => id
        });

        if (result)
            saveFileBlob(result, 'allocated_numbers.csv');

        this.setState(({data, ...state}) => ({
            ...state,
            data: data.map(
                trunk => ({
                    ...trunk,
                    downloadLoading: trunk.id === id ? false : trunk.downloadLoading
                })
            )
        }));
    };


    onEditTrunk = (rowData) => {
        const endDate = rowData.end_date ? new Date(rowData.end_date) : null;

        this.dataTrunk = {
            ...rowData,
            end_date: endDate 
                ? new Date( 
                    endDate.getFullYear(), 
                    endDate.getMonth(), 
                    endDate.getDate(), 
                    endDate.getHours(), 
                    endDate.getMinutes(), 
                    endDate.getSeconds() - 1 
                )
                : null
        };
        this.setState({showModal: true});
    };


    onRevokeTrunk = () => {
        this.setState({showRevokeTrunkModal: true})
    };


    onRemoveTrunk = (rowData) => {
        this.dataTrunk = {...rowData};
        this.setState({showRemoveTrunkModal: true});
    };

    render() {

        const {
            onAllocateNumbers, account_name, trunks, trunksLoading, isMobile, setClosedTrunksShow,
            allocatedNumbersLoading, trunk_id, getTrunks, acc_key,
        } = this.props;

        const {data, showModal, showRevokeTrunkModal, showRemoveTrunkModal} = this.state;

        const transformedData = data.map(item => {
            return {
                ...item,
                start_date: item.start_date ? item.start_date.replace(" ", "T") : "",
                end_date: item.end_date ? item.end_date.replace(" ", "T") : "",
            }
        });

        const currentTrunk = this.props.trunks.find((trunk) => trunk.id === trunk_id);

        return (
            <div>
                <Spacer size={10}/>
                <FlexboxGrid align={"middle"}>
                    {!isMobile &&
                        <FlexboxGrid.Item>
                            <Header>Trunks</Header>
                        </FlexboxGrid.Item>
                    }
                    <FlexboxGrid.Item  style={{marginLeft: 'auto'}}>
                        <FlexboxGrid align={"middle"}>

                            {!isMobile &&
                                <FlexboxGrid.Item>
                                    <StyledFilter>
                                        <Checkbox onClick={setClosedTrunksShow}/>
                                        <span>Show closed trunks</span>
                                    </StyledFilter>
                                </FlexboxGrid.Item>
                            }

                            <FlexboxGrid.Item>
                                <HeaderTrunkActions>
                                    <Add onClick={this.showTrunk}>+Add New Trunk</Add>
                                </HeaderTrunkActions>
                            </FlexboxGrid.Item>

                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

                {showModal &&
                    <TrunkModal
                        initialValues={this.dataTrunk}
                        account_id={acc_key}
                        account_name={account_name}
                        update={getTrunks}
                        trunksList={trunks}
                        onClose={() => this.setState({showModal: false})}
                    />
                }

                <Table
                    shouldUpdateScroll={false}
                    rowHeight={!isMobile ? 30 : 50}
                    scrollTopValue={this.currentTop}
                    data={transformedData}
                    loading={trunksLoading || allocatedNumbersLoading}
                    columns={!isMobile ? columns : mobileColumns}
                    height="25%"
                    active_id={trunk_id}
                    onRowClick={this.onClickTrunk}
                    rowClassName={(rowData) => rowData && rowData.closed
                        ? 'closed-row'
                        : (rowData && !rowData.active) ? 'row-disabled' : ''
                    }
                    ActionCell={ActionCell(
                        this.onEditTrunk,
                        this.onDownloadNumbers,
                        onAllocateNumbers,
                        this.onRevokeTrunk,
                        this.onRemoveTrunk,
                    )}
                />

                {showRevokeTrunkModal &&
                    <RevokeTrunkModal
                        account_id={acc_key}
                        trunk_id={trunk_id}
                        onClose={() => this.setState({showRevokeTrunkModal: false})}
                        update={getTrunks}
                    />
                }

                {showRemoveTrunkModal &&
                    <RemoveTrunkModal
                        account_id={acc_key}
                        trunk_id={trunk_id}
                        data={this.dataTrunk}
                        trunkList={data.filter(trunk => canIMoveNumbersToAnotherTrunk(currentTrunk, trunk))}
                        onClose={() => this.setState({showRemoveTrunkModal: false})}
                        update={getTrunks}
                    />
                }

            </div>
        );
    }
}

const ActionCell = (edit, download, allocate, revoke, remove) => (
    <Column width={218}>
        <HeaderCell>Options</HeaderCell>
        <Cell>
            {rowData => (
                <StyledActionCell>
                    <ButtonRowEdit onClick={() => edit(rowData)}/>
                    <ButtonRowDownload loading={rowData.downloadLoading} onClick={() => download(rowData.id)}/>
                    <ButtonRowAllocate disabled={rowData.closed || !rowData.active} onClick={() => allocate(rowData.id)}/>
                    <ButtonRowRevoke onClick={() => revoke(rowData.id)}/>
                    <ButtonRowDelete onClick={() => remove(rowData)}/>
                </StyledActionCell>
            )}
        </Cell>
    </Column>
);


const StyledFilter = styled.div.attrs(props => ({
    className: "",
}))`
    align-items: center !important;
    display: flex !important;
    padding-right: 20px;
`;

const StyledActionCell = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;