import React from 'react';
import {ApiRequest, API_Modal} from '../../components';
import { Alert } from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonClose} from "../../components/base/BaseButton";

export default ({target, onUpdate}) => {
    const [show, setShow] = React.useState(false);

    return (
        <>
            <ButtonClose onClick={() => setShow(true)}><Icon icon="lock" style={{marginRight: 5}}/>Close Account</ButtonClose>
            { show && 
                <API_Modal
                    title="Close Account"
                    onClose={() => setShow(false)}

                >
                    <ApiRequest
                        method="account:remove"
                        target={target}
                        checkResultKey="account"
                        update={() => {
                            onUpdate();
                            Alert.success(`Account was closed`)
                        }}
                    >
                        <p>You are sure?</p>
                        <p>All numbers will revoke and all trunks remove</p>
                    </ApiRequest>   
                </API_Modal>}
        </>
    );
}