import React, {useState} from 'react';
import {
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    InputNumber,
    Schema
} from 'rsuite';
import Modal from '../../components/Modal';
import CustomField from '../../components/client/Form/CustomField/CustomField';
import {useIntl} from "react-intl";
import Checkbox from 'rsuite/es/Checkbox';
import {ALLOCATION_SUBDESTINATION_DIALER_API} from "../../const/apiMethods";
import {useWindowWidth} from "../../hooks";
import styled, {css} from 'styled-components';
import {SCREEN_MEDIA} from "../../const";
import TrunksList from '../../components/TrunksList/TrunksList';
import m from "../../definedMessages";
import {CustomModalClient} from "../../components/base";
import {BaseCheckboxField, BaseInputNumberField} from "../../components/base/BaseForm";

const {md} = SCREEN_MEDIA;
const {StringType, NumberType} = Schema.Types;


export default (
    {
        show,
        onClose,
        onSend,
        trunks,
        selectedData,
        maxWidth = 600,
        loading,

        randomNumber = false,
        onChangeRandomNumber,

        formValue,
        onChangeFormValue,
        allocationLimit,
        ...props
    }
) => {
    const {formatMessage} = useIntl();

    const MIN_NUMBER = 1;
    const MAX_NUMBER = allocationLimit && allocationLimit.subdestination ? allocationLimit.subdestination.once : allocationLimit && allocationLimit.other && allocationLimit.other.once || 1000;

    const formSchema = Schema.Model({
        trunk_id: StringType()
            .isRequired(formatMessage(m.thisFieldIsRequired)),
        numbers: NumberType(formatMessage(m.pleaseEnterValidNumber))
            .min(MIN_NUMBER, formatMessage(m.minNumber, {number: MIN_NUMBER}))
            .max(MAX_NUMBER, formatMessage(m.maxNumber, {number: MAX_NUMBER}))
            .isRequired(formatMessage(m.thisFieldIsRequired)),
    });

    let form;
    const resizedWidth = useWindowWidth();

    const [formError, onChangeFormError] = useState({});
    // const [formValue, onChangeFormValue] = useState({trunk_id: null, numbers: 1});
    // const [randomNumber, onChangeRandomNumber] = useState(false);
    const [trunkId, onChangeTrunkId] = useState(null);

    const onSubmit = async () => {
        const data = form.getFormValue();
        const response = await onSend({...data,  numbers: Number(data.numbers), random_order: randomNumber});

        if (!response)
            return;

        if (response && response.status === 0) {
            clearForm();
        } else {
            const trunk = trunks.filter( trunk => trunk.id === trunkId );
            props.setReasonModalProps({
                reasonCode: response.reason_code,
                isRandom: randomNumber,
                method: ALLOCATION_SUBDESTINATION_DIALER_API,
                subDest: selectedData.b_subdestination_name,
                trunk: trunk[0],
                changeRandom: onChangeRandomNumber,
                numbersAmount: formValue.numbers,
                reasonHash: response.reason_hash,
            });
        }
    };

    const checkForm = (form, formSchema, formData, formError) => {
        if (!form.check()) {
            return true;
        } else {
            const checker = Object.keys(formSchema.check(formData)).map(i => formSchema.check(formData)[i].hasError).every(value => value === false) &&
                !(formData.hasOwnProperty('name') && formData.hasOwnProperty('numbers'));
            if (checker) {
                onSubmit();
                return false;
            }
        }
        return true;
    };

    const trunkList = trunks 
        ? trunks.filter(value => value.sp_key === selectedData.sp_key && !value.closed && !value.option_google_otp)
        : {};

    const clearForm = () => {
        onChangeFormValue({trunk_id: null, numbers: 1});
        onChangeRandomNumber(false);
    };

    return (
        <ModalForm
            show={show}
            confirmButtonText={formatMessage(m.getNumbers)}
            onConfirm={() => {
                checkForm(form, formSchema, formValue, formError);
                //    onChangeFormValue({trunk_id: '', numbers: 1});
            }}
            onClose={() => {
                onClose(false);
                clearForm();
            }}
            title={formatMessage(m.getNumbersForDestination, {name: selectedData.b_subdestination_name})}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            disabled={trunkId === null}
            loading={loading}
        >
            <Form
                layout="horizontal"
                ref={ref => (form = ref)}
                model={formSchema}
                onChange={formValue => {
                    onChangeFormValue(formValue);
                }}
                onCheck={formError => {
                    onChangeFormError(formError);
                }}
                formValue={formValue}
            >
                <FormParts>
                    <FormPart>

                        <FormGroup>
                            <StyledTrunkField>
                                <CustomField
                                    accepter={TrunksList}
                                    name='trunk_id'
                                    data={trunkList}
                                    onChange={(value) => onChangeTrunkId(value)}
                                />
                            </StyledTrunkField>
                        </FormGroup>

                    </FormPart>
                    <FormPart>

                        <FormGroup>
                            <FlexBox>
                                <FormLabel>{formatMessage(m.numbers)}</FormLabel>
                                <FormFieldWrapper width="80px">
                                    <FormControl
                                        accepter={BaseInputNumberField}
                                        max={MAX_NUMBER}
                                        min={MIN_NUMBER}
                                        name='numbers'
                                    />
                                </FormFieldWrapper>
                            </FlexBox>
                        </FormGroup>

                    </FormPart>

                    <FormPart>

                        <BaseCheckboxField
                            // Форма не подхватывает значение чекбокса, как в реф так и в стейт
                            onChange={(_, checked) => {
                                onChangeRandomNumber(checked)
                            }}
                            defaultChecked={randomNumber}
                            name="random_order"
                            value="random_order"
                        >
                            {formatMessage(m.randomOrder)}
                        </BaseCheckboxField>

                    </FormPart>
                </FormParts>
            </Form>
        </ModalForm>
    );
};


const FlexBox = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;

const FormParts = styled.div`
    display: block;
    margin-bottom: -20px;
    @media (min-width: ${md.min}px) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

const FormPart = styled.div`
    padding-bottom: 20px;

    @media (min-width: ${md.min}px) {
        padding-right: 10px;

        &:last-child {
            padding-right: 0;
        }
    }
`;

const FormLabel = styled(ControlLabel)`
    &&&& {
        float: none;
        margin: 0 0 10px 0;
        padding: 0;
        text-align: left;
        word-wrap: none;

        @media (min-width: ${md.min}px) {
            margin: 0 10px 0 0;
            max-width: 130px;
            width: auto;
        }
    }
`;

const FormFieldWrapper = styled.div`
    width: 100%;

    {${props => 
        props.width &&
        css`
            @media (min-width: ${md.min}px) {
                width: ${props.width}
            }
        `
    }
`;

const StyledTrunkField = styled.div`
    width: 100%;

    @media (min-width: ${md.min}px) {
        width: 200px;
    }
`;

export const ModalForm = styled(CustomModalClient)`
    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;