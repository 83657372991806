import {
    GLOBAL_SETTING_SET_LIST,
    GLOBAL_SETTING_SET_LOADING
} from "../actions/actionTypes";

const initialState = {
    loading: false,
    list: [],
    count: 0
};


const handlers = {
    [GLOBAL_SETTING_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [GLOBAL_SETTING_SET_LIST]: (state, {payload}) => ({...state, list: payload.global_setting_list, count: payload.global_setting_count, loading: false}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
