import {SPECIAL_RATES} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';


const setLoading = createAction('SET_LOADING_'+SPECIAL_RATES);
const setRates = createAction(SPECIAL_RATES);

export const getRates = (account_id, filter,  page = 1 ,per_page= 10000) => (dispatch, getStore) => {
    const store = getStore();

    filter = filter || {sp_key: store.references.defaultSPKey};

    dispatch(setLoading(true));

    api('account_price:get_list', {
        filter,
        page,
        per_page,
        target: {account_id}
    })
        .then((r) => {
            if (r === undefined) {
                return;
            }
            dispatch(setRates(r.account_price_list || []))
        })
        .finally(() => dispatch(setLoading(false)));
};
