import React from 'react';
import {getFileResponse} from '../../../api/loginRoutes';
import {saveFileBlob} from '../../../utils/helpers';
import {ButtonSideIcon} from "../../../components/base/BaseButton";

export default ({account_id, filter = {}}) => {
    const [loading, setLoading] = React.useState(false)
    const exportFile = async () => {
        setLoading(true);
        const result = await getFileResponse('account_price:get_list',{
            target: {account_id}, filter
        })
        if(result)
            saveFileBlob(result,'prices.csv')
        setLoading(false)

    }
    return (
        <ButtonSideIcon
            style={{marginTop: 10}}
            onClick={() => exportFile()}
            loading={loading}
            icon="upload2"
        >
            Export price
        </ButtonSideIcon>
    )
};