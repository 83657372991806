import React from "react";
import { Form, Alert } from "rsuite";
import { CheckboxControl } from "components/Form/CheckboxControl";
import * as S from "../styled"
import {modifyAccountRequest} from '../../../actions'

export default ({
    accountId,
    default_allow_hawala,
    defaultValue = {},
    modifyPaymentSettings
}) => {
    const handleChange = async ({allow_hawala, ...value} = {}) => {
        const params = {
            target: {
                account_id: accountId
            },
            allow_hawala: allow_hawala,
            ...(Object.keys(value).length ? {account_options: value} : {}),
        };

        const response = await modifyPaymentSettings(params);
        // const responseAccount = await modifyAccountRequest()

        if (response === undefined) {
            Alert.error('Something went wrong', 15000);
        }

        Alert.success('Payment settings has successfully changed!', 5000);
    };

    if (defaultValue.pause_payouts === undefined) {
        return null;
    }

    return (
        <Form 
            layout="inline"
            defaultFormValue={defaultValue}
            onChange={handleChange}
        >
            <CheckboxControl
                name="pause_payouts"
                defaultChecked={defaultValue.pause_payouts || false}
            >
                Pause all payments
            </CheckboxControl>

            <CheckboxControl
                name="auto_accept_our_stat"
                defaultChecked={defaultValue.auto_accept_our_stat || false}
            >
                Autoaccept our stat as invoice
            </CheckboxControl>

            <CheckboxControl
                name="allow_hawala"
                defaultChecked={default_allow_hawala || false}
            >
                Allow hawala payment request
            </CheckboxControl>
        </Form>
    )
}