import React, {useState} from "react";
import Modal from '../../components/Modal';
import {useIntl} from "react-intl";
import {useWindowWidth} from "../../hooks";
import m from "../../definedMessages";
import {CustomModalClient} from "../../components/base";

export default ({
    show,
    onClose,
    maxWidth = 600,
    selectedItem,
    removePaymentDetails,
    getPaymentDetailsList,
}) => {

    const {formatMessage} = useIntl();

    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const handleSuccess = async () => {
        setLoading(true);

        const data = {
            target: {
                payment_detail_id: (selectedItem || {})['id']
            },
        }

        await removePaymentDetails(data);

        getPaymentDetailsList();
        setLoading(false);
        onClose();
    };

    return (
        <CustomModalClient
            show={show}
            onClose={() => onClose(false)}
            title={formatMessage(m.removePaymentDetail)}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            loading={loading}
            confirmButtonText={formatMessage(m.yes)}
            focusTarget="close"
            onConfirm={handleSuccess}
        >
            {formatMessage(m.areYouSure)}
        </CustomModalClient>
    )
}