import {connect} from 'react-redux';

import {getKeyList, createApiKey, modifyApiKey, deleteApiKey} from 'actions/apiPage';

import ApiPage from 'pages/Account/ApiPage/ApiPage';
import React from 'react';
class ApiPageContainer extends React.Component {

    render() {
        const {keyList, getKeyList, deleteApiKey, modifyApiKey, createApiKey, account_id} = this.props;
        return (
            <>
                <ApiPage
                    {...{
                        deleteApiKey, modifyApiKey, createApiKey, getKeyList, keyList, account_id
                    }}
                />
            </>
        );
    }
}

const mapState = ({apiPage}) => ({
    keyList: apiPage.items.sort((a, b) => a.api_key > b.api_key),
    count: apiPage.count
});

export default connect(mapState,
    {
        getKeyList,
        createApiKey,
        modifyApiKey,
        deleteApiKey
    })(ApiPageContainer);