import React from 'react';
import Modal from '../../../components/Modal';
import { api } from '../../../api/loginRoutes';
import { Alert } from 'rsuite';
import {ButtonPrimary} from "../../../components/base/BaseButton";

export default function SetOrUnsetTest({getList, disabled, update, isSetTest}) {
    const [show, setShow ] = React.useState(false);
    const [_disabled, setDisabled ] = React.useState(false);

    const method = isSetTest ? 'price_range_number:set_test_number_list' : 'price_range_number:unset_test_number_list';

    const onSubmit = async () => {
        const numbersList = getList().list;

        setDisabled(true);

        const result = await api(method, {prn_key_list: numbersList});

        if (result.price_range_numbers === numbersList.length) {
            Alert.success(isSetTest
                ? `Setted ${result.price_range_numbers} numbers`
                : `Unsetted ${result.price_range_numbers} numbers`
            );
        } else {
            if (result.price_range_numbers) {
                Alert.warning(
                    isSetTest
                        ? `Setted ${result.price_range_numbers} numbers, but some weren't setted because allocated numbers can't setted as test`
                        : `Unsetted ${result.price_range_numbers} numbers, but some weren't unsetted because not test numbers can't unsetted`
                );
            } else {
                Alert.error(
                    isSetTest
                        ? `Allocated numbers can't be setted as test`
                        : `Not tested numbers can't be unset`
                );
            }
        }

        setShow(false);
        update();

        setDisabled(false)
    };

    return (
        <>
            <ButtonPrimary disabled={disabled} onClick={() => setShow(true)}>
                {isSetTest ? 'Set test' : 'Unset test'}
            </ButtonPrimary>

            {show &&
                <Modal
                    show={show}
                    title={isSetTest
                        ? 'Set test numbers'
                        : 'Unset test numbers'
                    }
                    onClose={() => setShow(false)}
                    footer={true}
                    successText="Confirm"
                    disabled={_disabled}
                    onSuccess={onSubmit}
                >
                    {isSetTest
                        ? `Selected numbers will be set as test. 
                           Pay your attention - if they are allocated to any dialer they will be revoked from them too.`
                        : `Selected numbers will be unset as test?`
                    }
                </Modal>
            }
        </>
        
    );
}