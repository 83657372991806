import {DEFAULT_PER_PAGE_SMALL, PRICE_RANGES, SET_RANGES} from '../const/';
import {api} from '../api/loginRoutes';
import {createAction} from  './defaults';
import {DESC_SORT} from "const";

const setLoading = createAction('SET_LOADING_'+PRICE_RANGES);
export const setRanges = createAction(SET_RANGES);

export const getRanges = (filter, page = 1, per_page = DEFAULT_PER_PAGE_SMALL, sort = {}) => (dispatch) => {
    dispatch(setLoading(true));

    // delete filter.sp_key;

    let sort1, sort1_desc;

    if (sort.column) {
        sort1 = [sort.column];
        sort1_desc = sort.type && sort.type === DESC_SORT;
    }

    return api(PRICE_RANGES, {filter, page, per_page,
        add: {trunk_numbers: {}},
        sort1, sort1_desc
    }).then( ({price_range_list, price_range_count}) => {
        localStorage.setItem(PRICE_RANGES+'_per_page', per_page)
        dispatch(setRanges({
            items: (price_range_list || []).map(
                item => {
                    item.allocated  = item.trunk_numbers;
                    item.test_number  = '';
                    // item.numbers = (item.allocated_numbers || '0') + '/' + (item.all_numbers || '0')
                    if(item.test_trunk_number_list && item.test_trunk_number_list.length){
                        item.test_number = item.test_trunk_number_list[0];
                        item.test_trunk_number_list[1] &&  (item.test_number +=  ' ...')
                    }
                    return item;
                }
            ),
            count: price_range_count || 0,
            page,
            per_page
        }));
    })
        .finally(() => dispatch(setLoading(false)));
};
