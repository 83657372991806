import styled, { css } from "styled-components";
import {Table as Table_, FormControl, ControlLabel, FormGroup as FormGroup_} from "rsuite";
import Modal from "../../components/Modal/Modal";
import {ButtonRowClient, ButtonToolbar} from "../../components/base/BaseButton";
import BaseTable from "../../components/base/BaseTable";
import {CustomModalClient} from "../../components/base";

export const Table = styled(BaseTable).attrs(props => ({
    height: 150,
    rowHeight: 30,
    headerHeight: 30
}))``;

export const FlexBlock = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;

export const SectionTitle = styled.h2`
    flex-grow: 1;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.2em;
    margin: 0;
    width: auto;
    color: var(--color-brand1);
    
    @media (min-width: 768px) {
        max-width: 200px;
    }
`;

export const ActionButtonWrapper = styled.div`
    
    @media (min-width: 768px) {
        text-align: right;
    }
`;

export const FormModal = styled(CustomModalClient)`
    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;

export const Label = styled(ControlLabel)`
    flex-shrink: 0;
    font-size: 18px;
    padding: 0;   
    text-align: left;
    
    @media (min-width: 768px) {
        font-size: 14px;
    }

    && {
        margin: 0 10px 0 0;
    }

    .rtl & {
        text-align: right;
        margin: 0 0 0 10px;
    }
    
    ${props =>
        props.disabled &&
        css`
            color: var(--color-disabled)
        `
    }
`;

export const Field = styled(FormControl)`

    &&& {
        min-width: 0;
        width: 100%;

        ${props => 
            props.componentClass === 'textarea' &&
            css`
                min-height: 120px;
                background: #fff;
                // overflow-x: scroll;
            `
        }
    }

    ${props =>
        props.disabled &&
        css`
            opacity: var(--value-disabled-opacity)
        `
    }
`;

export const FieldAmountWrapper = styled.div`
    width: 80px;
`;

export const FormRow = styled.div`
    margin-bottom: -20px;
    
    @media (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const FormItem = styled.div`
    padding-bottom: 20px;
    padding-right: 20px;

    .rtl & {
        padding-right: 0;
        padding-left: 20px;
    }

    &:last-child {
        padding-right: 0;
    }

    .rs-form-control-wrapper {
        vertical-align: top;
    }

    ${props =>
        props.grow && 
        css`
            flex-grow: 1;
        `
    }
`;

export const FormGroup = styled(FormGroup_)`
    display: block;
    align-items: center;
    display: flex;
`;

export const Currency = styled.span`
    display: block;
    margin-left: 10px;

    .rtl & {
        margin-left: 0;
        margin-right: 10px;
    }
`;


export const StyledTableActions = styled(ButtonToolbar)``;


export const StyledTableButton = styled(ButtonRowClient)`
    && {
        padding: 0 7px;
        width: auto;
    }
`;