import styled, {css} from "styled-components";
import {Dropdown} from "rsuite";
import {APP_TYPE_CLIENT} from "../../const";

export const StyledDropdown = styled(Dropdown).attrs((props) => ({
    isClient: props.theme.appType === APP_TYPE_CLIENT,
}))`
    &&&&&& .rs-dropdown-toggle {
        background-color: transparent !important;
    }
    
    &&& {
        .rs-dropdown-toggle {
            height: 56px;
            box-sizing: border-box;
            line-height: 56px;
            border-radius: 0;
            padding: 0 10px;
            position: relative;
        }
    
        .rs-dropdown-item-content {
            color: #575757;
        }
        
        .rs-dropdown-item-active > .rs-dropdown-item-content {
            color: var(--color-brand1);
            text-decoration: none;
            outline: 0;
            background-color: rgba(242, 250, 255, 0.5);
        }
    }
    
`;