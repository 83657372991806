import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import history from './config/history';
import {getReferences} from "./actions/references";
import {getPermission} from "actions/auth";
import Maintenance from 'pages/Maintenance/Maintenance';
import CacheBooster from 'components/CacheBooster';
import AdminRoutes from "./routes/RoutesAdmin";
import ClientRoutes from "./routes/RoutesClient";
import {
    APP_TYPE_ADMIN,
    APP_TYPE_CLIENT,
    APP_TYPE_DEFAULT,
    DEFAULT_NOT_AUTH_PATH
} from "./const";
import locales from "./locales";
import localesRsuite from "./locales-rsuite";
import {IntlProvider as RSIntlProvider} from "rsuite";
import {IntlProvider} from "react-intl";
import {getAccountDialerInfo} from "./actions/auth_dialer";
import {setBodyClassNames} from "./utils";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import {ThemeProvider} from "styled-components";
import themeContext from "./context/themeContext";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import PaymentDetailConfirm from "./pagesClient/PaymentDetails/PaymentDetailConfirm";

export const AppTypeContext = React.createContext(APP_TYPE_DEFAULT);
export const RtlContext = React.createContext(false);


function App(
    {
        auth,
        authInfo,
        lang,
        rtl,
        appType,
        getReferences,
        getPermission,
        maintenanceMode,
        getAccountDialerInfo
    }
) {

    useEffect(() => {
        if (auth && !maintenanceMode) {
            getReferences(appType);
            getPermission();

            if (appType === APP_TYPE_CLIENT) {
                getAccountDialerInfo();
            }
        }
    }, [appType, auth, maintenanceMode]);

    useEffect(() => {
        setBodyClassNames(authInfo || {});
    }, [authInfo]);

    const locale = lang || "en";
    const messages = locales[locale];
    const rsMessages = localesRsuite[locale];

    return (
        <IntlProvider locale={locale} messages={messages}>
            <RSIntlProvider locale={rsMessages}>
                <ThemeProvider theme={themeContext[appType]}>
                    <AppTypeContext.Provider value={appType}>
                        <RtlContext.Provider value={rtl}>

                             {/*проверить redirect и history.push (multiple switches between numbers and login)*/}
                            <Router history={history}>

                                <Switch>

                                    {maintenanceMode &&
                                        <Route path="/" component={Maintenance} />
                                    }

                                    <Route path="/login" component={Login}/>
                                    <Route path="/reset-password/:token?" component={ResetPassword}/>
                                    <Route path="/verify-mail/:token?" component={EmailVerification}/>
                                    {!auth && appType !== APP_TYPE_ADMIN &&
                                        <Route exact path="/payment-detail/:token" component={PaymentDetailConfirm}/>
                                    }
                                    {!auth &&
                                        <Redirect to={DEFAULT_NOT_AUTH_PATH}/>
                                    }

                                    {appType === APP_TYPE_ADMIN
                                        ? <AdminRoutes auth={auth}/>
                                        : <ClientRoutes auth={auth} authInfo={authInfo}/>
                                    }

                                </Switch>

                            </Router>

                            <CacheBooster />

                        </RtlContext.Provider>
                    </AppTypeContext.Provider>
                </ThemeProvider>
            </RSIntlProvider>
        </IntlProvider>
    );
}

const mapState = ({auth})=> ({
    appType: auth.appType,
    authInfo: auth.authInfo,
    auth: auth.auth,
    lang: auth.lang,
    rtl: auth.rtl,
    maintenanceMode: auth.maintenanceMode
});

export default connect(mapState, {
    getReferences,
    getPermission,
    getAccountDialerInfo
})(App);