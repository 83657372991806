import React, {createRef, useRef, useState} from 'react'
import {Button, Form, FormControl, InputNumber, Schema, SelectPicker} from "rsuite";
import styled from "styled-components";
import Icon from "rsuite/es/Icon";
import {countDecimals} from '../../utils/helpers';
import Text from '../../components/Form/Text'

const {NumberType} = Schema.Types;


const formModel = Schema.Model({
    sde_key: NumberType()
        .isRequired('Required'),
    range_numbers_default: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater than 0')
        .addRule((value) => value % 1 === 0, 'The number must be integer')
        .addRule((value, formValues) => {
            const currentValue =  Number.parseInt(value);
            const compareValue = formValues.range_numbers_max ? Number.parseInt(formValues.range_numbers_max) : null;

            return currentValue <= compareValue;
        }, 'The default number must be lesser than default'),
    range_numbers_max: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater than 0')
        .addRule((value) => value % 1  === 0, 'The number must be integer')
        .addRule((value, formValues) => {
            const currentValue =  Number.parseInt(value);
            const compareValue = formValues.range_numbers_default ? Number.parseInt(formValues.range_numbers_default) : null;

            return  currentValue >= compareValue
        }, 'The max number must be greater than default'),
    autorevoke_hours: NumberType()
        .isRequired('Required')
        .addRule((value) => value > 0, 'The number must be greater than 0')
        .addRule((value) => countDecimals(value) <= 1, 'This non-integer number must have maximum of one decimal')
});

export default ({
                    filter,
                    subdestinationList,
                    onSubmit,
                    getGoogleOtpAllocationList,
                }) => {

    const formRef = useRef(null);
    const testRef = useRef(null);

    const [formErrorState, onChangeFormError] = useState({});
    const [formValueState, onChangeFormValue] = useState({});

    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        if (!formRef.current.check())
            return;

        const formValue = formRef.current.getFormValue();
        formValue.range_numbers_default = +formValue.range_numbers_default;
        formValue.range_numbers_max = +formValue.range_numbers_max;
        formValue.autorevoke_hours = +formValue.autorevoke_hours;

        setLoading(true);
        onSubmit(formValue)
            .then(res => {
                setLoading(false);
                if (res) {
                    getGoogleOtpAllocationList(filter)
                }
            });
    };

    const updateFormErrorState = (value) => {
        const checkedFormData = formModel.check(value);
        const existErrorData = {
            ...formErrorState,
            ...Object.keys(checkedFormData).reduce((result, dataKey) => {
                if (checkedFormData[dataKey].hasError && value.hasOwnProperty(dataKey)) {
                    result[dataKey] = checkedFormData[dataKey].errorMessage
                } else {
                    result[dataKey] = null
                }
                return result
            }, formErrorState)
        }

        onChangeFormError(existErrorData)
    }

    return (
        <>
            <StyledForm
                ref={formRef}
                formError={formErrorState}
                onSubmit={handleSubmit}
            >
                <StyledFieldWrapper grow={3}>
                    <StyledField
                        name="sde_key"
                        onChange={(value) => {
                            const key = "sde_key";
                            const formValue = {...formValueState, [key]: value};
                            updateFormErrorState(formValue);
                            onChangeFormValue(formValue);
                        }}
                        className="tableFilters_field"
                        errorPlacement="topEnd"
                        accepter={SelectPicker}
                        data={subdestinationList}
                        placeholder="Subdestination"
                        valueKey="sde_key"
                        labelKey="name"
                        type="text"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper grow={2}>
                    <StyledField
                        name="range_numbers_default"
                        onChange={(value) => {
                            const key = "range_numbers_default";
                            const formValue = {...formValueState, [key]: value};
                            updateFormErrorState(formValue);
                            onChangeFormValue(formValue);
                        }}
                        min={0.01}
                        max={1000}
                        accepter={InputNumber}
                        placeholder="Range number default"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper grow={2}>
                    <StyledField
                        name="range_numbers_max"
                        onChange={(value) => {
                            const key = "range_numbers_max";
                            const formValue = {...formValueState, [key]: value};
                            updateFormErrorState(formValue);
                            onChangeFormValue(formValue);
                        }}
                        min={0.01}
                        max={1000}
                        accepter={InputNumber}
                        placeholder="Range number maximum"
                    />
                </StyledFieldWrapper>

                <StyledFieldWrapper grow={2}>
                    <StyledField
                        name="autorevoke_hours"
                        onChange={(value) => {
                            const key = "autorevoke_hours";
                            const formValue = {...formValueState, [key]: value};
                            updateFormErrorState(formValue);
                            onChangeFormValue(formValue);
                        }}
                        min={0.01}
                        max={1000}
                        accepter={InputNumber}
                        placeholder="Autorevoke hours"
                    />
                </StyledFieldWrapper>

                <StyledSubmitWrapper>
                    <Button
                        className="tableFilters_submit"
                        color="green"
                        type="submit"
                        loading={loading}
                    >
                        <Icon icon="plus"/>
                    </Button>
                </StyledSubmitWrapper>
            </StyledForm>
        </>
    )
};

const StyledField = styled(FormControl).attrs(props => ({
    className: "tableFilters_field",
    errorPlacement: "topEnd",
    placement: "autoVerticalStart",
    type: "text",
    cleanable: true,
    searchable: true,
}))`
`;

const StyledFieldWrapper = styled.div`
    flex-grow: ${props => props.grow || '0'};
    flex-shrink: 0;
    flex-basis: 200px;
    padding: 5px 10px;
    width: 174px;
`;
const StyledSubmitWrapper = styled.div`
    padding: 5px 10px;
    //width: 174px;
`;

const StyledForm = styled(Form)`
    display: flex;
`;