import React, {useEffect, useState, useCallback} from "react";
import HawalaPaymentRequestTable from "./HawalaPaymentRequestTable";
import {Spacer} from "../../../components/base/Spacer";
import {FlexboxGrid} from "rsuite";
import HawalaPaymentRequestFilter from "./HawalaPaymentRequestFilter";
import ModalHawalaRequest from "./ModalHawalaRequest";
import ModalHawalaResult from "./ModalHawalaResult";
import HawalaPaymentRequestApiFilter from "./HawalaPaymentRequestApiFilter";
import {ButtonPrimary} from "../../../components/base/BaseButton";
import styled from "styled-components";

export default ({
    requestPayment,
    paymentMethodList,
    paymentTermsList,
    data,
    loading,
    fetchData,
    account_manager_list,
    getAccountManagersList
}) => {
    const [apiFilter, setApiFilter] = useState({str: ""});
    const [filter, setFilter] = useState({payment_terms_name: "", account_manager_id: "", paid_only: true});
    const [selectedItems, setSelectedItems] = useState([]);
    const [requestedPayments, setRequestedPayments] = useState([]);

    const [modalRequestShow, setModalRequestShow] = useState(false);
    const [modalRequestLoading, setModalRequestLoading] = useState(false);
    const [modalResultShow, setModalResultShow] = useState(false);
    const [clearSelectedData, setClearSelectedData] = useState(false);

    useEffect(() => {
        getAccountManagersList()
    }, [])

    useEffect(() => {
        fetchData({filter: apiFilter});
    }, [apiFilter]);

    const handleShowModalRequest = useCallback(() => setModalRequestShow(true), []);

    const exportPayments = async () => {
        setModalRequestLoading(true);
        const selectedItemsKeys = getKeysFromSelectedItems(selectedItems);
        const selectedAccounts = data.filter(item => {
            return selectedItemsKeys.includes(item.id);
        }).map(item => {
            return {...item, ...getValueFromSelectedItems(item.id, selectedItems)}
        });

        const results = await Promise.all(
            selectedAccounts.map( item => requestPayment({
                target: {
                    account_id: item.id,
                    payment_detail_id: item.hawala_payment_detail.id
                },
                amount: item.available_for_payment,
                hawala: true
            }))
        );

        const requestedPayments = results.reduce((prev, item, index) => {
            if (!item || !item.payment_request || !item.payment_request.id)
                return prev;

            return [...prev, selectedAccounts[index]];
        }, []);

        setRequestedPayments(requestedPayments);
        setModalRequestLoading(false);
        setModalRequestShow(false);
        setModalResultShow(true);
        setClearSelectedData(true);
        fetchData({filter: apiFilter});
    };

    const getKeysFromSelectedItems = (items) => {
        return items && Object.keys(items).length && Object.keys(items) || [];
    };

    const getValueFromSelectedItems = (key, items) => {
        const valueObject = items && items.hasOwnProperty(key) && items[key] !== undefined && Object.keys(items[key]).length && items[key];
        const resultObject = Object.entries(valueObject).filter(([_, value]) => {
            return !!value;
        }).reduce((result, [key, value]) => {
            result[key] = value
            return result
        }, {}) || {};

        return resultObject
    };

    return (
        <>
            <FlexboxGrid>
                <FlexboxGrid.Item>

                    <HawalaPaymentRequestApiFilter
                        onChange={setApiFilter}
                    />

                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <HawalaPaymentRequestFilter
                        filters={filter}
                        account_manager_list={account_manager_list}
                        paymentTermsList={paymentTermsList}
                        onChangeFilters={(value) => setFilter(value)}
                    />
                </FlexboxGrid.Item>
                <StyledItemAutoMargin>
                    <ButtonPrimary
                        disabled={!getKeysFromSelectedItems(selectedItems).length}
                        onClick={handleShowModalRequest}
                    >
                        Export payments
                    </ButtonPrimary>
                </StyledItemAutoMargin>
            </FlexboxGrid>

            <Spacer/>

            <HawalaPaymentRequestTable
                paymentMethodList={paymentMethodList}
                filter={filter}
                data={data}
                loading={loading}
                setSelected={(value) => setSelectedItems(value)}
                {...{
                    clearSelectedData,
                    setClearSelectedData
                }}
            />

            <ModalHawalaRequest
                show={modalRequestShow}
                onClose={() => setModalRequestShow(false)}
                onSuccess={exportPayments}
                loading={modalRequestLoading}
                {...{
                    data,
                    selectedItems,
                    getKeysFromSelectedItems,
                    getValueFromSelectedItems
                }}
            />

            <ModalHawalaResult
                show={modalResultShow}
                data={requestedPayments}
                onClose={() => setModalResultShow(false)}
            />
        </>
    )
};

const StyledItemAutoMargin = styled(FlexboxGrid.Item)`
    && {
        margin-left: auto;
    }
`