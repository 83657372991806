import React, {useState} from 'react';
import {Form, Icon, IconButton, InputNumber, SelectPicker, Table as _Table} from 'rsuite';
import Checkbox from '../../../../../hoc/Checkbox';
import WZ from '../../../../../components/Filters/WZ';
import DE from '../../../../../components/Filters/DE';
import SDE from '../../../../../components/Filters/SDE';
import NumberField from '../../../../../components/Form/NumberField';
import MyForm from '../../../../../components/MyForm';
import {api, getFileResponse} from '../../../../../api/loginRoutes';
import Table from '../../../../../components/Table';
import {getBlobContent, pipe} from '../../../../../utils/helpers';
import RateField from '../../../../../components/Form/RateField';
import {ControlLabel, FormControl, FormGroup, Schema} from "rsuite/es";
import {valueAllocationLimit, valueAllocationLimitByPrefix} from 'const';
import {useWindowWidth} from "../../../../../hooks";
import {Collapse} from "react-collapse";
import styled from 'styled-components';

const {Column, HeaderCell, Cell} = _Table;
const {NumberType} = Schema.Types;

const createDownloadUrl = (string, type = "") => {
    return window.URL.createObjectURL( new Blob([string], {type: type}) );
};


const columns = [
    {
        label: 'Subdestination',
        dataKey: 'sde_name',
        minWidth: 280,
        flexGrow: 3
    }
];

const columnsMobile = [
    {
        label: 'Subdestination',
        dataKey: 'sde_name',
        minWidth: 140,
        flexGrow: 1
    }
];


export default ({
    activeTab, 
    onSelect, 
    target, 
    sp_key, 

    activeTrunk,

    showResult, 
    setHideBufferButtons,

    setSummaryState,
    setCsvContent,
    setTotalNumbersForced,

    allocateByPrefixesStatus = false,
    account_id, 
    spAuth, 
    allocationLimit,
    setNotAllocatedNumbers,
    isMobile,

    setTransactionId,
    ...props
}) => {

    const defaultNumberList = [
        {key: 1, name: "1"},
        {key: 10, name: "10"},
        {key: 100, name: "100"},
        {key: 1000, name: "1000"},
        {key: 10000, name: "10000"},
        {key: 100000, name: "100000"},
        {key: 1000000, name: "1000000"}
    ];

    const [valueForm, setValueForm] = React.useState({random_number: true, ranges: 1, numbers: 10});
    const [showFilters, setShowFilters] = React.useState(false);

    const [data, setData] = React.useState({
        current: [],
        loading: true,
        state: []
    });

    const windowWidth = useWindowWidth();

    React.useEffect(() => {

        setData({
            ...data,
            loading: true,
        });

        api('account_price__get_fullprice', {sp_key, target: {account_id: account_id || undefined}})
            .then(
                ({fullprice_for_account}) => {
                    setData({
                        current: fullprice_for_account || [],
                        loading: false,
                        state: fullprice_for_account || [],
                    });
                }
            );
    }, [sp_key, account_id]);

    const onChangePrice = (sde_key, price) => {
        const stateItems = data.state.map(
            row => row.sde_key === sde_key ? {...row, checked: true, rate: price} : row
        );
        const currentItems = data.current.map(
            row => row.sde_key === sde_key ? {...row, checked: true, rate: price} : row
        );

        setData({
            ...data,
            current: currentItems,
            state: stateItems,
        });
    };
    const onChangeChecked = (selected) => {
        const stateItems = data.state.map(
            row => selected.list.includes(row.sde_key) ? {...row, checked: true} : {...row, checked: false}
        );
        const currentItems = data.current.map(
            row => selected.list.includes(row.sde_key) ? {...row, checked: true} : {...row, checked: false}
        );
        setData({
            ...data,
            current: currentItems,
            state: stateItems,
        });
    };
    const onChangeFilters = (filters) => {
        setData({
            ...data,
            state: pipe(data.current, filters, FiltersFuncs)
        });
    };

    const changeSpAuthOption = (item) => {
        props.setAllocateByPrefixesStatus(item);
    };

    const handleFormChange = async (newFormValues) => {
        setValueForm(newFormValues);
    };

    const allocationLimitCondition = allocateByPrefixesStatus ? valueAllocationLimitByPrefix : valueAllocationLimit;

    const model = {
        numbers: NumberType()
            .isRequired('Required')
            .isInteger('Only whole numbers')
            .addRule((value, data) => {
                if (data.allocate_by_prefix !== undefined) {
                    if (value > allocationLimitCondition) {
                        return false
                    }
                }
                return true;
            }, `The maximum of this field is ${allocationLimitCondition}`)
    };


    const handleUpdateNew = ({trunk_number_transaction, not_allocated_numbers}) => {
        if (trunk_number_transaction) {
            const transactionId = trunk_number_transaction.id;
            const summaryInfo = {...trunk_number_transaction};

            setSummaryState(summaryInfo);
            setTransactionId(transactionId);

            setTotalNumbersForced(summaryInfo.numbers);

            setHideBufferButtons(true);
            setNotAllocatedNumbers(not_allocated_numbers);
            showResult();
        }
    };


    return (<>

        <div>
            {isMobile &&
                <div style={{paddingBottom: 10}}>
                    <IconButton
                        appearance={showFilters ? 'primary' : 'default'}
                        icon={<Icon icon="filter"/>}
                        onClick={() => setShowFilters(!showFilters)}
                    >
                    </IconButton>
                </div>
            }
            <Collapse isOpened={!isMobile || showFilters}>
                <FiltersSubdestination onChange={onChangeFilters}/>
            </Collapse>
        </div>

        <StyledTable
            virtualized
            shouldUpdateScroll={false}
            data={data.state}
            height="30%"
            loading={data.loading}
            row_key="sde_key"
            setSelected={onChangeChecked}
            isselected
            stateChecked
            ActionCell={ActionCell(onChangePrice)}

            width={isMobile ? windowWidth - 50 : 'auto'}
            columns={isMobile ? columnsMobile : columns}
            wordWrap={isMobile}
            rowHeight={isMobile ? 46 : 30}
        />

        <MyForm
            activeTrunk={activeTrunk}
            target={target}
            method="allocation:mass"
            checkResultKey='price_range_number_list'
            onChange={handleFormChange}
            update={handleUpdateNew}
            {...props}
            allocateByPrefixesStatus={allocateByPrefixesStatus}
            formValue={valueForm}
            addData={{
                list: data.current
                    .filter(x => x.checked)
                    .map(x => ({sde_key: x.sde_key, rate: +x.rate}))
            }}
            model={model}

            deepCloned={true}
           >

            <NumberField
                name="ranges"
                label="Ranges per subdestination"
                // validationKey="numbers"
                max={Infinity}
                min={0}
                step={1}
                useBr={isMobile ? false : true}
            />
            {allocateByPrefixesStatus && spAuth
                ? <FormGroup>
                    <ControlLabel>Number per range</ControlLabel>
                    <FormControl
                        name="numbers"
                        validationKey="numbers_by_prefix"
                        accepter={SelectPicker}
                        data={defaultNumberList.filter(amount => amount.key <= allocationLimitCondition)}
                        style={{width: '135px'}}
                        labelKey="name"
                        valueKey="key"
                        cleanable={false}
                        searchable={false}
                    />
                </FormGroup>
                : <NumberField name="numbers" label="Number per range" max={allocationLimitCondition} min={0} step={1}/>
            }
            {spAuth &&
                <Checkbox 
                    name="allocate_by_prefix"
                    onChange={changeSpAuthOption}
                    defaultChecked={allocateByPrefixesStatus}
                >
                    Allocate by prefixes
                </Checkbox>
            }
            <br style={{display: isMobile ? 'none' : 'block'}}/>
            <div className="no-margin"></div>
            <Checkbox name="random_number" defaultChecked>Random order</Checkbox>
        </MyForm>
    </>);
}


const FiltersFuncs = {
    sde_key: (x, f) => x.sde_key === f,
    de_key: (x, f) => x.de_key === f,
    wz_key: (x, f) => x.wz_key === f,
    rate_from: (x, f) => x.rate >= +f,
    rate_to: (x, f, allfilters) => {
        // debugger;
        return (+f ? x.rate <= +f : true);
    },
};

const FiltersSubdestination = ({onChange}) => {
    return (
        <Form
            layout="inline"
            onChange={onChange}
        >
            <WZ/>
            <DE/>
            <SDE/>
            <br/>
            <RateField label={'Rate range from'} name="rate_from"/>
            <RateField label={'to'} name="rate_to"/>
        </Form>
    );
};

const ActionCell = (onChange) => (
    <Column width={150} minWidth={140}>
        <HeaderCell>Price</HeaderCell>
        <Cell>
            {rowData => (
                <Form>
                    <StyledNumberField
                        key={rowData.sde_key}
                        max={10}
                        min={0}
                        size="sm"
                        placeholder="0"
                        value={rowData.rate}
                        calculated={true}
                        onChange={value => onChange(rowData.sde_key, value)}
                    />
                </Form>
            )}
        </Cell>
    </Column>
);

const StyledTable = styled(Table)`
    @media (max-width: 767px) {
        font-size: 12px;
    }
    .rs-table-cell-content {
        padding: 0 10px !important;
    }
`;

const StyledNumberField = styled(NumberField)`
    position: relative;
    width: 120px;

    @media (min-width: 1200px) {
        //top: -9px;
        width: 100px;
    }

    &.rs-input-group {
        width: 100px !important;

        @media (min-width: 767px) {
            width: 135px !important;
        }
    }
`;