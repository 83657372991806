import React, {useContext} from 'react';
import {useIntl} from "react-intl";
import {renderColumn, getRangeByNumber} from '../../utils/helpers';
import {LOCAL_STORAGE_USER_INFO_DIALER} from '../../const';
import CustomizableTable from '../../components/client/CustomizableTable/CustomizableTable';
import {useWindowWidth} from '../../hooks';
import {SCREEN_MEDIA} from '../../const'
import m from "../../definedMessages";
import {RtlContext} from "../../App";

const {md} = SCREEN_MEDIA;

export default ({
        data = [],
        loading,
        page,
        count,
        per_page,
        savedPerPage,
        onChangePerPage,
        onChangePage,
        setSelectedData,
        currentDisplay,
        getRowKey,
        useSelectedData,
        ...props
    }) => {

    const {formatMessage} = useIntl();

    const windowWidth = useWindowWidth();
    const rtl = useContext(RtlContext);
    const isMobile = windowWidth < md.min;

    const accountInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO_DIALER);
    const parsedAccountInfo = accountInfo !== null ? JSON.parse(accountInfo) : null;

    const columns = [
        {
            id: 'zone',
            label: formatMessage(m.zone),
            value: (({worldzone_name}) => worldzone_name || 'None'),
            minWidth: 110,
            flexGrow: 4,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'range',
            label: formatMessage(m.range),
            value: (({name, price_range}) => price_range
                ? price_range.name || ''
                : name || '0'),
            minWidth: 200,
            flexGrow: 4,
            align: 'center',
            displayWhen: ['trunk_number', 'price_range'],
        },
        {
            id: 'number', 
            label: formatMessage(m.number), 
            value: (({number, numbers_count}) => getRangeByNumber(number, numbers_count)), 
            minWidth: 130,
            flexGrow: 3,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'bill_increment', 
            label: formatMessage(m.billingIncrement), 
            value: '1/1', 
            minWidth: 90,
            flexGrow: 2,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'start_date', 
            label: formatMessage(m.start_date), 
            dataKey: 'start_date', 
            minWidth: 130,
            flexGrow: 3,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'rate', 
            label: formatMessage(m.rate), 
            value: (({rate}) => {
                if (!rate && rate !== 0)
                    return "";

                return `${Number.parseFloat(rate.toFixed(3))} ${parsedAccountInfo && parsedAccountInfo.session ? parsedAccountInfo.session.account.currency_name : ''}`;
            }), 
            minWidth: 90,
            flexGrow: 2,
            align: 'center',
            displayWhen: ['trunk_number', 'price_range', 'subdestination'],
        },
        {
            label: formatMessage(m.allocatedNumbers),
            dataKey: 'trunk_numbers', 
            minWidth: 90,
            flexGrow: 2,
            displayWhen: ['price_range'], 
            align: !rtl ? 'right' : 'left'
        },
        {
            label: formatMessage(m.subdestination),
            dataKey: 'subdestination_name', 
            minWidth: 200,
            flexGrow: 4,
            align: !rtl ? 'left' : 'right',
            displayWhen: ['subdestination']
        },
        {
            label: formatMessage(m.allocatedNumbersAndRanges),
            // dataKey: 'allocated_numbers_and_ranges',
            value: ({trunk_numbers, price_ranges}) => `${trunk_numbers || 0}/${price_ranges || 0}`,
            minWidth: 90,
            flexGrow: 2,
            displayWhen: ['subdestination'], 
            align: !rtl ? 'right' : 'left'
        },
    ];

    const mobileColumns = [
        {
            id: 'zone_range',
            label: `${formatMessage(m.zone)} / ${formatMessage(m.range)}`,
            value: (({worldzone_name, name}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{worldzone_name || 'None'}</div>
                        <div className="table-two-staged__cell-second">
                            {name || '0'}
                        </div>
                    </div>
                )
            }),
            minWidth: 180,
            flexGrow: 4,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'range',
            label: formatMessage(m.range),
            value: (({name, price_range}) => price_range
                ? price_range.name || ''
                : name || '0'),
            minWidth: 200,
            flexGrow: 4,
            align: 'center',
            displayWhen: ['price_range'],
        },
        {
            id: 'number',
            label: formatMessage(m.number),
            value: (({number, numbers_count}) => getRangeByNumber(number, numbers_count)),
            minWidth: 140,
            flexGrow: 3,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'bill_increment_rate', 
            label: `${formatMessage(m.billingIncrement)} / ${formatMessage(m.rate)}`, 
            value: (({rate}) => {
                return (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">1/1</div>
                        {(rate || rate === 0) && (
                            <div className="table-two-staged__cell-second">
                                {`${Number.parseFloat(rate.toFixed(3))} ${parsedAccountInfo && parsedAccountInfo.session ? parsedAccountInfo.session.account.currency_name : ''}`}
                            </div>
                        )}
                    </div>
                )
            }),
            minWidth: 90,
            flexGrow: 2,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'start_date',
            label: formatMessage(m.start_date),
            dataKey: 'start_date',
            minWidth: 130,
            flexGrow: 3,
            align: 'center',
            displayWhen: ['trunk_number'],
        },
        {
            id: 'rate', 
            label: formatMessage(m.rate), 
            value: (({rate}) => (rate || rate === 0) 
                ? `${Number.parseFloat(rate.toFixed(3))} ${parsedAccountInfo && parsedAccountInfo.session ? parsedAccountInfo.session.account.currency_name : ''}` 
                : ""), 
            minWidth: 90,
            flexGrow: 2,
            align: 'center',
            displayWhen: ['price_range', 'subdestination'],
        },
        {
            label: formatMessage(m.allocatedNumbers), 
            dataKey: 'trunk_numbers', 
            minWidth: 90,
            flexGrow: 2,
            displayWhen: ['price_range'], 
            align: !rtl ? 'right' : 'left'
        },
        {
            label: formatMessage(m.subdestination), 
            dataKey: 'subdestination_name', 
            minWidth: 200,
            flexGrow: 4,
            displayWhen: ['subdestination'],
            align: !rtl ? 'left' : 'right',
        },
        {
            label: formatMessage(m.allocatedNumbersAndRanges),
            value: ({trunk_numbers, price_ranges}) => `${trunk_numbers || 0}/${price_ranges || 0}`,
            minWidth: 90,
            flexGrow: 2,
            displayWhen: ['subdestination'], 
            align: !rtl ? 'right' : 'left'
        },
    ];

    const renderedColumns = !isMobile 
        ? columns.filter(el => el.displayWhen.indexOf(currentDisplay) !== -1).map(renderColumn) 
        : mobileColumns.filter(el => el.displayWhen.indexOf(currentDisplay) !== -1).map(renderColumn);


    return (
        <>
            <CustomizableTable
                {...{
                    rtl,
                    data,
                    count,
                    page,
                    per_page,
                    loading,
                    savedPerPage,
                    onChangePage,
                    onChangePerPage,
                    renderedColumns,
                    useSelectedData
                }}
                ref={ref => props.setRef(ref)}
                setSelected={setSelectedData}
                data={data}
                row_key={getRowKey()}
                isSelect={true}
                isSelectedAll={true}
                isChangedColumns={false}
                isPaginated={true}
                defaultPerPage={10}
                calculatedOuterHeight={601}
                isMobile={isMobile}
            />
        </>
    );
};