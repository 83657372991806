import {ACCESS_LIST as PREFIX, DEFAULT_PER_PAGE_MEDIUM, DESC_SORT, SET_LOADING_ACCESS_EXCLUSION} from '../const';
import {ACCESS_EXCLUSION_LIST} from '../const/apiMethods';
import {api} from '../api/loginRoutes';
import {createAction} from './defaults';

// cancellation
import axios from 'axios';

let cancelToken = axios.CancelToken.source();

const setAccessList = createAction('SET_ITEMS_' + PREFIX);
const setLoading = createAction('SET_LOADING_' + PREFIX);
const setAccessExclusionList = createAction(ACCESS_EXCLUSION_LIST);
const setAccessExclusionLoading = createAction(SET_LOADING_ACCESS_EXCLUSION);


export const getAccessList = (filter, page = 1, per_page = DEFAULT_PER_PAGE_MEDIUM, sort, spAuth = false) => (dispatch) => {
    dispatch(setLoading(true));

    let sort1, sort1_desc;

    if (sort.column) {
        sort1 = sort.column;
        sort1_desc = sort.type && sort.type === DESC_SORT;
    }

    const method = spAuth ? "access_list_otp__get_list" : "access_list__get_list";

    if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    api(method, {filter, page, per_page, sort1, sort1_desc}, false, cancelToken)
        .then((response) => {
            
            if (axios.isCancel(response)) {
                return;
            }

            if (response === undefined) {
                dispatch(setLoading(false));
                return;
            }

            const {access_list_count = 0, access_list_list = []} = response;

            localStorage.setItem(PREFIX + '_per_page', per_page);

            dispatch(setAccessList({
                count: access_list_count,
                access_list_list,
                page,
                per_page
            }));

            dispatch(setLoading(false))
        });
};

export const getAccessExclusionList = () => (dispatch) => {
    api(ACCESS_EXCLUSION_LIST, {target: {}})
        .then(({access_list_exclusion_list = []}) => {
            dispatch(setAccessExclusionList(access_list_exclusion_list));
        })
        .finally(() => dispatch(setAccessExclusionLoading(false)));
};
export const onAddAccessExclusionItem = (data) => async (dispatch) => {
    return await api(
        'access_list_exclusion:create',
        {
            target: {},
            ...data,
        }
    );
};
export const onEditAccessExclusionItem = (alx_key, data) => async (dispatch) => {
    return await api(
        'access_list_exclusion:modify',
        {
            target: {
                alx_key
            },
            ...data,
        }
    );
};
export const onRemoveAccessExclusionItem = (alx_key) => async (dispatch) => {
    return await api(
        'access_list_exclusion:remove',
        {
            target: {
                alx_key
            }
        }
    );
};