import React from 'react';
import { connect } from 'react-redux';
import { SelectPicker } from 'rsuite';
import CustomField from '../Form/CustomField';

const ServicePlansComponent = ({service_plan_list, service_plan_list_filtered, filtered, ...props}) => (
    <CustomField
        block
        data={filtered ? service_plan_list_filtered : service_plan_list}
        accepter={SelectPicker}
        labelKey = "name"
        valueKey = "sp_key"
        placeholder="Prices"
        name="sp_key"
        searchable={false}  
        cleanable={false}  
        classPrefix="allocated"
        {...props}
    />
);

const mapState = ( {references} )=> ({
    service_plan_list: references.service_plan_list,
    service_plan_list_filtered: references.service_plan_list_filtered
});


const ServicePlans = connect( mapState, {})(ServicePlansComponent);

export default ServicePlans; 